import { ReactNode } from 'react';
import { Link as NativeRouterLink, NavLink } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';

type LinkProps = {
  $disabled?: boolean;
};

export type LinkButtonStyleProps = {
  iconOnly?: boolean;
  loading?: boolean;
  weight?: 'bold' | 'medium' | 'normal';
  color?: 'Black' | 'Blue' | 'Red' | 'Grey' | 'White';
  icons?: {
    left?: ReactNode;
    right?: ReactNode;
  };
};

const getButtonColor = (color: LinkButtonStyleProps['color'], theme: DefaultTheme) => {
  switch (color) {
    case 'Grey':
      return theme.colors.base.black.shades[50];
    case 'Black':
      return theme.colors.base.black.shades[90];
    case 'Blue':
      return theme.colors.base.primary.shades[100];
    case 'Red':
      return theme.colors.base.red.shades[100];
    case 'White':
      return theme.colors.base.primary.tints[30];
    default:
      return theme.colors.base.primary.shades[100];
  }
};

const getButtonHoverColor = (color: LinkButtonStyleProps['color'], theme: DefaultTheme) => {
  switch (color) {
    case 'Grey':
      return theme.colors.base.black.shades[70];
    case 'Black':
      return theme.colors.base.black.shades[100];
    case 'Blue':
      return theme.colors.base.primary.shades[20];
    case 'Red':
      return theme.colors.base.red.shades[120];
    case 'White':
      return theme.colors.base.primary.tints[30];
    default:
      return theme.colors.base.primary.shades[20];
  }
};

export const LinkButtonStyle = styled.button<LinkButtonStyleProps>`
  border: none;
  background: none;
  color: ${({ theme, color }) => getButtonColor(color, theme)};
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  transform: all 0.15s ease-out;
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: pointer;
  font-weight: ${({ theme, weight }) => theme.fontWeights[weight ?? 'normal']};

  &:hover {
    color: ${({ theme, color }) => getButtonHoverColor(color, theme)};

    &::before {
      background-color: ${({ theme, color }) => getButtonHoverColor(color, theme)};
    }

    &::after {
      background-color: ${({ theme, color }) => getButtonHoverColor(color, theme)};
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: ${({ loading }) => (loading ? 'progress' : 'not-allowed')};
  }

  svg {
    margin-right: ${({ iconOnly }) => (iconOnly ? 0 : '0.3125rem')};
  }

  &::before {
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${({ theme, color }) => getButtonColor(color, theme)};
    mask-image: ${({ icons }) => (icons?.left ? `url("${icons?.left}")` : 'none')};
    content: ${({ icons }) => (icons?.left ? '""' : 'none')};
    display: inline-block;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    margin-right: ${({ iconOnly }) => (iconOnly ? 0 : '0.25rem')};
  }

  &::after {
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${({ theme, color }) => getButtonColor(color, theme)};
    mask-image: ${({ icons }) => (icons?.right ? `url("${icons?.right}")` : 'none')};
    content: ${({ icons }) => (icons?.right ? '""' : 'none')};
    display: inline-block;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    margin-left: ${({ iconOnly }) => (iconOnly ? 0 : '0.25rem')};
  }
`;

export const StylelessLink = styled(NativeRouterLink)`
  text-decoration: none;
  line-height: 1.25rem;
`;

export const Link = styled.a<LinkProps>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.secondary.textColor : theme.colors.primary.linkText};
  text-decoration: underline;
  font-size: ${({ theme }) => theme.fontSizes.link};
  line-height: 1.25rem;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;

export const InlineLink = styled(Link)`
  font-size: inherit;
  line-height: inherit;

  &:hover {
    color: ${({ theme }) => theme.colors.base.primary.shades[20]};
  }
`;

export const RouterLink = styled(NativeRouterLink)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.primary.linkText};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.link};
  line-height: 1.25rem;
  position: relative;
  text-align: end;
`;

export const NavigationBarLink = styled(NavLink)`
  display: inline-flex;
  position: relative;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.base.black.shades[60]};
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;

  &.active {
    color: ${({ theme }) => theme.colors.base.black.functional};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.base.black.functional};
  }
`;
