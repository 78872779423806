import styled from 'styled-components';

import { ShimmerOnWhite, Typography } from 'components';
import { Button } from 'components/buttonV2';

export const OfferStatusContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0 1rem 1.5rem;
  width: 100%;
  flex: 1;
  max-width: 33.75rem;
`;

export const OfferStatusInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  flex: 1;
  max-width: 33.75rem;
`;

export const OfferStatusContentInfo = styled.div`
  margin-top: 1.5rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const OfferStatusContentInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

type OfferTermsCardProps = {
  variant: 'light' | 'dark';
};

export const OfferTermsCard = styled.div<OfferTermsCardProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1.5rem;
  padding: 1rem 1.5rem;
  background-color: ${({ theme, variant }) =>
    variant === 'light' ? theme.colors.base.white.functional : theme.colors.base.black.tints[3]};

  @media ${({ theme }) => theme.media.maxPhone} {
    padding: 0.875rem 1rem;
  }
`;

export const OfferTermsCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const OfferTermsCardRowHeader = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const OfferTermsCardRowValue = styled(OfferTermsCardRowHeader)`
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  text-align: end;
`;

type OfferFileCardProps = {
  icon: string;
} & OfferTermsCardProps;

export const OfferFileCard = styled.div<OfferFileCardProps>`
  display: flex;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 1.5rem;
  padding: 1rem;
  gap: 0.75rem;
  position: relative;
  align-items: center;
  border: ${({ theme, variant }) =>
    variant === 'light' ? `1px solid ${theme.colors.base.black.shades[10]}` : 'none'};

  &:hover {
    cursor: pointer;
    box-shadow: 0 0.625rem 1.875rem rgba(4.75, 3.76, 53.12, 5%);
  }

  &::after {
    content: '';
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.colors.base.primary.shades[10]};
    border-radius: 100%;
    position: absolute;
    left: 1rem;
  }

  &::before {
    content: '';
    mask-image: ${({ icon }) => `url("${icon}")`};
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    background-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
    mask-position: center;
    mask-repeat: no-repeat;
    border-radius: 100%;
    z-index: 9;
  }
`;

export const OfferFileCardFileName = styled(Typography.Header).attrs({ type: 'h6', weight: 'medium' })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const OfferFileCardButton = styled(Button).attrs({ color: 'LightBlue' })`
  margin-left: auto;
  min-width: 5.3125rem;
`;

export const OfferStatusActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.75rem;
  margin-top: auto;
  width: 100%;

  button {
    width: 100%;
  }
`;

export const OfferDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 2.5rem;
`;

export const Title = styled(Typography.Body).attrs({ type: 'M', weight: 'medium' })`
  font-size: 1rem;
`;

export const OfferStatusInfoRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;

export const LabelContainer = styled.div`
  padding-bottom: 0.5rem;
`;

export const FileChipContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const SkeletonFileIcon = styled(ShimmerOnWhite)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
`;

export const SkeletonFileName = styled(ShimmerOnWhite)`
  height: 1.5rem;
  width: 9rem;
  border-radius: 1.5rem;
`;

export const SkeletonFileButton = styled(ShimmerOnWhite)`
  height: 2.5rem;
  width: 5.375rem;
  margin-left: auto;
`;

export const SkeletonFile = styled.div`
  padding: 1rem;
  border-radius: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.base.black.shades[10]};
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;
