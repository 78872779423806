import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FilterPanel, Paper, Section, SectionLayout } from 'components';
import { selectActiveCustomer } from 'features/auth/selectors';
import {
  BillsFilterFields,
  BillsFilterParams,
  billsDefaultParams
} from 'features/factoringInvoices/components';
import { useLazyGetBillsQuery } from 'features/factoringInvoices/slice';
import { BillsDetailsTable, PAGE_SIZE, Sort } from 'features/factoringInvoices/tables';
import { useAuthSelector } from 'hooks/redux/hooks';
import { queryBuilder } from 'services/utils/queryBuilder';
import { sortedItemsByDate } from 'utils';

export const FactoringBills: FC = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const [filterParamsString, setFilterParamsString] = useState('');
  const [filterParams, setFilterParams] = useState(billsDefaultParams);
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('invoiceDate,DESC');

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const partyId = activeCustomer?.customerCrmId || '';

  const [fetchBills, { data, isFetching }] = useLazyGetBillsQuery();

  const sortedActsItems = sortedItemsByDate(data?.bills, 'invoiceDate');

  useEffect(() => {
    if (partyId && contractId) {
      const queryString = queryBuilder('', filterParams);

      if (filterParamsString !== queryString) {
        setFilterParamsString(queryString);
        setSelectedPage(1);
      } else {
        fetchBills({
          partyId: partyId,
          contractIds: contractId,
          size: `${PAGE_SIZE}`,
          page: `${selectedPage}`,
          ...filterParams,
          sort: sortOrder
        });
      }
    }
  }, [contractId, filterParams, filterParamsString, selectedPage, sortOrder]);

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', inline: 'start' });
        }
      }, 500);
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  const onFilterParamsChange = (params: BillsFilterParams) => {
    if (filterParams !== params) {
      setFilterParams(params);
    }
  };

  const onChangePage = (pageNumber: number) => {
    setSelectedPage(pageNumber);
  };

  const onSortOrderChange = (sort: Sort) => {
    const { sortOrder, dataColumnId } = sort || { sortOrder: 'desc', dataColumnId: 'invoiceDate' };
    setSortOrder(`${dataColumnId || 'invoiceStatus'},${sortOrder.toUpperCase()}`);
  };

  return (
    <SectionLayout id="bills">
      <Paper>
        <Section header={t('bills')}>
          <FilterPanel
            FilterFields={BillsFilterFields}
            defaultParams={billsDefaultParams}
            onFilterParamsChange={onFilterParamsChange}
          />
        </Section>
        <Section>
          <BillsDetailsTable
            activePage={selectedPage}
            filterParams={filterParams as BillsFilterParams}
            totalPages={data?.totalPages}
            loading={isFetching}
            items={sortedActsItems}
            onChangePage={onChangePage}
            onSort={onSortOrderChange}
          />
        </Section>
      </Paper>
    </SectionLayout>
  );
};
