import { useEffect, useMemo } from 'react';

const useIntersectionObserver = (
  target: HTMLElement | null,
  callback: (entries: IntersectionObserverEntry[]) => unknown,
  options?: IntersectionObserverInit
) => {
  const optionValues = options ? Object.values(options) : [];
  const memoizedOptions = useMemo(() => options, optionValues);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, memoizedOptions);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [target, callback, memoizedOptions]);
};

export default useIntersectionObserver;
