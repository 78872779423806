import moment from 'moment';
import { Dispatch, FC, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { LinkButton, SecondaryText, ToggleInput } from 'components';
import { formatFullMonthAndYearOnly } from 'components/datePicker/utils';
import { Periodicity } from 'services/ContractsService/types';

import {
  DataTypeOption,
  DataTypeSelector,
  FilterPanelContainer,
  LeftSideSection,
  MonthSelector,
  MonthSelectorArrowContainer
} from './filterPanel.styles';

type Item = {
  amount?: number;
  availableAmount?: number;
  date?: string;
};

export type FilteredDataItem = Omit<Item, 'availableAmount'>;

type DataTypes = 'used' | 'available';

type Props = {
  data?: Item[];
  onPeriodicityChange: (periodicity: Periodicity) => unknown;
  onSelectedPointChange: Dispatch<SetStateAction<FilteredDataItem[]>>;
  onDataTypeChange?: (dataType: DataTypes) => unknown;
};

const yearMonth = (date?: string) => moment(date, 'YYYY MM');

const subtractMonth = (monthCount: number, date?: string) =>
  yearMonth(date).endOf('month').subtract(monthCount, 'month');

const addMonth = (monthCount: number, date?: string) =>
  yearMonth(date).endOf('month').add(monthCount, 'month');

const isSameByMonth = (dateA?: string, dateB?: string) => yearMonth(dateA).isSame(yearMonth(dateB), 'month');

const isSameOrBeforeByMonth = (dateA?: string, dateB?: string) =>
  yearMonth(dateA).isSameOrBefore(yearMonth(dateB), 'month');

const isSameOrAfterByMonth = (dateA?: string, dateB?: string) =>
  yearMonth(dateA).isSameOrAfter(yearMonth(dateB), 'month');

const isBetweenOneYearPeriod = (dateA?: string, dateB?: string) =>
  yearMonth(dateA).isBetween(subtractMonth(13, dateB), yearMonth(dateB), null, '[]');

export const FilterPanel: FC<Props> = ({
  data,
  onPeriodicityChange,
  onSelectedPointChange,
  onDataTypeChange
}) => {
  const [monthPeriod, setMonthPeriod] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().toISOString());
  const [selectedDataType, setSelectedDataType] = useState<DataTypes>('used');
  const [dataPoints, setDataPoints] = useState<FilteredDataItem[]>(
    data?.map((item) => ({ amount: item.amount, date: item.date })) || []
  );

  const { t, i18n } = useTranslation();

  const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    setMonthPeriod(e.currentTarget.checked);
    onPeriodicityChange(e.currentTarget.checked ? 'MONTH' : 'DAY');
  };

  const onDataTypeClick = (type: DataTypes) => () => {
    onDataTypeChange?.(type);
    setSelectedDataType(type);
  };

  const onSelectedPointHandler = (next: boolean) => () => {
    const { date: lastDate } = dataPoints[dataPoints.length - 1] || {};
    const { date: firstDate } = dataPoints[0] || {};

    if (next) {
      const nextMonth = addMonth(1, selectedMonth).toISOString();

      if (isSameOrBeforeByMonth(nextMonth, lastDate)) {
        setSelectedMonth(nextMonth);
      }
    } else {
      const previousMonth = subtractMonth(1, selectedMonth).toISOString();

      if (isSameOrAfterByMonth(previousMonth, firstDate)) {
        setSelectedMonth(previousMonth);
      }
    }
  };

  useEffect(() => {
    onDataTypeChange?.(selectedDataType);
  }, [onDataTypeChange, selectedDataType]);

  useEffect(() => {
    if (selectedMonth && dataPoints && dataPoints.length) {
      if (monthPeriod) {
        onSelectedPointChange(
          dataPoints.filter((item) => {
            return isBetweenOneYearPeriod(item.date, selectedMonth);
          })
        );
      } else {
        onSelectedPointChange(
          dataPoints.filter((item) => {
            return isSameByMonth(item.date, selectedMonth);
          })
        );
      }
    }
  }, [dataPoints, monthPeriod, onSelectedPointChange, selectedMonth]);

  useEffect(() => {
    if (data && data.length) {
      setDataPoints(
        data.map((item) =>
          selectedDataType === 'used'
            ? { amount: item.amount, date: item.date }
            : { amount: item.availableAmount, date: item.date }
        )
      );
    }
  }, [data, selectedDataType]);

  return (
    <FilterPanelContainer>
      <LeftSideSection>
        <ToggleInput
          testId="filter-panel-periodicity"
          leftLabel={t('month')}
          rightLabel={t('day')}
          onChange={onChangeHandler}
          checked={monthPeriod}
        />
        <MonthSelector>
          <LinkButton
            onClick={onSelectedPointHandler(false)}
            data-testid="filter-panel-month-selector-previous"
          >
            <MonthSelectorArrowContainer>
              <Icons.ChevronLeft />
            </MonthSelectorArrowContainer>
          </LinkButton>
          <LinkButton onClick={onSelectedPointHandler(true)} data-testid="filter-panel-month-selector-next">
            <MonthSelectorArrowContainer>
              <Icons.ChevronRight />
            </MonthSelectorArrowContainer>
          </LinkButton>
          <SecondaryText data-testid="filter-panel-month-selector-label">
            {formatFullMonthAndYearOnly(i18n.language)(selectedMonth)}
          </SecondaryText>
        </MonthSelector>
      </LeftSideSection>
      <DataTypeSelector>
        <DataTypeOption
          data-testid={`filter-panel-data-type-toggle${selectedDataType === 'used' ? '-active' : ''}`}
          active={selectedDataType === 'used'}
        >
          <LinkButton data-testid="filter-panel-data-type-toggle-used" onClick={onDataTypeClick('used')}>
            {t('used')}
          </LinkButton>
        </DataTypeOption>
        <DataTypeOption
          data-testid={`filter-panel-data-type-toggle${selectedDataType === 'used' ? '' : '-active'}`}
          active={selectedDataType === 'available'}
        >
          <LinkButton
            data-testid="filter-panel-data-type-toggle-available"
            onClick={onDataTypeClick('available')}
          >
            {t('available')}
          </LinkButton>
        </DataTypeOption>
      </DataTypeSelector>
    </FilterPanelContainer>
  );
};
