import { ReactNode } from 'react';

import { AddButtonCard, AddButtonIcon, AddButtonLabel } from './addButton.styles';

type Props = {
  children: ReactNode;
  onClick: () => unknown;
};

const AddButton = ({ children, onClick }: Props) => {
  return (
    <AddButtonCard data-testid="add-button" onClick={onClick}>
      <AddButtonIcon />
      <AddButtonLabel>{children}</AddButtonLabel>
    </AddButtonCard>
  );
};

export default AddButton;
