import { FC } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dropdown, Field, Fieldset } from 'components';
import { DrawerImplementation } from 'features/application/drawers';
import { selectIsPrefilledFromWeb } from 'features/application/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { Company } from 'services/CapService/types';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';

import { COMPANY_REGISTRATION_COUNTRIES_LIST } from './constants';

type Props = {
  validationHelpers: ValidationHelpers<Company>;
  getValues: UseFormGetValues<Company>;
  isGhostUser: boolean;
};

export const CompanyCountryFields: FC<Props> = ({ validationHelpers, getValues, isGhostUser }) => {
  const { toggleRightSideDrawer } = useNavContext<DrawerImplementation<'companyRegistrationSelector'>>();

  const isPrefilledFromWeb =
    useApplicationSelector<typeof selectIsPrefilledFromWeb>(selectIsPrefilledFromWeb);

  const disabled = !isGhostUser || isPrefilledFromWeb;

  const { t, i18n } = useTranslation();

  const companyRegistrationCountryOptions = COMPANY_REGISTRATION_COUNTRIES_LIST.map((isoCode) => ({
    value: isoCode.value,
    label: getCountryDisplayName(isoCode.value, i18n.language as Languages)
  }));

  return (
    <Fieldset>
      <Field
        Component={Dropdown}
        separateFromMenu
        onClick={() => toggleRightSideDrawer?.({ drawerId: 'companyRegistrationSelector' })}
        name="country"
        validateOnChange
        displayFlag
        disabled={disabled}
        value={getValues('country')}
        options={companyRegistrationCountryOptions}
        placeholder={t('placeholderCompanyCountry')}
        validationHelpers={validationHelpers}
        showValidationMessage={!getValues('country')}
        required
      />
    </Fieldset>
  );
};
