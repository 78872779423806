import styled, { css, DefaultTheme } from 'styled-components';

import { LinkButtonStyle } from '../link/link.styles';

const mobileContainerStyles = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;

  ${LinkButtonStyle} {
    display: flex;
    flex: 1 0 auto;
    height: 0.375rem;
  }
`;

export const StepperContainerStyles = styled.div`
  flex-direction: column;
  display: flex;
  position: sticky;
  top: 7.375rem;
  ${mobileContainerStyles}
`;

type StepStylesProps = {
  isActive?: boolean;
  isCompleted?: boolean;
};

type FullProps = { theme: DefaultTheme } & StepStylesProps;

const getFontColor = ({ theme, isActive, isCompleted }: FullProps) => {
  const colors = {
    active: theme.colors.base.primary.shades[100],
    completed: theme.colors.base.green.shades[100],
    neutral: theme.colors.base.black.tints[20]
  };

  if (isActive) {
    return colors.active;
  }

  if (isCompleted) {
    return colors.completed;
  }

  return colors.neutral;
};

const getStatusColor = ({ theme, isActive, isCompleted }: FullProps) => {
  const colors = {
    active: theme.colors.base.primary.shades[100],
    completed: theme.colors.base.green.shades[100],
    neutral: theme.colors.base.black.tints[20]
  };

  if (isActive) {
    return colors.active;
  }

  if (isCompleted) {
    return colors.completed;
  }

  return colors.neutral;
};

const textStyles = css`
  font-size: ${({ theme }) => theme.fontSizes.h5};
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${(props) => getFontColor(props)};
`;

const mobileBorderStyles = css<StepStylesProps>`
  display: flex;
  flex: 1 0 auto;
  border-left: none;
  border-radius: 2.5rem;
  height: 0.25rem;
  margin: 0;
  background-color: ${(props) => getStatusColor(props)};
`;

const borderStyle = css<StepStylesProps>`
  border-left: 0.125rem solid ${(props) => getStatusColor(props)};
  margin: 0 0 0.5rem;
  ${mobileBorderStyles}
`;

export const StepStyles = styled.div<StepStylesProps>`
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding-left: 1.25rem;
  ${borderStyle}
  ${textStyles}
`;

export const IconContainer = styled.div`
  display: flex;
`;
