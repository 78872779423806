import { format } from 'date-fns';
import { enGB, lt } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export const useMaintenanceDate = (date?: string) => {
  const { i18n } = useTranslation();

  const formats: Record<string, string> = {
    lt: "yyyy 'm.' MMMM d 'd.', EEEE",
    en: "EEEE do 'of' MMMM yyyy"
  };

  const locales: Record<string, Locale> = {
    en: enGB,
    lt: lt
  };

  const selectedLocale = locales[i18n.resolvedLanguage];

  return date ? format(new Date(date), formats[i18n.resolvedLanguage], { locale: selectedLocale }) : null;
};
