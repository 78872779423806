import styled from 'styled-components';

import gradientBankLogo from 'assets/logo/gradientSmeBank.svg';
import bankLogo from 'assets/logo/smeBank.svg';
import { LinkButton, Typography } from 'components';
import { Button } from 'components/buttonV2';

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.25rem;
`;

export const BankLogo = styled.img.attrs({ alt: 'SME Bank', src: bankLogo })`
  max-height: 1rem;
`;

export const GradientBankLogo = styled.img.attrs({ alt: 'SME Bank', src: gradientBankLogo })`
  max-height: 1rem;
`;

export const BankCardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BankCardHeader = styled.span`
  color: ${({ theme }) => theme.colors.base.white.functional};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  word-wrap: break-word;
`;

export const BankCardText = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.0088rem;
  word-wrap: break-word;
`;

export const BankAlternativeButton = styled.span.attrs({ role: 'button' })`
  display: inline-flex;
  text-decoration: underline;
  cursor: pointer;
`;

export const BankPaperCard = styled.div`
  background: ${({ theme }) => theme.colors.base.white.functional};
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  border-radius: 0.875rem;
`;

export const BankCardCloseButton = styled(LinkButton).attrs({ color: 'White', iconOnly: true })`
  margin-left: auto;
`;

export const BankCardPrimaryButton = styled(Button)<{ gradient?: boolean }>`
  white-space: break-spaces;
  background: ${({ gradient }) => gradient && 'linear-gradient(to right bottom, #2b64f5 0%, #7154d6 100%)'};

  span {
    line-height: 1rem;
    align-items: center;

    svg {
      margin-left: 0.6rem;

      path {
        stroke: ${({ gradient, theme }) => gradient && theme.colors.base.white.functional};
      }
    }
  }
`;

export const BankAccountExplanationText = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.shades[70]};
`;
