import { FC, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dropdown, Field, Input } from 'components';
import { getCountriesList } from 'components/countriesList/constants';
import { CountriesDrawer } from 'features/app/components/countries';
import { DeferralPeriodDrawer } from 'features/application/drawers/thirdParty';
import { ThirdParty } from 'services/CapService/types';
import { Languages } from 'translations/translations';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';

import { PAYMENT_DAYS_LIST } from './constants';
import { ThirdPartiesFieldset } from './thirdPartiesForm.styles';

type Props = {
  validationHelpers: ValidationHelpers<ThirdParty>;
  getValues: UseFormGetValues<ThirdParty>;
};

export const ThirdPartiesFields: FC<Props> = ({ validationHelpers, getValues }) => {
  const { t, i18n } = useTranslation();

  const [showPaymentTermList, setShowPaymentTermList] = useState(false);
  const [showCountriesList, setShowCountriesList] = useState(false);

  const allCountriesOptions = getCountriesList(i18n.language as Languages);

  const handlePeriodSelect = (value: number) => validationHelpers.setValue('deferralPeriod', value);
  const handleCountrySelect = (value: string) => validationHelpers.setValue('company.country', value as any);

  return (
    <>
      <DeferralPeriodDrawer
        open={showPaymentTermList}
        onClose={() => setShowPaymentTermList(false)}
        onSelect={handlePeriodSelect}
        selectedValue={getValues('deferralPeriod')}
      />
      <CountriesDrawer
        activeIndex={1}
        open={showCountriesList}
        onClose={() => setShowCountriesList(false)}
        onSelect={handleCountrySelect}
        selectedValue={getValues('company.country')}
        drawerHeader={t('searchCountry')}
      />
      <ThirdPartiesFieldset>
        <Field
          Component={Input}
          size="L"
          name="company.code"
          type="number"
          maxDecimals={0}
          value={getValues('company.code')}
          placeholder={t('placeholderCompanyCode')}
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={Input}
          size="L"
          name="company.name"
          value={getValues('company.name')}
          placeholder={t('placeholderCompanyName')}
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={Input}
          size="L"
          name="company.vatCode"
          value={getValues('company.vatCode')}
          placeholder={t('placeholderVatCode')}
          validationHelpers={validationHelpers}
        />
        <Field
          validateOnChange
          Component={Dropdown}
          size="L"
          name="company.country"
          separateFromMenu
          onClick={() => setShowCountriesList(true)}
          displayFlag
          value={getValues('company.country')}
          options={allCountriesOptions}
          placeholder={t('placeholderCompanyCountry')}
          validationHelpers={validationHelpers}
          sorted
          required
        />
        <Field
          validateOnChange
          Component={Dropdown}
          size="L"
          separateFromMenu
          onClick={() => setShowPaymentTermList(true)}
          name="deferralPeriod"
          value={isNaN(getValues('deferralPeriod') || NaN) ? -1 : getValues('deferralPeriod')}
          options={PAYMENT_DAYS_LIST}
          placeholder={t('placeholderDeferralPeriod')}
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={Input}
          size="L"
          name="limitAmount"
          value={isNaN(getValues('limitAmount') || NaN) ? '' : getValues('limitAmount')}
          placeholder={t('placeholderLimitAmount')}
          validationHelpers={validationHelpers}
          type="number"
          maxDecimals={2}
          suffix="€"
          required
        />
      </ThirdPartiesFieldset>
    </>
  );
};
