import { GroupBase, OptionProps, StylesConfig } from 'react-select';
import styled, { css } from 'styled-components';

import { ErrorMessage } from 'components/formError';
import { PlaceholderLabel } from 'components/input/input.style';
import { RegularText, SecondaryText } from 'components/text';
import { colors, layers } from 'design/designVariables';

import { Option } from './dropdown';

export type MultilineValueContainerProps = {
  flexDirection?: 'column' | 'row';
};

export const MultilineValueContainer = styled.div<MultilineValueContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: space-between;
  align-items: ${({ flexDirection }) => (flexDirection === 'row' ? 'center' : 'flex-start')};
  overflow: hidden;
  gap: ${({ flexDirection }) => (flexDirection === 'row' ? '0.625rem' : 0)};

  ${SecondaryText} {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

export type FirstLineRowProps = {
  size?: 'S' | 'M';
};

export const FirstLineRow = styled.div<FirstLineRowProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;

  ${RegularText} {
    font-size: ${({ size }) => (size === 'S' ? 'inherit' : '1rem')};
    line-height: ${({ size }) => (size === 'S' ? '1.25rem' : '1.5rem')};
  }
`;

type SelectElementWrapperProps = {
  stopPropagationToChildren?: boolean;
};

type SelectContainerProps = {
  invalidStatus: boolean;
  hasValue: boolean;
  hasPlaceholder?: boolean;
  disabled?: boolean;
  size?: 'M' | 'L';
} & SelectElementWrapperProps;

const getDropdownHeight = ({ size }: Partial<SelectContainerProps>) => (size === 'L' ? '3.5rem' : '3rem');

export const ClearIndicatorContainer = styled.div<Partial<SelectContainerProps>>`
  display: flex;
  min-height: ${getDropdownHeight};
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: -0.1875rem;
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  position: relative;
  height: ${getDropdownHeight};
  background-color: #fff;
  border-radius: 0.5rem;
  transition: all 0.1s ease-out;
  cursor: ${({ disabled, stopPropagationToChildren }) =>
    disabled ? 'not-allowed' : stopPropagationToChildren ? 'pointer' : 'default'};

  &:focus-within {
    label {
      transition: transform 0.15s ease-in-out;

      color: ${({ invalidStatus, theme }) =>
        invalidStatus ? theme.colors.primary.inputPlaceholderColor : theme.colors.base.primary.shades[100]};
      transform-origin: 0% 50%;
      transform: translateY(-0.75rem) scale(0.75);
    }
  }

  ${({ hasValue }) =>
    hasValue
      ? css`
          label {
            transform-origin: 0% 50%;
            transform: translateY(-0.75rem) scale(0.75);
          }
        `
      : css`
          span {
            display: none;
          }
        `}

  [class*='-control'] {
    border-color: ${({ theme, invalidStatus }) =>
      invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10]};

    &:hover {
      border-color: ${({ theme, invalidStatus }) =>
        invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[90]};
      cursor: pointer;
    }

    ${ClearIndicatorContainer} {
      visibility: hidden;
    }

    &:focus-within {
      ${ClearIndicatorContainer} {
        visibility: visible;
      }
    }
  }

  [class*='-control']:has(+ [class*='-menu']) {
    border-color: ${({ theme, invalidStatus }) =>
      invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.primary.buttonBackground};
  }

  [class*='-menu'] {
    border: none;
  }
  [class*='-control'] {
    > div:first-child {
      padding-top: ${({ hasPlaceholder }) => (hasPlaceholder ? '1rem' : 0)};
    }
  }
  [class*='-indicatorContainer'] {
    margin-top: -0.125rem;
  }

  ${ErrorMessage} {
    padding-left: 1rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      [class*='-control'] {
        background-color: ${colors.base.black.tints[5]};
        border-color: ${colors.base.black.tints[10]};

        [class*='-singleValue'] {
          color: ${colors.base.black.tints[40]};
        }
      }

      ${PlaceholderLabel} {
        color: ${colors.base.black.tints[30]};
      }
    `}
`;

export const SelectElementWrapper = styled.div<SelectElementWrapperProps>`
  position: relative;
  display: flex;
  height: auto;
  flex: 1;
  pointer-events: ${({ stopPropagationToChildren }) => (stopPropagationToChildren ? 'none' : 'auto')};

  > * {
    flex: 1;
  }
`;

export const getCustomStyles = (props: Partial<SelectContainerProps>): StylesConfig<Option, false> => ({
  menu: (provided) => ({
    ...provided,
    zIndex: layers.dropdown,
    boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
    borderRadius: '0.625rem',
    marginTop: '0.25rem',
    padding: '0.25rem'
  }),
  menuList: (provided, state) => {
    const isOveflowing = state.options.length > 5;

    return {
      ...provided,
      padding: 0,
      paddingRight: isOveflowing ? '0.25rem' : 0
    };
  },
  menuPortal: (provided) => ({
    ...provided,
    zIndex: layers.dropdown,
    borderRadius: '0.625rem',
    border: 'none'
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    height: getDropdownHeight(props),
    padding: '0rem 0.5rem',
    borderRadius: '0.5rem',
    border: `0.0625rem solid ${
      state.isFocused ? colors.base.primary.shades[100] : colors.base.black.tints[10]
    }`,
    outline: 'none',
    boxShadow: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    margin: 0
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    scale: '70%',
    color: colors.base.black.shades[50],
    ':hover': {
      color: colors.base.black.shades[50]
    },
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
  }),
  option: (provided, { isSelected, isFocused, ...rest }) => {
    const backgroundColor = isSelected ? colors.base.primary.shades[5] : '#fff';

    const { options, value } = rest as OptionProps<Option, false, GroupBase<Option>> & { value: string };

    const isLastOption = (options?.[options.length - 1] as Option)?.value === value;

    return {
      ...provided,
      ':active': {
        backgroundColor: isSelected && isFocused ? colors.base.primary.shades[10] : backgroundColor
      },
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 'medium',
      height: getDropdownHeight(props),
      marginBottom: isLastOption ? 0 : '0.25rem',
      backgroundColor: isSelected && isFocused ? colors.base.primary.shades[10] : backgroundColor,
      borderRadius: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'all 0.15s ease-out',
      '&:hover': {
        backgroundColor: isSelected ? colors.base.primary.shades[10] : colors.base.black.tints[3],
        cursor: 'pointer'
      }
    };
  },
  placeholder: (provided) => ({
    ...provided,
    ':disabled': {
      color: `${colors.base.black.tints[30]}`
    },
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    color: `${colors.primary.inputPlaceholderColor}`
  })
});

type DropdownDrawerCardProps = {
  absolute?: boolean;
  visible?: boolean;
};

export const DropdownDrawerCard = styled.div<DropdownDrawerCardProps>`
  display: flex;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  flex-direction: column;
  position: ${({ absolute }) => (absolute ? 'absolute' : 'relative')};
  max-height: 10rem;
  padding: 0.25rem;
  border-radius: 0.625rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 10%);
  z-index: ${({ theme }) => theme.layers.dropdown};
  width: 100%;
`;

export const DropdownDrawerScrollableList = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 0.25rem;
  overflow-y: auto;
`;

export const DropdownDrawerOption = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  > input[type='radio'] {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.base.black.tints[3]};
  }

  &:has(> input[type='radio']:checked) {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    background-color: ${({ theme }) => theme.colors.base.primary.shades[5]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.base.primary.shades[10]};
    }
  }
`;
