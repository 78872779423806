import styled from 'styled-components';

import { HeaderActions } from 'components/page/page.styles';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: '100%';
  position: 'relative';
  height: '100%';
  align-items: center;
`;

type ApplicationsListContainerProps = {
  dense?: boolean;
};

export const ApplicationsListContainer = styled.div<ApplicationsListContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  gap: 1rem;
  max-width: 33.75rem;
  width: 100%;

  ${HeaderActions} {
    width: ${({ dense }) => (dense ? '100%' : 'auto')};

    * {
      width: 100%;
    }
  }
`;
