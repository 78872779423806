import { FC, MouseEventHandler, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { NavigationBarLink } from 'components';
import { getSublinksByProduct } from 'features/app/components/sideNavigation/links';
import { useScrollStateContext } from 'features/app/context';

type Categories = 'factoring' | 'loans' | 'creditline' | 'leasing' | 'rbf';

import {
  StickyScreenSize,
  SublinksContainer,
  SublinksContainerOffset,
  SublinksContainerWrapper
} from './subcategoriesNavigationBar.styles';

type Props = {
  category: Categories;
  onLinkClick?: () => void;
};

export const SubcategoriesNavigationBar: FC<Props> = ({ category, onLinkClick }) => {
  const { t, i18n } = useTranslation();

  const { saveScrollPosition } = useScrollStateContext();

  const sublinks = getSublinksByProduct(category);

  const [underlineLayout, setUnderlineLayout] = useState({ width: 0, position: 0 });

  const anchorRef = useRef() as MutableRefObject<HTMLAnchorElement>;

  const location = useLocation();

  const pageContentContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const linksContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const resizeObserverRef = useRef(
    new ResizeObserver((entries) => {
      entries.forEach(() => {
        if (anchorRef.current) {
          setUnderlineLayout({
            width: anchorRef.current.offsetWidth,
            position: anchorRef.current.offsetLeft
          });
        }
      });
    })
  );

  useEffect(() => {
    if (resizeObserverRef.current) {
      if (linksContainerRef.current) {
        resizeObserverRef.current.observe(linksContainerRef.current);
      }
    }

    if (anchorRef.current) {
      setUnderlineLayout({
        width: anchorRef.current.offsetWidth,
        position: anchorRef.current.offsetLeft
      });
    }

    return () => {
      if (resizeObserverRef.current) {
        if (linksContainerRef.current) {
          resizeObserverRef.current.unobserve(linksContainerRef.current);
        }

        resizeObserverRef.current.disconnect();
      }
    };
  }, [
    location.pathname,
    anchorRef.current?.offsetWidth,
    anchorRef.current?.offsetLeft,
    window.document.body.offsetWidth,
    i18n.language
  ]);

  if (!sublinks?.links?.length) {
    return null;
  }

  const handleSubmenuClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    onLinkClick?.();
    const hrefTarget = e.target as HTMLAnchorElement;

    //reset saved scroll positions on direct navigation
    window.requestAnimationFrame(() => {
      if (document?.body) {
        // disable ts script check as window.scroll behaviour has a buggy definition (instant is not recognized as valid value)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.scroll({ behavior: 'instant', top: 0 });
        saveScrollPosition(0);
      }
    });

    setUnderlineLayout({
      width: hrefTarget.offsetWidth,
      position: hrefTarget.offsetLeft
    });
  };

  return (
    <StickyScreenSize display="flex" desktop desktopXL tablet mobile>
      <SublinksContainerWrapper>
        <SublinksContainerOffset ref={pageContentContainerRef}>
          <SublinksContainer ref={linksContainerRef} {...underlineLayout}>
            <ul>
              {sublinks.links?.map((linkSection) => {
                const { segment } = linkSection;

                return (
                  <li key={segment}>
                    <NavigationBarLink
                      ref={location.pathname.endsWith(segment || sublinks.segment) ? anchorRef : undefined}
                      to={segment}
                      onClick={handleSubmenuClick}
                    >
                      {t(linkSection.title || segment)}
                    </NavigationBarLink>
                  </li>
                );
              })}
            </ul>
          </SublinksContainer>
        </SublinksContainerOffset>
      </SublinksContainerWrapper>
    </StickyScreenSize>
  );
};
