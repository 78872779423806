import { FC, useEffect, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Icons from 'assets/icons';
import { DownloadLink, RegularText, SecondaryText, Table, transformDateToLocalizedString } from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { useScrollStateContext } from 'features/app/context';
import { selectActiveCustomer } from 'features/auth/selectors';
import { FilterParams } from 'features/invoices/components/filterFields';
import { useAuthSelector } from 'hooks/redux/hooks';
import { InvoicesService } from 'services';

import { createMobileListRows, createTableColumns, TableRowItem } from './createTableColumns';
import {
  CellCollumn,
  InvoiceDetailsTableContainer,
  ExpandoIcon,
  ExpandableRowContainer,
  SecondaryHeader
} from './invoiceDetailsTable.styles';

export const PAGE_SIZE = 10;

export type Sort = { dataColumnId?: string | number; sortOrder: SortOrder };

type Props = {
  items: TableRowItem[];
  filterParams: FilterParams;
  loading?: boolean;
  totalPages?: number;
  onSort?: (sortOder: Sort) => unknown;
  onChangePage?: (pageNumber: number) => unknown;
  activePage?: number;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
};

const expandableIcon = {
  collapsed: (
    <ExpandoIcon expanded={false}>
      <Icons.ChevronDown />
    </ExpandoIcon>
  ),
  expanded: (
    <ExpandoIcon expanded>
      <Icons.ChevronDown />
    </ExpandoIcon>
  )
};

type ExtraRowInformationProps = {
  data: TableRowItem;
};

const ExtraRowInformation: FC<ExtraRowInformationProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <ExpandableRowContainer>
      {data.submittedAt ? (
        <CellCollumn>
          <SecondaryText>{t('uploadedDate')}</SecondaryText>
          <RegularText>{transformDateToLocalizedString(new Date(data.submittedAt))}</RegularText>
        </CellCollumn>
      ) : null}
    </ExpandableRowContainer>
  );
};

type ExpandedRowsConfiguration = { id: string; expanded: boolean };

const getExportFilename = (filename: string) => {
  const [, extension] = filename.split('.');

  const currentDate = new Date();
  const localeToFormat = 'lt-LT';

  return `Invoices_${currentDate.toLocaleDateString(localeToFormat)}_${currentDate
    .toLocaleTimeString(localeToFormat)
    .split(':')
    .join('-')}.${extension}`;
};

export const InvoiceDetailsTable: FC<Props> = ({
  items,
  loading,
  totalPages,
  onSort,
  onChangePage,
  filterParams,
  activePage
}) => {
  const { isFeatureEnabled } = useRemoteConfigContext();
  const { saveScrollPosition, currentScrollPosition } = useScrollStateContext();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const contractId = searchParams.get('contractId');

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const [sortOrder, setSortOrder] = useState<{ dataColumnId?: string | number; sortOrder: SortOrder }>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [expandedRows, setExpandedRows] = useState<ExpandedRowsConfiguration[]>([]);

  const onRowExpand = (expanded: boolean, row: TableRowItem) => {
    if (row.id) {
      const rowConfiguration: ExpandedRowsConfiguration = { expanded, id: row.id };

      const updatedRowsConfiguration = expandedRows.map((row) => {
        if (row.id === rowConfiguration.id) {
          return rowConfiguration;
        }

        return row;
      });

      if (updatedRowsConfiguration.some((row) => row.id === rowConfiguration.id)) {
        setExpandedRows(updatedRowsConfiguration);
      } else {
        setExpandedRows([...updatedRowsConfiguration, rowConfiguration]);
      }
    }
  };

  const onExport = async () => {
    if (contractId && !isDownloading) {
      try {
        setIsDownloading(true);
        const result = await InvoicesService.downloadExportedInvoices({ ...filterParams, contractId });

        return result;
      } finally {
        setIsDownloading(false);
      }
    }
  };

  const conditionalRowStyles = [
    {
      when: (row: TableRowItem) =>
        expandedRows.some((rowConfiguration) => rowConfiguration.id === row.id && rowConfiguration.expanded),
      classNames: ['table-row_expanded']
    }
  ];

  const handleSort = (column: TableColumn<TableRowItem>, sortDirection: SortOrder) => {
    setSortOrder({ dataColumnId: column.id, sortOrder: sortDirection });
    onSort?.({ dataColumnId: column.id, sortOrder: sortDirection });
    setExpandedRows([]);
  };

  const handlePageChange = (pageNumber: number) => {
    onChangePage?.(pageNumber);
    setExpandedRows([]);
  };

  useEffect(() => {
    setExpandedRows([]);
  }, [items]);

  //reset scroll position on remount if scrollPosition is not 0
  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (document && document.body) {
        // disable ts script check as window.scroll behaviour has a buggy definition (instant is not recognized as valid value)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.scroll({ behavior: 'instant', top: currentScrollPosition });
      }
    });
  }, []);

  const customerName = activeCustomer?.customerName || '';

  const columns = createTableColumns(t, customerName);
  const mobileRows = createMobileListRows(t);

  const handleOnRowClicked = (row: TableRowItem) => {
    if (isFeatureEnabled('invoiceDetails')) {
      saveScrollPosition(window.scrollY || document.documentElement.scrollTop);
      navigate(`/finance/factoring/invoices/${row.id}?contractId=${contractId}`);
    }
  };

  return (
    <InvoiceDetailsTableContainer data-testid="invoice-details-table">
      {isFeatureEnabled('invoicesExport') ? (
        <SecondaryHeader>
          <DownloadLink fileRequest={onExport} formatFileName={getExportFilename} noFill>
            <Icons.Excel />
            {t('export')}
          </DownloadLink>
        </SecondaryHeader>
      ) : null}
      <Table<TableRowItem>
        activePage={activePage}
        pagination
        paginationPerPage={PAGE_SIZE}
        expandableRows
        expandOnRowClicked
        highlightOnHover
        onRowClicked={handleOnRowClicked}
        onRowExpandToggled={onRowExpand}
        conditionalRowStyles={conditionalRowStyles}
        expandableIcon={expandableIcon}
        expandableRowsComponent={ExtraRowInformation}
        sortedBy={sortOrder}
        noDataMessage={t('noListItems')}
        onSort={handleSort}
        progressPending={loading}
        columns={columns}
        mobileColumns={mobileRows}
        data={items}
        totalPages={totalPages}
        onChangePage={handlePageChange}
        persistNoDataContainerHeight
      />
    </InvoiceDetailsTableContainer>
  );
};
