import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';

import Input, { InputProps } from './input';

type IBANInputProps = Omit<InputProps, 'value'> & { value: string };

export const IbanInput = (props: IBANInputProps) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [previousCursorPosition, setPreviousCursorPosition] = useState<number | null>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = event.target.selectionStart;
    const previousValue = props.value;
    const currentValue = event.target.value;
    const uppercasedSanitizedValue = event.target.value.replace(/\s+/g, '').toUpperCase();

    let formattedValue = '';

    for (let i = 0; i < uppercasedSanitizedValue.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += ' ';
      }
      formattedValue += uppercasedSanitizedValue[i];
    }

    let newCursorPosition = cursorPosition;
    event.target.value = formattedValue;

    const lengthDifference = (formattedValue || '').length - (previousValue || '').length;

    if (newCursorPosition && cursorPosition) {
      if (
        (lengthDifference > 0 && formattedValue[cursorPosition] === ' ') ||
        (lengthDifference > 1 && formattedValue[cursorPosition + 1] === undefined) ||
        (lengthDifference >= 1 && formattedValue[cursorPosition - 1] === ' ')
      ) {
        newCursorPosition += 1;
      } else if (lengthDifference < 0 && currentValue[cursorPosition - 1] === ' ') {
        newCursorPosition -= 1;
      }
    }

    event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    props.onChange?.(formattedValue);
    setPreviousCursorPosition(newCursorPosition);
  };

  useEffect(() => {
    setTimeout(() => {
      if (previousCursorPosition || previousCursorPosition === 0) {
        inputRef.current?.setSelectionRange(previousCursorPosition, previousCursorPosition);
      }
    }, 16);
  }, [props.value]);

  return <Input ref={inputRef} {...props} handleChangeFormEvent={handleInputChange} />;
};
