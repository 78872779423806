import { FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Dropdown, Input } from 'components';
import { FilterPanelRow, InputContainer } from 'components/filterPanel/filterPanel.styles';
import { OptionsDrawer } from 'features/app/components/drawer';
import { FactoringStatementsGetRequest } from 'services/FactoringStatementsService/types';
import { camelCase } from 'utils';

type InputOnChangeHandler = ((value: string) => string | void) & FormEventHandler<HTMLInputElement>;

export type FilterKeys = keyof Omit<
  FactoringStatementsGetRequest,
  | 'contractId'
  | 'partyId'
  | 'page'
  | 'size'
  | 'sort'
  | 'ignoreUnbilledFeeActs'
  | 'shouldQueryForReconciliations'
  | 'actStatus'
>;

export type StatementsFilterParams = {
  [key in FilterKeys]: string;
};

type FilterFieldsProps = {
  onChange: (
    name: keyof StatementsFilterParams,
    shouldDebounce?: boolean
  ) => (value: string | Date | null) => unknown;
  filterParams: StatementsFilterParams;
};

export const statementsDefaultParams: StatementsFilterParams = {
  actType: '',
  dateFrom: '',
  dateTo: '',
  actNumber: ''
};

const actTypes = ['PAYOUT', 'PAYMENT', 'INTEREST_FEE', 'COMMISSION_FEE', 'LATE_FEE'];

export const StatementsFilterFields: FC<FilterFieldsProps> = ({ onChange, filterParams }) => {
  const { t } = useTranslation();

  const [actTypesVisible, setActTypeVisible] = useState(false);

  const actTypeOptions = [
    { value: '', label: t('all') },
    ...actTypes.map((actType) => ({ value: actType, label: t(camelCase(actType)) }))
  ];

  const onBillStatusChange = (value: string) => {
    onChange('actType')(value);

    setTimeout(() => setActTypeVisible(false), 150);
  };

  return (
    <>
      <OptionsDrawer
        isOpen={actTypesVisible}
        onClose={() => setActTypeVisible(false)}
        header={t('type')}
        onChange={onBillStatusChange}
        selectedValue={filterParams.actType}
        options={actTypeOptions}
      />
      <FilterPanelRow>
        <InputContainer>
          <Input
            required
            placeholder={t('number')}
            onChange={onChange('actNumber', true) as unknown as InputOnChangeHandler}
            value={filterParams.actNumber}
          />
        </InputContainer>
        <InputContainer>
          <Dropdown
            required
            separateFromMenu
            onClick={() => setActTypeVisible(true)}
            placeholder={t('type')}
            onChange={onChange('actType')}
            value={filterParams.actType}
            options={actTypeOptions}
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('dateFrom')}
            onChange={onChange('dateFrom')}
            value={filterParams.dateFrom}
            required
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('dateTo')}
            onChange={onChange('dateTo')}
            value={filterParams.dateTo}
            required
          />
        </InputContainer>
      </FilterPanelRow>
    </>
  );
};
