import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
  FactoringCommisionFeeDownloadRequest,
  FactoringCommisionFeeDownloadResponse,
  FactoringInterestFeeDownloadRequest,
  FactoringInterestFeeDownloadResponse,
  FactoringPaymentReceiptsDownloadRequest,
  FactoringPaymentReceiptsDownloadResponse,
  FactoringPayoutsRequest,
  FactoringPayoutsResponse
} from 'services/FactoringStatementsService/types';
import { axiosBaseQuery } from 'services/RestClient/axiosBaseQuery';
import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';

const withBaseUrl = baseUrlBuilder('factoring-statements');

const factoringStatementsApi = createApi({
  reducerPath: 'factoringStatementsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['commisionFee', 'interestFee', 'lateFees', 'paymentReceipts', 'payouts'],
  endpoints: (build) => ({
    getCommisionFee: build.query<FactoringCommisionFeeDownloadResponse, FactoringCommisionFeeDownloadRequest>(
      {
        query: (payload) => ({
          url: queryBuilder(
            withBaseUrl(`/factoring/statements/invoice/${payload.invoiceId}/commissionFee`),
            payload
          )
        }),
        providesTags: ['commisionFee']
      }
    ),
    getInterestFee: build.query<FactoringInterestFeeDownloadResponse, FactoringInterestFeeDownloadRequest>({
      query: (payload) => ({
        url: queryBuilder(
          withBaseUrl(`/factoring/statements/invoice/${payload.invoiceId}/interestFee`),
          payload
        )
      }),
      providesTags: ['interestFee']
    }),
    getLateFees: build.query<FactoringInterestFeeDownloadResponse, FactoringInterestFeeDownloadRequest>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl(`/factoring/statements/invoice/${payload.invoiceId}/lateFee`), payload)
      }),
      providesTags: ['lateFees']
    }),
    getPaymentReceipts: build.query<
      FactoringPaymentReceiptsDownloadResponse,
      FactoringPaymentReceiptsDownloadRequest
    >({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl(`/factoring/statements/invoice/${payload.invoiceId}/payments`), payload)
      }),
      providesTags: ['paymentReceipts']
    }),
    getPayouts: build.query<FactoringPayoutsResponse, FactoringPayoutsRequest>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl(`/factoring/statements/invoice/${payload.invoiceId}/payouts`), payload)
      }),
      providesTags: ['payouts']
    })
  })
});

export const {
  useGetCommisionFeeQuery,
  useLazyGetCommisionFeeQuery,
  useGetInterestFeeQuery,
  useLazyGetInterestFeeQuery,
  useGetLateFeesQuery,
  useLazyGetLateFeesQuery,
  useGetPaymentReceiptsQuery,
  useLazyGetPaymentReceiptsQuery,
  useGetPayoutsQuery,
  useLazyGetPayoutsQuery,
  endpoints
} = factoringStatementsApi;

export const { resetApiState: resetFactoringStatementsApiState } = factoringStatementsApi.util;

export default factoringStatementsApi;
