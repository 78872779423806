import styled, { css } from 'styled-components';

import arrowRight from 'assets/icons/chevronRight.svg';
import { CopyrightLinks, LinkButton, LinkButtonStyle, Paper, RegularText, SecondaryText } from 'components';
import { InputText } from 'components/typography/typography.styles';

type DropdownHeaderProps = {
  active?: boolean;
  variant?: 'dropdown' | 'icon';
};

export const DropdownContainer = styled.div<DropdownHeaderProps>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ variant }) =>
    variant === 'dropdown' &&
    css`
      @media ${({ theme }) => theme.media.minTablet} {
        &::after {
          content: '';
          min-width: 1.5rem;
          width: 1.5rem;
          height: 1.5rem;
          mask-image: ${() => `url("${arrowRight}")`};
          background-color: ${({ theme }) => theme.colors.base.black.shades[50]};
          margin-left: auto;
          position: absolute;
          right: 0.5rem;
        }
      }
    `}
`;

export const UserIconCircle = styled.div<DropdownHeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  border-radius: 50%;
  background: ${({ active }) => (active ? '#000000' : '#e6e6e6')};

  svg {
    color: ${({ theme }) => theme.colors.base.black.shades[70]};

    path {
      stroke: ${({ active, theme }) => (active ? theme.colors.base.white.functional : 'currentColor')};
    }

    circle {
      stroke: ${({ active, theme }) => (active ? theme.colors.base.white.functional : 'currentColor')};
    }
  }
`;

export const DropdownHeader = styled.div<DropdownHeaderProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${({ theme }) => theme.media.minTablet} {
    ${({ variant }) =>
      variant === 'dropdown' &&
      css`
        background-color: ${({ theme }) => theme.colors.base.white.functional};
        padding: 0.5rem;
        border-radius: 0.75rem;
        width: 100%;
        gap: 0.5rem;
        font-size: 0.8125rem;
        line-height: 1.3125rem;
        font-weight: ${({ theme }) => theme.fontWeights.medium};

        ${UserIconCircle} {
          min-width: 2rem;
          width: 2rem;
          height: 2rem;

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      `}
  }
`;

export const UserNameContainer = styled.div`
  display: inline;
  max-width: 9.0625rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const LanguageIconCircle = styled.div<DropdownHeaderProps>`
  display: inline-block;
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  box-shadow: none;
  position: relative;
  max-width: 2rem;
  border-radius: 50%;
  margin-right: 0.625rem;

  svg {
    width: 150%;
    position: relative;
    right: 0.375rem;
  }

  &::after {
    content: '';
    display: ${({ active }) => (active ? 'flex' : 'none')};
    position: absolute;
    border-radius: 100%;
    border: 0.4375rem solid ${({ theme }) => theme.colors.base.white.functional};
    height: 20px;
    width: 20px;
    top: -5px;
    right: -5px;
  }
`;

export const SelectedLanguageIconCircle = styled(LanguageIconCircle)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
`;

type DropdownListContainerProps = {
  boundingRect?: DOMRect | null;
} & DropdownHeaderProps;

export const DropdownListContainer = styled.div<DropdownListContainerProps>`
  position: fixed;
  min-width: 15rem;
  top: ${({ boundingRect, variant }) =>
    boundingRect ? (variant === 'icon' ? `${boundingRect.bottom + 8}px` : `${boundingRect.top}px`) : 0};
  background-color: transparent;
  z-index: ${({ theme }) => theme.layers.modal};
  left: ${({ boundingRect, variant }) =>
    boundingRect
      ? variant === 'icon'
        ? `calc(${boundingRect.left + boundingRect.width}px - 25rem)`
        : `${boundingRect.left + boundingRect.width + 8}px`
      : 0};

  &[data-popper-reference-hidden] {
    display: none;
  }

  ${Paper} {
    padding: 1.25rem;
    width: 12rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 10%);
  }
`;

export const LanguageDropdownListContainer = styled.div`
  position: absolute;
  right: 0;
  top: 1.75rem;
  max-width: 12.625rem;
  min-width: 12.625rem;
  width: 100%;
`;

type DropdownListProps = {
  locale?: string;
};

export const DropdownList = styled.div<DropdownListProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 1rem;
  min-width: ${({ locale }) => (locale === 'lt' ? '25rem' : '22rem')};
  box-shadow: 0 0.25rem 0.5rem ${({ theme }) => theme.colors.shadow};

  @media ${({ theme }) => theme.media.maxTablet} {
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
`;

export const DropdownCopyrightLinks = styled(CopyrightLinks)`
  justify-content: center;
  padding-top: 1rem;
  flex-wrap: wrap;

  ${InputText} {
    font-size: 1rem;
  }

  a {
    ${InputText} {
      font-size: 1rem;
    }
  }

  &::before {
    content: '';
    display: flex;
    margin-inline: -1.5rem;
    width: calc(100% + 3rem);
    height: 0.0625rem;
    background-color: ${({ theme }) => theme.colors.base.black.shades[10]};
  }
`;

export const WhiteBackground = styled.div`
  background: ${({ theme }) => theme.colors.base.white.functional};
  padding: 0 1.5rem 1rem;
`;

export const LanguagesDropdownList = styled.ul`
  margin: 0;
  padding: 0.25rem;
  background: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 0.625rem;
  max-width: 12.625rem;
  box-shadow: 0 0.25rem 0.5rem ${({ theme }) => theme.colors.shadow};
  width: 100%;

  @media ${({ theme }) => theme.media.maxTablet} {
    box-shadow: none;
    max-width: 100%;
    background: ${({ theme }) => theme.colors.base.white.functional};
    padding: 0.5rem;
  }
`;

export const WelcomText = styled(RegularText)`
  display: flex;
  color: ${({ theme }) => theme.colors.base.black.functional};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-left: 1rem;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }

  ${LinkButtonStyle} {
    width: 100%;
    min-height: 1.5rem;

    &:hover {
      ${RegularText} {
        color: ${({ theme }) => theme.colors.primary.linkText};
      }
    }
  }
`;

export const LanguageName = styled(RegularText)`
  color: ${({ theme }) => theme.colors.base.black.functional};
`;

export const LanguagesListItem = styled(ListItem)<DropdownHeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-bottom: 0;
  min-height: 3rem;
  background-color: ${({ active, theme }) => (active ? theme.colors.base.primary.shades[5] : 'transparent')};
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  transition: all 0.15s ease-out;

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.base.primary.shades[10] : theme.colors.base.black.shades[3]};
  }

  &:last-of-type {
    margin: 0;
    padding-bottom: 0;
  }

  ${LinkButtonStyle} {
    padding-inline: 0.875rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    display: flex;
    min-width: 100%;
    height: 100%;
    min-height: 3rem;

    &:hover {
      ${LanguageName} {
        color: ${({ theme }) => theme.colors.base.black.functional};
      }
    }
  }
`;

export const MenuNavigationButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;

  span {
    color: ${({ theme }) => theme.colors.base.black.shades[70]};
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  svg {
    margin-right: 0;
    stroke: ${({ theme }) => theme.colors.base.black.shades[70]};

    * {
      stroke: ${({ theme }) => theme.colors.base.black.shades[70]};
    }
  }
`;

export const MobileMenuNavigationItem = styled.button`
  display: flex;
  margin-bottom: 1rem;
  font-size: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;

    path {
      stroke: ${({ theme }) => theme.colors.base.black.faded};
    }
  }
`;

export const CompaniesListHeader = styled.div`
  ${ListItem} {
    padding-bottom: 0;
  }

  ${SecondaryText} {
    font-size: 0.75rem;
  }
`;

export const WhiteOverlay = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.primary.background};
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.layers.overlay};
`;

export const GrayOverlay = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary.background};
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.layers.overlay};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

type MobileHeaderMenuProps = {
  close?: boolean;
};

export const MobileMenuHeader = styled.div<MobileHeaderMenuProps>`
  display: flex;
  justify-content: space-between;
  height: 3.75rem;
  align-items: center;
  background-color: ${({ close, theme }) =>
    close ? theme.colors.base.white.functional : theme.colors.primary.background};

  ${LinkButtonStyle} {
    padding: 1rem;

    svg {
      margin: 0;

      path {
        fill: ${({ theme }) => theme.colors.base.black.shades[70]};
      }
    }
  }
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3.75rem;
`;

export const LanguageOptionsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  ${LanguagesListItem} {
    width: 100%;
  }
`;
