import { useSearchParams } from 'react-router-dom';

import { useLoansSelector } from 'hooks/redux/hooks';

import { useGetSchedulesQuery } from '../api';
import { selectLoansContractList } from '../selectors';

export type PaginationArgs = {
  pageNo: number;
  pageSize: number;
};

export const useFetchSchedulesForSelectedContract = () => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);

  const { contractNo, financierId, customerId, financierMigrationError, financierMigrationDate } =
    selectedContract || {};

  return useGetSchedulesQuery(
    {
      contractNo: contractNo || '',
      financierId: financierId || '',
      customerId: customerId || '',
      financierMigrationError: `${financierMigrationError}` || '',
      financierMigrationDate: financierMigrationDate || ''
    },
    {
      skip: !contractNo || !financierId || !customerId,
      selectFromResult: (result) => ({ ...result, financierMigrationError, financierMigrationDate })
    }
  );
};
