import { FC, useEffect, useState } from 'react';

import { Copyright, Loader, LoginPageStyle } from 'components';
import { resetApplicationState } from 'features/application/slice';
import {
  selectHasChallengeLoginFailed,
  selectIsIndentityStepUp,
  selectUserData
} from 'features/auth/selectors';
import { clearAuthTokens } from 'features/auth/utils';
import { TopNavigation } from 'features/nav/components';
import { useAuthSelector } from 'hooks/redux/hooks';
import { useAppLocation } from 'hooks/router/useAppLocation';
import { resetPresistedSlices } from 'store';

import Login from './components/login';
import { LoginLoaderContainer } from './components/loginContainer/loginContainer.styles';

type Props = {
  otpOnly?: boolean;
};

const LoginPage: FC<Props> = ({ otpOnly }) => {
  const { state } = useAppLocation();
  const hasChallengeLoginFailed = useAuthSelector<typeof selectHasChallengeLoginFailed>(
    selectHasChallengeLoginFailed
  );
  const userData = useAuthSelector<typeof selectUserData>(selectUserData);
  const isIdentityStepUp = useAuthSelector<typeof selectIsIndentityStepUp>(selectIsIndentityStepUp);

  const [stateCleared, setStateCleared] = useState(false);
  const [emailRedirectTo, setEmailRedirectTo] = useState('');

  useEffect(() => {
    (async () => {
      try {
        if (!isIdentityStepUp) {
          resetPresistedSlices();
        }

        resetApplicationState();
        clearAuthTokens();
      } catch {
        //fail silently
      } finally {
        setStateCleared(true);
      }
    })();
  }, [hasChallengeLoginFailed]);

  useEffect(() => {
    const searchParams = new URLSearchParams(state?.originalLocation?.search);
    const emailFromOriginalQuery = searchParams.get('email');
    const emailRedirectTo = state?.email ?? emailFromOriginalQuery;

    setEmailRedirectTo(emailRedirectTo ?? '');
  }, [state?.email, state?.originalLocation?.search]);

  const userEmail = userData.email ?? emailRedirectTo ?? '';

  return (
    <>
      <TopNavigation />
      <LoginPageStyle data-testid="login-page-desktop">
        {!stateCleared ? (
          <LoginLoaderContainer>
            <Loader />
          </LoginLoaderContainer>
        ) : (
          <Login
            otpOnly={otpOnly}
            userEmail={userEmail}
            country={userData.country}
            isIdentityStepUp={isIdentityStepUp}
          />
        )}
      </LoginPageStyle>
      <Copyright />
    </>
  );
};

export default LoginPage;
