type SelectOption<T extends string | number> = {
  value: T;
  label: string;
};

const createSelectOption = <T extends string | number>(value: T, label: string): SelectOption<T> => ({
  value,
  label
});

export const PAYMENT_DAYS_LIST = [
  createSelectOption(15, '15'),
  createSelectOption(30, '30'),
  createSelectOption(45, '45'),
  createSelectOption(60, '60'),
  createSelectOption(90, '90'),
  createSelectOption(120, '120'),
  createSelectOption(180, '180')
];
