import styled from 'styled-components';

import { SecondaryText } from '../text';

export const InfoText = styled(SecondaryText)`
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: 0.5%;
  max-width: 21.25rem;
  font-weight: 400;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
`;

export const IconButtonContainer = styled.div`
  display: inline-flex;
  cursor: default;

  svg {
    pointer-events: none;

    path {
      transition: stroke 0.15s ease-in-out;
      stroke: ${({ theme }) => theme.colors.base.black.shades[50]};
    }
  }

  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.base.black.shades[70]};
      }
    }
  }
`;
