import styled from 'styled-components';

import { Fieldset, Overlay, PaperStyle, SecondaryText } from 'components';
import { ListContainer } from 'components/formList/formList.styles';

export const BeneficialOwnerExplanationText = styled(SecondaryText)`
  display: inline-block;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;

  &::before {
    content: '* ';
  }
`;

export const BeneficialOwnersFieldset = styled(Fieldset)``;

export const BeneficialOwnersFormContainer = styled.div`
  ${ListContainer} {
    margin: 0 0 1.25rem;
  }

  ${Overlay} {
    > ${PaperStyle} {
      max-width: 35.75rem;
    }
  }
`;
