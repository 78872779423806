import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'components';
import { LoginPageStyle } from 'components/page';
import { Toast } from 'components/toastV2';
import PersonalDataForm from 'features/auth/components/personalDataForm';
import { selectUserData } from 'features/auth/selectors';
import { updatePIDAsync } from 'features/auth/slice';
import { PersonalData } from 'features/auth/types/authTypes';
import { TopNavigation } from 'features/nav/components';
import { usePatchUserMutation } from 'features/users/slice';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';

import LoginContainer from '../user/components/loginContainer';
import { LoginLoaderContainer } from '../user/components/loginContainer/loginContainer.styles';

import { PersonalInfoUpdateContainer, ToastContainer } from './personalInfoUpdatePage.styles';

export const PersonalInfoUpdatePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [updateUser] = usePatchUserMutation();

  const userData = useAuthSelector(selectUserData);

  const onBack = () => {
    navigate('/login');
  };

  const onSubmit = async (data: PersonalData) => {
    try {
      setLoading(true);

      await dispatch(updatePIDAsync({ pid: data.customerId })).unwrap();

      if (data.phone) {
        await updateUser({
          mobilePhoneNumber: data.phone
        }).unwrap();
      }

      setLoading(false);
      setError(false);
      navigate('/login', { state: { email: userData.email } });
    } catch {
      setLoading(false);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <LoginLoaderContainer>
        <Loader />
      </LoginLoaderContainer>
    );
  }

  return (
    <>
      <TopNavigation />
      <LoginPageStyle>
        <LoginContainer headerLabel="enterMissingPersonalInfoExplanation" onBack={onBack}>
          <PersonalInfoUpdateContainer>
            <Toast variant="info" isVisible header={t('missingLoginInfoExplanation')} />
            {error ? (
              <ToastContainer>
                <Toast isVisible variant="error" header={t('errorOccuredMessage')} />
              </ToastContainer>
            ) : null}
            <PersonalDataForm
              displayPhoneInput={true}
              onSubmit={onSubmit}
              continueButtonLabel="submit"
              requiredPhoneInput={false}
            />
          </PersonalInfoUpdateContainer>
        </LoginContainer>
      </LoginPageStyle>
    </>
  );
};
