import { useSearchParams } from 'react-router-dom';

import { useLoansSelector } from 'hooks/redux/hooks';

import { useGetContractDocumentsListQuery } from '../api';
import { selectChosenLoansContractInfo } from '../selectors';

export const useFetchLoansConctractsDocumentsList = () => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || '';

  const contract = useLoansSelector<typeof selectChosenLoansContractInfo>(selectChosenLoansContractInfo)(
    contractId || ''
  );

  const contractDetailsUpdated = contract?.contractNo === contractId;

  const response = useGetContractDocumentsListQuery(
    {
      contractNo: `${contract?.contractNo || ''}`,
      financierId: `${contract?.financierId || ''}`,
      customerId: `${contract?.customerId || ''}`
    },
    { skip: !contractId || !contractDetailsUpdated }
  );

  return { ...response, isFetching: response.isFetching };
};
