import { useId } from 'react';

import { DropdownDrawerCard, DropdownDrawerOption, DropdownDrawerScrollableList } from './dropdown.style';
import { GenericOption, Label } from './Label';

type Props<TOption extends GenericOption = GenericOption> = {
  options: TOption[];
  onSelect: (option: TOption) => unknown;
  name: string;
  value?: string;
  absolute?: boolean;
  visible?: boolean;
};

function DropdownDrawer<T extends GenericOption>({
  options,
  name,
  value,
  onSelect,
  absolute,
  visible
}: Props<T>) {
  const id = useId();

  const validDOMId = name + id.replace(/[^a-z0-9]/gi, '');

  const isSelected = (option: T) => (value ? value === option.value : undefined);

  if (!options?.length) {
    return null;
  }

  return (
    <DropdownDrawerCard visible={visible} absolute={absolute}>
      <DropdownDrawerScrollableList>
        {options.map((option, index) => (
          <DropdownDrawerOption
            htmlFor={validDOMId + index}
            key={option.value}
            onClick={() => onSelect(option)}
          >
            <input
              onChange={() => onSelect(option)}
              checked={isSelected(option)}
              id={validDOMId + index}
              type="radio"
              name={name}
              value={option.value}
            />
            <Label textSize="M" option={option} flexDirection="row" />
          </DropdownDrawerOption>
        ))}
      </DropdownDrawerScrollableList>
    </DropdownDrawerCard>
  );
}

export default DropdownDrawer;
