import { useNavigate } from 'react-router-dom';

import { resetApplicationState } from 'features/application/slice';
import { useModalContext } from 'features/nav/context/modalContext';
import { resetStatementsState } from 'features/statements/slice';
import { useAppDispatch } from 'hooks/redux/hooks';

import { ToggleParams as ViewOfferModalToggleParams } from '../components/offerStatusModal/offerStatusModal';
import { ToggleParams as ProvideInformationModalToggleParams } from '../components/provideInformationModal/provideInformationModal';
import { ButtonActions } from '../types/applicationsTypes';

type HandlerCallbacks = { [key in `on${Capitalize<ButtonActions>}`]?: (bool: true) => unknown };

type Handlers = (id?: string, applicationId?: string, offerId?: string) => unknown;

type HookReturnType = Record<ButtonActions, Handlers>;

const questionaireLink = VARIABLES.companiesQuestionaire as string;

export default function useApplicationCardStatusHandlers({
  onUploadDocuments,
  onViewOffer,
  onContinueFilling,
  onFillQuestionnaire,
  onRequestFinancing
}: HandlerCallbacks | undefined = {}): HookReturnType {
  const { modals } = useModalContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return {
    requestFinancing: () => {
      dispatch(resetStatementsState());
      dispatch(resetApplicationState());

      onRequestFinancing?.(true);
      navigate('/new-application?newcompany=true');
    },
    continueFilling: (id?: string) => {
      dispatch(resetStatementsState());
      dispatch(resetApplicationState());

      if (id) {
        onContinueFilling?.(true);
        navigate(`/new-application/${id}`);
      }
    },
    uploadDocuments: (applicationId?: string) => {
      if (applicationId) {
        const provideInformationModalParams: ProvideInformationModalToggleParams = {
          applicationId
        };

        modals?.get('provide-information')?.(provideInformationModalParams);
        onUploadDocuments?.(true);
      }
    },
    fillQuestionnaire: () => {
      onFillQuestionnaire?.(true);
      window.open(questionaireLink, '_blank', 'noopener');
    },
    viewOffer: async (applicationId?: string) => {
      if (applicationId) {
        const viewOfferModalParams: ViewOfferModalToggleParams = {
          applicationId
        };

        modals?.get('offer-status')?.(viewOfferModalParams);
        onViewOffer?.(true);
      }
    }
  };
}
