import styled, { css } from 'styled-components';

import { Typography } from 'components/typography';

import { Copyright } from '../copyright';
import { ScreenSize } from '../layout';
import { LinkButtonStyle } from '../link/link.styles';
import { SecondaryText } from '../text';

export const maxWidthStyle = css`
  max-width: ${({ theme }) => theme.pageContentLayouts.maxWidth};
  min-width: ${({ theme }) => theme.pageContentLayouts.minWidth};
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const PageCopyright = styled(Copyright)`
  margin-top: auto;
`;

export const PageContentLayout = styled.div`
  max-width: 87.5rem;
  min-width: 5rem;
  margin: 0 auto;
`;

export const Header = styled(Typography.Header).attrs({ type: 'h1', weight: 'light' })``;

export const Subheader = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${SecondaryText} {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 0.1875rem;
    margin-bottom: 0.625rem;
  }
`;

export const NativeLinkButton = styled.div`
  display: flex;
  gap: 1rem;

  ${LinkButtonStyle} {
    margin-bottom: 0.625rem;
    background-color: ${({ theme }) => theme.colors.primary.linkText};
    height: 2rem;
    border-radius: 1.5rem;
    padding: 0 0.8125rem;

    span {
      color: ${({ theme }) => theme.colors.primary.background};
      font-size: 1rem;
    }

    svg {
      fill: ${({ theme }) => theme.colors.primary.background};
    }
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

type HeaderProps = {
  centerVeritcally?: boolean;
  marginBottom?: string;
  sticky?: boolean;
  dense?: boolean;
};

export const HeaderRow = styled.div<HeaderProps>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: ${({ centerVeritcally }) => (centerVeritcally ? 'center' : 'flex-end')};
  ${({ sticky }) =>
    sticky
      ? css`
          position: sticky;
          top: 1.25rem;
          z-index: 99;
          max-width: 15rem;
          margin-left: auto;
          margin-bottom: 1.5rem;
        `
      : ''};

  @media ${({ theme }) => theme.media.maxPhone} {
    align-items: flex-start;
  }
`;

export const PageContentOuterContainer = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ dense }) => (dense ? 'center' : 'flex-start')};
  width: 100%;
`;

export const PageContentContainer = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-bottom: 2.5rem;
  width: 100%;
  ${({ dense }) =>
    dense
      ? css`
          max-width: 33.75rem;
        `
      : css``}

  ${HeaderRow} {
    min-height: 4.5rem;
    padding: 1rem 0 1.5rem;
  }
`;

export const Page = styled.article`
  display: flex;
  flex-grow: 1;
  padding-left: 3rem;
  padding-right: 3rem;
  ${maxWidthStyle}

  @media ${({ theme }) => theme.media.maxDesktop} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const LoginPageStyle = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8.625rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 5rem 1rem 1rem;
    display: block;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DialogMessageContainer = styled.div`
  max-width: 39rem;
  min-width: 39rem;
  width: 100%;

  @media ${({ theme }) => theme.media.maxDesktop} {
    min-width: 100%;
  }
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.gap};
  grid-template-columns: repeat(12, [col-start] 1fr);
`;

type ScreenSizes = 'Sm' | 'Md' | 'Lg' | '';
type Properties =
  | `colStart${ScreenSizes}`
  | `span${ScreenSizes}`
  | `spanRow${ScreenSizes}`
  | `rowStart${ScreenSizes}`;

export type GridProps = {
  [size in Properties]?: number | string;
};

const setPlacementInGrid = (size: ScreenSizes) => {
  return css<GridProps>`
    grid-column: col-start ${(props) => props[`colStart${size}`] || props['colStart']} / span
      ${(props) => props[`span${size}`] || props['span']};

    /* prettier-ignore */
    grid-row: ${(props) => props[`rowStart${size}`] || props['rowStart']} / ${(props) =>
      props[`spanRow${size}`] || props['spanRow']};
  `;
};

export const Column = styled.div<GridProps>`
  grid-column: col-start ${({ colStart }) => colStart} / span ${({ span }) => span};
  grid-row: ${({ rowStart }) => rowStart} / ${({ spanRow }) => spanRow};

  @media ${({ theme }) => theme.media.maxDesktop} {
    ${({ colStartLg, spanLg }) => (colStartLg && spanLg ? setPlacementInGrid('Lg') : '')}
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    ${({ colStartMd, spanMd }) => (colStartMd && spanMd ? setPlacementInGrid('Md') : '')}
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    ${({ colStartSm, spanSm }) => (colStartSm && spanSm ? setPlacementInGrid('Sm') : '')}
  }
`;

Column.defaultProps = {
  colStart: 0,
  span: 1,
  rowStart: 0,
  spanRow: 1
};

export const SideNavigationMenuContainer = styled(ScreenSize)`
  padding: 1rem 0;
  max-width: 15rem;
  position: sticky;
  top: 4.5rem;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media ${({ theme }) => theme.media.maxPhone} {
    width: 100%;
  }
`;

export const SideNavigationColumn = styled(Column)`
  min-width: 12.25rem;
`;
