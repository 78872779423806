import styled from 'styled-components';

import { PaperStyle, RegularText, StylelessLink } from 'components';

export const CapCardContainerPlaceholder = styled.div`
  max-width: calc(33% - 0.75rem);
  width: 100%;

  @media ${({ theme }) => theme.media.maxTablet} {
    max-width: 100%;
  }
`;

export const CapCardContainer = styled(CapCardContainerPlaceholder)`
  min-height: 4.5rem;

  ${PaperStyle} {
    padding: 0.25rem;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  ${StylelessLink} {
    width: 100%;
  }
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    line-height: 1.25rem;
  }

  ${RegularText} {
    font-size: 0.875rem;
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }
`;

export const InfoRow = styled.div`
  display: flex;
`;

export const IconPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeffe;
  width: 4rem;
  height: 4rem;
  border-radius: 0.875rem;
  margin-right: 1rem;

  svg {
    width: 2rem;
    height: 2rem;

    path {
      fill: ${({ theme }) => theme.colors.primary.linkText};
    }
  }
`;

export const EmptyCapCardContainer = styled.div`
  display: flex;
  max-width: 25.4375rem;
  width: 100%;
`;

export const DashboardCardContainer = styled.div`
  display: flex;
  padding-top: 1.5rem;
`;
