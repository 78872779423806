import { useEffect, useState } from 'react';
import { FieldValues, Path, UseFormGetValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Field, Dropdown, InlineLink } from 'components';
import { Toast } from 'components/toastV2';
import { navigationLinks } from 'features/application/consts/navigationLinks';
import { CompanyInputContainer } from 'features/application/forms/company/company.style';
import { COMPANY_REGISTRATION_COUNTRIES_LIST } from 'features/application/forms/company/constants';
import { ValidationHelpers } from 'hooks/forms/useFormHandlers';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

type Props<T extends FieldValues> = {
  validationHelpers: ValidationHelpers<T>;
  getValues: UseFormGetValues<T>;
  name: Path<T>;
  supportedCountries: string[];
};

const getRegistrationCountriesOptions = (language: string) =>
  COMPANY_REGISTRATION_COUNTRIES_LIST.map((isoCode) => ({
    value: isoCode.value,
    label: getCountryDisplayName(isoCode.value, language as Languages)
  }));

const getNavigationLink = (language: string) => navigationLinks.All.toExternal?.[language as Languages];

export const CountryOfRegistrationField = <T extends FieldValues>({
  validationHelpers,
  getValues,
  name,
  supportedCountries
}: Props<T>) => {
  const { t, i18n } = useTranslation();
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);
  const value = getValues(name);

  const companyRegistrationCountryOptions = getRegistrationCountriesOptions(i18n.language);
  const navigationLink = getNavigationLink(i18n.language);

  const isSupportedCountry = (value: string) => !supportedCountries.includes(value);
  const handleToast = isSupportedCountry(value);

  useEffect(() => {
    setIsToastVisible(handleToast);
  }, [handleToast, value]);

  return (
    <CompanyInputContainer>
      <Field
        Component={Dropdown}
        name={name}
        value={value}
        options={companyRegistrationCountryOptions}
        placeholder={t('placeholderCompanyCountry')}
        validationHelpers={validationHelpers}
        showValidationMessage={false}
        required
      />
      {isSupportedCountry(value) ? (
        <Toast
          isVisible={isToastVisible}
          onClose={setIsToastVisible}
          message={
            <Trans
              i18nKey="otherCountriesNotEnabled"
              components={{
                smeLink: <InlineLink href={navigationLink} target="_blank" rel="noopener noreferrer" />
              }}
            />
          }
          variant="info"
        />
      ) : null}
    </CompanyInputContainer>
  );
};
