import { FC } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Field, Fieldset, Input } from 'components';
import { FarmersDetailsFieldset } from 'features/application/forms/farmersDetails/farmersDetails.styles.ts';
import { ValidationHelpers } from 'hooks/forms/useFormHandlers.ts';

export type FarmersDetails = {
  firstName: string;
  lastName: string;
  personalCode: string;
};

type Props = {
  validationHelpers: ValidationHelpers<FarmersDetails>;
  getValues: UseFormGetValues<FarmersDetails>;
};

export const FarmersDetailsFields: FC<Props> = ({ validationHelpers, getValues }) => {
  const { t } = useTranslation();

  return (
    <FarmersDetailsFieldset>
      <Fieldset>
        <Field
          Component={Input}
          size="L"
          name="firstName"
          value={getValues('firstName')}
          placeholder={t('name')}
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={Input}
          size="L"
          name="lastName"
          value={getValues('lastName')}
          placeholder={t('lastName')}
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={Input}
          size="L"
          name="personalCode"
          value={getValues('personalCode')}
          placeholder={t('personalCode')}
          validationHelpers={validationHelpers}
          required
        />
      </Fieldset>
    </FarmersDetailsFieldset>
  );
};
