import { FieldError } from 'react-hook-form';

export type fieldValidationMeta = {
  invalid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  error?: FieldError | undefined;
};

export enum VALIDATION_MESSAGE_ID {
  REQUIRED = 'thisFieldIsRequired',
  EMAIL = 'emailIsNotValid',
  PERSONAL_CODE_LENGTH = 'invalidPersonalCode',
  PHONE_NUMBER_LENGTH = 'invalidPhoneNumber',
  MIN_AMOUNT = 'minAmountRangeError',
  MAX_AMOUNT = 'maxAmountRangeError',
  MAX_DECIMALS = 'maxDigitsAfterDecimal',
  MIN_LENGTH = 'minLengthRangeError',
  MAX_LENGTH = 'maxLengthRangeError',
  DIGITS_ONLY = 'digitsOnly',
  MAX_UPLOAD_SIZE = 'maxUploadSize',
  TERM_TO_SHORT = 'termToShort',
  TERM_TO_LONG = 'termToLong',
  ONLY_LETTERS = 'onlyLetters'
}
