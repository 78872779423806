import { useSearchParams } from 'react-router-dom';

import {
  LimitDetail,
  LimitDetailsSection
} from 'features/contracts/components/limitDetailsSection/limitDetailsSection';
import { selectChosenLoansContractInfo } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';

export const LeasingLimitsDetails = () => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contract = useLoansSelector<typeof selectChosenLoansContractInfo>(selectChosenLoansContractInfo)(
    contractId || ''
  );

  if (!contract) return null;

  const { leasingFinancedAmount, leasingOverdueAmount, repaidAmount, repaymentLeftAmount } = contract;

  const data: LimitDetail[] = [
    {
      label: 'financedAmount',
      info: 'loansFinancedAmountExplanation',
      value: leasingFinancedAmount
    },
    {
      label: 'repaymentLeft',
      info: 'loansRepaidAmountExplanation',
      value: repaymentLeftAmount
    },
    {
      label: 'repaidAmount',
      info: 'loansRepaymentLeftAmountExplanation',
      value: repaidAmount
    },
    {
      label: 'overdue',
      info: 'loansOverdueAmountExplanation',
      value: leasingOverdueAmount
    }
  ];

  return <LimitDetailsSection data={data} />;
};
