import { FC } from 'react';

import { ShimerContainer } from 'components';
import { TotalsCardContainer } from 'components/productTotalsCard';
import { ProductTotalsCard, TooltipLine } from 'components/productTotalsCard/productTotalsCard';

import { useFetchLoansTotals } from '../../hooks';

type Props = {
  loading: boolean;
  hideOnLoading?: boolean;
};

export const LeasingContractsTotalsCard: FC<Props> = ({ loading, hideOnLoading }) => {
  const {
    productTotals: totals,
    isLoading: totalsLoading,
    isError: isTotalsError,
    hasNoData
  } = useFetchLoansTotals('LEASING');

  const isLoading = totalsLoading || loading;

  const { totalOverdue = 0, totalRepaid = 0, totalRepaymentLeft = 0, totalTotal = 0 } = totals || {};

  const mappedData = {
    totalValue: totalTotal,
    value: totalRepaid,
    dangerValue: totalOverdue,
    navigateTo: '/finance/leasing/overview',
    tooltip: [
      { value: totalRepaymentLeft, label: 'repaymentLeft' },
      totalOverdue ? { value: totalOverdue, label: 'overdue', type: 'danger' } : null
    ].filter((el) => el) as TooltipLine[]
  };

  if ((isTotalsError && !isLoading) || (hasNoData && !isLoading) || (loading && hideOnLoading)) {
    return null;
  }

  return (
    <TotalsCardContainer>
      {isLoading ? <ShimerContainer overlay /> : null}
      <ProductTotalsCard {...mappedData} productName="leasing" valueLineLabel="repaid" />
    </TotalsCardContainer>
  );
};
