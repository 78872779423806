import { useTranslation } from 'react-i18next';

import { StatusChip } from 'components';
import { Button } from 'components/buttonV2';
import { StatusType } from 'design/designVariables';
import { useModalContext } from 'features/nav/context/modalContext';
import { onboardingSteps } from 'features/onboarding/consts';
import { useOnboardingRedirectHandler, useOndatoRedirectHandler } from 'features/onboarding/hooks';
import { OndatoStatusResponse, Status as OnboardingStatuses } from 'services/OnboardingService/types';

import StepCard from './stepCard';
import { StepCardText } from './stepCard.styles';

type ActionConfig = {
  label: string;
  onClick: () => unknown;
};

type Props = {
  step: 'verifyYourIdentity' | 'verifyYourBusiness';
  status: OnboardingStatuses;
  ondatoInfo: OndatoStatusResponse;
  disabled?: boolean;
  loading?: boolean;
  secondaryAction?: boolean;
  onInactiveClick?: () => unknown;
};

const statusChipTranslations: Record<NonNullable<OnboardingStatuses>, string> = {
  ONDATO_INITIALISED: '',
  ONDATO_IN_PROGRESS: '',
  ONDATO_FINISHED: 'done',
  ONDATO_FINISHED_WITH_ERROR: '',
  SME_CUSTOMER_ONBOARDING_COMPLETED: 'done',
  SME_CUSTOMER_ONBOARDING_IN_PROGRESS: 'started',
  SME_CUSTOMER_ONBOARDING_WAITING: 'inReview',
  SME_CUSTOMER_ONBOARDING_REJECTED: 'rejected',
  SME_CUSTOMER_ONBOARDING_NOT_FOUND: ''
};

const statusChipColors: Record<NonNullable<OnboardingStatuses>, keyof StatusType> = {
  ONDATO_INITIALISED: 'white',
  ONDATO_IN_PROGRESS: 'gray',
  ONDATO_FINISHED: 'green',
  ONDATO_FINISHED_WITH_ERROR: 'white',
  SME_CUSTOMER_ONBOARDING_COMPLETED: 'green',
  SME_CUSTOMER_ONBOARDING_IN_PROGRESS: 'gray',
  SME_CUSTOMER_ONBOARDING_WAITING: 'blue',
  SME_CUSTOMER_ONBOARDING_REJECTED: 'white',
  SME_CUSTOMER_ONBOARDING_NOT_FOUND: 'white'
};

type Steps = (typeof onboardingSteps)[number];

const OnboardingStepCard = ({
  step,
  disabled,
  status,
  loading,
  ondatoInfo,
  secondaryAction,
  onInactiveClick
}: Props) => {
  const { t } = useTranslation();

  const { modals } = useModalContext();

  const isDisabled = disabled ?? !status;

  const handleSuccess = (success: boolean) => {
    if (success) {
      modals?.get('ondato-intro')?.();
    }
  };

  const onVerifyBusinessClick = useOnboardingRedirectHandler({
    ondatoInfo
  });
  const [onVerifyIdentityClick, isSubmitting] = useOndatoRedirectHandler({
    ondatoInfo,
    onOndatoInitSuccess: handleSuccess
  });

  const primaryActions: Record<Steps, ActionConfig | undefined> = {
    verifyYourIdentity: {
      label: t('verifyIdentity'),
      onClick: onVerifyIdentityClick
    },
    verifyYourBusiness: {
      label: t('verifyBusiness'),
      onClick: onVerifyBusinessClick
    }
  };

  const explanationTexts: Record<Steps, string> = {
    verifyYourIdentity: '',
    verifyYourBusiness: status === 'SME_CUSTOMER_ONBOARDING_WAITING' ? t('verifyYourIdentityExplanation') : ''
  };

  const explanationText = explanationTexts[step];

  const statusChipLabel = status ? statusChipTranslations[status] : '';
  const statusColor = status ? statusChipColors[status] : 'white';

  const statusChip = statusChipLabel ? (
    <StatusChip color={statusColor}>{t(statusChipLabel)}</StatusChip>
  ) : null;

  const actionableStatuses: OnboardingStatuses[] = [
    'ONDATO_INITIALISED',
    'ONDATO_IN_PROGRESS',
    'SME_CUSTOMER_ONBOARDING_IN_PROGRESS',
    'SME_CUSTOMER_ONBOARDING_NOT_FOUND',
    'SME_CUSTOMER_ONBOARDING_REJECTED',
    'ONDATO_FINISHED_WITH_ERROR'
  ];

  const actionConfig = actionableStatuses.includes(status) ? primaryActions[step] : undefined;

  const verifyIdentityInitInProgress = step === 'verifyYourIdentity' && isSubmitting;

  const secondaryActionProps = {
    color: 'Blue',
    variant: 'StrokedWhite'
  } as const;

  const appliedStyles = secondaryAction ? secondaryActionProps : {};

  return (
    <StepCard
      onInactiveClick={onInactiveClick}
      loading={loading}
      disabled={isDisabled}
      title={t(step)}
      StatusChip={statusChip}
    >
      {explanationText ? <StepCardText>{t(explanationText)}</StepCardText> : null}
      {actionConfig ? (
        <Button
          {...appliedStyles}
          disabled={verifyIdentityInitInProgress}
          submitting={verifyIdentityInitInProgress}
          onClick={actionConfig?.onClick}
        >
          {actionConfig?.label}
        </Button>
      ) : null}
    </StepCard>
  );
};

export default OnboardingStepCard;
