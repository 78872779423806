import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { FilterPanel, Paper, Section, SectionLayout } from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { selectActiveCustomer } from 'features/auth/selectors';
import { ActsTable } from 'features/factoringInvoices/tables';
import { PAGE_SIZE, Sort } from 'features/factoringInvoices/tables/actsTable/actsTable';
import {
  statementsDefaultParams,
  StatementsFilterFields,
  StatementsFilterParams
} from 'features/factoringStatements/components';
import {
  selectActsList,
  selectActsTotalPagesCount,
  selectIsActsListLoading
} from 'features/factoringStatements/selectors';
import { fetchActsAsync } from 'features/factoringStatements/slice';
import { useAppDispatch, useAuthSelector, useFactorignStatementsSelector } from 'hooks/redux/hooks';
import { queryBuilder } from 'services/utils/queryBuilder';

export const FactoringStatements: FC = () => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [filterParamsString, setFilterParamsString] = useState('');
  const [filterParams, setFilterParams] = useState<StatementsFilterParams>({} as StatementsFilterParams);
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('date,DESC');

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const partyId = activeCustomer?.customerCrmId || '';

  useEffect(() => {
    if (partyId) {
      setFilterParams({} as StatementsFilterParams);
    }
  }, [partyId]);

  useEffect(() => {
    if (isFeatureEnabled('factoringStatements') && contractId && partyId) {
      const queryString = queryBuilder('', filterParams);

      if (filterParamsString !== queryString) {
        setFilterParamsString(queryString);
        setSelectedPage(1);
      } else {
        dispatch(
          fetchActsAsync({
            contractId: contractId,
            partyId: partyId,
            ...filterParams,
            actType: filterParams?.actType?.split(',') || undefined,
            page: `${selectedPage}`,
            size: `${PAGE_SIZE}`,
            sort: sortOrder
          })
        );
      }
    }
  }, [contractId, partyId, dispatch, filterParamsString, filterParams, selectedPage, sortOrder]);

  const actsItems = useFactorignStatementsSelector<typeof selectActsList>(selectActsList);
  const actsPages =
    useFactorignStatementsSelector<typeof selectActsTotalPagesCount>(selectActsTotalPagesCount);
  const actsListLoading =
    useFactorignStatementsSelector<typeof selectIsActsListLoading>(selectIsActsListLoading);

  const onFilterParamsChange = (params: StatementsFilterParams) => {
    setFilterParams(params);
  };

  const onChangePage = (pageNumber: number) => {
    setSelectedPage(pageNumber);
  };

  const onSortOrderChange = (sort: Sort) => {
    const { sortOrder, dataColumnId } = sort || { sortOrder: 'asc', dataColumnId: 'date' };
    setSortOrder(`${dataColumnId || 'date'},${sortOrder.toUpperCase()}`);
  };

  return (
    <SectionLayout>
      <Paper>
        <Section header={t('statements')}>
          <FilterPanel
            defaultParams={statementsDefaultParams}
            onFilterParamsChange={onFilterParamsChange}
            FilterFields={StatementsFilterFields}
          />
        </Section>
        <Section>
          <ActsTable
            activePage={selectedPage}
            items={actsItems}
            loading={actsListLoading}
            totalPages={actsPages}
            onChangePage={onChangePage}
            onSort={onSortOrderChange}
            persistNoDataContainerHeight
          />
        </Section>
      </Paper>
    </SectionLayout>
  );
};
