import styled from 'styled-components';

import checkmark from 'assets/icons/checkmark.svg';
import redX from 'assets/icons/redX.svg';
import { RadioButtonContainer, RadioButtonGroup, InfoPopup, ListItem, Typography } from 'components';

export const RoleRadioButtonGroup = styled(RadioButtonGroup)`
  margin-bottom: 1rem;

  legend {
    margin-top: 1rem;
  }

  ${RadioButtonContainer} {
    margin-top: 0.5rem;
  }
`;

export const RoleInfoPopup = styled(InfoPopup)`
  margin-left: 0.625rem;
`;

export const InfoLabelContainer = styled.div`
  display: flex;
`;

export const RoleWrapper = styled.div`
  gap: 0.25rem;
  display: flex;
  align-items: center;
`;

export const RolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1.5rem;
  gap: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.black.shades[10]};
  border-radius: 1.5rem;
`;

type ListOptionProps = {
  checked?: boolean;
};

export const ListOption = styled(ListItem).attrs(({ theme }) => ({
  avatarTheme: {
    background: theme.colors.toast.info.background,
    fill: theme.colors.base.primary.shades[100]
  }
}))<ListOptionProps>`
  border: 0.0625rem solid
    ${({ theme, checked }) =>
      checked ? theme.colors.base.primary.shades[100] : theme.colors.base.black.shades[20]};
  background-color: ${({ theme, checked }) => (checked ? theme.colors.base.primary.tints[5] : 'transparent')};
`;

export const ListOptionExplanationButton = styled.div.attrs({
  role: 'button'
})<{ icon?: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;

  &::after {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    mask-image: ${({ icon }) => `url("${icon}")`};
    mask-size: 1.5rem 1.5rem;
    mask-position: center;
    background-color: ${({ theme }) => theme.colors.base.black.tints[70]};
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled(Typography.Body).attrs({ type: 'L', weight: 'medium' })`
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const HiddenButton = styled.input.attrs({ type: 'radio' })`
  display: none;
  position: absolute;
`;

export const RoleName = styled(Typography.Header).attrs({ type: 'h6', weight: 'medium' })``;

export const PermissionDescription = styled(Typography.Body).attrs({ type: 'M', weight: 'medium' })``;

type PermissionIncludedIconProps = {
  included?: boolean;
};

export const PermissionIncludedIcon = styled.div<PermissionIncludedIconProps>`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    mask-image: ${({ included }) => `url("${included ? checkmark : redX}")`};
    mask-size: 1.25rem 1.25rem;
    mask-position: center;
    background-color: ${({ theme, included }) =>
      included ? theme.colors.base.primary.tints[80] : theme.colors.base.red.shades[100]};
    border-radius: 50%;
  }
`;
