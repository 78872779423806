import { useState, useCallback, useRef, ReactNode } from 'react';

import exclamationMark from '../../assets/icons/exclamationCircle.svg';
import { useIntersectionObserver } from '../../hooks/window';
import { LinkButton } from '../link';

import {
  ButtonWrapper,
  CloseButton,
  HeaderInTopNavigation,
  HeaderOut,
  HeaderTitle,
  NavigationButton,
  NavigationWrapper,
  OutWrapper,
  StepperContainer,
  TopNavigationContainer
} from './isolatedFlowTopNavigation.styles';

type Props = {
  header?: string;
  onBackClick?: () => unknown;
  onCloseClick?: () => unknown;
  onHelpClick?: () => unknown;
  stepper?: ReactNode;
  maxWidthInPx?: number;
};

const IsolatedFlowTopNavigation = ({
  header,
  onBackClick,
  stepper,
  onCloseClick,
  onHelpClick,
  maxWidthInPx = 540
}: Props) => {
  const outWrapperRef = useRef<HTMLDivElement>(null);
  const topbarRef = useRef<HTMLDivElement>(null);

  const [isTitleOnNavigation, setIsTitleOnNavigation] = useState(false);

  const handleTopBarIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      setIsTitleOnNavigation(!entry.isIntersecting);
    },
    [setIsTitleOnNavigation]
  );

  useIntersectionObserver(outWrapperRef.current, handleTopBarIntersection, {
    rootMargin: `-${topbarRef.current?.clientHeight ?? 0}px`
  });

  const helpIconButton = onHelpClick ? (
    <LinkButton color="Grey" iconOnly icons={{ left: exclamationMark }} onClick={onHelpClick} />
  ) : null;

  return (
    <>
      <TopNavigationContainer ref={topbarRef} isBorderVisible={isTitleOnNavigation}>
        <NavigationWrapper>
          <ButtonWrapper>
            <NavigationButton data-testid="button-back" onClick={onBackClick} />
          </ButtonWrapper>
          <HeaderInTopNavigation maxWidthInPx={maxWidthInPx} isScrolled={isTitleOnNavigation}>
            <HeaderTitle>
              <span>{header}</span> {helpIconButton}
            </HeaderTitle>
          </HeaderInTopNavigation>
          <ButtonWrapper>{onCloseClick ? <CloseButton onClick={onCloseClick} /> : null}</ButtonWrapper>
        </NavigationWrapper>
        <StepperContainer maxWidthInPx={maxWidthInPx}>{stepper}</StepperContainer>
      </TopNavigationContainer>
      <OutWrapper ref={outWrapperRef}>
        <HeaderOut maxWidthInPx={maxWidthInPx} isScrolled={isTitleOnNavigation}>
          <HeaderTitle>
            <span>{header}</span> {helpIconButton}
          </HeaderTitle>
        </HeaderOut>
      </OutWrapper>
    </>
  );
};

export default IsolatedFlowTopNavigation;
