import styled from 'styled-components';

import {
  DownloadLink,
  LinkButton,
  LinkButtonStyle,
  Paper,
  SecondaryText,
  ShimerContainer,
  Typography
} from 'components';

export const ApplicationInformation = styled(Paper)`
  flex-grow: initial;
  padding: 0;
`;

export const ContentInColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DrawerTopContent = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1.5rem 1rem;
  gap: 1rem;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.base.white.functional};

  > ${Paper} {
    padding: 1.25rem;
  }
`;

export const ProductName = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })`
  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

export const HeaderIconAndText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;

  @media ${({ theme }) => theme.media.maxTablet} {
    gap: 0.5rem;
  }
`;

export const TitleAndActionsInnerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.base.primary.tints[5]};
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;

    path {
      fill: ${({ theme }) => theme.colors.base.primary.shades[100]};
    }
  }
`;

export const MainSectionValue = styled(Typography.Body).attrs({ type: 'M' })`
  margin-left: 0.5rem;
`;
export const MainSectionTitle = styled(Typography.Body).attrs({ type: 'M' })`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.colors.base.black.shades[70]};
`;

export const MainInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

export const MainInfoSectionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const DetailsSection = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.colors.base.black.shades[5]};
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const DetailsHeader = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${LinkButtonStyle} {
    align-items: flex-end;

    svg {
      margin-right: 0;
    }
  }
`;

export const RowInputs = styled(Row)`
  display: flex;
  gap: 0.5rem;
`;

export const Line = styled.div`
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.primary.border.light};
  margin: 0.5rem 0;

  &:last-of-type {
    display: none;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding: 1.5rem;

  ${SecondaryText} {
    text-align: center;
    margin-bottom: 2rem;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    button {
      width: 100%;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

export const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  padding: 0.5rem 0 0;
  width: 100%;
  margin-top: auto;

  button {
    width: 100%;
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 12%);
  }
`;

export const ErrorStateHeader = styled(Typography.Header).attrs({ type: 'h2', weight: 'medium' })`
  margin-bottom: 1rem;
`;

export const SquareShimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })`
  border-radius: 0.5rem;
`;

export const Shimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })``;

export const ApplicationContentDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  position: relative;
  height: 100%;
  overflow: auto;
`;

export const DrawerScrolableContent = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overscroll-behavior: contain;
  padding: 0 1.25rem 1.5rem 1.5rem;
  margin-right: 0.125rem;
  height: 100%;
`;

export const StepSectionTitle = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const StepSectionValue = styled(StepSectionTitle)`
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  text-align: end;
`;

export const DeleteButton = styled(LinkButton)`
  padding: 0.75rem;
  width: 100%;
`;

export const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DownloadLinkButton = styled(DownloadLink)`
  overflow: hidden;
`;
