import styled from 'styled-components';

import { RegularText } from 'components';

export const BoldedText = styled(RegularText)`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const TitleText = styled(BoldedText)`
  text-transform: uppercase;
  display: inline-flex;
  margin: 1.5rem 0 1rem;
`;

export const HeaderText = styled(BoldedText)`
  display: flex;
  font-size: 20px;
  line-height: 28px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding-bottom: 2rem;
`;

export const TextBox = styled.div`
  height: 100%;
  width: 100%;
  overflow: visible;
`;

export const Hr = styled.div`
  color: ${({ theme }) => theme.colors.base.black.shades[10]};
  background-color: ${({ theme }) => theme.colors.base.black.shades[10]};
  border-color: ${({ theme }) => theme.colors.base.black.shades[10]};
  border-width: 0.0625rem;
  height: 0.0625rem;
  margin: 0 -2.5rem 1.5rem;

  @media ${({ theme }) => theme.media.maxDesktop} {
    margin: 0 -1.25rem 1.5rem;
  }
`;

export const OrderedList = styled.ol`
  margin: 0 0 0 1.5rem;
  padding: 0;
`;

export const OrderedListItem = styled.li`
  counter-increment: section;

  &::marker {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
  }

  & > ol > li {
    counter-increment: item;
    list-style: none;
    margin-bottom: 0.5rem;

    &::before {
      font-weight: ${({ theme }) => theme.fontWeights.semibold};
      content: counters(section, '.') '.' counters(item, '.') '. ';
    }

    & > ol > li {
      list-style: none;
      counter-increment: subitem;

      &::before {
        font-weight: ${({ theme }) => theme.fontWeights.semibold};
        content: counters(section, '.') '.' counters(item, '.') '.' counters(subitem, '.') '. ';
      }
    }
  }
`;
