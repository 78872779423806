import { getLeasingCalculatorInfo } from 'defaultConfiguration/getLeasingCalculatorInfo';
import { ApplicationState } from 'features/application/types/applicationTypes';
import { Applicant } from 'services/CapService/types';
import { queryBuilder } from 'services/utils/queryBuilder';

const BooleanToString = (value: unknown) => `${+Boolean(value)}`;

const amountValueWithCurrency = (amount?: number | string) => (amount ? `${amount}€` : `0€`);

export const getLeasingInterestRatePerMonth = (amount: number) => {
  const leasingCalculatorInfo = getLeasingCalculatorInfo();

  const { interestRates } = leasingCalculatorInfo;

  const [interestRate] =
    Object.entries(interestRates).find(
      ([, { min, max }]) => amount >= min && (max === undefined || amount <= max)
    ) || [];

  return Number(interestRate) / 100 / 12;
};

export const getResidualValueCeiling = (term: number) => {
  const leasingCalculatorInfo = getLeasingCalculatorInfo();

  const { residualValueCeiling } = leasingCalculatorInfo;

  const [residualValue] =
    Object.entries(residualValueCeiling).find(
      ([, { min, max }]) => term >= min && (max === undefined || term <= max)
    ) || [];

  return Number(residualValue);
};

export const calculateMonthlyPayment = (financedAmount: number, term: number, residualValue: number) => {
  const interestRate = getLeasingInterestRatePerMonth(financedAmount);

  /**
   * PMT function calculates monthly payment for leasing
   *
   * @param interestRate - per month
   * @param term - lease term in months
   * @param amount - financed amount got from total price - advance payment
   * @param futureValue - residual value after term
   * @param type - when payment is due (0 - end of period, 1 - beginning of period)
   * @returns monthly payment
   */
  const PMT = (
    interestRate: number, // per month
    term: number, // in months
    amount: number,
    futureValue = 0,
    type = 0
  ) => {
    if (!term) return 0;

    if (interestRate === 0) return (amount + futureValue) / term;

    const pvif = Math.pow(1 + interestRate, term);
    let pmt = (-interestRate * (amount * pvif + futureValue)) / (pvif - 1);

    if (type === 1) pmt /= 1 + interestRate;

    return pmt;
  };

  return PMT(interestRate, term, -financedAmount, residualValue);
};

export const createSMELeasingFormLink = (
  applicationData: ApplicationState['application'],
  applicant: Applicant
) => {
  const { requestedAmount, company } = applicationData.generalInfo || {};
  const { country, name, code } = company || {};
  const { firstName, lastName, phoneNumber, email } = applicant || {};
  const { term, residualValue, advancePayment, uniqueId, monthlyPayment } =
    applicationData.paymentDetails || {};
  const { marketing } = applicationData.applicationConsent || {};

  if (country === 'LT' && requestedAmount && term) {
    const leasingRedirectBaseUrl = (VARIABLES.myFinanceLeasingFormUrl || '') as string;

    const queryParams = {
      clientType: 'FA',
      vehiclePrice: amountValueWithCurrency(requestedAmount),
      period: term,
      monthPay: amountValueWithCurrency(monthlyPayment),
      email: email,
      phone: phoneNumber,
      marketingAgreement: BooleanToString(marketing),
      ID: uniqueId,
      advancePayment: amountValueWithCurrency(advancePayment),
      residualValue: amountValueWithCurrency(residualValue),
      companyName: name,
      companyCode: code,
      representativeName: firstName,
      representativeSurname: lastName
    };

    return queryBuilder(leasingRedirectBaseUrl, queryParams);
  }

  return '';
};
