import { selectActiveCompany } from 'features/applications/selectors';
import { useAppSelector } from 'hooks/redux/hooks';

import { useFetchExistingCustomerOnboardingStatusQuery } from '../api';

import useIsExistingCustomerOnboardingEnabled from './useIsExistingCustomerOnboardingEnabled';

const useTriggerExistingCustomerOnboardingStatusFetch = () => {
  const isFeatureEnabled = useIsExistingCustomerOnboardingEnabled();
  const activeCompany = useAppSelector(selectActiveCompany);

  useFetchExistingCustomerOnboardingStatusQuery(
    { cacheKey: activeCompany.id },
    { skip: !isFeatureEnabled || !activeCompany.id }
  );
};

export default useTriggerExistingCustomerOnboardingStatusFetch;
