import { RemoteConfig } from 'firebase/remote-config';
import {
  useState,
  useEffect,
  createContext,
  ReactNode,
  FC,
  SetStateAction,
  Dispatch,
  useContext
} from 'react';

import { useIsFeatureEnabled } from 'defaultConfiguration/hooks';
import { RemoteConfigSingleton } from 'services';

import { ResolvedFeaturesConfig } from '../../../configuration/features';

type RemoteConfigContextType = {
  remoteConfig: RemoteConfig | null;
  isFeatureEnabled: (featureName: keyof ResolvedFeaturesConfig) => boolean;
};

const remoteConfigInstance = RemoteConfigSingleton?.getInstance();

export const RemoteConfigContext = createContext<RemoteConfigContextType>({
  remoteConfig: null,
  isFeatureEnabled: (featureName: keyof ResolvedFeaturesConfig) => FEATURES[featureName]
});

export const useRemoteConfigContext = () => {
  return useContext(RemoteConfigContext);
};

const refreshRemoteConfigInInterval = (
  setRemoteConfig: Dispatch<SetStateAction<RemoteConfig | null>>,
  setLastRefresh: Dispatch<SetStateAction<number>>
) => {
  return setInterval(async () => {
    try {
      await remoteConfigInstance.activateLatestConfig();
      setRemoteConfig(remoteConfigInstance.remoteConfig);
      setLastRefresh(remoteConfigInstance.lastRemoteRefresh);
    } catch (e) {
      console.error(e);
    }
  }, 6000);
};

type Props = {
  children: ReactNode;
};

export const RemoteConfigContextProvider: FC<Props> = ({ children }) => {
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig | null>(null);
  const [, setLastRefresh] = useState<number>(Date.now());

  const isFeatureEnabled = useIsFeatureEnabled(remoteConfig);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (process.env.NODE_ENV !== 'test') {
      const fetchRemoteConfig = async () => {
        setRemoteConfig(remoteConfigInstance.remoteConfig);
        timer = refreshRemoteConfigInInterval(setRemoteConfig, setLastRefresh);
      };
      try {
        fetchRemoteConfig();
      } catch (err) {
        console.error(err);
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  return (
    <RemoteConfigContext.Provider value={{ remoteConfig, isFeatureEnabled }}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
