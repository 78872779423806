import { useRef, useState, useEffect } from 'react';

/**
 * Returns an intervalMs value to be used in tandem with rtk-query's pollingInterval option
 *
 *  stop polling after 18 seconds (timeoutMs) and poll every 3 seconds (intervalMs) or until pollingSwitch is set to false. See eg. bellow:
 *
 * @example
 * const pollingInterval = usePollingOptions(pollingSwitch, { timeoutMs: 18000, intervalMs: 3000 });
 *
 * @param pollingSwitch - boolean toggle to determine when to start/stop polling
 * @param options - timeoutMs: number; intervalMs: number. Defaults: { timeoutMs: 18000, intervalMs: 3000 }
 * @returns intervalMs if pollingSwitch is true, otherwise 0 (stop polling)
 */
const usePollingOptions = (
  pollingSwitch: boolean,
  options: { timeoutMs: number; intervalMs: number } = { timeoutMs: 18000, intervalMs: 3000 }
) => {
  const ondatoPollingTimeoutRef = useRef<number | null>(null);

  const [startPolling, setStartPolling] = useState(pollingSwitch);

  useEffect(() => {
    setStartPolling(pollingSwitch);

    if (pollingSwitch && !ondatoPollingTimeoutRef.current) {
      ondatoPollingTimeoutRef.current = setTimeout(() => {
        setStartPolling(false);
      }, options.timeoutMs) as unknown as number;
    }

    return () => {
      if (ondatoPollingTimeoutRef.current) {
        clearTimeout(ondatoPollingTimeoutRef.current);
        ondatoPollingTimeoutRef.current = null;
      }
    };
  }, [pollingSwitch]);

  return startPolling ? options.intervalMs : 0;
};

export default usePollingOptions;
