import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DocumentReviewCard } from 'features/contracts/components';
import { useFetchLoansConctractsDocumentsList } from 'features/loans/hooks';
import { selectChosenLoansContractInfo } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';

export const DocumentsReview: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || '';

  const contract = useLoansSelector<typeof selectChosenLoansContractInfo>(selectChosenLoansContractInfo)(
    contractId || ''
  );

  const contractDetails = {
    financierId: `${contract?.financierId || ''}`,
    customerId: `${contract?.customerId || ''}`
  };

  const { data, isFetching, isError } = useFetchLoansConctractsDocumentsList();

  const files = isError || isFetching ? [] : data?.loanDocumentsData || [];

  return <DocumentReviewCard header={t('documents')} files={files} contractDetails={contractDetails} />;
};
