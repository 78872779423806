import { Location } from 'history';
import { useLocation } from 'react-router-dom';

export type ProtectedRouteState = {
  preserveQueryParams?: boolean;
  originalLocation: Location;
  redirectToOriginalUrl?: boolean;
  fullyQualifiedLogin?: boolean;
  email?: string;
  loginMethod?: 'regular' | 'ghost';
};

export type AppLocation<T = ProtectedRouteState> = Location & { state?: T };

export const useAppLocation = <T extends ProtectedRouteState>() => {
  return useLocation() as AppLocation<T>;
};
