import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { NotificationModal } from 'components';
import { setIdentityStepUp } from 'features/auth/slice';
import { useOnboardingRedirectHandler } from 'features/onboarding/hooks';
import { useAppDispatch } from 'hooks/redux/hooks';
import { OndatoStatusResponse } from 'services/OnboardingService/types';

type Props = {
  open?: boolean;
  onVisibilityChange: (isVisible: boolean) => unknown;
  ondatoInfo: OndatoStatusResponse;
  isFarm?: boolean;
};

const ReauthenticationSuccessModal = ({ open, onVisibilityChange, ondatoInfo, isFarm }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onVerifyBusinessClick = useOnboardingRedirectHandler({ ondatoInfo });

  const onClose = () => {
    dispatch(setIdentityStepUp(false));
    onVisibilityChange(false);
  };

  const mainAction = {
    label: t(isFarm ? 'gotIt' : 'verifyBusiness'),
    handler: () => {
      !isFarm && onVerifyBusinessClick();
      onClose();
    }
  };

  return (
    <NotificationModal
      type={'success'}
      isOpen={!!open}
      informationalIcon={<Icons.LockInShield />}
      label={'youSuccessfullyLoggedIn'}
      handleClose={onClose}
      explanationText={
        isFarm ? 'youSuccessfullyLoggedInExplanationForFarm' : 'youSuccessfullyLoggedInExplanationForCompany'
      }
      mainAction={mainAction}
    />
  );
};

export default ReauthenticationSuccessModal;
