import styled from 'styled-components';

import { LinkButtonStyle } from '../link/link.styles';
import { RegularText } from '../text';

export const DownloadBadge = styled.div`
  display: flex;
  padding: 1.25rem;
  background: #f7f7f7;
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;

  svg {
    fill: ${({ theme }) => theme.colors.primary.linkText};
  }

  ${RegularText} {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  ${LinkButtonStyle} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
