import { type CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { FC, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Field, Fieldset, Input, PhoneField } from 'components';
import { CountriesDrawer } from 'features/app/components/countries';
import { selectCompanyCountry, selectIsPrefilledFromWeb } from 'features/application/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { Applicant, ApplicationConsent } from 'services/CapService/types';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';

type ApplicationFields = Applicant & ApplicationConsent;

type Props = {
  validationHelpers: ValidationHelpers<ApplicationFields>;
  getValues: UseFormGetValues<ApplicationFields>;
  isGhostUser: boolean;
  setPhoneNumber?: (value: string) => void;
};

export const ContactFields: FC<Props> = ({ getValues, validationHelpers, isGhostUser, setPhoneNumber }) => {
  const { t } = useTranslation();

  const disabled = useApplicationSelector<typeof selectIsPrefilledFromWeb>(selectIsPrefilledFromWeb);
  const companyRegistrationCountry =
    useApplicationSelector<typeof selectCompanyCountry>(selectCompanyCountry);

  const { toggleRightSideDrawer, hideSideDrawer } = useNavContext();

  const [selectedAreaCode, setSelectedAreaCode] = useState<string>(companyRegistrationCountry ?? 'LT');

  const [showAreaCodesSelector, setShowAreaCodesSelector] = useState(false);

  const { setValue } = validationHelpers;

  const handleAreaCodeSelectorClick = () => {
    if (toggleRightSideDrawer && isGhostUser) {
      toggleRightSideDrawer?.({ id: 'countries' });
    }

    setShowAreaCodesSelector(true);
  };

  const handleClose = () => {
    if (isGhostUser) {
      hideSideDrawer?.();
    }
    setShowAreaCodesSelector(false);
  };

  return (
    <>
      <CountriesDrawer
        rootDrawer={isGhostUser}
        activeIndex={1}
        open={showAreaCodesSelector}
        onClose={handleClose}
        onSelect={(value) => setSelectedAreaCode(value)}
        selectedValue={selectedAreaCode}
        searchable
        areaCodesEnabled
        drawerHeader={t('searchCountry')}
      />
      <Fieldset>
        <Field
          Component={Input}
          size="L"
          name="firstName"
          value={getValues('firstName')}
          disabled={disabled || !isGhostUser}
          placeholder={t('name')}
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={Input}
          size="L"
          name="lastName"
          value={getValues('lastName')}
          disabled={disabled || !isGhostUser}
          placeholder={t('lastName')}
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={Input}
          size="L"
          name="email"
          value={getValues('email')}
          placeholder={t('email')}
          disabled
          validationHelpers={validationHelpers}
          required
        />
        <Field
          Component={PhoneField}
          size="L"
          name="phoneNumber"
          value={getValues('phoneNumber') ?? getCountryCallingCode(selectedAreaCode as CountryCode)}
          onChange={(value: string) => {
            setValue('phoneNumber', value);
            setPhoneNumber?.(value);
          }}
          placeholder={t('phone')}
          defaultCountry={selectedAreaCode}
          onDropdownButtonClick={handleAreaCodeSelectorClick}
          disabled={disabled}
          validationHelpers={validationHelpers}
          required
        />
      </Fieldset>
    </>
  );
};
