import { FC, ReactNode } from 'react';

import { AmountText, RouterLink } from 'components';

import { Amount, ColorBox, InfoBoxContainer, InfoBoxPopup, Label, LabelContainer } from './infoBox.styles';

export type Link = {
  url: string;
  label: string;
};

type Props = {
  label: string;
  color: string;
  amount?: number;
  infoText?: string;
  link?: Link;
  children?: ReactNode;
  popupTestId?: string;
};

const InfoBox: FC<Props> = ({ label, color, amount, infoText, link, children, popupTestId }) => {
  const { url, label: linkLabel } = link || {};

  return (
    <InfoBoxContainer>
      <LabelContainer>
        <ColorBox color={color} />
        <Label>{label}</Label>
        {infoText ? <InfoBoxPopup testId={popupTestId} text={infoText} /> : null}
      </LabelContainer>
      <Amount>{amount || amount === 0 ? <AmountText>{amount}</AmountText> : null}</Amount>
      {children}
      {url ? <RouterLink to={url}>{linkLabel}</RouterLink> : null}
    </InfoBoxContainer>
  );
};

export default InfoBox;
