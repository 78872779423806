import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

import { selectActiveCompany } from 'features/applications/selectors';
import { Status } from 'services/OnboardingService/types';
import { RootState } from 'store';

import onboardingApi from './api';

const createCompanyQuestionaireStatusSelector = createSelector(
  (companyId: string) => companyId,
  (companyId) => onboardingApi.endpoints.fetchExistingCustomerOnboardingStatus.select({ cacheKey: companyId })
);

export const selectIsCompanyQuestionaireStatusLoading = createSelector(
  (state: RootState) => ({
    state,
    companyId: selectActiveCompany(state).id
  }),
  ({ state, companyId }) => createCompanyQuestionaireStatusSelector(companyId)(state).isLoading
);

export const selectCompanyQuestionaireStatus = createSelector(
  (state: RootState) => ({
    state,
    companyId: selectActiveCompany(state).id
  }),
  ({ state, companyId }) => createCompanyQuestionaireStatusSelector(companyId)(state).data
);

export const selectCompanyQuestionaireStatusFetchSucceeded = createSelector(
  (state: RootState) => ({
    state,
    companyId: selectActiveCompany(state).id
  }),
  ({ state, companyId }) => {
    const result = createCompanyQuestionaireStatusSelector(companyId)(state);

    return !result.error && result.status === QueryStatus.fulfilled && Boolean(result.data?.onboardingStatus);
  }
);

export const selectIsCompanyOnboardingNeeded = createSelector(
  [selectCompanyQuestionaireStatus, selectCompanyQuestionaireStatusFetchSucceeded],
  (companyQuestionaireStatus, succeeded) =>
    ['IN_PROGRESS', 'REJECTED', 'NOT_FOUND'].includes(companyQuestionaireStatus?.onboardingStatus ?? '') &&
    succeeded
);

export const selectExistingCustomerStatus = createSelector(
  selectCompanyQuestionaireStatus,
  (companyQuestionaireStatus): Status => {
    const { onboardingStatus, ondatoStatus } = companyQuestionaireStatus ?? {};

    if (onboardingStatus === 'COMPLETED') {
      return 'SME_CUSTOMER_ONBOARDING_COMPLETED';
    }

    if (ondatoStatus && ondatoStatus !== 'FINISHED') {
      return `ONDATO_${ondatoStatus}`;
    }

    if (onboardingStatus) {
      return `SME_CUSTOMER_ONBOARDING_${onboardingStatus}`;
    }
  }
);
