import moment from 'moment';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { Modal, RegularText } from 'components';

import { CounterContainer, Counter, IconContainer, Header } from './sessionTimeoutModal.styles';

type Props = {
  logout?: (...args: unknown[]) => unknown;
  extend?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
  timeout: number;
};

const formatTime = (value: number) => moment.utc(value).format('m:ss');

export const SessionTimeoutModal: FC<Props> = ({ isOpen, timeout, logout, extend }) => {
  const { t } = useTranslation();

  const timerRef = useRef<number>(0);
  const currentTimeRef = useRef<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(timeout);

  const handleClose = () => {
    extend?.(false);
  };

  const handleConfirmation = () => {
    logout?.();
    handleClose();
  };

  const clearTimer = () => {
    currentTimeRef.current = 0;

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = 0;
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (!timerRef.current) {
        timerRef.current = setInterval(() => {
          setCurrentTime(currentTime - 1000);
        }, 1000) as unknown as number;
      }
    } else {
      setCurrentTime(timeout);
      clearTimer();
    }

    return clearTimer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, isOpen]);

  return (
    <Modal
      variant="informational"
      testId="session-modal"
      isOpen={!!isOpen}
      sessionTimeoutModal
      closeButton={false}
      desktopFooterActionsDirection="column"
      onClose={handleConfirmation}
      mainActionLabel={t('continueSession')}
      mainActionhandler={handleClose}
      mainActionTestId="modal-session-yes-button"
      secondaryActionLabel={t('logout')}
      secondaryActionTestId="modal-session-no-button"
    >
      <IconContainer>
        <Icons.ExitDoorBackground />
      </IconContainer>
      <Header>{t('sessionTimeoutWarning')}</Header>
      <CounterContainer>
        <RegularText>{t('sessionExpires')}</RegularText>
        <Counter>{formatTime(currentTime)}</Counter>
      </CounterContainer>
    </Modal>
  );
};
