import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Loader, Paper, Section, SectionLayout, Spacings } from 'components';
import { ProductCard } from 'components/productCard';
import { Toast } from 'components/toastV2';
import {
  DashboardCardContainer,
  EmptyCapCardContainer
} from 'features/application/components/capCard/capCard.styles';
import { useFetchLoansContractsByType } from 'features/loans/hooks';
import { resetLoansState } from 'features/loans/slice';
import { LoansContracts } from 'features/loans/tables';
import { SubcategoriesNavigationBar } from 'features/nav/components';
import { useAppDispatch } from 'hooks/redux/hooks';

import { ContentContainer } from '../factoring/factoringTemplate.styles';

type Props = {
  children: ReactNode | ReactNode[];
  separateChildrenFromContracts?: boolean;
};

export const LeasingTemplate: FC<Props> = ({ children, separateChildrenFromContracts }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const [showSkeleton, setShowSkeleton] = useState(true);

  const memoizedPathname = useRef(pathname);

  const originalPathname = memoizedPathname.current;

  useEffect(() => {
    return () => {
      dispatch(resetLoansState());
    };
  }, [dispatch]);

  const { data, isLoading, isError, isUnintialized, refetch } = useFetchLoansContractsByType('LEASING');

  const noContracts = !data?.length;

  useEffect(() => {
    if (!isLoading && isError) {
      if (pathname !== originalPathname) {
        refetch();
        memoizedPathname.current = pathname;
      }
    }
  }, [isLoading, isError, pathname, originalPathname, refetch]);

  useEffect(() => {
    setTimeout(() => {
      setShowSkeleton(false);
    }, 300);
  }, []);

  if (!isLoading && isUnintialized) {
    return null;
  }

  if (isLoading) {
    return <Loader noMargin transparent />;
  }

  if (!isLoading && isError) {
    return <Toast isVisible header={t('generalErrorHeader')} message={t('generalErrorMessage')} />;
  }

  if (!isLoading && !isError) {
    if (noContracts) {
      return (
        <DashboardCardContainer>
          <EmptyCapCardContainer data-testid="empty-product-card">
            <ProductCard
              label={'getProductsExplanation'}
              link={{ to: '/dashboard/marketplace', label: 'getProducts' }}
            />
          </EmptyCapCardContainer>
        </DashboardCardContainer>
      );
    }
  }

  return (
    <>
      <SubcategoriesNavigationBar category="leasing" />
      <Spacings orientation="vertical" steps={2} />
      <ContentContainer isLoading={showSkeleton}>
        <SectionLayout>
          <Paper>
            <Section header={t('contracts')}>
              <LoansContracts items={data} loading={isLoading} />
            </Section>
            {!separateChildrenFromContracts ? children : null}
          </Paper>
        </SectionLayout>
        {separateChildrenFromContracts ? children : null}
      </ContentContainer>
    </>
  );
};
