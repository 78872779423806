import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  CurrencyInput,
  Field,
  Fieldset,
  IbanInput,
  Loader,
  Modal,
  RadioButton,
  SuccessModal,
  UploadArea,
  ValidationError
} from 'components';
import { ActionToast } from 'components/actionToast';
import { formatNumber } from 'components/text/formatters';
import { selectActiveCustomer, selectCustomerInformation, selectUserData } from 'features/auth/selectors';
import {
  useSubmitDisbursementAttachmentsRequestMutation,
  useSubmitDisbursementRequestMutation
} from 'features/loans/api';
import { selectLoansContractsTableItems } from 'features/loans/selectors';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector, useLoansSelector } from 'hooks/redux/hooks';
import { validIBANrequired } from 'validators';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import {
  AttachmentsExplanationText,
  DescriptionText,
  FieldsetHeader,
  FieldsetHeaderTitle,
  FieldsetHeaderValue,
  PurposeContainer,
  PurposeHeader
} from './requestDisbursementModal.styles';

type Props = {
  isOpen: boolean;
  onVisibilityChange: (isVisible: boolean) => unknown;
};

type DisbursmentRequest = {
  availableAmount: number;
  amount: number;
  iban: string;
  purpose: 'workingCapital' | 'investment' | 'other';
};

const validationSchema = Yup.object({
  availableAmount: Yup.number().optional(),
  amount: Yup.number()
    .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .test('amountIsToLow', 'loanDisbursementAmountIsToLow', function (amount) {
      return !!(amount && amount <= this.parent.availableAmount);
    }),
  iban: validIBANrequired,
  purpose: Yup.string().required(VALIDATION_MESSAGE_ID.REQUIRED)
});

export const RequestDisbursementModal: FC<Props> = ({ isOpen, onVisibilityChange }) => {
  const { t, i18n } = useTranslation();

  const [emailIsSending, setEmailIsSending] = useState(false);
  const [emailSendError, setEmailSendError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [attachments, setAttachments] = useState<File[]>([]);

  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const userData = useAuthSelector<typeof selectUserData>(selectUserData);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);

  const { email } = userData || {};

  const contracts = useLoansSelector<typeof selectLoansContractsTableItems>(selectLoansContractsTableItems);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);

  const initialValues = useMemo(
    () => ({ availableAmount: selectedContract?.availableAmount, amount: 0, iban: '' }),
    [selectedContract?.availableAmount]
  );

  const { validationHelpers, handleSubmit, setTouchedOnAll, reset, getValues } =
    useFormHandlers<DisbursmentRequest>(initialValues, validationSchema);

  const [triggerDisbursementRequest] = useSubmitDisbursementRequestMutation();
  const [triggerDisbursementAttachmentsRequest] = useSubmitDisbursementAttachmentsRequestMutation();

  const onSubmit = () => {
    handleSubmit(
      async (data: DisbursmentRequest) => {
        try {
          if (!emailIsSending) {
            setEmailIsSending(true);
            setEmailSendError(false);

            if (email) {
              if (data.purpose === 'investment') {
                await triggerDisbursementAttachmentsRequest({
                  contractNumber: contractId || '',
                  content: {
                    'multipart/form-data': attachments as unknown as string[]
                  }
                }).unwrap();
              }

              await triggerDisbursementRequest({
                companyCode: activeCustomer?.customerId || '',
                companyName: activeCustomer?.customerName || '',
                contractCreationDate: selectedContract?.validFromDate || '',
                contractFinancier: selectedContract?.financierShortName || '',
                contractFinancierCode: selectedContract?.financierId || '',
                contractNumber: contractId || '',
                country: activeCustomer?.customerCountryId || '',
                iban: data.iban,
                loanType: selectedContract?.type || '',
                purpose: data.purpose,
                requestAmount: data.amount,
                userEmail: customerInfo?.userId || '',
                userName: customerInfo?.userFirstName || '',
                userSurname: customerInfo?.userLastName || ''
              }).unwrap();
            }
          }

          setShowSuccessMessage(true);
        } catch (err) {
          console.error(err);
          setEmailSendError(true);
          setEmailIsSending(false);
        } finally {
          setEmailIsSending(false);
        }
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const handleClose = () => {
    setAttachments([]);
    onVisibilityChange?.(false);
    setShowSuccessMessage(false);
    setEmailSendError(false);
    reset();
  };

  const onUpload = (files: File[], validationErrors: ValidationError[]) => {
    const validFiles = files.filter((file) => !validationErrors.find((error) => error.name === file.name));

    setAttachments(validFiles);
  };

  const onDelete = (filename: string) => {
    setAttachments(attachments.filter((file) => file.name !== filename));
  };

  const onPurposeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    validationHelpers.setValue('purpose', value);
  };

  const purposeFieldState = validationHelpers.getFieldState('purpose');

  const isPurposeFieldValid = !purposeFieldState.error;

  const investmentInfoExists =
    (getValues('purpose') === 'investment' && attachments.length > 0) ||
    getValues('purpose') !== 'investment';

  return (
    <>
      <SuccessModal
        header="disbursementRequestSentSuccessHeader"
        text="disbursementRequestSentSuccessText"
        isOpen={showSuccessMessage}
        onClose={handleClose}
      />
      <Modal
        testId="request-disbursement"
        label={t('loanDisbursementRequest')}
        isOpen={isOpen && !showSuccessMessage}
        onClose={handleClose}
        mainActionDisabled={!investmentInfoExists}
        mainActionLabel={t('requestDisbursement')}
        mainActionhandler={onSubmit}
        secondaryActionLabel={t('cancel')}
      >
        <ActionToast
          isVisible={emailSendError}
          variant="error"
          message={t('generalErrorMessage')}
          onClose={() => setEmailSendError(false)}
        />
        {emailIsSending ? <Loader opaque /> : null}
        <DescriptionText>{t('disbursementRequestExplanationText')}</DescriptionText>
        <FieldsetHeader>
          <FieldsetHeaderTitle>{t('contractNumber')}</FieldsetHeaderTitle>
          <FieldsetHeaderValue>{selectedContract?.contractNo}</FieldsetHeaderValue>
        </FieldsetHeader>
        <Fieldset>
          <Field
            placeholder={t('requestAmount')}
            name="amount"
            Component={CurrencyInput}
            validationHelpers={validationHelpers}
            helpText={`${t('availableAmount')}: ${formatNumber(i18n.resolvedLanguage)(
              selectedContract?.availableAmount
            )}`}
            required
          />
          <Field
            placeholder={t('iban')}
            name="iban"
            Component={IbanInput}
            validationHelpers={validationHelpers}
            required
          />
        </Fieldset>
        <PurposeContainer>
          <PurposeHeader>{t('whatWillYouUseLoanFor')}</PurposeHeader>
          <RadioButton
            onChange={onPurposeChange}
            value={'workingCapital'}
            checked={getValues('purpose') === 'workingCapital'}
            name="purpose"
            label={t('workingCapital')}
            invalid={!isPurposeFieldValid}
          ></RadioButton>
          <RadioButton
            onChange={onPurposeChange}
            value={'investment'}
            checked={getValues('purpose') === 'investment'}
            name="purpose"
            label={t('investment')}
            invalid={!isPurposeFieldValid}
          ></RadioButton>
          <RadioButton
            onChange={onPurposeChange}
            value={'other'}
            checked={getValues('purpose') === 'other'}
            name="purpose"
            label={t('other')}
            invalid={!isPurposeFieldValid}
          ></RadioButton>
        </PurposeContainer>
        {getValues('purpose') === 'investment' ? (
          <>
            <AttachmentsExplanationText>{t('attachmentsExplanationText')}</AttachmentsExplanationText>
            <UploadArea maxFilesCount={5} onUpload={onUpload} onDelete={onDelete} />
          </>
        ) : null}
      </Modal>
    </>
  );
};
