import styled from 'styled-components';

import { Typography } from 'components';
import { Button } from 'components/buttonV2';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  align-items: center;
`;

export const RedirectView = styled(Column)`
  height: 100%;
  align-self: stretch;
  padding-top: 7rem;
  justify-content: flex-start;
`;

export const RedirectCard = styled(Column)`
  gap: 2.5rem;
  text-align: center;
  max-width: 33.75rem;
`;

export const RedirectCardContent = styled(Column)`
  gap: 1rem;
`;

export const RedirectLoaderContainer = styled(Column)`
  height: 3.375rem;
  position: relative;
`;

export const Header = styled(Typography.Header).attrs({ type: 'h3', weight: 'medium' })`
  display: inline-flex;
`;

export const Body = styled(Typography.Body)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
`;

export const ManualRedirectLink = styled(Typography.Body)`
  display: inline;
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
  line-height: 1.5rem;
  white-space: pre-wrap;
`;

export const CounterButton = styled(Button)`
  white-space: break-spaces;

  > span {
    min-width: 0.75rem;
  }
`;
