import styled from 'styled-components';

import { DownloadLink, StatusChip } from 'components';

export const BillsTableContainer = styled.div`
  div[data-column-id='paidAmount'],
  div[data-column-id='unpaidAmount'] {
    display: flex;
    justify-content: flex-end;
  }
`;

export const BillStatusChip = styled(StatusChip)``;

export const StatusChipWithDownloadContainer = styled.div`
  display: flex;

  ${BillStatusChip} {
    margin-right: 0.625rem;
  }
`;

export const InvoiceDownloadLink = styled(DownloadLink)`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.base.black.shades[50]};
    }
  }
`;
