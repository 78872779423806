import { FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Dropdown, Input } from 'components';
import { FilterPanelRow, InputContainer } from 'components/filterPanel/filterPanel.styles';
import { OptionsDrawer } from 'features/app/components/drawer';
import { camelCase } from 'utils';

import { getBillTypeTranslation } from '../../consts/billTypeTranslations';

type InputOnChangeHandler = ((value: string) => string | void) & FormEventHandler<HTMLInputElement>;

export type FilterKeys =
  | 'issueDateFrom'
  | 'issueDateTo'
  | 'dueDateFrom'
  | 'dueDateTo'
  | 'billNumber'
  | 'billType'
  | 'billStatus';

export type BillsFilterParams = Record<FilterKeys, string>;

type FilterFieldsProps = {
  onChange: (
    name: keyof BillsFilterParams,
    shouldDebounce?: boolean
  ) => (value: string | Date | null) => unknown;
  filterParams: BillsFilterParams;
  leasingSubtype?: boolean;
};

export const loansBillsFilterDefaultParams: BillsFilterParams = {
  issueDateFrom: '',
  issueDateTo: '',
  dueDateFrom: '',
  dueDateTo: '',
  billNumber: '',
  billType: '',
  billStatus: ''
};

const billTypes = [
  'ALL',
  'Loan Overdue Interest',
  'Loan Establishment Fee',
  'Loan Interest',
  'Loan Principal',
  'Loan Loan Admin Fee'
];

const leasingTypes = [
  'ALL',
  'Downpaymet',
  'Contract fee',
  'Installment',
  'Installment Principal',
  'Installment Interest',
  'Penalty Interest',
  'Full Asset Value',
  'Service Upfront'
];

const billStatus = ['ALL', 'OVERDUE', 'PAID', 'UNPAID', 'PARTIALLY_PAID'];

export const LoansBillsFilterFields: FC<FilterFieldsProps> = ({ onChange, filterParams, leasingSubtype }) => {
  const { t } = useTranslation();

  const createOption = (value: string) => ({ value, label: t(camelCase(value)) });

  const [billTypesVisible, setBillTypesVisible] = useState(false);
  const [billStatusVisible, setBillStatusVisible] = useState(false);

  const billTypeOptions = (leasingSubtype ? leasingTypes : billTypes).map((type) => ({
    value: type,
    label: t(type !== 'ALL' ? getBillTypeTranslation(type) : 'all')
  }));
  const billStatusOptions = billStatus.map((status) => createOption(status));

  const onBillTypeChange = (value: string) => {
    onChange('billType')(value);

    setTimeout(() => setBillTypesVisible(false), 150);
  };

  const onBillStatusChange = (value: string) => {
    onChange('billStatus')(value);

    setTimeout(() => setBillStatusVisible(false), 150);
  };

  return (
    <>
      <OptionsDrawer
        isOpen={billTypesVisible}
        onClose={() => setBillTypesVisible(false)}
        header={t('type')}
        onChange={onBillTypeChange}
        selectedValue={filterParams.billType}
        options={billTypeOptions}
      />
      <OptionsDrawer
        isOpen={billStatusVisible}
        onClose={() => setBillStatusVisible(false)}
        header={t('status')}
        onChange={onBillStatusChange}
        selectedValue={filterParams.billStatus}
        options={billStatusOptions}
      />
      <FilterPanelRow>
        <InputContainer>
          <Input
            required
            placeholder={t('number')}
            onChange={onChange('billNumber', true) as unknown as InputOnChangeHandler}
            value={filterParams.billNumber}
          />
        </InputContainer>
        <InputContainer>
          <Dropdown
            required
            separateFromMenu
            onClick={() => setBillTypesVisible(true)}
            placeholder={t('type')}
            onChange={onChange('billType')}
            value={filterParams.billType}
            options={billTypeOptions}
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('issueDateFrom')}
            onChange={onChange('issueDateFrom')}
            value={filterParams.issueDateFrom}
            required
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('issueDateTo')}
            onChange={onChange('issueDateTo')}
            value={filterParams.issueDateTo}
            required
          />
        </InputContainer>
      </FilterPanelRow>
      <FilterPanelRow>
        <InputContainer>
          <DatePicker
            placeholder={t('dueDateFrom')}
            onChange={onChange('dueDateFrom')}
            value={filterParams.dueDateFrom}
            required
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('dueDateTo')}
            onChange={onChange('dueDateTo')}
            value={filterParams.dueDateTo}
            required
          />
        </InputContainer>
        <InputContainer>
          <Dropdown
            required
            placeholder={t('status')}
            separateFromMenu
            onClick={() => setBillStatusVisible(true)}
            onChange={onChange('billStatus')}
            value={filterParams.billStatus}
            options={billStatusOptions}
          />
        </InputContainer>
      </FilterPanelRow>
    </>
  );
};
