import styled from 'styled-components';

import typography from 'components/typography/typography';

export const Flag = styled.span`
  font-size: 3.75rem;
  border-radius: 100%;
`;

export const ModalHeader = styled(typography.Header).attrs({ type: 'h3', weight: 'medium' })`
  text-align: center;
`;
