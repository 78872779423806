import styled from 'styled-components';

import { PersonalDataFormContainer } from 'features/auth/components/personalDataForm/personalDataForm.styles';

export const PersonalInfoUpdateContainer = styled.div`
  ${PersonalDataFormContainer} {
    margin-top: 1.25rem;
  }
`;

export const ToastContainer = styled.div`
  margin-top: 1.25rem;
`;
