import { type CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Field, PhoneField, Loader } from 'components';
import { CountriesDrawer } from 'features/app/components/countries';
import { Drawer } from 'features/app/components/drawer';
import { selectUserData } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext/';
import { PhoneVerificationModal } from 'features/users/components';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector } from 'hooks/redux/hooks';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useGetUserByEmailQuery } from '../../slice';
import useUpdatePhoneNumber from '../crudUserDrawer/hooks/useUpdatePhoneNumber';

import { PhoneInputWrapper } from './editPhoneDrawer.styles';

const validationSchema = Yup.object({
  phoneNumber: Yup.string()
    .min(11, VALIDATION_MESSAGE_ID.PHONE_NUMBER_LENGTH)
    .max(12, VALIDATION_MESSAGE_ID.PHONE_NUMBER_LENGTH)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
});

export const EditPhoneDrawer = () => {
  const { t } = useTranslation();

  const { email } = useAuthSelector<typeof selectUserData>(selectUserData);

  const { hideSideDrawer } = useNavContext();

  const { data: userData } = useGetUserByEmailQuery({ email });

  const [countriesListDrawerIsOpen, setCountriesListIsOpen] = useState(false);
  const [defaultAreaCodeCountry, setDefaultAreaCodeCountry] = useState<CountryCode>('LT');
  const [phoneNumber, setPhoneNumber] = useState(userData?.mobilePhoneNumber || '');
  const [creationStep, setCreationStep] = useState<'provideInfo' | 'verifyCreation'>('provideInfo');

  const formValues = useMemo(
    () => ({
      phoneNumber: userData?.mobilePhoneNumber || ''
    }),
    [userData?.mobilePhoneNumber]
  );

  const { validationHelpers, getValues, handleSubmit, setTouchedOnAll, reset } = useFormHandlers(
    formValues,
    validationSchema
  );

  const { updatePhoneNumber, isLoading } = useUpdatePhoneNumber(userData?.userId);

  const handleClose = () => {
    reset({ phoneNumber: userData?.mobilePhoneNumber || '' });
    setPhoneNumber(userData?.mobilePhoneNumber || '');
    setCreationStep('provideInfo');
    hideSideDrawer?.();
  };

  const handlePhoneUpdate = async () => {
    if (phoneNumber && userData?.userId) {
      try {
        await updatePhoneNumber(phoneNumber);
      } finally {
        handleClose();
      }
    }
  };

  const handleSave = () => {
    handleSubmit(
      (payload) => {
        setPhoneNumber(payload.phoneNumber);
        setCreationStep('verifyCreation');
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const handleCountrySelect = (isoCode: string) => {
    setDefaultAreaCodeCountry(isoCode as CountryCode);
  };

  return (
    <>
      <CountriesDrawer
        activeIndex={1}
        open={countriesListDrawerIsOpen}
        onClose={() => setCountriesListIsOpen(false)}
        onSelect={handleCountrySelect}
        areaCodesEnabled
        selectedValue={defaultAreaCodeCountry}
        drawerHeader={t('countryPhoneCode')}
        searchable
      />
      {isLoading ? <Loader opaque /> : null}
      <PhoneVerificationModal
        phoneNumber={phoneNumber}
        isOpen={creationStep === 'verifyCreation'}
        onClose={handleClose}
        onSuccess={handlePhoneUpdate}
      />
      <Drawer
        header={t('editPhoneNumber')}
        buttonLabel={t('save')}
        onSave={handleSave}
        onClose={handleClose}
        isSubmitting={isLoading}
        isValid
        dataTestId="edit-phone"
      >
        <PhoneInputWrapper>
          <Field
            name="phoneNumber"
            Component={PhoneField}
            defaultCountry={defaultAreaCodeCountry}
            onDropdownButtonClick={() => setCountriesListIsOpen(true)}
            validationHelpers={validationHelpers}
            placeholder={t('phone')}
            required
            value={getValues('phoneNumber') ?? getCountryCallingCode(defaultAreaCodeCountry)}
          />
        </PhoneInputWrapper>
      </Drawer>
    </>
  );
};
