import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormContainer } from 'features/application/components/formContainer';
import { useFormContext } from 'features/application/context/FormContext';
import { subproducts } from 'features/application/forms/subproducts';
import { selectProductType } from 'features/application/selectors';
import { setApplicationSubProduct } from 'features/application/slice';
import { STEPS } from 'features/application/types/applicationTypes';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { Type } from 'services/CapService/types';

import { Column, SubproductName, SubproductCard } from './selectSubproduct.styles';

type Props = {
  onNextClick: (sequence?: STEPS[]) => void;
};

export const SubproductSelect: FC<Props> = ({ onNextClick }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const productType = useApplicationSelector<typeof selectProductType>(selectProductType);

  const { setFormHandlers } = useFormContext();

  const handleSubProductSelect = (subproduct: string) => {
    dispatch(setApplicationSubProduct(subproduct));

    onNextClick();
  };

  useEffect(() => {
    setFormHandlers({
      submitHandler: undefined
    });
  }, []);

  const options = productType ? subproducts[productType as Type] : null;

  return (
    <FormContainer hiddenNavigation>
      <Column>
        {options?.map((subproduct) => (
          <SubproductCard
            data-testid={subproduct.id}
            color="Black"
            onClick={() => handleSubProductSelect(subproduct.id)}
            key={subproduct.id}
          >
            <SubproductName>{t(subproduct.label)}</SubproductName>
          </SubproductCard>
        ))}
      </Column>
    </FormContainer>
  );
};
