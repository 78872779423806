import isPropValid from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { RemoteConfigContextProvider } from 'defaultConfiguration/context';
import { GlobalStyles } from 'design/globalStyle';
import { lightTheme } from 'design/systemDesign';
import { GeneralErrorPage } from 'pages/error';
import { AppService } from 'services';
import { initUniqueSessionId } from 'utils/createHashId';

import App from './App';
import { AdminBanner } from './features/app/components/adminBanner';
import store, { persistor } from './store';

window.session = initUniqueSessionId();

const container = document.getElementById('smesp-root');
const root = createRoot(container as Element);

root.render(
  <>
    <GlobalStyles />
    <StyleSheetManager
      enableVendorPrefixes
      shouldForwardProp={(prop, elem) => {
        if (typeof elem !== 'string') {
          return true;
        }

        return isPropValid(prop);
      }}
    >
      <BrowserRouter>
        <ThemeProvider theme={lightTheme}>
          <Sentry.ErrorBoundary
            fallback={<GeneralErrorPage />}
            onError={(err, componentStack, eventId) => {
              const chunkFailedToLoad = /Loading chunk \d+ failed/.test(err?.message);

              /**
               * Reload web page automatically if chunk failed to load
               * as it indicates a new version of the app is available
               * check for retry flag to avoid infinite reload loop
               */
              if (chunkFailedToLoad && window.sessionStorage.getItem('chunkFailedToLoadRetry') !== 'true') {
                window.sessionStorage.setItem('chunkFailedToLoadRetry', 'true');
                window.location.reload();
              }

              AppService.reportCrash({
                message: err.message,
                cause: `${err.message}`,
                componentStack,
                eventId
              });
            }}
          >
            <RemoteConfigContextProvider>
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <AdminBanner />
                  <App />
                </PersistGate>
              </Provider>
            </RemoteConfigContextProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </StyleSheetManager>
  </>
);
