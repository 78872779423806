import styled from 'styled-components';

import { Fieldset, Typography } from 'components';

import { BankCardPrimaryButton } from '../bankCard/bankCard.styles';

export const Legend = styled(Typography.Body)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.tints[50]};
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  ${BankCardPrimaryButton} {
    width: 100%;
  }

  ${Fieldset} {
    width: 100%;

    ${Legend} {
      margin-bottom: 1rem;
    }
  }
`;
