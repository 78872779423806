export const sortedItemsByDate = (items: any, filterBy: string) => {
  return items
    ? [...items]?.sort((a, b) => {
        if (a[filterBy] && b[filterBy]) {
          return new Date(b[filterBy]).getTime() - new Date(a[filterBy]).getTime();
        }

        return 0;
      })
    : [];
};
