import styled from 'styled-components';

export const RelativeContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

type ContentContainerProps = {
  isLoading: boolean;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  position: relative;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  transition: opacity ${({ isLoading }) => (isLoading ? 0 : '100ms')} ease-in-out;
`;
