import { useTranslation } from 'react-i18next';

import {
  NotFoundIcon,
  NotFoundMessageContainer,
  NotFoundMessageContent,
  NotFoundSubText,
  NotFoundTitle
} from './companySwitch.styles';

const CompanyNotFound = () => {
  const { t } = useTranslation();

  return (
    <NotFoundMessageContainer>
      <NotFoundIcon />
      <NotFoundMessageContent>
        <NotFoundTitle>{t('noCompaniesFound')}</NotFoundTitle>
        <NotFoundSubText>{t('noCompaniesFoundExplanation')}</NotFoundSubText>
      </NotFoundMessageContent>
    </NotFoundMessageContainer>
  );
};

export default CompanyNotFound;
