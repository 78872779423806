import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { Status } from 'features/applications/types/applicationsTypes';
import { Status as FlowStatus } from 'services/OnboardingService/types';

import { isStepCompleted } from '../components/stepCard/capStepCard';
import { stepperSteps } from '../consts';

import useGetOnboardingEnabledConfig from './useGetOnboardingEnabledConfig';

export type Steps = (typeof stepperSteps)[number];

type useGetCurrentStepParams = {
  status: Status;
};

export type CurrentStepStatus = FlowStatus | Status;

export type UseGetCurrentStepStateReturn = [currentStep: Steps, statuses: Record<Steps, CurrentStepStatus>];

export const isStepEnabled = (currentStep: Steps, step: Steps) => {
  const currentStepIndex = stepperSteps.indexOf(currentStep);
  const stepIndex = stepperSteps.indexOf(step);

  return stepIndex <= currentStepIndex;
};

const useGetCurrentCAPStepState = ({ status }: useGetCurrentStepParams): UseGetCurrentStepStateReturn => {
  const { isFeatureEnabled } = useRemoteConfigContext();
  const isOnboardingSSOEnabled = isFeatureEnabled('onboardingSSO');

  const { ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const statuses: Record<Steps, CurrentStepStatus> = {
    requestForFinancing: status,
    acceptOffer: status === 'NOT_FILLED' ? undefined : status,
    verifyYourIdentity: undefined,
    verifyYourBusiness: undefined,
    setUpBankAccount: undefined,
    singContractAndReceiveFinancing: undefined
  };

  const capStepsCompleted = isStepCompleted('acceptOffer', status, ondatoAvailableCAPStatus);

  if (capStepsCompleted) {
    if (!isOnboardingSSOEnabled) {
      return ['verifyYourBusiness', { ...statuses, verifyYourBusiness: 'ONDATO_FINISHED' }];
    }

    return ['verifyYourIdentity', statuses];
  }

  if (status === ondatoAvailableCAPStatus) {
    return ['verifyYourIdentity', statuses];
  }

  return ['requestForFinancing', statuses];
};

export default useGetCurrentCAPStepState;
