import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import CreditLine from 'assets/images/ill_creditline.svg';
import InsightsImg from 'assets/images/insights.png';
import { Paper, RegularText, Grid, Column } from 'components';
import { Button } from 'components/buttonV2';
import { CreditScoreModal, InsuranceLimitModal } from 'features/insights/components';
import SuccessfulSubmitCard from 'features/insights/components/successfulSubmitCard/successfulSumbitCard';

import {
  Header,
  Image,
  Title,
  BulletPointsWrapper,
  BulletPointWrapper,
  ButtonWrapper
} from './insights.styles';

type InisightProps = 'creditScore' | 'insuranceLimit';

type BulletPoitProps = {
  index: InisightProps;
  bulletPoints: string[];
};

const insights: BulletPoitProps[] = [
  {
    index: 'creditScore',
    bulletPoints: [
      'getCreditScoreBulletPoint1',
      'getCreditScoreBulletPoint2',
      'getCreditScoreBulletPoint3',
      'getCreditScoreBulletPoint4'
    ]
  },
  {
    index: 'insuranceLimit',
    bulletPoints: [
      'getInsuranceLimitBulletPoint1',
      'getInsuranceLimitBulletPoint2',
      'getInsuranceLimitBulletPoint3',
      'getInsuranceLimitBulletPoint4'
    ]
  }
];

type BulletPointProps = {
  product: InisightProps;
};

const BulletPoints: FC<BulletPointProps> = ({ product }) => {
  const { t } = useTranslation();

  const insight = insights.find((insightBulletPoint) => insightBulletPoint.index === product);

  if (!insight) {
    return null;
  }

  return (
    <BulletPointsWrapper key={insight.index}>
      {insight.bulletPoints.map((bulletPoint) => {
        return (
          <BulletPointWrapper key={bulletPoint}>
            <div>
              <Icons.Checkmark />
            </div>
            <RegularText>{t(bulletPoint)}</RegularText>
          </BulletPointWrapper>
        );
      })}
    </BulletPointsWrapper>
  );
};

export const Insights = () => {
  const { t } = useTranslation();
  const [creditScoreModalOpen, setCreditScoreModalOpen] = useState(false);
  const [isInsuranceLimitModalOpen, setIsInsuranceLimitModalOpen] = useState(false);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);

  const handleGetCreditScore = () => {
    setCreditScoreModalOpen(true);
  };

  const handleGetInsuranceLimit = () => {
    setIsInsuranceLimitModalOpen(true);
  };

  return (
    <>
      {successfulSubmit ? (
        <SuccessfulSubmitCard showSuccessPage={setSuccessfulSubmit} />
      ) : (
        <>
          <CreditScoreModal
            onVisibilityChange={setCreditScoreModalOpen}
            isOpen={creditScoreModalOpen}
            showSuccessPage={setSuccessfulSubmit}
          />
          <InsuranceLimitModal
            isOpen={isInsuranceLimitModalOpen}
            onVisibilityChange={setIsInsuranceLimitModalOpen}
            showSuccessPage={setSuccessfulSubmit}
          />
          <Header>
            <RegularText>{t('insightsHeader')}</RegularText>
          </Header>
          <Grid>
            <Column span={12} colStart={1}>
              <Paper fullHeight spaceBetween>
                <div>
                  <Image src={InsightsImg} />
                  <Title>{t('getCreditScore')}</Title>
                  <BulletPoints product="creditScore" />
                </div>
                <ButtonWrapper>
                  <Button
                    variant="Filled"
                    color="Blue"
                    data-testid={'open-get-credit-score-modal-button'}
                    onClick={handleGetCreditScore}
                  >
                    {t('getReport')}
                  </Button>
                </ButtonWrapper>
              </Paper>
            </Column>
            <Column span={12} colStart={1}>
              <Paper fullHeight spaceBetween>
                <div>
                  <Image src={CreditLine} />
                  <Title>{t('getInsuranceLimit')}</Title>
                  <BulletPoints product="insuranceLimit" />
                </div>
                <ButtonWrapper>
                  <Button
                    variant="Filled"
                    color="Blue"
                    data-testid={'open-get-insurance-limit-modal-button'}
                    onClick={handleGetInsuranceLimit}
                  >
                    {t('getReport')}
                  </Button>
                </ButtonWrapper>
              </Paper>
            </Column>
          </Grid>
        </>
      )}
    </>
  );
};
