import styled from 'styled-components';

import { Typography } from 'components';

export const IconPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeffe;
  padding: 0.75rem;
  border-radius: 100%;
  margin-right: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill: ${({ theme }) => theme.colors.primary.linkText};
    }
  }
`;

type CardProp = {
  onClick?: (e: Event) => void;
};

export const Card = styled.div<CardProp>`
  cursor: pointer;
  width: 100%;
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.white.functional};
  display: flex;
  border-radius: 1rem;
  align-items: center;
  padding: 0.6875rem 1rem;
  min-height: 4.5rem;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.base.white.functional};

  &:hover {
    border: 0.0625rem solid ${({ theme }) => theme.colors.base.primary.shades[100]};
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    border: none;

    &:hover {
      border: none;
    }

    align-items: normal;
  }
`;

export const InfoRow = styled.div`
  display: flex;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProductCardContentContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};
  border-radius: 1rem;
  padding: 0.25rem;

  ${IconPlaceholder} {
    background-color: ${({ theme }) => theme.colors.base.white.functional};

    svg {
      fill: ${({ theme }) => theme.colors.base.black.shades[90]};
      width: 1.5rem;
      height: 1.5rem;

      path {
        fill: ${({ theme }) => theme.colors.base.black.shades[90]};
      }
    }
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    ${IconPlaceholder} {
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;

export const CardContent = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 1.26rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-left: 0.63rem;
  }
`;

export const CardLabel = styled.div`
  font-weight: 500;
  margin-bottom: 0.375rem;
  color: #2a2a2a;
`;

export const CardText = styled.div`
  color: #7f7f7f;
`;

export const InfoCardProductLabel = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })`
  text-align: start;
`;

export const InfoCardSubProductLabel = styled(Typography.Body).attrs({ type: 'S' })`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
  font-size: 0.875rem;
  line-height: 1.375rem;
`;
