import { FC } from 'react';

import ApplicationContentDrawer from 'features/application/components/applicationContentDrawer/applicationContentDrawer';
import { setCompanyRegistrationCountry } from 'features/application/slice';
import { DrawerInfo as ApplicationDetailsDrawer } from 'features/applications/components/applicationCard/applicationCard';
import { useNavContext } from 'features/nav/context/navContext';
import { useAppDispatch } from 'hooks/redux/hooks';

import { BeneficialOwnerDrawer, BeneficialOwnersDrawer } from '../beneficialOwner';
import CompanyRegistrationCountry from '../companyRegistrationCountry/companyRegistrationCountry';
import { ContactsDrawer } from '../contact';
import { StepExplanation, StepExplanationDrawer } from '../stepExplanation';
import { ThirdPartiesDrawer, ThirdPartyDrawer } from '../thirdParty';

type GenericDrawer = {
  drawerId:
    | 'beneficialOwner'
    | 'thirdParty'
    | 'contact'
    | 'applicationReview'
    | 'stepExplanation'
    | 'companyRegistrationSelector'
    | 'companyRegistrationSelectorWithDispatch';
};

type Implementations = {
  applicationReview: ApplicationDetailsDrawer;
  beneficialOwner: BeneficialOwnerDrawer;
  stepExplanation: StepExplanationDrawer;
  thirdParty: ThirdPartiesDrawer;
  contact: GenericDrawer;
  companyRegistrationSelector: GenericDrawer;
  companyRegistrationSelectorWithDispatch: GenericDrawer;
};

export type DrawerImplementation<TId extends GenericDrawer['drawerId']> = Implementations[TId] & {
  drawerId: TId;
};

type Props = {
  setPhoneNumber?: (value: string) => void;
};

const DrawersSwitch: FC<Props> = ({ setPhoneNumber }) => {
  const dispatch = useAppDispatch();

  const { activeDrawer } = useNavContext<GenericDrawer>();

  const onCountryOfRegistrationUpdate = (country: string) => {
    dispatch(setCompanyRegistrationCountry(country));
  };

  if (!activeDrawer) return null;

  switch (activeDrawer.drawerId) {
    case 'applicationReview':
      return <ApplicationContentDrawer />;
    case 'beneficialOwner':
      return <BeneficialOwnersDrawer />;
    case 'stepExplanation':
      return <StepExplanation />;
    case 'contact':
      return <ContactsDrawer setPhoneNumber={setPhoneNumber} />;
    case 'thirdParty':
      return <ThirdPartyDrawer />;
    case 'companyRegistrationSelector':
      return <CompanyRegistrationCountry />;
    case 'companyRegistrationSelectorWithDispatch':
      return <CompanyRegistrationCountry onSelect={onCountryOfRegistrationUpdate} />;
    default:
      return null;
  }
};

export default DrawersSwitch;
