import styled from 'styled-components';

import { InfoPopup } from 'components';

export const InfoBoxContainer = styled.div`
  box-sizing: border-box;
  min-height: 7.3125rem;
  padding: 1.375rem 1.25rem 1.25rem;
  max-width: 22rem;
  width: 100%;
  margin: 0.625rem 0;
  background-color: #f7f7f7;
  border-radius: 0.5rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 0.625rem;
`;

type ColorBoxProps = {
  color: string;
};

export const ColorBox = styled.div<ColorBoxProps>`
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
  background-color: ${({ color }) => color};
`;

export const Label = styled.div`
  color: #7f7f7f;
  font-size: 0, 875rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin-right: 0.625rem;
`;

export const Amount = styled.div`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.125rem;
  margin-bottom: 0.625rem;
`;

export const Link = styled.div`
  font-weight: 400;
  font-size: 0, 875rem;
  line-height: 1.125rem;
  color: #2b64f5;
  cursor: pointer;
`;

export const InfoBoxPopup = styled(InfoPopup)`
  display: inline-flex;
  color: blue;
  cursor: pointer;
`;
