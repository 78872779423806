import { FC } from 'react';

import { Section } from 'components';
import { LeasingLimitChartSection } from 'features/loans/components';
import { LoansUpcomingPayments } from 'features/loans/tables';

export const LeasingOverview: FC = () => {
  return (
    <>
      <Section>
        <LeasingLimitChartSection />
      </Section>
      <LoansUpcomingPayments navigateTo="/finance/leasing/bills" />
    </>
  );
};
