import styled from 'styled-components';

import { Paper, Typography } from 'components';
import { InputContainer } from 'components/input/input.style';
import { OuterTableContainer } from 'components/table/table.styles';
import { InputViewLabelMobile, InputViewTextMobile, RegularText } from 'components/text/text.styles';
import { InputText } from 'components/typography/typography.styles';

export const CellInputContainer = styled.div`
  display: flex;
  max-width: 16.5rem;
`;

export const HeaderLabel = styled(Typography.Header).attrs({ type: 'h5', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};

  @media ${({ theme }) => theme.media.maxTablet} {
    display: none;
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  ${OuterTableContainer} {
    margin-top: 1.5rem;
  }

  .mobile-list-header {
    border: none;

    ${InputText} {
      font-size: 1.125rem;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }

  .mobile-list-content {
    .mobile-list-row {
      margin-bottom: 0.5rem;
    }

    .table-cell_buyer-name {
      display: none;
    }

    .table-cell_verifier-name {
      ${InputViewLabelMobile} {
        display: none;
      }

      ${InputContainer} {
        flex: 0 0 100%;
      }

      ${InputViewTextMobile} {
        flex-direction: column;
        width: 100%;
      }

      ${CellInputContainer} {
        max-width: 100%;
      }
    }

    .table-cell_verifier-email {
      ${InputViewTextMobile} {
        overflow: hidden;

        ${RegularText} {
          overflow: hidden;
          overflow-wrap: break-word;
          text-align: end;
        }
      }
    }
  }
`;

export const BuyersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Card = styled(Paper)`
  padding: 1.5rem 2rem;
  gap: 1rem;
  border-radius: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApproverRow = styled.div`
  display: flex;
  gap: 2.5rem;
`;
