import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';

import { useFormContext } from 'features/application/context/FormContext';
import { STEPS } from 'features/application/types/applicationTypes.ts';
import { selectActiveCustomer } from 'features/auth/selectors/';
import { useAppDispatch, useApplicationSelector, useAppSelector, useAuthSelector } from 'hooks/redux/hooks/';
import { Farm } from 'services/CapService/types/';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { FormContainer } from '../../components/formContainer';
import { selectFarmCode, selectIsNewCompany } from '../../selectors';
import { setFarmersData, submitApplicationAsync } from '../../slice';

import { FormStyle } from './farmCode.style';
import { FarmCodeField } from './farmCodeField';

export const validationSchema = Yup.object({
  code: Yup.string().required(VALIDATION_MESSAGE_ID.REQUIRED)
});

const initialFarmCode: Farm = {
  code: ''
};

type Props = {
  onNextClick: (sequence?: STEPS[]) => void;
};

const FarmCodeForm: React.FC<Props> = ({ onNextClick }) => {
  const dispatch = useAppDispatch();

  const code = useApplicationSelector<typeof selectFarmCode>(selectFarmCode);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const activeCustomerFarmCode = activeCustomer?.customerId;
  const isNewCompany = useAppSelector(selectIsNewCompany);

  const initialValues = useMemo(
    () =>
      code || (activeCustomerFarmCode && !isNewCompany)
        ? { ...initialFarmCode, code: code || activeCustomerFarmCode }
        : initialFarmCode,
    [code, activeCustomerFarmCode]
  );

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers<Farm>(
    initialValues,
    validationSchema,
    'onBlur'
  );

  const { setFormHandlers } = useFormContext<Farm>();

  const submitHandler = handleSubmit(
    (data: Farm) => {
      dispatch(setFarmersData(data));
      dispatch(submitApplicationAsync());
      onNextClick();
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(
    () =>
      setFormHandlers({
        submitHandler,
        validationHelpers,
        getValues
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFormHandlers]
  );

  return (
    <FormContainer isValid={validationHelpers.isValid} onNextClick={submitHandler}>
      <FormStyle>
        <FarmCodeField validationHelpers={validationHelpers} getValues={getValues} />
      </FormStyle>
    </FormContainer>
  );
};

export default FarmCodeForm;
