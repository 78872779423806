import { MutableRefObject, useEffect } from 'react';

const checkIfRefIsInPath = (e: Event, ref: MutableRefObject<HTMLDivElement>) => {
  return ref.current && ref.current.contains(e.target as Node);
};

export const useCheckIfClickedOutside = (
  callback: (isOpen: boolean) => unknown,
  open: boolean,
  refs: MutableRefObject<HTMLDivElement>[],
  checkForExtraConditions?: () => boolean
) => {
  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      const extraConditionsPass = checkForExtraConditions ? checkForExtraConditions() : true;

      if (open && extraConditionsPass) {
        const isInPath = refs.some((ref) => checkIfRefIsInPath(e, ref));

        if (!isInPath) {
          callback(false);
        }
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => document.removeEventListener('mousedown', checkIfClickedOutside);
  }, [open]);
};
