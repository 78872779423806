import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';

import {
  UploadInvoiceDocumentResponse,
  UploadInvoiceDocumentRequest,
  GetInvoiceDocumentRequest,
  GetInvoiceDocumentResponse,
  UploadInvoicesPostRequestPayload,
  UploadInvoicesPostReponse,
  UploadInvoicesDeleteReponse,
  UploadInvoicesDeleteRequestPayload,
  UploadInvoicesDeleteByIdReponse,
  UploadInvoicesDeleteByIdRequestPayload,
  UploadInvoicesGetRequestPayload,
  UploadInvoices,
  UploadInvoicesPutRequestPayload,
  UploadInvoicesPutReponse,
  DeleteInvoiceDocumentResponse,
  DeleteInvoiceDocumentRequest,
  InvoicesDetailsGetResponse,
  InvoicesDetailsGetRequestPayload
} from 'services/InvoicesService/types';
import { axiosBaseQuery } from 'services/RestClient';
import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';

const withBaseUrl = baseUrlBuilder('invoices');

const axiosBaseQueryWithRetry = retry(axiosBaseQuery, { maxRetries: 3 });

const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: axiosBaseQueryWithRetry,
  tagTypes: ['documents', 'invoices', 'invoiceDetails', 'verifiers'],
  endpoints: (build) => ({
    getInvoiceDetails: build.query<InvoicesDetailsGetResponse, InvoicesDetailsGetRequestPayload>({
      query: (payload) => ({
        url: withBaseUrl(`/invoices/submitted/invoices/${payload.invoiceId}?contractId=${payload.contractId}`)
      }),
      providesTags: ['invoiceDetails']
    }),
    getUploadedInvoices: build.query<UploadInvoices, UploadInvoicesGetRequestPayload>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl('/invoices/aggregate/invoices'), payload)
      }),
      providesTags: ['invoices']
    }),
    getInvoicesDocumentsList: build.query<GetInvoiceDocumentResponse, GetInvoiceDocumentRequest>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl('/invoices/aggregate/invoices/documents'), payload)
      }),
      providesTags: ['documents']
    }),
    // TODO migrate verifiers logic to api slice
    // getVerifiersList: build.query<VerifiersGetResponse, VerifiersGetRequest>({
    //   query: (payload) => ({
    //     url: queryBuilder(withBaseUrl('/invoices/aggregate/invoices/verifiers'), payload)
    //   }),
    //   providesTags: ['verifiers']
    // }),
    uploadInvoicesDocuments: build.mutation<UploadInvoiceDocumentResponse, UploadInvoiceDocumentRequest>({
      query: (payload) => {
        const formData = new FormData();
        const files = payload.content['multipart/form-data'];

        if (files) {
          files.forEach((file) => {
            formData.append('document', file);
          });
        }

        return {
          url: withBaseUrl(`/invoices/aggregate/invoices/documents?contractId=${payload.contractId}`),
          method: 'POST',
          data: formData
        };
      },
      invalidatesTags: ['documents']
    }),
    uploadInvoices: build.mutation<UploadInvoicesPostReponse, UploadInvoicesPostRequestPayload>({
      query: (payload) => ({
        url: withBaseUrl(`/invoices/aggregate/invoices`),
        method: 'POST',
        data: payload
      }),
      invalidatesTags: ['invoices', 'documents', 'verifiers']
    }),
    updateInvoice: build.mutation<UploadInvoicesPutReponse, UploadInvoicesPutRequestPayload>({
      query: (payload) => ({
        url: withBaseUrl(`/invoices/aggregate/invoices/${payload.id}`),
        method: 'PUT',
        data: payload
      }),
      invalidatesTags: ['invoices', 'documents', 'verifiers']
    }),
    uploadInvoiceDocument: build.mutation<UploadInvoiceDocumentResponse, UploadInvoiceDocumentRequest>({
      query: (payload) => {
        const formData = new FormData();
        const files = payload.content['multipart/form-data'];

        if (files) {
          files.forEach((file) => {
            formData.append('document', file);
          });
        }

        return {
          url: withBaseUrl(`/invoices/aggregate/invoices/documents?contractId=${payload.contractId}`),
          method: 'POST',
          data: formData
        };
      },
      invalidatesTags: ['documents']
    }),
    deleteInvoiceDocument: build.mutation<DeleteInvoiceDocumentResponse, DeleteInvoiceDocumentRequest>({
      query: (payload) => ({
        url: withBaseUrl(
          `/invoices/aggregate/invoices/documents/${payload.fileName}?contractId=${payload.contractId}`
        ),
        method: 'DELETE'
      }),
      invalidatesTags: ['documents', 'invoices']
    }),
    deleteInvoices: build.mutation<UploadInvoicesDeleteReponse, UploadInvoicesDeleteRequestPayload>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl(`/invoices/aggregate/invoices`), payload),
        method: 'DELETE'
      }),
      invalidatesTags: ['documents', 'invoices', 'verifiers']
    }),
    deleteInvoiceById: build.mutation<
      UploadInvoicesDeleteByIdReponse,
      UploadInvoicesDeleteByIdRequestPayload
    >({
      query: (payload) => ({
        url: withBaseUrl(`/invoices/aggregate/invoices/${payload.invoiceId}`),
        method: 'DELETE'
      }),
      invalidatesTags: ['documents', 'invoices', 'verifiers']
    })
  })
});

export const {
  useGetUploadedInvoicesQuery,
  useLazyGetUploadedInvoicesQuery,
  useGetInvoicesDocumentsListQuery,
  useUploadInvoicesDocumentsMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceByIdMutation,
  useDeleteInvoicesMutation,
  useUploadInvoicesMutation,
  useUploadInvoiceDocumentMutation,
  useDeleteInvoiceDocumentMutation,
  useGetInvoiceDetailsQuery
} = invoicesApi;

export const { resetApiState: resetInvoicesApiState } = invoicesApi.util;

export default invoicesApi;
