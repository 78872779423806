import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PercentageText, RegularText, StatusChip, transformDateToLocalizedString } from 'components';
import { StatusType } from 'design/designVariables';
import { selectLoansContractList } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';
import { LoanContractStatus } from 'services/LoansService/types';
import { camelCase } from 'utils';

import { RequestDisbursementButton } from '../requestDisbursementButton/requestDisbursementButton';

import {
  DetailsAmount as AmountText,
  AssetInformation,
  ChipText,
  ContractDetailsSection,
  ContractDetailsSectionHeader,
  ContractInformation,
  ContractInformationCard,
  ContractInformationSection,
  ContractInformationSet,
  Label,
  SectionHeaderText
} from './loansContractDetails.styles';

type StatusToColorMap = Record<LoanContractStatus, keyof StatusType>;

const statusToColorMap: StatusToColorMap = {
  UNKNOWN_STATUS: 'white',
  LOST_RECOVERY: 'red',
  ACTIVE_NOT_DISBURSED: 'yellow',
  ACTIVE: 'green',
  SHORT_FALL: 'red'
};

export const withNullCheck = (value?: number | string) => (value || value === 0 ? value : '-');

export const LeasingContractDetails = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);

  return (
    <ContractInformationCard data-testid="contract-details">
      <ContractInformationSection
        data-testid="contract-details-information-section"
        colorName={statusToColorMap[selectedContract?.contractStatus || 'UNKNOWN_STATUS']}
      >
        <ContractDetailsSectionHeader>
          <SectionHeaderText>{t('contractInfo')}</SectionHeaderText>
          <StatusChip color="white">
            <ChipText>{t(camelCase(withNullCheck(selectedContract?.contractStatus) as string))}</ChipText>
          </StatusChip>
        </ContractDetailsSectionHeader>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('type')}</Label>
            <RegularText>{t('leasing')}</RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('asset')}</Label>
            <AssetInformation>{withNullCheck(selectedContract?.assetDescription)}</AssetInformation>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('serialNo')}</Label>
            <RegularText>{withNullCheck(selectedContract?.assetVin)}</RegularText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('financier')}</Label>
            <RegularText>{withNullCheck(selectedContract?.financierShortName)}</RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('validFrom')}</Label>
            <RegularText>
              {withNullCheck(transformDateToLocalizedString(selectedContract?.validFromDate))}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('validTo')}</Label>
            <RegularText>
              {withNullCheck(transformDateToLocalizedString(selectedContract?.validToDate))}
            </RegularText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <RequestDisbursementButton />
          </ContractInformation>
        </ContractInformationSet>
      </ContractInformationSection>
      <ContractDetailsSection>
        <ContractDetailsSectionHeader>
          <SectionHeaderText>{t('contractDetails')}</SectionHeaderText>
        </ContractDetailsSectionHeader>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('interestType')}</Label>
            <RegularText>{t(camelCase(withNullCheck(selectedContract?.interestType) as string))}</RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('interest')}</Label>
            <PercentageText>{withNullCheck(selectedContract?.interestRate)}</PercentageText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('defaultInterest')}</Label>
            <PercentageText>{withNullCheck(selectedContract?.penalties)}</PercentageText>
          </ContractInformation>
        </ContractInformationSet>
        {selectedContract?.interestType === 'Variable' && (
          <ContractInformationSet>
            <ContractInformation>
              <Label>{t('variableInterest')}</Label>
              <PercentageText>{withNullCheck(selectedContract?.variableInterest)}</PercentageText>
            </ContractInformation>
            <ContractInformation>
              <Label>{t('totalInterest')}</Label>
              <PercentageText>{withNullCheck(selectedContract?.totalInterest)}</PercentageText>
            </ContractInformation>
            <ContractInformation>
              <Label>{t('interestChangeDate')}</Label>
              <RegularText>
                {withNullCheck(transformDateToLocalizedString(selectedContract?.interestChangeDate))}
              </RegularText>
            </ContractInformation>
          </ContractInformationSet>
        )}
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('financedAmount')}</Label>
            <AmountText>{withNullCheck(selectedContract?.leasingFinancedAmount)}</AmountText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('assetPrice')}</Label>
            <AmountText>{withNullCheck(selectedContract?.assetPriceIncVat)}</AmountText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('advanceAmount')}</Label>
            <AmountText>{withNullCheck(selectedContract?.fullDownPaymentAmount)}</AmountText>
          </ContractInformation>
        </ContractInformationSet>
      </ContractDetailsSection>
    </ContractInformationCard>
  );
};
