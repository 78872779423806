import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
  LoansContractDocumentsReponse,
  LoansContractDocumentsRequest,
  LoansDisbursementRequest,
  LoansDisbursementResponse,
  LoansDisbursementUploadAttachmentsRequest,
  LoansDisbursementUploadAttachmentsResponse,
  LoansSchedulesGetReponse,
  LoansSchedulesGetRequestPayload,
  LoansTotalsGetReponse,
  LoansTotalsGetRequestPayload
} from 'services/LoansService/types';
import { axiosBaseQuery } from 'services/RestClient/axiosBaseQuery';
import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';

const withBaseUrl = baseUrlBuilder('loans');

const loansApi = createApi({
  reducerPath: 'loansApi',
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    getTotals: build.query<LoansTotalsGetReponse, LoansTotalsGetRequestPayload>({
      query: (payload) => ({ url: queryBuilder(withBaseUrl('/loans/totals'), payload) })
    }),
    getSchedules: build.query<LoansSchedulesGetReponse, LoansSchedulesGetRequestPayload>({
      query: (payload) => ({ url: queryBuilder(withBaseUrl('/loans/schedules'), payload) })
    }),
    getContractDocumentsList: build.query<LoansContractDocumentsReponse, LoansContractDocumentsRequest>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl(`/loans/documents`), {
          contractNo: payload.contractNo,
          financierId: payload.financierId,
          customerId: payload.customerId
        })
      }),
      keepUnusedDataFor: 0
    }),
    submitDisbursementRequest: build.mutation<LoansDisbursementResponse, LoansDisbursementRequest>({
      query: (payload) => ({
        method: 'POST',
        url: withBaseUrl('/user-requests/disbursement/'),
        data: payload
      })
    }),
    submitDisbursementAttachmentsRequest: build.mutation<
      LoansDisbursementUploadAttachmentsResponse,
      LoansDisbursementUploadAttachmentsRequest
    >({
      query: (payload) => {
        const formData = new FormData();
        const files = payload.content['multipart/form-data'];

        if (files) {
          files.forEach((file) => {
            formData.append('document', file);
          });
        }

        return {
          method: 'POST',
          url: withBaseUrl(
            `/user-requests/disbursement/attachments?contractNumber=${payload.contractNumber}`
          ),
          data: formData
        };
      }
    })
  }),
  keepUnusedDataFor: 15
});

export const {
  useGetTotalsQuery,
  useGetSchedulesQuery,
  useGetContractDocumentsListQuery,
  endpoints,
  useSubmitDisbursementRequestMutation,
  useSubmitDisbursementAttachmentsRequestMutation
} = loansApi;
export const { resetApiState: resetLoansApiState } = loansApi.util;

export default loansApi;
