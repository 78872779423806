import { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components';
import { useMaintenanceMode } from 'features/nav/hooks';
import { useMaintenanceDate } from 'pages/error/hooks';

import Icons from '../../assets/icons';
import UpsImage from '../../assets/images/oopsUnderConstruction.svg';
import { RouterLink } from '../link';
import { Paper } from '../paper';
import { Popup } from '../popup';
import { RegularText, SecondaryText } from '../text';

import {
  Row,
  Column,
  RightColumn,
  ProductTotalsCardContainer,
  Amount,
  TotalsLine,
  ValueLine,
  ValueDangerLine,
  MaintenanceCardContent,
  MaintenanceCardContainer
} from './productTotalsCard.styles';

export type TooltipLine = {
  value: number;
  label: string;
  type?: string;
};

type Product = 'loans' | 'creditline' | 'leasing' | 'rbf' | 'factoring';

type Props = {
  productName: Product;
  navigateTo: string;
  valueLineLabel: string;
  totalValue: number;
  value: number;
  dangerValue?: number;
  tooltip?: TooltipLine[];
  setUsedLineWidth?: (used: number) => number;
  setDangerLineWidth?: (danger: number) => number;
};

export const ProductTotalsCard: FC<Props> = ({
  productName,
  valueLineLabel,
  totalValue,
  value,
  navigateTo,
  tooltip,
  setUsedLineWidth,
  setDangerLineWidth,
  dangerValue = 0
}) => {
  const { t } = useTranslation();
  const isInMaintenanceMode = useMaintenanceMode();

  const coreSystem = productName === 'factoring' ? 'goFinance' : 'navision';

  const [isInMaintenance, , maintenanceInfo] = isInMaintenanceMode([productName, coreSystem]);

  const formatedDate = useMaintenanceDate(maintenanceInfo?.date);

  const usedValueWidth = setUsedLineWidth ? setUsedLineWidth(value) : (100 * value) / totalValue;
  const dangerValueWidth = setDangerLineWidth
    ? setDangerLineWidth(dangerValue)
    : (100 * dangerValue) / totalValue;

  const calculateTooltipOffset: OffsetsFunction = ({ popper }) => {
    const { height } = popper;
    const topPaperPaddingInPx = 24;

    return [0, -(height + topPaperPaddingInPx / 2) / 2];
  };

  if (isInMaintenance) {
    return (
      <MaintenanceCardContainer>
        <Paper>
          <Row>
            <MaintenanceCardContent>
              <RegularText>{t(productName)}</RegularText>
              <Typography.Header type="h5" weight="medium">
                {t('currentlyUpdating')}
              </Typography.Header>
              <Typography.Body type="S">
                {formatedDate
                  ? t('maintenanceBreakMessageTimed', { date: formatedDate })
                  : t('maintenanceBreakMessageAproximate')}
              </Typography.Body>
            </MaintenanceCardContent>
            <img src={UpsImage} />
          </Row>
        </Paper>
      </MaintenanceCardContainer>
    );
  }

  return (
    <ProductTotalsCardContainer data-testid={`totals-card-${productName}`}>
      <Paper>
        <Row>
          <RegularText>{t(productName)}</RegularText>
          <RouterLink to={navigateTo}>
            {t('seeAll')} <Icons.ChevronRight />
          </RouterLink>
        </Row>
        <Popup
          fullWidth
          containerOffset={calculateTooltipOffset}
          placement="top"
          showArrow={false}
          showOnHover
          button={
            <TotalsLine>
              <ValueLine widthInPct={Math.round(usedValueWidth > 100 ? 100 : usedValueWidth)} />
              {dangerValue ? (
                <ValueDangerLine
                  startingLine={!usedValueWidth}
                  widthInPct={Math.round(dangerValueWidth > 100 ? 100 : dangerValueWidth)}
                />
              ) : null}
            </TotalsLine>
          }
        >
          {tooltip?.map((row) => (
            <Row key={row.label}>
              <Column type={row.type}>
                <SecondaryText>{t(row.label)}</SecondaryText>
                <RegularText>
                  <Amount>{row.value}</Amount>
                </RegularText>
              </Column>
            </Row>
          ))}
        </Popup>
        <Row>
          <Column>
            <SecondaryText>{t(valueLineLabel)}</SecondaryText>
            <RegularText>
              <Amount>{value}</Amount>
            </RegularText>
          </Column>
          <RightColumn>
            <SecondaryText>{t('total')}</SecondaryText>
            <RegularText>
              <Amount>{totalValue}</Amount>
            </RegularText>
          </RightColumn>
        </Row>
      </Paper>
    </ProductTotalsCardContainer>
  );
};
