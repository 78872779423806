import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';

import { RestClient } from '../RestClient';

import { RequestUpdateUserPID, ResponseUpdateUserPID } from './typings';

const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('user-update');

export class UserUpdateService {
  static updatePID(payload: RequestUpdateUserPID) {
    const url = withBaseUrl('/user-update');

    return restClient.POST<RequestUpdateUserPID, ResponseUpdateUserPID>(url, payload);
  }
}
