import styled, { css } from 'styled-components';

export type ScreenSizeProps = {
  desktopXL?: boolean;
  desktop?: boolean;
  tablet?: boolean;
  mobile?: boolean;
  display?: 'flex' | 'block';
  fullWidth?: boolean;
  fullHeight?: boolean;
  justifyContent?: 'flex-start' | 'flex-end';
};

export const ScreenSize = styled.div<ScreenSizeProps>`
  display: ${({ desktopXL, display }) => (desktopXL ? display : 'none')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  justify-content: ${({ justifyContent }) => justifyContent};

  @media ${({ theme }) => theme.media.maxDesktop} {
    display: ${({ desktop, display }) => (desktop ? display : 'none')};
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    display: ${({ tablet, display }) => (tablet ? display : 'none')};
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    display: ${({ mobile, display }) => (mobile ? display : 'none')};
  }
`;

ScreenSize.defaultProps = {
  display: 'block'
};

type SpacingsProps = {
  orientation: 'horizontal' | 'vertical';
  steps?: number;
};

export const Spacings = styled.div<SpacingsProps>`
  display: flex;
  ${({ orientation, steps }) =>
    orientation === 'horizontal'
      ? css`
          width: ${(steps || 1) * 24}px;
        `
      : css`
          height: ${(steps || 1) * 24}px;
        `}
`;
