/* eslint-disable @typescript-eslint/no-explicit-any */
let timer = 0;

export const debounce = (func: (...args: any[]) => unknown, timeout = 300) => {
  return (...args: any[]) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(args);
    }, timeout) as unknown as number;
  };
};
