import { AxiosResponse } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountText, Column, DownloadLink, InputView, RegularText, SecondaryText } from 'components';
import {
  InvoicePaymentCollection,
  InvoicePayment,
  PaymentType
} from 'services/FactoringStatementsService/types';
import { camelCase } from 'utils';

import {
  InvoiceDetailsCard,
  HeaderContainer,
  Title,
  TransactionDetails,
  TotalsSection,
  GroupedItems
} from './invoiceDetails.styles';

type Props = {
  payments: InvoicePaymentCollection;
  onClick?: (actId: string) => Promise<AxiosResponse<any, any>> | undefined;
};

const byDate = (a: InvoicePayment, b: InvoicePayment) => {
  if (b.paymentCollectionDate && a.paymentCollectionDate) {
    return new Date(b.paymentCollectionDate).getTime() - new Date(a.paymentCollectionDate).getTime();
  }

  return 0;
};

export const InvoiceDetailsPayments: FC<Props> = ({ payments, onClick }) => {
  const { t } = useTranslation();

  const onClickHandler = (actId: string) => async () => {
    return onClick?.(actId);
  };

  const sortedItems = [...(payments?.invoicePaymentReceipts || [])].sort(
    (a: InvoicePayment, b: InvoicePayment) => {
      return byDate(a, b);
    }
  );

  const groupByCategory = sortedItems.reduce(
    (group: { [key: string | 'null']: InvoicePayment[] }, payment) => {
      const { paymentType } = payment;
      if (paymentType !== undefined) {
        const keyValue: PaymentType | 'null' = paymentType === null ? 'null' : paymentType;

        if (!group[keyValue]) {
          group[keyValue] = [];
        }

        group[keyValue].push(payment);
      }
      return group;
    },
    {}
  );

  return (
    <InvoiceDetailsCard>
      <HeaderContainer>
        <Title>{t('myPayments')}</Title>
      </HeaderContainer>
      {sortedItems.length ? (
        <>
          <Column>
            {Object.entries(groupByCategory).map(([type, payments]) => (
              <GroupedItems>
                {type !== 'null' ? <SecondaryText>{t(camelCase(type || ''))}</SecondaryText> : null}
                {payments.map((payment) => {
                  return (
                    <TransactionDetails key={payment.id}>
                      <RegularText>{payment.paymentCollectionDate}</RegularText>
                      <DownloadLink fileRequest={onClickHandler(payment.accountsReceivableActId || '')}>
                        {t('view')}
                      </DownloadLink>
                      <AmountText
                        className="payout-amount"
                        currency={payment.invoiceCollectedAmount?.currency}
                      >
                        {payment.invoiceCollectedAmount?.amount}
                      </AmountText>
                    </TransactionDetails>
                  );
                })}
              </GroupedItems>
            ))}
          </Column>
          <TotalsSection>
            <InputView orientation="horizontal" label={t('total')}>
              <AmountText
                className="payouts-amount"
                currency={payments.totalInvoiceCollectedAmount?.currency}
              >
                {payments.totalInvoiceCollectedAmount?.amount}
              </AmountText>
            </InputView>
          </TotalsSection>
        </>
      ) : (
        <SecondaryText>{t('emptyInvoiceStatementsState', { item: t('yourTransactions') })}</SecondaryText>
      )}
    </InvoiceDetailsCard>
  );
};
