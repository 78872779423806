import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { LinkButton } from '../link/linkButton';
import { Typography } from '../typography';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};
`;

export type TopBarContainerProps = {
  variant?: 'default' | 'inner';
  alignment?: 'left' | 'right';
};

export const LogoButton = styled(NavLink)`
  padding-bottom: 1.5rem;

  img {
    height: 1.5rem;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    padding-bottom: 0;

    img {
      height: 1.25rem;
    }
  }
`;

export const Header = styled(Typography.Header).attrs({ type: 'h2' })`
  display: inline-flex;
  max-width: 35.75rem;
  width: 100%;
  margin-bottom: 1.5rem;
  padding-inline: 1rem;

  @media ${({ theme }) => theme.media.minPhone} {
    display: none;
  }
`;

export const TopBarContainer = styled(Column)<TopBarContainerProps>`
  flex-direction: ${({ variant }) => (variant === 'inner' ? 'row' : 'column')};
  justify-content: ${({ variant }) => (variant === 'inner' ? 'space-between' : 'unset')};
  gap: 1rem;
  padding: ${({ variant }) => (variant === 'inner' ? '1rem 1.5rem' : 'unset')};

  ${Header} {
    max-width: 31.25rem;
    margin-bottom: 0;
    padding-inline: 0;
    display: inline-flex;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    flex-direction: row;
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
    justify-content: space-between;

    ${LogoButton} {
      height: 1.25rem;
    }
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    ${Header} {
      display: none;
    }
  }
`;

export const TopBarCloseButton = styled(LinkButton)`
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.colors.base.black.shades[10]};
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
