import styled from 'styled-components';

import { Button } from 'components/buttonV2';

type Props = {
  center?: boolean;
};

export const FormHeading = styled.h2<Props>`
  width: 100%;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  font-size: ${({ theme }) => theme.fontSizes.h2};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.primary.headerColor};
`;

export const FormSubheading = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.h4};
  text-align: center;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.primary.subHeaderColor};
  margin: 0 0 2rem;
`;

export const FormButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
