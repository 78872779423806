import { isBefore, addYears } from 'date-fns';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Modal,
  Fieldset,
  Field,
  DatePicker,
  TextArea,
  UploadArea,
  transformDateToLocalizedString,
  UploadAreaElement,
  Loader,
  SuccessModal,
  ToastLegacy
} from 'components';
import { formatFullDateISO } from 'components/datePicker/utils';
import { selectCustomerInformation } from 'features/auth/selectors';
import { useRequestContractProlongMutation } from 'features/contracts/api';
import { selectContractDetails } from 'features/contracts/selectors';
import { useLazyGetUserByEmailQuery } from 'features/users/slice';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector, useContractSelector } from 'hooks/redux/hooks';
import { ContractsService } from 'services';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import {
  DocumentsExplanatoryList,
  DocumentsUploadHeader,
  FieldsetHeader,
  FieldsetHeaderTitle,
  FieldsetHeaderValue,
  ToastContainer
} from './prolongContractModal.styles';

type ProlongContractDueDateRequest = {
  currentDueDate: Date;
  dueDate: string;
  comment: string;
};

const validationSchema = Yup.object({
  currentDueDate: Yup.date().optional(),
  dueDate: Yup.date()
    .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .test('', 'dueDateCannotBeBeforeCurrentDueDate', function (value) {
      const { currentDueDate } = this.parent;
      const isNotValid =
        !value ||
        !currentDueDate ||
        isBefore(new Date(value), new Date(currentDueDate)) ||
        transformDateToLocalizedString(value) === transformDateToLocalizedString(currentDueDate);

      return !isNotValid;
    }),
  comment: Yup.string()
    .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .min(1, VALIDATION_MESSAGE_ID.MIN_LENGTH)
    .max(200, VALIDATION_MESSAGE_ID.MAX_LENGTH)
});

type Props = {
  isOpen: boolean;
  onVisibilityChange: (isVisible: boolean) => unknown;
};

export const ProlongContractModal: FC<Props> = ({ isOpen, onVisibilityChange }) => {
  const { t } = useTranslation();

  const uploadAreaRef = useRef<UploadAreaElement>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const selectedContract = useContractSelector<typeof selectContractDetails>(selectContractDetails);
  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);

  const isBeforToday =
    selectedContract.contractTerm?.endDate &&
    isBefore(new Date(selectedContract.contractTerm?.endDate), new Date());

  const initialValues = useMemo(
    () => ({
      currentDueDate: selectedContract.contractTerm?.endDate
        ? new Date(selectedContract.contractTerm?.endDate)
        : new Date('1901-01-01'),
      dueDate: isBeforToday
        ? transformDateToLocalizedString(addYears(new Date(), 1))
        : selectedContract.contractTerm?.endDate
          ? transformDateToLocalizedString(addYears(new Date(selectedContract.contractTerm?.endDate), 1))
          : '',
      comment: ''
    }),
    [selectedContract.contractTerm?.endDate]
  );

  const { validationHelpers, reset, handleSubmit, setTouchedOnAll, getValues } =
    useFormHandlers<ProlongContractDueDateRequest>(initialValues, validationSchema);

  const [triggerProlongRequest] = useRequestContractProlongMutation();
  const [triggerUserInfoRequest] = useLazyGetUserByEmailQuery();

  const handleClose = () => {
    onVisibilityChange?.(false);
    setShowSuccessMessage(false);
    reset(initialValues);
  };

  const onSubmit = () => {
    handleSubmit(
      async (data) => {
        if (uploadAreaRef.current) {
          if (uploadAreaRef.current.isValid()) {
            try {
              if (!isSubmitting) {
                setIsSubmitting(true);
                setIsError(false);

                if (customerInfo?.userId && selectedContract.id) {
                  const { data: userInfo } = await triggerUserInfoRequest({ email: customerInfo?.userId });

                  const fileArray = uploadAreaRef.current.getFiles();

                  if (fileArray && fileArray.length > 0) {
                    const formData = new FormData();

                    fileArray.forEach((file) => {
                      formData.append('files', file);
                    });

                    await ContractsService.uploadContractDocuments({
                      contractId: selectedContract.id,
                      content: {
                        'multipart/form-data': formData
                      }
                    });
                  }

                  await triggerProlongRequest({
                    contractId: selectedContract.id,
                    mobilePhoneNumber: userInfo?.mobilePhoneNumber,
                    newDueContractDate: formatFullDateISO(new Date(data.dueDate)),
                    reasonForIncrease: data.comment,
                    userCountry: userInfo?.userCountry,
                    userName: userInfo?.firstName,
                    userSurname: userInfo?.lastName
                  }).unwrap();

                  setShowSuccessMessage(true);
                } else {
                  throw new Error('User not found');
                }
              }
            } catch (e) {
              setIsError(true);
            } finally {
              setIsSubmitting(false);
            }
          }
        }
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  return (
    <>
      <SuccessModal
        header="prolongContractRequestSentSuccessHeader"
        text="prolongContractRequestSentSuccessText"
        isOpen={showSuccessMessage}
        onClose={handleClose}
      />
      <Modal
        testId="prolong-contract-due-date"
        label={t('prolongContractDueDate')}
        isOpen={isOpen && !showSuccessMessage}
        onClose={handleClose}
        mainActionLabel={t('submit')}
        mainActionhandler={onSubmit}
      >
        {isError ? (
          <ToastContainer>
            <ToastLegacy isVisible message={t('generalErrorMessage')} />
          </ToastContainer>
        ) : null}
        {isSubmitting ? <Loader opaque /> : null}
        <FieldsetHeader>
          <FieldsetHeaderTitle>{t('contractNumber')}</FieldsetHeaderTitle>
          <FieldsetHeaderValue>{selectedContract?.contractNumber}</FieldsetHeaderValue>
        </FieldsetHeader>
        <Fieldset>
          <Field
            placeholder={t('newContractDueDate')}
            name="dueDate"
            Component={DatePicker}
            disablePast
            value={transformDateToLocalizedString(getValues('dueDate'))}
            validationHelpers={validationHelpers}
            helpText={`${t('currentContractDueDate')}: ${
              selectedContract.contractTerm?.endDate as unknown as string[]
            }`}
            required
          />
          <Field
            placeholder={t('reasonForProlongingContract')}
            name="comment"
            Component={TextArea}
            maxLength={200}
            validationHelpers={validationHelpers}
            value={getValues('comment')}
            counter
            required
          />
        </Fieldset>
        <DocumentsUploadHeader>{t('supportingDocuments')}</DocumentsUploadHeader>
        <DocumentsExplanatoryList>
          <li>{t('provideSupportingDocumentsSuchAs')}</li>
          <li>{t('newFinancialReports')}</li>
          <li>{t('newFinancialStatements')}</li>
          <li>{t('other')}</li>
        </DocumentsExplanatoryList>
        <UploadArea ref={uploadAreaRef} />
      </Modal>
    </>
  );
};
