import styled from 'styled-components';

import { Paper, ShimerContainer, Toast, Typography } from 'components';

export const FlexContainer = styled.div`
  display: flex;
`;

export const StatusChipContainer = styled(FlexContainer)``;

export const CardHeaderRow = styled(FlexContainer)`
  align-items: flex-start;
  align-self: stretch;
  align-content: flex-start;
  justify-content: space-between;
  gap: 0.5rem 1rem;
  width: 100%;
`;

export const ChildrenContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  gap: 1rem;

  button {
    width: 100%;
  }
`;

export const HeaderTitle = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })`
  display: inline-flex;
  align-self: center;
`;

export const CardPaper = styled(Paper)`
  padding: 1rem;
  width: 100%;
  flex: initial;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 1rem;
  min-height: 3.5rem;

  &[aria-disabled='true'] {
    background-color: ${({ theme }) => theme.colors.base.black.tints[2]};

    ${HeaderTitle} {
      color: ${({ theme }) => theme.colors.base.black.tints[40]};
    }

    ${ChildrenContainer} {
      display: none;
    }
  }
`;

export const ExplanationText = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
`;

export const CardTitleShimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })`
  height: 1.25rem;
  width: 8.75rem;
`;

export const ButtonShimmer = styled(CardTitleShimmer)`
  height: 2.5rem;
  width: 100%;
`;

export const StepToast = styled(Toast).attrs({ truncateText: true, showHeader: false, variant: 'info' })`
  text-align: left;
  margin-bottom: ${({ isVisible }) => (isVisible ? 0 : '-1rem')};
`;

export const StepCardText = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
`;
