import { useCallback, useEffect } from 'react';

const UNCERTAINTY_THRESHOLD = 3; // px

type NumericAttributes =
  | 'data-threshold'
  | 'data-translate'
  | 'data-swipe-started-at'
  | 'data-swipe-till-confirm';

type Attributes = 'data-swipe-started' | 'data-swipe-comfirm';

const isWithinSwipeZone = (x: number, y: number, element: HTMLElement | null) => {
  const swipeArea = element?.querySelector('div#draggable-pill');

  if (swipeArea) {
    const rect = swipeArea.getBoundingClientRect();
    const minimumAccessibleArea = 48; // minimum accesiible area (48px)
    const buffer = (minimumAccessibleArea - swipeArea.clientHeight) / 2; // minimum accesiible area (48px)

    // Calculate the extended boundary
    const minX = minimumAccessibleArea; // assume that we might have some clickable elements on the left side
    const maxX = window.innerWidth - minimumAccessibleArea; // assume that we might have some clickable elements on the right side
    const minY = rect.top - buffer;
    const maxY = rect.bottom + 2 * buffer; // assume that dragable area is positioned at the top of swipeable area so extend the bottom boundary downwards more

    // Check if the touch coordinates are within the extended boundary
    return x >= minX && x <= maxX && y >= minY && y <= maxY;
  }

  return false;
};

const isNodeScollable = (element: EventTarget) => {
  if (element instanceof HTMLElement) {
    // Check if the element has overflow styles that could allow for scrolling
    const overflowX = getComputedStyle(element).overflowX;
    const overflowY = getComputedStyle(element).overflowY;

    // Conditions to consider that scrolling might be enabled
    const overflowScrollOrAuto = (overflow: string) => ['scroll', 'auto'].includes(overflow);

    // Check if the element's content overflows its dimensions
    const contentOverflows =
      element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

    // Check if either overflowX or overflowY properties allow scrolling and if there is an overflow in content
    return (overflowScrollOrAuto(overflowX) || overflowScrollOrAuto(overflowY)) && contentOverflows;
  }

  return false;
};

const getTraveledDistance = (touches: TouchList, element: HTMLElement | null) => {
  if (!element) {
    return 0;
  }

  return touches[0].clientY - getNumericAttribute(element, 'data-swipe-started-at');
};

const getMaxTranslate = (touches: TouchList, min: number, max: number, element: HTMLElement | null) => {
  return Math.min(Math.max(getTraveledDistance(touches, element), min), max);
};

const getNumericAttribute = (element: HTMLElement | null, attribute: NumericAttributes) => {
  return element ? Number(element.getAttribute(attribute) ?? 0) : 0;
};

const getBooleanAttribute = (element: HTMLElement | null, attribute: Attributes) => {
  return element ? element.getAttribute(attribute) === 'true' : false;
};

const setBooleanAttribute = (element: HTMLElement | null, attribute: Attributes, value: boolean) => {
  if (element) {
    element.setAttribute(attribute, `${value}`);
  }
};

const setNumericAttribute = (element: HTMLElement | null, attribute: NumericAttributes, value: number) => {
  if (element) {
    element.setAttribute(attribute, `${value}`);
  }
};

const removeAttribute = (element: HTMLElement | null, attribute: Attributes | NumericAttributes) => {
  if (element) {
    element.removeAttribute(attribute);
  }
};

const getThreshold = (element: HTMLElement) => {
  return getNumericAttribute(element, 'data-threshold');
};

const getTranslate = (element: HTMLElement) => {
  return getNumericAttribute(element, 'data-translate');
};

const animateTranslate = (element: HTMLElement, direction: 'up' | 'down', options: SwipeHandlersOptions) => {
  const animationDirection = direction === 'up' ? -1 : 1;

  const elementHeight = element.clientHeight;

  const increase = elementHeight / 10;

  const oldTranslate = getTranslate(element);
  const newTranslate = Math.min(Math.max(oldTranslate + increase * animationDirection, 0), elementHeight);

  element.style.transform = `translateY(${newTranslate}px)`;
  setNumericAttribute(element, 'data-translate', newTranslate);

  const tillConfirm = Math.max(Math.min(newTranslate / options.thresholdForConfirm, 1), 0);

  setNumericAttribute(element, 'data-swipe-till-confirm', tillConfirm);
  options.onSwipe?.(newTranslate, tillConfirm);
};

export type SwipeHandlersOptions = {
  onSwipeConfirm: () => unknown;
  onSwipeCancel?: () => unknown;
  onSwipe?: (translate?: number, tillConfirm?: number) => unknown;
  thresholdForConfirm: number;
};

const touchStartHandler = (element: HTMLElement | null, e: TouchEvent) => {
  if (element) {
    const isInSwipeZone = isWithinSwipeZone(e.touches[0].clientX, e.touches[0].clientY, element);

    if (isInSwipeZone) {
      e.preventDefault();

      const eventBubblePath = e.composedPath();
      const childrenScrollLock = eventBubblePath.some(isNodeScollable); //check if children has scroll

      const isSwipingAllowed = !document.querySelector('[data-swipe-started="true"]') && !childrenScrollLock;

      if (isSwipingAllowed) {
        setBooleanAttribute(element, 'data-swipe-started', true);
        setNumericAttribute(element, 'data-swipe-started-at', e.touches[0].clientY);
      }
    }
  }
};

const touchMoveHandler = (element: HTMLElement | null, options: SwipeHandlersOptions, e: TouchEvent) => {
  const eventBubblePath = e.composedPath();

  const childrenScrollLock = eventBubblePath.some(isNodeScollable); //check if children has scroll
  const swipeAllowed = getBooleanAttribute(element, 'data-swipe-started');
  const swipeThresholdExceeded =
    Math.abs(e.touches[0].clientY - e.touches[0].screenY) > UNCERTAINTY_THRESHOLD;

  const isSwiping = swipeAllowed && swipeThresholdExceeded && !childrenScrollLock;

  if (isSwiping) {
    window.requestAnimationFrame(() => {
      const { touches } = e;

      if (element) {
        const height = element.clientHeight ?? window.innerHeight;

        setNumericAttribute(element, 'data-threshold', options.thresholdForConfirm);
        const translate = getMaxTranslate(touches, 0, height - 200, element);
        element.style.transform = `translateY(${translate}px)`;
        setNumericAttribute(element, 'data-translate', translate);

        const tillConfirm = Math.max(Math.min(translate / options.thresholdForConfirm, 1), 0);
        setNumericAttribute(element, 'data-swipe-till-confirm', tillConfirm);
        options.onSwipe?.(translate, tillConfirm);
      }
    });
  }
};

const touchEndHandler = async (element: HTMLElement | null, options: SwipeHandlersOptions) => {
  if (element) {
    const swipeAllowed = getBooleanAttribute(element, 'data-swipe-started');

    if (swipeAllowed) {
      const translate = getTranslate(element);
      const threshold = getThreshold(element);

      if (translate > threshold) {
        setBooleanAttribute(element, 'data-swipe-comfirm', true);
        let breaker = 0;

        do {
          window.requestAnimationFrame(() => {
            animateTranslate(element, 'down', options);
          });
          await new Promise((r) => setTimeout(r, 10));
          breaker++;
        } while (breaker < 15);

        options.onSwipeConfirm();
      } else {
        let breaker = 0;

        if (getBooleanAttribute(element, 'data-swipe-comfirm')) {
          do {
            window.requestAnimationFrame(() => {
              animateTranslate(element, 'up', options);
            });

            await new Promise((r) => setTimeout(r, 10));
            breaker++;
          } while (breaker < 15);
        }

        options.onSwipeCancel?.();
      }

      setTimeout(() => {
        removeAttribute(element, 'data-swipe-started');
        removeAttribute(element, 'data-threshold');
        removeAttribute(element, 'data-translate');
        removeAttribute(element, 'data-swipe-started-at');
        removeAttribute(element, 'data-swipe-till-confirm');
        options.onSwipe?.(0, 0);
        removeAttribute(element, 'data-swipe-comfirm');
        element.removeAttribute('style'); // remove inline style with timeout to prevent flickering from regular close animation
      }, 300);
    }
  }
};

const useSwipeHandlers = (
  element: HTMLElement | null,
  options: SwipeHandlersOptions = {
    thresholdForConfirm: 0.3 * window.innerHeight,
    onSwipeConfirm: () => {},
    onSwipeCancel: () => {},
    onSwipe: () => {}
  }
) => {
  const height = element?.clientHeight ?? window.innerHeight;

  const touchStart = useCallback((e: TouchEvent) => touchStartHandler(element, e), [element]);

  const touchMove = useCallback(
    (e: TouchEvent) => touchMoveHandler(element, options, e),
    [element, options.thresholdForConfirm, height]
  );

  const touchEnd = useCallback(
    async () => touchEndHandler(element, options),
    [element, options.onSwipeConfirm]
  );

  useEffect(() => {
    if (!element) return;

    element.addEventListener('touchstart', touchStart);
    document.addEventListener('touchmove', touchMove);
    document.addEventListener('touchend', touchEnd);

    return () => {
      element.removeEventListener('touchstart', touchStart);
      document.removeEventListener('touchmove', touchMove);
      document.removeEventListener('touchend', touchEnd);
    };
  }, [element, touchMove]);
};

export default useSwipeHandlers;
