import styled from 'styled-components';

import { LinkButtonStyle } from 'components';
import { PageButton } from 'components/table/table.styles';

export const ExpandableRowContainer = styled.div`
  display: flex;
  padding: 0.625rem 3.625rem;
`;

export const CellCollumn = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;
`;

export const AmountCellColumn = styled(CellCollumn)`
  align-items: flex-end;
`;

export const HeaderCell = styled.div`
  display: flex;
  padding: 0.625rem 0;
`;

export const InlineCell = styled.div`
  display: flex;

  > *:nth-child(2) {
    margin-left: 0.6rem;
    align-items: center;
  }
`;

export const InvoiceDetailsTableContainer = styled.div`
  margin-top: 2rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-top: 0;
  }

  div[data-column-id='invoiceDueDate'],
  div[data-column-id='invoiceAmount'],
  div[data-column-id='invoiceAmountUnfunded'],
  div[data-column-id='invoiceAmountAssignedOutstanding'] {
    display: flex;
    justify-content: flex-end;
  }

  button:not(${PageButton}) {
    min-height: 2.25rem;

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }

  .rdt_Table {
    .rdt_TableRow.table-row_expanded {
      border-bottom: none;
    }

    .table-row_expanded {
      background-color: #e9effe;
    }

    .rdt_TableCol {
      padding-right: 0;
    }
  }
`;

type ExpandoIconProps = {
  expanded: boolean;
};

export const ExpandoIcon = styled.div<ExpandoIconProps>`
  display: flex;
  width: 100%;

  svg {
    height: 1rem;
    transform: rotateZ(${({ expanded }) => (expanded ? '180deg' : 0)});
    transition: transform 0.25s ease-in;
  }
`;

export const SecondaryHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.25rem;

  ${LinkButtonStyle} {
    font-size: 1rem;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 0.75rem;
  }
`;

export type IconContainerProps = {
  variant: 'positive' | 'negative' | 'neutral';
};

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: ${({ variant }) => {
        switch (variant) {
          case 'negative':
            return '#EF2615';
          case 'positive':
            return '#5CB546';
          case 'neutral':
            return '#7F7F7F';
        }
      }};
    }
  }
`;

export const MobileVerificationBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.25rem;
`;
