import styled from 'styled-components';

import briefcase from 'assets/icons/briefcase.svg';
import chevronRight from 'assets/icons/chevronRight.svg';
import close from 'assets/icons/close.svg';
import person from 'assets/icons/person.svg';
import plus from 'assets/icons/plus.svg';
import telescope from 'assets/icons/telescope.svg';
import {
  Paper,
  Typography,
  BlueCheckmark,
  StatusChip,
  LinkButton,
  ShimerContainer,
  SearchInput
} from 'components';

import { BottomActions } from '../sideNavigation/sideNavigation.styles';

export const CloseButton = styled(LinkButton).attrs({
  icons: { left: close },
  iconOnly: true,
  color: 'Black'
})`
  display: none;

  @media ${({ theme }) => theme.media.maxTablet} {
    display: flex;
  }
`;

export const CompanySelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding: 0 0.75rem 0 0;
  margin-right: 0.25rem;
`;

export const CompanyStatus = styled(StatusChip).attrs({ color: 'gray' })`
  flex: 0 0 auto;
  margin-left: auto;
`;

type CompanyOptionProps = {
  user?: boolean;
  hideHero?: boolean;
};

export const CompanyOption = styled.label<CompanyOptionProps>`
  display: flex;
  padding: 0.5rem;
  border-radius: 0.75rem;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  min-height: 3.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:has(> ${BlueCheckmark}:checked) {
    background-color: ${({ theme }) => theme.colors.base.black.tints[5]};
  }

  &::before {
    content: ${({ hideHero }) => (hideHero ? 'none' : '""')};
    display: flex;
    padding: 0.75rem 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background: ${({ theme, user }) => theme.colors.base.black.tints[user ? 10 : 70]};
    justify-content: center;
    box-sizing: border-box;
    background-image: ${({ user }) => `url("${user ? person : briefcase}")`};
    background-repeat: no-repeat;
    background-position: center;
  }

  ${BlueCheckmark}:not(:checked) {
    display: none;
  }

  @media ${({ theme }) => theme.media.minTablet} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.base.black.tints[5]};
    }
  }
`;

export const AddCompanyOption = styled(CompanyOption)`
  margin-right: 1rem;
  margin-top: -0.25rem;

  &::before {
    content: '';
    background: ${({ theme }) => theme.colors.base.black.tints[10]};
    background-image: ${() => `url("${plus}")`};
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const CompanySwitchTitle = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.base.black.tints[50]};
  margin-right: 1rem;
`;

export const CompanyTitle = styled(Typography.Body).attrs({ weight: 'medium' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CompanyOptionContent = styled.div`
  display: flex;
  gap: 0.5rem;
  flex: 1;
  align-items: center;
  overflow: hidden;
`;

export const Shimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })``;

export const HeroShimmer = styled(Shimmer)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
`;

export const MenuButton = styled(CompanyOption)`
  min-height: 3rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  width: 100%;
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.white.functional};
  transition: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.base.white.functional};
  }

  input[type='checkbox'] {
    display: none;
  }

  &::before {
    content: '';
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    display: flex;
  }

  &::after {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    mask-image: ${() => `url("${chevronRight}")`};
    background-color: ${({ theme }) => theme.colors.base.black.tints[50]};
    margin-left: auto;
    transition: transform 0.15s ease-out;
    display: flex;
  }

  &:has(> input[type='checkbox']:checked) {
    border-color: ${({ theme }) => theme.colors.base.black.tints[10]};

    &::after {
      transform: rotate(90deg);
    }
  }

  ${CompanyTitle} {
    color: ${({ theme }) => theme.colors.base.black.shades[90]};
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 0;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
    }

    &::after {
      display: none;
    }

    ${CompanyTitle} {
      display: none;
    }

    &::before {
      width: 2.5rem;
      height: 2.5rem;
      background-image: ${() => `url("${person}")`};
      background-color: ${({ theme }) => theme.colors.base.black.tints[10]};
    }
  }
`;

export const CompanySwitchCard = styled(Paper)`
  padding: 1rem 0 0.5rem 1rem;
  gap: 0.5rem;
  width: 22.5rem;
  max-height: calc(calc(100dvh - 4rem) - var(--spa-globals-top-banner-height));

  ${BottomActions} {
    display: none;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    padding-bottom: 1rem;
    max-height: calc(calc(100dvh - 1.5rem) - var(--spa-globals-top-banner-height));

    ${BottomActions} {
      display: flex;
      margin-inline: -0.5rem;
    }
  }
`;

export const CompanySearchInput = styled(SearchInput)`
  padding-right: 1rem;
`;

export const NotFoundMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-block: 0.5rem;
`;

export const NotFoundMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const NotFoundTitle = styled(Typography.Body).attrs({ type: 'L', weight: 'medium' })`
  color: ${({ theme }) => theme.colors.base.black.tints[40]};
  text-align: center;
`;

export const NotFoundSubText = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.tints[40]};
  text-align: center;
`;

export const NotFoundIcon = styled.div`
  width: 5rem;
  height: 5rem;
  mask-image: ${() => `url("${telescope}")`};
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.base.black.tints[40]};
`;
