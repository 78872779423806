import styled from 'styled-components';

import borderDragOver from '../../assets/images/border-drag-over.svg';
import border from '../../assets/images/border.svg';
import { SpinnerContainer } from '../loader/loader.styles';
import { Typography } from '../typography';

type AreaContainerProps = {
  visible: boolean;
};

export const AreaContainer = styled.div<AreaContainerProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1.75rem;
  background-color: ${({ theme }) => theme.colors.base.primary.tints[5]};
  color: ${({ theme }) => theme.colors.base.primary.shades[100]};
  outline: none;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 500;
  padding: 1rem 1.5rem;
  text-align: center;
  user-select: none;
  background-image: ${() => `url("${border}")`};
  transform: background-color 0.1s ease-out;

  &:hover {
    background-color: #2b64f514;
    background-image: ${() => `url("${borderDragOver}")`};
  }

  &.dragover {
    cursor: copy;
  }

  &:active,
  &.dragover {
    background-color: #eaeffe;
    background-image: ${() => `url("${borderDragOver}")`};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 1rem;
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 0.75rem;
  }

  svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const UploadAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`;

export const InnerHeader = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })`
  margin-bottom: 0.75rem;
`;

export const HelpText = styled(Typography.Body).attrs({ type: 'S', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.primary.tints[80]};
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const HiddenImage = styled.img`
  opacity: 0;
  position: absolute;
  left: 0;
  right: 3.125rem;
  top: 0;
  bottom: 0;
`;

type Props = {
  error?: boolean;
  removable?: boolean;
};

export const ChipLabel = styled.label<Props>`
  position: relative;
  border: 0.0625rem solid
    ${({ theme, error }) => (error ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10])};
  background: ${({ theme }) => theme.colors.secondary.buttonBackground};
  display: flex;
  overflow: hidden;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding-inline: ${({ removable }) => (removable ? '0.75rem 0.625rem' : '0.75rem')};
  height: 2rem;
  justify-content: center;
  align-items: center;
  max-width: 12.75rem;
  width: fit-content;

  svg {
    margin-left: 0.5rem;
    margin-right: 0;

    path {
      fill: ${({ theme }) => theme.colors.base.black.tints[90]};
    }

    &:hover {
      path {
        fill: ${({ theme }) => theme.colors.base.black.tints[30]};
      }
    }
  }

  img {
    margin-right: 0.5rem;
  }

  ${SpinnerContainer} {
    svg {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:hover {
    transition: border 0.15s ease-in;
    border: 0.0625rem solid ${({ theme }) => theme.colors.base.black.tints[30]};
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    height: 2.5rem;
    padding-inline: ${({ removable }) => (removable ? '1.125rem 0.75rem' : '1.125rem')};
  }
`;

export const ChipButton = styled.div.attrs({ role: 'button' })`
  ${ChipLabel} {
    cursor: pointer;
  }
`;

export const ToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Filename = styled(Typography.Chip).attrs({ type: 'S', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${ChipLabel} {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const FileNamesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;

  ${ChipLabel} {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`;
