import { RestClient } from '../RestClient';
import { baseUrlBuilder } from '../utils/baseUrlBuilder';
import { queryBuilder } from '../utils/queryBuilder';

import {
  ContactsByPartyIdRequest,
  ContactsByPartyIdResponse,
  ContractDetailsRequest,
  ContractDetailsResponse,
  ContractDocumentDownloadRequest,
  ContractLimitHistoryRequest,
  ContractLimitHistoryResponse,
  ContractsIncreaseLimitDocumentsRequest,
  ContractsProlongDocumentsRequest
} from './types';


const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('contracts');

export class ContractsService {
  static async getContractById({ contractId }: ContractDetailsRequest) {
    const url = withBaseUrl(`/contracts/${contractId}`);
    return await restClient.GET<ContractDetailsResponse>(url);
  }

  static getCreditLimitHistory({ contractId, periodicity }: ContractLimitHistoryRequest) {
    const url = withBaseUrl(`/contracts/${contractId}/contract-credit-limit-history`);
    return restClient.GET<ContractLimitHistoryResponse>(queryBuilder(url, { periodicity }));
  }

  static getContactsByPartyId({ contractId, thirdPartyId }: ContactsByPartyIdRequest) {
    const url = withBaseUrl(`/contracts/${contractId}/third-parties/${thirdPartyId}/contacts`);
    return restClient.GET<ContactsByPartyIdResponse>(url);
  }

  static getContractDocumentById({ contractId, documentId, fileName }: ContractDocumentDownloadRequest) {
    const url = withBaseUrl(`/contracts/${contractId}/documents/${documentId}`);

    return restClient.DOWNLOAD(fileName ? `${url}?fileName=${encodeURIComponent(fileName)}` : url);
  }

  static uploadContractDocuments(payload: ContractsProlongDocumentsRequest) {
    const url = withBaseUrl(`/contracts/prolong/attachments?contractId=${payload.contractId}`);
    return restClient.UPLOAD(url, payload.content['multipart/form-data']);
  }

  static uploadContractIncreaseLimitDocuments(payload: ContractsIncreaseLimitDocumentsRequest) {
    const url = withBaseUrl(`/contracts/increase-limits/documents?contractId=${payload.contractId}`);
    return restClient.UPLOAD(url, payload.content['multipart/form-data']);
  }
}
