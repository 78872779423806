import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { selectActiveCompany } from 'features/applications/selectors';
import { selectActiveCustomer, selectSessiondId, selectUserData } from 'features/auth/selectors';
import { getAuthTokens } from 'features/auth/utils';
import { useModalContext } from 'features/nav/context/modalContext';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { postMessage } from 'pages/onboarding/onboarding';
import { AppService } from 'services';
import { Company } from 'services/CapService/types';
import { KYCApplicationStartRequest, OndatoStatusResponse } from 'services/OnboardingService/types';
import { CustomBaseQuery } from 'services/RestClient/typings';
import { queryBuilder } from 'services/utils/queryBuilder/';
import { getLanguageCode } from 'translations/utils';
import isSafari from 'utils/isSafari';
import tracking from 'utils/tracking';

import { useInitOnboardingApplicationMutation } from '../api';

const createTopLevelDomainCookie = (name: string, payload?: string) => {
  const host = location.host
    .replace('my', '')
    .replace('my.stage', '')
    .replace('dev.sp', '')
    .replace(':3000', '');

  const ttlInSeconds = 20;

  const expirationInMS = Date.now() + 1000 * ttlInSeconds;

  return `${name}=${payload ?? ''}; path=/; domain=${host}; max-age=${ttlInSeconds}; expires=${new Date(
    expirationInMS
  ).toUTCString()}; secure;`;
};

const setSafariCookie = (payload: string) => {
  const authTokens = getAuthTokens();

  const cookieString = createTopLevelDomainCookie('redirect', payload);
  const tokenString = createTopLevelDomainCookie('token', authTokens.accessToken);

  document.cookie = cookieString;
  document.cookie = tokenString;
};

type WarmupTrigger = MutationTrigger<
  MutationDefinition<
    KYCApplicationStartRequest,
    CustomBaseQuery<OndatoStatusResponse>,
    'applicationStatus' | 'bankAccount',
    string,
    'onboardingApi'
  >
>;

type Params = {
  ondatoInfo: OndatoStatusResponse;
  existingCustomerOnboarding?: boolean;
  redirectBackTo?: string;
};

type RedirectParams = Params & {
  activeCompany: Company;
  languageCode: string;
  email?: string;
  customerId?: string;
  sessionId?: string;
  afterSessionRefreshReturnUrl?: string;
};

const questionaireHost = VARIABLES.companiesQuestionaire as string;

export const useOnboardingWarmup = (
  trigger: WarmupTrigger,
  ondatoInfo: OndatoStatusResponse,
  userAgreementAccepted: boolean
) => {
  const activeCompany = useAppSelector(selectActiveCompany);
  const { i18n } = useTranslation();

  return async () => {
    if (
      (ondatoInfo.status === 'ONDATO_FINISHED' ||
        ondatoInfo.status === 'SME_CUSTOMER_ONBOARDING_NOT_FOUND') &&
      ondatoInfo?.ondatoId &&
      userAgreementAccepted
    ) {
      const result = await trigger({
        companyName: activeCompany.name ?? '',
        companyCode: activeCompany.id ?? '',
        country: activeCompany.country ?? '',
        referalKey: '',
        termsAndConditionsConsent: userAgreementAccepted,
        fetchOtherCompanyDataConsent: false,
        marketingDataUseConsent: false,
        marketingNewsletterConsent: false,
        identificationId: ondatoInfo?.ondatoId,
        language: getLanguageCode(i18n.language) === 'lt' ? 'lt-LT' : 'en-GB'
      }).unwrap();

      return result;
    }
  };
};

export const redirectToOnboarding = async ({
  ondatoInfo,
  activeCompany,
  customerId,
  email,
  languageCode,
  sessionId,
  afterSessionRefreshReturnUrl
}: RedirectParams) => {
  if (ondatoInfo?.ondatoId) {
    try {
      const authTokens = getAuthTokens();

      const payload = {
        customerId,
        email,
        redirectUrl: queryBuilder(`${location.protocol}//${location.host}/onboarding`, {
          lan: languageCode,
          redirectTo: afterSessionRefreshReturnUrl
        }),
        sessionId,
        kycInfo: {
          companyName: activeCompany.name ? encodeURIComponent(activeCompany.name.replaceAll('"', "'")) : '',
          companyCode: activeCompany.id ?? '',
          identificationId: ondatoInfo?.ondatoId
        },
        refreshToken: authTokens.refreshToken,
        anonymousId: window.session?.getUniqueId()
      };

      postMessage((document.getElementById('onboarding_frame') as HTMLIFrameElement)?.contentWindow, payload);

      if (isSafari()) {
        const stringifiedPayload = JSON.stringify(payload);

        setSafariCookie(stringifiedPayload);
      }

      setTimeout(() => {
        window.open(`${questionaireHost}smego?language=${languageCode}`, '_self');
      }, 0);
    } catch {
      AppService.reportError({ message: 'Failed to post to onboarding frame' });
    }
  } else {
    AppService.reportError({ message: 'No identificationId provided for ondato' });
  }
};

let LOCK_GLOBAL = false;

const useOnboardingRedirectHandler = ({ ondatoInfo, existingCustomerOnboarding, redirectBackTo }: Params) => {
  const { modals } = useModalContext();

  const { i18n } = useTranslation();

  const languageCode = getLanguageCode(i18n.language);

  const [triggerRedirect, setTriggerRedirect] = useState(false);

  const activeCompany = useAppSelector(selectActiveCompany);

  const sessionId = useAuthSelector<typeof selectSessiondId>(selectSessiondId);
  const customer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const [
    createOnboardingQuestionaire,
    { isLoading: questionaireBeingCreated, isError: questionaireCreationFailed }
  ] = useInitOnboardingApplicationMutation({
    fixedCacheKey: 'initOnboardingApplication'
  });

  const onboardingWarmupTrigger = useOnboardingWarmup(createOnboardingQuestionaire, ondatoInfo, true);

  useEffect(() => {
    if (questionaireCreationFailed && !triggerRedirect) {
      modals?.get('flow-lobby')?.({ screen: 'onboarding', visible: false });
    }
  }, [questionaireCreationFailed]);

  useEffect(() => {
    const ondatoReady =
      (ondatoInfo.status === 'ONDATO_FINISHED' ||
        ondatoInfo.status === 'SME_CUSTOMER_ONBOARDING_NOT_FOUND' ||
        ondatoInfo.status === 'SME_CUSTOMER_ONBOARDING_IN_PROGRESS') &&
      ondatoInfo?.ondatoId;

    if (!LOCK_GLOBAL && triggerRedirect && ondatoReady) {
      LOCK_GLOBAL = true;

      const redirectParams = {
        ondatoInfo,
        activeCompany,
        customerId: customer?.customerId,
        email: userData?.email,
        languageCode,
        sessionId,
        afterSessionRefreshReturnUrl: redirectBackTo
      };

      (async () => {
        if (triggerRedirect) {
          if (ondatoInfo.status === 'SME_CUSTOMER_ONBOARDING_IN_PROGRESS') {
            redirectToOnboarding(redirectParams);
          } else {
            modals?.get('flow-lobby')?.({ screen: 'onboarding', visible: true });

            if (!questionaireBeingCreated) {
              try {
                if (
                  (ondatoInfo.status === 'ONDATO_FINISHED' ||
                    ondatoInfo.status === 'SME_CUSTOMER_ONBOARDING_NOT_FOUND') &&
                  ondatoInfo?.ondatoId
                ) {
                  await onboardingWarmupTrigger();
                  await redirectToOnboarding(redirectParams);
                }
              } catch (error) {
                modals?.get('flow-lobby')?.({ screen: 'onboarding', visible: false });
                setTriggerRedirect(false);
                AppService.reportError({
                  message: `Failed to create onboarding questionaire for ondatoId: ${ondatoInfo?.ondatoId}`
                });
              } finally {
                modals?.get('flow-lobby')?.({ screen: 'onboarding', visible: false });
                LOCK_GLOBAL = false;
              }
            }
          }
        }
      })();
    }
  }, [ondatoInfo.status, triggerRedirect]);

  return () => {
    setTriggerRedirect(true);
    tracking.setSubmitEvent(
      existingCustomerOnboarding
        ? 'existing_customer_onboarding_flow_started'
        : 'single_flow_onboarding_flow_started'
    );
  };
};

export default useOnboardingRedirectHandler;
