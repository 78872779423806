import { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RegularText, RadioButton, Table } from 'components';
import { ContractItem } from 'features/loans/types';

import { ContractsTableContainer } from './contracts.styles';

type Props = {
  items?: ContractItem[];
  loading?: boolean;
  firstItemSelectedByDefault?: boolean;
  onRowClick?: (contractId: string) => unknown;
  additionalColumns?: TableColumn<ContractItem>[];
};

const piguLoanTypes = ['LOAN PIGU', 'LOAN PIGU EE', 'LOAN PIGU ES', 'LOAN PIGU LV'];
const greenLoanTypes = ['GREEN LOAN', 'GREEN LOAN EE', 'GREEN LOAN ES', 'GREEN LOAN LV'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withNullCheck = (value: any) => value || '-';

const convertTypeToTranslationId = (type: string) => type.replace('_', '').toLowerCase();

export const Contracts: FC<Props> = ({
  items,
  loading,
  onRowClick,
  additionalColumns,
  firstItemSelectedByDefault = true
}) => {
  const [searchParams, setSearchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const [selectedContractId, setSelectedContractId] = useState(contractId);

  const { t } = useTranslation();

  const mappedItems = items?.map((item) => ({
    ...item,
    toggleSelected: Boolean(contractId && item.id === contractId)
  }));

  const hasContracts = items && items.length;

  useEffect(() => {
    if (hasContracts) {
      if (selectedContractId && !contractId) {
        searchParams.set('contractId', selectedContractId);
        setSearchParams(searchParams, { replace: true });
      } else if (firstItemSelectedByDefault) {
        if (!selectedContractId && !contractId) {
          const firstContract = items[0];
          const firstContractsId = firstContract?.id;

          if (firstContractsId) {
            searchParams.set('contractId', firstContractsId);
            setSearchParams(searchParams, { replace: true });
            setSelectedContractId(firstContractsId);
          }
        }
      }
    }
  }, [selectedContractId, contractId, hasContracts, searchParams, setSearchParams]);

  const columns: TableColumn<ContractItem>[] = [
    {
      cell: (row) => (
        <RadioButton
          data-testid={`contracts-table-checkbox-${row.id}`}
          checked={row.id === contractId}
          defaultValue={''}
          onChange={() => handleRowClicked(row)}
        />
      )
    },
    {
      selector: (row) => withNullCheck(row.loanType),
      name: t('type'),
      format: (row) => {
        if (piguLoanTypes.includes(row.loanType || '')) {
          return <RegularText>{t('revenueFinancePigu')}</RegularText>;
        }

        if (greenLoanTypes.includes(row.loanType || '')) {
          return <RegularText>{t('greenLoan')}</RegularText>;
        }

        return <RegularText>{t(convertTypeToTranslationId(row.type || ''))}</RegularText>;
      }
    },
    {
      selector: (row) => withNullCheck(row.number),
      name: t('number'),
      format: (row) => <RegularText>{row.number}</RegularText>
    },
    {
      selector: (row) => row.financier,
      name: t('financier'),
      format: (row) => <RegularText>{row.financier}</RegularText>
    },
    ...(additionalColumns || [])
  ];

  const handleRowClicked = (row: ContractItem) => {
    setSelectedContractId(row.id || '');
    onRowClick?.(row.id || '');
    setSearchParams({ contractId: row.id || '' }, { replace: true });
  };

  return (
    <ContractsTableContainer data-testid="contracts-table">
      <Table
        noDataMessage={t('noListItems')}
        progressPending={loading}
        columns={columns}
        data={mappedItems || []}
        highlightOnHover
        onRowClicked={handleRowClicked}
      />
    </ContractsTableContainer>
  );
};
