import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';

import { clearAuthTokens, saveAuthTokens } from 'features/auth/utils';
import { RequestCompleteRefresh, ResponseCompleteRefresh } from 'services/LoginService/typings';
import { Store } from 'store';

import axiosInstance from './axiosInstance';

type Request = InternalAxiosRequestConfig<{ headers: { Authorization: string } }>;

let store: Store;

export const injectStore = (_store: Store) => {
  store = _store;
};

const authToken = () => sessionStorage.getItem('user');
const refreshToken = () => sessionStorage.getItem('refreshToken');

const clearToken = () => {
  clearAuthTokens();
};

// Function that will be called to refresh authorization
export const refreshAuthLogic = (
  failedRequest: AxiosError | null,
  activeCustomerId?: string,
  retrow?: boolean
) => {
  const { getState } = store || {};

  return axios
    .get<RequestCompleteRefresh, AxiosResponse<ResponseCompleteRefresh>, AxiosAuthRefreshRequestConfig>(
      '/api/login/token/refresh',
      {
        skipAuthRefresh: true,
        headers: {
          Authorization: `Bearer ${refreshToken()}`,
          'x-customer-id': activeCustomerId || getState().auth.activeCustomer?.customerId,
          'X-Client-Name': globalThis.window.clientName
        }
      } as AxiosAuthRefreshRequestConfig
    )
    .then((tokenRefreshResponse) => {
      saveAuthTokens(tokenRefreshResponse.data);


      if (failedRequest?.response?.config?.headers) {
        failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.accessToken}`;
      }
    })
    .catch(() => {
      clearToken();

      if (retrow) {
        throw new Error('token refresh failed');
      }
    });
};

// Use interceptor to inject the token to requests
axiosInstance.interceptors.request.use((request: Request) => {
  if (request) {
    if (request.url !== '/api/login/token/sso' && !request.url?.includes('/bank/api')) {
      if (request.headers && authToken()) {
        request.headers.Authorization = `Bearer ${authToken()}`;
      }
    }
  }

  return request;
});

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export class RestClient {
  client: typeof axiosInstance;

  constructor() {
    this.client = axiosInstance;
  }

  public async HEAD<T = unknown>(
    url: string,
    requestConfig?: AxiosAuthRefreshRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.client.get(url, requestConfig).then((response) => response);
  }
  public async GET<T>(url: string, requestConfig?: AxiosAuthRefreshRequestConfig): Promise<T> {
    return this.client.get(url, requestConfig).then((response) => response.data);
  }

  public async DOWNLOAD(url: string, requestConfig?: AxiosAuthRefreshRequestConfig) {
    return this.client.get(url, { ...requestConfig, responseType: 'blob' }).then((response) => response);
  }

  public async POST<T, K>(url: string, body: T, requestConfig?: AxiosAuthRefreshRequestConfig): Promise<K> {
    return this.client.post(url, body, requestConfig).then((response) => response.data);
  }

  public async UPLOAD<K>(
    url: string,
    formData: FormData,
    requestConfig?: AxiosAuthRefreshRequestConfig
  ): Promise<K> {
    return this.client.post(url, formData, requestConfig).then((response) => response.data);
  }

  public async PUT<T, K>(url: string, body: T, requestConfig?: AxiosAuthRefreshRequestConfig): Promise<K> {
    return this.client.put(url, body, requestConfig).then((response) => response.data);
  }

  public async PATCH<T, K>(url: string, body: T, requestConfig?: AxiosAuthRefreshRequestConfig): Promise<K> {
    return this.client.patch(url, body, requestConfig).then((response) => response.data);
  }

  public async DELETE<K>(url: string, requestConfig?: AxiosAuthRefreshRequestConfig): Promise<K> {
    return this.client.delete(url, requestConfig).then((response) => response.data);
  }
}
