import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Column, Grid } from 'components';
import { LoansContractDetails, LoansLimitsDetails } from 'features/loans/components';
import { DocumentsReview } from 'features/loans/components/documentsReview/documentsReview';
import { selectLoansContractList } from 'features/loans/selectors';
import { SchedulesTable } from 'features/loans/tables';
import { useLoansSelector } from 'hooks/redux/hooks';
import { LoansService } from 'services/LoansService';
import { getLanguageCode } from 'translations/utils';

import { Row, FlexColumn } from '../factoring/contracts.styles';

export const LoansContracts = () => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();
  const contractId = searchParams.get('contractId');

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);
  const {
    contractNo,
    financierId,
    customerId,
    contractTerm,
    loansDisbursedAmount,
    financierMigrationDate,
    financierMigrationError,
    validFromDate,
    loanType
  } = selectedContract || {};

  const handleScheduleDownload = async () => {
    if (
      contractNo &&
      financierId &&
      customerId &&
      contractTerm &&
      validFromDate &&
      (loansDisbursedAmount || loansDisbursedAmount === 0)
    ) {
      return LoansService.downloadSchedule({
        contractNo,
        financierId,
        customerId,
        //pass schema check
        greenReport: (loanType === 'GREEN LOAN') as unknown as string,
        contractTerm: contractTerm.toString(),
        loansDisbursedAmount: loansDisbursedAmount.toString(),
        scheduleReportLanguage: (getLanguageCode(i18n.resolvedLanguage) || 'en').toUpperCase(),
        financierMigrationDate,
        financierMigrationError: `${financierMigrationError}`,
        contractDate: validFromDate
      });
    }
  };

  return (
    <Grid>
      <Column rowStart={1} spanRow={3} colStart={1} span={12}>
        <Row>
          <FlexColumn>
            <LoansContractDetails />
          </FlexColumn>
          <FlexColumn>
            <LoansLimitsDetails />
            <DocumentsReview />
          </FlexColumn>
        </Row>
      </Column>
      <Column colStart={1} span={12} colStartMd={1} spanMd={12} rowStartMd={4} spanRowMd={4}>
        <SchedulesTable scheduleDownload={handleScheduleDownload} />
      </Column>
    </Grid>
  );
};
