import { ForwardedRef, forwardRef } from 'react';

import { Checkbox } from '../checkbox';
import { CheckboxProps } from '../checkbox/checkbox';

type SelectableRowCheckboxProps = { onClick: (selected: boolean) => void } & CheckboxProps;

export const SelectableRowCheckbox = forwardRef(
  (props: SelectableRowCheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    return <Checkbox ref={ref} {...props} onChange={props.onClick} />;
  }
);
