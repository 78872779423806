import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import {
  FieldValues,
  Path,
  useForm,
  UseFormGetFieldState,
  DefaultValues,
  UseFormWatch,
  UseFormReset,
  UseFormClearErrors
} from 'react-hook-form';
import type { AnyObjectSchema } from 'yup';

export type ValidationHelpers<T extends FieldValues> = {
  setValue: (name: Path<T>, value: T[keyof T]) => void;
  setValueWithoutValidation: (name: Path<T>, value: T[keyof T]) => void;
  setTouched: (name: Path<T>) => void;
  getFieldState: UseFormGetFieldState<T>;
  reset?: UseFormReset<T>;
  clearErrors?: UseFormClearErrors<T>;
  watch?: UseFormWatch<T>;
  isValid?: boolean;
  validateMode?: 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all';
};

const useFormHandlers = <T extends FieldValues>(
  defaultValues: DefaultValues<T>,
  validationSchema?: AnyObjectSchema,
  validateMode?: ValidationHelpers<T>['validateMode']
) => {
  const [isInitialFormValid, setIsInitialFormValid] = useState(false);

  const valueSetOptions = { shouldValidate: true, shouldTouch: true, shouldDirty: true };

  const valueSetWithoutValidationOptions = { shouldValidate: false, shouldTouch: false, shouldDirty: false };
  const initialValues = useMemo(() => ({ ...defaultValues }), [defaultValues]);

  const {
    trigger,
    reset,
    setValue,
    getValues,
    register,
    handleSubmit,
    clearErrors,
    getFieldState,
    watch,
    formState: { errors, touchedFields: touched, isSubmitting, isValid, isDirty }
  } = useForm<T>({
    defaultValues,
    resolver: validationSchema && yupResolver(validationSchema)
  });

  useEffect(() => {
    (async () => {
      if (validationSchema) {
        try {
          await validationSchema.validate(initialValues, { abortEarly: false });
          setIsInitialFormValid(true);
        } catch {
          setIsInitialFormValid(false);
        }
      }
    })();
  }, [validationSchema]);

  useEffect(() => {
    if (initialValues) {
      Object.keys(initialValues).forEach((key) => {
        register(key as Path<T>);
        setValue(key as Path<T>, initialValues[key]);
      });
    }
  }, [setValue, register, initialValues]);

  const setValueWithValidation = (name: Path<T>, value: T[keyof T]) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    setValue(name, trimmedValue, valueSetOptions);
  };
  const setValueWithoutValidation = (name: Path<T>, value: T[keyof T]) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value;

    setValue(name, trimmedValue, valueSetWithoutValidationOptions);
  };

  const setTouched = (name: Path<T>) => setValueWithValidation(name, getValues(name));

  const setTouchedOnAll = () => {
    if (initialValues) {
      Object.keys(initialValues).forEach((key) =>
        typeof initialValues[key] === 'object' && initialValues[key]
          ? Object.keys(initialValues[key]).forEach((innerKey) => {
              setTouched(`${key}.${innerKey}` as Path<T>);
            })
          : setTouched(key as Path<T>)
      );
    }
  };

  const setTouchedOn = (keys: Path<T>[]) => {
    if (initialValues) {
      keys.forEach((key) =>
        typeof initialValues[key] === 'object' && initialValues[key]
          ? Object.keys(initialValues[key]).forEach((innerKey) => {
              setTouched(`${key}.${innerKey}` as Path<T>);
            })
          : setTouched(key)
      );
    }
  };

  const isFormValid = !isDirty ? isInitialFormValid : isValid;

  return {
    trigger,
    setValueWithValidation,
    setValueWithoutValidation,
    getValues,
    getFieldState,
    setTouchedOnAll,
    setTouchedOn,
    handleSubmit,
    reset,
    clearErrors,
    errors,
    touched,
    isSubmitting,
    validationHelpers: {
      setValue: setValueWithValidation,
      setValueWithoutValidation,
      setTouched,
      getFieldState,
      reset,
      clearErrors,
      watch,
      isDirty,
      isValid: isFormValid,
      validateMode
    }
  };
};

export { useFormHandlers };
