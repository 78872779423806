import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, Modal } from 'components';
import { selectApplicationIsLoading } from 'features/application/selectors';
import { BankAccountsForm } from 'features/statements/components';
import { selectInstitutionsIsLoading } from 'features/statements/selectors';
import { useApplicationSelector, useStatementsSelector } from 'hooks/redux/hooks';

type Props = {
  onConfirmation?: (...args: unknown[]) => unknown;
  onVisibilityChange?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
  testId?: string;
};

export const BankStatementsModal: FC<Props> = ({ isOpen, onVisibilityChange }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onVisibilityChange?.(false);
  };

  const institutionsLoading =
    useStatementsSelector<typeof selectInstitutionsIsLoading>(selectInstitutionsIsLoading);

  const isApplicationLoading =
    useApplicationSelector<typeof selectApplicationIsLoading>(selectApplicationIsLoading);

  return (
    <Modal
      testId="bank-statements"
      isOpen={!!isOpen}
      onClose={handleClose}
      mainActionhandler={handleClose}
      mainActionLabel={t('submit')}
    >
      {isApplicationLoading ? null : (
        <BankAccountsForm displayInCAPFlow={false} displayInsideFormWrapper={false} />
      )}
      {institutionsLoading ? <Loader /> : null}
    </Modal>
  );
};
