import { useEffect } from 'react';
import * as Yup from 'yup';

import { useFormContext } from 'features/application/context/FormContext';
import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { selectActiveCustomer, selectIsUserGhost } from 'features/auth/selectors';
import { useAppDispatch, useAppSelector, useApplicationSelector, useAuthSelector } from 'hooks/redux/hooks';
import { Company } from 'services/CapService/types';
import { companyCodeRequired, personNameRequired } from 'validators';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { FormContainer } from '../../components/formContainer';
import { selectCompanyInfo, selectIsNewCompany } from '../../selectors';
import { setCompanyData, submitApplicationAsync } from '../../slice';

import { FormStyle } from './company.style';
import { CompanyFields } from './companyInfoFields';
import { COMPANY_REGISTRATION_COUNTRIES_LIST } from './constants';

export const validationSchema = Yup.object({
  code: companyCodeRequired,
  name: personNameRequired,
  vatCode: Yup.string().optional(),
  country: Yup.string().oneOf(
    COMPANY_REGISTRATION_COUNTRIES_LIST.map((purpose) => purpose.value),
    VALIDATION_MESSAGE_ID.REQUIRED
  )
});

const initialCompany: Company = {
  name: '',
  code: '',
  vatCode: ''
};

const CompanyInfoForm = () => {
  const dispatch = useAppDispatch();

  const company = useApplicationSelector<typeof selectCompanyInfo>(selectCompanyInfo);
  const isGhostUser = useAuthSelector(selectIsUserGhost);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const { customerId, customerName, customerCountryId } = activeCustomer || {};

  const isNewCompany = useAppSelector(selectIsNewCompany);

  const isActiveCompanyVerified = useAppSelector(selectIsActiveCompanyVerified);

  const anyCompanyIsAllowed = isNewCompany || isGhostUser || !isActiveCompanyVerified;

  const initialValues = initialCompany ?? {};

  const existingCustomerCompany: Company = {
    name: customerName,
    code: customerId,
    country: customerCountryId
  };

  const prefilledData = anyCompanyIsAllowed ? initialValues : existingCustomerCompany;

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers<Company>(
    company ?? prefilledData,
    validationSchema,
    'onBlur'
  );

  const { setFormHandlers } = useFormContext<Company>();

  const submitHandler = handleSubmit(
    (data: Company) => {
      dispatch(setCompanyData(data));
      dispatch(submitApplicationAsync());
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(
    () =>
      setFormHandlers({
        submitHandler,
        validationHelpers,
        getValues
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFormHandlers]
  );

  return (
    <FormContainer isValid={validationHelpers.isValid} onNextClick={submitHandler}>
      <FormStyle>
        <CompanyFields
          validationHelpers={validationHelpers}
          getValues={getValues}
          isGhostUser={anyCompanyIsAllowed}
        />
      </FormStyle>
    </FormContainer>
  );
};

export default CompanyInfoForm;
