export enum INVOICE_UPLOAD_VALIDATION_MESSAGE_ID {
  invoiceAmountIsRequired = 'invoiceAmountIsRequired',
  invoiceNumberIsRequired = 'invoiceNumberIsRequired',
  buyerNameIsRequired = 'buyerNameIsRequired',
  buyerCodeIsRequired = 'buyerCodeIsRequired',
  payoutRecipientShouldExists = 'payoutRecipientShouldExist',
  issueDateIsRequired = 'issueDateIsRequired',
  dueDateIsRequired = 'dueDateIsRequired',
  datesShouldNotMatch = 'datesShouldNotMatch',
  dateShouldNotBeInFuture = 'dateShouldNotBeInFuture',
  dateShouldNotBeInPast = 'dateShouldNotBeInPast',
  paymentTermShouldNotExceed = 'paymentTermShouldNotExceed',
  currencyIsRequired = 'currencyIsRequired',
  currencyValueShouldBeOneOf = 'currencyValueShouldBeOneOf',
  transferClaimShouldBeLessOrEqual = 'transferClaimShouldBeLessOrEqual',
  transferClaimIsRequired = 'transferClaimIsRequired',
  invoiceNumberShouldBeUnique = 'invoiceNumberShouldBeUnique',
  notSubmittedInvoiceNumberShouldBeUnique = 'notSubmittedInvoiceNumberShouldBeUnique',
  buyerCodeIncorrect = 'buyerCodeIncorrect'
}
