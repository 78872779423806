import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setUserCRUDSuccessMessage, setUserCRUDErrorMessage } from 'features/app/slice';
import { useUpdateUserMutation } from 'features/users/slice';
import { useAppDispatch } from 'hooks/redux/hooks';
import { RequestUpdateUser } from 'services/RbacService/typings/';

import { ROLES } from './useTriggerCreateUser';

const useTriggerUpdateUser = (email?: string, onSuccess?: () => unknown, onError?: () => unknown) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [updateUserData] = useUpdateUserMutation();

  const updateUser = async (
    fields: RequestUpdateUser['content']['application/json'],
    selectedRole?: ROLES
  ) => {
    if (!isSubmitting && email && selectedRole) {
      setIsSubmitting(true);

      try {
        await updateUserData({
          email,
          roleId: `${selectedRole}`,
          content: {
            'application/json': fields
          }
        }).unwrap();

        dispatch(setUserCRUDSuccessMessage({ action: t('updated') }));
        onSuccess?.();
      } catch {
        dispatch(setUserCRUDErrorMessage({ action: t('updated') }));
        onError?.();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return { isSubmitting, updateUser };
};

export default useTriggerUpdateUser;
