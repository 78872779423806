import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentReviewCard } from 'features/contracts/components';
import { useFetchContractsDocumentsList } from 'features/contracts/hooks';

export const FactoringDocumentsReview: FC = () => {
  const { t } = useTranslation();

  const { data, isFetching, isError } = useFetchContractsDocumentsList();

  const files = isError || isFetching ? [] : data || [];

  return <DocumentReviewCard header={t('documents')} files={files} />;
};
