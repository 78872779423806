import { useCallback, useEffect } from 'react';

import { selectActiveCustomer } from 'features/auth/selectors';
import { useLoansSelector, useAuthSelector, useAppDispatch } from 'hooks/redux/hooks';
import { ProductType } from 'services/LoansService/types';

import {
  selectLoansContractsTableItems,
  selectIsLoansContractListLoading,
  selectIsLoansContractListLoadFailed,
  selectLoansProductType
} from '../selectors';
import { getOverviewAsync } from '../slice';

export const useFetchLoansContractsByType = (type: ProductType) => {
  const dispatch = useAppDispatch();

  const loadedType = useLoansSelector<typeof selectLoansProductType>(selectLoansProductType);
  const contracts = useLoansSelector<typeof selectLoansContractsTableItems>(selectLoansContractsTableItems);
  const isContractsLoading = useLoansSelector<typeof selectIsLoansContractListLoading>(
    selectIsLoansContractListLoading
  );
  const isContractsLoadingFailed = useLoansSelector<typeof selectIsLoansContractListLoadFailed>(
    selectIsLoansContractListLoadFailed
  );

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const { customerId, customerCountryId } = activeCustomer || {};

  const getOverviewAsyncMemoized = useCallback(() => {
    if (customerId && customerCountryId) {
      dispatch(
        getOverviewAsync({
          customerCode: customerId,
          customerCountryCode: customerCountryId,
          customerType: 'B',
          productType: type
        })
      );
    }
  }, [customerCountryId, customerId, dispatch, type]);

  useEffect(() => {
    getOverviewAsyncMemoized();
  }, [getOverviewAsyncMemoized]);

  return {
    data: loadedType === type ? contracts : [],
    isLoading: isContractsLoading,
    isError: isContractsLoadingFailed,
    isUnintialized: false,
    refetch: getOverviewAsyncMemoized
  };
};
