import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AddsParams } from 'utils/tracking';

import { AppState, GLOBAL_MESSAGE_ID, LoginWarnings, Toast } from './types';

export const initialState: AppState = {
  toasts: [],
  adds: {}
};

type ActionToast = Omit<Toast, 'status' | 'message'>;

const createUserCRUDMessage = (
  state: AppState,
  type: 'success' | 'error',
  action: PayloadAction<ActionToast>
) => {
  {
    const id = type === 'success' ? GLOBAL_MESSAGE_ID.USER_CRUD_SUCCESS : GLOBAL_MESSAGE_ID.USER_CRUD_ERROR;
    const useCRUDMessageIndex = state.toasts.findIndex((toast) => toast.id === id);

    if (useCRUDMessageIndex !== -1) {
      state.toasts[useCRUDMessageIndex].header = action.payload.header;
      state.toasts[useCRUDMessageIndex].action = action.payload.action;
      state.toasts[useCRUDMessageIndex].status = type;
    } else {
      state.toasts = [
        ...state.toasts,
        {
          id: id,
          ...action.payload,
          message: '',
          status: type
        }
      ];
    }
  }
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToastMessage: (state, action: PayloadAction<Toast>) => {
      if (!state.toasts.find((toast) => toast.id !== action.payload.message)) {
        state.toasts = [{ ...action.payload }];
      }
    },
    clearToastMessage: (state, action: PayloadAction<Toast>) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload.id);
    },
    clearToastMessages: (state) => {
      state.toasts = [];
    },
    setLoginWarning: (state, action: PayloadAction<{ message: LoginWarnings; header: LoginWarnings }>) => {
      const loginToastIndex = state.toasts.findIndex((toast) => toast.id === GLOBAL_MESSAGE_ID.LOGIN_WARNING);

      if (loginToastIndex !== -1) {
        state.toasts[loginToastIndex].message = action.payload.message;
        state.toasts[loginToastIndex].header = action.payload.header;
      } else {
        state.toasts = [
          ...state.toasts,
          {
            status: 'error',
            header: action.payload.header,
            message: action.payload.message,
            id: GLOBAL_MESSAGE_ID.LOGIN_WARNING
          }
        ];
      }
    },
    setGeneralActionToast: (state, action: PayloadAction<Toast>) => {
      const generalErrorIndex = state.toasts.findIndex(
        (toast) => toast.id === GLOBAL_MESSAGE_ID.GENERAL_MESSAGE
      );

      if (generalErrorIndex !== -1) {
        state.toasts[generalErrorIndex].message = action.payload.message;
        state.toasts[generalErrorIndex].header = action.payload.header;
      } else {
        state.toasts = [
          ...state.toasts,
          {
            ...action.payload,
            id: GLOBAL_MESSAGE_ID.GENERAL_MESSAGE
          }
        ];
      }
    },
    clearGeneralActionToast: (state) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== GLOBAL_MESSAGE_ID.GENERAL_MESSAGE);
    },
    clearLoginWarning: (state) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== GLOBAL_MESSAGE_ID.LOGIN_WARNING);
    },
    setUserCRUDSuccessMessage: (state, action: PayloadAction<ActionToast>) =>
      createUserCRUDMessage(state, 'success', action),
    setUserCRUDErrorMessage: (state, action: PayloadAction<ActionToast>) =>
      createUserCRUDMessage(state, 'error', action),
    clearUserCRUDMessage: (state) => {
      state.toasts = state.toasts.filter(
        (toast) =>
          toast.id !== GLOBAL_MESSAGE_ID.USER_CRUD_SUCCESS && toast.id !== GLOBAL_MESSAGE_ID.USER_CRUD_ERROR
      );
    },
    setAdds: (state, action: PayloadAction<Partial<AddsParams>>) => {
      state.adds = { ...state.adds, ...action.payload };
    },
    resetAppState: () => initialState
  }
});

export const {
  setToastMessage,
  clearToastMessage,
  clearToastMessages,
  setLoginWarning,
  clearLoginWarning,
  resetAppState,
  setGeneralActionToast,
  setUserCRUDErrorMessage,
  setUserCRUDSuccessMessage,
  clearUserCRUDMessage,
  clearGeneralActionToast,
  setAdds
} = appSlice.actions;

export default appSlice.reducer;
