import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'components';
import { getAuthTokens } from 'features/auth/utils';
import { AppService } from 'services';
import { refreshAuthLogic } from 'services/RestClient';

const questionaireHost = VARIABLES.companiesQuestionaire as string;

const whitelistedOrigins = [questionaireHost];

type KYCApplicationInfo = {
  companyName: string;
  companyCode: string;
  country?: string;
  identificationId: string;
};

type Payload = {
  sessionId?: string;
  refreshToken?: string;
  customerId?: string;
  email?: string;
  redirectUrl?: string;
  kycInfo?: KYCApplicationInfo;
};

type TypedMessageEvent = MessageEvent<{
  message: 'redirect';
  data: Payload;
}>;

export function postMessage(reference: HTMLIFrameElement['contentWindow'], data: Payload) {
  const authTokens = getAuthTokens();

  reference?.postMessage({ message: 'redirect', data: { ...data, ...authTokens } }, questionaireHost);
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const Onboarding = () => {
  const navigate = useNavigate();

  const isInIframe = inIframe();

  useEffect(() => {
    window.addEventListener('message', async (event: TypedMessageEvent) => {
      if (whitelistedOrigins.includes(event.origin)) {
        if (event.data.message === 'redirect') {
          AppService.reportError({ message: `Origin ${event.origin} not whitelisted` });
        }

        return;
      }

      if (event.data.message === 'redirect') {
        sessionStorage.setItem('redirectPayload', JSON.stringify(event.data.data));
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!isInIframe) {
        const stringifiedRedirectPayload = sessionStorage.getItem('redirectPayload');
        const redirectPayload = JSON.parse(stringifiedRedirectPayload ?? '{}') as Payload;

        const searchParams = new URLSearchParams(window.location.search);

        const redirectTo = searchParams.get('redirectTo');
        const onboardingSuccess = searchParams.get('onboardingSuccess');

        const resolvedRedirectTo = `${redirectTo || '/dashboard/verification'}${onboardingSuccess ? '?onboardingSuccess=true' : ''}`;

        if (redirectPayload.refreshToken) {
          sessionStorage.setItem('refreshToken', redirectPayload.refreshToken);

          try {
            await refreshAuthLogic(null, redirectPayload.customerId, true);
          } catch {
            AppService.reportError({ message: 'SSO failed for onboarding. Refresh failed' });
          } finally {
            navigate(resolvedRedirectTo);
          }

          return;
        }

        AppService.reportError({ message: 'SSO failed for onboarding. Missing refreshToken' });

        navigate(resolvedRedirectTo);
      }
    })();
  }, [isInIframe]);

  return <Loader />;
};
