import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import success from '../../assets/images/success.png';
import { Modal } from '../modal';

import { SuccessMessage, SuccessTitle, SuccessTextWrapper, Image } from './sucessModal.styles';

type Props = {
  isOpen?: boolean;
  onClose?: () => unknown;
  header?: string;
  text?: string;
};

export const SuccessModal: FC<Props> = ({ header, text, isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      variant="informational"
      isOpen={!!isOpen}
      footerSecondaryAction={false}
      testId="success"
      mainActionhandler={onClose}
      overlayClickhandler={onClose}
      mainActionLabel={t('greatSuccess')}
      informationalImage={<Image src={success} alt="success" />}
    >
      <SuccessTextWrapper>
        {header ? <SuccessTitle>{t(header)}</SuccessTitle> : null}
        {text ? <SuccessMessage>{t(text)}</SuccessMessage> : null}
      </SuccessTextWrapper>
    </Modal>
  );
};
