import { createSelector } from '@reduxjs/toolkit';
import { TFunction } from 'react-i18next';

import { formatNumber, formatPercentage } from 'components';
import { isFeatureEnabled } from 'defaultConfiguration/isFeatureEnabled';
import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { selectIsUserGhost } from 'features/auth/selectors';
import { CreateApplicationRequest, Type } from 'services/CapService/types';
import { RootState } from 'store';
import { camelCase } from 'utils';

import { getStepsByProductType } from './forms/sequences';
import { ApplicationState } from './types/applicationTypes';

export const selectApplicationLoadFailed = (state: ApplicationState) => !state.isLoading && state.loadFailed;
export const selectApplicationIsLoading = (state: ApplicationState) => state.isLoading;
export const selectProduct = (state: ApplicationState) => state.application?.product;
export const selectProductType = (state: ApplicationState) => state.application?.type;
export const selectSubProductType = (state: ApplicationState) => state.application?.subProduct;
export const selectCapId = (state: ApplicationState) => state.application?.capId;
export const selectAmount = (state: ApplicationState) => state.application?.generalInfo?.requestedAmount;
export const selectCompany = (state: ApplicationState) => state.application?.generalInfo?.company;
export const selectFarm = (state: ApplicationState) => state.application?.generalInfo?.farm;
export const selectFarmerCountry = (state: ApplicationState) => selectFarm(state)?.country;
export const selectCompanyCountry = (state: ApplicationState) =>
  selectCompany(state)?.country ? selectCompany(state)?.country : selectFarm(state)?.country;

export const selectCompanyInfo = createSelector(selectCompany, (company) => {
  if (!company?.name && !company?.code) return null;

  return {
    name: company?.name,
    code: company?.code
  };
});
export const selectContactPerson = (state: ApplicationState) => state.application?.generalInfo?.applicant;
export const selectFarmerDetails = (state: ApplicationState) => {
  const { farm } = state.application.generalInfo ?? {};

  if (!farm?.firstName && !farm?.lastName && !farm?.personalCode) return null;

  return {
    firstName: farm.firstName,
    lastName: farm.lastName,
    personalCode: farm.personalCode
  };
};
export const selectFarmCode = (state: ApplicationState) => state.application?.generalInfo?.farm?.code;
export const selectConsentInfo = (state: ApplicationState) => state.application?.applicationConsent;
export const selectFactoringType = (state: ApplicationState) => state.application?.factoringAdditionalInfo;
export const selectBeneficialOwners = (state: ApplicationState) => state.application?.beneficialOwners;
export const selectOrgChartFileName = (state: ApplicationState) => state.application?.orgChartFileName;
export const selectThirdParties = (state: ApplicationState) => state.application?.thirdParties;
export const selectLoansAdditionalInfo = (state: ApplicationState) => state.application?.loansAdditionalInfo;
export const selectActiveStep = (state: ApplicationState) => state.activeStep;
export const selectStepsSequence = (state: ApplicationState) => state.sequence;
export const selectCompletionIndex = (state: ApplicationState) => state.completionIndex;
export const selectNavigateTo = (state: ApplicationState) => state.navigateTo;
export const selectIsNewCompany = (state: RootState) =>
  state.application.newCompany || !selectIsActiveCompanyVerified(state);
export const selectCompanyRegistrationCountryCode = (state: ApplicationState) =>
  state.application.generalInfo?.company?.country;
export const selectIsPrefilledFromWeb = () => false;
export const selectPaymentDetails = (state: ApplicationState) => state.application.paymentDetails;
export const selectRedirectUrl = (state: ApplicationState) =>
  state.application.paymentDetails?.leasingFormLink;
export const selectIsFarm = (state: RootState) => Boolean(state.application.application.generalInfo?.farm);
export const selectProductPayload = (state: ApplicationState) => (type: Type) => ({
  partner: state.application.partner,
  partnerType: state.application.partnerType,
  telem: state.application.telem,
  dealId: state.application.dealId,
  generalInfo: {
    ...state.application.generalInfo,
    requestedAmount:
      state.application.paymentDetails?.propertyPrice || state.application.generalInfo?.requestedAmount
  },
  applicationConsent: state.application.applicationConsent,
  type,
  leasingSubProduct: type === 'LEASING' ? state.application.subProduct?.toUpperCase() : undefined,
  paymentDetails: {
    ...state.application.paymentDetails,
    uniqueId: isFeatureEnabled('leasingUniqueId') ? state.application.paymentDetails?.uniqueId : undefined
  }
});

const selectCommonInfo = (state: ApplicationState): CreateApplicationRequest => ({
  dealId: state.application.dealId,
  type: state.application.type,
  telem: state.application.telem,
  generalInfo: state.application.generalInfo,
  product: state.application.product,
  applicationConsent: state.application.applicationConsent,
  applicationNumber: state.application.applicationNumber
});

export const selectLoansPayload = (state: ApplicationState): CreateApplicationRequest => ({
  ...selectCommonInfo(state),
  subProduct: state.application.subProduct,
  loansAdditionalInfo: state.application.loansAdditionalInfo,
  beneficialOwners: state.application.beneficialOwners
});

export const selectLeasingPayload = (state: ApplicationState): CreateApplicationRequest => ({
  ...selectCommonInfo(state),
  subProduct: state.application.subProduct,
  loansAdditionalInfo: state.application.loansAdditionalInfo,
  paymentDetails: state.application.paymentDetails,
  beneficialOwners: state.application.beneficialOwners
});

export const selectFactoringPayload = (state: ApplicationState): CreateApplicationRequest => ({
  ...selectCommonInfo(state),
  thirdParties: state.application.thirdParties,
  factoringAdditionalInfo: state.application.factoringAdditionalInfo,
  beneficialOwners: state.application.beneficialOwners
});

export const selectCreditLinePayload = (state: ApplicationState): CreateApplicationRequest => ({
  ...selectCommonInfo(state),
  subProduct: state.application.subProduct,
  loansAdditionalInfo: state.application.loansAdditionalInfo,
  beneficialOwners: state.application.beneficialOwners
});

export const selectApplicationData = (state: ApplicationState) => {
  switch (state.application.type) {
    case 'FACTORING':
      return selectFactoringPayload(state);
    case 'LOAN':
      return selectLoansPayload(state);
    case 'LEASING':
      return selectLeasingPayload(state);
    case 'CREDIT_LINE':
      return selectCreditLinePayload(state);
    default:
      return selectCommonInfo(state);
  }
};

export const selectIsCompanyStepsEnabled = (state: RootState) => {
  const isNewCompany = selectIsNewCompany(state);
  const capId = selectCapId(state.application);

  const isGhostUser = selectIsUserGhost(state.auth);
  const isVerifiedCompany = selectIsActiveCompanyVerified(state);

  return isNewCompany || isGhostUser || (!isVerifiedCompany && capId);
};

export const selectStepsSequenceByType = (state: RootState) => {
  const productType = selectProductType(state.application);
  const isFarmer = selectIsFarm(state);

  const isCompanyStepsEnabled = selectIsCompanyStepsEnabled(state);

  return getStepsByProductType(productType, !isCompanyStepsEnabled, isFarmer);
};

export const selectProductContractInfo = (state: ApplicationState) => (language: string, t: TFunction) => {
  const percentage = formatPercentage(language);
  const amount = formatNumber(language);

  const { interestRate, marginPercentage, term, administrationFee, euriborRate, monthlyAdministrationFee } =
    state.application.loansAdditionalInfo ?? {};

  const floatingInterestRate =
    euriborRate && marginPercentage
      ? `${percentage(marginPercentage, 0)} + ${t(camelCase(euriborRate))}`
      : null;

  const anualInterestRate =
    interestRate === 'FLOATING' ? floatingInterestRate : percentage(marginPercentage, 0);

  //TODO add mapping for leasing only fields once BE ready
  return {
    term,
    advancePayment: 0,
    advancePaymentFormated: `(${percentage(0, 0)}) ${amount(0)}`,
    residualAmount: 0,
    residualAmountFormated: `(${percentage(0, 0)}) ${amount(0)}`,
    propertyPrice: 0,
    propertyPriceFormated: amount(0),
    anualInterestRate,
    administrationFee,
    monthlyAdministrationFee
  };
};

export const selectShouldOpenBankAccount = (state: ApplicationState) => {
  const loansAdditionalInfo = selectLoansAdditionalInfo(state) ?? {};

  return (
    loansAdditionalInfo?.bankAccountOpen === 'WILL_OPEN' || loansAdditionalInfo?.bankAccountOpen === 'YES'
  );
};
