import { FC, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AmountText, Currency, RegularText, Table } from 'components';
import { selectActiveCustomer } from 'features/auth/selectors';
import { selectUpcommingPayments } from 'features/factoringInvoices/selectors';
import { useGetBillsQuery } from 'features/factoringInvoices/slice';
import { UpcommingPaymentItem } from 'features/invoices/types';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { Bill } from 'services/FactoringInvoicesService/types';
import { camelCase } from 'utils';

import { renderInvoiceStatusChip } from '../billsTable/createTableColumns';

import { UpcomingPaymentsTableContainer } from './upcomingPaymentsTable.styles';

type TableRowItem = UpcommingPaymentItem & Bill & { toggleSelected?: never };

export type Sort = { dataColumnId?: string | number; sortOrder: SortOrder };

export const withNullCheck = (value?: string | number | boolean) => value || '';

type Props = {
  minBlockSize?: number;
};

const UpcomingPaymentsTable: FC<Props> = ({ minBlockSize }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const [sortOrder, setSortOrder] = useState<string>();

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const partyId = activeCustomer?.customerCrmId || '';

  const billsQueryPayload = {
    partyId,
    contractIds: contractId || '',
    billStatus: 'UNPAID',
    size: '5',
    page: '1',
    sort: sortOrder
  };

  const { isFetching, isError } = useGetBillsQuery(billsQueryPayload, {
    skip: !partyId || !contractId
  });
  const { bills } = useAppSelector(selectUpcommingPayments)(billsQueryPayload);

  const sortedBills = bills?.sort((a, b) => {
    if (a.hasOverdue && !b.hasOverdue) {
      return 1;
    }

    if (!a.hasOverdue && b.hasOverdue) {
      return -1;
    }

    if (a.hasOverdue && b.hasOverdue) {
      if (a.dueDate && b.dueDate) {
        return new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime();
      }
    }

    if (a.dueDate && b.dueDate) {
      return new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime();
    }

    return 0;
  });

  const noItems = !bills || (!bills.length && !isError);

  const columns: TableColumn<TableRowItem>[] = [
    {
      selector: (row) => withNullCheck(row.tenantName),
      name: t('company'),
      id: 'company',
      sortField: 'tenantName',
      format: (row) => <RegularText>{row.tenantName}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.number),
      name: t('number'),
      id: 'number',
      sortField: 'number',
      format: (row) => <RegularText>{row.number}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.invoiceFeeType),
      name: t('type'),
      id: 'invoiceFeeType',
      sortField: 'invoiceFeeType',
      format: (row) => <RegularText>{t(camelCase(row.invoiceFeeType || ''))}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.dueDate),
      name: t('dueDate'),
      id: 'dueDate',
      sortField: 'dueDate',
      format: (row) => <RegularText>{row.dueDate}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.amountUnpaid?.amount),
      name: t('unpaid'),
      id: 'amountUnpaid',
      sortField: 'amountUnpaid',
      conditionalCellStyles: [
        {
          when: () => true,
          classNames: ['table-cell_amount_type']
        }
      ],
      format: (row) => {
        return (
          <RegularText>
            <AmountText currency={(row.amountUnpaid?.currency as Currency) || undefined}>
              {row.amountUnpaid?.amount}
            </AmountText>
          </RegularText>
        );
      }
    },
    {
      selector: (row) => withNullCheck(row.invoiceStatus),
      name: t('status'),
      id: 'invoiceStatus',
      sortField: 'invoiceStatus',
      format: (row) => {
        if (row.invoiceStatus) {
          return renderInvoiceStatusChip(row, t);
        }

        return null;
      }
    }
  ];

  const handleSort = (column: TableColumn<TableRowItem>, sortDirection: SortOrder) => {
    const { sortField } = column || {};

    setSortOrder(sortField ? `${sortField},${(sortDirection || 'asc').toUpperCase()}` : '');
  };

  return (
    <UpcomingPaymentsTableContainer>
      <Table<TableRowItem>
        noDataMessage={t(noItems ? 'noDuePayments' : 'noListItems')}
        onSort={handleSort}
        progressPending={isFetching}
        columns={columns}
        data={(sortedBills || []).slice(0, 5)}
        minNoDataContainerHeight={minBlockSize}
        paginationPerPage={5}
      />
    </UpcomingPaymentsTableContainer>
  );
};

export { UpcomingPaymentsTable };
