import { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import pencil from 'assets/icons/pencilOutline.svg';
import { LinkButton, ListItem, ListItemButton } from 'components';
import { FormContainer } from 'features/application/components/formContainer';
import { useFormContext } from 'features/application/context/FormContext';
import { DrawerImplementation } from 'features/application/drawers';
import { selectCustomerInformation, selectIsUserGhost, selectUserData } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { isDrawerOpen } from 'features/nav/hooks';
import { useAppDispatch, useApplicationSelector, useAuthSelector } from 'hooks/redux/hooks';
import { Applicant, ApplicationConsent } from 'services/CapService/types';
import { emailRequired, personNameRequired, phoneNumberRequired } from 'validators';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { selectConsentInfo, selectContactPerson } from '../../selectors';
import { setApplicantData, setApplicationConsent, submitApplicationAsync } from '../../slice';
import { FormStyle } from '../company/company.style';
import utils, { ObjectHelpers } from '../utils/objectHelpers';

import { ContactFields } from './contactFields';

export type ApplicationFields = Applicant & ApplicationConsent & { isSubmitting?: boolean };

const ommitEntries = (toFilter: object, filterBy: object) => {
  const entriesToFilter = Object.entries(toFilter);

  const filteredEntries = entriesToFilter.filter(([key]) => !Object.keys(filterBy).includes(key));

  return Object.fromEntries(filteredEntries);
};

export const validationSchema = Yup.object({
  firstName: personNameRequired,
  lastName: personNameRequired,
  email: emailRequired,
  phoneNumber: phoneNumberRequired,
  jobTitle: Yup.string().optional().max(100, VALIDATION_MESSAGE_ID.MAX_LENGTH),

  dataHandling: Yup.boolean().when('isSubmitting', (isSubmitting: boolean) => {
    if (isSubmitting) {
      return Yup.boolean().required(VALIDATION_MESSAGE_ID.REQUIRED).isTrue(VALIDATION_MESSAGE_ID.REQUIRED);
    }

    return Yup.boolean().optional();
  }),
  marketing: Yup.boolean().optional()
});

export const initialApplicant: Applicant = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  jobTitle: ''
};

const initialConsent: ApplicationConsent = {
  dataHandling: false,
  marketing: false
};

type Props = {
  phoneNumber?: string;
  setPhoneNumber: (phoneNumber: string) => void;
};

const ContactForm: FC<Props> = ({ phoneNumber, setPhoneNumber }) => {
  const isGhostUser = useAuthSelector(selectIsUserGhost);
  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const contactPerson = useApplicationSelector<typeof selectContactPerson>(selectContactPerson) ?? {};
  const { email } = useAuthSelector(selectUserData);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { setFormHandlers } = useFormContext<ApplicationFields>();
  const { toggleRightSideDrawer, hideSideDrawer } = useNavContext<DrawerImplementation<'contact'>>();

  const values = useRef({});

  const applicantData = utils.copyReadOnlyObjectValues(values.current, contactPerson ?? {});

  const consentData = useApplicationSelector<typeof selectConsentInfo>(selectConsentInfo) ?? initialConsent;

  const { userFirstName, userLastName } = customerInfo || {};

  const fillFormWithData = () => {
    const uploadedApplicantAndConsent = {
      ...ObjectHelpers(applicantData).map((currentValue) => (currentValue !== null ? currentValue : '')),
      ...consentData,
      email
    };

    if (isGhostUser) return { ...uploadedApplicantAndConsent, email };

    return {
      ...uploadedApplicantAndConsent,
      email,
      firstName: userFirstName,
      lastName: userLastName,
      phoneNumber
    };
  };

  const formData = useMemo(
    () => fillFormWithData(),
    [
      ...Object.values(applicantData),
      ...Object.values(consentData),
      email,
      ...Object.values(customerInfo ?? {})
    ]
  );

  const {
    validationHelpers,
    getValues,
    setTouchedOnAll,
    setTouchedOn,
    handleSubmit,
    setValueWithoutValidation,
    isSubmitting
  } = useFormHandlers<ApplicationFields>(formData, validationSchema, 'onBlur');

  const getInitials = () =>
    `${userFirstName?.charAt(0)?.toLocaleUpperCase()}${userLastName?.charAt(0)?.toLocaleUpperCase()}`;

  const submitHandler = handleSubmit(
    (data: ApplicationFields) => {
      setPhoneNumber(data.phoneNumber || '');
      dispatch(setApplicantData(ommitEntries(data, initialConsent)));

      if (!isDrawerOpen()) {
        dispatch(setApplicationConsent(ommitEntries(data, initialApplicant) as ApplicationConsent));
        dispatch(submitApplicationAsync());
      } else {
        hideSideDrawer?.();
      }
    },
    () => {
      if (isDrawerOpen()) {
        setTouchedOn(['firstName', 'lastName', 'email', 'phoneNumber']);
      } else {
        setTouchedOnAll();
      }
    }
  );

  useEffect(() => {
    if (!isDrawerOpen()) {
      setValueWithoutValidation('isSubmitting', isSubmitting);
    }

    setFormHandlers({
      submitHandler,
      getValues,
      validationHelpers
    });
  }, [setFormHandlers, isSubmitting, validationHelpers.isValid]);

  const onEditClick = () => toggleRightSideDrawer?.({ drawerId: 'contact' });

  const isTermsAgreed = getValues('dataHandling');
  const phoneNumberFieldState = validationHelpers.getFieldState('phoneNumber');
  const phoneNumberValue = getValues('phoneNumber');

  const phoneNumberLongEnough = (phoneNumberValue?.length ?? 0) > 4;

  return (
    <FormContainer isValid={isTermsAgreed && validationHelpers.isValid} onNextClick={submitHandler}>
      <FormStyle>
        {isGhostUser ? (
          <ContactFields
            getValues={getValues}
            validationHelpers={validationHelpers}
            isGhostUser={isGhostUser}
            setPhoneNumber={setPhoneNumber}
          />
        ) : (
          <ListItemButton onClick={onEditClick}>
            <ListItem
              title={`${userFirstName} ${userLastName}`}
              subtitle={
                phoneNumberLongEnough
                  ? phoneNumberValue
                  : phoneNumberFieldState.invalid || !validationHelpers.isValid
                    ? t('missingPhoneNumber')
                    : phoneNumberValue
              }
              invalid={!phoneNumberLongEnough || phoneNumberFieldState.invalid}
              content={getInitials()}
            >
              <LinkButton color="Grey" iconOnly icons={{ left: pencil }} onClick={onEditClick} />
            </ListItem>
          </ListItemButton>
        )}
      </FormStyle>
    </FormContainer>
  );
};

export default ContactForm;
