import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/buttonV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { useAppDispatch, useLoansSelector } from 'hooks/redux/hooks';

import {
  selectLoansUpcomingPaymentsTableItems,
  selectIsLoansPaymentsListLoading,
  selectChosenLoansContractInfo,
  selectIsLoansPaymentsHasNoData
} from '../selectors';
import { getPaymentsAsync } from '../slice';

import { StatusToChipColorMap, UpcomingPaymentsTable } from './upcomingPayments/upcomingPaymentsTable';

export const withNullCheck = (value?: string | number | boolean) => value || '';

type Props = {
  navigateTo: string;
};

const statusToChipColorMap: StatusToChipColorMap = {
  UNPAID: 'yellow',
  PARTIALLY_PAID: 'green',
  OVERDUE: 'red',
  PAID: 'gray',
  UNKNOWN: 'gray'
};

export const CreditLineUpcomingPayments: FC<Props> = ({ navigateTo }) => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const payments = useLoansSelector<typeof selectLoansUpcomingPaymentsTableItems>(
    selectLoansUpcomingPaymentsTableItems
  );
  const isPaymentsLoading = useLoansSelector<typeof selectIsLoansPaymentsListLoading>(
    selectIsLoansPaymentsListLoading
  );
  const selectedContract = useLoansSelector<typeof selectChosenLoansContractInfo>(
    selectChosenLoansContractInfo
  )(contractId || '');
  const noDuePayments = useLoansSelector<typeof selectIsLoansPaymentsHasNoData>(
    selectIsLoansPaymentsHasNoData
  );

  const { financierId, customerId } = selectedContract || {};

  useEffect(() => {
    if (contractId && customerId && financierId) {
      dispatch(
        getPaymentsAsync({
          contractNo: contractId,
          financierId,
          customerId,
          pageNo: '1',
          pageSize: '5'
        })
      );
    }
  }, [contractId, customerId, financierId, dispatch]);

  const handleNavigation = () => {
    navigate(navigateTo);
  };

  return (
    <UpcomingPaymentsTable
      noItems={noDuePayments}
      footerNavigation={
        isFeatureEnabled('bills') ? (
          <Button variant="Filled" color="Blue" onClick={handleNavigation}>
            {t('allBills')}
          </Button>
        ) : undefined
      }
      contractNumber={contractId || ''}
      loading={isPaymentsLoading}
      items={payments}
      statusToChipColorMap={statusToChipColorMap}
    />
  );
};
