import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --spa-globals-top-banner-height: 0;
  }

  html {
    --scrollbar-width: 0.1875rem;
    --scrollbar-offset: calc(100vw - 100dvw);
    font-size: 100%;
    scroll-padding-top: 5rem;
    height: 100dvh;
    background-color: #F4F4F5;
  }

  body {
    display: flex;
    margin: 0;
    min-height: 100dvh;
    height: 100%;
    width: 100%;
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F4F4F5;
    position: relative;
    overflow-x: hidden;
    color: #1A1A1A; // black[90] as default text color

    @media (max-width: 1200px) {
      overflow-y: scroll;
    }
  }

  button:focus {
    outline: none;
  }

  body.drawer-focused {
    @media (max-width: 1024px) {
      overflow-y: hidden !important;
      padding-right: var(--windowScrollbar-offset); // add padding to compensate for hidden scrollbar (offset determined via hook on page load)
    }
  }

  * {
    font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-width);
    border-radius: 6.25rem;
  }

  ::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-radius: 0.375rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    border-radius: 0.375rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #727272;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #smesp-root {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    width: 100%;
  }

  * {
    box-sizing: border-box;
  }


  #CookiebotWidget.CookiebotWidget-open {
    background-color: transparent !important;
    border-radius: 24px !important;
  }

  #CookiebotWidget.CookiebotWidget-open #CookiebotWidget-widgetContent {
    border-radius: 1.5rem !important;
  }

  #CookiebotWidget #CookiebotWidget-buttons button {
    border: 1px solid !important;
    border-radius: 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5rem !important;
  }

  #CookiebotWidget #CookiebotWidget-buttons button:hover {
    background-color: #2250C4 !important;
    opacity: 1 !important;
    transition: background-color 0.3s ease-out !important;
  }

  #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw:hover {
    background-color: transparent !important;
  }

  .CookiebotWidget-body-inner {
    padding: 0 1.125rem !important;
  }

  #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list {
    margin-bottom: 1.5rem !important;
  }

  .page-bottom-anchor {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
  }
`;
