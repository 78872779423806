import styled from 'styled-components';

import { RegularText, SecondaryText } from 'components';

export const ContentContainer = styled.div`
  @media ${({ theme }) => theme.media.maxTablet} {
    background-color: ${({ theme }) => theme.colors.base.white.functional};
    border-radius: 1rem;
  }

  ${RegularText} {
    line-height: 1.5rem;
  }

  ${SecondaryText} {
    font-size: ${({ theme }) => theme.fontSizes.h6};
    display: block;
    line-height: 1.25rem;
  }
`;
