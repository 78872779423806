import { useTranslation } from 'react-i18next';

import { useNavContext } from 'features/nav/context/navContext';

import { Drawer } from '../drawer';

import { type Props, default as InformationDrawerContent } from './informationDrawerContent';

const InformationDrawer = (props: Props) => {
  const { t } = useTranslation();

  const { hideSideDrawer } = useNavContext();

  const handleClose = () => {
    hideSideDrawer?.();
  };

  return (
    <Drawer
      isValid
      dataTestId="information-drawer"
      onSave={handleClose}
      disabledDelete
      onClose={handleClose}
      buttonLabel={t('gotIt')}
    >
      <InformationDrawerContent {...props} />
    </Drawer>
  );
};

export default InformationDrawer;
