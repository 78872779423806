import { FC, MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LinkButton, SecondaryText, Section } from 'components';
import { Button } from 'components/buttonV2';
import { useFetchFactoringContracts } from 'features/contracts/hooks';
import { UpcomingPaymentsTable as FactoringInvoicesUpcomingPaymentsTable } from 'features/factoringInvoices/tables';
import { selectIsInvoicesHasNoItems } from 'features/invoices/selectors';
import { UpcomingPaymentsTable as InvoicesUpcomingPaymentsTable } from 'features/invoices/tables';
import { UpcomingPaymentsHeader } from 'features/loans/tables/upcomingPayments/upcomingPaymentsTable.styles';
import { useInvoicesSelector } from 'hooks/redux/hooks';

import { DataTypeSelector, DataTypeOption } from '../components/filterPanel/filterPanel.styles';

export const FactoringUpcomingPayments: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const [minBlockSize] = useState(0);

  const [selectedDataSource, setSelectedDataSource] = useState('invoices');

  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const { currentData } = useFetchFactoringContracts();

  const { contracts } = currentData || {};

  const selectedContract = contracts?.find((contract) => contract.id === contractId);

  const noInvoices = useInvoicesSelector<typeof selectIsInvoicesHasNoItems>(selectIsInvoicesHasNoItems);

  const { contractNumber } = selectedContract || {};

  const handleInvoicesNavigation = () => {
    navigate('/finance/factoring/invoices#invoices');
  };

  const handleBillsNavigation = () => {
    navigate('/finance/factoring/bills#bills');
  };

  return (
    <Section
      header={
        <UpcomingPaymentsHeader>
          <div>
            {t('upcomingPayments')} <SecondaryText>({contractNumber})</SecondaryText>
          </div>
          <DataTypeSelector>
            <DataTypeOption
              data-testid={`filter-panel-data-type-toggle${
                selectedDataSource === 'invoices' ? '-active' : ''
              }`}
              active={selectedDataSource === 'invoices'}
            >
              <LinkButton
                data-testid="filter-panel-data-type-toggle-used"
                onClick={() => setSelectedDataSource?.('invoices')}
              >
                {t('invoices')}
              </LinkButton>
            </DataTypeOption>
            <DataTypeOption
              data-testid={`filter-panel-data-type-toggle${selectedDataSource === 'bills' ? '' : '-active'}`}
              active={selectedDataSource === 'bills'}
            >
              <LinkButton
                data-testid="filter-panel-data-type-toggle-available"
                onClick={() => setSelectedDataSource?.('bills')}
              >
                {t('bills')}
              </LinkButton>
            </DataTypeOption>
          </DataTypeSelector>
        </UpcomingPaymentsHeader>
      }
      footerButton={
        !noInvoices ? (
          <>
            <Button variant="Filled" color="Blue" onClick={handleInvoicesNavigation}>
              {t('allInvoices')}
            </Button>
            <Button variant="Filled" color="Blue" onClick={handleBillsNavigation}>
              {t('allBills')}
            </Button>
          </>
        ) : null
      }
    >
      <div ref={tableContainerRef} style={{ minBlockSize: minBlockSize }}>
        {selectedDataSource === 'invoices' ? (
          <InvoicesUpcomingPaymentsTable minBlockSize={minBlockSize} />
        ) : (
          <FactoringInvoicesUpcomingPaymentsTable minBlockSize={minBlockSize} />
        )}
      </div>
    </Section>
  );
};
