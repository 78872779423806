import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Applicant,
  ApplicationConsent,
  BeneficialOwner,
  Company,
  CreateApplicationResponse,
  FactoringAdditionalInfo,
  Farm,
  LoansAdditionalInfo,
  Partnership,
  PaymentDetails,
  ThirdParty,
  Type
} from 'services/CapService/types';

import { createSMELeasingFormLink } from './forms/priceAndPaymentDetails/utils';
import { Flows, getStepsArrayByFlow, sequences } from './forms/sequences';
import { openPagesSubproductsMapping, products } from './forms/subproducts';
import { QueryPayload } from './hooks/useQueryFields';
import { ApplicationState, FINANCIAL_PRODUCT, STEPS } from './types/applicationTypes';

export const initialState: ApplicationState = {
  isLoading: false,
  loadFailed: false,
  newCompany: false,
  activeStep: STEPS.REGISTRATION_COUNTRY,
  completionIndex: -1,
  sequence: getStepsArrayByFlow('createApplication', sequences),
  application: {},
  isFarm: undefined
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplication: (state, action: PayloadAction<ApplicationState['application']>) => {
      state.application = { ...state.application, ...action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setLoadFailed: (state, action: PayloadAction<boolean>) => {
      state.loadFailed = action.payload;
    },
    setCapId: (state, action: PayloadAction<string>) => {
      if (state.application) {
        state.application.capId = action.payload;
      }
    },
    setIsNewCompany: (state, action: PayloadAction<boolean | undefined>) => {
      state.newCompany = action.payload;
    },
    setIsFarm: (state, action: PayloadAction<boolean | undefined>) => {
      state.isFarm = action.payload;
    },
    setStepsSequence: (state, action: PayloadAction<STEPS[]>) => {
      state.sequence = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<STEPS>) => {
      state.activeStep = action.payload;
    },
    setCompletionIndex: (state, action: PayloadAction<number>) => {
      state.completionIndex = action.payload;
    },
    increaseCompletionIndex: (state) => {
      state.completionIndex += 1;
    },
    setApplicationType: (state, action: PayloadAction<Type>) => {
      state.application.type = action.payload;
    },
    setApplicationProduct: (state, action: PayloadAction<FINANCIAL_PRODUCT>) => {
      state.application.product = action.payload as unknown as typeof state.application.product;
    },
    setApplicationSubProduct: (state, action: PayloadAction<string | undefined>) => {
      state.application.subProduct = action.payload as typeof state.application.subProduct;
    },
    setApplicationAmount: (state, action: PayloadAction<number | undefined>) => {
      if (state.application.generalInfo) {
        state.application.generalInfo.requestedAmount = action.payload;
      } else {
        state.application.generalInfo = { requestedAmount: action.payload };
      }
    },
    setCompanyData: (state, action: PayloadAction<Company>) => {
      if (state.application.generalInfo) {
        state.application.generalInfo.company = {
          ...state.application.generalInfo.company,
          ...action.payload
        };
      } else {
        state.application.generalInfo = { company: action.payload };
      }
    },
    overWriteClientCountryCode: (state, isFarmer: PayloadAction<boolean | undefined>) => {
      const countryCode = state.application.generalInfo?.company?.country
        ? state.application.generalInfo?.company?.country
        : state.application.generalInfo?.farm?.country;

      if (isFarmer.payload) {
        state.application.generalInfo = {
          farm: { country: countryCode, ...state.application.generalInfo?.farm }
        };
      } else {
        state.application.generalInfo = {
          company: { country: countryCode, ...state.application.generalInfo?.company }
        };
      }
    },
    setFarmersData: (state, action: PayloadAction<Farm>) => {
      if (state.application.generalInfo) {
        state.application.generalInfo.farm = {
          ...state.application.generalInfo.farm,
          ...action.payload
        };
      } else {
        state.application.generalInfo = { farm: action.payload };
      }
    },
    setCompanyRegistrationCountry: (state, action: PayloadAction<string>) => {
      const company = state.application?.generalInfo?.company ?? {};

      if (company && state.application?.generalInfo) {
        state.application.generalInfo.company = { ...company, country: action.payload };
      } else {
        state.application.generalInfo = { company: { country: action.payload } };
      }
    },
    setApplicantData: (state, action: PayloadAction<Applicant>) => {
      if (state.application.paymentDetails && state.application.product === FINANCIAL_PRODUCT.LEASING) {
        state.application.paymentDetails.leasingFormLink = createSMELeasingFormLink(
          state.application,
          action.payload
        );
      }

      if (state.application.generalInfo) {
        state.application.generalInfo.applicant = action.payload;
      } else {
        state.application.generalInfo = { applicant: action.payload };
      }
    },
    setPaymentDetailsInfo: (state, action: PayloadAction<PaymentDetails>) => {
      state.application.paymentDetails = action.payload;
    },
    setFactoringAdditionalInfo: (state, action: PayloadAction<FactoringAdditionalInfo>) => {
      state.application.factoringAdditionalInfo = action.payload;
    },
    setBeneficialOwnerData: (state, action: PayloadAction<BeneficialOwner[]>) => {
      state.application.beneficialOwners = action.payload;
    },
    setOrgChartFileNameData: (state, action: PayloadAction<string | undefined>) => {
      state.application.orgChartFileName = action.payload;
    },
    setThirdPartiesData: (state, action: PayloadAction<ThirdParty[]>) => {
      state.application.thirdParties = action.payload;
    },
    setMarketingConsent: (state, action: PayloadAction<boolean>) => {
      if (state.application.applicationConsent) {
        state.application.applicationConsent.marketing = action.payload;
      } else {
        state.application.applicationConsent = { marketing: action.payload };
      }
    },
    setDataHandlingConsent: (state, action: PayloadAction<boolean>) => {
      if (state.application.applicationConsent) {
        state.application.applicationConsent.dataHandling = action.payload;
      } else {
        state.application.applicationConsent = { dataHandling: action.payload };
      }
    },
    setApplicationConsent: (state, action: PayloadAction<ApplicationConsent>) => {
      state.application.applicationConsent = action.payload;
    },
    setLoansAdditionalInfo: (state, action: PayloadAction<LoansAdditionalInfo>) => {
      state.application.loansAdditionalInfo = action.payload;
    },
    createApplication: (state, action: PayloadAction<CreateApplicationResponse>) => {
      state.application = action.payload;
    },
    setNavigateTo: (state, action: PayloadAction<STEPS>) => {
      state.navigateTo = action.payload;
    },
    setFieldsFromWebRedirect: (state, action: PayloadAction<QueryPayload>) => {
      const generalInfo: typeof state.application.generalInfo = {
        requestedAmount: Number(action.payload.requiredAmount),
        company: {
          code: action.payload.registrationCode,
          country: action.payload.country,
          name: action.payload.companyName
        },
        applicant: {
          firstName: action.payload.name,
          lastName: action.payload.surname,
          phoneNumber: `+${action.payload.phone}`,
          email: action.payload.email,
          jobTitle: ''
        }
      };

      state.application.subProduct = openPagesSubproductsMapping(
        action.payload.subProductSelect
      ) as typeof state.application.subProduct;

      state.application.generalInfo = generalInfo;
      state.application.applicationConsent = {
        marketing: Boolean(action.payload.privacyAgreement),
        dataHandling: Boolean(action.payload.privacyAgreement)
      };
      state.application.dealId = action.payload.dealId;

      const missingInfo = generalInfo?.company ? !generalInfo.company.code : !generalInfo?.farm?.code;

      if (action.payload.purpose) {
        const additionalInfo = {
          term: Number(action.payload.term),
          purpose: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: action.payload.purpose as unknown as any,
            description: action.payload.other
          }
        };

        state.application.loansAdditionalInfo = additionalInfo;
      }

      if (action.payload.productSelect) {
        const productConfig = products[action.payload.productSelect?.toLocaleLowerCase()];
        const sequence = getStepsArrayByFlow(productConfig.product as Flows, sequences);

        state.application.product = productConfig.product as typeof state.application.product;
        state.application.type = productConfig.type as typeof state.application.type;
        state.sequence = sequence;
        state.activeStep = missingInfo ? STEPS.REGISTRATION_COUNTRY : STEPS.CONTACT_PERSON;
        state.completionIndex = sequence.indexOf(STEPS.CONTACT_PERSON) - 1;
      }
    },
    setPartner: (state, action: PayloadAction<Partnership>) => {
      state.application.partner = action.payload.partner;
      state.application.partnerType = action.payload.partnerType;
    },
    setTelem: (state, action: PayloadAction<string>) => {
      state.application.telem = action.payload;
    },
    resetApplicationState: (state, action: PayloadAction<STEPS | undefined>) => {
      const navigateToProductSelect = !action.payload || action.payload === STEPS.PRODUCT_TYPE;

      /**
       * Reset state properties manually this is used to ensure that query params would be persisted
       */
      state.isLoading = false;
      state.loadFailed = false;
      state.activeStep = action.payload || STEPS.REGISTRATION_COUNTRY;
      state.completionIndex = -1;
      state.sequence = getStepsArrayByFlow('createApplication', sequences);
      state.newCompany = false;
      state.isFarm = undefined;

      const marketingParams = {
        partner: state.application.partner,
        partnerType: state.application.partnerType,
        telem: state.application.telem
      };

      state.application = {
        product: navigateToProductSelect ? undefined : state.application.product,
        type: navigateToProductSelect ? undefined : state.application.type,
        ...marketingParams
      };
    }
  }
});

export const submitApplicationAsync = createAsyncThunk('application/submitApplication', async () => {
  return 0;
});

export const {
  setLoading,
  createApplication,
  setActiveStep,
  setStepsSequence,
  setApplicationType,
  setApplicationProduct,
  setApplicationAmount,
  setCompletionIndex,
  setApplication,
  setCompanyData,
  setFactoringAdditionalInfo,
  setApplicantData,
  setCompanyRegistrationCountry,
  setApplicationConsent,
  setBeneficialOwnerData,
  setOrgChartFileNameData,
  setThirdPartiesData,
  setLoansAdditionalInfo,
  setIsNewCompany,
  setIsFarm,
  resetApplicationState,
  setNavigateTo,
  setFieldsFromWebRedirect,
  increaseCompletionIndex,
  setPartner,
  setTelem,
  setPaymentDetailsInfo,
  setApplicationSubProduct,
  setMarketingConsent,
  setDataHandlingConsent,
  setCapId,
  setLoadFailed,
  setFarmersData,
  overWriteClientCountryCode
} = applicationSlice.actions;

export default applicationSlice.reducer;
