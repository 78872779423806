import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FilterPanel, Loader, LoaderContainer, Paper, Section, SectionLayout } from 'components';
import { selectIsDetailsLoading } from 'features/contracts/selectors';
import { fetchContractByIdAsync } from 'features/contracts/slice';
import { FactoringSummaryTable } from 'features/factoring/tables';
import { calculateSummary } from 'features/factoring/tables/utils';
import { FilterParams, FilterFields, defaultParams } from 'features/invoices/components/filterFields';
import {
  selectInvoicesPageCount,
  selectIsInvoicesLoading,
  selectUpcomintPaymentsTableItems
} from 'features/invoices/selectors';
import { fetchInvoicesAsync } from 'features/invoices/slice';
import { InvoiceDetailsTable, PAGE_SIZE } from 'features/invoices/tables/invoiceDetails';
import { Sort } from 'features/invoices/tables/invoiceDetails/invoiceDetailsTable';
import { useAppDispatch, useContractSelector, useInvoicesSelector } from 'hooks/redux/hooks';
import { queryBuilder } from 'services/utils/queryBuilder';

export const FactoringInvoices: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');
  const invoiceStatus = searchParams.get('invoiceStatus') || '';
  const queryPage = Number(searchParams.get('page')) || 1;

  const [filterParamsString, setFilterParamsString] = useState('');
  const [filterParams, setFilterParams] = useState({ ...defaultParams, invoiceStatus });
  const [selectedPage, setSelectedPage] = useState(queryPage);
  const [sortOrder, setSortOrder] = useState('invoiceStatus,ASC');

  const isContractDetailsLoading = useContractSelector<typeof selectIsDetailsLoading>(selectIsDetailsLoading);

  const invoicesLoading = useInvoicesSelector<typeof selectIsInvoicesLoading>(selectIsInvoicesLoading);
  const invoices = useInvoicesSelector<typeof selectUpcomintPaymentsTableItems>(
    selectUpcomintPaymentsTableItems
  );
  const pageCount = useInvoicesSelector<typeof selectInvoicesPageCount>(selectInvoicesPageCount);

  const onFilterParamsChange = (params: FilterParams) => {
    setFilterParams(params);
  };

  const onChangePage = (pageNumber: number) => {
    setSelectedPage(pageNumber);
    searchParams.set('page', `${pageNumber}`);
    setSearchParams(searchParams, { replace: true });
  };

  const onSortOrderChange = (sort: Sort) => {
    const { sortOrder, dataColumnId } = sort || { sortOrder: 'asc', dataColumnId: 'invoiceStatus' };
    setSortOrder(`${dataColumnId || 'invoiceStatus'},${sortOrder.toUpperCase()}`);
  };

  useEffect(() => {
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ block: 'start', inline: 'nearest' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (contractId) {
      const queryString = queryBuilder('', filterParams);

      if (filterParamsString !== queryString) {
        setFilterParamsString(queryString);
        setSelectedPage(1);
      } else {
        dispatch(
          fetchInvoicesAsync({
            contractId,
            ...filterParams,
            page: `${selectedPage}`,
            size: `${PAGE_SIZE}`,
            sort: sortOrder
          })
        );
      }
    }
  }, [contractId, filterParams, filterParamsString, selectedPage, sortOrder, dispatch]);

  useEffect(() => {
    if (contractId) {
      dispatch(fetchContractByIdAsync({ contractId }));
      setFilterParams({ ...defaultParams, invoiceStatus });
    }
  }, [contractId]);

  return (
    <>
      <SectionLayout>
        <Paper>
          <Section header={t('summary')}>
            <FactoringSummaryTable summaryData={calculateSummary(invoices || [])} />
          </Section>
        </Paper>
      </SectionLayout>
      <SectionLayout id="invoices">
        <Paper>
          <Section header={t('invoices')}>
            {isContractDetailsLoading ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <>
                <FilterPanel
                  appliedParams={filterParams}
                  FilterFields={FilterFields}
                  defaultParams={defaultParams}
                  onFilterParamsChange={onFilterParamsChange}
                />
                <InvoiceDetailsTable
                  activePage={selectedPage}
                  filterParams={filterParams as FilterParams}
                  totalPages={Number(pageCount)}
                  loading={invoicesLoading}
                  items={invoices}
                  onChangePage={onChangePage}
                  onSort={onSortOrderChange}
                />
              </>
            )}
          </Section>
        </Paper>
      </SectionLayout>
    </>
  );
};
