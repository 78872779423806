import { queryBuilder } from "./queryBuilder";

type OpenApiGeneratorPaths = Record<string, unknown>;

/**
 * NOTE: localhost calls are being proxied via hostname provided in package.json proxy option
 * For fetch GET calls to work with deep nested urls, format of base url needs to be strict and start with a slash
 * / - without it path which will be proxied with preappended react router path resulting in 404 responses in development environment
 * @param route
 * @returns
 */
export const baseUrlBuilder =
  (service: string) =>
  (route: string, version = 1) =>
    `/api/${service}/v${version}${route}`;


const replacePathParams = (route: string, params: Record<string, unknown>) => {
  const keys = Object.keys(params);

  if (keys.length === 0) {
    return route;
  }

  const replacedRoute = keys.reduce((acc, key) => {
    return acc.replace(`{${key}}`, params[key] as string);
  }, route);

  return replacedRoute;
}

export const typedBaseUrlBuilder =
  <TPaths extends OpenApiGeneratorPaths = OpenApiGeneratorPaths>(service: string) =>
    (route: keyof TPaths, pathParams: Record<string, unknown> = {}, queryParams: Record<string, unknown> = {}) => {
      const replacedRoute = replacePathParams(String(route), pathParams);

      return queryBuilder(`/api/${service}${replacedRoute}`, queryParams);
    };