import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setUserCRUDErrorMessage, setUserCRUDSuccessMessage } from 'features/app/slice';
import { useDeleteUserMutation } from 'features/users/slice';
import { useAppDispatch } from 'hooks/redux/hooks';

const useTriggerDeleteUser = (email?: string, onSuccess?: () => unknown, onError?: () => unknown) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [deleteRelation] = useDeleteUserMutation();

  const deleteUser = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      try {
        if (email) {
          await deleteRelation({ email }).unwrap();
        }

        dispatch(setUserCRUDSuccessMessage({ action: t('deleted') }));
        onSuccess?.();
      } catch {
        dispatch(setUserCRUDErrorMessage({ action: t('deleted') }));
        onError?.();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return { isSubmitting, deleteUser };
};

export default useTriggerDeleteUser;
