import { UserSkeletonRow, AvatarShimmer, RowShimmer } from './users.style';

const Skeleton = () => {
  return (
    <>
      <UserSkeletonRow>
        <AvatarShimmer />
        <RowShimmer />
      </UserSkeletonRow>
      <UserSkeletonRow>
        <AvatarShimmer />
        <RowShimmer />
      </UserSkeletonRow>
    </>
  );
};

export default Skeleton;
