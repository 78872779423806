import { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'components';
import { formatNumber, formatPercentage } from 'components/text/formatters';
import Typography from 'components/typography/typography';
import { getLeasingCalculatorInfo } from 'defaultConfiguration/getLeasingCalculatorInfo';
import FormContext from 'features/application/context/FormContext';
import { selectAmount, selectPaymentDetails } from 'features/application/selectors';
import { setApplicationAmount, setPaymentDetailsInfo } from 'features/application/slice';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { PaymentDetails } from 'services/CapService/types';
import { createHashId } from 'utils/createHashId';

import { FormContainer } from '../../components/formContainer';

import MonthlyPaymentBanner from './montlyPaymentBanner';
import { FieldsColumn, LinkedInputsBlock } from './priceAndPaymentDetailsForm.styles';
import { calculateMonthlyPayment } from './utils';
import { FormLimits, ValidatorReturnType, getFormLimits, validator } from './validator';

type Props = {
  onNextClick: () => void;
};

const formatToFixedDecimal = (value: number, decimals = 2) => {
  return Number(value.toFixed(decimals));
};

const calculateValueFromPercentage = (percentage: number | string, amount: number) => {
  const value = (Number(percentage) * amount) / 100;
  return formatToFixedDecimal(value);
};

const getResidualValueRangeText = (
  limits: FormLimits,
  formatter: (value?: number, decimals?: number) => string,
  term?: string | number
) => {
  const minResidualPct = limits.residualValuePercentage.min(term);
  const maxResidualPct = limits.residualValuePercentage.max(term);
  const isMinMaxEqual = minResidualPct === maxResidualPct;

  return isMinMaxEqual
    ? formatter(maxResidualPct, 0)
    : `${formatter(minResidualPct, 0)} - ${formatter(maxResidualPct, 0)}`;
};

const getAdvancePaymentRangeText = (
  limits: FormLimits,
  formatter: (value?: number, decimals?: number) => string
) => {
  const minAdvancePct = limits.advancePaymentPercentage.min();
  const maxAdvancePct = limits.advancePaymentPercentage.max();

  return `${formatter(minAdvancePct, 0)} - ${formatter(maxAdvancePct, 0)}`;
};

const getTermRangeText = (limits: FormLimits) => {
  const minTerm = limits.term.min();
  const maxTerm = limits.term.max();

  return `${minTerm} - ${maxTerm}`;
};

const getPriceRangeText = (limits: FormLimits, formatter: (value?: number, decimals?: number) => string) => {
  const minPrice = limits.propertyPrice.min();
  const maxPrice = limits.propertyPrice.max();

  return `${formatter(minPrice, 0)} - ${formatter(maxPrice, 0)}`;
};

const onLinkedInputsChange =
  (
    amount: number | string,
    setValue: Dispatch<SetStateAction<number | string>>,
    setSecondaryValue: Dispatch<SetStateAction<number | string>>
  ) =>
  (isPercentage: boolean) =>
  (value: string) => {
    if (value === '' || amount === '' || amount === '-' || value === '-') {
      setValue('');
      setSecondaryValue('');
      return;
    }

    const numberValue = Number(value);
    const numberAmount = Number(amount);

    if (isPercentage) {
      setValue(numberValue);
      setSecondaryValue(calculateValueFromPercentage(numberValue, numberAmount));
    } else {
      setValue(numberValue);
      const percentage = (numberValue / numberAmount) * 100;

      setSecondaryValue(formatToFixedDecimal(percentage));
    }
  };

const PriceAndPaymentDetails: FC<Props> = ({ onNextClick }) => {
  const { t, i18n } = useTranslation();

  const leasingDefaultConfig = getLeasingCalculatorInfo();

  const uniqueIdRef = useRef(createHashId());

  const dispatch = useAppDispatch();

  const { setFormHandlers } = useContext(FormContext);

  const formatterCurrency = formatNumber(i18n.language);
  const formatterPercentage = formatPercentage(i18n.language);

  const selectedAmount = useApplicationSelector<typeof selectAmount>(selectAmount);
  const paymentDetails = useApplicationSelector<typeof selectPaymentDetails>(selectPaymentDetails);

  const formData = { ...(paymentDetails || {}), propertyPrice: selectedAmount };

  const validateFields = validator(t, leasingDefaultConfig);
  const formLimits = getFormLimits(leasingDefaultConfig);

  const [fieldErrors, setFieldErrors] = useState<ValidatorReturnType>({});

  const [advance, setAdvance] = useState<string | number>(
    formData.advancePayment ??
      calculateValueFromPercentage(
        formLimits.advancePaymentPercentage.default,
        formLimits.propertyPrice.default
      )
  );
  const [residual, setResidual] = useState<string | number>(
    formData.residualValue ??
      calculateValueFromPercentage(
        formLimits.residualValuePercentage.default,
        formLimits.propertyPrice.default
      )
  );
  const [advancePct, setAdvancePct] = useState<string | number>(
    formData.advancePaymentPercentage ?? formLimits.advancePaymentPercentage.default
  );
  const [residualPct, setResidualPct] = useState<string | number>(
    formData.residualValuePercentage ?? formLimits.residualValuePercentage.default
  );
  const [term, setTerm] = useState<string | number>(formData.term ?? formLimits.term.default);
  const [amount, setAmount] = useState<string | number>(
    formData.propertyPrice ?? formLimits.propertyPrice.default
  );

  const paymentPercentageHelpText = getAdvancePaymentRangeText(formLimits, formatterPercentage);
  const residualValuePercentageHelpText = getResidualValueRangeText(formLimits, formatterPercentage, term);
  const propertyPriceHelpText = getPriceRangeText(formLimits, formatterCurrency);
  const termRangeText = getTermRangeText(formLimits);
  const termHelpText = t('months', { value: termRangeText });

  const onAdvanceChange = onLinkedInputsChange(amount, setAdvance, setAdvancePct);
  const onResidualChange = onLinkedInputsChange(amount, setResidual, setResidualPct);
  const onAdvancePctChange = onLinkedInputsChange(amount, setAdvancePct, setAdvance);
  const onResidualPctChange = onLinkedInputsChange(amount, setResidualPct, setResidual);

  const onAmountChange = (value: string) => {
    if (value === '' || value === '-') {
      setAmount(value);
      return;
    }

    const numberValue = Number(value);

    setAmount(numberValue);
    setAdvance(calculateValueFromPercentage(advancePct, numberValue));
    setResidual(calculateValueFromPercentage(residualPct, numberValue));
  };

  const onTermChange = (value: string) => {
    if (value === '' || value === '-') {
      setTerm(value);
      return;
    }

    const numberValue = Number(value);
    setTerm(numberValue);
  };

  const getFormValues = () => {
    const values = {
      propertyPrice: amount,
      term,
      advancePaymentPercentage: advancePct,
      advancePayment: advance,
      residualValuePercentage: residualPct,
      residualValue: residual,
      monthlyPayment: calculateMonthlyPayment(
        Number(amount) - Number(advance),
        Number(term),
        Number(residual)
      ),
      uniqueId: uniqueIdRef.current
    };

    return values;
  };

  const updateFieldErrors = (fieldName?: keyof PaymentDetails) => {
    const errors = validateFields(getFormValues(), fieldName);

    setFieldErrors(errors);

    return errors;
  };

  const handleNextClick = () => {
    const errors = updateFieldErrors();

    if (Object.keys(errors).length === 0) {
      dispatch(setPaymentDetailsInfo(getFormValues()));
      dispatch(setApplicationAmount(Number(amount)));

      onNextClick();
    }
  };

  const errorCount = Object.keys(fieldErrors).length;

  useEffect(() => {
    if (errorCount) {
      updateFieldErrors();
    }
  }, [i18n.language, errorCount]);

  useEffect(
    () =>
      setFormHandlers({
        submitHandler: handleNextClick
      }),
    [setFormHandlers, amount, term, advance, residual]
  );

  const formValues = getFormValues();

  const isValid = Object.keys(validateFields(formValues)).length === 0;

  return (
    <FormContainer isValid={isValid} onNextClick={handleNextClick}>
      <FieldsColumn>
        <Input
          size="L"
          onBlur={() => updateFieldErrors('propertyPrice')}
          required
          name="propertyPrice"
          type="number"
          helpText={propertyPriceHelpText}
          placeholder={t('propertyPrice')}
          value={`${amount}`}
          onChange={onAmountChange}
          fieldMeta={fieldErrors.propertyPrice}
        />
        <Input
          size="L"
          onBlur={() => updateFieldErrors('term')}
          required
          type="number"
          name="term"
          helpText={termHelpText}
          placeholder={t('placeholderTerm')}
          value={`${term}`}
          onChange={onTermChange}
          fieldMeta={fieldErrors.term}
        />
        <Typography.Header type="h4">{t('advancePayment')}</Typography.Header>
        <LinkedInputsBlock invalid={!!fieldErrors.advancePaymentPercentage?.error?.message}>
          <Input
            size="L"
            onBlur={() => updateFieldErrors('advancePaymentPercentage')}
            type="number"
            required
            name="advancePaymentPercentage"
            helpText={paymentPercentageHelpText}
            suffix="%"
            placeholder=""
            value={`${advancePct}`}
            onChange={onAdvancePctChange(true)}
            fieldMeta={fieldErrors.advancePaymentPercentage}
          />
          <Input
            size="L"
            required
            type="number"
            name="advancePayment"
            suffix={String.fromCodePoint(8364)}
            placeholder=""
            value={`${advance}`}
            onChange={onAdvanceChange(false)}
          />
        </LinkedInputsBlock>
        <Typography.Header type="h4">{t('residualValue')}</Typography.Header>
        <LinkedInputsBlock invalid={!!fieldErrors.residualValuePercentage?.error?.message}>
          <Input
            size="L"
            required
            onBlur={() => updateFieldErrors('residualValuePercentage')}
            type="number"
            name="residualValuePercentage"
            helpText={residualValuePercentageHelpText}
            suffix="%"
            placeholder=""
            value={`${residualPct}`}
            onChange={onResidualPctChange(true)}
            fieldMeta={fieldErrors.residualValuePercentage}
          />
          <Input
            size="L"
            required
            type="number"
            name="residualValue"
            suffix={String.fromCodePoint(8364)}
            placeholder=""
            value={`${residual}`}
            onChange={onResidualChange(false)}
          />
        </LinkedInputsBlock>
        <MonthlyPaymentBanner monthlyPayment={formValues.monthlyPayment} />
      </FieldsColumn>
    </FormContainer>
  );
};

export default PriceAndPaymentDetails;
