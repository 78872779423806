import { useTranslation } from 'react-i18next';

import checkmark from 'assets/icons/checkmark48px.svg';
import { NotificationModal } from 'components';
import { NotificationIcon } from 'components/notificationModal/notificationModal.styles';

type Props = {
  open?: boolean;
  onVisibilityChange: (isVisible: boolean) => unknown;
};

const OnboardingSuccessModal = ({ open, onVisibilityChange }: Props) => {
  const { t } = useTranslation();

  const onClose = () => {
    onVisibilityChange(false);
  };

  const mainAction = {
    label: t('next'),
    handler: () => {
      onClose();
    }
  };

  return (
    <NotificationModal
      type={'success'}
      isOpen={!!open}
      informationalIcon={<NotificationIcon variant="success" icon={checkmark} />}
      label={'businessDetailsSubmitted'}
      handleClose={onClose}
      explanationText={'businessDetailsSubmittedExplanation'}
      mainAction={mainAction}
    />
  );
};

export default OnboardingSuccessModal;
