import { createSelector } from '@reduxjs/toolkit';
import i18next from 'i18next';

import api from 'features/application/api';
import {
  selectActiveCustomer,
  selectCustomerInformation,
  selectIsUserGhost,
  selectUserData
} from 'features/auth/selectors';
import { Company } from 'features/auth/types/authTypes';
import { ApplicationItem, GetRequiredInfoResponse } from 'services/CapService/types';
import { CustomerInfo } from 'services/LoginService/typings';
import { RootState } from 'store';

import { Status } from './types/applicationsTypes';

const getTopLevelRoleForCustomer = (customer?: CustomerInfo) => {
  if (!customer) {
    return 'PROSPECT';
  }

  if (!customer.roles?.includes('PROSPECT')) {
    return 'VERIFIED';
  }

  if (customer.roles?.includes('PROSPECT')) {
    return 'PROSPECT';
  }

  return 'PROSPECT';
};

const getDefaultEmptyCompany = (email?: string) => ({
  id: '',
  name: email ?? '',
  roles: ['PROSPECT'],
  country: 'LT'
});

const applicationsFetchResponse = api.endpoints.fetchApplications.select({
  fullList: true
});

export const selectApplicationsIsLoading = createSelector(
  applicationsFetchResponse,
  (result) => result.isLoading
);
export const selectApplicationsLoadFailed = createSelector(
  applicationsFetchResponse,
  (result) => result.isError
);

export const selectActiveCompany = (state: RootState): Company => {
  const activeCompany = state?.auth?.activeCompany;

  if (activeCompany?.name) {
    return activeCompany;
  }

  const companies = selectCompaniesAggregate(state);
  const activeCustomer = selectActiveCustomer(state?.auth);

  if (activeCustomer) {
    const companyName = companies?.find((company) => company.id === activeCustomer.customerId)?.name;

    return {
      id: activeCustomer.customerId ?? '',
      name: companyName ?? activeCustomer.customerName ?? '',
      roles: [getTopLevelRoleForCustomer(activeCustomer), ...(activeCustomer.roles ?? [])],
      country: activeCustomer.customerCountryId,
      type: activeCustomer.customerType
    };
  }

  const userData = selectUserData(state.auth);
  return companies[0] ?? getDefaultEmptyCompany(userData.email);
};

export const selectCompanyById = (state: RootState) => (companyId: string) =>
  selectCompaniesAggregate(state).find((company) => company.id === companyId);

export const selectIsActiveCompanyVerified = createSelector(selectActiveCompany, (company) =>
  company?.roles?.includes('VERIFIED')
);

export const selectApplicationsList = createSelector(
  applicationsFetchResponse,
  selectActiveCompany,
  (result, company) => {
    return result.data
      ?.filter(
        (application) =>
          (application.company?.code ?? application.farm?.code) === company?.id ||
          !company?.id ||
          !(application.company?.code ?? application.farm?.code)
      )
      ?.filter((application) => application.status !== 'NA' && application.status !== 'REJECTED');
  }
);

export const selectFullApplicationsList = createSelector(applicationsFetchResponse, (result) => result.data);

export const getUnverifiedApplications = (authState: RootState['auth'], applications?: ApplicationItem[]) => {
  if (!applications) {
    return [];
  }

  const uniqueCompanies = Array.from(
    new Set(applications?.map((application) => application.company?.code ?? application.farm?.code))
  );

  const verifiedCompanies = selectIsUserGhost(authState)
    ? []
    : selectCustomerInformation(authState)?.customers?.map((customer) => customer.customerId) ?? [];

  const unverifiedCompanies = uniqueCompanies.filter((company) => !verifiedCompanies.includes(company));

  return (
    unverifiedCompanies.map((companyId) =>
      applications
        .filter((application) => application?.status !== 'OFFER_REJECTED')
        .find((application) => (application.company?.code ?? application.farm?.code) === companyId)
    ) ?? []
  );
};

export const selectUnverifiedApplications = (state: RootState) =>
  getUnverifiedApplications(state.auth, selectFullApplicationsList(state));

export const selectApplicationById = (state: RootState) => (capId: string) =>
  selectApplicationsList(state)?.find((application) => application.capId === capId);

export const selectHasNoApplications = (state: RootState) =>
  !selectApplicationsIsLoading(state) &&
  !selectApplicationsLoadFailed(state) &&
  !selectApplicationsList(state)?.length;

export const selectGroupedRequiredInfo = (requiredInfo?: GetRequiredInfoResponse) => {
  if (!requiredInfo?.documents?.length) {
    return [];
  }

  return Array.from(
    requiredInfo.documents.reduce(
      (m, { documentType, description }) =>
        m.set(documentType, [...(m.get(documentType) || []), description]),
      new Map()
    ),
    ([documentType, description]) => ({ documentType, description })
  );
};

const byDate = (a: ApplicationItem, b: ApplicationItem) => {
  if (b?.creationDate && a?.creationDate) {
    return new Date(b?.creationDate).getTime() - new Date(a?.creationDate).getTime();
  }

  return 0;
};

const statusWeight: Record<Status, number> = {
  OFFER_SENT: 110,

  ADDITIONAL_INFORMATION_NEEDED: 105,

  INITIAL: 100,

  ASSESSMENT_ONGOING: 95,
  VOTING_COMPLETED: 95,
  SUBMITTED_FOR_VOTING: 95,
  ADDITIONAL_INFO_SUBMITTED: 95,
  WAITING_FOR_INFO: 95,
  OFFER_GENERATED: 95,

  OFFER_REJECTED: 90,
  KYC_REJECTED: 90,

  OFFER_ACCEPTED: 85,
  KYC_WAITING: 85,
  KYC_COMPLETED: 85,
  KYC_NOT_REQUIRED: 85,
  CONTRACT_PREPARED: 85,
  CONTRACT_PREPARATION_IN_PROGRESS: 85,
  CONTRACT_PREPARATION_FAILED: 85,
  CONTRACT_SIGNED: 85,

  NOT_FILLED: 0,
  NA: 0,
  REJECTED: 0,
  INACTIVE: 0,
  DELETED: 0,
  REFUSED_BY_CLIENT: 0
};

const sortApplications = (applications: ApplicationItem[]) => {
  const sortedApplications = [...applications].sort((a: ApplicationItem, b: ApplicationItem) => {
    const statusSort = statusWeight[b.status ?? 'INITIAL'] - statusWeight[a.status ?? 'INITIAL'];

    return statusSort === 0 ? byDate(a, b) : statusSort;
  });

  return sortedApplications;
};

export const selectSortedApplications = (state: RootState) => {
  return sortApplications(selectApplicationsList(state) ?? []);
};

const statusForVerificationWeight: Record<Status, number> = {
  KYC_REJECTED: 70,

  OFFER_ACCEPTED: 60,
  KYC_COMPLETED: 60,
  KYC_WAITING: 60,
  KYC_NOT_REQUIRED: 60,
  CONTRACT_PREPARATION_IN_PROGRESS: 60,
  CONTRACT_SIGNED: 60,
  CONTRACT_PREPARATION_FAILED: 60,
  CONTRACT_PREPARED: 60,

  OFFER_SENT: 40,

  ADDITIONAL_INFORMATION_NEEDED: 35,

  OFFER_GENERATED: 40,
  SUBMITTED_FOR_VOTING: 30,
  VOTING_COMPLETED: 30,
  WAITING_FOR_INFO: 25,
  ADDITIONAL_INFO_SUBMITTED: 25,
  ASSESSMENT_ONGOING: 25,

  INITIAL: 20,

  NOT_FILLED: 0,
  OFFER_REJECTED: 0,
  NA: 0,
  REJECTED: 0,
  INACTIVE: 0,
  DELETED: 0,
  REFUSED_BY_CLIENT: 0
};

export const getSortedApplicationsForVerification = (applications: ApplicationItem[]) => {
  const sortedApplications = applications
    .filter((application) => application.status !== 'NA' && application.status !== 'REJECTED')
    .sort((a: ApplicationItem, b: ApplicationItem) => {
      const statusSort =
        statusForVerificationWeight[b.status ?? 'INITIAL'] -
        statusForVerificationWeight[a.status ?? 'INITIAL'];

      return statusSort === 0 ? byDate(a, b) : statusSort;
    });

  const latestRejectedApplication = sortedApplications
    .filter((application) => application.status === 'OFFER_REJECTED')
    .reverse()[0];

  if (latestRejectedApplication) {
    const latestRejectedApplicationCreationDate = new Date(
      latestRejectedApplication.creationDate ?? 0
    ).getTime();

    return sortedApplications
      .filter((application) => application.status !== 'OFFER_REJECTED')
      .filter((application) => {
        const applicationStatusWeight = statusForVerificationWeight[application.status ?? 'INITIAL'];

        if (applicationStatusWeight >= statusForVerificationWeight.OFFER_ACCEPTED) {
          return true;
        }

        if (!application.creationDate) {
          return true;
        }

        const applicationCreationDate = new Date(application.creationDate ?? 0).getTime();

        return applicationCreationDate > latestRejectedApplicationCreationDate;
      });
  }

  return sortedApplications.filter((application) => application.status !== 'OFFER_REJECTED');
};

export const selectApplicationsForVerification = (state: RootState) => {
  return getSortedApplicationsForVerification(selectApplicationsList(state) ?? []);
};

export const selectVerificationApplication = (state: RootState) => {
  return selectApplicationsForVerification(state)[0] ?? {};
};

export const selectCompaniesAggregate = (state: RootState): Company[] => {
  const applications = selectUnverifiedApplications(state);
  const allApplications = selectFullApplicationsList(state);
  const activeCustomer = selectCustomerInformation(state.auth);
  const userData = selectUserData(state.auth);

  const prospectCompanies =
    applications?.map<Company | undefined>((application) => {
      if ((application?.company?.code && application?.company.name) || application?.farm?.code) {
        return {
          id: application.company?.code ?? application.farm?.code ?? '',
          name:
            application.company?.name ??
            `${i18next.t('farmer')} ${application.farm?.firstName} ${application.farm?.lastName}`,
          roles: ['PROSPECT'],
          applicationId: application.applicationId,
          country: application.company?.country ?? application.farm?.country
        };
      }
    }) ?? [];

  const userCompanies =
    activeCustomer?.customers?.map<Company | undefined>((customer) => {
      const fullCustomer = allApplications?.find(
        (app) => (app.company?.code ?? app.farm?.personalCode) === customer.customerId
      );

      if (customer?.customerId && customer?.customerName) {
        const prefix = fullCustomer?.farm ? `${i18next.t('farmer')} ` : '';

        return {
          id: customer.customerId,
          name: `${prefix}${customer.customerName}`,
          roles: [getTopLevelRoleForCustomer(customer), ...(activeCustomer.roles ?? [])],
          country: customer.customerCountryId
        };
      }
    }) ?? [];

  const emptyCompany = getDefaultEmptyCompany(userData.email);

  if (userCompanies?.length === 0 && prospectCompanies?.length === 0) {
    return [emptyCompany];
  }

  const prospectCompaniesInFirestore = userCompanies
    .filter((company) => company?.roles?.includes?.('PROSPECT'))
    .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''));

  const userCompaniesWithFullControl = userCompanies
    .filter((company) => !company?.roles?.includes?.('PROSPECT'))
    .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''));

  return [
    ...userCompaniesWithFullControl,
    ...prospectCompaniesInFirestore,
    ...prospectCompanies.sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? ''))
  ].filter(Boolean) as Company[];
};
