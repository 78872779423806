import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components';
import { selectToastMessages } from 'features/app/selectors';
import { clearToastMessage, clearToastMessages } from 'features/app/slice';
import { Toast } from 'features/app/types';
import ContactsTermsSection from 'features/application/forms/contact/contactTermsSection';
import {
  selectActiveStep,
  selectApplicationIsLoading,
  selectStepsSequence
} from 'features/application/selectors';
import { setNavigateTo } from 'features/application/slice';
import { STEPS } from 'features/application/types/applicationTypes';
import { useAppDispatch, useAppInfoSelector, useApplicationSelector } from 'hooks/redux/hooks';

import {
  ButtonContainer,
  ContainerStyle,
  Content,
  ContinueButton,
  FormContainerStyle,
  ToastMessage
} from './formContainer.style';

type Props = {
  isValid?: boolean;
  children?: React.ReactNode;
  hiddenNextButton?: boolean;
  hiddenNavigation?: boolean;
  onNextClick?: () => void;
};

const GeneralErrorMessage: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const toastMessages = useAppInfoSelector<typeof selectToastMessages>(selectToastMessages);

  const onClickHandler = (toast: Toast) => () => {
    dispatch(clearToastMessage(toast));
  };

  return (
    <>
      {toastMessages.map((toast, index) => (
        <ToastMessage
          key={`${toast.status}.${index}`}
          onClose={onClickHandler(toast)}
          isVisible
          header={t(toast.message)}
        />
      ))}
    </>
  );
};

const FormContainer: FC<Props> = ({ hiddenNextButton, hiddenNavigation, children, onNextClick, isValid }) => {
  const dataTestId = 'form-container';

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);

  const [isBorderVisible, setIsBorderVisible] = useState(false);

  const activeStep = useApplicationSelector<typeof selectActiveStep>(selectActiveStep);
  const loading = useApplicationSelector<typeof selectApplicationIsLoading>(selectApplicationIsLoading);
  const sequence = useApplicationSelector<typeof selectStepsSequence>(selectStepsSequence);
  const toastMessages = useAppInfoSelector<typeof selectToastMessages>(selectToastMessages);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      setIsBorderVisible(!entry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-72px'
    });

    const target = containerRef.current;

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  const onNextClickHandler = async () => {
    if (toastMessages.length !== 0) {
      dispatch(clearToastMessages());
    }

    const currentIndex = sequence.findIndex((step) => step === activeStep);

    dispatch(setNavigateTo(sequence[currentIndex + 1]));

    await onNextClick?.();
  };

  const showNextButton = hiddenNavigation ? false : !hiddenNextButton && onNextClick;

  return (
    <>
      <ContainerStyle>
        <FormContainerStyle data-testid={dataTestId}>
          {loading && <Loader opaque />}
          <GeneralErrorMessage />
          <Content data-testid={`${dataTestId}-${activeStep}`}>{children}</Content>
        </FormContainerStyle>
      </ContainerStyle>

      {showNextButton && (
        <>
          <div ref={containerRef} style={{ height: '1px' }} />
          <ButtonContainer
            ref={buttonContainerRef}
            isBorderVisible={isBorderVisible}
            data-testid="buttons-section"
          >
            {activeStep === STEPS.CONTACT_PERSON ? <ContactsTermsSection /> : null}
            <ContinueButton invalid={!isValid} onClick={onNextClickHandler}>
              {t('next')}
            </ContinueButton>
          </ButtonContainer>
        </>
      )}
    </>
  );
};

export default FormContainer;
