import { FC } from 'react';

import Icons from '../../assets/logo';

import LogoStyle from './logo.styles';

export const Logo: FC = () => {
  return (
    <LogoStyle>
      <Icons.SMEGo />
    </LogoStyle>
  );
};
