import { DefaultTheme } from 'styled-components';

import {
  colors,
  fontWeights,
  fontSizes,
  responsiveBreakPoints,
  status,
  pageContentLayouts,
  shadows,
  spacings,
  layers
} from 'design/designVariables';

export const lightTheme: DefaultTheme = {
  theme: 'light',
  colors: colors,
  media: responsiveBreakPoints,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  status: status,
  shadows,
  pageContentLayouts,
  spacings,
  layers
};
