import styled from 'styled-components';

import { RouterLink } from '../link';
import { PaperStyle } from '../paper';
import { AmountText, SecondaryText } from '../text';

export const ProductTotalsCardContainer = styled.div`
  display: flex;
  width: 100%;

  span {
    line-height: 1.5rem;
  }

  ${PaperStyle} {
    padding: 1.5rem;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${RouterLink} {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-left: 1.5rem;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.primary.linkText};
      }
    }
  }
`;

export const Amount = styled(AmountText)`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

type ColumnProps = {
  type?: string;
};

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;

  ${SecondaryText} {
    font-size: 0.75rem;
  }

  ${Amount} {
    color: ${({ theme, type }) => (type === 'danger' ? theme.colors.primary.status.error[100] : 'inherit')};
  }
`;

export const RightColumn = styled(Column)`
  align-items: flex-end;
`;

export const Line = styled.div`
  display: flex;
  height: 0.25rem;
  border-radius: 0.125rem;
  z-index: 999;
`;

export const TotalsLine = styled(Line)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base.black.shades[10]};
  margin-bottom: 0.5rem;
`;

type Props = {
  widthInPct?: number;
  startingLine?: boolean;
};

export const ValueLine = styled(Line)<Props>`
  width: ${({ widthInPct }) => `${widthInPct}%`};
  background-color: ${({ theme }) => theme.colors.primary.linkText};
`;

export const ValueDangerLine = styled(Line)<Props>`
  width: ${({ widthInPct }) => `${widthInPct}%`};
  background-color: ${({ theme }) => theme.colors.primary.status.error[100]};
  position: relative;

  &::before {
    content: '';
    width: 0.25rem;
    position: absolute;
    z-index: 99;
    left: ${({ startingLine }) => (startingLine ? 0 : '-0.125rem')};
    height: 0.25rem;
    background-color: ${({ theme }) => theme.colors.primary.status.error[100]};
    border-radius: ${({ startingLine }) => (startingLine ? '0.125rem' : 0)};
  }
`;

export const TotalsCardContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const MaintenanceCardContainer = styled.div`
  display: flex;
  width: 100%;

  ${PaperStyle} {
    padding: 1.5rem;
  }

  img {
    margin-left: 1rem;
    width: 6rem;
  }
`;

export const MaintenanceCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-wrap;

  h5 {
    margin-block: 0.75rem 0.5rem;
  }

  span {
    color: ${({ theme }) => theme.colors.base.black.shades[50]};
  }
`;
