import { TableColumn } from 'react-data-table-component';
import { TFunction } from 'react-i18next';

import Icons from 'assets/icons';
import {
  AmountText,
  RegularText,
  SecondaryText,
  StatusChip,
  transformDateToLocalizedString
} from 'components';
import { AmountCellColumn, CellCollumn, HeaderCell } from 'components/table/table.styles';
import { StatusType } from 'design/designVariables';
import { BillStatus, LoanInvoiceItem } from 'services/LoansService/types';
import { camelCase } from 'utils';

import { getBillTypeTranslation } from '../../consts/billTypeTranslations';

import { BillStatusChip, InvoiceDownloadLink, StatusChipWithDownloadContainer } from './loansBills.style';

export type TableRowItem = LoanInvoiceItem & { toggleSelected?: never };

export const withNullCheck = (value: string | number | undefined) => (value || value === 0 ? value : '-');

type StatusToChipColorMap = Record<BillStatus, keyof StatusType>;

const statusToChipColorMap: StatusToChipColorMap = {
  PAID: 'green',
  UNPAID: 'yellow',
  OVERDUE: 'red',
  PARTIALLY_PAID: 'blue',
  UNKNOWN: 'white'
};

type InvoiceDownloadHandler = (invoiceNumber?: string) => () => Promise<unknown | undefined>;

export const createTableColumns = (
  t: TFunction<'translation', undefined>,
  invoiceDownloadHandler: InvoiceDownloadHandler
): TableColumn<TableRowItem>[] => [
  {
    selector: (row) => withNullCheck(row.billNumber),
    name: (
      <HeaderCell>
        {t('number')}/<br></br>
        {t('type')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'billType',
    sortField: 'billType',
    format: (row) => {
      if (row.billType !== 'Loan Principal' && row.billType !== 'Installment Principal') {
        return (
          <CellCollumn>
            <SecondaryText>{withNullCheck(row.billNumber)}</SecondaryText>
            <RegularText>{t(withNullCheck(getBillTypeTranslation(row.billType)) as string)}</RegularText>
          </CellCollumn>
        );
      }
      return <RegularText>{t(withNullCheck(getBillTypeTranslation(row.billType)) as string)}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.dueDate),
    name: (
      <HeaderCell>
        {t('placeholderUploadIssueDate')}/<br></br>
        {t('dueDate')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'dueDate',
    sortField: 'dueDate',
    format: (row) => {
      return (
        <CellCollumn>
          <SecondaryText>
            {transformDateToLocalizedString(withNullCheck(row.issueDate) as string)}
          </SecondaryText>
          <RegularText>{transformDateToLocalizedString(withNullCheck(row.dueDate) as string)}</RegularText>
        </CellCollumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceAmount),
    name: (
      <HeaderCell>
        {t('invoiceAmount')}/<br></br>
        {t('paidAmount')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'paidAmount',
    sortField: 'paidAmount',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText>{withNullCheck(row.invoiceAmount)}</AmountText>
          </RegularText>
          <RegularText>
            <AmountText>{withNullCheck(row.paidAmount)}</AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.unpaidAmount),
    name: <HeaderCell>{t('unpaid')}</HeaderCell>,
    sortable: true,
    id: 'unpaidAmount',
    sortField: 'unpaidAmount',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return (
        <AmountCellColumn>
          <AmountText>{withNullCheck(row.unpaidAmount)}</AmountText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.paymentStatus),
    name: <HeaderCell>{t('status')}</HeaderCell>,
    sortable: true,
    id: 'status',
    sortField: 'status',
    format: (row) => {
      if (row.paymentStatus) {
        return (
          <StatusChip color={statusToChipColorMap[row.paymentStatus as BillStatus]}>
            <RegularText>{t(withNullCheck(camelCase(row.paymentStatus || '')) as string)}</RegularText>
          </StatusChip>
        );
      }

      return null;
    }
  },
  {
    selector: (row) => withNullCheck(row.billNumber),
    width: '40px',
    format: (row) => {
      if (row.billType !== 'Loan Principal' && row.billType !== 'Installment Principal') {
        return (
          <InvoiceDownloadLink fileType="pdf" fileRequest={invoiceDownloadHandler(row.billNumber)}>
            <Icons.Download />
          </InvoiceDownloadLink>
        );
      }
      return null;
    }
  }
];

export const createMobileListRows = (
  t: TFunction<'translation', undefined>,
  invoiceDownloadHandler: InvoiceDownloadHandler
): TableColumn<TableRowItem>[] => [
  {
    selector: (row) => withNullCheck(row.billNumber),
    name: t('number'),
    format: (row) => {
      if (row.billType !== 'Loan Principal' && row.billType !== 'Installment Principal') {
        return <RegularText>{withNullCheck(row.billNumber)}</RegularText>;
      }
      return null;
    }
  },
  {
    selector: (row) => withNullCheck(row.billType),
    name: t('type'),
    format: (row) => {
      return <RegularText>{t(withNullCheck(getBillTypeTranslation(row.billType)) as string)}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.issueDate),
    name: t('placeholderUploadIssueDate'),
    format: (row) => {
      return (
        <RegularText>{transformDateToLocalizedString(withNullCheck(row.issueDate) as string)}</RegularText>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.dueDate),
    name: t('dueDate'),
    format: (row) => {
      return (
        <RegularText>{transformDateToLocalizedString(withNullCheck(row.dueDate) as string)}</RegularText>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceAmount),
    name: t('invoiceAmount'),
    format: (row) => {
      return <AmountText>{withNullCheck(row.invoiceAmount)}</AmountText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.paidAmount),
    name: t('paidAmount'),
    id: 'paidAmount',
    format: (row) => {
      return <AmountText>{withNullCheck(row.paidAmount)}</AmountText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.unpaidAmount),
    name: t('unpaid'),
    format: (row) => {
      return <AmountText>{withNullCheck(row.unpaidAmount)}</AmountText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.paymentStatus),
    name: t('status'),
    format: (row) => {
      if (
        row.paymentStatus &&
        row.billType !== 'Loan Principal' &&
        row.billType !== 'Installment Principal'
      ) {
        return (
          <StatusChipWithDownloadContainer>
            <BillStatusChip color={statusToChipColorMap[row.paymentStatus as BillStatus]}>
              <RegularText>{t(withNullCheck(camelCase(row.paymentStatus || '')) as string)}</RegularText>
            </BillStatusChip>
            <InvoiceDownloadLink fileType="pdf" fileRequest={invoiceDownloadHandler(row.billNumber)}>
              <Icons.Download />
            </InvoiceDownloadLink>
          </StatusChipWithDownloadContainer>
        );
      } else if (row.billType === 'Loan Principal' || row.billType === 'Installment Principal') {
        return (
          <StatusChip color={statusToChipColorMap[row.paymentStatus as BillStatus]}>
            <RegularText>{t(withNullCheck(camelCase(row.paymentStatus || '')) as string)}</RegularText>
          </StatusChip>
        );
      }

      return null;
    }
  }
];
