import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { FactoringBillsGetResponse, FactoringBillsGetRequest } from 'services/FactoringInvoicesService/types';
import { axiosBaseQuery } from 'services/RestClient/axiosBaseQuery';
import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';

const withBaseUrl = baseUrlBuilder('factoring-invoices');

const factoringInvoicesApi = createApi({
  reducerPath: 'factoringInvoices',
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    getBills: build.query<FactoringBillsGetResponse, FactoringBillsGetRequest>({
      query: (payload) => ({ url: queryBuilder(withBaseUrl('/bills'), payload) }),
      forceRefetch: () => true, //prevents caching
      merge: (_, responseData) => responseData //reuse same cache key,
    })
  })
});

export const { useGetBillsQuery, useLazyGetBillsQuery, endpoints } = factoringInvoicesApi;
export const { resetApiState: resetFactoringInvoicesApiState } = factoringInvoicesApi.util;

export default factoringInvoicesApi;
