import { ChangeEvent, forwardRef, ReactNode } from 'react';

import Icon from '../../assets/icons';
import { ErrorMessage } from '../formError';
import { FieldState } from '../formField/field';
import { InputContainer } from '../input/input.style';
import { Typography } from '../typography';

import { Checkmark, HiddenCheckbox, LabelContainer, StyledCheckbox } from './checkbox.style';

export type CheckboxProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  id?: string;
  fieldMeta?: FieldState;
  label?: ReactNode;
  disabled?: boolean;
  circle?: boolean;
  className?: string;
  name?: string;
  size?: 'M' | 'L';
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ disabled, checked, onChange, id, label, circle, name, fieldMeta, className, size }, ref) => {
    const { isTouched, error, showValidationMessage, message } = fieldMeta || {};

    const onChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.checked);
    };

    const isInvalid = isTouched && Boolean(error?.message);
    const hasValidationMessages = error?.message && isTouched;
    const resolvedSize = size ?? 'L';

    return (
      <InputContainer>
        <StyledCheckbox
          checked={Boolean(checked)}
          disabled={disabled}
          invalidStatus={isInvalid}
          className={className}
          data-testid={`checkbox-${name}`}
        >
          <HiddenCheckbox
            ref={ref}
            disabled={disabled}
            id={id}
            aria-checked={Boolean(checked)}
            checked={Boolean(checked)}
            onChange={onChangeHandle}
            data-testid={`hidden-checkbox-${name}`}
          />
          <Checkmark isInvalid={isInvalid} checked={checked} circle={circle} size={resolvedSize}>
            {checked && <Icon.CheckmarkWhite />}
          </Checkmark>
          {label ? (
            <LabelContainer>
              <Typography.Body type="M">{label}</Typography.Body>
            </LabelContainer>
          ) : null}
        </StyledCheckbox>
        <ErrorMessage hidden={!hasValidationMessages || !showValidationMessage}>{message}</ErrorMessage>
      </InputContainer>
    );
  }
);

export default Checkbox;
