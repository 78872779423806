import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SuccessfulUploadImage from 'assets/images/successfulUpload.svg';
import { DialogCard } from 'components';
import NavContext from 'features/nav/context/navContext';

import { PageContainer } from './successfulSubmitCard.styles';

type Props = {
  showSuccessPage: (visible: boolean) => unknown;
};

const SuccessfulSubmitCard: FC<Props> = ({ showSuccessPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setShowSideNavigation } = useContext(NavContext);

  useEffect(() => {
    setShowSideNavigation(false);
  }, []);

  const navigateToInsights = () => {
    showSuccessPage(false);
    setShowSideNavigation(true);
    navigate('/dashboard/insights');
  };

  return (
    <PageContainer>
      <DialogCard
        image={SuccessfulUploadImage}
        label={t('congratulations')}
        text={t('insightReportSuccessfulSubmited')}
        buttonLabel={t('viewInsights')}
        onClick={navigateToInsights}
        maxWidth="34.875rem"
      />
    </PageContainer>
  );
};

export default SuccessfulSubmitCard;
