import { useTranslation } from 'react-i18next';

import { CountryFlagIcon } from '../countriesList';
import { RegularText, SecondaryText } from '../text';

import { FirstLineRow, MultilineValueContainer, MultilineValueContainerProps } from './dropdown.style';

export type GenericOption = {
  value: string;
  label: string;
  sublabel?: string;
};

export type LabelProps<TOption extends GenericOption = GenericOption> = {
  option: TOption | null;
  flagCode?: string;
  textSize?: 'S' | 'M';
} & MultilineValueContainerProps;

export function Label<T extends GenericOption>({
  option,
  flagCode,
  flexDirection,
  textSize = 'S'
}: LabelProps<T>) {
  const { t } = useTranslation();

  return (
    <MultilineValueContainer data-testid="multiline-value-container" flexDirection={flexDirection}>
      {option?.label && (
        <FirstLineRow size={textSize}>
          {flagCode ? <CountryFlagIcon size="S" flagCode={flagCode} /> : null}
          <RegularText data-testid={`dropdown-option-${option.value}`}>{t(option.label)}</RegularText>
        </FirstLineRow>
      )}
      {option?.sublabel && <SecondaryText>{t(option.sublabel)}</SecondaryText>}
    </MultilineValueContainer>
  );
}
