import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormList, Toast, formatPercentage } from 'components';
import FormContext from 'features/application/context/FormContext';
import { DrawerImplementation } from 'features/application/drawers';
import { selectBeneficialOwners } from 'features/application/selectors';
import { submitApplicationAsync } from 'features/application/slice';
import { useNavContext } from 'features/nav/context/navContext';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';

import { FormContainer } from '../../components/formContainer';

import { BeneficialOwnersFormContainer } from './beneficialOwnerForm.styles';

const BeneficialOwnersForm: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { toggleRightSideDrawer } = useNavContext<DrawerImplementation<'beneficialOwner'>>();

  const beneficialOwners = useApplicationSelector<typeof selectBeneficialOwners>(selectBeneficialOwners);

  const [submitedWithoutOwners, setSubmitedWithoutOwners] = useState<boolean>(false);

  const { setFormHandlers } = useContext(FormContext);

  const formatListItemAvatar = (title: string, key: number) => {
    const owner = beneficialOwners?.[key];

    if (!owner) return title?.substring(0, 2)?.toLocaleUpperCase();

    return `${owner.firstName?.charAt(0)}${owner.lastName?.charAt(0)}`;
  };

  const saveSubmitHandler = () => {
    if (beneficialOwners?.length) {
      dispatch(submitApplicationAsync());

      return;
    }

    setSubmitedWithoutOwners(true);
  };

  const addOwner = () => {
    toggleRightSideDrawer?.({
      drawerId: 'beneficialOwner',
      mode: 'add'
    });
    setSubmitedWithoutOwners(false);
  };

  const editOwner = (key: number) => {
    toggleRightSideDrawer?.({
      drawerId: 'beneficialOwner',
      mode: 'edit',
      keyInEdit: key
    });
  };

  const format = formatPercentage(i18n.language);

  useEffect(
    () =>
      setFormHandlers({
        submitHandler: saveSubmitHandler
      }),
    [setFormHandlers]
  );

  const list =
    beneficialOwners?.map((key) => ({
      title: `${key.firstName} ${key.lastName}`,
      subtitle: `${t('owns')} ${format(key.owningPercentage, 0)}`
    })) ?? [];

  const isBeneficialOwnersAdded = (beneficialOwners?.length ?? 0) > 0;

  return (
    <FormContainer isValid={isBeneficialOwnersAdded} onNextClick={saveSubmitHandler}>
      <BeneficialOwnersFormContainer>
        <FormList
          onItemClick={editOwner}
          addButtonLabel={t('beneficialOwnerAdd')}
          onAddCardClick={addOwner}
          list={list}
          onItemEdit={editOwner}
          formatContent={formatListItemAvatar}
        />
        <Toast isVisible={submitedWithoutOwners} header={t('noBeneficialOwnersAddedError')} />
      </BeneficialOwnersFormContainer>
    </FormContainer>
  );
};

export default BeneficialOwnersForm;
