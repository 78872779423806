import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';

import { useFormContext } from 'features/application/context/FormContext';
import { setFarmersData, submitApplicationAsync } from 'features/application/slice.ts';
import { STEPS } from 'features/application/types/applicationTypes.ts';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks.ts';
import { VALIDATION_MESSAGE_ID } from 'validators/types/';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { FormContainer } from '../../components/formContainer';
import { selectFarmerDetails } from '../../selectors';

import { FormStyle } from './farmersDetails.styles';
import { FarmersDetails, FarmersDetailsFields } from './farmersDetailsFields';

type Props = {
  onNextClick: (sequence?: STEPS[]) => void;
};

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .matches(/^[A-Z]+$/i, VALIDATION_MESSAGE_ID.ONLY_LETTERS),
  lastName: Yup.string()
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .matches(/^[A-Z]+$/i, VALIDATION_MESSAGE_ID.ONLY_LETTERS),
  personalCode: Yup.string().required(VALIDATION_MESSAGE_ID.REQUIRED)
});

const initialFarmersInfo: FarmersDetails = {
  firstName: '',
  lastName: '',
  personalCode: ''
};

const FarmersDetailsForm: React.FC<Props> = ({ onNextClick }) => {
  const dispatch = useAppDispatch();
  const farmerDetails = useApplicationSelector<typeof selectFarmerDetails>(selectFarmerDetails);

  const initialValues = useMemo(() => {
    return farmerDetails
      ? {
          ...initialFarmersInfo,
          firstName: farmerDetails.firstName,
          lastName: farmerDetails.lastName,
          personalCode: farmerDetails.personalCode
        }
      : initialFarmersInfo;
  }, [farmerDetails?.firstName, farmerDetails?.lastName, farmerDetails?.personalCode]);

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers<FarmersDetails>(
    initialValues,
    validationSchema,
    'onBlur'
  );

  const { setFormHandlers } = useFormContext<FarmersDetails>();

  const submitHandler = handleSubmit(
    (data) => {
      dispatch(setFarmersData(data));
      dispatch(submitApplicationAsync());
      onNextClick();
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(() => {
    setFormHandlers({
      submitHandler,
      validationHelpers,
      getValues
    });
  }, [setFormHandlers]);

  return (
    <FormContainer isValid={validationHelpers.isValid} onNextClick={submitHandler}>
      <FormStyle>
        <FarmersDetailsFields validationHelpers={validationHelpers} getValues={getValues} />
      </FormStyle>
    </FormContainer>
  );
};

export default FarmersDetailsForm;
