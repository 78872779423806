import styled from 'styled-components';

import { Typography } from '../../components/typography';
import { ErrorMessage } from '../formError';

type Props = {
  leftAlign?: boolean;
};

export const TimerText = styled(Typography.Body).attrs({ type: 'M', weight: 'normal' })``;
export const OTPTimerLabel = styled(Typography.Body).attrs({ type: 'M', weight: 'light' })``;

export const FormHeading = styled.h2<Props>`
  width: 100%;
  text-align: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
  font-size: ${({ theme }) => theme.fontSizes.h2};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.primary.headerColor};
`;

export const FormSubheading = styled.h4<Props>`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.h5};
  text-align: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
  color: ${({ theme }) => theme.colors.primary.subHeaderColor};
  line-height: 1.25rem;
  margin: 0 0 1.25rem;
`;

export const OTPErrorMessage = styled(ErrorMessage)`
  position: absolute;
  width: 100%;
  bottom: -0.5rem;
  left: 0;
  text-align: center;
  transform: translateY(100%);
  transform-origin: top;
`;

type InputProps = {
  borderless?: boolean;
};

export const OTPForm = styled.form`
  width: 100%;
`;

export const Input = styled.input<InputProps>`
  display: flex;
  outline: none;
  border-radius: 0.5rem;
  border: ${({ borderless }) => (borderless ? 0 : '0.0625rem')} solid
    ${({ theme }) => theme.colors.formElement.border};
  font-size: 2rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.primary.inputTextColor};
  text-align: center;
  max-width: 4rem;
  height: 4rem;
  width: 100%;

  &:focus {
    border: 0.0625rem solid ${({ theme }) => theme.colors.base.primary.shades[100]};
  }
`;

export const InputsContainer = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  gap: 1rem;
  margin-block: 0.5rem 2.5rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }
`;

export const OTPBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  position: relative;
`;
