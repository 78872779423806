import { useTranslation } from 'react-i18next';

import { formatNumber } from 'components/text/formatters';

import {
  MonthlyPaymentBanner,
  MonthlyPaymentBannerText,
  MonthlyPaymentBannerValue,
  MonthlyPaymentBannerValueLabel,
  MonthlyPaymentValueRow
} from './priceAndPaymentDetailsForm.styles';

type Props = {
  monthlyPayment: number;
};

const MonthlyPayment = ({ monthlyPayment }: Props) => {
  const { t, i18n } = useTranslation();

  const formatCurrency = formatNumber(i18n.language);

  return (
    <MonthlyPaymentBanner>
      <MonthlyPaymentValueRow>
        <MonthlyPaymentBannerValueLabel>{t('monthlyPayment')}:</MonthlyPaymentBannerValueLabel>
        <MonthlyPaymentBannerValue>{formatCurrency(monthlyPayment)}</MonthlyPaymentBannerValue>
      </MonthlyPaymentValueRow>
      <MonthlyPaymentBannerText>{t('preliminaryMonthlyPaymentExplanation')}</MonthlyPaymentBannerText>
    </MonthlyPaymentBanner>
  );
};

export default MonthlyPayment;
