export function camelCase(str: string, delimeter = '_') {
  return str
    .trim()
    .toLowerCase()
    .split(delimeter)
    .map((word: string, index: number) => {
      if (index !== 0) {
        return word.substring(0, 1).toUpperCase() + word.substring(1, word.length);
      }

      return word;
    })
    .join('');
}
