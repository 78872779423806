import styled from 'styled-components';

import { Paper, RegularText, SecondaryText } from 'components';
import { CardHeader } from 'features/invoices/components/invoiceCard/invoiceCard.styles';

type Props = {
  expanded?: boolean;
};

export const ContractInformationCard = styled(Paper)`
  padding: 1.5rem 2rem;
  height: 100%;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const ContractInformationSet = styled.div<Props>`
  display: flex;
  margin-bottom: 0;
  overflow: hidden;
  height: 100%;
  max-height: ${({ expanded }) => (expanded ? '4rem' : 0)};
  transition: max-height ease 0.9s;
  gap: 0.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    display: flex;
    flex-direction: column;
    max-height: ${({ expanded }) => (expanded ? '13rem' : 0)};
    transition: max-height ease 0.9s;
  }
`;

export const ContractInformationHeader = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ expanded }) => (expanded ? '1.5rem' : 0)};
  transition: margin-bottom ease 0.9s;

  ${RegularText} {
    font-size: ${({ theme }) => theme.fontSizes.h4};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  ${SecondaryText} {
    display: flex;
    margin-right: 0.625rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  svg {
    transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 0;

    &::after {
      content: '';
      display: flex;
      width: 100%;
      transition: margin-block ease 0.9s;
      height: ${({ expanded }) => (expanded ? '0.0625rem' : 0)};
      background-color: ${({ theme }) => theme.colors.base.black.tints[10]};
      margin-block: ${({ expanded }) => (expanded ? '0.5rem' : 0)};
    }
  }
`;

export const ContractInformationHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContractInformationHeaderInfo = styled.div``;

export const StaticRowHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    ${CardHeader} {
      display: none;
    }
  }
`;
