import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthenticationDialog } from 'components';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { useAppLocation } from 'hooks/router/useAppLocation';
import { RequestInitMobileID } from 'services/LoginService/typings';

import PersonalDataForm from '../../components/personalDataForm';
import { selectIsWaitingForConfirmation, selectUserData } from '../../selectors';
import { initiateMobileIDAsync, loginMobileIDAsync, setIsWaitingForConfirmation } from '../../slice';
import { PersonalData } from '../../types/authTypes';

type Props = {
  onFailHandle: () => unknown;
};

export const MobileID: FC<Props> = ({ onFailHandle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state } = useAppLocation();
  const navigate = useNavigate();
  const { originalLocation, redirectToOriginalUrl } = state || {};

  const isWaitingForConfirmation = useAuthSelector<typeof selectIsWaitingForConfirmation>(
    selectIsWaitingForConfirmation
  );

  const [personalId, setPersonalId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const { email } = useAuthSelector<typeof selectUserData>(selectUserData);

  const submit = (data: PersonalData) => {
    setPersonalId(data.customerId);
    setPhoneNumber(data.phone);

    dispatch(setIsWaitingForConfirmation(true));
  };

  const initiateLogin = () => {
    const data: RequestInitMobileID = {
      pid: personalId,
      phoneNumber: phoneNumber,
      email: email
    };

    return dispatch(initiateMobileIDAsync(data)).unwrap();
  };

  const completeLogin = async ({ sessionId }: { sessionId?: string }) => {
    if (!sessionId) {
      return { state: 'failed' };
    }

    return dispatch(loginMobileIDAsync({ sessionId })).unwrap();
  };

  const onLinkBackClick = () => {
    dispatch(setIsWaitingForConfirmation(false));
  };

  const onSuccessHandle = () => {
    if (redirectToOriginalUrl) {
      navigate(originalLocation?.pathname || '/dashboard/overview', { state });
    } else {
      navigate('/dashboard/overview');
    }
  };

  const pollingConfiguration = {
    init: initiateLogin,
    complete: completeLogin
  };

  return (
    <AuthenticationDialog
      hideBackLink
      onFailHandle={onFailHandle}
      testId="mobile-id"
      isWaitingForConfirmation={!!isWaitingForConfirmation}
      onBackHandle={onLinkBackClick}
      onSuccessHandle={onSuccessHandle}
      backLinkLabel={t('back')}
      infoMessage={t('smartIdInfoMessage')}
      timerLabel={t('yourVerificationCodeWillExpireIn')}
      pollingConfig={pollingConfiguration}
    >
      <PersonalDataForm displayPhoneInput onSubmit={submit} continueButtonLabel={t('login')} />
    </AuthenticationDialog>
  );
};
