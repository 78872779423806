import { Type } from 'services/CapService/types';

import { FINANCIAL_PRODUCT } from '../types/applicationTypes';

export const subproducts: Record<Type, { parentId: FINANCIAL_PRODUCT; id: string; label: string }[] | null> =
  {
    FACTORING: null,
    LOAN: [
      { parentId: FINANCIAL_PRODUCT.BUSINESS_LOANS, id: 'BUSINESS_LOAN', label: 'businessLoan' },
      { parentId: FINANCIAL_PRODUCT.BUSINESS_LOANS, id: 'LOAN_FOR_FARMERS', label: 'loanForFarmers' },
      {
        parentId: FINANCIAL_PRODUCT.BUSINESS_LOANS,
        id: 'GREEN_LOAN_FOR_AGRICULTURE',
        label: 'greenLoanForAgriculture'
      },
      {
        parentId: FINANCIAL_PRODUCT.BUSINESS_LOANS,
        id: 'LOAN_WITH_EIF_GUARANTEE',
        label: 'loanWithEIFGuarantee'
      },
      { parentId: FINANCIAL_PRODUCT.BUSINESS_LOANS, id: 'MICROFINANCE', label: 'microfinancing' }
    ],
    RBF: null,
    CREDIT_LINE: null,
    LEASING: [
      { parentId: FINANCIAL_PRODUCT.LEASING, id: 'COMMERCIAL_TRANSPORT', label: 'commercialLeasing' },
      { parentId: FINANCIAL_PRODUCT.LEASING, id: 'CAR_LEASING', label: 'carLeasing' },
      { parentId: FINANCIAL_PRODUCT.LEASING, id: 'EQUIPMENT_LEASING', label: 'equipmentLeasing' },
      { parentId: FINANCIAL_PRODUCT.LEASING, id: 'LEASING_OF_AGRICULTURAL', label: 'agricultureLeasing' }
    ]
  };

type OpenPagesSubproducts =
  | 'commercialTransportLeasing'
  | 'carLeasing'
  | 'equipmentLeasing'
  | 'leasingOfAgriculturalForestryAndConstructionMachinery'
  | 'businessLoan'
  | 'loanForFarmersNow'
  | 'greenLoanForFarmersNow'
  | 'loanWithEifGuarantee'
  | 'microfinancing'
  | 'eif.loanWithEifGuarantee'
  | 'microfinancing.microfinancing';

export const openPagesSubproductsMapping = (subproduct?: string) => {
  if (!subproduct) return undefined;

  const openPagesSubproducts: Record<OpenPagesSubproducts, string> = {
    commercialTransportLeasing: 'COMMERCIAL_TRANSPORT',
    carLeasing: 'CAR_LEASING',
    equipmentLeasing: 'EQUIPMENT_LEASING',
    leasingOfAgriculturalForestryAndConstructionMachinery: 'LEASING_OF_AGRICULTURAL',
    businessLoan: 'BUSINESS_LOAN',
    loanForFarmersNow: 'LOAN_FOR_FARMERS',
    greenLoanForFarmersNow: 'GREEN_LOAN_FOR_AGRICULTURE',
    loanWithEifGuarantee: 'LOAN_WITH_EIF_GUARANTEE',
    microfinancing: 'MICROFINANCE',
    'eif.loanWithEifGuarantee': 'LOAN_WITH_EIF_GUARANTEE',
    'microfinancing.microfinancing': 'MICROFINANCE'
  };

  return openPagesSubproducts[subproduct as OpenPagesSubproducts] || undefined;
};

type ProductConfig = { product: FINANCIAL_PRODUCT; type: Type };

type Products = Record<string, ProductConfig>;

export const products: Products = {
  factoring: { product: FINANCIAL_PRODUCT.FACTORING, type: 'FACTORING' },
  creditline: { product: FINANCIAL_PRODUCT.BUSINESS_LOANS, type: 'CREDIT_LINE' },
  loans: { product: FINANCIAL_PRODUCT.BUSINESS_LOANS, type: 'LOAN' },
  leasing: { product: FINANCIAL_PRODUCT.LEASING, type: 'LEASING' },
  rbf: { product: FINANCIAL_PRODUCT.RBF, type: 'RBF' }
};
