import { ReactNode, useEffect, useRef, useState } from 'react';

import { SearchInput } from 'components';
import { AsideMenuTopBar } from 'components/pageV2';
import { DrawerTopbarTitle } from 'components/pageV2/layout.styles';
import { useKeyboardHandler } from 'features/app/hooks';

import { DrawerContainer, InnerContainer, SearchInputContainer } from './drawer.styles';

type Props = {
  children: ReactNode;
  onClose?: () => unknown;
  header?: string;
  deepNested?: boolean;
  searchable?: boolean;
  onSearch?: (searchValue: string) => unknown;
  searchInputPlaceholder?: string;
  searchQuery?: string;
  dataTestId?: string;
};

const InnerDrawer = ({
  children,
  onClose,
  header,
  deepNested,
  searchable,
  searchInputPlaceholder,
  dataTestId,
  onSearch,
  searchQuery = ''
}: Props) => {
  const drawerContainerRef = useRef<HTMLDivElement>(null);

  const [initialHeight, setInitialHeight] = useState(0);

  const handleClose = () => {
    onClose?.();
  };

  const handleSearch = (searchValue: string) => {
    onSearch?.(searchValue);
  };

  useKeyboardHandler(handleClose, handleClose);

  useEffect(() => {
    if (drawerContainerRef.current) {
      setInitialHeight(drawerContainerRef.current.clientHeight);
    }
  }, []);

  return (
    <>
      <AsideMenuTopBar
        dataTestId={dataTestId}
        inner={deepNested}
        handleClose={handleClose}
        topbarContent={header ? <DrawerTopbarTitle>{header}</DrawerTopbarTitle> : null}
      />
      {searchable ? (
        <SearchInputContainer data-testid={`drawer-${dataTestId}-search-input-container`}>
          <SearchInput value={searchQuery} placeholder={searchInputPlaceholder} onChange={handleSearch} />
        </SearchInputContainer>
      ) : null}
      <DrawerContainer
        data-testid={`drawer-content-${dataTestId}`}
        minHeight={initialHeight}
        ref={drawerContainerRef}
      >
        <InnerContainer>{children}</InnerContainer>
      </DrawerContainer>
    </>
  );
};

export default InnerDrawer;
