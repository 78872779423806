import { AnyAction, createListenerMiddleware, isPending, isRejected } from '@reduxjs/toolkit';

import {
  createApplication,
  fetchApplication,
  submitApplication,
  submitPipedriveApplication,
  updateApplication
} from 'features/application/api';
import {
  checkStatusEIDeasyAsync,
  completeEIDeasyAsync,
  initEIDeasyAsync,
  initiateMobileIDAsync,
  initiateOTPAsync,
  initiateSmartIDAsync,
  loginMobileIDAsync,
  loginSSOAsync,
  loginSmartIDAsync,
  refreshMobileSSOTokenAsync,
  validateUserEmail
} from 'features/auth/slice';
import {
  fetchInstitutionsByCountryAsync,
  fetchInsitutionConsentLink,
  fetchCollectData
} from 'features/statements/slice';

import { clearLoginWarning, setLoginWarning, setToastMessage } from './slice';
import { Effect } from './types';

/**
 * Create a listener middleware
 * In development mode will log out all sync errors to console
 */
const listenerMiddleware = createListenerMiddleware({
  onError: process.env.NODE_ENV === 'development' ? console.error : undefined
});

/**
 * Error handling for app (save toast message to be displayed)
 * Import async thunk action you want to listen for error and add it to requestFailed matcher
 */

/**
 * Match cap async thunks actions rejected state
 */
const capRequestFailed = (action: AnyAction) => {
  const apiEndpointsRejected = [
    createApplication.matchRejected(action),
    updateApplication.matchRejected(action),
    submitApplication.matchRejected(action),
    submitPipedriveApplication.matchRejected(action),
    fetchApplication.matchRejected(action)
  ];

  return (
    isRejected(fetchInstitutionsByCountryAsync, fetchInsitutionConsentLink, fetchCollectData)(action) ||
    apiEndpointsRejected.some(Boolean)
  );
};

/**
 * If action was rejected push toast object to app state
 */
const capRequestFailedEffect: Effect = (_action, { dispatch }) => {
  dispatch(setToastMessage({ id: 'generalErrorMessage', status: 'error', message: 'generalErrorMessage' }));
};

listenerMiddleware.startListening({
  predicate: capRequestFailed,
  effect: capRequestFailedEffect
});

const loginAsyncThunksToHandle = [
  validateUserEmail,
  initiateSmartIDAsync,
  loginSmartIDAsync,
  initiateMobileIDAsync,
  loginMobileIDAsync,
  initiateOTPAsync,
  loginSSOAsync,
  initEIDeasyAsync,
  checkStatusEIDeasyAsync,
  completeEIDeasyAsync,
  refreshMobileSSOTokenAsync
] as const;

/**
 * Match login async thunks actions rejected state
 */
const loginRequestFailed = isRejected(...loginAsyncThunksToHandle);

/**
 * Match login async thunks actions pending state
 */
const loginRequestPending = isPending(...loginAsyncThunksToHandle);

/**
 * If action was rejected push toast object to app state
 */

const loginRequestFailedEffect: Effect = (action, { dispatch }) => {
  if (action.type === 'auth/loginSmartID/rejected' && action.error) {
    const payload = action.payload;
    const errorCode = payload.response?.data?.errorCode;

    if (errorCode !== undefined) {
      switch (errorCode) {
        case '6007':
          dispatch(setLoginWarning({ message: 'basicSmartIdMessage', header: 'basicSmartIdMessageHeader' }));
          break;
        default:
          dispatch(setLoginWarning({ message: '', header: 'errorOccuredMessage' }));
      }
    }
  } else {
    dispatch(setLoginWarning({ message: '', header: 'errorOccuredMessage' }));
  }
};

/**
 * If action went into pending state reset toast object in app state
 */
const loginRequestInitiatedEffect: Effect = (_action, { dispatch }) => {
  dispatch(clearLoginWarning());
};

listenerMiddleware.startListening({
  matcher: loginRequestFailed,
  effect: loginRequestFailedEffect
});

listenerMiddleware.startListening({
  matcher: loginRequestPending,
  effect: loginRequestInitiatedEffect
});

export default listenerMiddleware;
