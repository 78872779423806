import {
  pickersLayoutClasses,
  PickersLayoutProps,
  PickersLayoutRoot,
  usePickerLayout
} from '@mui/x-date-pickers/PickersLayout';

import { SpaPickersLayoutContentWrapper } from './datePicker.styles';

const DatePickerLayout = (props: PickersLayoutProps<any, any, any>) => {
  const { tabs, content, actionBar, shortcuts } = usePickerLayout(props);

  return (
    <PickersLayoutRoot className={pickersLayoutClasses.root} ownerState={props}>
      <SpaPickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {tabs}
        {content}
      </SpaPickersLayoutContentWrapper>
      {actionBar}
      {shortcuts}
    </PickersLayoutRoot>
  );
};

export default DatePickerLayout;
