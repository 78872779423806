import styled from 'styled-components';

import { Legend, RegularText } from 'components';

export const BankAccountButton = styled.div.attrs({ role: 'button' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0 none;
  height: 4.375rem;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0;
  margin-bottom: 0.625rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const BankAccountContent = styled.div.attrs({ role: 'button' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
  height: 4.375rem;
  max-width: 100%;
  flex-grow: 1;
  border-radius: 0.5rem;
  padding: 0 1.25rem 0 1rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.black.shades[20]};

  img {
    max-height: 2.625rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    border: 0.0625rem solid ${({ theme }) => theme.colors.base.black.shades[60]};
  }
`;

export const BankName = styled(RegularText)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const BankAccountsFormStyles = styled.div`
  ${Legend} {
    padding: 0 0 1.25rem;
  }
`;

export const AccountsSection = styled.section`
  margin: 1rem 0 0.625rem;

  &:last-of-type {
    margin: 1rem 0 0;
  }
`;

export const BankAccountsFormHeadersStyles = styled.div`
  ${Legend} {
    margin-top: 1.25rem;
  }
`;
