import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InvoicesService } from 'services';
import {
  SubmittedInvoicesByContractsResponse,
  SubmittedInvoicesByContractsRequest,
  SupportedDocumentsGetRequest,
  SupportedDocumentsDeleteRequest,
  InvoicesTotalsGetRequest,
  VerifiersGetRequest,
  VerifiersContact,
  SubmitInvoicesPostRequestPayload,
  SummaryDataGetRequest
} from 'services/InvoicesService/types';

import { getVerificationStepIndex } from './components/stepper/sequence';
import { InvoicesState, UPLOAD_STEPS } from './types';

export const initialState: InvoicesState = {
  isLoading: false,
  isTotalsLoading: false,
  isInvoicesLoadFailed: false,
  isVerifiersLoading: false,
  isVerifiersLoadFailed: false,
  isUploadingInvoices: false,
  invoicesFullReport: {} as SubmittedInvoicesByContractsResponse,
  uploadedInvoices: [],
  totalsForContract: {},
  summaryData: {},
  uploadedInvoicesContacts: [],
  supportDocuments: { fileNames: [] },
  activeStep: UPLOAD_STEPS.CONTRACTS,
  completionIndex: -1,
  verifiers: {}
};

const checkIfContactsChanged = (contacts: VerifiersContact[], previousContacts: VerifiersContact[]) => {
  if ((contacts?.length || 0) !== (previousContacts?.length || 0)) {
    return true;
  }

  const filteredContacts = contacts.filter(
    (contact) =>
      !previousContacts.find((previousContact) => previousContact.thirdPartyId === contact.thirdPartyId)
  );

  return !!filteredContacts?.length;
};

const resetCompletionIndexToVerificationStep = (state: InvoicesState) => {
  const verificationIndex = getVerificationStepIndex();
  const needToResetIndex = state.completionIndex >= verificationIndex;

  if (needToResetIndex) {
    state.completionIndex = verificationIndex - 1;
  }
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setCompletionIndex: (state, action: PayloadAction<number>) => {
      state.completionIndex = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<UPLOAD_STEPS>) => {
      state.activeStep = action.payload;
    },
    setUploadedInvoicesContacts: (state, action: PayloadAction<VerifiersContact[]>) => {
      state.uploadedInvoicesContacts = action.payload;
    },
    resetInvoicesState: () => initialState
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoicesAsync.pending, (state) => {
        state.isLoading = true;
        state.isInvoicesLoadFailed = false;
      })
      .addCase(fetchInvoicesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isInvoicesLoadFailed = false;
        state.invoicesFullReport = action.payload;
      })
      .addCase(fetchInvoicesAsync.rejected, (state) => {
        state.isLoading = false;
        state.isInvoicesLoadFailed = false;
        state.invoicesFullReport = {} as SubmittedInvoicesByContractsResponse;
      });

    builder
      .addCase(fetchAllSupportDocumentsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllSupportDocumentsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.supportDocuments = action.payload;
      })
      .addCase(fetchAllSupportDocumentsAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchInvoicesTotalsAsync.pending, (state) => {
        state.isTotalsLoading = true;
      })
      .addCase(fetchInvoicesTotalsAsync.fulfilled, (state, action) => {
        state.isTotalsLoading = false;
        state.totalsForContract = action.payload;
      })
      .addCase(fetchInvoicesTotalsAsync.rejected, (state) => {
        state.isTotalsLoading = false;
        state.totalsForContract = {};
      });

    builder
      .addCase(uploadSupportDocumentsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadSupportDocumentsAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadSupportDocumentsAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteSupportDocumentAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSupportDocumentAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSupportDocumentAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchSummaryDataAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSummaryDataAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.summaryData = action.payload;
      })
      .addCase(fetchSummaryDataAsync.rejected, (state) => {
        state.isLoading = false;
        state.summaryData = {};
      });

    builder
      .addCase(fetchInvoicesVerifiersAsync.pending, (state) => {
        state.isVerifiersLoading = true;
        state.isVerifiersLoadFailed = false;
      })
      .addCase(fetchInvoicesVerifiersAsync.fulfilled, (state, action) => {
        const { verificationRequired, verifierDetails } = action.payload || {};
        const {
          verificationRequired: previousVerificationRequired,
          verifierDetails: previousVerifierContacts
        } = state.verifiers || {};

        const contactsChanged = checkIfContactsChanged(verifierDetails || [], previousVerifierContacts || []);

        if (previousVerificationRequired !== verificationRequired || contactsChanged) {
          resetCompletionIndexToVerificationStep(state);
          state.uploadedInvoicesContacts = [];
        }

        state.isVerifiersLoading = false;
        state.isVerifiersLoadFailed = false;
        state.verifiers = action.payload;
      })
      .addCase(fetchInvoicesVerifiersAsync.rejected, (state) => {
        resetCompletionIndexToVerificationStep(state);

        state.isVerifiersLoading = false;
        state.isVerifiersLoadFailed = true;
      });
  }
});

export const fetchInvoicesAsync = createAsyncThunk(
  'invoices/getInvoices',
  async (payload: SubmittedInvoicesByContractsRequest) => {
    return await InvoicesService.get(payload);
  }
);

export const submitInvoicesForVerificationAsync = createAsyncThunk(
  'invoices/submitInvoices',
  async (payload: SubmitInvoicesPostRequestPayload) => {
    return await InvoicesService.submitInvoicesForVerification(payload);
  }
);

export const fetchAllSupportDocumentsAsync = createAsyncThunk(
  'invoices/getAllSupportDocuments',
  async (payload: SupportedDocumentsGetRequest) => {
    return await InvoicesService.getSupportedDocuments(payload);
  }
);

export const uploadSupportDocumentsAsync = createAsyncThunk(
  'invoices/uploadSupportDocuments',
  async ({ contractId, formData }: { contractId: string; formData: FormData }) => {
    return await InvoicesService.uploadSupportDocument(contractId, formData);
  }
);

export const deleteSupportDocumentAsync = createAsyncThunk(
  'invoices/deleteSupportDocument',
  async (payload: SupportedDocumentsDeleteRequest) => {
    return await InvoicesService.removeSupportDocumentById(payload);
  }
);

export const fetchInvoicesTotalsAsync = createAsyncThunk(
  'invoices/getTotals',
  async (payload: InvoicesTotalsGetRequest) => {
    return await InvoicesService.getTotals(payload);
  }
);

export const fetchInvoicesVerifiersAsync = createAsyncThunk(
  'invoices/getVerifiers',
  async (payload: VerifiersGetRequest) => {
    return await InvoicesService.getVerifiers(payload);
  }
);

export const downloadExcelTemplate = createAsyncThunk('invoices/downloadExcelTemplate', async () => {
  return await InvoicesService.downloadExcelTemplate();
});

export const fetchSummaryDataAsync = createAsyncThunk(
  'invoices/getSummaryData',
  async (payload: SummaryDataGetRequest) => {
    return await InvoicesService.getSummaryData(payload);
  }
);

export const { setCompletionIndex, setActiveStep, setUploadedInvoicesContacts, resetInvoicesState } =
  invoicesSlice.actions;

export default invoicesSlice.reducer;
