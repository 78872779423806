import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Field, Input } from 'components';
import { formatNumber } from 'components/text/formatters';
import { useFormContext } from 'features/application/context/FormContext';
import { selectAmount, selectIsPrefilledFromWeb, selectProductType } from 'features/application/selectors';
import { Type } from 'services/CapService/types';
import { amountRangeRequired } from 'validators';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { useAppDispatch, useApplicationSelector } from '../../../../hooks/redux/hooks';
import { FormContainer } from '../../components/formContainer';
import { setApplicationAmount } from '../../slice';

import { AmountFormStyle } from './selectAmountForm.styles';

type Amount = {
  requestedAmount: number;
  type?: Type;
};

const AmountRanges = {
  min: 5000,
  max: 5000000
};

const FactoringRanges = {
  min: 10000,
  max: 5000000
};

const amountLabel: Record<Type, string> = {
  CREDIT_LINE: 'amount',
  LOAN: 'amount',
  LEASING: 'amount',
  RBF: 'averageMonthlyRevenue',
  FACTORING: 'amount'
};

const validateProductType = Yup.number().when('type', (type: Type) => {
  if (type === 'FACTORING') return amountRangeRequired(FactoringRanges);

  return amountRangeRequired(AmountRanges);
});

export const validationSchema = Yup.object({
  requestedAmount: validateProductType
});

const SelectAmount: FC = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const isPrefilledFromWeb =
    useApplicationSelector<typeof selectIsPrefilledFromWeb>(selectIsPrefilledFromWeb);
  const type = useApplicationSelector<typeof selectProductType>(selectProductType);
  const selectedAmount = useApplicationSelector(selectAmount);
  const selectType = useApplicationSelector(selectProductType);

  const initialAmount = type === 'FACTORING' ? FactoringRanges.min : AmountRanges.min;

  const initialValues: Amount = {
    requestedAmount: selectedAmount ?? initialAmount,
    type
  };

  const memoizedInitialValues = useMemo(() => initialValues, [type, selectedAmount]);

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers<Amount>(
    memoizedInitialValues,
    validationSchema,
    'onBlur'
  );

  const { setFormHandlers } = useFormContext<Amount>();

  const submitHandler = handleSubmit(
    (data: Amount) => {
      dispatch(setApplicationAmount(data.requestedAmount));
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(
    () =>
      setFormHandlers({
        submitHandler
      }),
    [setFormHandlers]
  );

  const formatterCurrency = formatNumber(i18n.language);

  const amountHelpText = `${formatterCurrency(
    type === 'FACTORING' ? FactoringRanges.min : AmountRanges.min,
    0
  )} - ${formatterCurrency(type === 'FACTORING' ? FactoringRanges.max : AmountRanges.max, 0)}`;

  return (
    <FormContainer isValid={validationHelpers.isValid} onNextClick={submitHandler}>
      <AmountFormStyle>
        <Field
          size="L"
          Component={Input}
          required
          name="requestedAmount"
          placeholder={t(amountLabel[selectType as Type])}
          value={getValues('requestedAmount')}
          validationHelpers={validationHelpers}
          type="number"
          maxDecimals={2}
          disabled={isPrefilledFromWeb}
          suffix="€"
          helpText={amountHelpText}
        />
      </AmountFormStyle>
    </FormContainer>
  );
};

export default SelectAmount;
