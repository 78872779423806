import styled from 'styled-components';

import {
  LinkButtonStyle,
  NativeLinkButton,
  RegularText,
  SecondaryText,
  ShimmerOnWhite,
  ToastLegacy,
  Typography
} from 'components';
import { Button } from 'components/buttonV2';

export const AdditionalInfoContainer = styled.div`
  margin-bottom: 1rem;

  ${RegularText} {
    line-height: 1.5rem;
  }

  ${SecondaryText} {
    font-size: ${({ theme }) => theme.fontSizes.h6};
    display: block;
    line-height: 1.25rem;
  }
`;

export const TableWrapper = styled.div``;

export const UsersTableContainer = styled.div`
  font-size: 0.875rem;
`;

export const CrudActions = styled.div`
  display: flex;
  justify-self: flex-end;
`;

export const Action = styled.div`
  width: 2rem;
  display: flex;
  justify-content: center;

  ${LinkButtonStyle} {
    .pencil {
      path {
        stroke: ${({ theme }) => theme.colors.base.black.shades[50]};
      }
    }

    .bin {
      path {
        fill: ${({ theme }) => theme.colors.base.black.shades[50]};
      }
    }
  }
`;

export const UserInputActionsIconContainer = styled.div`
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
`;

export const UserInputActions = styled.div`
  display: flex;
  flex: 1 0 calc(100% - 84px);
  margin-right: 0.625rem;

  ${LinkButtonStyle} {
    width: 100%;

    svg {
      margin-right: 0;
    }

    span {
      color: ${({ theme }) => theme.colors.primary.linkText};
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const SecondaryHeader = styled.div`
  display: flex;
  justify-content: flex-end;

  ${LinkButtonStyle} {
    font-size: 1rem;
    margin-bottom: 0;

    svg {
      path {
        stroke: ${({ theme }) => theme.colors.primary.linkText};
      }
    }
  }

  ${NativeLinkButton} {
    margin-left: 0.625rem;

    ${LinkButtonStyle} {
      svg {
        path {
          stroke: none;
          fill: ${({ theme }) => theme.colors.primary.background};
        }
      }
    }
  }
`;

export const UsersTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    ${SecondaryHeader} {
      justify-content: space-between;
      width: 100%;
    }

    justify-content: flex-end;
  }
`;

export const CellCollumn = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;

  @media ${({ theme }) => theme.media.maxPhone} {
    text-align: end;
  }
`;

export const UsersCreationToast = styled(ToastLegacy)`
  margin-bottom: 1.5rem;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ActionToastContainer = styled.div``;

export const AddUserButton = styled(Button).attrs({ size: 'L' })`
  margin-top: 0.5rem;
  width: 100%;
`;

export const Clause = styled(Typography.Body).attrs({ type: 'L', weight: 'medium' })`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const UserSkeletonRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 1.5rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`;

export const AvatarShimmer = styled(ShimmerOnWhite)`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  border-radius: 100%;
`;

export const RowShimmer = styled(ShimmerOnWhite)`
  height: 24px;
  width: 50%;
  border-radius: 1.5rem;
`;
