import styled, { DefaultTheme, css } from 'styled-components';

/**
 * Button variants
 * Stroked - button with border and transparent background
 * StrokedWhite - button with border and white background
 * Filled - button with background and transparent border
 *
 * default variant is Filled
 * default size is M
 * default color is Blue
 * default icons are empty
 */
export type Props = {
  variant?: 'Stroked' | 'StrokedWhite' | 'Filled';
  size?: 'S' | 'M' | 'L' | 'XL';
  icons?: { left?: string; right?: string };
  color?: 'Blue' | 'DarkBlue' | 'Black' | 'Red' | 'Green' | 'Yellow' | 'LightBlack' | 'LightBlue';
  iconOnly?: boolean;
  invalid?: boolean;
  media?: Partial<{
    [key in keyof DefaultTheme['media']]: {
      size?: 'S' | 'M' | 'L' | 'XL';
    };
  }>;
  doNotTruncate?: boolean;
};

type State = 'rest' | 'hover' | 'active' | 'disabled';

const getMainColor = (state: State, color: Props['color'], theme: DefaultTheme) => {
  switch (color) {
    case 'Red': {
      if (state === 'disabled') {
        return theme.colors.base.red.shades[50];
      }

      return theme.colors.base.red.shades[100];
    }
    case 'Black': {
      if (state === 'rest') {
        return theme.colors.base.black.shades[90];
      }

      if (state === 'disabled') {
        return theme.colors.base.black.shades[40];
      }

      return theme.colors.base.black.functional;
    }
    case 'LightBlack': {
      if (state === 'rest') {
        return theme.colors.base.black.shades[5];
      }

      if (state === 'disabled') {
        return theme.colors.base.black.tints[30];
      }

      return theme.colors.base.black.shades[10];
    }
    case 'LightBlue': {
      if (state === 'rest') {
        return theme.colors.base.primary.shades[10];
      }

      if (state === 'disabled') {
        return theme.colors.base.primary.shades[5];
      }

      return theme.colors.base.primary.tints[20];
    }
    case 'DarkBlue': //TODO add dark blue colors
    case 'Green': //TODO add green colors
    case 'Yellow': //TODO add yellow colors
    case 'Blue':
    default: {
      if (state === 'rest') {
        return theme.colors.base.primary.shades[100];
      }

      if (state === 'disabled') {
        return theme.colors.base.primary.shades[50];
      }

      return theme.colors.base.primary.shades[20];
    }
  }
};

const getButtonBackgroundColor = (
  state: State,
  variant: Props['variant'],
  color: Props['color'],
  theme: DefaultTheme
) => {
  switch (variant) {
    case 'Stroked':
      return 'transparent';
    case 'StrokedWhite':
      return theme.colors.base.white.functional;
    case 'Filled':
    default:
      return getMainColor(state, color, theme);
  }
};

const getButtonBorderColor = (
  state: State,
  variant: Props['variant'],
  color: Props['color'],
  theme: DefaultTheme
) => {
  switch (variant) {
    case 'Stroked':
    case 'StrokedWhite':
    case 'Filled':
    default:
      return getMainColor(state, color, theme);
  }
};

const getButtonHeight = (size: Props['size']) => {
  switch (size) {
    case 'S':
      return '2rem';
    case 'L':
      return '3rem';
    case 'XL':
      return '3.5rem';
    case 'M':
    default:
      return '2.5rem';
  }
};

const getButtonTextSize = (size: Props['size']) => {
  switch (size) {
    case 'S':
      return '0.875rem';
    default:
      return '1rem';
  }
};

const getButtonCornerRadius = (size: Props['size']) => {
  switch (size) {
    case 'S':
      return '0.625rem';
    case 'L':
      return '1rem';
    case 'XL':
      return '1rem';
    case 'M':
    default:
      return '0.75rem';
  }
};

export const getButtonTextColor = (
  state: State,
  variant: Props['variant'],
  color: Props['color'],
  theme: DefaultTheme
) => {
  switch (variant) {
    case 'Stroked':
    case 'StrokedWhite':
      if (color === 'LightBlack') {
        return theme.colors.base.black.functional;
      }

      if (color === 'LightBlue') {
        return theme.colors.base.primary.shades[100];
      }

      return getMainColor(state, color, theme);
    case 'Filled':
    default:
      if (color === 'LightBlack') {
        return theme.colors.base.black.functional;
      }

      if (color === 'LightBlue') {
        return theme.colors.base.primary.shades[100];
      }

      return theme.colors.base.white.functional;
  }
};

const getButtonPadding = (size: Props['size'], icons: Props['icons'], iconOnly?: boolean) => {
  switch (size) {
    case 'S': {
      if (icons?.right) {
        if (iconOnly) {
          return '0.25rem';
        }

        return '0.25rem 0.75rem 0.25rem 1.125rem';
      }

      if (icons?.left) {
        if (iconOnly) {
          return '0.25rem';
        }

        return '0.25rem 1.125rem 0.25rem 0.75rem';
      }

      if (icons?.left && icons?.right) {
        return '0.25rem 0.75rem';
      }

      return '0.25rem 1.125rem';
    }
    case 'L': {
      if (icons?.right) {
        if (iconOnly) {
          return '0.75rem';
        }

        return '0.75rem 0.75rem 0.75rem 1.75rem';
      }

      if (icons?.left) {
        if (iconOnly) {
          return '0.75rem';
        }

        return '0.75rem 1.75rem 0.75rem 0.75rem';
      }

      if (icons?.left && icons?.right) {
        return '0.75rem';
      }

      return '0.75rem 1.75rem';
    }
    case 'XL': {
      if (icons?.right) {
        if (iconOnly) {
          return '1rem';
        }

        return '1rem 1.875rem 1rem 2.25rem';
      }

      if (icons?.left) {
        if (iconOnly) {
          return '1rem';
        }

        return '1rem 2.25rem 1rem 1.875rem';
      }

      if (icons?.left && icons?.right) {
        return '1rem 1.875rem';
      }

      return '1rem 2.25rem';
    }
    case 'M':
    default: {
      if (icons?.right) {
        if (iconOnly) {
          return '0.5rem';
        }

        return '0.5rem 0.5rem 0.5rem 1.375rem';
      }

      if (icons?.left) {
        if (iconOnly) {
          return '0.5rem';
        }

        return '0.5rem 1.375rem 0.5rem 0.5rem';
      }

      if (icons?.left && icons?.right) {
        return '0.5rem';
      }

      return '0.5rem 1.375rem';
    }
  }
};

const disabledStyles = css<Props>`
  background-color: ${({ theme, variant, color }) =>
    getButtonBackgroundColor('disabled', variant, color, theme)};
  border: 0.0625rem solid
    ${({ theme, variant, color }) => getButtonBorderColor('disabled', variant, color, theme)};
  color: ${({ theme, variant, color }) => getButtonTextColor('disabled', variant, color, theme)};

  &::before {
    background-color: ${({ theme, variant, color }) => getButtonTextColor('disabled', variant, color, theme)};
  }

  &::after {
    background-color: ${({ theme, variant, color }) => getButtonTextColor('disabled', variant, color, theme)};
  }

  &:hover,
  &:active,
  &:focus-visible {
    background-color: ${({ theme, variant, color }) =>
      getButtonBackgroundColor('disabled', variant, color, theme)};
    border: 0.0625rem solid
      ${({ theme, variant, color }) => getButtonBorderColor('disabled', variant, color, theme)};
    color: ${({ theme, variant, color }) => getButtonTextColor('disabled', variant, color, theme)};
  }
`;

const animateDisabledTransition = css<Props>`
  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  &[aria-disabled='true'] {
    cursor: not-allowed;
    ${disabledStyles}
  }
`;

export const getButtonDimensionsCss = (size: Props['size'], icons: Props['icons'], iconOnly?: boolean) => css`
  height: ${getButtonHeight(size)};
  border-radius: ${getButtonCornerRadius(size)};
  font-size: ${getButtonTextSize(size)};
  padding: ${getButtonPadding(size, icons, iconOnly)};
`;

const createMediaSizeStyles = (
  media: Props['media'],
  theme: DefaultTheme,
  icons: Props['icons'],
  iconOnly?: boolean
) => {
  const styles = media
    ? Object.keys(media).map((key) => {
        const { size } = media[key as keyof typeof media] || { size: 'M' };

        return `
      @media ${theme.media[key as keyof typeof media]} {        
        height: ${getButtonHeight(size)};
        border-radius: ${getButtonCornerRadius(size)};
        font-size: ${getButtonTextSize(size)};
        padding: ${getButtonPadding(size, icons, iconOnly)};
      }
    `;
      })
    : [''];

  return styles.join('');
};

export const Button = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, variant, color }) => getButtonBackgroundColor('rest', variant, color, theme)};
  border: 0.0625rem solid
    ${({ theme, variant, color }) => getButtonBorderColor('rest', variant, color, theme)};
  font-weight: 400;
  line-height: 1.5rem;
  color: ${({ theme, variant, color }) => getButtonTextColor('rest', variant, color, theme)};
  width: fit-content;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ size, icons, iconOnly }) => getButtonDimensionsCss(size, icons, iconOnly)}
  ${({ media, theme, icons, iconOnly }) => createMediaSizeStyles(media, theme, icons, iconOnly)};
  flex-shrink: ${({ doNotTruncate }) => (doNotTruncate ? 0 : 1)};

  &::before {
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${({ theme, variant, color }) => getButtonTextColor('rest', variant, color, theme)};
    display: inline-block;
    content: ${({ icons }) => (icons?.left ? `""` : 'none')};
    mask-image: ${({ icons }) => (icons?.left ? `url("${icons?.left}")` : 'none')};
    min-width: ${({ icons }) => (icons?.left ? '1.5rem' : '0')};
    opacity: ${({ icons }) => (icons?.left ? '100%' : '0')};
    height: 1.5rem;
    margin-right: ${({ iconOnly }) => (iconOnly ? 0 : '0.5rem')};
    transition:
      background-color 0.3s ease-in-out,
      min-width 0.3s ease-in-out,
      opacity 0.3s ease-in-out,
      width 1s ease-in-out;

    @keyframes loader {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  &::after {
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${({ theme, variant, color }) => getButtonTextColor('rest', variant, color, theme)};
    mask-image: ${({ icons }) => (icons?.right ? `url("${icons?.right}")` : 'none')};
    content: ${({ icons }) => (icons?.right ? '""' : 'none')};
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: ${({ iconOnly }) => (iconOnly ? 0 : '0.5rem')};
  }

  &:hover,
  &:active,
  &:focus-visible {
    outline: none;
    background-color: ${({ theme, variant, color }) =>
      getButtonBackgroundColor('hover', variant, color, theme)};
    border: 0.0625rem solid
      ${({ theme, variant, color }) => getButtonBorderColor('hover', variant, color, theme)};
    color: ${({ theme, variant, color }) => getButtonTextColor('hover', variant, color, theme)};

    &::before {
      background-color: ${({ theme, variant, color }) => getButtonTextColor('hover', variant, color, theme)};
    }

    &::after {
      background-color: ${({ theme, variant, color }) => getButtonTextColor('hover', variant, color, theme)};
    }
  }

  ${animateDisabledTransition}
  ${({ invalid }) => (invalid ? disabledStyles : '')}
`;

type ButtonRowProps = {
  desktopDirection?: 'row' | 'column';
  reverse?: boolean;
};

export const ButtonRow = styled.div<ButtonRowProps>`
  display: flex;
  margin-top: 1rem;
  flex-direction: ${({ desktopDirection, reverse }) =>
    desktopDirection === 'column'
      ? reverse
        ? `${desktopDirection}`
        : `${desktopDirection}-reverse`
      : 'row'};
  justify-content: flex-end;
  gap: 1rem;

  ${Button} {
    width: ${({ desktopDirection }) => (desktopDirection === 'row' ? 'fit-content' : '100%')};
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    flex-direction: column;

    ${Button} {
      width: 100%;
    }
  }
`;

ButtonRow.defaultProps = {
  desktopDirection: 'row'
};

export const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Button} {
    width: 100%;
    margin-bottom: 0.625rem;
  }

  ${Button}:last-of-type {
    margin-bottom: 0;
  }
`;

export const ButtonLabel = styled.span`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
`;
