import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { RegularText } from 'components';
import { SubmittedInvoice } from 'services/InvoicesService/types';
import { camelCase } from 'utils';

import {
  DetailsContainer,
  InvoiceDetailsCard,
  HeaderContainer,
  Title,
  Status
} from './invoiceDetails.styles';

const statusIcons = {
  ACCEPTED: <Icons.QuestionaireCompleted />,
  REJECTED: <Icons.QuestionaireRejected />,
  WAITING: <Icons.QuestionaireWaiting />
};

type Props = {
  invoiceDetails: SubmittedInvoice;
};

export const InvoiceDetailsApproval: FC<Props> = ({ invoiceDetails }) => {
  const { t } = useTranslation();

  return (
    <InvoiceDetailsCard>
      <HeaderContainer>
        <Title>{t('invoiceApproval')}</Title>
        {invoiceDetails.buyerVerificationStatus &&
        invoiceDetails.buyerVerificationStatus !== 'NOT_APPLICABLE' ? (
          <Status>
            <RegularText>{t(camelCase(invoiceDetails.buyerVerificationStatus))} </RegularText>
            {statusIcons[invoiceDetails.buyerVerificationStatus]}
          </Status>
        ) : null}
      </HeaderContainer>
      <DetailsContainer></DetailsContainer>
    </InvoiceDetailsCard>
  );
};
