import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LoansService } from 'services/LoansService';
import { LoansOverviewGetRequestPayload, LoansPaymentsGetRequestPayload } from 'services/LoansService/types';

import { LoansState } from './types';

const initialState: LoansState = {
  isLoading: false,
  isLoadingFailed: false,
  isPaymentsLoading: false,
  isPaymentsLoadFailed: false,
  overview: {},
  payments: {}
};

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    resetLoansState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOverviewAsync.pending, (state) => {
        state.isLoading = true;
        state.isLoadingFailed = false;
        state.overview = {};
      })
      .addCase(getOverviewAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.overview.contracts = action.payload.response;
        state.overview.type = action.payload.request.productType;
      })
      .addCase(getOverviewAsync.rejected, (state) => {
        state.isLoading = false;
        state.isLoadingFailed = true;
        state.overview = {};
      });

    builder
      .addCase(getPaymentsAsync.pending, (state) => {
        state.isPaymentsLoading = true;
        state.isPaymentsLoadFailed = false;
        state.payments = {};
      })
      .addCase(getPaymentsAsync.fulfilled, (state, action) => {
        state.isPaymentsLoading = false;
        state.isPaymentsLoadFailed = false;
        state.payments = action.payload;
      })
      .addCase(getPaymentsAsync.rejected, (state) => {
        state.isPaymentsLoading = false;
        state.isPaymentsLoadFailed = true;
        state.payments = {};
      });
  }
});

export const getOverviewAsync = createAsyncThunk(
  'loans/getOverview',
  async (payload: LoansOverviewGetRequestPayload) => {
    const response = await LoansService.getOverview(payload);

    return { response, request: payload };
  }
);

export const getPaymentsAsync = createAsyncThunk(
  'loans/getPayments',
  async (payload: LoansPaymentsGetRequestPayload) => {
    return LoansService.getPayment(payload);
  }
);

export const { resetLoansState } = loansSlice.actions;

export default loansSlice.reducer;
