import styled from 'styled-components';

import { RegularText, ToastLegacy } from 'components';

export const SchedulesTableContainer = styled.div`
  div[data-column-id='unpaid'],
  div[data-column-id='interest'],
  div[data-column-id='coverageAmount'],
  div[data-column-id='principalPart'],
  div[data-column-id='vatAmount'],
  div[data-column-id='totalAmount'] {
    display: flex;
    justify-content: flex-end;
  }

  div[data-column-id='principalPart'] {
    text-align: end;
  }

  .rdt_Table {
    .rdt_TableCol {
      padding-right: 0;
    }
  }
`;

export const SchedulesTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;
`;

export const HeaderText = styled(RegularText)`
  display: inline-flex;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-right: 1.5rem;
`;

export const MissingScheduleDataToast = styled(ToastLegacy)`
  margin-bottom: 1.25rem;
`;

export const LeftAllignedContent = styled.div`
  display: flex;
`;
