import { FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Dropdown, Input } from 'components';
import { FilterPanelRow, InputContainer } from 'components/filterPanel/filterPanel.styles';
import { OptionsDrawer } from 'features/app/components/drawer';
import { FactoringBillsGetRequest } from 'services/FactoringInvoicesService/types';
import { camelCase } from 'utils';

type InputOnChangeHandler = ((value: string) => string | void) & FormEventHandler<HTMLInputElement>;

export type FilterKeys = keyof Omit<
  FactoringBillsGetRequest,
  'partyId' | 'contractIds' | 'tenantId' | 'page' | 'size' | 'sort'
>;

export type BillsFilterParams = Record<FilterKeys, string>;

type FilterFieldsProps = {
  onChange: (
    name: keyof BillsFilterParams,
    shouldDebounce?: boolean
  ) => (value: string | Date | null) => unknown;
  filterParams: BillsFilterParams;
};

export const billsDefaultParams: BillsFilterParams = {
  dateFrom: '',
  dateTo: '',
  invoiceNumber: '',
  billTypes: '',
  billStatus: ''
};

const billTypes = ['ALL', 'LATE_FEE', 'INTEREST_FEE', 'COMMISSION_FEE', 'OTHER_FEE'];
const billStatus = ['ALL', 'PAID', 'UNPAID'];

export const BillsFilterFields: FC<FilterFieldsProps> = ({ onChange, filterParams }) => {
  const { t } = useTranslation();

  const createOption = (value: string) => ({ value, label: t(camelCase(value)) });

  const [billTypesVisible, setBillTypesVisible] = useState(false);
  const [billStatusVisible, setBillStatusVisible] = useState(false);

  const billTypeOptions = billTypes.map((type) => createOption(type));
  const billStatusOptions = billStatus.map((status) => createOption(status));

  const onBillTypeChange = (value: string) => {
    onChange('billTypes')(value);

    setTimeout(() => setBillTypesVisible(false), 150);
  };

  const onBillStatusChange = (value: string) => {
    onChange('billStatus')(value);

    setTimeout(() => setBillStatusVisible(false), 150);
  };

  return (
    <>
      <OptionsDrawer
        isOpen={billTypesVisible}
        onClose={() => setBillTypesVisible(false)}
        header={t('type')}
        onChange={onBillTypeChange}
        selectedValue={filterParams.billTypes}
        options={billTypeOptions}
      />
      <OptionsDrawer
        isOpen={billStatusVisible}
        onClose={() => setBillStatusVisible(false)}
        header={t('status')}
        onChange={onBillStatusChange}
        selectedValue={filterParams.billStatus}
        options={billStatusOptions}
      />
      <FilterPanelRow>
        <InputContainer>
          <Input
            required
            placeholder={t('number')}
            onChange={onChange('invoiceNumber', true) as unknown as InputOnChangeHandler}
            value={filterParams.invoiceNumber}
          />
        </InputContainer>
        <InputContainer>
          <Dropdown
            required
            placeholder={t('type')}
            onChange={onChange('billTypes')}
            separateFromMenu
            onClick={() => setBillTypesVisible(true)}
            value={filterParams.billTypes}
            options={billTypeOptions}
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('dateFrom')}
            onChange={onChange('dateFrom')}
            value={filterParams.dateFrom}
            required
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('dateTo')}
            onChange={onChange('dateTo')}
            value={filterParams.dateTo}
            required
          />
        </InputContainer>
      </FilterPanelRow>
      <FilterPanelRow>
        <InputContainer>
          <Dropdown
            required
            placeholder={t('status')}
            onChange={onChange('billStatus')}
            separateFromMenu
            onClick={() => setBillStatusVisible(true)}
            value={filterParams.billStatus}
            options={billStatusOptions}
          />
        </InputContainer>
      </FilterPanelRow>
    </>
  );
};
