import { FC } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AsyncSearchInput, Field, Fieldset, Input } from 'components';
import { GenericOption } from 'components/dropdown/Label';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { useLazySearchCompaniesByNameQuery } from 'features/application/api';
import {
  selectCompanyRegistrationCountryCode,
  selectIsPrefilledFromWeb
} from 'features/application/selectors';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { CompaniesSearchResponse } from 'services/Cap2Service/types';
import { Company } from 'services/CapService/types';
import tracking from 'utils/tracking';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';

type Props = {
  validationHelpers: ValidationHelpers<Company>;
  getValues: UseFormGetValues<Company>;
  isGhostUser: boolean;
};

export const CompanyFields: FC<Props> = ({ validationHelpers, getValues, isGhostUser }) => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const isPrefilledFromWeb =
    useApplicationSelector<typeof selectIsPrefilledFromWeb>(selectIsPrefilledFromWeb);

  const companyCountry = useApplicationSelector<typeof selectCompanyRegistrationCountryCode>(
    selectCompanyRegistrationCountryCode
  );

  const disabled = !isGhostUser || isPrefilledFromWeb;

  const { t } = useTranslation();

  const [searchCompanies] = useLazySearchCompaniesByNameQuery();

  const handleSelect = (option: GenericOption | null) => {
    if (!option) {
      return;
    }

    validationHelpers.setValue('name', option.label);

    if (option.value) {
      validationHelpers.setValue('code', option.value);
      tracking.setSubmitEvent('application_company_suggestion_selected');
    }
  };

  const handleSearch = async (searchQuery: string) => searchCompanies(searchQuery).unwrap();

  const mapResults = (results: CompaniesSearchResponse) =>
    results?.map((company) => ({
      label: company.title,
      value: company.code,
      sublabel: company.code
    }));

  const isSearchDisabled = companyCountry?.toUpperCase() !== 'LT' || !isFeatureEnabled('companyAutofill');

  return (
    <Fieldset>
      <Field
        Component={AsyncSearchInput}
        disabled={disabled}
        size="L"
        name="name"
        onResultSelect={handleSelect}
        disableSearch={isSearchDisabled}
        addQueryAsOption
        onResultsAvailable={mapResults}
        asyncFetchResults={handleSearch}
        value={getValues('name') ?? ''}
        validationHelpers={validationHelpers}
        placeholder={t('placeholderCompanyName')}
        required
      />
      <Field
        Component={Input}
        size="L"
        name="code"
        disabled={disabled}
        value={getValues('code')}
        placeholder={t('placeholderCompanyCode')}
        validationHelpers={validationHelpers}
        required
      />
    </Fieldset>
  );
};
