import Icons from 'assets/icons';
import { ApplicationContentDrawer } from 'features/application/components';
import { InformationReviewDrawer, OfferStatusModal } from 'features/applications/components';
import { useNavContext } from 'features/nav/context/navContext';
import { CreateCompanyDrawer, EditCompanyInfoDrawer, EditPhoneDrawer } from 'features/users/components';
import { CrudUserDrawer, CrudUserDrawerToggleParams } from 'features/users/components/crudUserDrawer';
import { RolesDrawer, RolesDrawerToggleParams } from 'features/users/components/rolesDrawer';

import { InformationDrawer } from '..';

type GenericDrawer = {
  drawerId:
    | 'editUser'
    | 'roles'
    | 'applicationDetails'
    | 'offerDetails'
    | 'reviewInProgress'
    | 'createCompany'
    | 'createCompanySuccess'
    | 'editPhone'
    | 'editCompanyName'
    | 'editCrmId'
    | 'editCompanyCode';
  id?: string;
  drawer?: string;
};

export type ApplicationsDrawer = {
  applicationId?: string;
  offerId?: string;
  drawer: GenericDrawer['drawerId'];
};

type Implementations = {
  editUser: CrudUserDrawerToggleParams;
  roles: RolesDrawerToggleParams;
  applicationDetails: ApplicationsDrawer;
  offerDetails: ApplicationsDrawer;
  reviewInProgress: ApplicationsDrawer;
  createCompany: unknown;
  createCompanySuccess: unknown;
  editPhone: unknown;
  editCompanyName: unknown;
  editCrmId: unknown;
  editCompanyCode: unknown;
};

export type DrawerImplementation<TId extends GenericDrawer['drawerId']> = Implementations[TId] & {
  drawerId: TId;
  id?: string;
  drawer?: string;
};

type DrawersSwitchProps = {
  toggleBankAccountDrawer?: (params: { inner: boolean; isOpen: boolean }) => void;
};

const DrawersSwitch = ({ toggleBankAccountDrawer }: DrawersSwitchProps) => {
  const { activeDrawer, toggleRightSideDrawer } = useNavContext<GenericDrawer>();

  if (!activeDrawer) return null;

  switch (activeDrawer.drawerId ?? activeDrawer.drawer ?? activeDrawer.id) {
    case 'editUser':
      return <CrudUserDrawer />;
    case 'roles':
      return <RolesDrawer />;
    case 'reviewInProgress':
      return <InformationReviewDrawer />;
    case 'applicationDetails':
      return <ApplicationContentDrawer />;
    case 'offerDetails':
      return (
        <OfferStatusModal
          application={activeDrawer as DrawerImplementation<'offerDetails'>}
          readonly
          toggleDrawer={() =>
            toggleRightSideDrawer<DrawerImplementation<'offerDetails'>>?.({
              drawer: 'offerDetails',
              drawerId: 'offerDetails'
            })
          }
          openBankAccountDrawer={(inner, visible) => toggleBankAccountDrawer?.({ inner, isOpen: visible })}
        />
      );
    case 'createCompany':
      return <CreateCompanyDrawer />;
    case 'createCompanySuccess':
      return (
        <InformationDrawer
          header="newCompanyCreated"
          body="salesAfterNewCompanyCreationExplanation"
          icon={<Icons.DoneCheckmarkLarge />}
        />
      );
    case 'editPhone':
      return <EditPhoneDrawer />;
    case 'editCompanyName':
      return <EditCompanyInfoDrawer updateProperty="customerName" />;
    case 'editCrmId':
      return <EditCompanyInfoDrawer updateProperty="customerCrmCode" />;
    case 'editCompanyCode':
      return <EditCompanyInfoDrawer updateProperty="customerCode" />;
    default:
      return null;
  }
};

export default DrawersSwitch;
