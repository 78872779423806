import styled from 'styled-components';

export const ChartContainer = styled.div`
  display: flex;
  min-height: 15.625rem;
  height: 15.625rem;
  width: 100%;
`;

export const CustomTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:focus {
    border: none;
  }
`;
