import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusChip } from 'components';
import { ChipText } from 'features/loans/components/loansContractDetails/loansContractDetails.styles';
import { ContractDetailsResponse } from 'services/ContractsService/types';
import { camelCase } from 'utils';

import { statusToColorMap, withNullCheck } from '../factoringContractDetails/factoringContractDetails';

import { Header, HeaderContainer, HeaderTextContainer, Subheader } from './invoiceDetailsHeader.styles';

type Props = {
  invoiceNumber: string;
  contractNumber: string;
  status: ContractDetailsResponse['status'];
};

export const InvoiceDetailsHeader: FC<Props> = ({ status, contractNumber, invoiceNumber }) => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <HeaderTextContainer>
        <Header>{invoiceNumber}</Header>
        <Subheader>{`${t('contract')} ${contractNumber}`}</Subheader>
      </HeaderTextContainer>
      <StatusChip color={status ? statusToColorMap[status] : 'white'}>
        {<ChipText>{t(camelCase(withNullCheck(status) as string))}</ChipText>}
      </StatusChip>
    </HeaderContainer>
  );
};
