export enum PURPOSE {
  WORKING_CAPITAL = 'WORKING_CAPITAL',
  INVESTMENT = 'INVESTMENT',
  OTHER = 'OTHER'
}

export const PURPOSE_LIST = [
  {
    label: 'workingCapital',
    value: PURPOSE.WORKING_CAPITAL
  },
  {
    label: 'investment',
    value: PURPOSE.INVESTMENT
  },
  {
    label: 'other',
    value: PURPOSE.OTHER
  }
] as const;
