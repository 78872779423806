import styled from 'styled-components';

import { Button } from '../buttonV2/button.styles';
import { Typography } from '../typography';

export const SuccessCloseButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.5rem;

  ${Button} {
    width: 100%;
    max-width: 16rem;
  }
`;

export const SuccessImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  img {
    width: 11rem;
    height: 11rem;
  }
`;

export const SuccessTitle = styled(Typography.Header).attrs({ type: 'h3', weight: 'medium' })`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  text-align: center;
`;

export const SuccessMessage = styled(Typography.Body).attrs({ type: 'L', weight: 'normal' })`
  display: inline-flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
  text-align: center;
`;

export const SuccessTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
`;

export const Image = styled.img`
  max-width: 11rem;
  max-height: 11rem;
  width: 100%;
`;
