import { getValue, RemoteConfig } from 'firebase/remote-config';

import { RemoteConfigSingleton } from 'services';

import { ResolvedFeaturesConfig } from '../../configuration/features';

const isFeatureEnabledInDefaultConfig = (remoteConfig: RemoteConfig, keyName: string) => {
  return JSON.parse(remoteConfig.defaultConfig.features as string)[keyName];
};

const valueExistsInsideRemoteFeatures = (features: Record<string, boolean>, keyName: string) => {
  return features[keyName] !== undefined;
};

export const checkIsFeatureEnabled = (remoteConfig: RemoteConfig | null) => (keyName: string) => {
  let isFeatureEnabled = false;

  if (remoteConfig) {
    const featuresJSONString = getValue(remoteConfig, 'features').asString();

    try {
      const features = JSON.parse(featuresJSONString);

      if (valueExistsInsideRemoteFeatures(features, keyName)) {
        isFeatureEnabled = features[keyName];
      } else {
        isFeatureEnabled = isFeatureEnabledInDefaultConfig(remoteConfig, keyName);
      }
    } catch {
      isFeatureEnabled = isFeatureEnabledInDefaultConfig(remoteConfig, keyName);
    }
  } else {
    isFeatureEnabled = FEATURES[keyName];
  }

  return isFeatureEnabled;
};

export const isFeatureEnabled = (keyName: keyof ResolvedFeaturesConfig) => {
  const isInTestEnv = process.env.NODE_ENV === 'test';

  if (isInTestEnv) {
    /**
     * for running tests in node enviroments resolve default configuration
     */
    return true;
  }

  const remoteConfigInstance = RemoteConfigSingleton.getInstance();

  return checkIsFeatureEnabled(remoteConfigInstance.remoteConfig)(keyName);
};
