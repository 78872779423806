import { MutableRefObject, useRef } from 'react';

import { AsideDrawer, ChipOption } from 'components';
import { Overlay } from 'components/pageV2/layout.styles';
import { useCheckIfClickedOutside } from 'features/nav/hooks';

import InnerDrawer from './innerDrawer';

type Props = {
  isOpen: boolean;
  onClose: () => unknown;
  onChange: (value: string) => unknown;
  options: { label: string; value: string }[];
  selectedValue?: string;
  header?: string;
};

const OptionsDrawer = ({ isOpen, header, selectedValue, options, onClose, onChange }: Props) => {
  const drawerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

  useCheckIfClickedOutside(onClose, isOpen, [drawerRef]);

  return (
    <>
      <AsideDrawer ref={drawerRef} isOpen={isOpen} portal={true} activeIndex={2} position="fixed">
        <Overlay position="absolute" />
        <InnerDrawer onClose={onClose} header={header} deepNested>
          {options.map((option) => (
            <ChipOption
              type="radio"
              onChange={() => onChange(option.value)}
              key={option.value}
              checked={selectedValue === option.value}
              on="light"
            >
              {option.label}
            </ChipOption>
          ))}
        </InnerDrawer>
      </AsideDrawer>
    </>
  );
};

export default OptionsDrawer;
