import { useEffect, useRef } from 'react';
import { Navigate, Params, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { MaintenanceInfo } from 'defaultConfiguration/getRoutesInMaintenance';
import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { selectIsUserAuthenticated, selectIsUserPartiallyAuthenticated } from 'features/auth/selectors';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { AppLocation, ProtectedRouteState, useAppLocation } from 'hooks/router/useAppLocation';
import { LoginPage } from 'pages';
import { PageNotFound } from 'pages/error';

import { ResolvedFeaturesConfig } from '../../../configuration/features';

import { PublicRoute } from './PublicRoute';

type AuthenticationType = 'default' | 'otp';

const authLevels = {
  partial: 'partial',
  full: 'full'
} as const;

const blacklistedQueryParams = ['email', 'phone', 'name', 'surname', 'companyName', 'registrationCode'];
const companyLevel = ['VERIFIED', 'PROSPECT'] as const;

export type ProtectedRouteProps = {
  outlet: JSX.Element;
  redirectToOriginalUrl?: boolean;
  systems?: MaintenanceInfo['system'][];
  title?: string;
  featureName?: keyof ResolvedFeaturesConfig;
  preserveQueryParams?: ((search: string) => boolean) | boolean;
  authLevel?: keyof typeof authLevels;
  validateOnNavigate?: (params: Readonly<Params<string>>, level: (typeof companyLevel)[number][]) => boolean;
  companyLevel?: (typeof companyLevel)[number][];
};

export default function ProtectedRoute({
  outlet,
  redirectToOriginalUrl,
  systems,
  title,
  preserveQueryParams,
  validateOnNavigate,
  featureName,
  companyLevel = ['VERIFIED'],
  authLevel = 'full'
}: Readonly<ProtectedRouteProps>) {
  const location = useAppLocation();
  const navigate = useNavigate();

  const params = useParams();

  const [searchParams] = useSearchParams();
  const isAuthenticated = useAuthSelector<typeof selectIsUserAuthenticated>(selectIsUserAuthenticated);
  const isPartiallyAuthenticated = useAuthSelector<typeof selectIsUserPartiallyAuthenticated>(
    selectIsUserPartiallyAuthenticated
  );
  const isCompanyVerified = useAppSelector(selectIsActiveCompanyVerified);

  const memoizedLocation = useRef({ ...location, search: window.location.search } as AppLocation);

  const authType = searchParams.get('authType') as AuthenticationType;

  const shouldPreserveQueryParams =
    typeof preserveQueryParams === 'function' ? preserveQueryParams(location.search) : preserveQueryParams;

  const state: ProtectedRouteState = {
    preserveQueryParams: shouldPreserveQueryParams,
    originalLocation: memoizedLocation.current,
    fullyQualifiedLogin: authLevel === 'full',
    redirectToOriginalUrl,
    loginMethod: authType === 'otp' ? 'ghost' : 'regular'
  };

  useEffect(() => {
    if (!isAuthenticated) {
      if (window.Cookiebot?.consent?.stamp !== '0') {
        const originalLocation = memoizedLocation.current || location;

        const urlSearchParams = new URLSearchParams(originalLocation.search);

        blacklistedQueryParams.forEach((param) => {
          urlSearchParams.delete(param);
        });

        const stringifiedSearchParams = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '';

        navigate(shouldPreserveQueryParams ? originalLocation.pathname + stringifiedSearchParams : '', {
          replace: true,
          state
        });
      }
    }
  }, [navigate, isAuthenticated, memoizedLocation.current]);

  const checkValidity = () => {
    if (!isAuthenticated && !isPartiallyAuthenticated) {
      return true;
    }

    const checkOnNavigate = validateOnNavigate?.(params, companyLevel) ?? true;

    if (isCompanyVerified || authLevel === 'partial') {
      return checkOnNavigate;
    }

    return companyLevel.includes('PROSPECT') && checkOnNavigate;
  };

  const isValid = checkValidity();

  if (!isValid) {
    if (!isCompanyVerified && authLevel !== 'partial') {
      return <Navigate to={'/dashboard/verification'} replace />;
    }

    return <PageNotFound />;
  }

  /**
   * show page not found if user authentication and page authentication level do not match
   *
   * e.g. if user is partially authenticated via verification or missing information page logins
   * and tries to access page like dashboard which requires full authentication or vice versa
   * */
  if (
    (isAuthenticated && authLevel === authLevels.partial) ||
    (isPartiallyAuthenticated && authLevel === authLevels.full)
  ) {
    return <PageNotFound />;
  }

  if (isAuthenticated || isPartiallyAuthenticated) {
    return <PublicRoute featureName={featureName} title={title} systems={systems} outlet={outlet} />;
  }

  return <LoginPage otpOnly={location.state?.loginMethod === 'ghost'} />;
}
