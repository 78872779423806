import styled from 'styled-components';

import bin from 'assets/icons/bin.svg';
import { LinkButton } from 'components';
import { Button } from 'components/buttonV2';

type Props = {
  minHeight?: number;
};

export const DrawerContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 1.5rem;
  padding-inline: 1rem;
  overflow: auto;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight / 16}rem` : 'auto')};
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  gap: 1rem;
  position: relative;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const DeleteButton = styled(LinkButton).attrs({ color: 'Red', icons: { left: bin } })`
  margin-bottom: 1rem;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
`;

export const SaveButton = styled(Button).attrs({ size: 'M' })`
  width: 100%;
  margin-top: 0.75rem;
`;

export const DrawerForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
`;
