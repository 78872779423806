import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';

import { ValidationHelpers } from 'hooks/forms/useFormHandlers';

type SubmitHandler = () => unknown;

type FormHandlersState<TInitialValue extends Record<string, unknown> = Record<string, unknown>> = {
  submitHandler?: SubmitHandler;
  validationHelpers?: ValidationHelpers<TInitialValue>;
  getValues?: UseFormGetValues<TInitialValue>;
};

type FormContextProps<TInitialValue extends Record<string, unknown> = Record<string, unknown>> = {
  formHandlers: FormHandlersState<TInitialValue>;
  setFormHandlers: Dispatch<SetStateAction<FormHandlersState<TInitialValue>>>;
};

type Props = {
  children: ReactNode;
};

const FormContext = createContext<FormContextProps>({} as FormContextProps);

export const useFormContext = <TInitialValue extends Record<string, unknown> = Record<string, unknown>>() => {
  return useContext<FormContextProps<TInitialValue>>(
    FormContext as React.Context<FormContextProps<TInitialValue>>
  );
};

export const FormContextProvider: FC<Props> = ({ children }) => {
  const [formHandlers, setFormHandlers] = useState({});
  const context: FormContextProps = { formHandlers, setFormHandlers };

  return <FormContext.Provider value={context}>{children}</FormContext.Provider>;
};

export default FormContext;
