import { useTranslation } from 'react-i18next';

import { useExternalSessionStorage } from 'features/onboarding/hooks';

import StepCard from './stepCard';
import { ExplanationText } from './stepCard.styles';

type Props = {
  step: 'singContractAndReceiveFinancing';
  enabled?: boolean;
  loading?: boolean;
  onInactiveClick?: () => unknown;
};

const ContractSigningStepCard = ({ step, enabled, loading, onInactiveClick }: Props) => {
  const { t } = useTranslation();

  const [bankAccountStarted] = useExternalSessionStorage('setUpBankAccount');

  const status = bankAccountStarted || !window.Cookiebot?.consent?.preferences;

  return (
    <StepCard
      onInactiveClick={onInactiveClick}
      loading={loading}
      disabled={!enabled || !status}
      title={t(step)}
    >
      {<ExplanationText>{t('singContractAndReceiveFinancingExplanation')}</ExplanationText>}
    </StepCard>
  );
};

export default ContractSigningStepCard;
