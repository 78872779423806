import { useEffect } from 'react';

const useOnOpenChangeMutationObserver = (
  target: HTMLElement | null,
  callback: (highestActiveIndex: number, isOpen?: boolean) => unknown
) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.target === target && mutation.attributeName === 'class') {
        const classList = target.classList;

        if (classList.contains('close')) {
          callback(Number(target.getAttribute('data-active-index')), classList.contains('open'));
        }

        if (classList.contains('open')) {
          callback(Number(target.getAttribute('data-active-index')), classList.contains('open'));
        }
      }
    });
  });

  const initializer = () => {
    if (target) {
      observer.observe(target, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  };

  useEffect(initializer, [target]);
};

export default useOnOpenChangeMutationObserver;
