import { ResponseCompleteRefresh } from 'services/LoginService/typings';

export const clearAuthTokens = () => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('sharedToken');
};

export const saveAuthTokens = (authContext: ResponseCompleteRefresh) => {
  if (authContext.accessToken) {
    sessionStorage.setItem('user', authContext.accessToken);
  }

  if (authContext.refreshToken) {
    sessionStorage.setItem('refreshToken', authContext.refreshToken);
  }

  if (authContext.sharedToken) {
    sessionStorage.setItem('sharedToken', authContext.sharedToken);
  }
};

export const getAuthTokens = () => {
  return {
    refreshToken: sessionStorage.getItem('refreshToken') || undefined,
    accessToken: sessionStorage.getItem('user') || undefined
  };
};
