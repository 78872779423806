import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Field, LinkButton, TermsModal } from 'components';
import { useFormContext } from 'features/application/context/FormContext';
import { selectIsPrefilledFromWeb } from 'features/application/selectors';
import { setDataHandlingConsent, setMarketingConsent } from 'features/application/slice';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { Applicant, ApplicationConsent } from 'services/CapService/types';

import { MandatoryTermsAndConditionsWarning, TermsSection } from './contact.style';

export type ApplicationFields = Applicant & ApplicationConsent;

type ModalId = 'directMarketingConsent' | 'dataProcessingConsent' | '';

const ContactsTermsSection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { formHandlers } = useFormContext<ApplicationFields>();

  const { getValues, validationHelpers } = formHandlers;
  const { getFieldState } = validationHelpers ?? {};
  const { isTouched, error } = getFieldState?.('dataHandling') ?? {};

  const [openModalId, setOpenModalId] = useState<ModalId>('');

  const disabled = useApplicationSelector<typeof selectIsPrefilledFromWeb>(selectIsPrefilledFromWeb);

  const onToggleModal = (documentID: ModalId) => () => {
    setOpenModalId(documentID);
  };

  return (
    <>
      <TermsModal
        isOpen={openModalId === 'dataProcessingConsent'}
        termsTextId="dataProcessingConsent"
        onVisibilityChange={() => setOpenModalId('')}
      />
      <TermsModal
        isOpen={openModalId === 'directMarketingConsent'}
        termsTextId="directMarketingConsent"
        onVisibilityChange={() => setOpenModalId('')}
      />
      <TermsSection>
        <Field
          Component={Checkbox}
          name="marketing"
          checked={getValues?.('marketing')}
          value={getValues?.('marketing')}
          validateOnChange
          validationHelpers={validationHelpers}
          disabled={disabled}
          onChangeOverride={(name, value, onChange, setStateValue) => {
            onChange(name)(value);
            setStateValue(value);

            dispatch(setMarketingConsent(value));
          }}
          label={
            <>
              {t('agreementMarketingTextForContact')}
              <LinkButton onClick={onToggleModal('directMarketingConsent')}>{t('readMore')}</LinkButton>
            </>
          }
        />
        <Field
          Component={Checkbox}
          name="dataHandling"
          checked={getValues?.('dataHandling')}
          value={getValues?.('dataHandling')}
          validateOnChange
          validationHelpers={validationHelpers}
          showValidationMessage={false}
          disabled={disabled}
          onChangeOverride={(name, value, onChange, setStateValue) => {
            onChange(name)(value);
            setStateValue(value);

            dispatch(setDataHandlingConsent(value));
          }}
          label={
            <>
              {t('agreementPartnershipTextForContact')}
              <LinkButton onClick={onToggleModal('dataProcessingConsent')}>{t('readMore')}</LinkButton>
            </>
          }
        />
        <MandatoryTermsAndConditionsWarning hidden={!(isTouched && error?.message)}>
          {t('dataHandlingMandatory')}
        </MandatoryTermsAndConditionsWarning>
      </TermsSection>
    </>
  );
};

export default ContactsTermsSection;
