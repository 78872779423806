import { FC, InputHTMLAttributes, ReactNode } from 'react';

import { InputLabel } from '../input/input.style';

import { FakeRadioButton, RadioButtonContainer, HiddenInput } from './radioButton.styles';

type Props = { label?: string | ReactNode; invalid?: boolean } & InputHTMLAttributes<HTMLInputElement>;

export const RadioButton: FC<Props> = ({ checked, ...rest }) => {
  return (
    <RadioButtonContainer invalid={rest.invalid} checked={checked}>
      <HiddenInput checked={!!checked} {...rest} type="radio" readOnly></HiddenInput>
      <FakeRadioButton
        checked={!!checked}
        disabled={rest.disabled}
        data-testid={`radio-option-${rest.value}-${rest.name}`}
      ></FakeRadioButton>
      <InputLabel>{rest.label}</InputLabel>
    </RadioButtonContainer>
  );
};
