import { useEffect, useState } from 'react';

import {
  Checkmark,
  CheckmarkContainer,
  CheckmarkContainerFiller,
  CheckmarkContainerBackground,
  CheckmarkLoaderContainer
} from './loader.styles';

export const CheckmarkCircle = () => {
  const [className, setClassName] = useState('');

  useEffect(() => {
    setTimeout(() => setClassName('draw'), 0);
  }, []);

  return (
    <CheckmarkLoaderContainer>
      <CheckmarkContainer className={className}>
        <CheckmarkContainerBackground />
        <CheckmarkContainerFiller />
        <Checkmark />
      </CheckmarkContainer>
    </CheckmarkLoaderContainer>
  );
};
