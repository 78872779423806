import styled from 'styled-components';

type Props = {
  weight?: 'bold' | 'semibold' | 'medium' | 'normal' | 'light';
};

export const H6 = styled.h6<Props>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
`;

export const H5 = styled.h5<Props>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
`;

export const H4 = styled.h4<Props>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
`;

export const H3 = styled.h3<Props>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin: 0;
`;

export const H2 = styled.h2<Props>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
`;

export const H1 = styled.h1<Props>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
`;

export type SizeProps = {
  size?: 'S' | 'M' | 'L';
};

const getBodyFontSizeMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '0.75rem';
    case 'M':
      return '1rem';
    case 'L':
      return '1rem';
    default:
      return '0.875rem';
  }
};

const getBodyLineHeightMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '1.25rem';
    case 'M':
      return '1.5rem';
    case 'L':
      return '1.5rem';
    default:
      return '1.375rem';
  }
};

const getBodyFontSize = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '0.75rem';
    case 'M':
      return '0.875rem';
    case 'L':
      return '1rem';
    default:
      return '0.875rem';
  }
};

const getBodyLineHeight = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '1.25rem';
    case 'M':
      return '1.375rem';
    case 'L':
      return '1.5rem';
    default:
      return '1.375rem';
  }
};

export const B = styled.span<Props & SizeProps>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: ${({ size }) => getBodyFontSize(size)};
  line-height: ${({ size }) => getBodyLineHeight(size)};

  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: ${({ size }) => getBodyFontSizeMobile(size)};
    line-height: ${({ size }) => getBodyLineHeightMobile(size)};
  }
`;

const getChipsFontSizeMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '0.875rem';
    case 'M':
    case 'L':
    default:
      return '0.875rem';
  }
};

const getChipsLineHeightMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '1rem';
    case 'M':
    case 'L':
    default:
      return '1rem';
  }
};

const getChipsFontSize = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '0.75rem';
    case 'M':
    case 'L':
    default:
      return '0.75rem';
  }
};

const getChipsLineHeight = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '1.125rem';
    case 'M':
    case 'L':
    default:
      return '1.125rem';
  }
};

export const Chips = styled.span<Props & SizeProps>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: ${({ size }) => getChipsFontSize(size)};
  line-height: ${({ size }) => getChipsLineHeight(size)};

  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: ${({ size }) => getChipsFontSizeMobile(size)};
    line-height: ${({ size }) => getChipsLineHeightMobile(size)};
  }
`;

const getInputTextFontSizeMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '0.875rem';
    case 'M':
    case 'L':
    default:
      return '1rem';
  }
};

const getInputTextLineHeightMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '1.375rem';
    case 'M':
    case 'L':
    default:
      return '1.5rem';
  }
};

//TODO expand or remove inheritence based on usage
const getInputTextFontSize = getInputTextFontSizeMobile;
const getInputTextLineHeight = getInputTextLineHeightMobile;

export const InputText = styled.span<Props & SizeProps>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: ${({ size }) => getInputTextFontSize(size)};
  line-height: ${({ size }) => getInputTextLineHeight(size)};

  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: ${({ size }) => getInputTextFontSizeMobile(size)};
    line-height: ${({ size }) => getInputTextLineHeightMobile(size)};
  }
`;

const getInputLabelFontSizeMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '0.75rem';
    case 'M':
    case 'L':
    default:
      return '0.875rem';
  }
};

const getInputLabelLineHeightMobile = (size: SizeProps['size']) => {
  switch (size) {
    case 'S':
      return '1rem';
    case 'M':
    case 'L':
    default:
      return '1.25rem';
  }
};

//TODO expand or remove inheritence based on usage
const getInputLabelFontSize = getInputLabelFontSizeMobile;
const getInputLabelLineHeight = getInputLabelLineHeightMobile;

export const InputLabel = styled.span<Props & SizeProps>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: ${({ size }) => getInputLabelFontSize(size)};
  line-height: ${({ size }) => getInputLabelLineHeight(size)};

  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: ${({ size }) => getInputLabelFontSizeMobile(size)};
    line-height: ${({ size }) => getInputLabelLineHeightMobile(size)};
  }
`;

type ButtonTextSize = 'XS' | SizeProps['size'];

const getButtonFontSizeMobile = (size: ButtonTextSize) => {
  switch (size) {
    case 'XS':
    default:
      return '0.75rem';
  }
};

const getButtonLineHeightMobile = (size: ButtonTextSize) => {
  switch (size) {
    case 'XS':
    default:
      return '1.125rem';
  }
};

//TODO expand or remove inheritence based on usage
const getButtonFontSize = getInputTextFontSizeMobile;
const getButtonLineHeight = getButtonLineHeightMobile;

export const ButtonText = styled.span<Props & SizeProps & { size: ButtonTextSize }>`
  font-weight: ${({ weight, theme }) => theme.fontWeights[weight || 'normal']};
  font-size: ${({ size }) => getButtonFontSize(size)};
  line-height: ${({ size }) => getButtonLineHeight(size)};

  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: ${({ size }) => getButtonFontSizeMobile(size)};
    line-height: ${({ size }) => getButtonLineHeightMobile(size)};
  }
`;
