import { TableColumn } from 'react-data-table-component';
import { TFunction } from 'react-i18next';

import Icons from 'assets/icons';
import {
  SecondaryText,
  RegularText,
  AmountText,
  StatusChip,
  transformDateToLocalizedString,
  Currency,
  InfoPopup,
  DownloadLink
} from 'components';
import { StatusType } from 'design/designVariables';
import {
  HeaderCell,
  CellCollumn,
  AmountCellColumn,
  InlineCell
} from 'features/invoices/tables/invoiceDetails/invoiceDetailsTable.styles';
import { Bill, BillStatus } from 'services/FactoringInvoicesService/types';
import { camelCase } from 'utils';

export type TableRowItem = Bill & { toggleSelected?: never };

type StatusToChipColorMap = Record<BillStatus, keyof StatusType>;

type DownloadHandler = (id?: string) => () => Promise<unknown>;

const statusToChipColorMap: StatusToChipColorMap = {
  UNPAID: 'yellow',
  PAID: 'green',
  OVERDUE: 'red',
  PARTIALLY_PAID: 'blue',
  SOLD: 'gray'
};

export const withNullCheck = (value?: string | number | true) => value || '';

export const renderInvoiceStatusChip = (bill: TableRowItem, t: TFunction<'translation', undefined>) => {
  const isPaid = bill.invoiceStatus === 'PAID';

  if (!isPaid && bill.hasOverdue) {
    return (
      <InlineCell>
        <StatusChip color={statusToChipColorMap.OVERDUE}>
          <RegularText>{t(camelCase(bill.invoiceStatus || ''))}</RegularText>
        </StatusChip>
        <InfoPopup text={t('overdueBill')} />
      </InlineCell>
    );
  }

  return (
    <InlineCell>
      <StatusChip color={statusToChipColorMap[bill.invoiceStatus as BillStatus]}>
        <RegularText>{t(camelCase(bill.invoiceStatus || ''))}</RegularText>
      </StatusChip>
      {!isPaid && bill.hasPartiallyPaid && <InfoPopup text={t('partiallyPayedBill')} />}
    </InlineCell>
  );
};

const createCommonColumns = (
  t: TFunction<'translation', undefined>,
  downloadHandler: DownloadHandler
): TableColumn<TableRowItem>[] => [
  {
    selector: (row) => withNullCheck(row.amountUnpaid?.amount),
    name: <HeaderCell>{t('unpaid')}</HeaderCell>,
    sortable: true,
    id: 'amountUnpaid',
    sortField: 'amountUnpaid',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      const { amountUnpaid, invoiceType } = row;

      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText currency={(amountUnpaid?.currency as Currency) || undefined}>
              {invoiceType === 'CREDIT_MEMO' && amountUnpaid?.amount !== 0
                ? `-${amountUnpaid?.amount}`
                : amountUnpaid?.amount}
            </AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceStatus),
    name: <HeaderCell>{t('status')}</HeaderCell>,
    sortable: true,
    id: 'invoiceStatus',
    sortField: 'invoiceStatus',
    format: (row) => {
      if (row.invoiceStatus) {
        return renderInvoiceStatusChip(row, t);
      }

      return null;
    }
  },
  {
    selector: (row) => withNullCheck(row.id),
    width: '40px',
    format: (row) => {
      return (
        <DownloadLink fileRequest={downloadHandler(row.id)}>
          <Icons.Download />
        </DownloadLink>
      );
    }
  }
];

export const createTableColumns = (
  t: TFunction<'translation', undefined>,
  downloadHandler: DownloadHandler
): TableColumn<TableRowItem>[] => [
  {
    selector: (row) => withNullCheck(row.invoiceNumber),
    name: (
      <HeaderCell>
        {t('number')}/<br></br>
        {t('type')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'invoiceFeeType',
    sortField: 'invoiceFeeType',
    format: (row) => {
      return (
        <CellCollumn>
          <SecondaryText>{row.invoiceNumber}</SecondaryText>
          <RegularText>{t(camelCase(row.invoiceFeeType || ''))}</RegularText>
        </CellCollumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceDate),
    name: (
      <HeaderCell>
        {t('dueDate')}/<br></br>
        {t('placeholderUploadIssueDate')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'invoiceDate',
    sortField: 'invoiceDate',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return (
        <AmountCellColumn>
          <SecondaryText>{transformDateToLocalizedString(row.invoiceDueDate)}</SecondaryText>
          <RegularText>{transformDateToLocalizedString(row.invoiceDate)}</RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceAmount?.amount),
    name: (
      <HeaderCell>
        {t('invoiceAmount')}/<br></br>
        {t('paidAmount')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'invoiceAmount',
    sortField: 'invoiceAmount',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      const { invoiceAmount, amountPaid, invoiceType } = row;

      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText currency={(invoiceAmount?.currency as Currency) || undefined}>
              {invoiceType === 'CREDIT_MEMO' && invoiceAmount?.amount !== 0
                ? `-${invoiceAmount?.amount}`
                : invoiceAmount?.amount}
            </AmountText>
          </RegularText>
          <RegularText>
            <AmountText currency={(amountPaid?.currency as Currency) || undefined}>
              {invoiceType === 'CREDIT_MEMO' && amountPaid?.amount !== 0
                ? `-${amountPaid?.amount}`
                : amountPaid?.amount}
            </AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  ...createCommonColumns(t, downloadHandler)
];

export const createMobileListRows = (
  t: TFunction<'translation', undefined>,
  downloadHandler: DownloadHandler
): TableColumn<TableRowItem>[] => [
  {
    selector: (row) => withNullCheck(row.invoiceNumber),
    name: <HeaderCell>{t('number')}</HeaderCell>,
    id: 'invoiceNumber',
    format: (row) => {
      return <RegularText>{row.invoiceNumber}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceFeeType),
    name: <HeaderCell>{t('type')}</HeaderCell>,
    id: 'invoiceFeeType',
    format: (row) => {
      return <RegularText>{t(camelCase(row.invoiceFeeType || ''))}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceDueDate),
    name: <HeaderCell>{t('dueDate')}</HeaderCell>,
    id: 'invoiceDate',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return <RegularText>{row.invoiceDueDate}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceDate),
    name: <HeaderCell>{t('placeholderUploadIssueDate')}</HeaderCell>,
    id: 'invoiceDate',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return <RegularText>{row.invoiceDate}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceAmount?.amount),
    name: <HeaderCell>{t('invoiceAmount')}</HeaderCell>,
    id: 'invoiceAmount',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      const { invoiceAmount } = row;

      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText currency={(invoiceAmount?.currency as Currency) || undefined}>
              {row.invoiceAmount?.amount}
            </AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.amountPaid?.amount),
    name: <HeaderCell>{t('paidAmount')}</HeaderCell>,
    id: 'invoiceAmount',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      const { amountPaid } = row;

      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText currency={(amountPaid?.currency as Currency) || undefined}>
              {amountPaid?.amount}
            </AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  ...createCommonColumns(t, downloadHandler)
];
