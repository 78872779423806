import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
  FinancialReportResponse,
  FinancialReportRequestPayload,
  InsuranceReportReponse,
  InsuranceReportRequestPayload
} from 'services/InsightsService/types';
import { axiosBaseQuery } from 'services/RestClient/axiosBaseQuery';
import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';

const withBaseUrl = baseUrlBuilder('insights');

const insightsApi = createApi({
  reducerPath: 'insightsApi',
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    requestFinancialReport: build.mutation<FinancialReportResponse, FinancialReportRequestPayload>({
      query: (payload) => ({
        url: withBaseUrl('/insights/finance/'),
        method: 'POST',
        data: payload
      })
    }),
    requestInsuranceReport: build.mutation<InsuranceReportReponse, InsuranceReportRequestPayload>({
      query: (payload) => ({
        url: withBaseUrl('/insights/insurance/'),
        method: 'POST',
        data: payload
      })
    })
  })
});

export const { resetApiState: resetInsightsApiState } = insightsApi.util;

export const { useRequestFinancialReportMutation, useRequestInsuranceReportMutation } = insightsApi;

export default insightsApi;
