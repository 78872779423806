import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';

import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { selectIsRegularUser } from 'features/auth/selectors';
import {
  ExistingCustomerOnboardingStatusRequest,
  ExistingCustomerOnboardingStatusResponse,
  ExistingCustomerOndatoFlowRequest,
  ExistingCustomerOndatoFlowResponse,
  KYCApplicationSettingsUpdateRequest,
  KYCApplicationSettingsUpdateResponse,
  KYCApplicationStartRequest,
  KYCApplicationStartResponse,
  OnboardingAccountFetchRequest,
  OnboardingAccountFetchResponse,
  OnboardingAccountSubmitRequest,
  OnboardingAccountSubmitResponse,
  OnboardingStatusResponse,
  OndatoInitiateRequest,
  OndatoInitiateResponse,
  OndatoStatusRequest,
  OndatoStatusResponse,
  Status,
  extendedOnboardingPaths
} from 'services/OnboardingService/types';
import { axiosBaseQuery } from 'services/RestClient';
import { CustomBaseQueryError } from 'services/RestClient/typings';
import { baseUrlBuilder, typedBaseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { RootState } from 'store';

import { selectIsCompanyOnboardingNeeded } from './selectors';

const withBaseUrl = typedBaseUrlBuilder<extendedOnboardingPaths>('onboarding');

const axiosBaseQueryWithRetry = retry(axiosBaseQuery, { maxRetries: 3 });

type ReturnValue<TResponse> = QueryReturnValue<TResponse, CustomBaseQueryError, Record<string, string>>;

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: axiosBaseQueryWithRetry,
  tagTypes: ['applicationStatus', 'bankAccount', 'existingCustomerKYCStatus'],
  endpoints: (build) => ({
    fetchExistingCustomerOnboardingStatus: build.query<
      ExistingCustomerOnboardingStatusResponse,
      ExistingCustomerOnboardingStatusRequest
    >({
      query: () => ({
        url: withBaseUrl('/v1/onboarded-company/status/'),
        method: 'GET'
      }),
      extraOptions: {
        maxRetries: -1
      },
      providesTags: ['existingCustomerKYCStatus']
    }),
    fetchExistingCustomerOndatoLink: build.mutation<
      ExistingCustomerOndatoFlowResponse,
      ExistingCustomerOndatoFlowRequest
    >({
      query: (payload) => ({
        url: withBaseUrl('/v1/onboarded-company/initiate-ondato'),
        method: 'POST',
        data: { ...payload, cacheKey: undefined }
      }),
      invalidatesTags: ['existingCustomerKYCStatus'],
      extraOptions: {
        maxRetries: -1
      }
    }),
    checkForApplicationStatus: build.query<OndatoStatusResponse, OndatoStatusRequest>({
      queryFn: async (payload, _api, _extraOptions, query) => {
        const get = async <TResponse>(url: string) => query({ url, method: 'GET' }) as ReturnValue<TResponse>;

        try {
          const ondatoInfo = await get<OndatoStatusResponse>(
            withBaseUrl('/v1/ondato/status/{applicationId}', payload)
          );

          if (ondatoInfo.error) {
            throw ondatoInfo.error;
          }

          const ondatoStatus = `ONDATO_${ondatoInfo.data?.ondatoStatus ?? 'INITIALISED'}` as Status;

          if (ondatoInfo.data?.ondatoStatus === 'FINISHED') {
            const onboardingInfo = await get<OnboardingStatusResponse>(
              withBaseUrl('/v1/sme-onboarding/status/{applicationId}', payload)
            );

            if (onboardingInfo.error) {
              throw onboardingInfo.error;
            }

            if (onboardingInfo.data) {
              const onboardingStatus = `SME_CUSTOMER_ONBOARDING_${
                onboardingInfo.data.onboardingStatus ?? 'NOT_FOUND'
              }` as Status;

              return { ...onboardingInfo, data: { ...ondatoInfo.data, status: onboardingStatus } };
            }
          }

          return {
            ...ondatoInfo,
            data: {
              ...ondatoInfo.data,
              status: ondatoStatus
            }
          };
        } catch (error) {
          return { error: error as CustomBaseQueryError };
        }
      },
      providesTags: ['applicationStatus'],
      extraOptions: {
        backoff(attempt) {
          return new Promise((resolve) => {
            setTimeout(resolve, 3000 * attempt);
          });
        }
      }
    }),
    initOnboardingApplication: build.mutation<KYCApplicationStartResponse, KYCApplicationStartRequest>({
      queryFn: async (payload, api, _extraOptions, query) => {
        const post = async <
          TResponse,
          TRequest extends { identificationId?: string; [key: string]: unknown }
        >(
          url: string,
          payload: TRequest
        ) => {
          const data = { ...payload, referalKey: 'smego-web', referralKey: 'smego-web' };

          delete data.identificationId;

          return query({
            url,
            method: 'POST',
            data,
            headers: {
              'X-IdentificationId': payload.identificationId
            }
          }) as ReturnValue<TResponse>;
        };

        try {
          const rootState = api.getState() as RootState;

          const isAuthenticatedAsRegular = selectIsRegularUser(rootState.auth);
          const isActiveCompanyVerified = selectIsActiveCompanyVerified(rootState);

          if (!isAuthenticatedAsRegular) {
            throw new Error('User is not authenticated by trusted method');
          }

          const onboardingNeeded = selectIsCompanyOnboardingNeeded(rootState);

          if (isActiveCompanyVerified && !onboardingNeeded) {
            throw new Error('Company is not in prospect state');
          }

          if (isActiveCompanyVerified && onboardingNeeded) {
            const response = await post<
              KYCApplicationSettingsUpdateResponse,
              KYCApplicationSettingsUpdateRequest
            >(withBaseUrl('/v1/onboarded-company/initiate-onboarding'), payload);

            if (response.error) {
              throw response.error;
            }

            if (response.data) {
              await post<
                { data?: KYCApplicationSettingsUpdateResponse },
                KYCApplicationSettingsUpdateRequest
              >('/onboarding/api/ApplicationSettings', {
                applicationId: response.data?.applicationId?.replaceAll('"', '') as string,
                language: payload.language
              });
            }

            return { data: response.data.applicationId ?? '' };
          }

          const applicationResponse = await post<KYCApplicationStartResponse, KYCApplicationStartRequest>(
            '/onboarding/api/Kyc/Application',
            payload
          );

          if (applicationResponse.error) {
            throw applicationResponse.error;
          }

          if (applicationResponse.data) {
            await post<KYCApplicationSettingsUpdateResponse, KYCApplicationSettingsUpdateRequest>(
              '/onboarding/api/ApplicationSettings',
              {
                applicationId: applicationResponse.data,
                language: payload.language
              }
            );
          }

          return applicationResponse;
        } catch (error) {
          return { error: error as CustomBaseQueryError };
        }
      },
      extraOptions: {
        maxRetries: -1
      },
      invalidatesTags: ['applicationStatus']
    }),
    fetchOndatoLink: build.mutation<OndatoInitiateResponse, OndatoInitiateRequest>({
      query: (payload) => ({
        url: withBaseUrl('/v1/ondato/', {}, { applicationId: payload.applicationId }),
        method: 'POST',
        data: payload.content['application/json']
      }),
      invalidatesTags: ['applicationStatus'],
      extraOptions: {
        maxRetries: -1
      }
    }),
    fetchBankAccountInfo: build.query<OnboardingAccountFetchResponse, OnboardingAccountFetchRequest>({
      query: (payload) => ({
        url: baseUrlBuilder('onboarding')(`/account/${payload.applicationId}`),
        method: 'GET'
      }),
      extraOptions: {
        maxRetries: -1
      },
      providesTags: ['bankAccount']
    }),
    submitBankAccountInfo: build.mutation<OnboardingAccountSubmitResponse, OnboardingAccountSubmitRequest>({
      query: (payload) => ({
        url: baseUrlBuilder('onboarding')('/account/'),
        method: 'POST',
        data: payload
      }),
      extraOptions: {
        maxRetries: -1
      },
      invalidatesTags: ['bankAccount']
    })
  })
});

export const {
  useFetchOndatoLinkMutation,
  useFetchExistingCustomerOndatoLinkMutation,
  useLazyCheckForApplicationStatusQuery,
  useInitOnboardingApplicationMutation,
  useCheckForApplicationStatusQuery,
  useFetchBankAccountInfoQuery,
  useSubmitBankAccountInfoMutation,
  useFetchExistingCustomerOnboardingStatusQuery,
  useLazyFetchExistingCustomerOnboardingStatusQuery
} = onboardingApi;

export const { resetApiState: resetOnboardingApiState, updateQueryData } = onboardingApi.util;
export default onboardingApi;
