import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, ToastLegacy } from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { availableLogonMethodsByCountry } from 'features/auth/consts/availableLogonMethods';
import { selectUserData } from 'features/auth/selectors';
import { setUserData } from 'features/auth/slice';
import { LoginTypes } from 'features/auth/types/authTypes';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

import { LoginMethodButton } from '../loginMethodButton/loginMethodButton';

import { List, LoginMethodsContainer } from './loginMethodsList.styles';

type Props = {
  onSwitch: (tab: LoginTypes) => void;
};

const INITIAL_COUNTRY = 'LT';

const LoginMethodsList: FC<Props> = ({ onSwitch }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const userData = useAuthSelector<typeof selectUserData>(selectUserData);
  const [country, setCountry] = useState(INITIAL_COUNTRY);

  const { isFeatureEnabled } = useRemoteConfigContext();

  const countrySelectOptions = availableLogonMethodsByCountry.map((isoCode) => ({
    value: isoCode.value,
    label: getCountryDisplayName(isoCode.value, i18n.language as Languages) || isoCode.value
  }));

  const selectedCountryLoginMethods = availableLogonMethodsByCountry
    .find((countryLoginMethods) => countryLoginMethods.value === country)
    ?.loginMethods?.filter((loginMethod) => {
      if (loginMethod === 'IDIN') {
        return isFeatureEnabled('idinLogin');
      }

      if (loginMethod === 'FTN') {
        return isFeatureEnabled('ftnLogin');
      }

      if (loginMethod === 'eparakts') {
        return isFeatureEnabled('eparaktsLogin');
      }

      return true;
    });

  const noLoginMethodsFound = !selectedCountryLoginMethods || !selectedCountryLoginMethods?.length;

  const onClickHandler = (tab: LoginTypes) => {
    onSwitch(tab);
  };

  return (
    <LoginMethodsContainer>
      <Dropdown
        onChange={(value) => {
          dispatch(setUserData({ ...userData, country: value }));
          setCountry(value);
        }}
        options={countrySelectOptions}
        value={country}
        placeholder={t('country')}
        required
      />
      {noLoginMethodsFound ? (
        <ToastLegacy isVisible variant="warning" message={t('countryLoginNotSupportedExplanation')} />
      ) : null}
      <List>
        {selectedCountryLoginMethods?.map((type, index) => {
          return <LoginMethodButton method={type} onClick={onClickHandler} key={index} />;
        })}
      </List>
    </LoginMethodsContainer>
  );
};

export default LoginMethodsList;
