import { FC } from 'react';

import { ShimerContainer } from 'components';
import { TotalsCardContainer } from 'components/productTotalsCard';
import { ProductTotalsCard, TooltipLine } from 'components/productTotalsCard/productTotalsCard';

import { useFetchLoansTotals } from '../../hooks';

type Props = {
  loading: boolean;
  hideOnLoading?: boolean;
};

export const CreditLineContractsTotalsCard: FC<Props> = ({ loading, hideOnLoading }) => {
  const {
    productTotals: totals,
    isLoading: totalsLoading,
    isError: isTotalsError,
    hasNoData
  } = useFetchLoansTotals('CREDIT_LINE');

  const isLoading = totalsLoading || loading;

  const { totalAvailable = 0, totalOverdue = 0, totalTotal = 0, totalUsed = 0 } = totals || {};

  const mappedData = {
    totalValue: totalTotal,
    value: totalUsed,
    dangerValue: totalOverdue,
    navigateTo: '/finance/creditline/overview',
    tooltip: [
      { value: totalAvailable, label: 'available' },
      totalOverdue ? { value: totalOverdue, label: 'overdue', type: 'danger' } : null
    ].filter((el) => el) as TooltipLine[]
  };

  const setUsedValueWidth = (value: number) =>
    totalOverdue >= value ? 0 : ((value - totalOverdue) * 100) / totalTotal;

  if ((isTotalsError && !isLoading) || (hasNoData && !isLoading) || (hideOnLoading && isLoading)) {
    return null;
  }

  return (
    <TotalsCardContainer>
      {isLoading ? <ShimerContainer overlay /> : null}
      <ProductTotalsCard
        setUsedLineWidth={setUsedValueWidth}
        {...mappedData}
        productName="creditline"
        valueLineLabel="used"
      />
    </TotalsCardContainer>
  );
};
