import styled from 'styled-components';

import { Typography } from 'components/typography';

import { StatusType } from '../../design/designVariables';

export type Props = {
  color?: keyof StatusType;
};

export const StatusChipContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0.125rem 0.75rem;
  border-radius: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: ${({ color, theme }) => (color ? theme.status[color].bg : theme.status.gray.bg)};
`;

export const StatusChipText = styled(Typography.Body).attrs({ type: 'S' })<Props>`
  color: ${({ color, theme }) => (color ? theme.status[color].text : theme.status.gray.text)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
