import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  AmountText,
  PercentageText,
  RegularText,
  StatusChip,
  transformDateToLocalizedString
} from 'components';
import { StatusType } from 'design/designVariables';
import { selectLoansContractList } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';
import { LoanContractStatus } from 'services/LoansService/types';
import { camelCase } from 'utils';

import { RequestDisbursementButton } from '../requestDisbursementButton/requestDisbursementButton';

import {
  ContractInformation,
  ContractInformationSet,
  ContractInformationCard,
  ContractDetailsSection,
  ContractInformationSection,
  ContractDetailsSectionHeader,
  SectionHeaderText,
  ChipText,
  Label
} from './loansContractDetails.styles';

type StatusToColorMap = Record<LoanContractStatus, keyof StatusType>;

const statusToColorMap: StatusToColorMap = {
  UNKNOWN_STATUS: 'white',
  LOST_RECOVERY: 'red',
  ACTIVE_NOT_DISBURSED: 'yellow',
  ACTIVE: 'green',
  SHORT_FALL: 'red'
};

export const withNullCheck = (value?: number | string) => (value || value === 0 ? value : '-');

export const CreditLineContractDetails = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);

  return (
    <ContractInformationCard data-testid="contract-details">
      <ContractInformationSection
        data-testid="contract-details-information-section"
        colorName={statusToColorMap[selectedContract?.contractStatus || 'UNKNOWN_STATUS']}
      >
        <ContractDetailsSectionHeader>
          <SectionHeaderText>{t('contractInfo')}</SectionHeaderText>
          <StatusChip color="white">
            <ChipText>{t(camelCase(withNullCheck(selectedContract?.contractStatus) as string))}</ChipText>
          </StatusChip>
        </ContractDetailsSectionHeader>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('type')}</Label>
            <RegularText>
              {t(camelCase(withNullCheck(selectedContract?.loanType) as string, ' '))}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('financier')}</Label>
            <RegularText>{withNullCheck(selectedContract?.financierShortName)}</RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('usedAmount')}</Label>
            <RegularText>
              <AmountText>{withNullCheck(selectedContract?.usedAmount)}</AmountText>
            </RegularText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('totalAmount')}</Label>
            <RegularText>
              <AmountText>{withNullCheck(selectedContract?.loanAmount)}</AmountText>
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('validFrom')}</Label>
            <RegularText>
              {withNullCheck(transformDateToLocalizedString(selectedContract?.validFromDate))}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('validTo')}</Label>
            <RegularText>
              {withNullCheck(transformDateToLocalizedString(selectedContract?.validToDate))}
            </RegularText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <RequestDisbursementButton />
          </ContractInformation>
        </ContractInformationSet>
      </ContractInformationSection>
      <ContractDetailsSection>
        <ContractDetailsSectionHeader>
          <SectionHeaderText>{t('contractDetails')}</SectionHeaderText>
        </ContractDetailsSectionHeader>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('interestType')}</Label>
            <RegularText>
              {t(withNullCheck(camelCase(selectedContract?.interestType || '')) as string)}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('interest')}</Label>
            <PercentageText>{withNullCheck(selectedContract?.interestRate)}</PercentageText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('compensatoryInterest')}</Label>
            <PercentageText>{withNullCheck(selectedContract?.penalties)}</PercentageText>
          </ContractInformation>
        </ContractInformationSet>
        {selectedContract?.interestType === 'Variable' && (
          <ContractInformationSet>
            <ContractInformation>
              <Label>{t('variableInterest')}</Label>
              <PercentageText>{withNullCheck(selectedContract?.variableInterest)}</PercentageText>
            </ContractInformation>
            <ContractInformation>
              <Label>{t('totalInterest')}</Label>
              <PercentageText>{withNullCheck(selectedContract?.totalInterest)}</PercentageText>
            </ContractInformation>
            <ContractInformation>
              <Label>{t('interestChangeDate')}</Label>
              <RegularText>
                {withNullCheck(transformDateToLocalizedString(selectedContract?.interestChangeDate))}
              </RegularText>
            </ContractInformation>
          </ContractInformationSet>
        )}
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('repaymentType')}</Label>
            <RegularText>
              {t(withNullCheck(camelCase(selectedContract?.repaymentType || '', ' ')) as string)}
            </RegularText>
          </ContractInformation>

          {(camelCase(selectedContract?.repaymentType || '', ' ') as string) === 'periodic' && (
            <ContractInformation>
              <Label>{t('period')}</Label>
              <RegularText>{`${withNullCheck(selectedContract?.repaymentPeriod)} ${t(
                'pluralDays'
              )}`}</RegularText>
            </ContractInformation>
          )}
        </ContractInformationSet>
      </ContractDetailsSection>
    </ContractInformationCard>
  );
};
