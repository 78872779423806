import { FC } from 'react';

import { useNavContext } from 'features/nav/context/navContext';
import { ApplicationItem } from 'services/CapService/types';

import ApplicationCard, { DrawerInfo } from '../applicationCard/applicationCard';

type Props = {
  applications: ApplicationItem[];
};

const ApplicationsList: FC<Props> = ({ applications }) => {
  const { toggleRightSideDrawer, activeDrawer, showRightSideDrawer } = useNavContext<DrawerInfo>();

  const handleClose = () => toggleRightSideDrawer?.();

  return (
    <>
      {applications?.map((application) => {
        return application ? (
          <ApplicationCard
            onCardClick={toggleRightSideDrawer}
            closeDrawer={() => handleClose()}
            key={application.capId ?? application.applicationId}
            id={application.capId}
            applicationId={application.applicationId}
            product={application.type}
            applicationNumber={application.applicationNumber}
            amount={application.requestedAmount}
            isActive={
              showRightSideDrawer &&
              ((activeDrawer?.id && activeDrawer?.id === application.capId) ||
                activeDrawer?.applicationId === application.applicationId)
            }
            drawerId={activeDrawer?.id ?? activeDrawer?.applicationId}
          />
        ) : null;
      })}
    </>
  );
};

export default ApplicationsList;
