import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, LoaderContainer } from 'components';
import { selectActiveCompany, selectVerificationApplication } from 'features/applications/selectors';
import { Status } from 'features/applications/types/applicationsTypes';
import { selectSessiondId, selectActiveCustomer, selectUserData } from 'features/auth/selectors';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { OndatoStatusResponse } from 'services/OnboardingService/types';
import { getLanguageCode } from 'translations/utils';

import { useCheckForApplicationStatusQuery } from '../../api';
import { useGetOnboardingEnabledConfig } from '../../hooks';
import { isReadyForOndato } from '../../hooks/useCurrentFlowStep';
import { redirectToOnboarding } from '../../hooks/useOnboardingRedirectHandler';

import { LobbbyContentContainer, LobbyHeaderText, LobbyText, LobbyTextContainer } from './lobbyScreen.styles';

export type Props = {
  screen: 'ondato' | 'onboarding' | '';
};

const OnboardingApplicationInitLoader = () => {
  const { t, i18n } = useTranslation();

  const { ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const application = useAppSelector(selectVerificationApplication);

  const applicationStatus = application?.status as Status;
  const resolvedId = application?.applicationId ?? '';

  const languageCode = getLanguageCode(i18n.language);

  const activeCompany = useAppSelector(selectActiveCompany);

  const sessionId = useAuthSelector<typeof selectSessiondId>(selectSessiondId);
  const customer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const notReadyForOndato = !resolvedId || !isReadyForOndato(applicationStatus, ondatoAvailableCAPStatus);

  const { data: ondatoInfo = {} as OndatoStatusResponse } = useCheckForApplicationStatusQuery(
    { applicationId: resolvedId },
    {
      skip: notReadyForOndato
    }
  );

  useEffect(() => {
    if (ondatoInfo.status === 'SME_CUSTOMER_ONBOARDING_IN_PROGRESS') {
      const redirectParams = {
        ondatoInfo,
        activeCompany,
        customerId: customer?.customerId,
        email: userData?.email,
        languageCode,
        sessionId
      };

      redirectToOnboarding(redirectParams);
    }
  }, [ondatoInfo.status]);

  return (
    <LobbbyContentContainer>
      <LoaderContainer>
        <Loader transparent opaque={false} noMargin />
      </LoaderContainer>
      <LobbyTextContainer>
        <LobbyHeaderText>{t('creatingQuestionnaireForYourBusiness')}</LobbyHeaderText>
        <LobbyText>{t('thisMightTakeUpToSeconds', { seconds: 30 })}</LobbyText>
      </LobbyTextContainer>
    </LobbbyContentContainer>
  );
};

const LobbyScreen = ({ screen }: Props) => {
  switch (screen) {
    case 'onboarding':
      return <OnboardingApplicationInitLoader />;
    case 'ondato':
    default:
      return <Loader transparent opaque={false} noMargin />;
  }
};

export default LobbyScreen;
