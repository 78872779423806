type Link = {
  to: {
    en: string;
    lt: string;
  };
  toExternal?: {
    en: string;
    lt: string;
  };
};

export type Products = 'Leasing' | 'CreditLine' | 'Factoring' | 'Loans' | 'RBF' | 'All';

type Links = Record<Products, Link>;

const smeFinanceDomain = VARIABLES.openPagesFinanceDomainUrl;

export const oldNavigationLinks: Links = {
  Leasing: {
    to: {
      en: `//${smeFinanceDomain}/en/leasing/`,
      lt: `//${smeFinanceDomain}/lizingas/#lizingo-paraiska`
    },
    toExternal: {
      en: `//${smeFinanceDomain}/en/leasing/`,
      lt: `//${smeFinanceDomain}/lizingas/#lizingo-paraiska`
    }
  },
  CreditLine: {
    to: {
      en: '/new-application?product=creditline',
      lt: '/new-application?product=creditline'
    },
    toExternal: {
      en: `//${smeFinanceDomain}/en/credit-line/`,
      lt: `//${smeFinanceDomain}/kredito-linija/`
    }
  },
  All: {
    to: {
      en: '/new-application',
      lt: '/new-application'
    },
    toExternal: {
      en: `//${smeFinanceDomain}/en/`,
      lt: `//${smeFinanceDomain}/`
    }
  },
  Factoring: {
    to: {
      en: '/new-application?product=factoring',
      lt: '/new-application?product=factoring'
    },
    toExternal: {
      en: `//${smeFinanceDomain}/en/factoring/`,
      lt: `//${smeFinanceDomain}/faktoringas/`
    }
  },
  Loans: {
    to: {
      en: '/new-application?product=loans',
      lt: '/new-application?product=loans'
    },
    toExternal: {
      en: `//${smeFinanceDomain}/en/business-loans/`,
      lt: `//${smeFinanceDomain}/verslo-paskolos/`
    }
  },
  RBF: {
    to: {
      en: `//${smeFinanceDomain}/en/smart-loan/`,
      lt: `//${smeFinanceDomain}/ismanioji-paskola/`
    },
    toExternal: {
      en: `//${smeFinanceDomain}/en/smart-loan/`,
      lt: `//${smeFinanceDomain}/ismanioji-paskola/`
    }
  }
};

export const navigationLinks: Links = {
  Leasing: {
    to: {
      en: `/new-application?product=leasing`,
      lt: `/new-application?product=leasing`
    }
  },
  CreditLine: {
    to: {
      en: '/new-application?product=creditline',
      lt: '/new-application?product=creditline'
    }
  },
  All: {
    to: {
      en: '/new-application',
      lt: '/new-application'
    }
  },
  Factoring: {
    to: {
      en: '/new-application?product=factoring',
      lt: '/new-application?product=factoring'
    }
  },
  Loans: {
    to: {
      en: '/new-application?product=loans',
      lt: '/new-application?product=loans'
    }
  },
  RBF: {
    to: {
      en: `/new-application?product=rbf`,
      lt: `/new-application?product=rbf`
    }
  }
};
