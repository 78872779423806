import { FC } from 'react';

import { Button } from 'components/buttonV2';

import { Paper } from '../paper';

import { Image, TextWrapper, Text } from './dialogCard.styles';

type Props = {
  image: string;
  label: string;
  text?: string;
  buttonLabel?: string;
  onClick?: () => unknown;
  maxWidth?: string;
};

export const DialogCard: FC<Props> = ({ image, label, text, buttonLabel, onClick }) => {
  const showButton = buttonLabel && onClick;

  return (
    <Paper data-testid={`paper-${label}`}>
      <Image src={image} />
      <TextWrapper>
        <Text primary>{label}</Text>
        {text && <Text>{text}</Text>}
      </TextWrapper>
      {showButton && (
        <Button variant="Filled" color="Blue" data-testid="button-continue" onClick={onClick}>
          {buttonLabel}
        </Button>
      )}
    </Paper>
  );
};
