import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import eParaksts from 'assets/pictograms/eParaksts.svg';
import ftn from 'assets/pictograms/ftn.svg';
import idin from 'assets/pictograms/idin.svg';
import mobileId from 'assets/pictograms/mobileId.svg';
import smartId from 'assets/pictograms/smartId.svg';
import { LoginTypes } from 'features/auth/types/authTypes';

import { Button, LoginLabelContainer, LoginMethodName, LoginPoweredByText } from './loginMethodButton.styles';

type Props = {
  method: LoginTypes;
  onClick: (method: LoginTypes) => unknown;
};

type Pictograms = Record<Props['method'], string>;

export const pictograms: Pictograms = {
  eparakts: eParaksts,
  smartId: smartId,
  FTN: ftn,
  IDIN: idin,
  mobilId: mobileId
};

const sublabel: Record<Props['method'], string> = {
  eparakts: 'poweredByEIDEasy',
  smartId: '',
  FTN: '',
  IDIN: '',
  mobilId: ''
};

export const LoginMethodButton: FC<Props> = ({ method, onClick }) => {
  const imgSrc = pictograms[method];

  const { t } = useTranslation();

  return (
    <Button onClick={() => onClick(method)}>
      {imgSrc ? <img src={imgSrc} alt={t(method)} /> : null}
      <LoginLabelContainer>
        <LoginMethodName> {t(method)}</LoginMethodName>
        {sublabel[method] ? <LoginPoweredByText>{t(sublabel[method])}</LoginPoweredByText> : null}
      </LoginLabelContainer>
    </Button>
  );
};
