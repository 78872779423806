
import { RestClient } from '../RestClient';
import { baseUrlBuilder } from '../utils/baseUrlBuilder';


const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('cap');

export class ApplicationService {
  static getRequiredDocumentTemplate(documentType: string) {
    const url = withBaseUrl(`/templates/${documentType}`);

    return restClient.DOWNLOAD(url);
  }

  static getOfferById(applicationId: string, offerId: string, filename: string) {
    const url = withBaseUrl(`/offers/${offerId}?applicationId=${applicationId}&filename=${filename}`);

    return restClient.DOWNLOAD(url);
  }
}
