import {
  InvoicesTotalsGetResponse,
  SubmittedInvoicesByContractsResponse,
  SummaryDataGetResponse,
  SupportedDocumentsGetResponse,
  UploadInvoices,
  VerifiersContact,
  VerifiersGetResponse
} from 'services/InvoicesService/types';

export enum UPLOAD_STEPS {
  CONTRACTS = 'contracts',
  INVOICES = 'invoices',
  SUPPORTING_DOCUMENTS = 'supportingDocuments',
  VERIFICATION = 'verification',
  REVIEW = 'review'
}

export type InvoicesState = {
  isLoading: boolean;
  isTotalsLoading: boolean;
  isInvoicesLoadFailed: boolean;
  invoicesFullReport: SubmittedInvoicesByContractsResponse;
  uploadedInvoices: UploadInvoices;
  uploadedInvoicesContacts: VerifiersContact[];
  activeStep: UPLOAD_STEPS;
  completionIndex: number;
  supportDocuments: SupportedDocumentsGetResponse;
  totalsForContract: InvoicesTotalsGetResponse;
  summaryData: SummaryDataGetResponse;
  verifiers: VerifiersGetResponse;
  isVerifiersLoading: boolean;
  isVerifiersLoadFailed: boolean;
  isUploadingInvoices: boolean;
};

export type UpcommingPaymentItem = {
  id?: string;
  type?: string;
  number?: string;
  dueDate?: string;
  unpaid?: number;
  status?: string;
  [key: string]: unknown;
};
