import {
  ShimmerButton,
  ShimmerHeader,
  ShimmerIcon,
  ShimmerText,
  SkeletonCardStyle
} from './skeletonCard.styles';
import { CardContent, CardContentContainer } from './updateCompanyProfileCard.styles';

const SkeletonCard = () => {
  return (
    <SkeletonCardStyle>
      <CardContentContainer>
        <ShimmerIcon />
        <CardContent>
          <ShimmerHeader />
          <ShimmerText />
          <ShimmerText />
        </CardContent>
      </CardContentContainer>
      <ShimmerButton />
    </SkeletonCardStyle>
  );
};

export default SkeletonCard;
