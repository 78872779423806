import styled from 'styled-components';

import { Paper, ShimerContainer, Typography } from 'components';
import { StepToast } from 'features/onboarding/components/stepCard/stepCard.styles';

type CardProps = {
  isActive?: boolean;
};

export const Amount = styled(Typography.Header).attrs({ type: 'h2', weight: 'medium' })`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.base.black.tints[3]};
  border-radius: 0.875rem;
  width: 100%;
`;

export const Card = styled(Paper)<CardProps>`
  display: flex;
  width: 100%;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  box-shadow: none;
  border: 0.0625rem solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.base.primary.shades[100] : theme.colors.base.white.functional};

  &:hover {
    box-shadow: 0 0.625rem 1.875rem rgba(4.75, 3.76, 53.12, 5%);
  }

  button {
    width: 100%;
  }
`;

export const ProductName = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })`
  @media ${({ theme }) => theme.media.maxPhone} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

export const ApplicationNumber = styled(Typography.Body).attrs({ type: 'M', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.tints[70]};

  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.base.primary.tints[5]};
  width: 3rem;
  height: 3rem;
  border-radius: 100%;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.base.primary.shades[100]};
    }
  }
`;

export const HeaderLeftColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

export const HeaderIconAndText = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const HeaderTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-block: 0.125rem;
  gap: 0.125rem;
`;

export const HeaderTextContentSkeleton = styled(HeaderTextContent)`
  gap: 0.375rem;
`;

export const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  align-items: flex-start;
  height: 100%;
  align-self: flex-start;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  width: 100%;
  gap: 0.5rem;

  button {
    width: 100%;
  }
`;

export const Shimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })``;

export const ApplicationCardToast = styled(StepToast)`
  margin-bottom: ${({ isVisible }) => (isVisible ? '0.5rem' : '-1rem')};
`;
