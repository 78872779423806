import { FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { GenericOption } from 'components/dropdown/Label';

import { DropdownDrawer } from '../dropdown';

import Input, { InputProps } from './input';
import { AsyncSearchInputContainer, DropdownAnchor } from './input.style';

function debounce(func: (searchQuery: string) => Promise<unknown>, wait: number) {
  let timeout: number;

  return function wrappedFunction(searchQuery: string) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      if (timeout) {
        clearTimeout(timeout);
      }

      func(searchQuery);
    }, wait) as unknown as number;
  };
}

type Props = {
  name: string;
  value?: string;
  placeholder?: string;
  asyncFetchResults: (searchQuery: string) => Promise<unknown>;
  onResultsAvailable: (results: unknown) => GenericOption[];
  onResultSelect: (result: GenericOption) => unknown;
  disableSearch?: boolean;
  addQueryAsOption?: boolean;
  searchDelay?: number;
} & InputProps;

const AsyncSearchInput = ({
  name,
  value,
  onResultSelect,
  asyncFetchResults,
  onBlur,
  onResultsAvailable,
  onChange,
  disableSearch,
  size,
  addQueryAsOption,
  searchDelay = 200,
  ...rest
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchResults, setSearchResults] = useState<GenericOption[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [boundings, setBoundings] = useState<DOMRect | null>(null);

  const handleSearch = useRef(
    debounce(async (searchQuery: string) => {
      if (searchQuery) {
        const results = await asyncFetchResults(searchQuery);

        let mappedResults = onResultsAvailable(results);

        if (addQueryAsOption && mappedResults?.length) {
          mappedResults = [{ label: searchQuery, value: '' }, ...mappedResults];
        }

        setSearchResults(mappedResults);
      }

      setIsDropdownVisible(searchQuery !== '');
    }, searchDelay)
  ).current;

  const handleChange = (searchQuery: string) => {
    onChange?.(searchQuery);

    if (!disableSearch) {
      handleSearch(searchQuery);
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setTimeout(() => {
      setIsDropdownVisible(false);
      onBlur?.(e);
    }, 200);
  };

  const handleFocus = () => {
    setIsDropdownVisible(true);
  };

  const handleSelect = (option: GenericOption) => {
    onResultSelect(option);
    setIsDropdownVisible(false);
  };

  const alignPosition = useCallback(() => {
    requestAnimationFrame(() => {
      if (containerRef.current) {
        setBoundings(containerRef.current.getBoundingClientRect());
      }
    });
  }, [containerRef.current]);

  useEffect(() => {
    if (!boundings) {
      alignPosition();
    }

    window.addEventListener('scroll', alignPosition);
    window.addEventListener('resize', alignPosition);

    return () => {
      window.removeEventListener('scroll', alignPosition);
      window.removeEventListener('resize', alignPosition);
    };
  }, [containerRef.current]);

  const selectedOption = searchResults.find((option) => option.value === value || option.label === value);

  return (
    <>
      <AsyncSearchInputContainer ref={containerRef} data-testid="async-search-input">
        <Input
          {...rest}
          value={value}
          onChange={handleChange}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          size={size}
        />
      </AsyncSearchInputContainer>
      {containerRef.current
        ? createPortal(
            <DropdownAnchor
              style={{
                position: 'fixed',
                top: (boundings?.top ?? 0) + (size === 'L' ? 60 : 52),
                left: boundings?.left,
                width: boundings?.width
              }}
            >
              <DropdownDrawer
                onSelect={handleSelect}
                visible={isDropdownVisible}
                absolute
                name={name}
                options={searchResults}
                value={selectedOption?.value ?? value}
              />
            </DropdownAnchor>,
            document.body,
            'async-search-input-dropdown'
          )
        : null}
    </>
  );
};

export default AsyncSearchInput;
