import { useTranslation } from 'react-i18next';

import { formatPercentage } from 'components';
import { AsideDrawer, AsideMenuTopBar } from 'components/pageV2';
import Typography from 'components/typography/typography';
import { selectApplicationData } from 'features/application/selectors';
import { useApplicationSelector } from 'hooks/redux/hooks';

import { OfferDrawerHeader, Title } from '../offerStatusModal/offerStatusModal.styles';

import {
  InnerContent,
  ProcessSection,
  PromotionSection,
  SectionHeader
} from './openBankExplanationDrawer.styles';

export type OpenBankExplanationDrawerParams = {
  open?: boolean;
  onClose: () => unknown;
  inner?: boolean;
};

const OpenBankExplanationDrawer = ({ open, onClose, inner }: OpenBankExplanationDrawerParams) => {
  const { t, i18n } = useTranslation();

  const applicationDetails = useApplicationSelector<typeof selectApplicationData>(selectApplicationData);

  const { loansAdditionalInfo } = applicationDetails ?? {};

  const percentage = formatPercentage(i18n.language);

  const anualTurnoverPercentage = percentage(loansAdditionalInfo?.commercialTurnover, 0);

  return (
    <AsideDrawer activeIndex={1} isOpen={open} position="fixed" portal={false}>
      <AsideMenuTopBar
        handleClose={onClose}
        inner={inner}
        topbarContent={
          <OfferDrawerHeader>
            <Title>{t('whatDoesItMean')}</Title>
          </OfferDrawerHeader>
        }
      />
      <InnerContent>
        <SectionHeader>
          <Typography.Body weight="medium">{t('whatAnualTurnoverMeans')}</Typography.Body>
        </SectionHeader>
        {t('anualTurnoverExplanation', { percentage: anualTurnoverPercentage })}
        <PromotionSection>
          <Typography.Body weight="medium">{t('openAccountIsEasy')}</Typography.Body>
        </PromotionSection>
        <ProcessSection>
          <SectionHeader>
            <Typography.Body weight="medium">{t('openBankAccountProcessHeader')}</Typography.Body>
          </SectionHeader>
          <Typography.Body>{t('openBankAccountProcess')}</Typography.Body>
        </ProcessSection>
        <Typography.Body>{t('openBankAccountNextSteps')}</Typography.Body>
      </InnerContent>
    </AsideDrawer>
  );
};

export default OpenBankExplanationDrawer;
