import { FC, useEffect, useState } from 'react';

import { Loader } from 'components';
import { selectUserData } from 'features/auth/selectors';
import { SupportedCountries } from 'features/auth/types/authTypes';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { LoginLoaderContainer } from 'pages/login/user/components/loginContainer/loginContainer.styles';
import { RequestInitEIDeasy } from 'services/LoginService/typings';

import { initEIDeasyAsync } from '../../slice';

const getLoginMethod = (country: SupportedCountries) => {
  switch (country) {
    case 'NL':
      return 'idin-login';
    case 'FI':
      return 'ftn-login';
    case 'LV':
      return 'lv-eparaksts-mobile-login';
    default:
      return '';
  }
};

export const EIDeasy: FC = () => {
  const dispatch = useAppDispatch();
  const [, setIsRedirecting] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');

  const { email, country } = useAuthSelector<typeof selectUserData>(selectUserData);

  const loginMethod = getLoginMethod(country as SupportedCountries);

  const initiateLogin = async () => {
    try {
      const request: RequestInitEIDeasy = {
        email: email,
        lang: 'EN',
        country: country,
        loginMethod: loginMethod
      };

      setIsRedirecting(true);

      const result = await dispatch(initEIDeasyAsync(request)).unwrap();

      setRedirectUrl(result.authorizeUrl);
    } catch {
      setIsRedirecting(false);
      setRedirectUrl('');
    }
  };

  useEffect(() => {
    initiateLogin();
  }, []);

  return (
    <>
      <LoginLoaderContainer>
        <Loader transparent noDelay />
      </LoginLoaderContainer>
      {redirectUrl ? <meta http-equiv="Refresh" content={`0; url='${redirectUrl}'`} /> : null}
    </>
  );
};
