import {
  ClipboardEventHandler,
  FC,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { UserData } from 'features/auth/types/authTypes';

import { LinkButton } from '../link';

import {
  FormHeading,
  FormSubheading,
  Input,
  InputsContainer,
  OTPErrorMessage,
  TimerText,
  OTPBottomContainer,
  OTPForm,
  OTPTimerLabel
} from './otpCodeDialog.styles';

type Props = {
  onSubmit: (code: string) => unknown;
  onRefresh: () => unknown;
  onSuccess: (response: unknown) => unknown;
  wrongOtpWarningLabel: string;
  wrongOtpWarningExceededRetriesLabel: string;
  resendOtpLinkLabel: string;
  notReceivedLabel?: string;
  resendOneTimePasswordLable?: string;
  codeLength?: number;
  userData?: UserData;
  enterOtpHeaderLabel?: string;
  otpHeaderLabel?: string;
  allowedAttemptsCount?: number;
  waitSecondsBeforeRefresh?: number;
  leftAlign?: boolean;
  borderless?: boolean;
};

const getTime = (counterValue: number) => {
  const minutes = Math.floor(counterValue / 60);
  const seconds = counterValue - minutes * 60;

  return `${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
};

const getSortedMapByKeyValueArray = (map: Map<string, string>) => {
  const entries = map.entries();

  return Array.from(entries)
    .sort(([id], [nextId]) => id.localeCompare(nextId))
    .map(([, value]) => value);
};

export const OtpCodeDialog: FC<Props> = ({
  onSubmit,
  onRefresh,
  onSuccess,
  resendOtpLinkLabel,
  enterOtpHeaderLabel,
  otpHeaderLabel,
  notReceivedLabel,
  wrongOtpWarningLabel,
  resendOneTimePasswordLable,
  wrongOtpWarningExceededRetriesLabel,
  leftAlign,
  borderless,
  codeLength = 4,
  allowedAttemptsCount = 3,
  waitSecondsBeforeRefresh = 60
}) => {
  const firstInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const codeNumbersRef = useRef(new Map());
  const timerRef = useRef(0);

  const codeNumbers = codeNumbersRef.current;
  const codeNumbersSize = codeNumbers.size;

  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(waitSecondsBeforeRefresh);
  const [code, setCode] = useState<string[]>();
  const [error, setError] = useState(false);
  const [attemptsCount, setAttemptsCount] = useState(allowedAttemptsCount);
  const [refreshDisabled, setRefreshDisabled] = useState(true);

  const filledCodeLength = code?.filter((el) => el).length;

  const resetCode = () => {
    new Array<string>(codeLength)
      .fill('')
      .forEach((value, index) => codeNumbers.set(`input-${index}`, value));

    setCode(getSortedMapByKeyValueArray(codeNumbers));
  };

  const submitHandler = async (code: string[]) => {
    try {
      setError(false);
      const response = await onSubmit(code.join(''));

      if (response) {
        onSuccess(response);
      }
    } catch {
      setError(true);
      setAttemptsCount((previousAttemptsCount) => previousAttemptsCount - 1);
      resetCode();

      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }
  };

  const refreshHandler = () => {
    onRefresh();
    setError(false);
    setAttemptsCount(allowedAttemptsCount);
    setRefreshDisabled(true);
    resetCode();
  };

  const onChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
    const { id, value, nextElementSibling } = currentTarget;

    if (value || value === '0') {
      if (nextElementSibling) {
        (nextElementSibling as HTMLInputElement).focus();
      }
    }

    codeNumbers.set(id, value[value.length - 1] || '');
    setCode(getSortedMapByKeyValueArray(codeNumbers));
  };

  const onKeyPress = ({ currentTarget, nativeEvent }: SyntheticEvent<HTMLInputElement, KeyboardEvent>) => {
    const { code } = nativeEvent;
    const { value, previousElementSibling } = currentTarget;

    if (code === 'Backspace') {
      if (!value) {
        if (previousElementSibling) {
          (previousElementSibling as HTMLInputElement).focus();
        }
      }
    }
  };

  const onPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const pastedData = e.clipboardData?.getData('text/plain') || '';

    codeNumbers.forEach((_, key) => {
      const [, index] = key.split('-');

      codeNumbers.set(key, pastedData[index]);
    });

    setCode(getSortedMapByKeyValueArray(codeNumbers));
  };

  useEffect(() => {
    if (codeNumbersSize < codeLength) {
      resetCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeLength, codeNumbersSize, codeNumbers]);

  useEffect(() => {
    if (code && filledCodeLength === codeLength) {
      submitHandler(code);
    }
  }, [code, codeLength, filledCodeLength]);

  useEffect(() => {
    if (!attemptsCount) {
      setRefreshDisabled(false);

      if (timerRef.current) {
        setTimeLeft(waitSecondsBeforeRefresh);
        clearInterval(timerRef.current);
        timerRef.current = 0;
      }
    }
  }, [attemptsCount, waitSecondsBeforeRefresh]);

  useEffect(() => {
    if (!timerRef.current && refreshDisabled) {
      if (timeLeft === waitSecondsBeforeRefresh) {
        timerRef.current = setInterval(() => {
          setTimeLeft((previousTimeLeft) => previousTimeLeft - 1);
        }, 1000) as unknown as number;
      }
    }

    if (!timeLeft) {
      if (timerRef.current) {
        setRefreshDisabled(false);
        setTimeLeft(waitSecondsBeforeRefresh);
        clearInterval(timerRef.current);
        timerRef.current = 0;
      }
    }
  }, [refreshDisabled, timeLeft, waitSecondsBeforeRefresh]);

  return (
    <OTPForm autoComplete="off" data-testid="otp-dialog">
      {otpHeaderLabel ? <FormHeading leftAlign={leftAlign}>{otpHeaderLabel}</FormHeading> : null}
      {enterOtpHeaderLabel ? (
        <FormSubheading leftAlign={leftAlign}>{enterOtpHeaderLabel}</FormSubheading>
      ) : null}
      <InputsContainer data-testid="otp-inputs-container">
        {code?.map((value, index) => {
          const id = `input-${index}`;

          return (
            <Input
              autoFocus={index === 0}
              borderless={borderless}
              ref={index === 0 ? firstInputRef : undefined}
              value={value}
              key={index}
              data-testid={`otp-input-number-${index}`}
              id={id}
              type="number"
              onKeyDown={onKeyPress}
              onChange={onChange}
              onPaste={onPaste}
            />
          );
        })}
        <OTPErrorMessage hidden={!error}>
          {t(attemptsCount > 0 ? wrongOtpWarningLabel : wrongOtpWarningExceededRetriesLabel, {
            attemptsLeft: attemptsCount
          })}
        </OTPErrorMessage>
      </InputsContainer>

      <OTPBottomContainer>
        {notReceivedLabel ? <OTPTimerLabel>{notReceivedLabel}</OTPTimerLabel> : null}
        <OTPTimerLabel data-testid="refresh-timer-explanation">
          {resendOtpLinkLabel} <TimerText>{`${getTime(refreshDisabled ? timeLeft : 0)}`}</TimerText>
        </OTPTimerLabel>
        <LinkButton disabled={refreshDisabled} onClick={refreshHandler} data-testid="refresh-link">
          {resendOneTimePasswordLable}
        </LinkButton>
      </OTPBottomContainer>
    </OTPForm>
  );
};
