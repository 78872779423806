import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';

import { FormContainer } from 'features/application/components/formContainer';
import FormContext from 'features/application/context/FormContext';
import { selectFactoringType } from 'features/application/selectors';
import { setFactoringAdditionalInfo, submitApplicationAsync } from 'features/application/slice';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { FactoringAdditionalInfo } from 'services/CapService/types';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { PARTY_TYPE_VALUE } from '../types/formTypes';
import utils from '../utils/objectHelpers';

import { FactoringTypeFields } from './factoringTypeFields';

const initialFactoringData: FactoringAdditionalInfo = {
  partyType: PARTY_TYPE_VALUE.BUYERS
};

const validateIfPartyTypeIsBuyer = Yup.boolean().when('partyType', (partyType: PARTY_TYPE_VALUE) => {
  if (partyType === PARTY_TYPE_VALUE.BUYERS) {
    return Yup.boolean().required(VALIDATION_MESSAGE_ID.REQUIRED);
  }
  return Yup.boolean().optional();
});

const validateAgreeToPayInsuranceLimitFee = Yup.boolean().when(
  'insurancePresent',
  (insurancePresent: boolean) => {
    if (insurancePresent) return Yup.boolean().optional().nullable();
    return Yup.boolean().required(VALIDATION_MESSAGE_ID.REQUIRED);
  }
);

export const validationSchema = Yup.object({
  partyType: Yup.string().oneOf([PARTY_TYPE_VALUE.BUYERS]),
  informThirdParties: validateIfPartyTypeIsBuyer,
  insurancePresent: validateIfPartyTypeIsBuyer,
  agreeToPayInsuranceLimitFee: validateAgreeToPayInsuranceLimitFee
});

const FactoringTypeForm: React.FC = () => {
  const factoringType = useApplicationSelector<typeof selectFactoringType>(selectFactoringType);

  const { getValues, validationHelpers, handleSubmit, clearErrors } =
    useFormHandlers<FactoringAdditionalInfo>(
      utils.copyReadOnlyObjectValues(initialFactoringData, factoringType ?? {}),
      validationSchema,
      'onBlur'
    );

  const dispatch = useAppDispatch();

  const { setFormHandlers } = useContext(FormContext);

  const submitHandler = handleSubmit((data: FactoringAdditionalInfo) => {
    const filteredData =
      data.partyType === PARTY_TYPE_VALUE.BUYERS
        ? data
        : { partyType: data.partyType, agreeToPayInsuranceLimitFee: data.agreeToPayInsuranceLimitFee };

    dispatch(setFactoringAdditionalInfo(filteredData));
    dispatch(submitApplicationAsync());
  });

  useEffect(
    () =>
      setFormHandlers({
        submitHandler
      }),
    [setFormHandlers]
  );

  const handleNextClick = async () => {
    await submitHandler();
  };

  const isInsuranceLimitRequiredSelected =
    getValues('insurancePresent') === true ? true : getValues('agreeToPayInsuranceLimitFee') !== undefined;

  return (
    <FormContainer
      isValid={validationHelpers.isValid && isInsuranceLimitRequiredSelected}
      onNextClick={handleNextClick}
    >
      <FactoringTypeFields
        validationHelpers={validationHelpers}
        getValues={getValues}
        clearErrors={clearErrors}
      />
    </FormContainer>
  );
};

export default FactoringTypeForm;
