import { FC, ReactNode } from 'react';

import bin from '../../assets/icons/bin.svg';
import pencil from '../../assets/icons/pencilOutline.svg';
import { AddButton } from '../addButton';
import { LinkButton } from '../link';

import { ListButtonsContainer, ListContainer, ListItemButton } from './formList.styles';
import ListItem from './listItem';

export type ListItemType = {
  title: ReactNode;
  subtitle?: ReactNode;
  invalid?: boolean;
  icon?: string;
  dataTestId?: string;
};

type Props = {
  list: ListItemType[];
  addButtonLabel?: string;
  itemIcon?: string;
  onAddCardClick?: () => void;
  onItemEdit?: (key: number) => void;
  onItemClick?: (key: number) => void;
  onItemDelete?: (key: number) => void;
  formatContent?: (content: string, key: number) => string;
  itemAvatarTheme?: {
    fill?: string;
    background?: string;
  };
  renderExtraActions?: (item: ListItemType, key: number) => ReactNode;
  className?: string;
};

export const FormList: FC<Props> = ({
  className,
  addButtonLabel,
  itemIcon,
  list,
  onItemClick,
  onItemEdit,
  onItemDelete,
  onAddCardClick,
  renderExtraActions,
  itemAvatarTheme,
  formatContent = (title: string) => title?.substring(0, 2)?.toLocaleUpperCase()
}) => {
  return (
    <ListContainer className={className}>
      {onAddCardClick ? <AddButton onClick={onAddCardClick}>{addButtonLabel}</AddButton> : null}
      {list?.map((item, key) => {
        if (!item) return null;

        const arrayItemKey = typeof item.title === 'string' ? `${item.title ?? ''}${key}` : `${key}`;

        const content =
          itemIcon || item.icon || typeof item.title !== 'string' ? '' : formatContent(item.title, key);

        const listItem = (
          <ListItem
            avatarTheme={itemAvatarTheme}
            key={arrayItemKey}
            title={item.title}
            subtitle={item.subtitle}
            invalid={item.invalid}
            content={content}
            icon={itemIcon ?? item.icon}
          >
            <ListButtonsContainer>
              {onItemDelete ? (
                <LinkButton iconOnly icons={{ left: bin }} color="Grey" onClick={() => onItemDelete(key)} />
              ) : null}
              {onItemEdit ? (
                <LinkButton iconOnly icons={{ left: pencil }} color="Grey" onClick={() => onItemEdit(key)} />
              ) : null}
              {renderExtraActions?.(item, key)}
            </ListButtonsContainer>
          </ListItem>
        );

        if (onItemClick) {
          return (
            <ListItemButton data-testId={item.dataTestId} key={arrayItemKey} onClick={() => onItemClick(key)}>
              {listItem}
            </ListItemButton>
          );
        }

        return listItem;
      })}
    </ListContainer>
  );
};
