import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Field,
  Fieldset,
  Input,
  Checkbox,
  Modal,
  PhoneField,
  Loader,
  Legend,
  ToastLegacy,
  LinkButton,
  TermsModal
} from 'components';
import { ModalRef } from 'components/modal/modal';
import { selectActiveCustomer, selectCustomerInformation, selectUserData } from 'features/auth/selectors';
import { useRequestFinancialReportMutation } from 'features/insights/api';
import { useGetRelationsQuery } from 'features/users/slice';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector } from 'hooks/redux/hooks';
import { emailRequired, personNameRequired, phoneNumberRequired, companyCodeRequired } from 'validators';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { CountryOfRegistrationField } from '../countryOfRegistrationField/countryOfRegistrationField';

import { HeaderText, AgreementFieldContainer, AgreementText } from './creditScoreModal.styles';

const fields = {
  companyName: '',
  companyCode: '',
  country: 'LT',
  userName: '',
  userSurname: '',
  phoneNumber: '',
  email: '',
  dataHandling: false
};

type Fields = typeof fields;

type CreditScoreValidationSchema = Yup.ObjectSchema<Record<keyof Fields, Yup.AnySchema>>;

const supportedCountries = ['LT'];

export const validationSchema: CreditScoreValidationSchema = Yup.object({
  companyName: personNameRequired,
  companyCode: companyCodeRequired,
  country: Yup.string().oneOf(supportedCountries, VALIDATION_MESSAGE_ID.REQUIRED),
  userName: personNameRequired,
  userSurname: personNameRequired,
  phoneNumber: phoneNumberRequired,
  email: emailRequired,
  dataHandling: Yup.boolean().isTrue('dataHandlingMandatory')
});

type Props = {
  isOpen: boolean;
  onVisibilityChange: (visible: boolean) => unknown;
  showSuccessPage: (visible: boolean) => unknown;
};

export const CreditScoreModal: FC<Props> = ({ onVisibilityChange, isOpen, showSuccessPage }) => {
  const { t } = useTranslation();

  const creditScoreRef = useRef<ModalRef>(null);

  const [triggerFinancialReportRequest] = useRequestFinancialReportMutation();

  const [dataHandlingModalOpen, setIsDataHandlingModalOpen] = useState(false);
  const [emailSendError, setEmailSendError] = useState(false);
  const [emailIsSending, setEmailIsSending] = useState(false);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const customerInformation = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const { customerId, customerName } = activeCustomer || {};
  const { userFirstName, userLastName } = customerInformation || {};
  const { email } = userData || {};

  const { data } = useGetRelationsQuery({ email: email || '' }, { skip: !email });
  const { userData: relationUserData } = data?.[0] || {};
  const { mobilePhoneNumber } = relationUserData || {};

  const formValues = useMemo(
    () => ({
      ...fields,
      companyCode: customerId || '',
      companyName: customerName || '',
      userName: userFirstName || '',
      userSurname: userLastName || '',
      phoneNumber: mobilePhoneNumber || '',
      email: email || ''
    }),
    [customerId, customerName, userFirstName, userLastName, email, mobilePhoneNumber, isOpen]
  );

  const { validationHelpers, getValues, reset, handleSubmit, setTouchedOnAll } = useFormHandlers(
    formValues,
    validationSchema
  );

  const handleClose = () => {
    reset();

    creditScoreRef.current?.handleClose?.();

    onVisibilityChange(false);
    setEmailSendError(false);
    setEmailIsSending(false);
  };

  const handleSend = handleSubmit(
    async (data) => {
      try {
        if (!emailIsSending) {
          setEmailIsSending(true);
          setEmailSendError(false);
          await triggerFinancialReportRequest(data).unwrap();
        }
      } catch {
        setEmailSendError(true);
        setEmailIsSending(false);
      } finally {
        handleClose();
        showSuccessPage(true);
      }
    },
    () => {
      setTouchedOnAll();
    }
  );

  const handleReadMoreClick = () => {
    setIsDataHandlingModalOpen(true);
  };

  return (
    <>
      <TermsModal
        isOpen={dataHandlingModalOpen}
        termsTextId="dataProcessingConsent"
        onVisibilityChange={setIsDataHandlingModalOpen}
      />
      <Modal
        ref={creditScoreRef}
        toggleHash="creditScoreReport"
        label={t('creditScoreReport')}
        isOpen={isOpen}
        onClose={handleClose}
        mainActionLabel={t('orderService')}
        mainActionhandler={handleSend}
      >
        {emailIsSending ? <Loader /> : null}
        <ToastLegacy isVisible={emailSendError} message={t('generalErrorMessage')} />
        <HeaderText>{t('company')}</HeaderText>
        <Fieldset>
          <Legend>{t('provideCompanyInformation')}</Legend>
          <Field
            name="companyName"
            Component={Input}
            value={getValues('companyName')}
            placeholder={t('placeholderCompanyName')}
            validationHelpers={validationHelpers}
            required
          />
          <Field
            name="companyCode"
            type="number"
            maxDecimals={0}
            Component={Input}
            value={getValues('companyCode')}
            placeholder={t('placeholderCompanyCode')}
            validationHelpers={validationHelpers}
            required
          />
          <CountryOfRegistrationField
            name="country"
            getValues={getValues}
            validationHelpers={validationHelpers}
            supportedCountries={supportedCountries}
          />
        </Fieldset>
        <HeaderText>{t('contactPerson')}</HeaderText>
        <Fieldset>
          <Legend>{t('provideInformationOfContactPerson')}</Legend>
          <Field
            name="userName"
            Component={Input}
            validationHelpers={validationHelpers}
            placeholder={t('name')}
            value={getValues('userName')}
            required
          />
          <Field
            name="userSurname"
            Component={Input}
            validationHelpers={validationHelpers}
            placeholder={t('lastName')}
            value={getValues('userSurname')}
            required
          />
          <Field
            name="phoneNumber"
            Component={PhoneField}
            validationHelpers={validationHelpers}
            placeholder={t('phone')}
            value={getValues('phoneNumber')}
            defaultCountry={'LT'}
            required
          />
          <Field
            name="email"
            Component={Input}
            validationHelpers={validationHelpers}
            placeholder={t('email')}
            value={getValues('email')}
            required
          />
        </Fieldset>
        <AgreementFieldContainer>
          <Field
            Component={Checkbox}
            name="dataHandling"
            value={getValues('dataHandling')}
            validationHelpers={validationHelpers}
            label={
              <AgreementText>
                {t('agreementPartnershipTextForContact')}
                <LinkButton onClick={handleReadMoreClick}>{t('readMore')}</LinkButton>
              </AgreementText>
            }
          />
        </AgreementFieldContainer>
      </Modal>
    </>
  );
};
