import styled from 'styled-components';

import exclamation from 'assets/icons/exclamationCircle.svg';
import { LinkButton, Typography } from 'components';

export const BuyersFormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const BuyersFormListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const BuyersFormOptions = styled.div`
  display: flex;
  gap: 1rem;

  label {
    width: 100%;
  }
`;

export const BuyersFormListItemHeader = styled(Typography.Header).attrs({ type: 'h5' })`
  display: inline;
`;

export const AdditionalInfoButton = styled(LinkButton).attrs({
  iconOnly: true,
  icons: { left: exclamation },
  color: 'Grey'
})`
  display: inline;
  height: 24px;
  vertical-align: middle;
  margin-left: 0.25rem;
`;
