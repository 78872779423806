import { FC, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import Icons from 'assets/icons';
import { Modal } from 'components';
import { Note } from 'components/notificationModal/notificationModal.styles';
import { WideLayout, WideLayoutPageContainer } from 'components/pageV2';
import { selectToastMessages } from 'features/app/selectors';
import { clearToastMessages } from 'features/app/slice';
import { TopNavigation } from 'features/application/components/topNavigation/topNavigation';
import { FormContextProvider } from 'features/application/context/FormContext';
import { DrawerImplementation, DrawersSwitch } from 'features/application/drawers';
import { BeneficialOwnersForm } from 'features/application/forms/beneficialOwner';
import { CompanyForm } from 'features/application/forms/company';
import CompanyCountryForm from 'features/application/forms/company/companyCountryForm';
import CompanyOrFarmForm from 'features/application/forms/companyOrFarm/companyOrFarmForm/';
import { ContactForm } from 'features/application/forms/contact';
import { FactoringTypeForm } from 'features/application/forms/factoringType';
import FarmCodeForm from 'features/application/forms/farmCode/farmCodeForm/';
import FarmersDetailsForm from 'features/application/forms/farmersDetails/farmersDetailsForm';
import { PriceAndPaymentDetailsForm } from 'features/application/forms/priceAndPaymentDetails';
import { PurposeForm } from 'features/application/forms/purpose';
import { ReviewForm } from 'features/application/forms/review';
import { SelectAmountForm } from 'features/application/forms/selectAmount';
import { SelectProduct, SubproductSelect } from 'features/application/forms/selectProduct';
import { TermsForm } from 'features/application/forms/terms';
import { ThirdPartiesForm } from 'features/application/forms/thirdParties';
import {
  selectActiveStep,
  selectCapId,
  selectCompletionIndex,
  selectContactPerson,
  selectIsCompanyStepsEnabled,
  selectIsNewCompany,
  selectProduct,
  selectProductType,
  selectRedirectUrl,
  selectStepsSequence,
  selectSubProductType
} from 'features/application/selectors';
import { resetApplicationState, setActiveStep, setCompletionIndex } from 'features/application/slice';
import { STEPS } from 'features/application/types/applicationTypes';
import { postMessages } from 'features/application/utils';
import { selectActiveCompany } from 'features/applications/selectors';
import { selectIsEmbededInMobileWebview, selectIsUserGhost, selectUserData } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { BankAccountsForm } from 'features/statements/components';
import { useLazyGetUserByEmailQuery } from 'features/users/slice';
import {
  useAppDispatch,
  useAppInfoSelector,
  useAppSelector,
  useApplicationSelector,
  useAuthSelector
} from 'hooks/redux/hooks';
import { Type } from 'services/CapService/types';
import trackers from 'utils/tracking';

import { StepExplanation, SubheaderContainer } from './newApplication.styles';

const headerTranslationMapping: Record<string, string> = {
  [STEPS.PRODUCT_TYPE]: 'chooseLoan',
  [STEPS.SUBPRODUCT_TYPE]: 'chooseSubproduct',
  [STEPS.AMOUNT]: 'howMuchMoneyDoYouNeed',
  [STEPS.REGISTRATION_COUNTRY]: 'whereIsYourCompanyRegistered',
  [STEPS.COMPANY]: 'enterCompanyDetails',
  [STEPS.CONTACT_PERSON]: 'whoToReach',
  [STEPS.PRICE_AND_PAYMENT_DETAILS]: 'priceAndPaymentDetails',
  [STEPS.FACTORING_TYPE]: 'whatWillBeInvoiceFinanceType',
  [STEPS.BENEFICIAL_OWNERS]: 'listAllBeneficialOwners',
  [STEPS.THIRD_PARTIES]: 'listAllThirdParties',
  [STEPS.TERMS]: 'pickYourLoanTerm',
  [STEPS.PURPOSE]: 'whatIsYourLoanPurpose',
  [STEPS.REVIEW]: 'myApplication',
  [STEPS.COMPANY_OR_FARM]: 'financingFor',
  [STEPS.FARM_CODE]: 'farmCode',
  [STEPS.FARMERS_DETAILS]: 'farmersDetails'
};

const stepExplanationsMapping: Record<string, string> = {
  [STEPS.PRODUCT_TYPE]: '',
  [STEPS.SUBPRODUCT_TYPE]: '',
  [STEPS.AMOUNT]: '',
  [STEPS.REGISTRATION_COUNTRY]: '',
  [STEPS.COMPANY]: '',
  [STEPS.CONTACT_PERSON]: '',
  [STEPS.PRICE_AND_PAYMENT_DETAILS]: '',
  [STEPS.FACTORING_TYPE]: '',
  [STEPS.BENEFICIAL_OWNERS]: '',
  [STEPS.THIRD_PARTIES]: 'listAllThirdPartiesExplanation',
  [STEPS.TERMS]: '',
  [STEPS.PURPOSE]: '',
  [STEPS.REVIEW]: '',
  [STEPS.COMPANY_OR_FARM]: '',
  [STEPS.FARM_CODE]: '',
  [STEPS.FARMERS_DETAILS]: ''
};

const headerAndCompanyTranslationMapping: Record<string, string> = {
  [STEPS.PRODUCT_TYPE]: 'chooseLoanForCompany'
};

const typesTranslationMapping: Record<Type, string> = {
  FACTORING: '',
  CREDIT_LINE: '',
  LOAN: 'loanso',
  LEASING: 'leasingo',
  RBF: ''
};

const getTranslatedHeader = (t: TFunction) => (step: STEPS, type?: Type, companyName?: string) => {
  if (companyName && headerAndCompanyTranslationMapping[step]) {
    return t(headerAndCompanyTranslationMapping[step], { companyName });
  }

  return t(headerTranslationMapping[step] ?? '', { type: t(typesTranslationMapping[type ?? 'FACTORING']) });
};

const NewApplication: FC = () => {
  const { i18n, t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showRightSideDrawer, hideSideDrawer, toggleRightSideDrawer } =
    useNavContext<DrawerImplementation<'stepExplanation'>>();

  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const redirectUrl = useApplicationSelector<typeof selectRedirectUrl>(selectRedirectUrl);
  const activeStep = useApplicationSelector<typeof selectActiveStep>(selectActiveStep);
  const product = useApplicationSelector<typeof selectProduct>(selectProduct);
  const productType = useApplicationSelector<typeof selectProductType>(selectProductType);
  const productSubtype = useApplicationSelector<typeof selectSubProductType>(selectSubProductType);
  const capId = useApplicationSelector<typeof selectCapId>(selectCapId);
  const sequence = useApplicationSelector<typeof selectStepsSequence>(selectStepsSequence);
  const toastMessages = useAppInfoSelector<typeof selectToastMessages>(selectToastMessages);
  const isNewCompany = useAppSelector(selectIsNewCompany);
  const isEmbedded = useAuthSelector<typeof selectIsEmbededInMobileWebview>(selectIsEmbededInMobileWebview);
  const completionIndex = useApplicationSelector<typeof selectCompletionIndex>(selectCompletionIndex);
  const activeCompany = useAppSelector(selectActiveCompany);
  const isCompanyStepsEnabled = useAppSelector(selectIsCompanyStepsEnabled);
  const contactPerson = useApplicationSelector<typeof selectContactPerson>(selectContactPerson) ?? {};
  const { email } = useAuthSelector(selectUserData);
  const isGhostUser = useAuthSelector(selectIsUserGhost);

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(contactPerson.phoneNumber);

  const [triggerUserInfoRequest] = useLazyGetUserByEmailQuery();

  useEffect(() => {
    if (productType) {
      searchParams.set('product', productType?.toLocaleLowerCase());
    }

    if (productSubtype) {
      searchParams.set('subproduct', productSubtype?.toLocaleLowerCase());
    }

    if (activeStep) {
      const applicationProgress = {
        locality: i18n.language,
        application_step: activeStep || '',
        product: productType ?? '',
        subproduct: productSubtype ?? 'not set'
      };

      searchParams.set('currentStep', activeStep?.toLocaleLowerCase());
      trackers.updateApplicationProgress(applicationProgress);
    }

    setSearchParams(searchParams, { replace: true });
  }, [productType, activeStep, productSubtype]);

  useEffect(() => {
    if (capId) {
      navigate({ ...location, pathname: `/new-application/${capId}` }, { replace: true });
    }
  }, [capId]);

  useEffect(() => {
    if (!contactPerson?.phoneNumber && !isGhostUser) {
      const fetchUserInfo = async () => {
        const { data: userInfo } = await triggerUserInfoRequest({ email });

        setPhoneNumber(userInfo?.mobilePhoneNumber);
      };

      fetchUserInfo();
    }
  }, [email, contactPerson?.phoneNumber]);

  const moveToPreviousStep = (currentStep: STEPS, stepSequence: STEPS[]) => {
    const currentIndex = stepSequence.indexOf(currentStep);
    const previousIndex = currentIndex - 1;

    if (previousIndex >= 0) {
      dispatch(setActiveStep(stepSequence[previousIndex]));
    }
  };

  const moveToNextStep = (currentSequence?: STEPS[]) => {
    const resolvedSequence = currentSequence ?? sequence;

    const currentIndex = resolvedSequence.indexOf(activeStep);

    if (toastMessages.length !== 0) {
      dispatch(clearToastMessages());
    }

    const nextIndex = currentIndex + 1;

    if (nextIndex) {
      dispatch(setActiveStep(resolvedSequence[nextIndex]));

      if (nextIndex > completionIndex) {
        dispatch(setCompletionIndex(currentIndex));
      }
    }
  };

  const onBackStep = () => {
    if (activeStep === STEPS.REGISTRATION_COUNTRY) {
      if (isEmbedded) {
        postMessages.postNavigationFromCapEvent();
      }

      setShowDeleteConfirmationModal(true);

      return;
    }

    if (toastMessages.length !== 0) {
      dispatch(clearToastMessages());
    }

    moveToPreviousStep(activeStep, sequence);
  };

  const discardApplication = () => {
    if (isEmbedded) {
      postMessages.postNavigationFromCapEvent();
    }

    dispatch(resetApplicationState());

    return navigate('/dashboard/applications');
  };

  const handleClose = () => {
    setShowDeleteConfirmationModal(false);
  };

  const successRedirectUrl = isCompanyStepsEnabled ? '/dashboard/verification' : '/dashboard/applications';

  const renderForm = (activeStep: string) => {
    switch (activeStep) {
      case STEPS.PRODUCT_TYPE:
        return <SelectProduct onNextClick={moveToNextStep} />;
      case STEPS.SUBPRODUCT_TYPE:
        return <SubproductSelect onNextClick={moveToNextStep} />;
      case STEPS.PURPOSE:
        return <PurposeForm />;
      case STEPS.AMOUNT:
        return <SelectAmountForm />;
      case STEPS.TERMS:
        return <TermsForm />;
      case STEPS.PRICE_AND_PAYMENT_DETAILS:
        return <PriceAndPaymentDetailsForm onNextClick={moveToNextStep} />;
      case STEPS.REGISTRATION_COUNTRY:
        return <CompanyCountryForm />;
      case STEPS.COMPANY_OR_FARM:
        return <CompanyOrFarmForm onNextClick={moveToNextStep} />;
      case STEPS.COMPANY:
        return <CompanyForm />;
      case STEPS.FARM_CODE:
        return <FarmCodeForm onNextClick={moveToNextStep} />;
      case STEPS.CONTACT_PERSON:
        return <ContactForm phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />;
      case STEPS.FARMERS_DETAILS:
        return <FarmersDetailsForm onNextClick={moveToNextStep} />;
      case STEPS.FACTORING_TYPE:
        return <FactoringTypeForm />;
      case STEPS.BENEFICIAL_OWNERS:
        return <BeneficialOwnersForm />;
      case STEPS.THIRD_PARTIES:
        return <ThirdPartiesForm />;
      case STEPS.BANK_ACCOUNTS:
        return <BankAccountsForm onNextClick={moveToNextStep} />;
      case STEPS.REVIEW:
        return <ReviewForm />;
      case STEPS.SUCCESS_SUBMIT:
        return (
          <Navigate to={`${successRedirectUrl}?success=true`} replace state={{ redirectUrl, product }} />
        );
      default:
        return null;
    }
  };

  const onHelpClickFactory = (step: STEPS) => {
    if (step === STEPS.BENEFICIAL_OWNERS) {
      return () => {
        toggleRightSideDrawer?.({ drawerId: 'stepExplanation', step });
      };
    }
  };

  const headerTranslator = getTranslatedHeader(t);

  return (
    <>
      <Modal
        informationalIcon={capId ? <Icons.Email /> : <Icons.SpaceShip />}
        variant="informational"
        label={capId ? t('continueFillingApplicationHeader') : t('applicationDiscardHeader')}
        isOpen={showDeleteConfirmationModal}
        onClose={handleClose}
        mainActionLabel={t('continueFilling')}
        secondaryActionLabel={capId ? t('saveAndClose') : t('quit')}
        mainActionhandler={handleClose}
        secondaryActionhandler={discardApplication}
        mainButtonColor="Blue"
      >
        <Note>{capId ? t('continueFillingApplicationExplanation') : t('applicationDiscardExplanation')}</Note>
      </Modal>
      <FormContextProvider>
        <WideLayout
          onDrawerStateChange={(visible) => {
            if (!visible) {
              hideSideDrawer?.();
            }
          }}
          rightSideDrawerOpen={showRightSideDrawer}
          asideMenuContent={<DrawersSwitch setPhoneNumber={setPhoneNumber} />}
        >
          <TopNavigation
            onBackClick={onBackStep}
            showModal={setShowDeleteConfirmationModal}
            header={headerTranslator(activeStep, productType, isNewCompany ? undefined : activeCompany?.name)}
            onHelpClick={onHelpClickFactory(activeStep)}
          />
          <SubheaderContainer>
            <StepExplanation>{t(stepExplanationsMapping[activeStep])}</StepExplanation>
          </SubheaderContainer>
          <WideLayoutPageContainer>
            <div ref={contentWrapperRef} style={{ height: '1px' }} />
            {renderForm(activeStep)}
          </WideLayoutPageContainer>
        </WideLayout>
      </FormContextProvider>
    </>
  );
};

export default NewApplication;
