import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountText, Currency, RegularText, SecondaryText } from 'components';
import {
  Cell,
  InvoicesSummaryTableContainer
} from 'features/invoices/components/summary/invoiceSummary.styles';

import { FactoringSummary } from './utils/calculateSummary';

export type MonetaryValue = {
  amount: number;
  currency?: 'EUR';
};

type Props = {
  summaryData: FactoringSummary;
};

export const FactoringSummaryTable: FC<Props> = ({ summaryData }) => {
  const { t } = useTranslation();

  return (
    <InvoicesSummaryTableContainer>
      <Cell>
        <SecondaryText>{t('totalAmount')}</SecondaryText>
        <RegularText>
          <AmountText currency={summaryData.totalAmount?.currency as Currency}>
            {summaryData.totalAmount.amount}
          </AmountText>
        </RegularText>
      </Cell>
      <Cell>
        <SecondaryText>{t('totalFinanced')}</SecondaryText>
        <RegularText>
          <AmountText currency={summaryData.totalFinanced?.currency as Currency}>
            {summaryData.totalFinanced.amount}
          </AmountText>
        </RegularText>
      </Cell>
      <Cell>
        <SecondaryText>{t('reminder')}</SecondaryText>
        <RegularText>
          <AmountText currency={summaryData.reminder?.currency as Currency}>
            {summaryData.reminder.amount}
          </AmountText>
        </RegularText>
      </Cell>
      <Cell>
        <SecondaryText>{t('totalUnpaid')}</SecondaryText>
        <RegularText>
          <AmountText currency={summaryData.reminder?.currency as Currency}>
            {summaryData.totalUnpaid.amount}
          </AmountText>
        </RegularText>
      </Cell>
    </InvoicesSummaryTableContainer>
  );
};
