import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import leftArrow from 'assets/icons/arrowLeft.svg';
import { Grid, Column, LinkButton, Loader } from 'components';
import { useFetchContractDetails } from 'features/contracts/hooks';
import { InvoiceDetailsHeader } from 'features/factoring/components';
import { InvoiceDetailsTable, InvoiceDetailsTotals } from 'features/factoringInvoices/tables';
import { FeesAndTransactionsTitle } from 'features/factoringInvoices/tables/invoiceDetails/invoiceDetails.styles';
import { InvoiceDetailsApproval } from 'features/factoringInvoices/tables/invoiceDetails/invoiceDetailsApproval';
import { InvoiceDetailsFees } from 'features/factoringInvoices/tables/invoiceDetails/invoiceDetailsFees';
import { InvoiceDetailsPayments } from 'features/factoringInvoices/tables/invoiceDetails/invoiceDetailsPayments';
import { InvoiceDetailsPayouts } from 'features/factoringInvoices/tables/invoiceDetails/invoiceDetailsPayouts';
import {
  useGetCommisionFeeQuery,
  useGetInterestFeeQuery,
  useGetLateFeesQuery,
  useGetPaymentReceiptsQuery,
  useGetPayoutsQuery
} from 'features/factoringStatements/api';
import { useGetInvoiceDetailsQuery } from 'features/invoices/api';
import { FactoringStatementsService } from 'services';

type Props = {
  itemId: string;
};

export const InvoiceDetails: FC<Props> = ({ itemId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: contractDetails, isLoading: contractDetailsIsLoading } = useFetchContractDetails();

  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const { data: invoiceDetails, isLoading: invoiceDetailsIsLoading } = useGetInvoiceDetailsQuery(
    { invoiceId: itemId, contractId: contractId || '' },
    { skip: !itemId || !contractId }
  );

  const { data: payouts, isLoading: payoutsIsLoading } = useGetPayoutsQuery(
    { invoiceId: itemId },
    { skip: !itemId }
  );

  const { data: payments, isLoading: paymentsIsLoading } = useGetPaymentReceiptsQuery(
    { invoiceId: itemId },
    { skip: !itemId }
  );

  const { data: commisionFees, isLoading: commisionFeesIsLoading } = useGetCommisionFeeQuery(
    { invoiceId: itemId },
    { skip: !itemId }
  );

  const { data: interestFees, isLoading: interestFeesIsLoading } = useGetInterestFeeQuery(
    { invoiceId: itemId },
    { skip: !itemId }
  );

  const { data: lateFees, isLoading: lateFeesIsLoading } = useGetLateFeesQuery(
    { invoiceId: itemId },
    { skip: !itemId }
  );

  const downloadHandler = (actId?: string) => {
    if (actId) {
      return FactoringStatementsService.getPaymentDocument({ actId });
    }
  };

  const isLoading =
    contractDetailsIsLoading ||
    invoiceDetailsIsLoading ||
    payoutsIsLoading ||
    paymentsIsLoading ||
    commisionFeesIsLoading ||
    interestFeesIsLoading ||
    lateFeesIsLoading;

  const { invoice } = invoiceDetails || {};

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Grid>
      <Column rowStart={1} spanRow={1} colStart={1} span={12}>
        <LinkButton
          style={{ paddingBlock: '1.5rem' }}
          color="Black"
          icons={{ left: leftArrow }}
          onClick={onBack}
        >
          {t('back')}
        </LinkButton>
        {!isLoading ? (
          <InvoiceDetailsHeader
            invoiceNumber={invoice?.invoiceNumber || '-'}
            contractNumber={contractDetails.contractNumber || '-'}
            status={contractDetails.status}
          />
        ) : (
          <Loader />
        )}
      </Column>
      {!isLoading ? (
        <>
          <Column
            rowStart={2}
            spanRow={2}
            colStart={1}
            colStartMd={1}
            colStartSm={1}
            span={9}
            spanMd={12}
            spanSm={12}
          >
            {invoice ? (
              <InvoiceDetailsTable invoiceDetails={invoice} contractDetails={contractDetails} />
            ) : null}
          </Column>
          <Column
            colStart={10}
            colStartMd={1}
            colStartSm={1}
            spanMd={12}
            spanSm={12}
            rowStart={2}
            rowStartLg={3}
            rowStartMd={3}
            rowStartSm={3}
            spanRow={2}
            spanRowLg={3}
            spanRowMd={3}
            spanRowSm={3}
            span={3}
          >
            {invoice ? <InvoiceDetailsTotals invoiceDetails={invoice} /> : null}
          </Column>
          <Column
            rowStartLg={3}
            rowStartMd={4}
            rowStartSm={4}
            spanRowLg={3}
            spanRowMd={4}
            spanRowSm={4}
            colStart={1}
            span={12}
          >
            {invoice ? <InvoiceDetailsApproval invoiceDetails={invoice} /> : null}
          </Column>
          <Column
            rowStartLg={3}
            rowStartMd={4}
            rowStartSm={4}
            spanRowLg={3}
            spanRowMd={4}
            spanRowSm={4}
            colStart={1}
            span={12}
          >
            <FeesAndTransactionsTitle>{t('feesAndTransactions')}</FeesAndTransactionsTitle>
          </Column>
          <Column
            rowStartLg={3}
            rowStartMd={4}
            rowStartSm={4}
            spanRowLg={3}
            spanRowMd={4}
            spanRowSm={4}
            colStart={1}
            span={12}
          >
            {payouts ? <InvoiceDetailsPayouts payouts={payouts} onClick={downloadHandler} /> : null}
          </Column>
          <Column
            rowStartLg={3}
            rowStartMd={4}
            rowStartSm={4}
            spanRowLg={3}
            spanRowMd={4}
            spanRowSm={4}
            colStart={1}
            span={12}
          >
            {payments ? <InvoiceDetailsPayments payments={payments} onClick={downloadHandler} /> : null}
          </Column>
          <Column
            rowStartLg={3}
            rowStartMd={4}
            rowStartSm={4}
            spanRowLg={3}
            spanRowMd={4}
            spanRowSm={4}
            colStart={1}
            span={12}
          >
            <InvoiceDetailsFees
              commisionFees={commisionFees || {}}
              lateFees={lateFees || {}}
              interestFees={interestFees || {}}
              onClick={downloadHandler}
            />
          </Column>
        </>
      ) : null}
    </Grid>
  );
};
