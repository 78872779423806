import styled, { css } from 'styled-components';

import { LinkButtonStyle, PopupIconButtonContainer } from 'components';

export const ActsTableContainer = styled.div`
  div[data-column-id='amount'] {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
  }

  .rdt_Table {
    .rdt_TableCol_Sortable {
      padding-left: 0;
    }
  }

  .rdt_TableHeadRow {
    padding-left: 0.625rem;
  }

  ${LinkButtonStyle} {
    svg {
      g {
        path {
          fill: ${({ theme }) => theme.colors.secondary.textColor};
          stroke: none;
        }
      }
    }
  }

  ${PopupIconButtonContainer} {
    padding: 0 0.3125rem;

    svg {
      g {
        path {
          fill: ${({ theme }) => theme.colors.secondary.textColor};
          stroke: none;
        }
      }
    }
  }
`;

const fileTypeNameVisualBeforeCss = css`
  display: inline-flex;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.125rem;
  font-size: 0.5rem;
  font-weight: bold;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.linkText};
`;

const fileTypeNameVisualAfterCss = css`
  padding-left: 0.3125rem;
  color: ${({ theme }) => theme.colors.primary.linkText};
`;

export const FileTypePdf = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: 'PDF';
    ${fileTypeNameVisualBeforeCss}
  }

  &::after {
    content: '.pdf';
    ${fileTypeNameVisualAfterCss}
  }
`;

export const FileTypeXls = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: 'X';
    ${fileTypeNameVisualBeforeCss}
  }

  &::after {
    content: '.xls';
    ${fileTypeNameVisualAfterCss}
  }
`;

export const DownloadTypesContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${FileTypePdf} {
    margin-bottom: 0.3125rem;
  }
`;
