import { FC } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Field, Input } from 'components';
import { BeneficialOwner } from 'services/CapService/types';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';

import { BeneficialOwnersFieldset } from './beneficialOwnerForm.styles';

type Props = {
  validationHelpers: ValidationHelpers<BeneficialOwner>;
  getValues: UseFormGetValues<BeneficialOwner>;
};

export const BeneficialOwnerFields: FC<Props> = ({ validationHelpers, getValues }) => {
  const { t } = useTranslation();

  const percentage = isNaN(Number(getValues('owningPercentage'))) ? '' : getValues('owningPercentage');

  return (
    <BeneficialOwnersFieldset>
      <Field
        Component={Input}
        size="L"
        name="firstName"
        value={getValues('firstName')}
        placeholder={t('name')}
        validationHelpers={validationHelpers}
        required
      />
      <Field
        Component={Input}
        size="L"
        name="lastName"
        value={getValues('lastName')}
        placeholder={t('lastName')}
        validationHelpers={validationHelpers}
        required
      />
      <Field
        Component={Input}
        size="L"
        name="owningPercentage"
        value={percentage}
        type="number"
        maxDecimals={2}
        placeholder={t('amountOfSharesInPercentage')}
        validationHelpers={validationHelpers}
        required
      />
    </BeneficialOwnersFieldset>
  );
};
