import { useTranslation } from 'react-i18next';

import bin from 'assets/icons/bin.svg';
import { Column, Grid, InputView, LinkButton } from 'components';
import { transformDateToLocalizedString } from 'components/datePicker/utils';
import { selectActiveCustomer, selectIsCustomerAdmin } from 'features/auth/selectors';
import { EditableField } from 'features/users/components';
import { useDeleteCustomerMutation, useGetCustomerQuery } from 'features/users/slice';
import { useAuthSelector } from 'hooks/redux/hooks';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

import { ContentContainer } from './company.styles';

const Company = () => {
  const { t, i18n } = useTranslation();

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isAdmin = useAuthSelector<typeof selectIsCustomerAdmin>(selectIsCustomerAdmin);

  const [triggerDelete] = useDeleteCustomerMutation();

  const { customerName, customerId, customerCountryId, customerCrmId } = activeCustomer || {};
  const countryName = getCountryDisplayName(customerCountryId || '', i18n.language as Languages);

  const { currentData: companyInfo } = useGetCustomerQuery(
    { country: customerCountryId ?? '', companyCode: customerId ?? '' },
    { skip: !customerId || !customerCountryId }
  );

  const disableEdit = !isAdmin;

  const updatedOnInfoLabel = [
    `${t('nameChangedOn')} ${transformDateToLocalizedString(companyInfo?.updatedOn ?? '')}`,
    companyInfo?.updatedBy
  ]
    .filter(Boolean)
    .join(';');

  return (
    <ContentContainer data-testid="settings-company-info">
      <Grid>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField
            readonly={disableEdit}
            label="companyName"
            value={customerName}
            editDrawerId="editCompanyName"
          />
          {isAdmin ? <InputView label={updatedOnInfoLabel}></InputView> : null}
        </Column>
        {isAdmin ? (
          <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
            <EditableField
              readonly={disableEdit}
              label="crmId"
              value={customerCrmId}
              editDrawerId="editCrmId"
            />
          </Column>
        ) : null}
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField
            readonly
            label="placeholderCompanyCode"
            value={customerId}
            editDrawerId="editCompanyCode"
          />
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField readonly label="country" value={countryName} />
        </Column>
        {disableEdit ? null : (
          <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
            <LinkButton
              iconOnly
              icons={{ left: bin }}
              color="Red"
              data-testid="delete-company-button"
              onClick={() => {
                if (customerCountryId && customerId) {
                  triggerDelete({ country: customerCountryId, companyCode: customerId });
                }
              }}
            />
          </Column>
        )}
      </Grid>
    </ContentContainer>
  );
};

export default Company;
