import { FC, ReactNode, useState } from 'react';

import { LinkButtonStyleProps } from '../link/link.styles';

import { DownloadLinkStyle } from './downloadLink.styles';

type HeadResponse = {
  headers?: {
    'content-disposition': string;
    'content-type': string;
  };
};

type FileDataResponse = { data: unknown; headers: HeadResponse['headers'] };

type Props = {
  children?: ReactNode | ReactNode[];
  fileRequest: () => Promise<unknown | undefined | FileDataResponse>;
  fileName?: string;
  fileType?: 'pdf' | 'other';
  testId?: string;
  formatFileName?: (fileName: string) => string;
  className?: string;
  noFill?: boolean;
} & LinkButtonStyleProps;

const readFileNameFromContentDisposition = (headerValue?: string) => {
  if (!headerValue) {
    return '';
  }

  let filename = headerValue.split(/;(.+)/)[1].split(/=(.+)/)[1];

  if (filename.toLowerCase().startsWith("utf-8''")) {
    filename = decodeURIComponent(filename.replace("utf-8''", ''));
  } else {
    filename = filename.replace(/['"]/g, '');
  }

  return filename;
};

const getFileExtensionFromFileName = (fileName: string) => {
  return fileName.split('.').pop();
};

const hasFileExtension = (fileName: string) => {
  return fileName.includes('.');
};

export const DownloadLink: FC<Props> = ({
  children,
  fileRequest,
  fileName,
  testId,
  className,
  fileType = 'other',
  noFill,
  formatFileName = (value) => value,
  ...rest
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  let contentType = fileType === 'other' ? undefined : { type: `application/${fileType}` };

  const downloadHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!isDownloading) {
        setIsDownloading(true);
        const fileResponse = await fileRequest();

        if (fileResponse) {
          let originalFileName = fileName;

          if (typeof fileResponse === 'object' && 'data' in fileResponse && 'headers' in fileResponse) {
            const dataResponse = fileResponse as FileDataResponse;
            const { headers } = dataResponse || {};

            originalFileName =
              originalFileName || readFileNameFromContentDisposition(headers?.['content-disposition']);
            const contentTypeValue = headers?.['content-type'];

            if (hasFileExtension(originalFileName)) {
              originalFileName = formatFileName(originalFileName);
            } else {
              const headerFileNameExtension = getFileExtensionFromFileName(
                readFileNameFromContentDisposition(headers?.['content-disposition'])
              );

              originalFileName = formatFileName(`${originalFileName}.${headerFileNameExtension}`);
            }

            if (contentTypeValue && contentTypeValue.includes('pdf')) {
              contentType = { type: headers?.['content-type'] };
            }
          }

          let blob = null;

          if (typeof fileResponse === 'object' && 'data' in fileResponse && 'headers' in fileResponse) {
            blob = new Blob([(fileResponse as FileDataResponse).data as string], contentType);
          } else {
            blob = new Blob([fileResponse as string], contentType);
          }

          if (originalFileName && blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originalFileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      }
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <DownloadLinkStyle
      loading={isDownloading}
      noFill={noFill}
      className={className}
      data-testid={testId}
      onClick={downloadHandler}
      {...rest}
    >
      {children}
    </DownloadLinkStyle>
  );
};
