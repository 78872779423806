import { useTranslation } from 'react-i18next';

import { StatusChip } from 'components';
import { Button } from 'components/buttonV2';
import { Status } from 'features/applications/types/applicationsTypes';

import StepCard from './stepCard';

type Steps = 'verifyYourIdentity' | 'verifyYourBusiness';

type Props = {
  id?: string;
  applicationId?: string;
  status?: Status;
  step: Steps;
  disabled?: boolean;
  loading?: boolean;
};

const statusesOrder: Status[] = [
  'NA',
  'OFFER_REJECTED',
  'NOT_FILLED',
  'INITIAL',
  'ADDITIONAL_INFORMATION_NEEDED',
  'ASSESSMENT_ONGOING',
  'OFFER_SENT',
  'OFFER_ACCEPTED',
  'KYC_WAITING',
  'KYC_COMPLETED',
  'KYC_NOT_REQUIRED',
  'KYC_REJECTED'
];

const isStepEnabled = (status: Status) => {
  return statusesOrder.indexOf(status) >= statusesOrder.indexOf('OFFER_ACCEPTED');
};

export const isStepCompleted = (status: Status) => {
  return statusesOrder.indexOf(status) >= statusesOrder.indexOf('KYC_COMPLETED');
};

const Chip = ({ status }: Props) => {
  const { t } = useTranslation();

  const stepCompleted = isStepCompleted(status ?? 'NA');

  if (stepCompleted) {
    return <StatusChip color="green">{t('done')}</StatusChip>;
  }

  return null;
};

const OnboardingLoginStepCard = ({ step, disabled, loading, status = 'NOT_FILLED', ...ids }: Props) => {
  const { t } = useTranslation();

  const stepCompleted = isStepCompleted(status);
  const stepEnabled = isStepEnabled(status);

  const onClick = () => {
    window.open(VARIABLES.companiesQuestionaire as string, '_blank');
  };

  return (
    <StepCard
      loading={loading}
      disabled={!stepEnabled || disabled || loading}
      title={t('answerQuestionaire')}
      StatusChip={<Chip step={step} status={status} {...ids} />}
    >
      {stepCompleted || status === 'OFFER_REJECTED' ? null : <Button onClick={onClick}>{t('verify')}</Button>}
    </StepCard>
  );
};

export default OnboardingLoginStepCard;
