import styled from 'styled-components';

export const HorizontalScrollableContainer = styled.div`
  display: flex;
  overflow-x: auto;

  > * {
    margin-right: 0.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const CardsReelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;
