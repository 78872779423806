import styled from 'styled-components';

import { PaperStyle, Typography } from 'components';

export const Container = styled(PaperStyle)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2rem 2rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 1rem 1.5rem 1.5rem;
  }
`;

export const MainTitle = styled(Typography.Header)`
  text-align: center;
  margin-block: 1rem;
`;

MainTitle.defaultProps = {
  type: 'h2',
  weight: 'medium'
};

export const Subtitle = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
  text-align: center;
  white-space: pre-wrap;
  font-size: 1rem;
  line-height: 1.5rem;
`;
