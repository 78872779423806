import { AxiosResponse } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountText, Column, DownloadLink, InputView, RegularText, SecondaryText } from 'components';
import {
  Fee,
  InvoiceCommissionFeesActCollection,
  InvoiceInterestFeesCollection,
  InvoiceLateFeesActCollection
} from 'services/FactoringStatementsService/types';

import {
  InvoiceDetailsCard,
  HeaderContainer,
  Title,
  TransactionDetails,
  GroupedItems,
  TotalsSection
} from './invoiceDetails.styles';

type Props = {
  commisionFees: InvoiceCommissionFeesActCollection;
  interestFees: InvoiceInterestFeesCollection;
  lateFees: InvoiceLateFeesActCollection;
  onClick?: (actId: string) => Promise<AxiosResponse<any, any>> | undefined;
};

const byDate = (a: Fee, b: Fee) => {
  if (b.calculationDate && a.calculationDate) {
    return new Date(a.calculationDate).getTime() - new Date(b.calculationDate).getTime();
  }

  return 0;
};

export const InvoiceDetailsFees: FC<Props> = ({ lateFees, commisionFees, interestFees, onClick }) => {
  const { t } = useTranslation();

  const onClickHandler = (actId: string) => async () => {
    return onClick?.(actId);
  };

  const totalLateFees = lateFees.totalAmount?.amount || 0;
  const totalCommisionFees = commisionFees.totalAmount?.amount || 0;
  const totalInterestFees = interestFees.totalAmount?.amount || 0;
  const totalFees = totalLateFees + totalCommisionFees + totalInterestFees;

  const renderGroups = (fees: Fee[]) => {
    const sortedItems = [...(fees || [])].sort((a: Fee, b: Fee) => {
      return byDate(a, b);
    });

    return sortedItems.map((fee) => {
      return (
        <TransactionDetails key={fee.id}>
          <RegularText>{fee.calculationDate}</RegularText>
          <DownloadLink fileRequest={onClickHandler(fee.id || '')}>{t('view')}</DownloadLink>

          <AmountText className="payout-amount" currency={fee.totalAmount?.currency}>
            {fee.totalAmount?.amount}
          </AmountText>
        </TransactionDetails>
      );
    });
  };

  return (
    <InvoiceDetailsCard>
      <HeaderContainer>
        <Title>{t('fees')}</Title>
      </HeaderContainer>
      {!lateFees.invoiceLateFeesActs?.length &&
      !interestFees.invoiceInterestFeesActs?.length &&
      !commisionFees.invoiceCommissionFeesActs?.length ? (
        <SecondaryText>{t('emptyInvoiceStatementsState', { item: t('fees') })}</SecondaryText>
      ) : (
        <>
          <Column>
            {lateFees && lateFees.invoiceLateFeesActs?.length ? (
              <GroupedItems>
                <SecondaryText>{t('lateFee')}</SecondaryText>
                {renderGroups(lateFees.invoiceLateFeesActs)}
              </GroupedItems>
            ) : null}
            {interestFees && interestFees.invoiceInterestFeesActs?.length ? (
              <GroupedItems>
                <SecondaryText>{t('interest')}</SecondaryText>
                {renderGroups(interestFees.invoiceInterestFeesActs)}
              </GroupedItems>
            ) : null}
            {commisionFees && commisionFees.invoiceCommissionFeesActs?.length ? (
              <GroupedItems>
                <SecondaryText>{t('commisionFee')}</SecondaryText>
                {renderGroups(commisionFees.invoiceCommissionFeesActs)}
              </GroupedItems>
            ) : null}
          </Column>
          <TotalsSection>
            <InputView orientation="horizontal" label={t('total')}>
              <AmountText className="fees-amount" currency={'EUR'}>
                {totalFees}
              </AmountText>
            </InputView>
          </TotalsSection>
        </>
      )}
    </InvoiceDetailsCard>
  );
};
