import { FC } from 'react';

import { LineContainer } from './loader.styles';

export const Line: FC = () => {
  return (
    <LineContainer>
      <div className="line" />
    </LineContainer>
  );
};
