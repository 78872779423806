import styled from 'styled-components';

import { Paper } from '../paper';

export const SoonCardLabel = styled.span`
  position: absolute;
  top: 0;
  right: 1.75rem;
  padding: 1rem;
  background-color: #a0e08f;
  color: #346028;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`;

export const SoonCardPaper = styled(Paper)`
  padding: 1.5rem;
  min-height: 22.375rem;
`;
