import { FC, ReactNode } from 'react';

import { RegularText } from '../text';

import { ArticleHeader, ContentContaner, FooterContainer, SectionContainer } from './section.style';

type Props = {
  header?: string | ReactNode;
  children?: ReactNode | ReactNode[];
  footerButton?: ReactNode;
  testId?: string;
};

const Section: FC<Props> = ({ header, children, footerButton, testId }) => {
  return (
    <SectionContainer data-testid={testId}>
      {header ? (
        <ArticleHeader>
          {typeof header === 'string' ? <RegularText>{header}</RegularText> : header}
        </ArticleHeader>
      ) : null}
      <ContentContaner>{children}</ContentContaner>
      {footerButton ? <FooterContainer>{footerButton}</FooterContainer> : null}
    </SectionContainer>
  );
};

export default Section;
