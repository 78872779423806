import { FC, ReactNode, SyntheticEvent, useId } from 'react';

import Download from '../../assets/icons/download.svg';
import BackArrow from '../../assets/icons/leftLongArrow.svg';
import doc from '../../assets/pictograms/docSmall.svg';
import excel from '../../assets/pictograms/excelSmall.svg';
import image from '../../assets/pictograms/imageSmall.svg';
import pdf from '../../assets/pictograms/pdfSmall.svg';
import unknown from '../../assets/pictograms/unknownSmall.svg';

import { isImage } from './filePreview';
import {
  PreviewControls,
  ControlsGroup,
  ControlButton,
  Filename,
  FileTypeImage,
  StyleProps
} from './filePreview.styles';

type Props = {
  filename: string;
  extraActions?: ReactNode;
  onClose?: () => unknown;
  onDownload?: () => unknown;
  hasDownloadControlVisible?: boolean;
  hasCancelControlVisible?: boolean;
} & StyleProps;

type FilePictograms = Record<'excel' | 'doc' | 'unknown' | 'pdf' | 'image', string>;

const pictograms = {
  excel,
  doc,
  unknown,
  pdf,
  image
};

export const filePictograms = (filename: string, pictograms: FilePictograms) => {
  const fileExtension = filename.split('.').pop()?.toLowerCase();

  if (isImage(filename)) {
    return pictograms.image;
  }

  switch (fileExtension) {
    case 'xls':
    case 'xlsx':
    case 'xlsm':
    case 'xlt':
    case 'xltx':
      return pictograms.excel;
    case 'doc':
    case 'docx':
    case 'docm':
    case 'dot':
      return pictograms.doc;
    case 'pdf':
      return pictograms.pdf;
    default:
      return pictograms.unknown;
  }
};

export const PreviewHeader: FC<Props> = ({
  filename,
  extraActions,
  onClose,
  onDownload,
  lightMode,
  hasCancelControlVisible = true,
  hasDownloadControlVisible = true
}) => {
  const uniqueId = useId();

  const handleClose = (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClose?.();
  };

  const handleDownload = (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onDownload?.();
  };

  return (
    <PreviewControls
      onClick={(e) => e.stopPropagation()}
      className="preview--header"
      style={{ justifyContent: 'space-between' }}
      lightMode={lightMode}
    >
      {filename && hasCancelControlVisible ? (
        <ControlsGroup id={`cancel-control-${uniqueId}`}>
          {onClose ? <ControlButton disabled={!onClose} icon={BackArrow} onClick={handleClose} /> : null}
          <FileTypeImage src={filePictograms(filename, pictograms)} alt="" />
          <Filename>{filename}</Filename>
        </ControlsGroup>
      ) : null}
      {filename && hasDownloadControlVisible ? (
        <ControlsGroup id={`download-control-${uniqueId}`}>
          <label htmlFor={`extra-actions-control`}>
            <img id="extra-actions-control" style={{ display: 'none' }} alt="Autofill form"></img>
            {extraActions}
          </label>
          <ControlButton disabled={!onDownload} icon={Download} onClick={handleDownload} />
        </ControlsGroup>
      ) : null}
    </PreviewControls>
  );
};
