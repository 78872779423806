import styled from 'styled-components';

import { Typography } from 'components/typography';

export const ListItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  gap: 1rem;
  border-radius: 1.5rem;
  width: 100%;
`;

export const ItemCardCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

export const ItemCardTitle = styled(Typography.Header).attrs({ type: 'h6', weight: 'medium' })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-flex;
`;

type ItemCardSubtitleProps = {
  invalid?: boolean;
};

export const ItemCardSubtitle = styled(Typography.Body).attrs({ type: 'M' })<ItemCardSubtitleProps>`
  overflow: hidden;
  color: ${({ theme, invalid }) =>
    invalid ? theme.colors.base.red.shades[100] : theme.colors.base.black.tints[50]};
  display: inline-flex;
  text-align: left;
`;

export type ItemCardIconProps = {
  content?: string;
  icon?: string;
  avatarTheme?: {
    fill?: string;
    background?: string;
  };
};

export const ItemCardIcon = styled.div<ItemCardIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  min-height: 3rem;
  width: 3rem;
  height: 3rem;
  background-color: ${({ theme, avatarTheme }) =>
    avatarTheme ? avatarTheme.background : theme.colors.base.black.shades['10']};
  border-radius: 50%;
  padding: 0.25rem;
  position: relative;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 1.25rem;

  &::before {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    content: '${({ content }) => content ?? ''}';
    text-transform: uppercase;
    width: 1.5rem;
    height: 1.5rem;
    mask-image: ${({ icon }) => (icon ? `url("${icon}")` : 'none')};
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: ${({ theme, icon, avatarTheme }) =>
      icon ? (avatarTheme ? avatarTheme.fill : theme.colors.base.black.shades['70']) : 'transparent'};
    color: ${({ theme, avatarTheme }) =>
      avatarTheme ? avatarTheme.fill : theme.colors.base.black.shades['70']};
  }
`;
