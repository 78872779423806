import { SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AsideDrawer, ChipOption, useDrawerCloseMutationObserver } from 'components';
import { InnerDrawer } from 'features/app/components/drawer';
import { useKeyboardHandler } from 'features/app/hooks';
import { PAYMENT_DAYS_LIST } from 'features/application/forms/thirdParties/constants';

type Props = {
  open: boolean;
  onClose: () => unknown;
  onSelect: (value: number) => unknown;
  selectedValue?: number;
};

const DeferralPeriodDrawer = ({ open, onClose, onSelect, selectedValue }: Props) => {
  const { t } = useTranslation();

  const drawerRef = useRef<HTMLDivElement>(null);

  useKeyboardHandler(onClose, onClose);
  useDrawerCloseMutationObserver(drawerRef.current, onClose);

  const handleSelect = (e: SyntheticEvent<HTMLInputElement>) => {
    const selectedValue = e.currentTarget.value;

    if (selectedValue) {
      onSelect(Number(selectedValue));
    }
  };

  const handleClose = () => {
    setTimeout(onClose, 150);
  };

  const isChecked = (value: number) => {
    return Number(selectedValue) === value;
  };

  return (
    <AsideDrawer ref={drawerRef} activeIndex={1} isOpen={open}>
      <InnerDrawer deepNested header={t('placeholderDeferralPeriod')} onClose={onClose}>
        {PAYMENT_DAYS_LIST.map((paymentTerm, index) => (
          <ChipOption
            tabIndex={index}
            name="deferralPeriod"
            on="light"
            key={paymentTerm.value}
            value={paymentTerm.value}
            checked={isChecked(paymentTerm.value)}
            onChange={handleSelect}
            onClick={handleClose}
          >
            {`${paymentTerm.label} ${t('pluralDays')}`}
          </ChipOption>
        ))}
      </InnerDrawer>
    </AsideDrawer>
  );
};

export default DeferralPeriodDrawer;
