import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AuthenticationDialog } from 'components';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { useAppLocation } from 'hooks/router/useAppLocation';
import { RequestInitSmartID } from 'services/LoginService/typings';

import PersonalDataForm from '../../components/personalDataForm';
import { selectHasChallengeCompleted, selectIsWaitingForConfirmation, selectUserData } from '../../selectors';
import { initiateSmartIDAsync, loginSmartIDAsync, setIsWaitingForConfirmation } from '../../slice';
import { PersonalData } from '../../types/authTypes';

type Props = {
  onFailHandle: () => unknown;
};

export const SmartID: FC<Props> = ({ onFailHandle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state } = useAppLocation();
  const navigate = useNavigate();
  const { originalLocation, redirectToOriginalUrl } = state || {};

  const isWaitingForConfirmation = useAuthSelector<typeof selectIsWaitingForConfirmation>(
    selectIsWaitingForConfirmation
  );

  const [personalId, setPersonalId] = useState('');

  const { email, country } = useAuthSelector<typeof selectUserData>(selectUserData);
  const challengeCompleted = useAuthSelector<typeof selectHasChallengeCompleted>(selectHasChallengeCompleted);

  const submit = (data: PersonalData) => {
    setPersonalId(data.customerId);

    dispatch(setIsWaitingForConfirmation(true));
  };

  const initiateLogin = () => {
    const data: RequestInitSmartID = {
      pid: personalId,
      email: email,
      country: country
    };

    return dispatch(initiateSmartIDAsync(data)).unwrap();
  };

  const completeLogin = async ({ sessionId }: { sessionId?: string }) => {
    if (!sessionId) {
      return { state: 'failed' };
    }

    return dispatch(loginSmartIDAsync(sessionId)).unwrap();
  };

  const onLinkBackClick = () => {
    dispatch(setIsWaitingForConfirmation(false));
  };

  const onSuccessHandle = () => {
    if (redirectToOriginalUrl) {
      navigate(originalLocation?.pathname || '/dashboard/overview', { state });
    } else {
      navigate('/dashboard/overview');
    }
  };

  const pollingConfiguration = {
    init: initiateLogin,
    complete: completeLogin
  };

  return (
    <AuthenticationDialog
      hideBackLink
      onFailHandle={onFailHandle}
      testId="smart-id"
      isWaitingForConfirmation={!!isWaitingForConfirmation}
      onBackHandle={onLinkBackClick}
      onSuccessHandle={onSuccessHandle}
      backLinkLabel={t('back')}
      infoMessage={t('smartIdInfoMessage')}
      timerLabel={t('yourVerificationCodeWillExpireIn')}
      pollingConfig={pollingConfiguration}
      challengeCompleted={challengeCompleted}
    >
      <PersonalDataForm displayPhoneInput={false} onSubmit={submit} continueButtonLabel={t('login')} />
    </AuthenticationDialog>
  );
};
