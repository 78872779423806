import { useEffect, useRef } from 'react';

import AsideDrawer, { toggleAsideMenuByActiveDataIndex } from './asideDrawer';
import { useSwipeTillConfirmAttributeMutationObserver } from './hooks';
import { Content, FullWidthMainContent, MainContainer, Overlay, PageColumn } from './layout.styles';
import { getHighestActiveDrawerIndex, toggleAsideMenu } from './utils';

type Props = {
  asideMenuContent?: React.ReactNode;
  children?: React.ReactNode;
  topBanner?: React.ReactNode;
  rightSideDrawerOpen?: boolean;
  onDrawerStateChange?: (isOpen: boolean) => unknown;
};

const WideLayout = ({
  children,
  asideMenuContent,
  rightSideDrawerOpen,
  onDrawerStateChange,
  topBanner
}: Props) => {
  const asideDrawerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onDrawerStateChange?.(Boolean(rightSideDrawerOpen));
  }, [rightSideDrawerOpen]);

  const onSwipe = (tillConfirm: number) => {
    if (overlayRef.current) {
      if (tillConfirm) {
        overlayRef.current.style.backgroundColor = `rgba(0, 0, 0, ${Math.min(1 - tillConfirm, 0.5)})`;
      } else {
        overlayRef.current.removeAttribute('style');
      }
    }
  };

  useSwipeTillConfirmAttributeMutationObserver(asideDrawerRef.current, onSwipe);

  return (
    <PageColumn
      onClick={() => {
        if (rightSideDrawerOpen) {
          const highestActiveDrawerIndex = getHighestActiveDrawerIndex();

          if (highestActiveDrawerIndex === 0) {
            toggleAsideMenu(false);
            onDrawerStateChange?.(false);
          } else {
            toggleAsideMenuByActiveDataIndex(highestActiveDrawerIndex, false);
          }
        }
      }}
    >
      {topBanner}
      <Content>
        <MainContainer absolute>
          <FullWidthMainContent data-testid="main-content" id="full-width-main-content">
            {children}
          </FullWidthMainContent>
        </MainContainer>
        <Overlay ref={overlayRef} position="absolute" />
        <AsideDrawer ref={asideDrawerRef} isOpen={rightSideDrawerOpen} disableBodyScroll>
          {asideMenuContent}
        </AsideDrawer>
      </Content>
    </PageColumn>
  );
};

export default WideLayout;
