import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import emptyList from 'assets/images/empty-list.svg';
import { Button } from 'components/buttonV2';

import {
  Card,
  EmptyCardContentContainer,
  EmptyCardContentImage,
  EmptyCardContentText
} from './applicationList.styles';

const EmptyApplicationsList = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/new-application');
  };

  return (
    <Card {...props}>
      <EmptyCardContentContainer>
        <EmptyCardContentImage src={emptyList} alt="empty applications list" />
        <EmptyCardContentText>{t('OopsYouDonTHaveAnyApplicationsYet')}</EmptyCardContentText>
      </EmptyCardContentContainer>
      <Button
        media={{
          maxPhone: {
            size: 'L'
          }
        }}
        color="Blue"
        variant="Filled"
        onClick={handleClick}
      >
        {t('requestFinancing')}
      </Button>
    </Card>
  );
};

export default EmptyApplicationsList;
