import {
  Context,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

type Props = {
  children: ReactNode;
};

type HashTrigger = {
  hash: string;
  searchParams: Record<string, unknown>;
};

const convertSearchParamsToObject = (searchParams: URLSearchParams): Record<string, string> => {
  const obj: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    obj[key] = value;
  }

  return obj;
};

export type ModalsRegistry = Map<string, (params?: unknown) => unknown>;

type ModalContext<T = unknown> = {
  modals: ModalsRegistry;
  registerModalHash: (name: string, toggle: (params?: T) => unknown) => { unregister: () => unknown };
};

const modalContext = createContext<ModalContext>({} as ModalContext);

export function useModalContext<T = unknown>() {
  return useContext(modalContext as Context<ModalContext<T>>);
}

export const ModalContextProvider = ({ children }: Props) => {
  const [modals, setModals] = useState<ModalsRegistry>(new Map());
  const [hashTrigger, setHashTrigger] = useState<HashTrigger>({ hash: '', searchParams: {} });

  const registerModalHash = useCallback((name: string, toggle: (params?: unknown) => unknown) => {
    setModals((prev) => new Map(prev).set(name, toggle));

    return {
      unregister: () => {
        setModals((prev) => {
          const next = new Map(prev);

          next.delete(name);

          return next;
        });
      }
    };
  }, []);

  const context: ModalContext = useMemo(() => ({ modals, registerModalHash }), [modals, registerModalHash]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');

    const urlSearchParams = new URLSearchParams(location.search);

    setHashTrigger({ hash, searchParams: convertSearchParamsToObject(urlSearchParams) });
  }, [location.hash]);

  useEffect(() => {
    if (hashTrigger.hash && modals?.get(hashTrigger.hash)) {
      setHashTrigger({ hash: '', searchParams: {} });
      modals?.get(hashTrigger.hash)?.(hashTrigger.searchParams);
    }
  }, [modals, hashTrigger.hash]);

  return <modalContext.Provider value={context}>{children}</modalContext.Provider>;
};

export default modalContext;
