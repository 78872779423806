import styled, { css } from 'styled-components';

import { RouterLink } from '../link';
import { Paper } from '../paper';

const layoutRestrictions = css`
  width: 11.9375rem;
  height: 11.9375rem;
`;

export const MarketCardPaper = styled(Paper)`
  ${layoutRestrictions}
  justify-content: center;
  align-items: center;
`;

export const MarketCardContainer = styled.div`
  ${layoutRestrictions}

  ${RouterLink} {
    ${layoutRestrictions}
  }
`;

export type ImageProps = {
  imageAspect?: 'regular' | 'wide';
};

export const LogoImage = styled.img<ImageProps>`
  max-width: ${({ imageAspect }) => (imageAspect === 'regular' ? '5rem' : '100%')};
  width: 100%;
  height: auto;
`;
