import styled, { css } from 'styled-components';

export type ContainerProps = {
  transparent?: boolean;
  opaque?: boolean;
  rounded?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.colors.formElement.background};
  opacity: ${({ opaque }) => (opaque ? 0.4 : 1)};
  z-index: 99999;
  border-radius: ${({ rounded }) => (rounded ? '1.5rem' : '')};
  min-height: 2rem;
`;

export const NoteStyle = styled.p`
  color: black;
  font-size: 1.25rem;
  margin-top: 3.75rem;
`;

export const LoaderContainer = styled.div`
  position: relative;
  min-height: 2rem;
  height: 100%;
  width: 100%;
`;

export type ShimerContainerProps = {
  height?: string;
  width?: string;
  overlay?: boolean;
  invert?: boolean;
  color?: string;
};

export const ShimerContainer = styled.div<ShimerContainerProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  background-color: ${({ theme, overlay, color }) =>
    overlay ? theme.colors.primary.background : color || '#ffffff'};
  border-radius: 0.875rem;
  ${({ overlay, invert }) => {
    if (overlay) {
      return css`
        position: absolute;
        z-index: 1000;
        top: 0;
        bottom: 0;

        &::before {
          border-radius: 1rem;
          animation: flicker 1s ease-in-out infinite;
          width: 100%;
          content: '';
          position: absolute;
          z-index: 100;
          top: 0;
          bottom: 0;
          background-color: ${({ theme }) => (invert ? theme.colors.primary.background : '#ffffff')};
        }
      `;
    }

    return css`
      animation: flicker 1s ease-in-out infinite;
      background-color: ${({ theme }) => (invert ? theme.colors.primary.background : '#ffffff')};
    `;
  }}

  @keyframes flicker {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const ShimmerOnWhite = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })``;

export const TopLine = styled.div`
  width: 24px;
  height: 6px;
  background-color: #2b64f5;
  animation: top-line 1.125s linear 0s 1 normal;
  animation-iteration-count: infinite;

  @keyframes top-line {
    0% {
      // fake delay
    }

    17% {
      // fake delay
    }

    18% {
      transform: scaleY(0.66) scaleX(0.625) translate(-15%, 0);
      border-radius: 2px;
    }

    36% {
      transform: scaleY(0.66) scaleX(1.95);
      border-radius: 2px;
    }

    64% {
      transform: scaleY(0.66) scaleX(0.83) translate(-100%, 0);
      border-radius: 2px;
    }

    72% {
      transform: scaleY(0.66);
      border-radius: 2px;
    }
  }
`;

export const MiddleLine = styled.div`
  width: 15px;
  height: 6px;
  background-color: #2b64f5;
  animation: middle-line 1.125s linear 0s 1 normal;
  animation-iteration-count: infinite;

  @keyframes middle-line {
    0% {
      // fake delay
    }

    17% {
      // fake delay
    }

    18% {
      transform: scaleY(0.66) scaleX(2) translate(-15%, 0);
      border-radius: 2px;
    }

    36% {
      transform: scaleY(0.66) scaleX(1) translate(25%, 0);
      border-radius: 2px;
    }

    54% {
      transform: scaleY(0.66) scaleX(2.67) translate(35%, 0);
      border-radius: 2px;
    }

    72% {
      transform: scaleY(0.66);
      border-radius: 2px;
    }
  }
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2b64f5;
  animation: dot 1.125s linear 0s 1 normal;
  animation-iteration-count: infinite;

  @keyframes dot {
    0% {
      // fake delay
    }

    17% {
      // fake delay
    }

    18% {
      transform: scale(0.5);
    }

    36% {
      transform: scale(0.5);
    }

    54% {
      transform: scale(0.75);
    }

    64% {
      transform: scale(0.5);
    }

    90% {
      transform: scale(1);
    }
  }
`;

export type LogoProps = {
  noMargin?: boolean;
};

export const LogoLoaderContainer = styled.div<LogoProps>`
  margin-left: ${({ noMargin }) => (noMargin ? '' : '3.125rem')};
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: space-between;
`;

type SpinnerProps = {
  show?: boolean;
};

export const SpinnerContainer = styled.div<SpinnerProps>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-start;
  width: ${({ show }) => (show ? '1.75rem' : '0')};
  height: 1rem;
  transition: width 0.2s ease-in-out;
`;

export const LineContainer = styled.div`
  position: absolute;
  bottom: -4px;
  width: 100%;
  left: 0;

  .line {
    --spa-line-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
    --spa-line-speed: 2s;
    --spa-line-stroke: 0.25rem;
    --spa-line-bg-opacity: 0.1;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--spa-line-stroke);
    width: 100%;
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }

  .line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--spa-line-color);
    opacity: var(--spa-line-bg-opacity);
    transition: background-color 0.3s ease;
  }

  .line::after {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 0.875rem;
    animation: zoom var(--spa-line-speed) ease-in-out infinite;
    transform: translateX(-100%);
    background-color: var(--spa-line-color);
    transition: background-color 0.3s ease;
  }

  @keyframes zoom {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
`;

export const CheckmarkLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 2rem;
  height: 2rem;
  animation-delay: 900ms;
  animation-duration: 400ms;
  animation-timing-function: ease;
  animation-name: bump;
  transform-origin: center;
  transform: scale(1);

  @keyframes bump {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
`;

const CHECKMARK_CONTAINER_SIZE = '2rem';

const circleBeforeAfter = css`
  box-sizing: inherit;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
`;

export const CheckmarkContainerBackground = styled.div`
  --spa-checkmark-container-color: #32bf33;

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  border-radius: 100%;
  background-color: var(--spa-checkmark-container-color);
  width: 1.875rem;
  height: 1.875rem;
  z-index: 1;
`;

export const CheckmarkContainerFiller = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  width: 1.875rem;
  height: 1.875rem;
  animation-delay: 400ms;
  animation-duration: 400ms;
  animation-timing-function: ease;
  animation-name: collapse;
  animation-fill-mode: forwards;
  transform-origin: center;
  z-index: 2;

  @keyframes collapse {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }
`;

export const CheckmarkContainer = styled.div`
  --spa-checkmark-container-color: #32bf33;
  --spa-checkmark-container-border: 0.125rem;

  width: ${CHECKMARK_CONTAINER_SIZE};
  height: ${CHECKMARK_CONTAINER_SIZE};
  background-color: #ffffff;
  position: relative;
  border-radius: 100%;

  &::before {
    border: var(--spa-checkmark-container-border) solid transparent;
    ${circleBeforeAfter}
  }

  &::after {
    border: 0 solid transparent;
    ${circleBeforeAfter}
  }

  &.draw::before {
    border-top-color: var(--spa-checkmark-container-color);
    border-right-color: var(--spa-checkmark-container-color);
    border-bottom-color: var(--spa-checkmark-container-color);
    transition:
      border-top-color 0.15s linear,
      border-right-color 0.15s linear 0.1s,
      border-bottom-color 0.15s linear 0.2s;
  }

  &.draw::after {
    border-top: var(--spa-checkmark-container-border) solid var(--spa-checkmark-container-color);
    border-left-width: var(--spa-checkmark-container-border);
    border-right-width: var(--spa-checkmark-container-border);
    transform: rotate(270deg);
    transition:
      transform 0.4s linear 0s,
      border-left-width 0s linear 0.35s;
  }
`;

export const Checkmark = styled.div`
  display: flex;

  --spa-checkmark-container-size: ${CHECKMARK_CONTAINER_SIZE};
  --spa-checkmark-height: calc(var(--spa-checkmark-container-size) / 2.25);
  --spa-checkmark-stroke: 1.5px;
  --spa-checkmark-width: calc(var(--spa-checkmark-height) / 2);
  --spa-checkmark-left: calc(var(--spa-checkmark-container-size) / 4);

  &::after {
    animation-delay: 800ms;
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    animation-fill-mode: forwards;
    transform: scaleX(-1) rotate(135deg);
    opacity: 0;
    height: 0;
    width: 0;
    transform-origin: left top;
    border-right: var(--spa-checkmark-stroke) solid ${({ theme }) => theme.colors.base.white.functional};
    border-top: var(--spa-checkmark-stroke) solid ${({ theme }) => theme.colors.base.white.functional};
    content: '';
    left: var(--spa-checkmark-left);
    top: calc(var(--spa-checkmark-height) + 0.125rem);
    position: absolute;
    z-index: 3;
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }

    20% {
      height: 0;
      width: var(--spa-checkmark-width);
      opacity: 1;
    }

    40% {
      height: var(--spa-checkmark-height);
      width: var(--spa-checkmark-width);
      opacity: 1;
    }

    100% {
      height: var(--spa-checkmark-height);
      width: var(--spa-checkmark-width);
      opacity: 1;
    }
  }
`;
