import Input from './input';
import { SearchInputContainer } from './input.style';

type Props = {
  value: string;
  onChange: (searchQuery: string) => unknown;
  placeholder?: string;
  className?: string;
};

const SearchInput = ({ value, onChange, placeholder, className }: Props) => {
  return (
    <SearchInputContainer className={className}>
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        data-testid="search-input"
        required
      />
    </SearchInputContainer>
  );
};

export default SearchInput;
