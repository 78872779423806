import { FC } from 'react';

import { Section } from 'components';
import { LimitUsage, FactoringLimitChartSection } from 'features/factoring/components';
import { FactoringUpcomingPayments } from 'features/factoring/tables';

export const FactoringOverview: FC = () => {
  return (
    <>
      <Section>
        <FactoringLimitChartSection />
      </Section>
      <LimitUsage />
      <FactoringUpcomingPayments />
    </>
  );
};
