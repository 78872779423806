import { queryBuilder } from 'services/utils/queryBuilder';

import { RestClient } from '../RestClient';
import { baseUrlBuilder } from '../utils/baseUrlBuilder';

import {
  FactoringFeeDownloadRequest,
  FactoringPaymentDownloadRequest,
  FactoringStatementsGetRequest,
  FactoringStatementsGetResponse
} from './types';


const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('factoring-statements');

export class FactoringStatementsService {
  static getActs(payload: FactoringStatementsGetRequest) {
    const url = withBaseUrl('/factoring/statements');
    return restClient.GET<FactoringStatementsGetResponse>(queryBuilder(url, payload));
  }

  static getPaymentDocument(payload: FactoringPaymentDownloadRequest) {
    const url = withBaseUrl(`/factoring/statements/${payload.actId}/document`);
    return restClient.DOWNLOAD(url);
  }

  static getFeeDocument(payload: FactoringFeeDownloadRequest) {
    const url = withBaseUrl(`/factoring/statements/${payload.actId}/fee`);
    return restClient.DOWNLOAD(url);
  }
}
