import { ChangeEvent, FC } from 'react';
import { UseFormGetValues, UseFormClearErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ChipOption, ErrorMessage } from 'components';
import { FieldState } from 'components/formField/field';
import { DrawerImplementation } from 'features/application/drawers';
import { STEPS } from 'features/application/types/applicationTypes';
import { useNavContext } from 'features/nav/context/navContext';
import { FactoringAdditionalInfo } from 'services/CapService/types';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';
import { FadeInContainer } from '../purpose/purpose.styles';

import {
  AdditionalInfoButton,
  BuyersFormFieldsContainer,
  BuyersFormListItem,
  BuyersFormListItemHeader,
  BuyersFormOptions
} from './factoringTypeForm.styles';

type Props = {
  getValues: UseFormGetValues<FactoringAdditionalInfo>;
  validationHelpers: ValidationHelpers<FactoringAdditionalInfo>;
  clearErrors: UseFormClearErrors<FactoringAdditionalInfo>;
};

const isBoolean = (val?: boolean) => Boolean(val) === val;

const isInvalid = (fieldState: FieldState) =>
  Boolean(fieldState?.invalid) && Boolean(fieldState?.error?.message);

export const FactoringTypeFields: FC<Props> = ({ getValues, validationHelpers, clearErrors }) => {
  const { t } = useTranslation();
  const { setValue, getFieldState, setValueWithoutValidation } = validationHelpers || {};

  const { toggleRightSideDrawer } = useNavContext<DrawerImplementation<'stepExplanation'>>();

  const setRiskAssessmentInsurance = (value: boolean) => {
    setValue('insurancePresent', value);
    clearErrors('insurancePresent');
    setValueWithoutValidation('agreeToPayInsuranceLimitFee', undefined);
    clearErrors('agreeToPayInsuranceLimitFee');
  };
  const setInformThirdParties = (value: boolean) => setValue('informThirdParties', value);

  const onAggreeToPayInsuranceLimitFeeChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue('agreeToPayInsuranceLimitFee', target.value === 'true');
  };

  const onRiskAssessmentInsuranceChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setRiskAssessmentInsurance(target.value === 'true');
  };

  const onInformThirdPartiesChangeValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInformThirdParties(target.value === 'true');
  };

  const informThirdPartiesState = getFieldState('informThirdParties');
  const insurancePresentState = getFieldState('insurancePresent');
  const agreeToPayInsuranceLimitFeeState = getFieldState('agreeToPayInsuranceLimitFee');

  const informThirdParties = getValues('informThirdParties');
  const insurancePresent = getValues('insurancePresent');
  const agreeToPayInsuranceLimitFee = getValues('agreeToPayInsuranceLimitFee');

  const showInsuranceLimitFeeOption = isBoolean(insurancePresent) && !insurancePresent;

  const toggleStepExplanation = (sub: string) => () =>
    toggleRightSideDrawer?.({
      drawerId: 'stepExplanation',
      step: STEPS.FACTORING_TYPE,
      sub
    });

  return (
    <BuyersFormFieldsContainer>
      <BuyersFormListItem data-testid="factoringInformBuyers-header">
        <BuyersFormListItemHeader>{t('factoringInformBuyers')}</BuyersFormListItemHeader>
        <BuyersFormOptions>
          <ChipOption
            onChange={onInformThirdPartiesChangeValue}
            checked={informThirdParties}
            value="true"
            name="informThirdParties"
          >
            {t('yes')}
          </ChipOption>
          <ChipOption
            onChange={onInformThirdPartiesChangeValue}
            checked={isBoolean(informThirdParties) && !informThirdParties}
            value="false"
            name="informThirdParties"
          >
            {t('no')}
          </ChipOption>
        </BuyersFormOptions>
        <ErrorMessage hidden={!isInvalid(informThirdPartiesState)}>
          {t(informThirdPartiesState.error?.message ?? '')}
        </ErrorMessage>
      </BuyersFormListItem>
      <BuyersFormListItem>
        <BuyersFormListItemHeader data-testid="factoringInsurance-header">
          {t('factoringInsurance')}{' '}
          <AdditionalInfoButton
            data-testid="factoringInsurance-helpIcon"
            onClick={toggleStepExplanation('insurancePresent')}
          />
        </BuyersFormListItemHeader>
        <BuyersFormOptions>
          <ChipOption
            onChange={onRiskAssessmentInsuranceChange}
            checked={insurancePresent}
            value="true"
            name="haveInsurance"
          >
            {t('yes')}
          </ChipOption>
          <ChipOption
            onChange={onRiskAssessmentInsuranceChange}
            checked={isBoolean(insurancePresent) && !insurancePresent}
            value="false"
            name="haveInsurance"
          >
            {t('no')}
          </ChipOption>
        </BuyersFormOptions>
        <ErrorMessage hidden={!isInvalid(insurancePresentState)}>
          {t(insurancePresentState.error?.message ?? '')}
        </ErrorMessage>
      </BuyersFormListItem>
      {showInsuranceLimitFeeOption ? (
        <FadeInContainer>
          <BuyersFormListItem>
            <BuyersFormListItemHeader data-testid="factoringBuyerInsurance-header">
              {t('factoringBuyerInsurance')}
              <AdditionalInfoButton
                data-testid="factoringBuyerInsurance-helpIcon"
                onClick={toggleStepExplanation('insuranceLimitFee')}
              />
            </BuyersFormListItemHeader>
            <BuyersFormOptions>
              <ChipOption
                onChange={onAggreeToPayInsuranceLimitFeeChange}
                checked={agreeToPayInsuranceLimitFee}
                value="true"
                name="factoringBuyerInsurance"
              >
                {t('yes')}
              </ChipOption>
              <ChipOption
                onChange={onAggreeToPayInsuranceLimitFeeChange}
                checked={isBoolean(agreeToPayInsuranceLimitFee) && !agreeToPayInsuranceLimitFee}
                value="false"
                name="factoringBuyerInsurance"
              >
                {t('no')}
              </ChipOption>
            </BuyersFormOptions>
            <ErrorMessage hidden={!isInvalid(agreeToPayInsuranceLimitFeeState)}>
              {t(agreeToPayInsuranceLimitFeeState.error?.message ?? '')}
            </ErrorMessage>
          </BuyersFormListItem>
        </FadeInContainer>
      ) : null}
    </BuyersFormFieldsContainer>
  );
};
