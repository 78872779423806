import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';

import { RestClient } from '../RestClient';

import {
  RequestConsentCollect,
  RequestInstitutionsConsent,
  RequestInstitutionsList,
  ResponseConsentCollect,
  ResponseInstitutionsConsent,
  ResponseInstitutionsList
} from './types';

const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('statements');

export class StatementsService {
  static getInstitutions({ country }: RequestInstitutionsList) {
    const url = withBaseUrl(`/statements/institutions?country=${country}`);

    return restClient.GET<ResponseInstitutionsList>(url);
  }

  static getInsitutionConsentLink(payload: RequestInstitutionsConsent) {
    const url = withBaseUrl(`/statements/institutions`);

    return restClient.POST<RequestInstitutionsConsent, ResponseInstitutionsConsent>(url, payload);
  }

  static collectData(body: RequestConsentCollect) {
    const url = withBaseUrl(`/statements/confirmation`);

    return restClient.POST<RequestConsentCollect, ResponseConsentCollect>(url, body);
  }
}
