import { useTranslation } from 'react-i18next';

import { useFormContext } from 'features/application/context/FormContext';
import { COMPANY_REGISTRATION_COUNTRIES_LIST } from 'features/application/forms/company/constants';
import { selectCompanyCountry } from 'features/application/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { Company } from 'services/CapService/types';

import { CountriesDrawer } from '../../../app/components/countries';

type Props = {
  onSelect?: (value: string) => void;
};

const CompanyRegistrationCountry = ({ onSelect }: Props) => {
  const { t } = useTranslation();

  const selectedCountry = useApplicationSelector<typeof selectCompanyCountry>(selectCompanyCountry);

  const { hideSideDrawer, showRightSideDrawer } = useNavContext();
  const { formHandlers } = useFormContext<Company>();

  const { validationHelpers } = formHandlers ?? {};

  const handleCountrySelect = (value: string) => {
    if (onSelect) {
      onSelect(value);
    } else {
      validationHelpers?.setValue('country', value);
    }
  };

  return (
    <CountriesDrawer
      searchable={false}
      open={showRightSideDrawer}
      onClose={() => hideSideDrawer?.()}
      onSelect={handleCountrySelect}
      selectedValue={selectedCountry}
      whitelist={COMPANY_REGISTRATION_COUNTRIES_LIST.map((country) => country.value)}
      drawerHeader={t('placeholderCompanyCountry')}
    />
  );
};

export default CompanyRegistrationCountry;
