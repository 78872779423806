import styled from 'styled-components';

import { LinkButtonStyle, Paper, Typography } from 'components';
import { SpinnerContainer } from 'components/loader/loader.styles';
import { InputViewContainer, RegularText, SecondaryText } from 'components/text/text.styles';
import { InputLabel } from 'components/typography/typography.styles';

export const InvoiceDetailsCard = styled(Paper)`
  padding: 1.5rem 2rem;
  height: 100%;
`;

export const InvoiceTotalsCard = styled(InvoiceDetailsCard)`
  background: ${({ theme }) => theme.colors.base.primary.shades[5]};
  border: 0.0625rem solid #95b2fa;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })`
  margin-bottom: 1rem;
`;

export const FeesAndTransactionsTitle = styled(Typography.Header).attrs({ type: 'h3', weight: 'normal' })``;

export const Status = styled.span`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    flex-direction: column;
  }
`;

export const TransactionDetails = styled.div`
  display: flex;
  margin-bottom: 1rem;

  ${InputLabel} {
    margin-bottom: 0.6rem;
  }

  ${LinkButtonStyle} {
    margin-left: 1.5rem;

    ${SpinnerContainer} {
      display: none;
    }
  }

  .payout-amount {
    align-self: flex-end;
    margin-left: auto;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GroupedItems = styled.div`
  display: flex;
  flex-direction: column;

  ${SecondaryText} {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  ${TransactionDetails} {
    margin-bottom: 0.5rem;

    ${RegularText} {
      max-width: 6.5rem;
      width: 6.5rem;
    }

    @media ${({ theme }) => theme.media.maxTablet} {
      ${RegularText} {
        font-size: 0.875rem;
        max-width: 5.5rem;
        width: 5.5rem;
      }
    }
  }
`;

export const TotalsSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.border.light};

  ${InputViewContainer} {
    justify-content: flex-end;
    margin-top: 0.5rem;

    ${InputLabel} {
      margin-right: 1.5rem;
    }
  }

  .payouts-amount {
    color: ${({ theme }) => theme.colors.base.green.shades[100]};
  }

  .fees-amount {
    color: ${({ theme }) => theme.colors.base.red.shades[100]};
  }
`;
