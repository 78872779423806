import {
  ActionCreator,
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch
} from '@reduxjs/toolkit';

import { resetAppState } from 'features/app/slice';
import { resetCapApiState } from 'features/application/api';
import { selectContactPerson } from 'features/application/selectors';
import { resetApplicationState, setApplicantData } from 'features/application/slice';
import { selectActiveCompany } from 'features/applications/selectors';
import { resetBankState } from 'features/bank/slice';
import { resetContractsApiState } from 'features/contracts/api';
import { resetContractsState } from 'features/contracts/slice';
import { resetFactoringInvoicesApiState } from 'features/factoringInvoices/slice';
import { resetFactoringStatementsApiState } from 'features/factoringStatements/api';
import { resetFactoringStatementsState } from 'features/factoringStatements/slice';
import { resetInsightsApiState } from 'features/insights/api';
import { resetInvoicesApiState } from 'features/invoices/api';
import { resetInvoicesState } from 'features/invoices/slice';
import { resetInvoicesVerficationApiState } from 'features/invoicesVerification/api';
import { resetInvoicesVerificationState } from 'features/invoicesVerification/slice';
import { resetLoansApiState } from 'features/loans/api';
import { resetLoansState } from 'features/loans/slice';
import { resetOnboardingApiState } from 'features/onboarding/api';
import { resetStatementsState } from 'features/statements/slice';
import { resetUsersApiState } from 'features/users/slice';
import { LoginService, UserUpdateService } from 'services';
import {
  CustomerInfo,
  RequestCompleteEIDeasy,
  RequestCompleteMobileID,
  RequestCompleteRefresh,
  RequestCompleteSmartID,
  RequestCompleteSMSOTP,
  RequestCompleteSSO,
  RequestInitEIDeasy,
  RequestInitMobileID,
  RequestInitSmartID,
  RequestInitSMSOTP,
  RequestPollEIDeasy,
  RequestValidateUser,
  ResponseCompleteSmartID
} from 'services/LoginService/typings';
import { RequestUpdateUserPID } from 'services/UserUpdateService/typings';
import { RootState } from 'store';
import trackers from 'utils/tracking';

import { SSOLoginRequest } from './methods/ssoMobile/ssoMobile';
import { selectUserData } from './selectors';
import { AuthState, Company, NewCustomerEmail, NewCustomerOTP, UserData } from './types/authTypes';
import { clearAuthTokens, saveAuthTokens } from './utils';

const sleep = (ms = 1400) => new Promise((resolve) => setTimeout(resolve, ms));

const initialState: AuthState = {
  isLoading: false,
  isGhostUser: false,
  identityStepUp: false,
  isUserAuthenticated: false,
  isUserPartiallyAuthenticated: false,
  isOTPInitiated: false,
  sessionId: '',
  userData: {},
  personalInformationExists: false,
  isMobileAppWebview: false,
  validatingUserEmail: false,
  challengeLoginFailed: false,
  chalengeCompleted: false
};

const challengeAuthRefreshSuccessExtraReducer = (
  state: AuthState,
  response: ResponseCompleteSmartID,
  request: Partial<SSOLoginRequest>
) => {
  if (response) {
    if (response.customerInfo) {
      state.customerInformation = response.customerInfo;

      const selectedUserId = response.customerInfo?.customerCrmId;

      const selectedCustomer = response.customerInfo?.customers?.find(
        (customer) => customer.customerCrmId === selectedUserId
      );

      if (selectedCustomer) {
        state.activeCustomer = selectedCustomer;
      }

      state.userData = {
        email: response.customerInfo?.userId || request.email
      };

      const isGhostUser = !response.customerInfo?.userId;

      state.isGhostUser = isGhostUser;

      trackers.setUserType(isGhostUser);

      saveAuthTokens(response);
      state.waitingForConfirmation = false;
      state.isUserAuthenticated = true;
      state.verificationCode = '';
      state.identityStepUp = false;
    }
  }
};

const challengeAuthSuccessExtraReducer = (
  state: AuthState,
  action: PayloadAction<ResponseCompleteSmartID>
) => {
  if (action.payload) {
    if (action.payload.state === 'ok' || action.payload.state === 'COMPLETE') {
      if (action.payload.customerInfo) {
        state.customerInformation = action.payload.customerInfo;

        const selectedUserId = action.payload.customerInfo?.customerCrmId;

        const selectedCustomer = action.payload.customerInfo?.customers?.find(
          (customer) => customer.customerCrmId === selectedUserId
        );

        if (selectedCustomer) {
          state.activeCustomer = selectedCustomer;
        }
      }

      saveAuthTokens(action.payload);
      state.waitingForConfirmation = false;
      state.isUserAuthenticated = true;
      state.verificationCode = '';
    }
  } else {
    return initialState;
  }
};

const challengeAuthErrorExtraReducer = (state: AuthState) => {
  state.waitingForConfirmation = false;
  state.isUserAuthenticated = false;
  state.challengeLoginFailed = true;
  state.verificationCode = '';
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    setCustomerInformation: (state, action: PayloadAction<CustomerInfo>) => {
      state.customerInformation = action.payload;
    },
    appendCustomerInformation: (state, action: PayloadAction<CustomerInfo>) => {
      if (state.customerInformation) {
        state.customerInformation.customers = [
          ...(state.customerInformation.customers || []),
          action.payload
        ];
      }
    },
    patchCustomerInformation: (
      state,
      action: PayloadAction<CustomerInfo & { referenceCustomerId: string }>
    ) => {
      if (state.customerInformation) {
        const customers = state.customerInformation.customers || [];

        const updatedCustomers = customers.map((customer) => {
          if (customer.customerId === action.payload.customerId) {
            return {
              ...customer,
              ...action.payload
            };
          }

          return customer;
        });

        if (state.activeCustomer?.customerId === action.payload.referenceCustomerId) {
          state.activeCustomer = {
            ...state.activeCustomer,
            ...action.payload
          };
        }

        state.customerInformation = {
          ...state.customerInformation,
          ...action.payload,
          customers: updatedCustomers
        };

        state.activeCustomer = {
          ...state.activeCustomer,
          customerName: action.payload.customerName,
          customerCrmId: action.payload.customerCrmId
        };

        if (state.activeCompany) {
          state.activeCompany = {
            ...state.activeCompany,
            name: action.payload.customerName ?? ''
          };
        }
      }
    },
    setChallengeCompleted: (state, action: PayloadAction<boolean>) => {
      state.chalengeCompleted = action.payload;
    },
    setActiveCustomer: (state, action: PayloadAction<CustomerInfo>) => {
      state.activeCustomer = action.payload;
    },
    setActiveCustomerById: (state, action: PayloadAction<string>) => {
      const activeCustomer = state.customerInformation?.customers?.find(
        (customer) => customer.customerId === action.payload
      );

      if (activeCustomer) {
        state.activeCustomer = activeCustomer;
      }
    },
    setActiveCompany: (state, action: PayloadAction<Company | undefined>) => {
      state.activeCompany = action.payload;
    },
    setUserType: (state, action: PayloadAction<boolean>) => {
      state.isGhostUser = action.payload;
      trackers.setUserType(action.payload);
    },
    setIsUserAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isUserAuthenticated = action.payload;
    },
    setIsWaitingForConfirmation: (state, action: PayloadAction<boolean>) => {
      state.waitingForConfirmation = action.payload;
    },
    setIsRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.isRefreshTokenLoading = action.payload;
    },
    setAgreementAccepted: (state, action: PayloadAction<boolean>) => {
      if (state.customerInformation) {
        state.customerInformation.userAgreementAccepted = action.payload;
      }
    },
    setIdentityStepUp: (state, action: PayloadAction<boolean>) => {
      state.identityStepUp = action.payload;
    },
    setChallengeLoginFailed: (state) => {
      state.waitingForConfirmation = false;
      state.isUserAuthenticated = false;
      state.verificationCode = '';
      state.identityStepUp = false;
    },
    resetAuthState: (state) => {
      if (state.identityStepUp) {
        return {
          ...initialState,
          userData: state.userData,
          identityStepUp: true
        };
      }

      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateOTPAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initiateOTPAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isOTPInitiated = true;
        state.sessionId = action.payload.sessionId;
      })
      .addCase(initiateOTPAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(loginOTPAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginOTPAsync.fulfilled, (state, action) => {
        state.isGhostUser = true;
        trackers.setUserType(true);
        state.isUserAuthenticated = action.payload.fullyQualifiedLogin;
        state.isUserPartiallyAuthenticated = !action.payload.fullyQualifiedLogin;

        if (action.payload) {
          saveAuthTokens(action.payload);
        }

        state.isLoading = false;
        state.isOTPInitiated = false;
        state.identityStepUp = false;
      })
      .addCase(loginOTPAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(loginSSOAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginSSOAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        challengeAuthSuccessExtraReducer(state, action);
      })
      .addCase(loginSSOAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(refreshMobileSSOTokenAsync.pending, (state) => {
        state.isRefreshTokenLoading = true;
      })
      .addCase(refreshMobileSSOTokenAsync.fulfilled, (state, action) => {
        state.isRefreshTokenLoading = false;
        state.isMobileAppWebview = true;
        challengeAuthRefreshSuccessExtraReducer(state, action.payload.response, action.payload.request);
      })
      .addCase(refreshMobileSSOTokenAsync.rejected, (state) => {
        state.isRefreshTokenLoading = false;
      });

    builder
      .addCase(initiateSmartIDAsync.pending, (state) => {
        state.isLoading = true;
        state.waitingForConfirmation = true;
      })
      .addCase(initiateSmartIDAsync.fulfilled, (state, action) => {
        state.verificationCode = action.payload.verificationCode;
        state.sessionId = action.payload.sessionId;
      })
      .addCase(initiateSmartIDAsync.rejected, (state) => {
        state.isLoading = false;
        state.waitingForConfirmation = false;
      });

    builder
      .addCase(loginSmartIDAsync.pending, (state) => {
        state.waitingForConfirmation = true;
      })
      .addCase(loginSmartIDAsync.fulfilled, challengeAuthSuccessExtraReducer)
      .addCase(loginSmartIDAsync.rejected, challengeAuthErrorExtraReducer);

    builder
      .addCase(initiateMobileIDAsync.pending, (state) => {
        state.isLoading = true;
        state.waitingForConfirmation = true;
      })
      .addCase(initiateMobileIDAsync.fulfilled, (state, action) => {
        state.verificationCode = action.payload.verificationCode;
        state.sessionId = action.payload.sessionId;
      })
      .addCase(initiateMobileIDAsync.rejected, (state) => {
        state.isLoading = false;
        state.waitingForConfirmation = false;
      });

    builder
      .addCase(initEIDeasyAsync.pending, (state) => {
        state.isLoading = true;
        state.waitingForConfirmation = true;
      })
      .addCase(initEIDeasyAsync.fulfilled, (state, action) => {
        state.htmlContent = action.payload.base64Content || '';
        state.redirectSessionId = action.payload.sessionId || '';
        state.sessionId = action.payload.sessionId;
      })
      .addCase(initEIDeasyAsync.rejected, (state) => {
        state.isLoading = false;
        state.waitingForConfirmation = false;
      });

    builder
      .addCase(loginMobileIDAsync.pending, (state) => {
        state.waitingForConfirmation = true;
      })
      .addCase(loginMobileIDAsync.fulfilled, challengeAuthSuccessExtraReducer)
      .addCase(loginMobileIDAsync.rejected, challengeAuthErrorExtraReducer);

    builder
      .addCase(checkStatusEIDeasyAsync.pending, (state) => {
        state.waitingForConfirmation = true;
      })
      .addCase(checkStatusEIDeasyAsync.fulfilled, (state, action) => {
        const response = action.payload;

        if (response) {
          if (response.customerInfo) {
            state.customerInformation = response.customerInfo;

            const selectedUserId = response.customerInfo?.customerCrmId;

            const selectedCustomer = response.customerInfo?.customers?.find(
              (customer) => customer.customerCrmId === selectedUserId
            );

            if (selectedCustomer) {
              state.activeCustomer = selectedCustomer;
            }

            state.userData = {
              email: response.customerInfo?.userId
            };

            state.isGhostUser = false;
            trackers.setUserType(false);

            saveAuthTokens(response);
            state.isUserAuthenticated = true;
            state.waitingForConfirmation = false;
            state.verificationCode = '';
            state.identityStepUp = false;
          }
        }
      })
      .addCase(checkStatusEIDeasyAsync.rejected, challengeAuthErrorExtraReducer);
  }
});

export const validateUserEmail = createAsyncThunk(
  'auth/validateUserEmail',
  async (request: RequestValidateUser) => {
    return await LoginService.validateByUserEmail(request);
  }
);

export const initiateSmartIDAsync = createAsyncThunk(
  'auth/initiateSmartID',
  async (request: RequestInitSmartID) => {
    return await LoginService.initiateSmartID(request);
  }
);

export const loginSmartIDAsync = createAsyncThunk(
  'auth/loginSmartID',
  async (sessionId: RequestCompleteSmartID['sessionId'], { dispatch, rejectWithValue }) => {
    try {
      const response = await LoginService.completeSmartID(sessionId);

      if (response?.state === 'ok' && response?.accessToken) {
        dispatch(setChallengeCompleted(true));
      }

      // gives time for login animations to complete before redirecting
      await sleep();

      return response;
    } catch (error) {
      return rejectWithValue(error); // Make sure 'error' here is an Axios error
    }
  }
);

export const initiateMobileIDAsync = createAsyncThunk(
  'auth/initiateMobileID',
  async (request: RequestInitMobileID) => {
    return await LoginService.initiateMobileID(request);
  }
);

export const loginMobileIDAsync = createAsyncThunk(
  'auth/loginMobileID',
  async (request: RequestCompleteMobileID, { dispatch }) => {
    const { sessionId } = request || {};
    const response = await LoginService.completeMobileID(sessionId);

    if (response?.state === 'ok' && response?.accessToken) {
      dispatch(setChallengeCompleted(true));
    }

    // gives time for login animations to complete before redirecting
    await sleep();

    return response;
  }
);

export const initiateOTPAsync = createAsyncThunk(
  'auth/initiateOTP',
  async (newCustomerEmail: NewCustomerEmail) => {
    return await LoginService.initiateOTP(newCustomerEmail);
  }
);

export const initiateVerifyOTPAsync = createAsyncThunk(
  'auth/initiateVerifyOTP',
  async (newCustomerEmail: NewCustomerEmail) => {
    return await LoginService.initiateOTP(newCustomerEmail);
  }
);

export const initiateVerifySMSOTPAsync = createAsyncThunk(
  'auth/initiateVerifySMSOTP',
  async (payload: RequestInitSMSOTP) => {
    return await LoginService.initiateSMSOTP(payload);
  }
);

export const verifySMSOTPAsync = createAsyncThunk(
  'auth/verifySMSOTP',
  async (payload: RequestCompleteSMSOTP) => {
    return await LoginService.completeOTP(payload);
  }
);

export const loginOTPAsync = createAsyncThunk(
  'auth/loginOTP',
  async (newCustomerOTP: NewCustomerOTP, { getState }) => {
    const state = getState() as RootState;
    const { sessionId } = state.auth;
    const { password, fullyQualifiedLogin } = newCustomerOTP || {};

    const response = await LoginService.completeOTP({ password, sessionId });

    return { ...response, fullyQualifiedLogin };
  }
);

export const verifyOTPAsync = createAsyncThunk(
  'auth/verifyOTP',
  async (verificationOTP: Omit<NewCustomerOTP, 'fullyQualifiedLogin'>) => {
    const { sessionId } = verificationOTP || {};
    const { password } = verificationOTP || {};

    const response = await LoginService.completeOTP({ password, sessionId });

    return response;
  }
);

export const loginSSOAsync = createAsyncThunk('auth/loginSSO', async (headers: RequestCompleteSSO) => {
  const response = await LoginService.completeSSO(headers);

  return response;
});

export const initEIDeasyAsync = createAsyncThunk(
  'auth/initEIDeasyAsync',
  async (payload: RequestInitEIDeasy) => {
    return await LoginService.initEIDeasy(payload);
  }
);

export const checkStatusEIDeasyAsync = createAsyncThunk(
  'auth/pollEIDeasyAsync',
  async (payload: RequestPollEIDeasy) => {
    return await LoginService.checkStatusEIDeasy(payload);
  }
);

export const completeEIDeasyAsync = createAsyncThunk(
  'auth/completeEIDeasyAsync',
  async (payload: RequestCompleteEIDeasy) => {
    return await LoginService.completeEIDeasy(payload);
  }
);

export const refreshMobileSSOTokenAsync = createAsyncThunk(
  'auth/refreshToken',
  async ({ headers, request }: { headers: RequestCompleteRefresh; request: SSOLoginRequest }) => {
    const response = await LoginService.refreshMobileToken(headers);

    return { response, request };
  }
);

export const updatePIDAsync = createAsyncThunk(
  'auth/updatePIDAsync',
  async (payload: RequestUpdateUserPID) => {
    return await UserUpdateService.updatePID(payload);
  }
);

type LogoutThunkParams =
  | {
      identityStepUp?: boolean;
    }
  | undefined;

type ResetStatesActions = Record<keyof RootState, ActionCreator<AnyAction>>;

const createStateResetTrigger =
  (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => (resetActions: ResetStatesActions) => {
    Object.values(resetActions).forEach((action) => dispatch(action()));
  };

export const logoutAsync = createAsyncThunk(
  'auth/logout',
  async (params: LogoutThunkParams = {}, { getState, dispatch }) => {
    const { identityStepUp } = params;

    const rootState = getState() as RootState;

    const triggerStateReset = createStateResetTrigger(dispatch);

    triggerStateReset({
      auth: resetAuthState,
      app: resetAppState,
      application: resetApplicationState,
      bank: resetBankState,
      contracts: resetContractsState,
      factoringContractsApi: resetContractsApiState,
      factoringStatements: resetFactoringStatementsState,
      factoringStatementsApi: resetFactoringStatementsApiState,
      invoices: resetInvoicesState,
      invoicesVerificationStatuses: resetInvoicesVerificationState,
      invoicesVerificationApi: resetInvoicesVerficationApiState,
      loansApi: resetLoansApiState,
      invoicesApi: resetInvoicesApiState,
      loans: resetLoansState,
      statements: resetStatementsState,
      users: resetUsersApiState,
      capApi: resetCapApiState,
      factoringInvoices: resetFactoringInvoicesApiState,
      insightsApi: resetInsightsApiState,
      onboardingApi: resetOnboardingApiState
    });

    clearAuthTokens();

    /** preserve user data after identity setup */
    if (identityStepUp) {
      const contactPerson = selectContactPerson({
        ...rootState.application
      });
      const userData = selectUserData(rootState.auth);
      const activeCompany = selectActiveCompany(rootState);

      dispatch(setApplicantData(contactPerson));
      dispatch(setActiveCompany(activeCompany));
      dispatch(setUserData(userData));
    }

    dispatch(setIdentityStepUp(Boolean(identityStepUp)));

    return await LoginService.logout();
  }
);

export const {
  setUserData,
  setUserType,
  setIsUserAuthenticated,
  setCustomerInformation,
  setIsWaitingForConfirmation,
  setIdentityStepUp,
  setActiveCustomer,
  setActiveCompany,
  resetAuthState,
  appendCustomerInformation,
  setIsRefreshingToken,
  patchCustomerInformation,
  setActiveCustomerById,
  setAgreementAccepted,
  setChallengeLoginFailed,
  setChallengeCompleted
} = authSlice.actions;

export default authSlice.reducer;
