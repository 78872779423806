import * as Yup from 'yup';

import { VALIDATION_MESSAGE_ID } from './types';

const string100RequiredMessage = (message: string) =>
  Yup.string()
    .typeError(message)
    .required(message)
    .min(1, VALIDATION_MESSAGE_ID.MIN_LENGTH)
    .max(100, VALIDATION_MESSAGE_ID.MAX_LENGTH);

const string15RequiredMessage = (message: string) =>
  Yup.string()
    .typeError(message)
    .required(message)
    .min(1, VALIDATION_MESSAGE_ID.MIN_LENGTH)
    .max(15, VALIDATION_MESSAGE_ID.MAX_LENGTH);

const phoneNumber15RequiredMessage = (message: string) =>
  Yup.string()
    .typeError(message)
    .required(message)
    .min(5, VALIDATION_MESSAGE_ID.MIN_LENGTH)
    .max(15, VALIDATION_MESSAGE_ID.MAX_LENGTH);

const string100Required = string100RequiredMessage(VALIDATION_MESSAGE_ID.REQUIRED);
const string15Required = string15RequiredMessage(VALIDATION_MESSAGE_ID.REQUIRED);

export const personNameRequired = string100Required;
export const companyNameRequired = string100Required;

export const personalCodeRequired = string15Required;
export const companyCodeRequired = string15Required;

const phoneNumber15Required = phoneNumber15RequiredMessage(VALIDATION_MESSAGE_ID.REQUIRED);

export const personNameRequiredMessage = (message: string) => string100RequiredMessage(message);
export const companyNameRequiredMessage = (message: string) => string100RequiredMessage(message);

export const personalCodeRequiredMessage = (message: string) => string15RequiredMessage(message);
export const companyCodeRequiredMessage = (message: string) => string15RequiredMessage(message);

export const emailRequiredMessage = (message: string) =>
  Yup.string().required(message).email(VALIDATION_MESSAGE_ID.EMAIL);

export const emailRequired = emailRequiredMessage(VALIDATION_MESSAGE_ID.REQUIRED);

export const phoneNumberRequired = phoneNumber15Required;
export const phoneNumberRequiredMessage = (message: string) => phoneNumber15RequiredMessage(message);

export const jobTitleRequired = Yup.string()
  .required(VALIDATION_MESSAGE_ID.REQUIRED)
  .min(3, VALIDATION_MESSAGE_ID.MIN_LENGTH)
  .max(100, VALIDATION_MESSAGE_ID.MAX_LENGTH);
