import styled from 'styled-components';

import { SecondaryText } from '../text';

type Props = {
  checked?: boolean;
};

export const FakeToggleInput = styled.span<Props>`
  display: inline-flex;
  align-items: center;
  width: 2.5rem;
  height: 1.25rem;
  border-radius: 6.25rem;
  background-color: #cccccc;
  position: relative;

  &::after {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6.25rem;
    background-color: ${({ theme }) => theme.colors.primary.linkText};
    position: absolute;
    left: ${({ checked }) => (checked ? 0 : '1rem')};
    transition: left 200ms linear;
  }
`;

export const ToggleContainerStyle = styled.div`
  ${FakeToggleInput} {
    margin: 0 0.625rem;
  }

  label {
    display: inline-flex;
  }

  input {
    display: none;
  }
`;

export const ToggleLabel = styled(SecondaryText)<Props>`
  ${({ checked, theme }) => (checked ? `color: ${theme.colors.primary.linkText}` : '')}
`;
