import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Field, Fieldset, Input, PhoneField } from 'components';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector } from 'hooks/redux/hooks';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { selectUserData } from '../../selectors';
import { PersonalData } from '../../types/authTypes';

import { PersonalDataFormContainer, FormButton } from './personalDataForm.styles';

type Props = {
  displayPhoneInput: boolean;
  onSubmit?: (data: FormFields) => unknown;
  continueButtonLabel?: string;
  requiredPhoneInput?: boolean;
};

type FormFields = PersonalData & { validatePhoneInput: boolean };

const initialValues: FormFields = {
  customerId: '',
  phone: '',
  validatePhoneInput: true
};

const validationSchema = Yup.object({
  customerId: Yup.string()
    .length(11, VALIDATION_MESSAGE_ID.PERSONAL_CODE_LENGTH)
    .required(VALIDATION_MESSAGE_ID.REQUIRED),
  phone: Yup.string().when('validatePhoneInput', function (validatePhoneInput: boolean) {
    if (validatePhoneInput) {
      return Yup.string()
        .min(11, VALIDATION_MESSAGE_ID.PHONE_NUMBER_LENGTH)
        .max(12, VALIDATION_MESSAGE_ID.PHONE_NUMBER_LENGTH)
        .required(VALIDATION_MESSAGE_ID.REQUIRED);
    } else {
      return Yup.string().optional();
    }
  })
});

const PersonalDataForm: FC<Props> = ({
  continueButtonLabel,
  displayPhoneInput,
  onSubmit,
  requiredPhoneInput = true
}) => {
  const disabled = !onSubmit;
  const required = displayPhoneInput ? requiredPhoneInput : false;

  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({ ...initialValues, validatePhoneInput: required });

  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers<FormFields>(
    formValues,
    validationSchema
  );

  useEffect(() => {
    setFormValues({ ...initialValues, validatePhoneInput: required });
  }, [required]);

  const onHandleSubmit = async () => {
    await handleSubmit(
      (data: FormFields) => {
        onSubmit?.(data);
      },
      // on invalid case
      (err) => {
        console.error(err);
        setTouchedOnAll();
      }
    )();
  };

  return (
    <PersonalDataFormContainer>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onHandleSubmit();
        }}
      >
        <Fieldset>
          <Field
            focused
            Component={Input}
            name="customerId"
            type="number"
            maxDecimals={0}
            value={getValues('customerId')}
            placeholder={t('placeholderNationalId')}
            validationHelpers={validationHelpers}
            required
          />
          {displayPhoneInput ? (
            <Field
              Component={PhoneField}
              name="phone"
              value={getValues('phone')}
              placeholder={t('phone')}
              defaultCountry={userData.country}
              validationHelpers={validationHelpers}
              required={required}
            />
          ) : null}
        </Fieldset>
        <FormButton
          data-testid={`button-${continueButtonLabel || 'continue'}`}
          disabled={disabled}
          onClick={onHandleSubmit}
        >
          {t(continueButtonLabel || 'continue')}
        </FormButton>
      </form>
    </PersonalDataFormContainer>
  );
};

export default PersonalDataForm;
