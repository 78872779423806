import styled from 'styled-components';

import { LinkButton } from '../link';

type Props = {
  noFill?: boolean;
};

export const DownloadLinkStyle = styled(LinkButton)<Props>`
  svg {
    fill: ${({ noFill }) => (noFill ? 'none' : 'currentColor')};
  }
`;
