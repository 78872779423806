import React, { FC, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import Icons from '../../assets/icons';
import doc from '../../assets/pictograms/docSmallExtra.svg';
import excel from '../../assets/pictograms/excelSmallExtra.svg';
import image from '../../assets/pictograms/imageSmallExtra.svg';
import pdf from '../../assets/pictograms/pdfSmallExtra.svg';
import unknown from '../../assets/pictograms/unknownSmallExtra.svg';
import { FileViewer } from '../filePreview';
import { filePictograms } from '../filePreview/previewHeader';
import { LinkButton } from '../link';
import { Spinner } from '../loader';

import { ChipButton, ChipLabel, Filename, HiddenImage } from './uploadArea.styles';

type Props = {
  filename: string;
  index?: number;
  error?: boolean;
  formatFilename?: (filename: string) => string;
  handleDelete?: (filename: string) => unknown;
  onPreview?: (filename: string) => Promise<unknown>;
  file?: File | Blob;
  loading?: boolean;
  chipNode?: ReactNode;
  dataTestId?: string;
};

const pictograms = {
  excel,
  doc,
  unknown,
  pdf,
  image
};

export const FileChip: FC<Props> = ({
  filename,
  index,
  error,
  formatFilename,
  handleDelete,
  onPreview,
  file,
  chipNode,
  loading,
  dataTestId
}) => {
  const [fileRef, setFileRef] = useState<Blob | null>(file || null);
  const [isLoading, setIsLoading] = useState(loading);
  const [showPreview, setShowPreview] = useState(false);

  const handleFilePreview = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      if (!fileRef) {
        if (file) {
          setFileRef(file);
        } else if (onPreview) {
          const response = (await onPreview(filename)) as unknown as { data: Blob };

          setFileRef(response.data);
        }
      }

      setShowPreview(true);
    } catch {
      console.error('Error while reading file');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteHandler = async (e: SyntheticEvent<HTMLButtonElement>, filename: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (isLoading) return;

    setIsLoading(true);
    await handleDelete?.(filename);
    setIsLoading(false);
  };

  useEffect(() => {
    if (loading !== isLoading) {
      setIsLoading(loading);
    }
  }, [loading]);

  const chip = chipNode ?? (
    <ChipLabel
      data-testid={`file-chip-${filename}-status-${error ? 'error' : 'success'}`}
      htmlFor={filename}
      error={error}
      removable={Boolean(handleDelete)}
    >
      <Spinner timeout={0} display={isLoading} />
      <img src={filePictograms(filename, pictograms)} alt="" />
      <HiddenImage title={filename} alt={filename} id={filename} />
      <React.Fragment key={index}>
        <Filename>{formatFilename?.(filename) || filename}</Filename>
        {handleDelete && (
          <LinkButton onClick={(e) => deleteHandler(e, filename)}>
            <Icons.Close />
          </LinkButton>
        )}
      </React.Fragment>
    </ChipLabel>
  );

  if (onPreview || file) {
    return (
      <>
        <ChipButton data-testid={dataTestId} onClick={handleFilePreview}>
          {chip}
        </ChipButton>
        {showPreview &&
          fileRef &&
          ReactDOM.createPortal(
            <FileViewer onClose={() => setShowPreview(false)} file={fileRef} filename={filename} />,
            document.body
          )}
      </>
    );
  }

  return <>{chip}</>;
};
