import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';

import { RestClient } from '../RestClient';

import {
  RequestInitSmartID,
  ResponseInitSmartID,
  RequestCompleteSmartID,
  ResponseCompleteSmartID,
  RequestInitOTP,
  ResponseInitOTP,
  RequestCompleteOTP,
  ResponseCompleteOTP,
  ResponseCompleteSSO,
  RequestCompleteSSO,
  RequestCompleteMobileID,
  ResponseCompleteMobileID,
  RequestInitMobileID,
  ResponseInitMobileID,
  RequestValidateUser,
  ResponseValidateUser,
  RequestCompleteRefresh,
  ResponseCompleteRefresh,
  RequestInitSMSOTP,
  ResponseInitSMSOTP,
  RequestCompleteSMSOTP,
  ResponseCompleteSMSOTP,
  RequestInitEIDeasy,
  ResponseInitEIDeasy,
  RequestCompleteEIDeasy,
  ResponseCompleteEIDeasy,
  RequestPollEIDeasy,
  ResponsePollEIDeasy
} from './typings';

const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('login');

const loginEndpointsConfig = {
  skipAuthRefresh: true
};

export class LoginService {
  static abortController: AbortController | null = null;
  static counter = 0;

  static initiateOTP(newCustomerData: RequestInitOTP) {
    const url = withBaseUrl('/otp/email/initiate');
    return restClient.POST<RequestInitOTP, ResponseInitOTP>(url, newCustomerData, loginEndpointsConfig);
  }

  static async completeOTP(newCustomerData: RequestCompleteOTP) {
    const url = withBaseUrl('/otp/email/login');
    return await restClient.POST<RequestCompleteOTP, ResponseCompleteOTP>(
      url,
      newCustomerData,
      loginEndpointsConfig
    );
  }

  static initiateSMSOTP(newCustomerData: RequestInitSMSOTP) {
    const url = withBaseUrl('/otp/sms/initiate');
    return restClient.POST<RequestInitSMSOTP, ResponseInitSMSOTP>(url, newCustomerData, loginEndpointsConfig);
  }

  static async completeSMSOTP(newCustomerData: RequestCompleteSMSOTP) {
    const url = withBaseUrl('/otp/sms/login');
    return await restClient.POST<RequestCompleteSMSOTP, ResponseCompleteSMSOTP>(
      url,
      newCustomerData,
      loginEndpointsConfig
    );
  }

  static async initiateSmartID(data: RequestInitSmartID) {
    this.counter = 0;
    this.abortController = new AbortController();

    const url = withBaseUrl('/smartid', 2);
    return await restClient.POST<RequestInitSmartID, ResponseInitSmartID>(url, data, {
      signal: this.abortController.signal,
      ...loginEndpointsConfig
    });
  }

  static async completeSmartID(sessionId: RequestCompleteSmartID['sessionId']) {
    this.abortController = new AbortController();
    this.counter++;

    const url = withBaseUrl(`/smartid/${sessionId}`, 2);
    return await restClient.GET<ResponseCompleteSmartID>(url, {
      signal: this.abortController.signal,
      ...loginEndpointsConfig,
      headers: {
        'x-attempts': this.counter.toString()
      }
    });
  }

  static async initiateMobileID(data: RequestInitMobileID) {
    this.abortController = new AbortController();

    const url = withBaseUrl('/mobileid', 2);
    return await restClient.POST<RequestInitMobileID, ResponseInitMobileID>(url, data, {
      signal: this.abortController.signal,
      ...loginEndpointsConfig
    });
  }

  static async completeMobileID(sessionId: RequestCompleteMobileID['sessionId']) {
    this.abortController = new AbortController();

    const url = withBaseUrl(`/mobileid/${sessionId}`, 2);
    return await restClient.GET<ResponseCompleteMobileID>(url, {
      signal: this.abortController.signal,
      ...loginEndpointsConfig
    });
  }

  static async completeSSO(headers: RequestCompleteSSO) {
    const url = '/api/login/token/sso';
    return await restClient.GET<ResponseCompleteSSO>(url, { headers, ...loginEndpointsConfig });
  }

  static async refreshMobileToken(headers: RequestCompleteRefresh) {
    const url = '/api/login-mobile/v1/token/refresh';
    return await restClient.GET<ResponseCompleteRefresh>(url, { headers, ...loginEndpointsConfig });
  }

  static async refreshOnboardingToken(headers: RequestCompleteRefresh) {
    const url = '/api/login/token/refresh';
    return await restClient.GET<ResponseCompleteRefresh>(url, { headers, ...loginEndpointsConfig });
  }

  static async validateByUserEmail(request: RequestValidateUser) {
    const url = withBaseUrl(`/validation/user/${request.user}`);
    return await restClient.GET<ResponseValidateUser>(url, loginEndpointsConfig);
  }

  static async logout() {
    const url = '/api/login/logout';
    return await restClient.POST<object, unknown>(url, loginEndpointsConfig);
  }

  static async initEIDeasy(payload: RequestInitEIDeasy) {
    const url = withBaseUrl('/eideasy');
    return restClient.POST<RequestInitEIDeasy, ResponseInitEIDeasy>(url, payload, loginEndpointsConfig);
  }

  static async checkStatusEIDeasy(payload: RequestPollEIDeasy) {
    const url = withBaseUrl(`/eideasy/${payload.sessionId}`);
    return restClient.GET<ResponsePollEIDeasy>(url, loginEndpointsConfig);
  }

  static async completeEIDeasy(payload: RequestCompleteEIDeasy) {
    const url = withBaseUrl('/redirect/eideasy');
    return restClient.GET<ResponseCompleteEIDeasy>(queryBuilder(url, payload), loginEndpointsConfig);
  }
}
