import { FC, useEffect, useState } from 'react';

import {
  Container,
  ContainerProps,
  Dot,
  LogoLoaderContainer,
  MiddleLine,
  NoteStyle,
  TopLine
} from './loader.styles';

type Props = {
  note?: string;
  noDelay?: boolean;
  noMargin?: boolean;
} & ContainerProps;

const Loader: FC<Props> = ({ note, transparent, opaque, noDelay, rounded, noMargin }) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => setDisplay(true), noDelay ? 0 : 350);
  }, []);

  return display ? (
    <Container opaque={opaque} transparent={transparent} data-testid="loader-container" rounded={rounded}>
      <LogoLoaderContainer noMargin={noMargin}>
        <TopLine />
        <MiddleLine />
        <Dot />
      </LogoLoaderContainer>
      {note ? <NoteStyle>{note}</NoteStyle> : null}
    </Container>
  ) : null;
};

export default Loader;
