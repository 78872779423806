import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

import { selectActiveCustomer } from '../auth/selectors';
import { selectHasLoansProducts } from '../loans/selectors';

import factoringContractsApi from './api';
import { ContractsState } from './types';

export const selectContractDetails = (state: ContractsState) => state.contractDetails;
export const selectIsDetailsLoading = (state: ContractsState) => state.detailsIsLoading;
export const selectIsDetailsLoadFailed = (state: ContractsState) => state.detailsLoadFailed;

export const selectContractCreditListHistory = (state: ContractsState) => state.creditLimitHistory;

const createFactoringTotalsSelector = createSelector(
  (partyId: string) => ({
    partyId
  }),
  (partyId) => {
    return factoringContractsApi.endpoints.getFactoringContractTotals.select(partyId);
  }
);

export const selectHasProducts = createSelector(
  (state: RootState) => ({
    state,
    partyId: selectActiveCustomer(state.auth)?.customerCrmId ?? ''
  }),
  ({ state, partyId }) => {
    const totals = createFactoringTotalsSelector(partyId)(state);

    const isSuccessfullyLoaded = !totals.isUninitialized && !totals.isLoading && totals.isSuccess;
    const data = totals.data;

    const hasFactoringProduct = Object.keys(data ?? {}).length !== 0;

    return isSuccessfullyLoaded && hasFactoringProduct && selectHasLoansProducts(state);
  }
);
