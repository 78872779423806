import { UploadInvoice } from 'services/InvoicesService/types';

import { INVOICE_UPLOAD_VALIDATION_MESSAGE_ID } from './manualValidationMessages';

export type BackendValidationGeneralErrorType = 'TECHNICAL_ERROR';

export type BackendFieldValidationTypes =
  | 'INVOICE_NUMBER_EMPTY'
  | 'DUPLICATED_INVOICE'
  | 'BUYER_CODE_EMPTY'
  | 'BUYER_NAME_EMPTY'
  | 'ISSUE_DATE_EMPTY'
  | 'FUTURE_ISSUE_DATE'
  | 'DUE_DATE_OVERDUE'
  | 'DUE_DATE_EMPTY'
  | 'AMOUNT_EMPTY'
  | 'TRANSFER_CLAIM_AMOUNT_EMPTY'
  | 'CURRENCY_CODE_EMPTY'
  | 'ISSUE_DATE_EMPTY'
  | 'ISSUE_DATE_IS_EQUAL_TO_DUE_DATE'
  | 'UNSUPPORTED_AMOUNT_FORMAT'
  | 'UNSUPPORTED_TRANSFER_CLAIM_AMOUNT_FORMAT'
  | 'TRANSFER_CLAIM_AMOUNT_BIGGER_THAN_AMOUNT'
  | 'UNSUPPORTED_CURRENCY'
  | 'AMOUNT_ZERO'
  | 'TRANSFER_CLAIM_AMOUNT_ZERO'
  | 'BUYER_INVALID';

type BackendValidationMessages = {
  [key in BackendFieldValidationTypes]: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID;
};

type BackendValidationTypesMapToFormFields = {
  [key in keyof Omit<UploadInvoice, 'contractId'>]: BackendFieldValidationTypes[];
};

export const backendValidationMessages: BackendValidationMessages = {
  DUPLICATED_INVOICE: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceNumberShouldBeUnique,
  INVOICE_NUMBER_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceNumberIsRequired,
  BUYER_CODE_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.buyerCodeIsRequired,
  BUYER_NAME_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.buyerNameIsRequired,
  FUTURE_ISSUE_DATE: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.dateShouldNotBeInFuture,
  ISSUE_DATE_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.issueDateIsRequired,
  DUE_DATE_OVERDUE: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.dateShouldNotBeInPast,
  DUE_DATE_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.dueDateIsRequired,
  AMOUNT_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceAmountIsRequired,
  TRANSFER_CLAIM_AMOUNT_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.transferClaimIsRequired,
  CURRENCY_CODE_EMPTY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.currencyIsRequired,
  ISSUE_DATE_IS_EQUAL_TO_DUE_DATE: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.datesShouldNotMatch,
  UNSUPPORTED_AMOUNT_FORMAT: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceAmountIsRequired,
  UNSUPPORTED_TRANSFER_CLAIM_AMOUNT_FORMAT: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.transferClaimIsRequired,
  TRANSFER_CLAIM_AMOUNT_BIGGER_THAN_AMOUNT:
    INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.transferClaimShouldBeLessOrEqual,
  UNSUPPORTED_CURRENCY: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.currencyValueShouldBeOneOf,
  AMOUNT_ZERO: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceAmountIsRequired,
  TRANSFER_CLAIM_AMOUNT_ZERO: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.invoiceAmountIsRequired,
  BUYER_INVALID: INVOICE_UPLOAD_VALIDATION_MESSAGE_ID.buyerCodeIncorrect
};

export const backendValidationTypesMapToFormFields: BackendValidationTypesMapToFormFields = {
  amount: ['AMOUNT_EMPTY', 'AMOUNT_ZERO', 'UNSUPPORTED_AMOUNT_FORMAT'],
  buyerCode: ['BUYER_CODE_EMPTY', 'BUYER_INVALID'],
  buyerName: ['BUYER_NAME_EMPTY'],
  currencyCode: ['CURRENCY_CODE_EMPTY', 'UNSUPPORTED_CURRENCY'],
  dueDate: ['DUE_DATE_EMPTY', 'ISSUE_DATE_IS_EQUAL_TO_DUE_DATE', 'DUE_DATE_OVERDUE'],
  invoiceNumber: ['INVOICE_NUMBER_EMPTY', 'DUPLICATED_INVOICE'],
  issueDate: ['ISSUE_DATE_EMPTY', 'ISSUE_DATE_IS_EQUAL_TO_DUE_DATE'],
  transferClaimAmount: [
    'TRANSFER_CLAIM_AMOUNT_EMPTY',
    'TRANSFER_CLAIM_AMOUNT_BIGGER_THAN_AMOUNT',
    'TRANSFER_CLAIM_AMOUNT_ZERO',
    'UNSUPPORTED_TRANSFER_CLAIM_AMOUNT_FORMAT'
  ]
};
