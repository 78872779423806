import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { useModalContext } from 'features/nav/context/modalContext';
import { isOndatoVerificationSuccess } from 'features/onboarding/utils';
import { useAppSelector } from 'hooks/redux/hooks';

import { Verification } from './verification';

const VerificationWrapper = () => {
  const navigate = useNavigate();
  const isVerifiedCompany = useAppSelector(selectIsActiveCompanyVerified);
  const { modals } = useModalContext();

  const isOndatoSucceeded = isOndatoVerificationSuccess();

  /**
   * Trigger flow lobby screen overlay. We assume that component doing check if ondato is passed will handle hiding the modal.
   */
  useEffect(() => {
    if (isVerifiedCompany && modals) {
      if (isOndatoSucceeded) {
        modals?.get('flow-lobby')?.({ screen: 'ondato', visible: true });

        const navigateTo = isOndatoSucceeded
          ? '/dashboard/overview?createQuestionaire=true&identitySuccess=true'
          : '/dashboard/overview';

        navigate(navigateTo, { replace: true });
      }
    }
  }, [isOndatoSucceeded, isVerifiedCompany, location.search, modals]);

  if (isVerifiedCompany) {
    return null;
  }

  return <Verification />;
};

export default VerificationWrapper;
