import { ReactNode, forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from '../../assets/icons';

import {
  ToastMessage,
  ToastStyle,
  ToastProps,
  IconContainer,
  Header,
  ToastContainer,
  ToggleButton,
  CloseContainer,
  ToastHeader,
  Column,
  ToastLeftContent
} from './toast.styles';

type IconVariants = 'info' | 'error' | 'check';

type ToastIcons = {
  [key in IconVariants]: ReactNode;
};

type ListMessage = { message: string; id: string };

type Props = {
  message?: ReactNode;
  isVisible: boolean;
  truncateText?: boolean;
  showHeader?: boolean;
  testId?: string;
  className?: string;
  header?: ReactNode;
  onClose?: (visible: boolean) => unknown;
  icon?: IconVariants;
  customIcon?: ReactNode;
  animate?: boolean;
  list?: ListMessage[];
  footerActions?: ReactNode;
  messageWithIcon?: boolean;
} & Partial<ToastProps>;

const renderIcons: ToastIcons = {
  info: <Icons.Info />,
  error: <Icons.Warning />,
  check: <Icons.Check />
};

const checkIfItemWasUpdated = (list: ListMessage[], id: string): boolean => {
  return list.some((item) => item.id === id);
};

const getRemovedListItems = (list: ListMessage[], updatedList: ListMessage[]): ListMessage[] => {
  return list.filter((item) => !checkIfItemWasUpdated(updatedList, item.id));
};

const Toast = forwardRef<HTMLDivElement, Props>(
  (
    {
      testId,
      variant,
      message,
      isVisible,
      className,
      header,
      onClose,
      icon,
      customIcon,
      list,
      footerActions,
      messageWithIcon,
      showHeader = true,
      truncateText = true,
      animate = true
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [overflowActive, setOverflowActive] = useState<boolean>(false);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [messagesList, setMessagesList] = useState<ListMessage[]>(list || []);
    const [firstLoadIsVisible, setFirstLoadIsVisible] = useState<boolean | null>(isVisible);

    const overflowingText = useRef<HTMLParagraphElement | null>(null);

    const checkOverflow = (textContainer: HTMLParagraphElement | null): boolean => {
      if (textContainer)
        return (
          textContainer.offsetHeight < textContainer.scrollHeight ||
          textContainer.offsetWidth < textContainer.scrollWidth
        );

      return false;
    };

    useEffect(() => {
      setOverflowActive(checkOverflow(overflowingText.current));
    }, [overflowActive, showMore, message]);

    const handleOnClose = () => onClose?.(false);

    const variantType = variant ?? 'error';
    const iconType = icon ?? 'info';

    useEffect(() => {
      setTimeout(() => {
        setMessagesList(list || []);
      }, 450);
    }, [list]);

    useEffect(() => {
      if (firstLoadIsVisible !== null && isVisible !== firstLoadIsVisible) {
        setTimeout(() => {
          setFirstLoadIsVisible(null);
        }, 550);
      }
    }, [isVisible]);

    const showMessage = (footerActions || showMore || messagesList?.length || message) && !messageWithIcon;

    const messageContent = (
      <ToastMessage
        showHeader={showHeader && !messageWithIcon}
        showMore={showMore}
        truncateText={truncateText}
      >
        {messagesList?.length ? (
          <ul>
            {messagesList.map((item, index) => {
              const removedItems = getRemovedListItems(messagesList, list || []);
              const key = `${item.id ?? index}`;

              return (
                <li
                  className={removedItems.some((removedItem) => removedItem.id === item.id) ? 'removed' : ''}
                  key={key}
                >
                  {item.message}
                </li>
              );
            })}
          </ul>
        ) : null}
        <p ref={overflowingText}>{message}</p>
        {(!overflowActive && !showMore) || !truncateText ? null : (
          <ToggleButton variant={variantType} onClick={() => setShowMore(!showMore)}>
            {showMore ? t('readLess') : t('readMore')}
          </ToggleButton>
        )}
        {footerActions}
      </ToastMessage>
    );

    return (
      <ToastStyle
        ref={ref}
        animate={animate && (firstLoadIsVisible !== isVisible || isVisible)}
        className={className}
        data-testid={`${variantType}-toast-message${testId ?? ''}`}
        isVisible={isVisible}
        variant={variantType}
      >
        <ToastContainer>
          <Column>
            {showHeader ? (
              <ToastHeader>
                <ToastLeftContent>
                  <IconContainer colorByVariant={!customIcon} variant={variantType}>
                    {customIcon || renderIcons[iconType]}
                  </IconContainer>
                  {messageWithIcon ? messageContent : null}
                  {header && <Header variant={variantType}>{header}</Header>}
                </ToastLeftContent>
                {onClose ? (
                  <CloseContainer onClick={handleOnClose} variant={variantType}>
                    <Icons.Close />
                  </CloseContainer>
                ) : null}
              </ToastHeader>
            ) : null}
            {showMessage ? messageContent : null}
          </Column>
        </ToastContainer>
      </ToastStyle>
    );
  }
);

export default Toast;
