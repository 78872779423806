import styled from 'styled-components';

import eye from 'assets/icons/eye.svg';
import { BannerToast } from 'components';

export const AdminToast = styled(BannerToast)`
  position: sticky;
  top: 0;
  z-index: 90000;
  background-color: #ffcf71cc;
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  border: 1px solid #e1b443;

  h3 {
    color: ${({ theme }) => theme.colors.base.black.shades[90]};
  }
`;

export const EyeIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme }) => theme.colors.base.black.shades[90]};
  mask-image: ${() => `url("${eye}")`};
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
`;
