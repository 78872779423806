import styled, { css } from 'styled-components';

type Props = {
  padding?: string;
  fullHeight?: boolean;
  spaceBetween?: boolean;
};

const defaultPaper = css<Props>`
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.formElement.background};
  border-radius: 1.125rem;
  box-shadow: 0 0.25rem 1.25rem 0 #00000005;
  padding: ${({ padding }) => padding ?? '2.5rem'};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : '')};
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : '')};

  @media ${({ theme }) => theme.media.maxPhone} {
    padding: 1.25rem;
  }
`;

export const PaperStyle = styled.div<Props>`
  ${defaultPaper};
`;
