import styled, { css } from 'styled-components';

import chevronLeft from '../../assets/icons/chevronLeft.svg';
import closeMedium from '../../assets/icons/close24px.svg';
import { LinkButton } from '../link';
import { StepperContainerStyles } from '../stepper/stepper.styles';
import typography from '../typography/typography';

const buttonStyles = css`
  border-radius: 0.625rem;
  padding: 0.5rem;
  min-height: 2.5rem;
  text-decoration: none;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: 0.0081rem;
  transition:
    background-color 0.15s ease-out,
    color 0.15s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.base.black.tints[15]};
  }

  svg {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

type TopNavigationContainerProps = {
  isBorderVisible?: boolean;
};

export const TopNavigationContainer = styled.div<TopNavigationContainerProps>`
  z-index: ${({ theme }) => theme.layers.nav};
  position: sticky;
  top: 0;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.background};
  border-bottom: ${({ isBorderVisible, theme }) =>
    isBorderVisible ? `1px solid ${theme.colors.base.black.shades[10]}` : '1px solid transparent'};

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 0.75rem 1rem;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
`;

type HeaderProps = {
  isScrolled?: boolean;
  maxWidthInPx: number;
};

export const OutWrapper = styled.div`
  margin: 0 1rem;
`;

export const HeaderOut = styled.div<HeaderProps>`
  display: none;

  @media ${({ theme }) => theme.media.maxPhone} {
    display: flex;
    text-align: left;
    line-height: 1.9rem;
    margin-top: 1.5rem;
    opacity: ${({ isScrolled }) => (isScrolled ? 0 : 1)};
    transition: opacity 0.1s ease-in-out;
    width: 100%;
    justify-content: center;

    h2 {
      width: 100%;
      max-width: ${({ maxWidthInPx }) => `${maxWidthInPx / 16}rem`};
    }
  }
`;

export const HeaderInTopNavigation = styled.div<HeaderProps>`
  max-width: ${({ maxWidthInPx }) => `${maxWidthInPx / 16}rem`};
  width: 100%;
  display: inline-flex;
  align-items: center;

  @media ${({ theme }) => theme.media.maxPhone} {
    text-align: center;
    line-height: 1.9rem;
    opacity: ${({ isScrolled }) => (isScrolled ? 1 : 0)};
    transition: opacity 0.1s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;

    h2 {
      font-size: 1rem;
      line-height: 2rem;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }
`;

export const HeaderTitle = styled(typography.Header).attrs({
  type: 'h2',
  weight: 'semibold'
})`
  display: inline-flex;
  gap: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NavigationButton = styled(LinkButton).attrs({
  color: 'Black',
  iconOnly: true,
  icons: { left: chevronLeft }
})`
  ${buttonStyles}
`;

export const CloseButton = styled(LinkButton).attrs({
  color: 'Black',
  iconOnly: true,
  icons: { left: closeMedium }
})`
  background-color: ${({ theme }) => theme.colors.base.black.shades[10]};
  ${buttonStyles}
`;

export const StepperContainer = styled.div<HeaderProps>`
  max-width: ${({ maxWidthInPx }) => `${maxWidthInPx / 16}rem`};
  bottom: 0;
  position: absolute;
  width: 100%;

  ${StepperContainerStyles} {
    top: 0;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    padding: 0 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
