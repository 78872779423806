import styled from 'styled-components';

import { Typography } from 'components';

export const StepExplanation = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.tints[60]};

  &:not(:empty) {
    margin-top: 0.5rem;
  }
`;

export const SubheaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 33.75rem;
  width: 100%;
  align-self: center;

  @media (max-width: 36rem) {
    padding-inline: 1rem;
    max-width: 100%;
  }
`;
