import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { getOnboardingParallelFlowConfig } from 'defaultConfiguration/getOnboardingParallelFlowConfig';
import { CountryOfCompanyRegistration } from 'features/application/forms/company/constants';
import { selectVerificationApplication } from 'features/applications/selectors';
import { Status } from 'features/applications/types/applicationsTypes';
import { useAppSelector } from 'hooks/redux/hooks';

type OnboardingEnabledConfig = {
  ondatoAvailableCAPStatus: Status;
  isParallelFlowEnabled?: boolean;
};

const checkIfChannelIsEnabled = (applicationNumber: string, channels: string[]) =>
  channels.includes(applicationNumber.charAt(0));

const useGetOnboardingEnabledConfig = (): OnboardingEnabledConfig => {
  const application = useAppSelector(selectVerificationApplication);

  const { isFeatureEnabled } = useRemoteConfigContext();
  const defaultConfig = getOnboardingParallelFlowConfig();

  const parallelFlowEnabled = isFeatureEnabled('parallelFlow');

  const companyCountry = application.company?.country ?? application.farm?.country;

  const isChannelEnabled = checkIfChannelIsEnabled(
    application?.applicationNumber ?? '',
    defaultConfig.enabledForChannels ?? []
  );

  if (companyCountry) {
    if (
      parallelFlowEnabled &&
      defaultConfig.includedCountries?.includes(companyCountry as CountryOfCompanyRegistration) &&
      isChannelEnabled
    ) {
      return {
        ondatoAvailableCAPStatus: defaultConfig.ondatoAvailableCAPStatus,
        isParallelFlowEnabled: true
      };
    }
  }

  return {
    ondatoAvailableCAPStatus: defaultConfig.ondatoAvailableCAPStatusForOtherCountries,
    isParallelFlowEnabled: false
  };
};

export default useGetOnboardingEnabledConfig;
