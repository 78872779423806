import { useEffect, useLayoutEffect, useState } from 'react';

import { OverlayView } from 'components/pageV2/overlayView';
import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { useModalContext } from 'features/nav/context/modalContext';
import getTypedSearchParams from 'features/onboarding/utils/getTypedSearchParams';
import { useAppSelector } from 'hooks/redux/hooks';

import LobbyScreen, { Props as LobbyScreenProps } from './lobbyScreen';

type LobyParams = {
  visible: boolean;
} & LobbyScreenProps;

const LobbyOverlay = () => {
  const [screen, setScreen] = useState<LobyParams['screen']>('');
  const isCompanyVerified = useAppSelector(selectIsActiveCompanyVerified);

  const { registerModalHash } = useModalContext<LobyParams>();

  const [visible, setVisible] = useState(false);

  const searchParams = getTypedSearchParams();

  const { identitySuccess } = searchParams ?? {};

  const handleToggle = (params?: LobyParams) => {
    setVisible(params?.visible ?? !visible);
    setScreen(params?.screen ?? screen);
  };

  useLayoutEffect(() => {
    if (typeof identitySuccess !== 'undefined' && identitySuccess && !isCompanyVerified) {
      setVisible(true);
    }
  }, [identitySuccess]);

  useEffect(() => {
    const modals = registerModalHash('flow-lobby', handleToggle);

    return () => {
      modals.unregister();
    };
  }, []);

  return (
    <OverlayView direction="right" open={visible}>
      <LobbyScreen screen={screen} />
    </OverlayView>
  );
};

export default LobbyOverlay;
