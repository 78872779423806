import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Modal,
  Fieldset,
  Field,
  TextArea,
  UploadArea,
  UploadAreaElement,
  Loader,
  SuccessModal,
  CurrencyInput,
  ToastLegacy
} from 'components';
import { formatNumber } from 'components/text/formatters';
import { selectCustomerInformation } from 'features/auth/selectors';
import { useRequestIncreaseLimitMutation } from 'features/contracts/api';
import { selectContractDetails } from 'features/contracts/selectors';
import { useLazyGetUserByEmailQuery } from 'features/users/slice';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector, useContractSelector } from 'hooks/redux/hooks';
import { ContractsService } from 'services';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import {
  DocumentsExplanatoryList,
  DocumentsUploadHeader,
  FieldsetHeader,
  FieldsetHeaderTitle,
  FieldsetHeaderValue,
  ToastContainer
} from '../prolongContractModal/prolongContractModal.styles';

type IncreaseLimitRequest = {
  currentFinanceLimit: number;
  financeLimit: number;
  comment: string;
};

const validationSchema = Yup.object({
  currentFinanceLimit: Yup.number().optional(),
  financeLimit: Yup.number()
    .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .test('', 'financeLimitCannotBeSmallerThanCurrent', function (value) {
      const { currentFinanceLimit } = this.parent;

      return Number(currentFinanceLimit) < Number(value);
    }),
  comment: Yup.string()
    .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .min(1, VALIDATION_MESSAGE_ID.MIN_LENGTH)
    .max(200, VALIDATION_MESSAGE_ID.MAX_LENGTH)
});

type Props = {
  isOpen: boolean;
  onVisibilityChange: (isVisible: boolean) => unknown;
};

export const IncreaseFinanceLimitModal: FC<Props> = ({ isOpen, onVisibilityChange }) => {
  const { t, i18n } = useTranslation();

  const uploadAreaRef = useRef<UploadAreaElement>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const selectedContract = useContractSelector<typeof selectContractDetails>(selectContractDetails);
  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);

  const initialValues = useMemo(
    () => ({
      currentFinanceLimit: selectedContract.factoringCreditLimit,
      financeLimit: 0,
      comment: ''
    }),
    [selectedContract.contractTerm?.endDate]
  );

  const { validationHelpers, reset, handleSubmit, setTouchedOnAll, getValues } =
    useFormHandlers<IncreaseLimitRequest>(initialValues, validationSchema);

  const [triggerIncreaseLimit] = useRequestIncreaseLimitMutation();
  const [triggerUserInfoRequest] = useLazyGetUserByEmailQuery();

  const handleClose = () => {
    onVisibilityChange?.(false);
    setShowSuccessMessage(false);
    reset(initialValues);
  };

  const onSubmit = () => {
    handleSubmit(
      async (data) => {
        if (uploadAreaRef.current) {
          if (uploadAreaRef.current.isValid()) {
            try {
              if (!isSubmitting) {
                setIsError(false);
                setIsSubmitting(true);

                if (customerInfo?.userId && selectedContract.id) {
                  const { data: userInfo } = await triggerUserInfoRequest({ email: customerInfo?.userId });

                  const fileArray = uploadAreaRef.current.getFiles();

                  if (fileArray && fileArray.length > 0) {
                    const formData = new FormData();

                    fileArray.forEach((file) => {
                      formData.append('files', file);
                    });

                    await ContractsService.uploadContractIncreaseLimitDocuments({
                      contractId: selectedContract.id,
                      content: {
                        'multipart/form-data': formData
                      }
                    });
                  }

                  await triggerIncreaseLimit({
                    contractId: selectedContract.id,
                    mobilePhoneNumber: userInfo?.mobilePhoneNumber,
                    financingLimit: data.financeLimit,
                    reasonForIncrease: data.comment,
                    userCountry: userInfo?.userCountry,
                    userName: userInfo?.firstName,
                    userSurname: userInfo?.lastName
                  }).unwrap();

                  setShowSuccessMessage(true);
                } else {
                  throw new Error('User not found');
                }
              }
            } catch (e) {
              setIsError(true);
            } finally {
              setIsSubmitting(false);
            }
          }
        }
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const formatCurrency = formatNumber(i18n.resolvedLanguage);

  return (
    <>
      <SuccessModal
        header="increaseContractRequestSentSuccessHeader"
        text="increaseContractRequestSentSuccessText"
        isOpen={showSuccessMessage}
        onClose={handleClose}
      />
      <Modal
        testId="increase-financing-limit"
        label={t('increaseContractFinancingLimit')}
        isOpen={isOpen && !showSuccessMessage}
        onClose={handleClose}
        mainActionLabel={t('submit')}
        mainActionhandler={onSubmit}
        secondaryActionLabel={t('cancel')}
      >
        {isError ? (
          <ToastContainer>
            <ToastLegacy isVisible message={t('generalErrorMessage')} />
          </ToastContainer>
        ) : null}
        {isSubmitting ? <Loader opaque /> : null}
        <FieldsetHeader>
          <FieldsetHeaderTitle>{t('contractNumber')}</FieldsetHeaderTitle>
          <FieldsetHeaderValue>{selectedContract?.contractNumber}</FieldsetHeaderValue>
        </FieldsetHeader>
        <Fieldset>
          <Field
            placeholder={t('newInvoiceFinanceLimit')}
            name="financeLimit"
            Component={CurrencyInput}
            validationHelpers={validationHelpers}
            helpText={`${t('currentFinancingLimit')}: ${formatCurrency(
              selectedContract.factoringCreditLimit
            )}`}
            required
          />
          <Field
            placeholder={t('reasonForIncrease')}
            name="comment"
            Component={TextArea}
            maxLength={200}
            validationHelpers={validationHelpers}
            value={getValues('comment')}
            counter
            required
          />
        </Fieldset>
        <DocumentsUploadHeader>{t('supportingDocuments')}</DocumentsUploadHeader>
        <DocumentsExplanatoryList>
          <li>{t('provideSupportingDocumentsSuchAs')}</li>
          <li>{t('newFinancialReports')}</li>
          <li>{t('newFinancialStatements')}</li>
          <li>{t('other')}</li>
        </DocumentsExplanatoryList>
        <UploadArea ref={uploadAreaRef} />
      </Modal>
    </>
  );
};
