import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Loader } from 'components';
import { selectLoginWarning } from 'features/app/selectors';
import { clearLoginWarning } from 'features/app/slice';
import EmailForm, { LoginInfo } from 'features/auth/components/emailForm/emailForm';
import { SmartID, MobileID } from 'features/auth/methods';
import { EIDeasy } from 'features/auth/methods/eideasy';
import { OtpCode } from 'features/auth/methods/otp';
import { selectIsWaitingForConfirmation, selectUserData } from 'features/auth/selectors';
import {
  initiateOTPAsync,
  setIsWaitingForConfirmation,
  setUserData,
  validateUserEmail
} from 'features/auth/slice';
import { LoginTypes, NewCustomerEmail, SupportedCountries } from 'features/auth/types/authTypes';
import { useAppDispatch, useAppInfoSelector, useAuthSelector } from 'hooks/redux/hooks';
import { useAppLocation } from 'hooks/router/useAppLocation';
import { getLanguageCode } from 'translations/utils';

import LoginContainer from './loginContainer';
import { Bolded, LoginLoaderContainer, WarningToast } from './loginContainer/loginContainer.styles';
import { pictograms } from './loginMethodButton/loginMethodButton';
import { LoginMethodsList } from './loginMethodsList';

type Props = {
  country?: string;
  userEmail?: string;
  otpOnly?: boolean;
  isIdentityStepUp?: boolean;
};

type Steps = 'email' | 'loginSelection' | 'otp' | 'loginMethod';

const steps: Steps[] = ['email', 'loginSelection', 'loginMethod'];
const otpSteps: Steps[] = ['email', 'otp'];

const TIMEOUT_IN_MINS = 5;

const MINUTE_IN_MILISECONDS = 1000 * 60;

const methodSubheaderLabels: Record<string, string> = {
  smartId: 'verificationCodeSentToYourDevice',
  mobilId: 'verificationCodeSentToYourDevice',
  IDIN: '',
  FTN: '',
  eparakts: ''
};

const headerLabels: Record<string, string> = {
  smartId: 'smartIdHeader',
  mobilId: 'mobileIdHeader',
  IDIN: '',
  FTN: '',
  eparakts: ''
};

const subheaderLabelsByStep: Record<Steps, (method?: string) => string> = {
  email: () => 'pleaseLoginExplanation',
  loginSelection: () => '',
  otp: () => 'weSentYouEmailOTP',
  loginMethod: (method) => methodSubheaderLabels[method || 'smartId']
};

const headerLabelsByStep: Record<Steps, (method?: string) => string> = {
  email: () => 'login',
  loginSelection: () => 'chooseLoginMethod',
  otp: () => 'checkYourEmail',
  loginMethod: (method) => headerLabels[method || 'smartId']
};

const smartIDbasicTutorialLinks: Record<string, string> = {
  en: 'https://www.smart-id.com/help/faq/registering/registration-methods-for-smart-id',
  lt: 'https://www.smart-id.com/lt/help/faq/registracija/smart-id-registracijos-budai'
};

const Login: FC<Props> = ({ userEmail, country, otpOnly }) => {
  const { state } = useAppLocation();
  const { t, i18n } = useTranslation();

  const languageCode = getLanguageCode(i18n.language || 'EN').toUpperCase();

  const { originalLocation, fullyQualifiedLogin = true } = state || {};

  const redirectToUrl = originalLocation?.pathname || '/dashboard/applications';

  const [currentStep, setCurrentStep] = useState<Steps>(steps[0]);

  const [validationInProgress, setValidationInProgress] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<LoginTypes | null>(null);
  const [, setEmailValidationFailed] = useState<boolean>(false);
  const [missingPersonalInformation, setMissingPersonalInformation] = useState<boolean>(false);
  const [lastActivity, setLastActivity] = useState<number>(Date.now());

  const dispatch = useAppDispatch();

  const isWaitingForConfirmation = useAuthSelector<typeof selectIsWaitingForConfirmation>(
    selectIsWaitingForConfirmation
  );

  const loginWarningToast = useAppInfoSelector<typeof selectLoginWarning>(selectLoginWarning);

  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const redirectTimeout = MINUTE_IN_MILISECONDS * TIMEOUT_IN_MINS;

  const switchTabHandler = (value: LoginTypes): void => {
    setCurrentStep('loginMethod');
    setActiveTab(value);
  };

  const initiateOtpLogin = async (customerData: NewCustomerEmail) => {
    setCurrentStep('otp');
    dispatch(initiateOTPAsync(customerData)).unwrap();
  };

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  const resetLoginFlow = () => {
    setCurrentStep('email');
    setActiveTab(null);
    setValidationInProgress(false);
    setEmailValidationFailed(false);
    setMissingPersonalInformation(false);
    dispatch(setIsWaitingForConfirmation(false));
  };

  const logonSelection = async ({ email, country }: LoginInfo) => {
    try {
      setValidationInProgress(true);

      dispatch(clearLoginWarning());
      setMissingPersonalInformation(false);

      const newCustomerData: NewCustomerEmail = {
        email,
        country
      };

      dispatch(setUserData(newCustomerData));

      if (otpOnly) {
        await initiateOtpLogin({ ...newCustomerData, country: languageCode as SupportedCountries });

        return;
      }

      const validationResponse = await dispatch(validateUserEmail({ user: email })).unwrap();
      const { userValid, userAdditionalInfoRequired } = validationResponse || {};

      setCurrentStep('loginSelection');
      setMissingPersonalInformation(Boolean(userAdditionalInfoRequired));

      if (!userValid || userAdditionalInfoRequired) {
        await initiateOtpLogin({ ...newCustomerData, country: languageCode as SupportedCountries });
      }
    } catch {
      setEmailValidationFailed(true);
      setCurrentStep('email');
    } finally {
      setValidationInProgress(false);
      setEmailValidationFailed(false);
    }
  };

  const handleBack = () => {
    handleActivity();

    if (isWaitingForConfirmation) {
      dispatch(setIsWaitingForConfirmation(false));
    } else {
      const flowSteps = currentStep === 'otp' ? otpSteps : steps;

      setCurrentStep(flowSteps[flowSteps.findIndex((step) => step === currentStep) - 1]);
      setActiveTab(null);
    }
  };

  const handleTimeout = () => {
    resetLoginFlow();
  };

  useEffect(() => {
    const timeoutId = setInterval(() => {
      if (Date.now() - lastActivity > redirectTimeout) {
        handleTimeout();
      }
    }, 1000);

    return () => clearInterval(timeoutId);
  }, [lastActivity]);

  useEffect(() => {
    const activityHandler = () => {
      handleActivity();
    };

    document.addEventListener('keydown', activityHandler);
    document.addEventListener('mousemove', activityHandler);
    document.addEventListener('click', activityHandler);

    return () => {
      document.removeEventListener('keydown', activityHandler);
      document.removeEventListener('mousemove', activityHandler);
      document.removeEventListener('click', activityHandler);
    };
  }, []);

  const renderLoginMethodForm = (param: string) => {
    switch (param) {
      case 'mobilId':
        return <MobileID onFailHandle={handleBack} />;
      case 'IDIN':
      case 'FTN':
      case 'eparakts':
        return <EIDeasy />;
      case 'smartId':
      default:
        return <SmartID onFailHandle={handleBack} />;
    }
  };

  const renderStep = (currentStep: Steps) => {
    switch (currentStep) {
      case 'loginSelection':
        return <LoginMethodsList onSwitch={switchTabHandler} />;

      case 'otp':
        return (
          <OtpCode
            state={state}
            fullyQualifiedLogin={!missingPersonalInformation && fullyQualifiedLogin}
            redirectUri={missingPersonalInformation ? '/settings/update-info' : redirectToUrl}
            leftAlign
          />
        );

      case 'loginMethod':
        return activeTab ? renderLoginMethodForm(activeTab) : null;

      case 'email':
      default:
        return (
          <EmailForm
            inputPlaceholder={t('email')}
            buttonLabel={t('login')}
            onSubmit={logonSelection}
            userEmail={userEmail}
          />
        );
    }
  };

  useEffect(() => {
    if (userEmail && !validationInProgress) {
      logonSelection({ email: userEmail, country: (country || languageCode) as SupportedCountries });
    }
  }, [userEmail]);

  useEffect(() => {
    dispatch(clearLoginWarning());
  }, [currentStep]);

  const headerLabel = headerLabelsByStep[currentStep](activeTab || 'smartId');
  const headerPictogram = currentStep === 'loginMethod' ? pictograms[activeTab || 'smartId'] : undefined;
  const subheaderLabel =
    (currentStep === 'loginMethod' && isWaitingForConfirmation) || currentStep !== 'loginMethod'
      ? subheaderLabelsByStep[currentStep](activeTab || 'smartId')
      : '';

  const SmartIDBasicLink = (
    <a
      target="_blank"
      rel="noopener noreferer"
      href={smartIDbasicTutorialLinks[i18n.language] || smartIDbasicTutorialLinks.en}
    />
  );

  if (validationInProgress) {
    return (
      <LoginLoaderContainer>
        <Loader transparent rounded noMargin />
      </LoginLoaderContainer>
    );
  }

  return (
    <LoginContainer
      dense={currentStep === 'otp'}
      headerLabel={headerLabel}
      headerPictogram={headerPictogram}
      subheaderLabel={
        <Trans
          i18nKey={subheaderLabel}
          values={{ email: userData.email }}
          components={{ b: <Bolded />, span: <span /> }}
        />
      }
      onBack={currentStep !== 'email' ? handleBack : undefined}
    >
      <WarningToast
        animate={false}
        variant={loginWarningToast?.status}
        header={t(loginWarningToast?.header || '')}
        message={
          loginWarningToast?.message ? (
            <Trans
              i18nKey={loginWarningToast?.message}
              components={{ a: SmartIDBasicLink, span: <span /> }}
            />
          ) : (
            ''
          )
        }
        isVisible={Boolean(loginWarningToast)}
      />
      {renderStep(currentStep)}
    </LoginContainer>
  );
};

export default Login;
