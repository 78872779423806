import styled from 'styled-components';

import { getButtonDimensionsCss } from 'components/buttonV2/button.styles';

import {
  BankCardCloseButton,
  BankCardContent,
  BankCardHeader,
  BankCardPrimaryButton,
  BankCardText,
  BankPaperCard,
  LogoContainer
} from '../bankCard/bankCard.styles';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${BankCardHeader} {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    text-align: center;
    padding-bottom: 1rem;
  }

  ${BankCardText} {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-align: center;
  }

  ${BankCardContent} {
    gap: 0;
  }

  ${LogoContainer} {
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    align-self: center;
  }

  ${BankCardCloseButton} {
    margin-top: -0.5rem;
    margin-right: -0.5rem;
  }

  ${BankCardPrimaryButton} {
    width: 100%;
    ${getButtonDimensionsCss('L', {}, false)}
  }

  ${BankPaperCard} {
    max-width: 25rem;
    padding: 2rem 2rem 1.5rem;
    border-radius: 1.5rem;
    justify-content: space-between;
    align-items: center;

    @media ${({ theme }) => theme.media.maxPhone} {
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }
`;
