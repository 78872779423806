import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import disbursementIcon from 'assets/icons/disbursement.svg';
import { Button } from 'components/buttonV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { PERMISSIONS } from 'features/auth/consts/permissions';
import { selectActiveCustomer } from 'features/auth/selectors';
import { selectLoansContractList } from 'features/loans/selectors';
import { useLoansSelector, useAuthSelector } from 'hooks/redux/hooks';

import { RequestDisbursementModal } from '..';

const statusesAllowedToDisburse = ['ACTIVE_NOT_DISBURSED', 'ACTIVE'];

export const RequestDisbursementButton = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const hasManageContractPermissions = activeCustomer?.roles?.includes(PERMISSIONS.manageContract);

  const isAllowedToDisburse =
    selectedContract?.contractStatus &&
    statusesAllowedToDisburse.includes(selectedContract?.contractStatus) &&
    selectedContract?.availableAmount &&
    hasManageContractPermissions;

  const handleRequestDisbursementClick = () => {
    setIsOpen(true);
  };

  const { isFeatureEnabled } = useRemoteConfigContext();

  if (!isFeatureEnabled('loansDisbursementRequest') || !isAllowedToDisburse) {
    return null;
  }

  return (
    <>
      <Button
        icons={{ left: disbursementIcon }}
        size="S"
        variant="StrokedWhite"
        color="Blue"
        onClick={handleRequestDisbursementClick}
      >
        {t('disburse')}
      </Button>
      <RequestDisbursementModal isOpen={isOpen} onVisibilityChange={setIsOpen} />
    </>
  );
};
