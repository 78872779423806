import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  RefObject,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';

type UnknownObject = Record<string, unknown>;

type NavContextProps<T = UnknownObject> = T & {
  activeDrawer?: T;
  toggleRightSideDrawer?: <TParams extends T = T>(info?: TParams) => void;
  hideSideDrawer?: () => void;
  showRightSideDrawer: boolean;
  showSideNavigation: boolean;
  setShowSideNavigation: Dispatch<SetStateAction<boolean>>;
  setPageTitle?: Dispatch<SetStateAction<string>>;
  pageTitle?: string;
  drawerRef?: RefObject<HTMLDivElement>;
  setActiveDrawer?: (info: UnknownObject) => unknown;
};

type Props = {
  children: ReactNode;
};

const NavContext = createContext<NavContextProps<UnknownObject>>({} as NavContextProps);

export function useNavContext<T = UnknownObject>() {
  return useContext<NavContextProps<T>>(NavContext as React.Context<NavContextProps<T>>);
}

export const NavContextProvider: FC<Props> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('smeGo');
  const [showSideNavigation, setShowSideNavigation] = useState(true);
  const [showRightSideDrawer, setShowRightSideDrawer] = useState(false);
  const [activeDrawer, setActiveDrawer] = useState<UnknownObject>();

  const location = useLocation();

  useEffect(() => {
    setActiveDrawer({});
    setShowRightSideDrawer(false);
  }, [location.pathname]);

  const context: NavContextProps = {
    showSideNavigation,
    setShowSideNavigation,
    setPageTitle,
    pageTitle,
    hideSideDrawer: () => setShowRightSideDrawer(false),
    showRightSideDrawer,
    toggleRightSideDrawer: (info) => {
      setActiveDrawer(info ? info : {});
      setShowRightSideDrawer(Boolean(info));
    },
    setActiveDrawer,
    activeDrawer
  };

  return <NavContext.Provider value={context}>{children}</NavContext.Provider>;
};

export default NavContext;
