import styled from 'styled-components';

import {
  ProductCardContentContainer,
  CardContent
} from 'features/application/components/productCard/productCard.styles';

export const AmountFormStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${ProductCardContentContainer} {
    margin-bottom: 1rem;
    height: auto;
    align-items: flex-start;
  }

  ${CardContent} {
    margin-left: 1.25rem;
    justify-content: flex-start;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }
`;
