import { Dialog, DialogProps, InputAdornment, Paper } from '@mui/material';
import { FilledInputProps } from '@mui/material/FilledInput';
import { styled as muiStyled, Theme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { PickersDay, PickersLayoutContentWrapper } from '@mui/x-date-pickers';
import { PickersCalendarHeader, PickersCalendarHeaderProps } from '@mui/x-date-pickers/PickersCalendarHeader';
import React from 'react';
import styled from 'styled-components';

import icons from '../../assets/icons';
import { getBorderFocusColor, getBorderHoverColor, getBorderRestColor } from '../input/input.style';

type Props = {
  invalidStatus?: boolean;
  disabled?: boolean;
};

export const DatePickerContainer = styled.div<Props>`
  display: flex;

  .MuiButtonBase-root {
    margin-right: -0.375rem;
    border-radius: 0.5rem;

    &:hover {
      background-color: ${({ theme }) => theme.colors.base.black.neutrals[5]};
    }
  }

  .MuiInputLabel-root {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    line-height: 1rem;
    letter-spacing: 0.0075rem;
    color: ${({ theme }) => theme.colors.base.black.shades[50]};
    transform: translate(1rem, 0.4375rem) scale(0.75);

    &.Mui-focused {
      color: ${getBorderFocusColor};
    }

    &.Mui-error {
      color: ${({ theme }) => theme.colors.base.black.shades[50]};
    }
  }

  .MuiFormControl-root {
    .MuiFormHelperText-root {
      &.Mui-error {
        color: ${({ theme }) => theme.colors.base.red.shades[120]};
      }
    }
  }
`;

type SpaTextFieldProps = {
  invalidStatus?: boolean;
} & TextFieldProps;

//function which checks if passed prop is of type Symbol(react.element)
// and verified that its childrens are truthy elements
// if so, it returns the element, otherwise returns null
function getEndAdornment(endAdornment: React.ReactNode) {
  if (React.isValidElement(endAdornment) && React.Children.count(endAdornment) > 0) {
    if (React.Children.toArray(endAdornment.props.children).some((child) => Boolean(child))) {
      return endAdornment;
    }
  }

  return null;
}

export const SpaTextField = muiStyled((props: SpaTextFieldProps) => {
  return (
    <TextField
      {...props}
      variant="filled"
      InputProps={
        {
          ...props.InputProps,
          disableUnderline: true,
          endAdornment: getEndAdornment(props.InputProps?.endAdornment) ?? (
            <InputAdornment position="end">
              <icons.Calendar />
            </InputAdornment>
          ),
          'data-testid': 'date-picker-input-container'
        } as unknown as Partial<FilledInputProps>
      }
      InputLabelProps={{ ...props.InputLabelProps, shrink: true, variant: 'filled' }}
    />
  );
})(({ theme, error }) => {
  const { spaTheme } = theme;

  const args = {
    theme: spaTheme,
    invalidStatus: error
  };

  const borderStyle = {
    border: '0.0625rem solid',
    borderRadius: '0.5rem',
    borderColor: getBorderRestColor(args)
  };

  const borderHoverStyle = {
    ...borderStyle,
    borderColor: getBorderHoverColor(args)
  };

  const borderFocusedStyle = {
    ...borderStyle,
    borderColor: getBorderFocusColor(args)
  };

  const inputBackgroundStyle = { background: spaTheme.colors.formElement.background };

  return {
    width: '100%',
    '& .MuiFilledInput-root': {
      maxHeight: '3rem',
      width: '100%',
      ...borderStyle,
      ...inputBackgroundStyle,

      '&:hover': { ...borderHoverStyle, ...inputBackgroundStyle },
      '&.Mui-focused': { ...borderFocusedStyle, ...inputBackgroundStyle },
      '.MuiInputBase-input': {
        paddingLeft: '1rem',
        color: spaTheme.colors.base.black.shades[100]
      }
    },
    '& .MuiFilledInput-root:not(:has(~ p.MuiFormHelperText-root.Mui-error))': {
      borderColor: getBorderRestColor({ ...args, invalidStatus: false }),
      '&:hover': { borderColor: getBorderRestColor({ ...args, invalidStatus: false }) },
      '&.Mui-focused': { borderColor: getBorderRestColor({ ...args, invalidStatus: false }) }
    }
  };
});

export const SpaCalendarHeader = muiStyled((props: PickersCalendarHeaderProps<Date>) => (
  <PickersCalendarHeader {...props} slotProps={{ switchViewButton: icons.ChevronDown }} reduceAnimations />
))(({ theme }) => ({
  width: '100%',
  marginTop: 0,
  marginBottom: '1rem',
  paddingLeft: 0,
  paddingRight: 0,
  maxHeight: '2.5rem',
  minHeight: '2.5rem',
  color: theme.spaTheme.colors.base.black.shades[90],
  '.MuiPickersCalendarHeader-switchViewButton': {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '0.5rem'
  },
  '.MuiPickersCalendarHeader-switchViewIcon': {
    fill: theme.spaTheme.colors.base.black.shades[90],
    path: {
      fill: theme.spaTheme.colors.base.black.shades[90]
    }
  },
  '.MuiPickersArrowSwitcher-button': {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '0.5rem'
  },
  '.MuiPickersArrowSwitcher-spacer': {
    width: '2rem'
  },
  '.MuiPickersCalendarHeader-label': {
    marginRight: '0.25rem',
    textTransform: 'capitalize'
  }
}));

export const SpaPaper = muiStyled(Paper)(({ theme }) => ({
  marginTop: '0.5rem',
  backgroundColor: theme.spaTheme.colors.formElement.background,
  boxShadow: '0rem 0.25rem 1.5rem 0rem rgba(0, 0, 0, 0.05), 0rem 1rem 1.5rem 0rem rgba(0, 0, 0, 0.12)',
  borderRadius: '1.5rem',
  padding: '1rem 1.24rem',
  maxWidth: '21.25rem',
  width: '100%',

  '.MuiDayCalendar-header': {
    gap: '0.5rem'
  },
  '.MuiDayCalendar-weekDayLabel': {
    maxHeight: '2.25rem',
    margin: 0,
    color: theme.spaTheme.colors.base.black.tints[60]
  },
  '.MuiDayCalendar-weekContainer': {
    gap: '0.5rem',
    margin: '0 0 0.5rem',
    '&:last-child': {
      marginBottom: 0
    }
  }
}));

const getTimePickerFontStyles = (theme: Theme) => ({
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: theme.spaTheme.fontWeights.normal,
  lineHeight: '20px'
});

const getButtonStateColors = (theme: Theme) => ({
  color: theme.spaTheme.colors.base.black.shades[90],
  '&:hover': {
    backgroundColor: theme.spaTheme.colors.base.primary.shades[5]
  },
  '&:focus': {
    backgroundColor: theme.spaTheme.colors.formElement.background
  },
  '&.Mui-selected': {
    color: theme.spaTheme.colors.base.white.functional,
    backgroundColor: theme.spaTheme.colors.base.primary.shades[100],
    '&:hover': {
      backgroundColor: theme.spaTheme.colors.base.primary.shades[100]
    },
    '&:focus': {
      backgroundColor: theme.spaTheme.colors.base.primary.shades[100]
    }
  },
  '&.Mui-disabled': {
    color: theme.spaTheme.colors.base.black.tints[30]
  }
});

export const SpaPickersDay = muiStyled(PickersDay)(({ theme }) => ({
  height: '2.25rem',
  margin: 0,
  ...getTimePickerFontStyles(theme),
  ...getButtonStateColors(theme),
  '&.MuiPickersDay-today': {
    border: `0.0625rem solid ${theme.spaTheme.colors.base.primary.shades[100]}`,
    background: '#ffffff'
  },
  '&.MuiPickersDay-dayOutsideMonth': {
    color: theme.spaTheme.colors.base.black.tints[30]
  }
}));

export const SpaPickersLayoutContentWrapper = muiStyled(PickersLayoutContentWrapper)(({ theme }) => ({
  '.MuiDateCalendar-root, .MuiMonthCalendar-root, .MuiYearCalendar-root': {
    padding: 0,
    maxWidth: '18.75rem',
    width: '100%',
    maxHeight: '22.375rem',
    height: '22.375rem',
    alignContent: 'baseline'
  },
  '.MuiYearCalendar-root, .MuiMonthCalendar-root': {
    '.MuiPickersYear-root, .MuiPickersMonth-root': {
      '.MuiPickersYear-yearButton, .MuiPickersMonth-monthButton': {
        margin: '0 0 1.5rem',
        height: '2.25rem',
        textTransform: 'capitalize',
        ...getTimePickerFontStyles(theme),
        ...getButtonStateColors(theme)
      }
    }
  },
  '.MuiMonthCalendar-root': {
    '.MuiPickersMonth-root': {
      '.MuiPickersMonth-monthButton': {
        margin: '0 0 1rem'
      }
    }
  },
  '.MuiPickersSlideTransition-root': {
    overflowX: 'visible'
  }
}));

export const SpaPickersModalDialogRoot = muiStyled((props: DialogProps) => (
  <Dialog
    {...props}
    onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
    }}
  />
))({
  '.MuiDialog-paper': {
    maxWidth: '21.25rem',
    width: '100%',
    margin: '0 auto'
  }
});
