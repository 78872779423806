import { useEffect } from 'react';

const useSwipeTillConfirmAttributeMutationObserver = (
  target: HTMLElement | null,
  onSwipe: (tillConfirm: number) => unknown
) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.target === target && mutation.attributeName === 'data-swipe-till-confirm') {
        const isTopSwipeableArea = target.getAttribute('data-active-index') === '0';

        if (isTopSwipeableArea) {
          const tillConfirm = Number(target.getAttribute('data-swipe-till-confirm') ?? 0);
          onSwipe(tillConfirm);
        }
      }
    });
  });

  const initializer = () => {
    if (target) {
      observer.observe(target, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  };

  useEffect(initializer, [target]);
};

export default useSwipeTillConfirmAttributeMutationObserver;
