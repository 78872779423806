import { FC, SyntheticEvent } from 'react';

import { ToggleLabel, ToggleContainerStyle, FakeToggleInput } from './toggleInput.styles';

type Props = {
  testId: string;
  leftLabel?: string;
  rightLabel?: string;
  checked?: boolean;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => unknown;
};

export const ToggleInput: FC<Props> = ({ testId, leftLabel, rightLabel, checked, onChange }) => {
  return (
    <ToggleContainerStyle>
      <label data-testid={`${testId}-toggle`}>
        <ToggleLabel checked={checked} data-testid={checked ? `${testId}-toggle-active` : ''}>
          {leftLabel}
        </ToggleLabel>
        <input type="checkbox" onChange={onChange} checked={checked} />
        <FakeToggleInput checked={checked}></FakeToggleInput>
        <ToggleLabel checked={!checked} data-testid={!checked ? `${testId}-toggle-active` : ''}>
          {rightLabel}
        </ToggleLabel>
      </label>
    </ToggleContainerStyle>
  );
};
