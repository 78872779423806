import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormTrigger } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Field, Input, ChipOption, ErrorMessage } from 'components';
import { selectIsPrefilledFromWeb } from 'features/application/selectors';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { LoansAdditionalInfo } from 'services/CapService/types';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';

import { PURPOSE, PURPOSE_LIST } from './constants';
import { ChipOptions, FadeInContainer, PurposeFieldset } from './purpose.styles';

type Props = {
  validationHelpers: ValidationHelpers<LoansAdditionalInfo>;
  getValues: UseFormGetValues<LoansAdditionalInfo>;
  trigger: UseFormTrigger<LoansAdditionalInfo>;
};

const emojis: Record<PURPOSE, string> = {
  [PURPOSE.WORKING_CAPITAL]: '💰',
  [PURPOSE.INVESTMENT]: '🚀',
  [PURPOSE.OTHER]: '🔄'
};

export const PurposeFields: FC<Props> = ({ validationHelpers, getValues, trigger }) => {
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState(getValues('purpose.type'));
  const [validationMessage, setValidationMessage] = useState('');

  const disabled = useApplicationSelector<typeof selectIsPrefilledFromWeb>(selectIsPrefilledFromWeb);

  const fieldState = validationHelpers.getFieldState('purpose.type');

  const showEnterPurpose = selectedValue === PURPOSE.OTHER;

  const isChecked = (value: unknown) => selectedValue === value;

  const onSelect = (e: SyntheticEvent<HTMLInputElement>) => {
    const selectedValue = e.currentTarget.value;

    if (selectedValue) {
      validationHelpers.setValueWithoutValidation('purpose', { type: selectedValue as PURPOSE });
      setSelectedValue(selectedValue as PURPOSE);
      setValidationMessage('');
    }
  };

  useEffect(() => {
    if (fieldState) {
      if (fieldState.isTouched && fieldState.invalid) {
        const errorWithMessage = fieldState.error as { message: string };

        const message = errorWithMessage?.message ?? '';

        setValidationMessage(message);
      }
    }
  }, [fieldState?.isTouched]);

  useEffect(() => {
    if (selectedValue !== PURPOSE.OTHER) {
      trigger();
    }

    validationHelpers.setValueWithoutValidation('purpose.description', '');
  }, [selectedValue]);

  return (
    <PurposeFieldset>
      <ChipOptions>
        {PURPOSE_LIST.map((purpose) => (
          <ChipOption
            onChange={onSelect}
            checked={isChecked(purpose.value)}
            value={purpose.value}
            name="purpose"
            key={purpose.value}
          >
            {`${emojis[purpose.value]} ${t(purpose.label)}`}
          </ChipOption>
        ))}
        {validationMessage ? (
          <ErrorMessage hidden={!validationMessage}>{t(validationMessage)}</ErrorMessage>
        ) : null}
      </ChipOptions>
      {showEnterPurpose && (
        <FadeInContainer>
          <Field
            size="L"
            Component={Input}
            name="purpose.description"
            disabled={disabled}
            value={getValues('purpose.description')}
            placeholder={t('enterPurpose')}
            validationHelpers={validationHelpers}
            required
          />
        </FadeInContainer>
      )}
    </PurposeFieldset>
  );
};
