import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatementsService } from 'services';
import {
  Institution,
  RequestConsentCollect,
  RequestInstitutionsConsent,
  RequestInstitutionsList
} from 'services/StatementsService/types';

import { StatementsState } from './types';

const initialState: StatementsState = {
  capId: '',
  institutions: [],
  institutionsWithConsent: [],
  consentInitiatedFor: '',
  isLoading: false,
  isError: false,
  reguisitionId: '',
  psd2FlowLink: ''
};

const SANDBOX_ID =
  process.env.REACT_APP_STAGE !== 'stage' && process.env.REACT_APP_STAGE !== 'prod'
    ? 'SANDBOXFINANCE_SFIN0000'
    : '';

const SandboxInstitution = {
  id: SANDBOX_ID,
  name: SANDBOX_ID,
  bic: SANDBOX_ID,
  logo: ''
};

const statementsSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    setCapIdForConsent: (state, action: PayloadAction<string>) => {
      state.capId = action.payload;
    },
    setConsentFlowIsIniatedFor: (state, action: PayloadAction<string>) => {
      state.consentInitiatedFor = action.payload;
    },
    setConsentForInstitution: (state, action: PayloadAction<Institution>) => {
      const { institutionsWithConsent } = state;

      if (institutionsWithConsent) {
        state.institutionsWithConsent = [...institutionsWithConsent, action.payload];
      }
    },
    resetStatementsState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstitutionsByCountryAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchInstitutionsByCountryAsync.fulfilled, (state, action) => {
        state.isLoading = false;

        const { institutions } = action.payload;

        if (institutions) {
          state.institutions = SANDBOX_ID
            ? [...institutions, SandboxInstitution]
            : action.payload.institutions;
        }
      })
      .addCase(fetchInstitutionsByCountryAsync.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });

    builder
      .addCase(fetchInsitutionConsentLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInsitutionConsentLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reguisitionId = action.payload.requisitionId;
        state.psd2FlowLink = action.payload.redirectUrl;

        if (action.payload.redirectUrl) {
          window.location.href = action.payload.redirectUrl;
        }
      })
      .addCase(fetchInsitutionConsentLink.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchCollectData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCollectData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchCollectData.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const fetchInstitutionsByCountryAsync = createAsyncThunk(
  'statements/getInstitutions',
  async (payload: RequestInstitutionsList) => {
    return await StatementsService.getInstitutions(payload);
  }
);

export const fetchInsitutionConsentLink = createAsyncThunk(
  'statements/getInsitutionConsentLink',
  async (payload: RequestInstitutionsConsent) => {
    return await StatementsService.getInsitutionConsentLink(payload);
  }
);

export const fetchCollectData = createAsyncThunk(
  'statements/postCollectData',
  async (payload: RequestConsentCollect) => {
    return await StatementsService.collectData(payload);
  }
);

export const {
  setConsentFlowIsIniatedFor,
  setConsentForInstitution,
  resetStatementsState,
  setCapIdForConsent
} = statementsSlice.actions;

export default statementsSlice.reducer;
