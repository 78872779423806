import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { clearUserCRUDMessage, setUserCRUDErrorMessage, setUserCRUDSuccessMessage } from 'features/app/slice';
import { useCreateUserMutation } from 'features/users/slice';
import { useAppDispatch } from 'hooks/redux/hooks';
import { UserData } from 'services/RbacService/typings';

import { UserRole } from '../../roles/roles';

type UserPayload = UserData & { userEmails: string[]; role: UserRole };

export enum ROLES {
  admin = 1,
  viewer,
  editor,
  approver
}

const createUserPayload = (form: UserPayload) => {
  const payload: Partial<UserPayload> = { ...form };

  delete payload.userEmails;
  delete payload.role;

  payload.userLanguage = payload.userCountry;

  return payload;
};

const useTriggerCreateUser = (_onSuccess?: () => unknown, onError?: () => unknown) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [triggerUserCreation] = useCreateUserMutation();

  const createUser = async (newUserInfo: UserPayload | null) => {
    if (newUserInfo) {
      if (!isSubmitting) {
        setIsSubmitting(true);
        dispatch(clearUserCRUDMessage());

        try {
          const payload = createUserPayload(newUserInfo) as UserData;
          const selectedRole = ROLES[newUserInfo.role as keyof typeof ROLES];

          await triggerUserCreation({
            content: { 'application/json': payload },
            roleId: `${selectedRole}`
          }).unwrap();

          dispatch(setUserCRUDSuccessMessage({ action: t('added') }));
        } catch {
          dispatch(setUserCRUDErrorMessage({ action: t('added') }));
          onError?.();
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  };

  return { isSubmitting, createUser };
};

export default useTriggerCreateUser;
