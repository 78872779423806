import styled from 'styled-components';

import { RegularText } from 'components';

export const ExpandoLinkText = styled(RegularText)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

type CardSectionProps = {
  hidden?: boolean;
};

/**
 * This is used to prevent fetch loop as inner cards are fetching data
 * as well as parrent listening for data changes
 *
 * Because in erro case data is refetched, we need to hide card section instead of not rendering it
 * to prevent infinite fetch loop in case of error
 */
export const CardContentSection = styled.div<CardSectionProps>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
`;

export const CardSkeletonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
