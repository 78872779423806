import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { Modal } from 'components';
import { Note } from 'components/notificationModal/notificationModal.styles';
import { useFetchExistingCustomerOnboardingStatus } from 'features/onboarding/hooks';

type Props = {
  onVisibilityChange?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
};

export const ApplicationReviewModal: FC<Props> = ({ isOpen, onVisibilityChange }) => {
  const { t } = useTranslation();

  const { onboardingNeeded, onboardingInitiationHandler } = useFetchExistingCustomerOnboardingStatus();

  const handleClose = () => {
    onVisibilityChange?.(false);
  };

  const mainActionhandler = onboardingNeeded ? onboardingInitiationHandler : handleClose;
  const mainActionLabel = onboardingNeeded ? 'updateProfile' : 'gotIt';
  const label = onboardingNeeded ? 'applicationInReviewButVerificationNeeded' : 'applicationReview';
  const note = onboardingNeeded ? 'updateProfileToastMessage' : 'applicationReviewExplanation';

  return (
    <Modal
      variant="informational"
      informationalIcon={<Icons.RocketBig />}
      testId="application-review"
      isOpen={!!isOpen}
      onClose={handleClose}
      mainActionhandler={mainActionhandler}
      mainActionLabel={t(mainActionLabel)}
      label={t(label)}
      hideSecondaryAction={true}
    >
      <Note>{t(note)}</Note>
    </Modal>
  );
};
