import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { RegularText, ScreenSize } from 'components';
import { ArticleHeader } from 'components/section/section.style';

import { DonutChart, PieCartData } from '../donutChart/donutChart';
import InfoBox, { Link } from '../infoBox/infoBox';

import {
  DonutChartContainer,
  LimitChart,
  LimitChartContainer,
  TotalsContainer
} from './limitChartSection.styles';

type InfoBoxProps = {
  label?: string;
  value?: number;
  link?: Link;
  infoPopupText?: string;
  children?: ReactNode;
};

type InfoBoxesTypes = 'total' | 'used' | 'available' | 'overdue';

export type InfoBoxes = Record<InfoBoxesTypes, InfoBoxProps>;

type Props = {
  infoBoxes: InfoBoxes;
};

const colors: Record<InfoBoxesTypes, string> = {
  total: '#7F7F7F',
  used: '#2B64F5',
  available: '#A4E694',
  overdue: '#FF7064'
};

export const usedValue = (used?: number, overdue?: number) => {
  if (used && overdue) {
    return overdue >= used ? 0 : used - overdue;
  }

  return 0;
};

export const LimitChartSection: FC<Props> = ({ infoBoxes }) => {
  const { t } = useTranslation();

  const data: PieCartData[] = [
    { name: 'used', value: usedValue(infoBoxes?.used?.value, infoBoxes?.overdue?.value), color: colors.used },
    { name: 'available', value: infoBoxes.available?.value, color: colors.available },
    { name: 'overdue', value: infoBoxes.overdue?.value, color: colors.overdue }
  ];

  return (
    <LimitChart>
      <ArticleHeader>
        <RegularText>{t('limit')}</RegularText>
      </ArticleHeader>
      <LimitChartContainer>
        <TotalsContainer>
          <InfoBox
            popupTestId="total"
            label={infoBoxes.total?.label || t('total')}
            color={colors.total}
            amount={infoBoxes.total?.value}
            infoText={infoBoxes.total?.infoPopupText}
            link={infoBoxes.total?.link}
          >
            {infoBoxes.total?.children}
          </InfoBox>
          <InfoBox
            popupTestId="used"
            label={infoBoxes.used?.label || t('used')}
            color={colors.used}
            amount={infoBoxes.used?.value}
            infoText={infoBoxes.used?.infoPopupText}
            link={infoBoxes.used?.link}
          >
            {infoBoxes.used?.children}
          </InfoBox>
        </TotalsContainer>
        <ScreenSize display="flex" tablet desktop desktopXL>
          <DonutChartContainer>
            <DonutChart data={data} />
          </DonutChartContainer>
        </ScreenSize>
        <TotalsContainer>
          <InfoBox
            popupTestId="available"
            label={infoBoxes.available?.label || t('available')}
            color={colors.available}
            amount={infoBoxes.available?.value}
            infoText={infoBoxes.available?.infoPopupText}
            link={infoBoxes.available?.link}
          >
            {infoBoxes.available?.children}
          </InfoBox>
          <InfoBox
            popupTestId="overdue"
            label={infoBoxes.overdue?.label || t('overdue')}
            color={colors.overdue}
            amount={infoBoxes.overdue?.value}
            infoText={infoBoxes.overdue?.infoPopupText}
            link={infoBoxes.overdue?.link}
          >
            {infoBoxes.overdue?.children}
          </InfoBox>
        </TotalsContainer>
      </LimitChartContainer>
    </LimitChart>
  );
};
