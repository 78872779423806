import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { TableColumn, TableProps } from 'react-data-table-component';

import Icons from '../../assets/icons';
import { LoaderContainer, Loader } from '../loader';
import { RadioButton } from '../radioButton';
import { SecondaryText } from '../text';
import { SecondaryValueText } from '../text/text.styles';

import { Paginator } from './pagination';
import { SelectableRowCheckbox } from './selectableRowCheckbox';
import {
  MobileList,
  HeaderContainer,
  Header,
  CellWithCheckbox,
  MobileListItem,
  MobileListItemRow,
  SecondaryTextWithAction,
  NoDataContainer,
  MobileNoDataExistsMessage,
  MobileListItemHeader,
  MobileListCollapsibleItem,
  MobileListItemHeaderWrapper
} from './table.styles';
import { DefaultItem } from './types';

type MobileCheckboxProps<T extends DefaultItem> = {
  allSelected?: boolean;
  row: T;
  selectedRows: T[];
  label: string;
  setSelectedRows: Dispatch<SetStateAction<T[]>>;
  onSelectedRowsChange:
    | ((selected: { allSelected: boolean; selectedCount: number; selectedRows: T[] }) => void)
    | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

function MobileSelectableRowCheckbox<T extends DefaultItem>({
  row,
  selectedRows,
  label,
  setSelectedRows,
  onSelectedRowsChange
}: MobileCheckboxProps<T>) {
  const onSelect = (row: T) => (selected: boolean) => {
    const result = {
      selectedRows: selected
        ? [...selectedRows, row]
        : selectedRows.filter((selectedRow) => selectedRow.id !== row.id),
      selectedCount: selected ? selectedRows?.length || 0 : 0,
      allSelected: false
    };

    if (process.env.NODE_ENV === 'development') {
      if (!row.id) {
        console.error('Selectable rows require id property');
      }
    }

    setSelectedRows(result.selectedRows);
    onSelectedRowsChange?.(result);
  };

  return (
    <SelectableRowCheckbox
      label={label}
      checked={Boolean(selectedRows.find((selectedRow) => selectedRow.id === row.id))}
      onClick={onSelect(row)}
    />
  );
}

type Props<T extends DefaultItem> = {
  headerLabel: string;
  selectAllCheckboxLabel: string;
  headerSelectAllExplanationLabel: string;
  headerActions: ReactNode | ReactNode[];
  visibleItemsInPage: T[];
  totalRowsCount: number;
  loading: boolean;
  currentPage: number;
  setCurrentPage: (currentPage: number) => unknown;
  onChangePageHandler: (page: number, totalRows?: number) => unknown;
  noDataMessage?: string;
  mobileColumns?: TableColumn<T>[];
  collapsible?: boolean;
};

export function MobileTableList<T extends DefaultItem>({
  data,
  headerLabel,
  selectAllCheckboxLabel,
  headerSelectAllExplanationLabel,
  headerActions,
  visibleItemsInPage,
  onRowClicked,
  columns,
  mobileColumns,
  pagination,
  totalRowsCount,
  loading,
  noDataMessage,
  currentPage,
  onChangePageHandler,
  setCurrentPage,
  collapsible,
  ...rest
}: TableProps<T> & Props<T>) {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const [open, setOpen] = useState<string | null>();

  const onAllSelect = (selected: boolean) => {
    const result = {
      selectedRows: selected ? data.map((item) => ({ ...item, toggleSelected: true })) : [],
      selectedCount: selected ? data?.length || 0 : 0,
      allSelected: selected
    };

    setSelectedRows(result.selectedRows);
    rest.onSelectedRowsChange?.(result);
  };

  useEffect(() => {
    const toggledItem = visibleItemsInPage.find((obj) => {
      return obj.toggleSelected === true;
    });

    setOpen(toggledItem?.id);
  }, [visibleItemsInPage]);

  const noData = !data || !data.length;

  return (
    <MobileList>
      {headerLabel ? (
        <HeaderContainer>
          <Header>{headerLabel}</Header>
          <CellWithCheckbox>
            <SecondaryText>
              {rest.selectableRows ? (
                <SelectableRowCheckbox onClick={onAllSelect} label={selectAllCheckboxLabel} />
              ) : null}
              {headerSelectAllExplanationLabel}
            </SecondaryText>
            {headerActions}
          </CellWithCheckbox>
        </HeaderContainer>
      ) : null}
      {!collapsible
        ? visibleItemsInPage?.map((item, itemIndex) => {
            const mobileRowClickedHandler = (
              e: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
            ) => onRowClicked?.(item, e as React.MouseEvent<HTMLDivElement>);

            return (
              <MobileListItem key={itemIndex} onClick={mobileRowClickedHandler}>
                {(mobileColumns || columns).map((column, index) => {
                  return (
                    <MobileListItemRow key={index}>
                      {rest.selectableRows && index === 0 ? (
                        <CellWithCheckbox>
                          <MobileSelectableRowCheckbox
                            row={item}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            onSelectedRowsChange={rest.onSelectedRowsChange || noop}
                            label={column.name as string}
                          />
                        </CellWithCheckbox>
                      ) : (
                        <SecondaryText>
                          {onRowClicked ? (
                            <SecondaryTextWithAction>
                              {index === 1 && (
                                <RadioButton
                                  checked={Boolean(item.toggleSelected)}
                                  defaultValue={''}
                                  onChange={mobileRowClickedHandler}
                                />
                              )}
                              {column.name}
                            </SecondaryTextWithAction>
                          ) : (
                            column.name
                          )}
                        </SecondaryText>
                      )}
                      {column.name ? (
                        <SecondaryValueText active={Boolean(item.toggleSelected)}>
                          {(column.format || column.cell)?.(
                            item,
                            index,
                            {} as TableColumn<T>,
                            column.id || index
                          )}
                        </SecondaryValueText>
                      ) : (
                        column.format?.(item, index)
                      )}
                    </MobileListItemRow>
                  );
                })}
              </MobileListItem>
            );
          })
        : null}
      {pagination && !noData ? (
        <Paginator
          rowCount={totalRowsCount || data?.length || 0}
          rowsPerPage={rest.paginationPerPage || 0}
          onChangePage={(pageNumber) => {
            if (rest.onChangePage) {
              rest.onChangePage?.(pageNumber, totalRowsCount);
              setCurrentPage(pageNumber);
            } else {
              onChangePageHandler(pageNumber, totalRowsCount);
            }
          }}
          onChangeRowsPerPage={noop}
          currentPage={currentPage}
        />
      ) : null}
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {!loading && noData && (
        <NoDataContainer>
          <MobileNoDataExistsMessage>{noDataMessage}</MobileNoDataExistsMessage>
        </NoDataContainer>
      )}
      {collapsible && (
        <>
          {visibleItemsInPage?.map((item, itemIndex) => {
            const mobileRowClickedHandler = (
              e: ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>
            ) => {
              if (item && item.id) setOpen(item.id);

              return onRowClicked?.(item, e as React.MouseEvent<HTMLDivElement>);
            };

            return (
              <MobileListItem collapsible key={itemIndex} onClick={mobileRowClickedHandler}>
                <MobileListItemHeaderWrapper isOpen={open === item?.id}>
                  <MobileListItemHeader>
                    <RadioButton
                      checked={Boolean(item.toggleSelected)}
                      defaultValue={''}
                      onChange={mobileRowClickedHandler}
                    />
                    {item.contractNumber}
                  </MobileListItemHeader>
                  <Icons.ChevronDown />
                </MobileListItemHeaderWrapper>
                <MobileListCollapsibleItem key={itemIndex} isOpen={open === item?.id}>
                  {(mobileColumns || columns)
                    .filter((el) => {
                      return el.name !== 'Number' && el.name !== 'Status';
                    })
                    .map((column, index) => {
                      return (
                        <MobileListItemRow key={index}>
                          <SecondaryText>{column.name}</SecondaryText>
                          {column.name ? (
                            <SecondaryValueText active={Boolean(item.toggleSelected)}>
                              {(column.format || column.cell)?.(
                                item,
                                index,
                                {} as TableColumn<T>,
                                column.id || index
                              )}
                            </SecondaryValueText>
                          ) : (
                            column.format?.(item, index)
                          )}
                        </MobileListItemRow>
                      );
                    })}
                </MobileListCollapsibleItem>
              </MobileListItem>
            );
          })}
        </>
      )}
    </MobileList>
  );
}
