import styled from 'styled-components';

import { ErrorMessage } from 'components';

export const MandatoryTermsAndConditionsWarning = styled(ErrorMessage)`
  margin: 0.25rem 0;
`;

export const TermsSection = styled.div`
  padding-block: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 33.75rem;
  width: 100%;
  gap: 1rem;
`;
