import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AmountText, RegularText, SecondaryText } from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { InfoBoxes, LimitChartSection } from 'features/contracts/components';
import { selectChosenLoansContractInfo } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';

import { RequestDisbursementButton } from '../requestDisbursementButton/requestDisbursementButton';

import {
  AdditionalFeesSection,
  AditionalInfoHeader,
  AditionalInfoRow,
  DisbursementSection
} from './loansLimitChartSection.styles';

export const RbfLimitChartSection: FC = () => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const contract = useLoansSelector<typeof selectChosenLoansContractInfo>(selectChosenLoansContractInfo)(
    contractId || ''
  );

  if (!contract) return null;

  const {
    contract: contractFeesOverdue,
    overdueAmount,
    loanAmount,
    availableAmount,
    usedAmount,
    interestAmount,
    defaultInterest
  } = contract;

  const showAdditionalFees = interestAmount || defaultInterest || contractFeesOverdue;

  const availableAdditionalInfo = availableAmount ? (
    <AditionalInfoRow>
      <DisbursementSection>
        <RequestDisbursementButton />
      </DisbursementSection>
    </AditionalInfoRow>
  ) : null;

  const overdueAdditionalInfo = showAdditionalFees ? (
    <AdditionalFeesSection data-testid="additional-fees-section">
      <AditionalInfoHeader>
        <RegularText>{t('additionalFees')}</RegularText>
      </AditionalInfoHeader>
      {interestAmount ? (
        <AditionalInfoRow>
          <SecondaryText>{t('interest')}</SecondaryText>
          <SecondaryText>
            <AmountText>{interestAmount}</AmountText>
          </SecondaryText>
        </AditionalInfoRow>
      ) : null}
      {defaultInterest ? (
        <AditionalInfoRow>
          <SecondaryText>{t('overdueInterest')}</SecondaryText>
          <SecondaryText>
            <AmountText>{defaultInterest}</AmountText>
          </SecondaryText>
        </AditionalInfoRow>
      ) : null}
      {contractFeesOverdue ? (
        <AditionalInfoRow>
          <SecondaryText>{t('contract')}</SecondaryText>
          <SecondaryText>
            <AmountText>{contractFeesOverdue}</AmountText>
          </SecondaryText>
        </AditionalInfoRow>
      ) : null}
    </AdditionalFeesSection>
  ) : null;

  const infoBoxes: InfoBoxes = {
    total: {
      value: loanAmount,
      infoPopupText: t('loansTotalAmountExplanation')
    },
    used: {
      value: usedAmount,
      infoPopupText: t('loansUsedAmountExplanation')
    },
    available: {
      value: availableAmount,
      infoPopupText: t('loansAvailableAmountExplanation'),
      children: availableAdditionalInfo
    },
    overdue: {
      value: overdueAmount,
      infoPopupText: t('loansOverdueAmountExplanation'),
      link: isFeatureEnabled('bills')
        ? { url: `/finance/rbf/bills?contractId=${contractId}&billStatus=OVERDUE`, label: t('view') }
        : undefined,
      children: overdueAdditionalInfo
    }
  };

  return <LimitChartSection infoBoxes={infoBoxes} />;
};
