import { useEffect, useState } from 'react';

import { BlueCheckmark } from '../checkbox';

import { CountryCodes, getCountriesList } from './constants';
import { CountryDisplayName, CountryLabel, SearchListContainer } from './countriesList.styles';
import CountryFlagIcon from './countryFlag';

type Props = {
  language: 'en' | 'lt';
  whitelist?: CountryCodes[];
  blacklist?: CountryCodes[];
  onSelect: (value: CountryCodes) => unknown;
  onClick?: () => unknown;
  selectedValue: CountryCodes;
  searchQuery?: string;
  areaCodesEnabled?: boolean;
  prioritisedCountries?: CountryCodes[];
};

const CountriesList = ({
  language,
  whitelist,
  blacklist,
  selectedValue,
  searchQuery,
  areaCodesEnabled,
  prioritisedCountries,
  onSelect,
  onClick
}: Props) => {
  const allSupportedCountriesOptions = getCountriesList(language, areaCodesEnabled, prioritisedCountries)
    .filter((countryOption) => !whitelist || whitelist?.includes(countryOption.value))
    .filter((countryOption) => !blacklist || !blacklist?.includes(countryOption.value));

  const [filteredCountries, setFilteredCountries] = useState(allSupportedCountriesOptions);

  const handleSearch = (searchValue: string) => {
    const filteredOptions = allSupportedCountriesOptions.filter(
      (countryOption) =>
        countryOption.label?.toLowerCase().includes(searchValue) ||
        countryOption.value.toLowerCase().includes(searchValue)
    );

    setFilteredCountries(filteredOptions);
  };

  useEffect(() => {
    setFilteredCountries(allSupportedCountriesOptions);
  }, [areaCodesEnabled, whitelist]);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery?.toLocaleLowerCase());
    } else {
      setFilteredCountries(allSupportedCountriesOptions);
    }
  }, [searchQuery]);

  return (
    <SearchListContainer data-testid="country-list">
      {filteredCountries.map((country, index) => (
        <CountryLabel
          data-testid={`country-option-${country.value}`}
          tabIndex={index}
          key={country.value}
          onClick={onClick}
        >
          <CountryFlagIcon flagCode={country.flagCode} />
          <CountryDisplayName>{country.label}</CountryDisplayName>
          <BlueCheckmark
            checked={country.value === selectedValue}
            onChange={() => {
              onSelect(country.value as CountryCodes);
            }}
            name="country"
          />
        </CountryLabel>
      ))}
    </SearchListContainer>
  );
};

export default CountriesList;
