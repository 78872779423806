import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InlineLink, Loader } from 'components';
import { OverlayView } from 'components/pageV2/overlayView';
import { useModalContext } from 'features/nav/context/modalContext';

import {
  Body,
  CounterButton,
  Header,
  ManualRedirectLink,
  RedirectCard,
  RedirectCardContent,
  RedirectLoaderContainer,
  RedirectView
} from './ondatoRedirect.styles';

type Props = {
  redirectTo: string;
  registrationId?: string;
};

const DEFAULT_TIMEOUT_BEFORE_REDIRECT = 5;

const OndatoRedirect = ({ redirectTo, registrationId = 'ondato-redirect' }: Props) => {
  const { t } = useTranslation();
  const { registerModalHash } = useModalContext();

  const timerRef = useRef<number | null>(null);

  const [timer, setTimer] = useState(DEFAULT_TIMEOUT_BEFORE_REDIRECT);
  const [open, setOpen] = useState(false);

  const handleRedirect = () => {
    window.open(redirectTo, '_self');
  };

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    if (open && !timerRef.current && redirectTo) {
      setTimer(DEFAULT_TIMEOUT_BEFORE_REDIRECT);

      timerRef.current = setInterval(() => {
        setTimer((prevState) => {
          if (prevState - 1 === 1) {
            clearTimer();
            handleRedirect();
          }

          return prevState - 1;
        });
      }, 1000) as unknown as number;
    }

    if (!open) {
      clearTimer();
    }

    return () => {
      if (timerRef.current) {
        clearTimer();
      }
    };
  }, [open, redirectTo]);

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const modals = registerModalHash(registrationId, handleToggle);

    return () => {
      modals.unregister();
    };
  }, []);

  return (
    <OverlayView direction="right" open={open} onClose={handleToggle}>
      <RedirectView>
        <RedirectCard>
          <RedirectLoaderContainer>
            <Loader noMargin transparent />
          </RedirectLoaderContainer>
          <RedirectCardContent>
            <Header>{t('youAreBeingRedirectedToOndato')}</Header>
            <Body>{t('youAreBeingRedirectedToOndatoDescription')}</Body>
            <CounterButton onClick={handleRedirect}>
              <Trans i18nKey={'continueInSeconds'} components={{ span: <span /> }} count={timer} />
            </CounterButton>
          </RedirectCardContent>
          <ManualRedirectLink>
            <Trans
              i18nKey={'redirectToOndatoManualLink'}
              components={{ ondatoLink: <InlineLink href={redirectTo} target="_self" /> }}
            ></Trans>
          </ManualRedirectLink>
        </RedirectCard>
      </RedirectView>
    </OverlayView>
  );
};

export default OndatoRedirect;
