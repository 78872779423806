import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { InfoBoxes, LimitChartSection } from 'features/contracts/components';
import { useFetchSelectedContract } from 'features/contracts/hooks';
import { selectInvoicesTotalsByContract } from 'features/invoices/selectors';
import { fetchInvoicesTotalsAsync } from 'features/invoices/slice';
import { useAppDispatch, useInvoicesSelector } from 'hooks/redux/hooks';

const getUploadInvoicesPageLink = (id: string) => {
  return `/finance/invoices/upload?contractId=${id}`;
};

export const FactoringLimitChartSection: FC = () => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId') || '';

  const { selectedContract, refetch } = useFetchSelectedContract();

  useEffect(() => {
    if (contractId) {
      dispatch(fetchInvoicesTotalsAsync({ contractId }));
      refetch();
    }
  }, [contractId, dispatch, refetch]);

  const isActiveContract = selectedContract?.status === 'ACTIVE';

  const totals = useInvoicesSelector<typeof selectInvoicesTotalsByContract>(selectInvoicesTotalsByContract);

  if (!selectedContract) return null;

  const { factoringCreditLimit, usedFactoringCreditLineAmount, availableFactoringCreditLineAmount } =
    selectedContract;

  const infoBoxes: InfoBoxes = {
    total: {
      value: factoringCreditLimit,
      infoPopupText: t('infoTextForContractsTotal')
    },
    used: {
      value: usedFactoringCreditLineAmount,
      infoPopupText: t('infoTextForContractsUsed')
    },
    available: {
      value: availableFactoringCreditLineAmount,
      link:
        isFeatureEnabled('invoiceUpload') && isActiveContract
          ? {
              url: getUploadInvoicesPageLink(selectedContract.id || ''),
              label: t('linkTextForContractsAvailable')
            }
          : undefined,
      infoPopupText: t('infoTextForContractsAvailable')
    },
    overdue: {
      value: totals.overdueInvoicesAmount,
      link: {
        url: `/finance/factoring/invoices?contractId=${contractId}&invoiceStatus=OVERDUE`,
        label: t('linkTextForContractsOverdue')
      },
      infoPopupText: t('infoTextForContractsOverdue')
    }
  };

  return <LimitChartSection infoBoxes={infoBoxes} />;
};
