import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { LoanInvoiceItem } from 'services/LoansService/types';

import { filterPaymentsList } from './filterPaymentsList';
import { BillsFilterParams, loansBillsFilterDefaultParams } from './loansBillsFilterFields';

export const useBillsFilteredData = (
  items?: LoanInvoiceItem[]
): [LoanInvoiceItem[], BillsFilterParams, Dispatch<SetStateAction<BillsFilterParams>>] => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search);

  const billStatus = searchParams.get('billStatus') || '';

  const [appliedFilterParams, setAppliedFilterParams] = useState(loansBillsFilterDefaultParams);

  useEffect(() => {
    if (billStatus) {
      setAppliedFilterParams({ ...loansBillsFilterDefaultParams, billStatus });

      searchParams.delete('billStatus');
      setSearchParams(searchParams, { replace: true });
    }
  }, [billStatus, searchParams, setSearchParams]);

  const filteredItems = filterPaymentsList(items, appliedFilterParams);

  return [filteredItems, appliedFilterParams, setAppliedFilterParams];
};
