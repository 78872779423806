import 'react-phone-input-2/lib/high-res.css';

import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultPhoneInput, { CountryData } from 'react-phone-input-2';

import { getAreaCodes, getCountriesList } from '../countriesList/constants';
import { ErrorMessage } from '../formError';
import { FieldState } from '../formField/field';
import { InputContainer, InputMessagesContainer } from '../input/input.style';

import { AreaCodeSelectOverride, PhoneFieldContainer, StyledPhoneInputContainer } from './phoneField.styles';

type Props = {
  value: string;
  placeholder: string;
  defaultCountry?: string;
  onDropdownButtonClick?: () => unknown;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>, data: CountryData) => unknown;
  onChange: (value: string) => void;
  fieldMeta?: FieldState;
  required?: boolean;
  disabled?: boolean;
  absoluteMessage?: boolean;
  size?: 'M' | 'L';
};

const PhoneField: FC<Props> = ({
  value,
  placeholder,
  defaultCountry,
  onBlur,
  onChange,
  fieldMeta,
  required,
  disabled,
  absoluteMessage,
  size,
  onDropdownButtonClick
}) => {
  const { t, i18n } = useTranslation();

  const { error, showValidationMessage, message } = fieldMeta || {};

  const [phoneFieldWidth, setPhoneFieldWidth] = useState(0);
  const [fieldValue, setValue] = useState<string | undefined>(value);
  const [showPlaceholderLabel, setShowPlaceholderLabel] = useState(true);

  const phoneFieldContainerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const resizeObserverRef = useRef(
    new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setPhoneFieldWidth(entry.contentBoxSize[0].inlineSize);
      });
    })
  );

  const handleChange = (value: string) => {
    setValue(value);
    onChange(`+${value}`);
  };

  const onFocusHandler = () => {
    setShowPlaceholderLabel(false);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>, data: CountryData) => {
    setShowPlaceholderLabel(true);
    onBlur?.(event, data);
  };

  const hasValidationMessages = error?.message;
  const hasPlaceholder = Boolean(showPlaceholderLabel && placeholder);

  const placeholderText = required ? placeholder : t('placeholderOptional', { placeholder });

  const localization = getCountriesList(i18n.language as 'en' | 'lt').reduce(
    (a, countryOption) => ({
      ...a,
      [countryOption.value.toLowerCase()]: countryOption.label
    }),
    {}
  );

  useEffect(() => {
    if (resizeObserverRef.current) {
      if (phoneFieldContainerRef.current) {
        resizeObserverRef.current.observe(phoneFieldContainerRef.current);
      }
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  const resolvedCountry = (defaultCountry || 'lt').toLowerCase();

  const resolvedSize = size ?? 'M';

  return (
    <InputContainer>
      <PhoneFieldContainer
        ref={phoneFieldContainerRef}
        data-testid={'phone-number-input-container'}
        invalidStatus={Boolean(hasValidationMessages)}
        inputSize={resolvedSize}
      >
        <StyledPhoneInputContainer
          showLabel={hasPlaceholder && !fieldValue}
          hasError={Boolean(error)}
          disabled={disabled}
          width={phoneFieldWidth}
          inputSize={resolvedSize}
        >
          <DefaultPhoneInput
            searchNotFound={t('noResultsFound')}
            localization={localization}
            enableSearch
            countryCodeEditable={false}
            country={resolvedCountry}
            value={fieldValue}
            onChange={handleChange}
            placeholder={placeholderText}
            specialLabel={placeholderText}
            searchPlaceholder={t('searchForCountry')}
            disabled={disabled}
            masks={{ lt: '........' }}
            containerClass="phoneInputContainer"
            inputClass="phoneInput"
            buttonClass={'phoneInputButton'}
            dropdownClass="phoneInputDropdown"
            searchClass="phoneInputSearch"
            onFocus={onFocusHandler}
            areaCodes={getAreaCodes()}
            onBlur={onBlurHandler}
          />
          {onDropdownButtonClick ? (
            <AreaCodeSelectOverride
              data-testid="phone-number-area-code-button"
              onClick={onDropdownButtonClick}
            ></AreaCodeSelectOverride>
          ) : null}
        </StyledPhoneInputContainer>
        <InputMessagesContainer absoluteMessage={absoluteMessage}>
          <ErrorMessage hidden={!hasValidationMessages || !showValidationMessage}>{message}</ErrorMessage>
        </InputMessagesContainer>
      </PhoneFieldContainer>
    </InputContainer>
  );
};

export default PhoneField;
