import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, lt } from 'date-fns/locale';
import 'flag-icons/css/flag-icons.css';
import React, { Suspense, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { Navigate, Params, Route, Routes } from 'react-router-dom';
import './translations/translations';

import { Loader, useCustomPrivacyTrigger } from 'components';
import { ActionToast } from 'components/actionToast';
import { OverlayView } from 'components/pageV2/overlayView';
import { GeneralTermsAndConditions, HelpPopup } from 'features/app/components';
import { useGeneralActionToast } from 'features/app/hooks';
import { useScrollBarOffsetVariable } from 'features/app/hooks/useScrollBarOffsetVariable';
import { setAdds } from 'features/app/slice';
import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { SessionTimeout } from 'features/auth/components';
import { SSOMobileLogin } from 'features/auth/methods';
import {
  selectIsRefreshTokenLoading,
  selectIsRegularUser,
  selectIsUserAuthenticated,
  selectIsUserPartiallyAuthenticated
} from 'features/auth/selectors';
import { ModalContextProvider } from 'features/nav/context/modalContext';
import { NavContextProvider } from 'features/nav/context/navContext';
import { PublicRoute } from 'features/nav/PublicRoute';
import { LobbyOverlay, OndatoIntro, OndatoRedirect, OndatoStatusModal } from 'features/onboarding/components';
import { useAppDispatch, useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import NewApplicationWrapper from 'pages/cap/applicationWrapper';
import { PageNotFound } from 'pages/error';
import { RedirectLogin } from 'pages/login';
import { PersonalInfoUpdatePage } from 'pages/login/customerWithMissingInfo';
import { Onboarding } from 'pages/onboarding/onboarding';
import { Settings } from 'pages/settings';
import { injectStore } from 'services/RestClient/RestClient';
import tracking from 'utils/tracking';

import ProtectedRoute from './features/nav/ProtectedRoute';
import { useFetchExistingCustomerOnboardingStatus } from './features/onboarding/hooks';
import { LoginPage } from './pages';
import { MyDashboard } from './pages/dashboard/dashboard';
import { FinanceDashboard } from './pages/finance/dashboard';
import applyWithResolversPolyfill from './polyfills';
import store from './store';
import { getLanguageCode } from './translations/utils';

const InvoiceUpload = React.lazy(() => import('./pages/invoices/upload'));
const BuyerVerification = React.lazy(() => import('./pages/invoices/verification'));

injectStore(store);
applyWithResolversPolyfill();

if (location.hostname === 'test.my.sme-go.com') {
  location.href = location.href.replace('test.my.sme-go.com', 'dev.sp.smefinance.eu');
}

const questionaireHost = VARIABLES.companiesQuestionaire as string;

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isTokenRefreshLoading =
    useAuthSelector<typeof selectIsRefreshTokenLoading>(selectIsRefreshTokenLoading);
  const isUserAuthenticated = useAuthSelector<typeof selectIsUserAuthenticated>(selectIsUserAuthenticated);
  const isRegularUser = useAuthSelector<typeof selectIsRegularUser>(selectIsRegularUser);
  const isUserPartiallyAuthenticated = useAuthSelector<typeof selectIsUserPartiallyAuthenticated>(
    selectIsUserPartiallyAuthenticated
  );
  const isCompanyVerified = useAppSelector(selectIsActiveCompanyVerified);

  const [, { isVisible, toast, clearToast }] = useGeneralActionToast();

  useScrollBarOffsetVariable();
  useCustomPrivacyTrigger();

  const isAuthenticated = isUserAuthenticated || isUserPartiallyAuthenticated;

  const validateCompanyLevelOnMainDashboardNavigate = (params: Readonly<Params<string>>) => {
    const allowedDashboardSubcategory = 'verification';

    const { subCategory } = params;
    const accessingUnverified = allowedDashboardSubcategory !== subCategory && !isCompanyVerified;

    return !accessingUnverified;
  };

  useEffect(() => {
    const addsParams = tracking.readAddsParams();

    dispatch(setAdds(addsParams));
  }, [dispatch]);

  const { data: existingCustomerOnboardingStatus } = useFetchExistingCustomerOnboardingStatus();

  const locales = {
    lt,
    en: enGB
  };

  const activeLocale = locales[getLanguageCode(i18n.language) as 'en' | 'lt'];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={activeLocale}>
      <NavContextProvider>
        <ModalContextProvider>
          <iframe
            title="onboarding_frame"
            id="onboarding_frame"
            width={1}
            height={1}
            style={{ display: 'none', position: 'absolute', left: -1000, top: -1000 }}
            src={`${questionaireHost}smego`}
          />
          <LobbyOverlay />
          <OndatoIntro
            registrationId="existing-customer-ondato-intro"
            triggerModalId="existing-customer-ondato-redirect"
          />
          <OndatoRedirect
            registrationId="existing-customer-ondato-redirect"
            redirectTo={existingCustomerOnboardingStatus?.ondatoUrl ?? ''}
          />
          <OndatoStatusModal />
          <ActionToast
            onClose={clearToast}
            variant={toast?.status}
            isVisible={isVisible}
            header={toast?.header}
            message={toast?.message}
          />
          {isTokenRefreshLoading ? <Loader opaque /> : null}
          <Suspense
            fallback={
              <OverlayView direction="right" open>
                <Loader noMargin />
              </OverlayView>
            }
          >
            {isAuthenticated ? <SessionTimeout /> : null}
            {isUserAuthenticated && isRegularUser ? <GeneralTermsAndConditions /> : null}
            <Routes>
              {/** Public routes (Login, etc...) */}
              <Route
                path="/login/sso"
                element={<PublicRoute featureName="mobileSSO" title="login" outlet={<SSOMobileLogin />} />}
              />

              <Route
                path="/onboarding"
                element={
                  <PublicRoute featureName="verificationFlow" title="Onboarding" outlet={<Onboarding />} />
                }
              />
              <Route path="/login" element={<PublicRoute title="login" outlet={<LoginPage />} />} />
              <Route
                path="/login/redirect"
                element={<PublicRoute title="login" outlet={<RedirectLogin />} />}
              />
              <Route
                path="/login/otp"
                element={<PublicRoute title="login" outlet={<LoginPage otpOnly />} />}
              />

              {/** Tab routes (Sme finance, my dashboard, etc...) */}
              <Route
                path="/dashboard/:subCategory"
                element={
                  <ProtectedRoute
                    companyLevel={['VERIFIED', 'PROSPECT']}
                    validateOnNavigate={validateCompanyLevelOnMainDashboardNavigate}
                    outlet={<MyDashboard />}
                    redirectToOriginalUrl
                  />
                }
              />
              <Route path="/finance/:category" element={<ProtectedRoute outlet={<FinanceDashboard />} />}>
                <Route path=":subCategory" element={<ProtectedRoute outlet={<FinanceDashboard />} />}>
                  <Route
                    path=":itemId"
                    element={<ProtectedRoute outlet={<FinanceDashboard />} redirectToOriginalUrl />}
                  />
                </Route>
                <Route />
              </Route>

              {/** CAP flow routes */}
              <Route
                path="/new-application"
                element={
                  <ProtectedRoute
                    featureName="customerApplicationProduct"
                    title="newApplication"
                    companyLevel={['VERIFIED', 'PROSPECT']}
                    systems={['goCredits']}
                    outlet={<NewApplicationWrapper />}
                    preserveQueryParams
                    redirectToOriginalUrl
                  />
                }
              >
                <Route
                  path=":capId"
                  element={
                    <ProtectedRoute
                      featureName="customerApplicationProduct"
                      title="application"
                      companyLevel={['VERIFIED', 'PROSPECT']}
                      systems={['goCredits']}
                      outlet={<NewApplicationWrapper />}
                      redirectToOriginalUrl
                    />
                  }
                />
              </Route>

              {/** Invoices */}
              <Route
                path="/finance/invoices/upload"
                element={
                  <ProtectedRoute
                    featureName="invoiceUpload"
                    title="uploadInvoices"
                    systems={['goFinance']}
                    outlet={<InvoiceUpload />}
                  />
                }
              />
              <Route
                path="/finance/invoices/verification"
                element={
                  <ProtectedRoute
                    featureName="invoiceUpload"
                    authLevel="partial"
                    preserveQueryParams
                    title="Invoices verification"
                    systems={['goFinance']}
                    outlet={<BuyerVerification />}
                    redirectToOriginalUrl
                  />
                }
              />

              {/** Settings */}
              <Route
                path="/settings/update-info"
                element={
                  <ProtectedRoute
                    authLevel="partial"
                    title="Info update"
                    outlet={<PersonalInfoUpdatePage />}
                  />
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute
                    featureName="userSettings"
                    title="settings"
                    companyLevel={['VERIFIED', 'PROSPECT']}
                    outlet={<Settings />}
                  />
                }
              />
              <Route path="/" element={<Navigate to="/dashboard/overview" />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
          <HelpPopup />
        </ModalContextProvider>
      </NavContextProvider>
    </LocalizationProvider>
  );
};

export default App;
