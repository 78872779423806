import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import briefcase from 'assets/icons/briefcaseMedium.svg';
import { FormList, Toast } from 'components';
import { FormContainer } from 'features/application/components/formContainer';
import FormContext from 'features/application/context/FormContext';
import { DrawerImplementation } from 'features/application/drawers';
import { selectFactoringType, selectThirdParties } from 'features/application/selectors';
import { submitApplicationAsync } from 'features/application/slice';
import { useNavContext } from 'features/nav/context/navContext';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';

import { PARTY_TYPE_VALUE } from '../types/formTypes';

import { ThirdPartiesFormContainer } from './thirdPartiesForm.styles';

const ThirdPartiesForm: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const thirdParties = useApplicationSelector<typeof selectThirdParties>(selectThirdParties);
  const factoringType = useApplicationSelector<typeof selectFactoringType>(selectFactoringType);

  const { toggleRightSideDrawer } = useNavContext<DrawerImplementation<'thirdParty'>>();

  const [submitedWithoutParties, setSubmitedWithoutParties] = useState<boolean>(false);

  const { setFormHandlers } = useContext(FormContext);

  const saveSubmitHandler = () => {
    if (thirdParties?.length) {
      dispatch(submitApplicationAsync());

      return;
    }

    setSubmitedWithoutParties(true);
  };

  useEffect(
    () =>
      setFormHandlers({
        submitHandler: saveSubmitHandler
      }),
    [setFormHandlers]
  );

  const addOwner = () => {
    toggleRightSideDrawer?.({
      drawerId: 'thirdParty',
      mode: 'add'
    });
  };

  const editParties = (key: number) => {
    toggleRightSideDrawer?.({
      drawerId: 'thirdParty',
      mode: 'edit',
      keyInEdit: key
    });
  };

  const addTextId = factoringType?.partyType === PARTY_TYPE_VALUE.BUYERS ? 'addBuyer' : 'addSuplier';

  const partiesList =
    thirdParties?.map((key) => ({
      title: key.company?.name ?? ''
    })) ?? [];

  const isThirdPartiesAdded = (thirdParties?.length ?? 0) > 0;

  return (
    <FormContainer isValid={isThirdPartiesAdded} onNextClick={saveSubmitHandler}>
      <ThirdPartiesFormContainer>
        <FormList
          onItemClick={editParties}
          itemIcon={briefcase}
          addButtonLabel={t(addTextId)}
          onAddCardClick={addOwner}
          list={partiesList}
          onItemEdit={editParties}
        ></FormList>
        <Toast isVisible={submitedWithoutParties} header={t('noThirdPartiesAddedError')} />
      </ThirdPartiesFormContainer>
    </FormContainer>
  );
};

export default ThirdPartiesForm;
