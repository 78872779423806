import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { Button } from 'components/buttonV2';
import { OverlayView } from 'components/pageV2/overlayView';
import { useModalContext } from 'features/nav/context/modalContext';

import {
  IntroCard,
  IntroCardHeader,
  IntroCardRow,
  IntroCardRowColumn,
  IntroCardSubheader,
  IntroView
} from './ondatoIntro.styles';

type Props = {
  registrationId?: string;
  triggerModalId?: string;
};

const OndatoIntro = ({ registrationId = 'ondato-intro', triggerModalId = 'ondato-redirect' }: Props) => {
  const { t } = useTranslation();

  const { modals, registerModalHash } = useModalContext();

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };

  const handleStartVerification = () => {
    modals?.get(triggerModalId)?.();
  };

  useEffect(() => {
    const modals = registerModalHash(registrationId, handleToggle);

    return () => {
      modals.unregister();
    };
  }, []);

  return (
    <OverlayView title={t('letsConfirmItsYou')} direction="right" open={open} onClose={handleToggle}>
      <IntroView>
        <IntroCard>
          <IntroCardHeader>{t('verificationChecklist')}</IntroCardHeader>
          <IntroCardRow>
            <Icons.PersonalId />
            <IntroCardRowColumn>
              <IntroCardHeader>{t('proofOfIdentity')}</IntroCardHeader>
              <IntroCardSubheader>{t('proofOfIdentityDescription')}</IntroCardSubheader>
            </IntroCardRowColumn>
          </IntroCardRow>
          <IntroCardRow>
            <Icons.Camera />
            <IntroCardRowColumn>
              <IntroCardHeader>{t('fewSelfies')}</IntroCardHeader>
              <IntroCardSubheader>{t('fewSelfiesDescription')}</IntroCardSubheader>
            </IntroCardRowColumn>
          </IntroCardRow>
        </IntroCard>
        <Button media={{ maxPhone: { size: 'L' } }} onClick={handleStartVerification}>
          {t('startVerification')}
        </Button>
      </IntroView>
    </OverlayView>
  );
};

export default OndatoIntro;
