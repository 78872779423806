import styled from 'styled-components';

import filter from '../../assets/icons/filter.svg';
import { Button } from '../buttonV2';
import { LinkButton } from '../link';

export const FilterPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  margin-bottom: auto;
`;
export const FilterPanelRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 1rem;
  gap: 1rem;
`;

export const ClearButtonContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 14.9375rem;
  width: 100%;
  height: 3rem;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const FilterPanelModalContainer = styled(FilterPanelContainer)`
  ${FilterPanelRow} {
    flex-direction: column;
  }

  ${InputContainer} {
    max-width: 100%;
    margin: 1.25rem 0 0;

    &:first-of-type {
      margin: 0;
    }
  }

  ${FilterPanelRow} {
    margin: 1.25rem 0 0;

    &:first-of-type {
      margin: 0;
    }
  }

  ${ClearButtonContainer} {
    display: none;
  }
`;

export const ActionButton = styled(LinkButton)`
  font-size: 1rem;
  line-height: 1.5rem;

  .filter {
    path {
      stroke: currentColor;
    }
  }
`;

export const ActionsPanelRow = styled(FilterPanelRow)`
  display: flex;
  justify-content: space-between;
  padding: 0;

  ${ClearButtonContainer} {
    width: auto;
  }
`;

export const FilterButton = styled(Button).attrs({
  color: 'LightBlack',
  size: 'S',
  icons: { left: filter }
})`
  padding: 0.25rem 0.75rem;
`;

export const FilterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
