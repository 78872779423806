import { AuthState } from './types/authTypes';

export const selectIsOTPInitiated = (state: AuthState) => state?.isOTPInitiated;
export const selectIsUserAuthenticated = (state: AuthState) => {
  return Boolean(state?.isUserAuthenticated && sessionStorage.getItem('user'));
};
export const selectIsUserPartiallyAuthenticated = (state: AuthState) => {
  return state?.isUserPartiallyAuthenticated && sessionStorage.getItem('user');
};
export const selectIsUserGhost = (state: AuthState) => state?.isGhostUser;
export const selectSessiondId = (state: AuthState) => state?.sessionId;
export const selectUserData = (state: AuthState) => state?.userData;
export const selectCustomerInformation = (state: AuthState) => state?.customerInformation;
export const selectRedirectSessionId = (state: AuthState) => state?.redirectSessionId;
export const selectActiveCustomer = (state: AuthState) => state?.activeCustomer;
export const selectCanMangeUsers = (state: AuthState) => {
  const permissions = selectActiveCustomersPermissions(state);

  return permissions?.includes('MANAGE_USERS');
};
export const selectActiveCustomersPermissions = (state: AuthState) => {
  const activeCustomer = selectActiveCustomer(state) || { roles: [] };

  return activeCustomer?.roles;
};
export const selectIsCustomerAdmin = (state: AuthState) => {
  const permissions = selectActiveCustomersPermissions(state);

  return (
    (permissions?.length === 2 && permissions.includes('READ') && permissions.includes('MANAGE_USERS')) ||
    permissions?.includes('SALES_ADMIN')
  );
};
export const selectIsWaitingForConfirmation = (state: AuthState) => state?.waitingForConfirmation;
export const selectHasChallengeLoginFailed = (state: AuthState) => state?.challengeLoginFailed;
export const selectHasChallengeCompleted = (state: AuthState) => state?.chalengeCompleted;
export const selectVerificationCode = (state: AuthState) => state?.verificationCode;
export const selectIsLoading = (state: AuthState) => state?.isLoading;
export const selectIsRefreshTokenLoading = (state: AuthState) => state?.isRefreshTokenLoading;
export const selectIsEmbededInMobileWebview = (state: AuthState) => state?.isMobileAppWebview;
export const selectIsIndentityStepUp = (state: AuthState) => state?.identityStepUp;

export const selectIsRegularUser = (state: AuthState) => {
  const isAuth = selectIsUserAuthenticated(state);
  const isPartialAuth = selectIsUserPartiallyAuthenticated(state);
  const isGhost = selectIsUserGhost(state);

  return isAuth && !isPartialAuth && !isGhost;
};
