import { useCallback, useEffect } from 'react';

const useKeyboardHandler = (handleEscape?: () => unknown, handleEnter?: () => unknown) => {
  const memoizedHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        handleEscape?.();
      }
      if (e.key === 'Enter') {
        e.stopPropagation();
        handleEnter?.();
      }
    },
    [handleEscape, handleEnter]
  );

  useEffect(() => {
    window.addEventListener('keydown', memoizedHandler);
    return () => {
      window.removeEventListener('keydown', memoizedHandler);
    };
  }, [memoizedHandler]);
};

export default useKeyboardHandler;
