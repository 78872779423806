import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyrightText, CopyrightTextContainer } from './copyright.styles';
import { CopyrightLinks } from './copyrightLinks';

type Props = {
  className?: string;
};

export const Copyright: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <CopyrightTextContainer className={className}>
      <CopyrightText>
        &copy; {currentYear}
        {t('copyrightLabel')}
      </CopyrightText>
      <CopyrightLinks />
    </CopyrightTextContainer>
  );
};
