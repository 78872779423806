import { useEffect } from 'react';

import { selectIsIndentityStepUp, selectUserData } from 'features/auth/selectors';
import { logoutAsync, validateUserEmail } from 'features/auth/slice';
import { ModalsRegistry, useModalContext } from 'features/nav/context/modalContext';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { AppService } from 'services';
import { ResponseValidateUser } from 'services/LoginService/typings';
import { OndatoStatusResponse } from 'services/OnboardingService/types';
import tracking from 'utils/tracking';

import getTypedSearchParams from '../utils/getTypedSearchParams';

const validateUserWithRetry = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  email: string,
  breakerCount = 12
): Promise<ResponseValidateUser> => {
  if (breakerCount === 0) {
    return Promise.reject(new Error('Failed to validate user'));
  }

  await new Promise((resolve) => setTimeout(resolve, 1500)); // sleep in between calls 1.5s sleep -> 1req -> 3s sleep -> 1req ...
  const response = await dispatch(validateUserEmail({ user: email })).unwrap();

  if (!response.userValid || response.userAdditionalInfoRequired) {
    await new Promise((resolve) => setTimeout(resolve, 1500));

    return await validateUserWithRetry(dispatch, email, breakerCount - 1);
  }

  return response;
};

const validLogoutStatuses: OndatoStatusResponse['status'][] = [
  'ONDATO_FINISHED',
  'SME_CUSTOMER_ONBOARDING_NOT_FOUND'
];

const logoutWithMessage = async (
  dispatch: ReturnType<typeof useAppDispatch>,
  ondatoStatus: OndatoStatusResponse['status'],
  modals: ModalsRegistry,
  email: string
) => {
  try {
    await validateUserWithRetry(dispatch, email);

    modals?.get('ondato-status')?.(ondatoStatus === 'ONDATO_FINISHED_WITH_ERROR' ? 'error' : 'success');

    if (validLogoutStatuses.includes(ondatoStatus)) {
      modals?.get('flow-lobby')?.({ screen: 'ondato', visible: false });
      dispatch(
        logoutAsync({
          identityStepUp: true
        })
      );
      tracking.setSubmitEvent('single_flow_ondato_flow_finished');
    }
  } catch (e) {
    AppService.reportError({ message: 'Failed to validate user' });
  } finally {
    modals?.get('flow-lobby')?.({ screen: 'ondato', visible: false });
  }
};

const useStepNotifications = (
  onAuthenticationStepUp: (show: boolean) => unknown,
  onOnboardingSuccess: (show: boolean) => unknown,
  ondatoInfo: OndatoStatusResponse
) => {
  const { modals } = useModalContext();
  const dispatch = useAppDispatch();
  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const isIdentityStepUp = useAuthSelector(selectIsIndentityStepUp);

  const searchParams = getTypedSearchParams();
  const { identitySuccess, onboardingSuccess } = searchParams;

  useEffect(() => {
    (async () => {
      if (typeof identitySuccess !== 'undefined' && identitySuccess && ondatoInfo.status && modals) {
        modals?.get('flow-lobby')?.({ screen: 'ondato', visible: true });
        await logoutWithMessage(dispatch, ondatoInfo.status, modals, userData.email ?? '');
      }
    })();
  }, [identitySuccess, modals, ondatoInfo.status]);

  useEffect(() => {
    onAuthenticationStepUp(isIdentityStepUp);
  }, [isIdentityStepUp]);

  useEffect(() => {
    if (typeof onboardingSuccess !== 'undefined') {
      tracking.setSubmitEvent('single_flow_onboarding_flow_submitted');
      onOnboardingSuccess(true);
    }
  }, [onboardingSuccess]);
};

export default useStepNotifications;
