import { FC } from 'react';

import { Copyright } from 'components';
import { MaintenanceInfo } from 'defaultConfiguration/getRoutesInMaintenance';

import { Footer } from './error.styles';
import { MaintenancePageCardColumn, MaintenancePageContent } from './maintenance.styles';
import { MaintenanceCard } from './maintenanceCard';

type Props = {
  date?: string;
  system?: MaintenanceInfo['system'];
};

export const MaintenanceModePage: FC<Props> = ({ date, system }) => {
  return (
    <MaintenancePageContent>
      <MaintenancePageCardColumn>
        <MaintenanceCard date={date} system={system} />
      </MaintenancePageCardColumn>
      <Footer>
        <Copyright />
      </Footer>
    </MaintenancePageContent>
  );
};
