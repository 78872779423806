import styled from 'styled-components';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const Row = styled.div`
  display: flex;

  & > * {
    margin-right: 1.5rem;

    &:first-of-type {
      width: 100%;
    }

    &:last-of-type {
      min-width: 19.6875rem;
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.media.maxDesktop} {
    flex-direction: column;

    & > * {
      &:first-of-type {
        margin-bottom: 1.5rem;
      }

      &:last-of-type {
        min-width: 100%;
      }
    }
  }
`;
