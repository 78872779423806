import { Skeleton } from '@mui/material';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Popup, ScreenSize } from 'components';
import { BottomDrawer } from 'components/pageV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { useFetchApplicationsQuery } from 'features/application/api';
import { selectActiveCompany, selectCompaniesAggregate } from 'features/applications/selectors';
import { useAppSelector } from 'hooks/redux/hooks';

import CompanySwitch from './companySwitch';
import { CompanyTitle, MenuButton } from './companySwitch.styles';

const CompanySwitchButton = () => {
  const buttonRef = useRef<HTMLLabelElement | null>(null);
  const mobileSwitchRef = useRef<HTMLDivElement | null>(null);

  const { isFeatureEnabled } = useRemoteConfigContext();

  const { isLoading } = useFetchApplicationsQuery({ fullList: isFeatureEnabled('verificationFlow') });

  const activeCompany = useAppSelector(selectActiveCompany);

  const companies = useAppSelector(selectCompaniesAggregate);

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const onVisibilityChange = (visible: boolean) => {
    setMenuIsVisible(visible);

    buttonRef?.current?.click();
  };

  return (
    <>
      <Popup
        containerOffset={() => [0, 8]}
        showPureChildren
        childrenHeight={40}
        onVisibilityChange={() => onVisibilityChange(!menuIsVisible)}
        padding={32}
        focusableRefs={[mobileSwitchRef]}
        animationType={'fade-slide-in'}
        keepPopupSameWidth
        placement={'right-start'}
        inputContainer
        button={
          <MenuButton
            user={!activeCompany.id}
            ref={buttonRef}
            htmlFor="menu-checkbox-hidden"
            data-testid="company-switch-main-button"
          >
            <CompanyTitle>{!isLoading ? activeCompany.name : <Skeleton width={126} />}</CompanyTitle>
            <input
              id="menu-checkbox-hidden"
              type="checkbox"
              onClick={(e) => {
                e.stopPropagation();
              }}
              checked={menuIsVisible}
            />
          </MenuButton>
        }
      >
        <ScreenSize desktop desktopXL>
          <CompanySwitch
            dataTestId="company-options-desktop"
            onSearchChange={setSearchQuery}
            searchQuery={searchQuery}
            loading={isLoading}
            onSelect={() => onVisibilityChange(false)}
            companies={companies}
          />
        </ScreenSize>
      </Popup>
      {createPortal(
        <BottomDrawer open={menuIsVisible} onVisibilityChange={() => onVisibilityChange(!menuIsVisible)}>
          <CompanySwitch
            dataTestId="company-options-mobile"
            onSearchChange={setSearchQuery}
            searchQuery={searchQuery}
            ref={mobileSwitchRef}
            loading={isLoading}
            onSelect={() => onVisibilityChange(false)}
            companies={companies}
          />
        </BottomDrawer>,
        document.body
      )}
    </>
  );
};

export default CompanySwitchButton;
