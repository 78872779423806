import styled from 'styled-components';

import Upload from './upload';

export const DocumentUpload = styled(Upload)`
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
