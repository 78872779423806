import { ring } from 'ldrs';
import { useRef, useEffect, useState, FC } from 'react';

import { colors } from '../../design/designVariables';

import { SpinnerContainer } from './loader.styles';

type Props = {
  display?: boolean;
  timeout?: number;
  color?: string;
};

const DEFAULT_TIMEOUT_MS = 250;

ring.register();

export const Spinner: FC<Props> = ({ display, color, timeout = DEFAULT_TIMEOUT_MS }) => {
  const spinnerTimeout = useRef<number | null>(null);
  const [displaySpinner, setDisplaySpinner] = useState(false);

  useEffect(() => {
    if (display) {
      spinnerTimeout.current = setTimeout(() => {
        setDisplaySpinner(!!display);
      }, timeout) as unknown as number;
    }

    if (!display) {
      if (spinnerTimeout.current) {
        setDisplaySpinner(false);
        clearTimeout(spinnerTimeout.current);
      }
    }
  }, [display]);

  return (
    <SpinnerContainer show={displaySpinner}>
      <l-ring size={16} stroke={2} speed={2} color={color ?? colors.base.primary.tints[70]} />
    </SpinnerContainer>
  );
};
