const baseColors = {
  neutrals: {
    black: {
      10: '#EBEBED'
    }
  },
  primary: {
    shades: {
      5: '#F4F7FF',
      10: '#EAEFFE',
      20: '#2250C4',
      30: '#1E46AC',
      50: '#95B2FA',
      100: '#2B64F5'
    },
    tints: {
      5: '#F4F7FF',
      10: '#EAEFFE',
      20: '#D5E0FD',
      30: '#BFD1FC',
      50: '#95B2FA',
      60: '#80A2F9',
      70: 'rgba(107, 146, 248, 1)',
      80: 'rgba(85, 131, 247, 1)'
    }
  },
  black: {
    functional: '#000000',
    faded: '#4D4D4D',
    neutrals: {
      5: '#F2F2F2'
    },
    shades: {
      3: '#F7F7F7',
      5: '#F4F4F5',
      8: '#EBEBEB',
      10: '#E6E6E6',
      20: '#CCCCCC',
      40: '#999999',
      50: '#808080',
      60: '#666666',
      70: '#4D4D4D',
      90: '#1A1A1A',
      100: '#000000'
    },
    tints: {
      2: '#FAFAFA',
      3: 'rgba(247, 247, 247, 1)',
      5: '#f4f4f5',
      10: '#E6E6E6',
      15: '#D9D9D9',
      20: '#CCCCCC',
      30: 'rgba(179, 179, 179, 1)',
      40: '#999999',
      50: 'rgba(128, 128, 128, 1)',
      60: '#666666',
      70: 'rgba(77, 77, 77, 1)',
      80: 'rgba(51, 51, 51, 1)',
      90: '#999999',
      100: '#000000'
    }
  },
  red: {
    shades: {
      5: '#FEF4F6',
      20: '#B61A3633',
      50: '#B61A3680',
      100: '#E32143',
      120: '#B61A36'
    }
  },
  yellow: {
    shades: {
      20: '#966A0C33',
      50: '#FAB11480',
      160: '#FAB114'
    }
  },
  blue: {
    shades: {
      20: '#2250C433',
      50: '#2250C480'
    }
  },
  green: {
    shades: {
      10: '#32BF331A',
      20: '#1E731733',
      50: '#32BF3380',
      100: '#32BF33',
      140: '#32BF33'
    }
  },
  white: {
    functional: '#ffffff'
  }
};

export const colors = {
  base: baseColors,
  primary: {
    background: baseColors.black.shades[5],
    buttonText: baseColors.white.functional,
    buttonBackground: '#2B64F5',
    hoverButtonBackground: '#275ADD',
    navBackground: baseColors.white.functional,
    textColor: baseColors.black.shades[90],
    headerColor: baseColors.black.shades[90],
    subHeaderColor: '#7F7F7F',
    inputTextColor: baseColors.black.shades[90],
    linkText: '#2b64f5',
    inputPlaceholderColor: baseColors.black.shades[50],
    infoTextColor: baseColors.black.shades[50],
    border: {
      light: baseColors.black.shades[10],
      middle: '#B3B3B3',
      dark: baseColors.black.shades[50],
      darker: baseColors.black.shades[60],
      black: baseColors.black.functional
    },
    sideMenuDropdownBackground: '#2b64f5',
    errorText: baseColors.red.shades[100],
    shadow: '#0000001A',
    chart: {
      background: '#2b64f5'
    },
    status: {
      error: {
        100: '#E32143'
      }
    }
  },
  secondary: {
    buttonText: baseColors.black.shades[90],
    buttonBackground: baseColors.white.functional,
    textColorLight: '#999',
    textColor: '#7F7F7F',
    hoverButtonColor: '#666666',
    shadow: '#2b64f533'
  },
  accent: {
    buttonBackground: '#E32143',
    buttonText: '#FFF',
    hoverButtonBackground: '#CC1E3C',
    shadow: '#e3214333'
  },
  disabled: {
    background: '#E6E6E6',
    text: '#B3B3B3',
    border: '#B3B3B3'
  },
  formElement: {
    background: baseColors.white.functional,
    border: '#ccc',
    requiredSign: '#EF2615'
  },
  status: {
    error: '#FF7064',
    note: '#d09910'
  },
  shadow: '#0000001A',
  notificationIcon: {
    success: {
      icon: '#1E731F',
      background: '#EAF9EB'
    },
    error: {
      icon: '#B61A36',
      background: '#FEF4F6'
    }
  },
  toast: {
    error: {
      header: '#B61A36',
      text: '#EF2615',
      background: baseColors.red.shades[5],
      border: '#B61A36',
      outline: baseColors.red.shades[20],
      fill: baseColors.red.shades[50]
    },
    warning: {
      header: '#FAB114',
      text: '#d09910',
      background: '#fff8e6',
      border: '#966A0C',
      outline: baseColors.yellow.shades[20],
      fill: baseColors.yellow.shades[50]
    },
    info: {
      header: '#2250C4',
      text: '#2B64F5',
      background: '#EAF0FE',
      border: '#2250C4',
      outline: baseColors.blue.shades[20],
      fill: baseColors.blue.shades[50]
    },
    success: {
      header: '#32BF33',
      text: '#539B42',
      background: '#dbf5d4',
      border: '#1E7317',
      outline: baseColors.green.shades[20],
      fill: baseColors.green.shades[50]
    }
  }
};

export const layers = {
  sessionTimeoutModal: 10000,
  termsModal: 9000,
  dropdown: 8500,
  modal: 8250,
  overlay: 8000,
  sideDrawer: 7500,
  nav: 7000,
  helpPopup: 6000
};

export type Layers = typeof layers;

export const spacings = {
  gap: '1.5rem',
  gap2x: '3rem'
};

export type Spacings = typeof spacings;

export const shadows = {
  medium: '0px 0.625rem 2.5rem rgb(0 0 0 / 10%)'
};

export type Shadows = typeof shadows;

export type AppColorsType = typeof colors;

export const status = {
  gray: {
    text: baseColors.black.shades[70],
    bg: baseColors.black.shades[10]
  },
  red: { text: baseColors.red.shades[100], bg: baseColors.red.shades[5] },
  blue: { text: baseColors.primary.shades[100], bg: baseColors.primary.tints[10] },
  lightBlue: { text: baseColors.primary.shades[100], bg: baseColors.primary.tints[10] },
  green: { text: '#1d731f', bg: '#eaf9eb' },
  yellow: {
    text: '#966a0d',
    bg: '#feefd1'
  },
  white: { text: baseColors.black.shades[90], bg: baseColors.white.functional }
};

export type StatusType = typeof status;

export const breakpointSizes = {
  maxPhonePx: 768,
  maxTabletPx: 1024,
  maxDesktopPx: 1200,
  maxDesktopXLPx: 1399
};

export const responsiveBreakPoints = {
  maxPhone: `(max-width: ${breakpointSizes.maxPhonePx}px)`,
  maxTablet: `(max-width: ${breakpointSizes.maxTabletPx}px)`,
  maxDesktop: `(max-width: ${breakpointSizes.maxDesktopPx}px)`,
  maxDesktopXL: `(max-width: ${breakpointSizes.maxDesktopXLPx}px)`,
  minPhone: `(min-width: ${breakpointSizes.maxPhonePx + 1}px)`,
  minTablet: `(min-width: ${breakpointSizes.maxTabletPx + 1}px)`,
  minDesktop: `(min-width: ${breakpointSizes.maxDesktopPx + 1}px)`,
  minDesktopXL: `(min-width: ${breakpointSizes.maxDesktopXLPx + 1}px)`
};

export const pageContentLayouts = {
  maxWidth: '87.5rem',
  minWidth: '5rem'
};

export const pageContentLayoutsType = typeof status;

export type AppBreakpointsType = typeof responsiveBreakPoints;

export const fontSizes = {
  h1: '1.375rem', // 22px
  h2: '1.25rem', // 20px
  h3: '1.125rem', // 18px
  h4: '1rem', // 16px
  h5: '0.875rem', // 14px
  h6: '0.75rem', // 12px
  link: '1rem'
};

export type AppFSType = typeof fontSizes;

export const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800
};

export type AppFontWeightType = typeof fontWeights;
