import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/redux/hooks';

import { useLazyFetchApplicationQuery } from '../api';
import { setApplication, setLoadFailed, setLoading } from '../slice';

type HookReturnType = ReturnType<typeof useLazyFetchApplicationQuery>;

const useLazyFetchApplicationDetails = (): HookReturnType => {
  const dispatch = useAppDispatch();

  const originalResult = useLazyFetchApplicationQuery();

  const [, response] = originalResult;

  //TODO - can be removed once application selectors are removed out of code base
  useEffect(() => {
    if (response.isSuccess) {
      dispatch(setApplication(response.data));
    }
    dispatch(setLoadFailed(response.isError));
    dispatch(setLoading(response.isLoading));
  }, [response.isError, response.isLoading, response.isSuccess, response.data]);

  return originalResult;
};

export default useLazyFetchApplicationDetails;
