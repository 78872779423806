import { Bill, FactoringBillsGetRequest } from 'services/FactoringInvoicesService/types';
import { RootState } from 'store';

import { endpoints } from './slice';

export const selectUpcommingPayments = (state: RootState) => (payload: FactoringBillsGetRequest) => {
  const billsSelector = endpoints.getBills.select(payload);

  const result = billsSelector(state).data ?? {};

  return {
    ...result,
    bills:
      result.bills?.map((bill: Bill) => {
        const { invoiceType, invoiceNumber, invoiceDueDate, invoiceStatus } = bill;
        return {
          ...bill,
          type: invoiceType,
          number: invoiceNumber,
          dueDate: invoiceDueDate,
          status: invoiceStatus
        };
      }) ?? []
  };
};
