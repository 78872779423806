import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Icons from 'assets/icons';
import { RegularText } from 'components';
import { logoutAsync } from 'features/auth/slice';
import { useAppDispatch } from 'hooks/redux/hooks';

import { LogoutButton } from './logoutButton.styles';

type Props = {
  onAction?: () => unknown;
  absolute?: boolean;
};

export const Logout: FC<Props> = ({ onAction, absolute }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(logoutAsync());
    navigate('/login');
    onAction?.();
  };

  return (
    <LogoutButton id="logout-button" data-testid={`menu-option-logout`} onClick={logout} absolute={absolute}>
      <Icons.ExitDoor />
      <RegularText>{t('logout')}</RegularText>
    </LogoutButton>
  );
};
