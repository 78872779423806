export const formatPercentage =
  (language: string) =>
  (value?: number, decimals = 2) => {
    if (value === undefined || value === null || isNaN(Number(value))) return '-';

    const decimalsSeparator = language === 'en' ? '.' : ',';
    const whitespace = language === 'en' ? '' : ' ';

    return `${value.toFixed(decimals)}${whitespace}%`.replace('.', decimalsSeparator);
  };

export const formatNumber =
  (language: string, options = { style: 'currency', currency: 'EUR' }) =>
  (value?: number | string, decimals = 2) => {
    const locales: Record<string, string> = {
      en: 'en-GB',
      lt: 'lt-LT'
    };

    if (!value && value !== 0) {
      return '-';
    }

    const mergedOptions = { ...options, minimumFractionDigits: decimals };

    return new Intl.NumberFormat(locales[language], mergedOptions).format(Number(value));
  };
