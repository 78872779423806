import styled from 'styled-components';

import { StatusChip } from '../statusChip';
import { RegularText, SecondaryText } from '../text';

export const HiddenFileInput = styled.input`
  display: none;
`;

export const UploadStyle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.primary.background};
  border-radius: 0.625rem;
  padding: 1.25rem;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${SecondaryText} {
    font-size: 0.75rem;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  h1:first-child {
    margin-right: 0.75rem;
  }
`;

export const ValidationMessageContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.75rem;
`;

export const FileNamesContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const UploadButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary.linkText};
  padding: 0;

  &:hover {
    cursor: pointer;
  }
  font-size: 1rem;
  svg {
    margin-right: 0.3rem;
    width: 1.5rem;
    height: 1.5rem;

    path {
      stroke: currentColor;
    }
  }
`;

export const FileUploadButtonMobileContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;

  ${UploadButtonStyle} {
    margin-top: 0.625rem;
  }
`;

type Props = {
  error?: boolean;
};

export const FileNameContainer = styled.div<Props>`
  display: flex;
  border: 0.0625rem solid
    ${({ theme, error }) => (error ? theme.colors.base.red.shades[120] : theme.colors.base.black.shades[10])};
  background: ${({ theme }) => theme.colors.secondary.buttonBackground};
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding: 0 1.25rem;
  height: 2rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  justify-content: center;
  align-items: center;
  max-width: 12.75rem;

  svg {
    margin-left: 0.3125rem;
    margin-right: 0;
    color: ${({ theme }) => theme.colors.base.black.shades[70]};

    path {
      fill: currentColor;
    }
  }

  ${RegularText} {
    color: ${({ theme }) => theme.colors.base.black.shades[90]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5rem;
  }
`;

export const UploadTitle = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1rem;
  margin: 0;
`;

export const UploadStatusChip = styled(StatusChip)`
  padding: 0 0.625rem;
`;
