import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../assets/icons';
import { Modal } from '../modal';

import { IconStyle, Note, NoteHeader } from './notificationModal.styles';

type Props = {
  type: 'error' | 'warning' | 'success';
  label: string;
  explanationText: string;
  explanationHeader?: string;
  isOpen: boolean;
  informationalIcon?: React.ReactNode;
  informationalImage?: React.ReactNode;
  handleClose: (open: boolean) => unknown;
  mainAction?: {
    label: string;
    handler: () => unknown;
    isSubmitting?: boolean;
  };
  secondaryAction?: {
    label: string;
    handler: () => unknown;
    isSubmitting?: boolean;
  };
};

export const NotificationModal: FC<Props> = ({
  isOpen,
  handleClose,
  type,
  label,
  explanationHeader,
  explanationText,
  informationalIcon,
  informationalImage,
  secondaryAction,
  mainAction
}) => {
  const { t } = useTranslation();

  const onClose = () => handleClose(false);

  const icon = informationalIcon ?? (
    <IconStyle type={type}>
      <Icon.Error />
    </IconStyle>
  );

  return (
    <Modal
      variant="informational"
      label={t(label)}
      isOpen={isOpen}
      onClose={onClose}
      hideSecondaryAction={!!mainAction && !secondaryAction}
      mainActionLabel={mainAction?.label}
      mainActionDisabled={mainAction?.isSubmitting}
      mainActionSubmitting={mainAction?.isSubmitting}
      mainActionhandler={mainAction?.handler}
      secondaryActionLabel={secondaryAction?.label}
      secondaryActionDisabled={secondaryAction?.isSubmitting}
      secondaryActionSubmitting={secondaryAction?.isSubmitting}
      secondaryActionhandler={secondaryAction?.handler}
      informationalImage={informationalImage}
      informationalIcon={informationalImage ? null : icon}
    >
      {explanationHeader ? <NoteHeader>{t(explanationHeader)}</NoteHeader> : null}
      <Note>{t(explanationText)}</Note>
    </Modal>
  );
};
