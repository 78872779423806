import styled from 'styled-components';

import {
  LabelContainer,
  UploadButtonStyle,
  UploadStatusChip,
  UploadStyle
} from 'components/upload/upload.style';

export const RequiredDocumentUploadContainer = styled.div`
  display: flex;

  ${UploadStatusChip} {
    margin: 0.3125rem 0;
  }

  ${UploadButtonStyle} {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${UploadStyle} {
    width: 100%;
  }

  ${LabelContainer} {
    flex-wrap: wrap;
  }
`;
