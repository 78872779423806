import { forwardRef, useImperativeHandle, useState } from 'react';

import { SkeletonRow, SkeletonRowContainer } from './table.styles';

const DEFAULT_ROWS_COUNT = 10;

type Props = {
  rowCount?: number;
  visible?: boolean;
};

export type TableSkeletonRef = { rowHeight: (height?: number) => unknown };

export const TableSkeleton = forwardRef<TableSkeletonRef, Props>(({ rowCount }, ref) => {
  const [rowHeight, setRowHeight] = useState(0);
  const skeletonRows = new Array(rowCount || DEFAULT_ROWS_COUNT).fill(1);
  const shimmerRowHeight = rowHeight - 20;

  useImperativeHandle(
    ref,
    () => ({
      rowHeight: (height?: number) => height && setRowHeight(height)
    }),
    []
  );

  return (
    <>
      {skeletonRows.map((_, index) => {
        return (
          <SkeletonRowContainer rowHeight={rowHeight} key={index}>
            <SkeletonRow invert rowHeight={shimmerRowHeight}></SkeletonRow>
          </SkeletonRowContainer>
        );
      })}
    </>
  );
});
