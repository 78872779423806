import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import pantheon from 'assets/icons/pantheon.svg';
import plus from 'assets/icons/plus.svg';
import { NativeLinkButton } from 'components';
import { Button } from 'components/buttonV2';
import { Header, HeaderActions, HeaderContainer, HeaderRow } from 'components/page/page.styles';
import { Toast } from 'components/toastV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { useLazyFetchApplicationsQuery } from 'features/application/api';
import { LimitedInfoModal } from 'features/application/components/limitedInfoModal/limitedInfoModal';
import { resetApplicationState } from 'features/application/slice';
import { FINANCIAL_PRODUCT } from 'features/application/types/applicationTypes';
import {
  ApplicationCardSkeleton,
  ApplicationsList,
  BankStatementsModal,
  EmptyApplicationsList,
  SmeGoAppBanner
} from 'features/applications/components';
import { ApplicationReviewModal } from 'features/applications/components/applicationReviewModal/applicationReviewModal';
import {
  selectApplicationsIsLoading,
  selectApplicationsLoadFailed,
  selectHasNoApplications,
  selectSortedApplications
} from 'features/applications/selectors';
import { selectCustomerInformation, selectIsUserGhost } from 'features/auth/selectors';
import { useMaintenanceMode } from 'features/nav/hooks';
import { OnboardingSuccessModalForExistingCustomer } from 'features/onboarding/components/onboardingSuccessModalForExistingCustomer/onboardingSuccessModalForExistingCustomer';
import { selectIsCompanyQuestionaireStatusLoading } from 'features/onboarding/selectors';
import getTypedSearchParams from 'features/onboarding/utils/getTypedSearchParams';
import { resetStatementsState } from 'features/statements/slice';
import { useAppDispatch, useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { MaintenanceCard } from 'pages/error/maintenanceCard';
import tracking from 'utils/tracking';

import { ApplicationsListContainer, ContentContainer } from './applications.styles';

export const ApplicationsOverview = () => {
  const { isFeatureEnabled } = useRemoteConfigContext();
  const isFeatureInMaintenance = useMaintenanceMode();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as { product?: FINANCIAL_PRODUCT; redirectUrl?: string };

  const [searchParams, setSearchParams] = useSearchParams();
  const typedSearchParams = getTypedSearchParams();
  const success = searchParams.get('success');
  const bankConsentRef = searchParams.get('ref');

  const [isInformationModalVisible, setIsInformationModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isOnboardingSuccessModalVisible, setIsOnboardingSuccessModalVisible] = useState(false);
  const [isAppModalVisible, setAppModalVisible] = useState(false);
  const [error, setError] = useState(false);
  const [contentIsPushed, setContentIsPushed] = useState(false);
  const [showBankStatementsModal, setShowBankStatementsModal] = useState(Boolean(bankConsentRef));

  const customerInformation = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const fetchingApplicationOnboardingStatus = useAppSelector(selectIsCompanyQuestionaireStatusLoading);

  const [fetchApplications, { isLoading }] = useLazyFetchApplicationsQuery();

  const loading = useAppSelector(selectApplicationsIsLoading) || fetchingApplicationOnboardingStatus;
  const applications = useAppSelector(selectSortedApplications);
  const hasNoApplications = useAppSelector(selectHasNoApplications);
  const hasLoadFailed = useAppSelector(selectApplicationsLoadFailed);

  const isGhostUser = useAuthSelector<typeof selectIsUserGhost>(selectIsUserGhost);

  const [isInMaintenance, , maintenanceInfo] = isFeatureInMaintenance(['goCredits']);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setError(hasLoadFailed);
  }, [hasLoadFailed]);

  useEffect(() => {
    if (success) {
      searchParams.delete('success');

      if (state) {
        state.redirectUrl && state.product === FINANCIAL_PRODUCT.LEASING
          ? setIsInformationModalVisible(true)
          : setIsSuccessModalVisible(true);

        setSearchParams(searchParams, { replace: true, state });

        return;
      }

      setIsSuccessModalVisible(true);
      setSearchParams(searchParams, { replace: true });
    }
  }, [success]);

  useEffect(() => {
    fetchApplications({ fullList: true });
  }, [customerInformation?.customerId]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const maxCardsWidth = 540;

        window.requestAnimationFrame(() => setContentIsPushed(entry.contentRect.width < maxCardsWidth));
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [containerRef.current]);

  const { onboardingSuccess } = typedSearchParams;

  useEffect(() => {
    if (typeof onboardingSuccess !== 'undefined') {
      tracking.setSubmitEvent('existing_customer_onboarding_flow_submitted');
      setIsOnboardingSuccessModalVisible(true);
    }
  }, [onboardingSuccess]);

  const SuccessMessages = (
    <>
      <ApplicationReviewModal
        isOpen={isSuccessModalVisible}
        onVisibilityChange={() => {
          setIsSuccessModalVisible(false);
          setAppModalVisible(true);
        }}
      />
      <OnboardingSuccessModalForExistingCustomer
        isOpen={isOnboardingSuccessModalVisible}
        onVisibilityChange={() => setIsOnboardingSuccessModalVisible(false)}
      />
      <SmeGoAppBanner isOpen={isAppModalVisible} onVisibilityChange={() => setAppModalVisible(false)} />
      <LimitedInfoModal
        onContinue={() => {
          window.open(state.redirectUrl, '_blank');
          setIsInformationModalVisible(false);
          setIsSuccessModalVisible(true);
        }}
        onCancel={() => {
          setIsInformationModalVisible(false);
          setIsSuccessModalVisible(true);
        }}
        isOpen={isInformationModalVisible}
      />
    </>
  );

  const createNewApplicationHandler = () => {
    dispatch(resetStatementsState());
    dispatch(resetApplicationState());
    navigate('/new-application');
  };

  const addBankAccountStatementsHandler = () => {
    dispatch(resetStatementsState());
    setShowBankStatementsModal(true);
  };

  const renderHeaderButtons = () => {
    return !hasNoApplications && !isLoading ? (
      <NativeLinkButton>
        {isFeatureEnabled('nordigenStatementsInApplications') && !isGhostUser ? (
          <Button
            onClick={addBankAccountStatementsHandler}
            variant="Stroked"
            size="S"
            color="Blue"
            icons={{
              left: pantheon
            }}
          >
            {t('addAccountInfo')}
          </Button>
        ) : null}
        <Button
          onClick={createNewApplicationHandler}
          variant="Stroked"
          size="S"
          color="Blue"
          icons={{
            left: plus
          }}
        >
          {t('newApplication')}
        </Button>
      </NativeLinkButton>
    ) : null;
  };

  if (error) {
    return (
      <>
        {SuccessMessages}
        <Toast
          isVisible={error}
          onClose={setError}
          header={t('generalErrorHeader')}
          message={t('generalErrorMessage')}
        />
      </>
    );
  }

  if (hasNoApplications) {
    return (
      <>
        {SuccessMessages}
        <EmptyApplicationsList data-testid="empty-product-card" />
      </>
    );
  }

  if (isInMaintenance) {
    return <MaintenanceCard date={maintenanceInfo?.date} system={maintenanceInfo?.system} />;
  }

  return (
    <>
      <BankStatementsModal isOpen={showBankStatementsModal} onVisibilityChange={setShowBankStatementsModal} />
      {SuccessMessages}
      <ContentContainer>
        <ApplicationsListContainer ref={containerRef} dense={contentIsPushed}>
          <HeaderRow style={{ paddingBottom: '0.5rem' }} centerVeritcally>
            <HeaderContainer>
              <Header>{t('myApplications')}</Header>
            </HeaderContainer>
            <HeaderActions>{renderHeaderButtons()}</HeaderActions>
          </HeaderRow>
          {loading ? (
            <>
              <ApplicationCardSkeleton />
              <ApplicationCardSkeleton />
            </>
          ) : (
            <ApplicationsList applications={applications} />
          )}
        </ApplicationsListContainer>
      </ContentContainer>
    </>
  );
};
