import { RestClient } from '../RestClient';

import { DashboardInfoResponse } from './types';

const restClient = new RestClient();

const sharedToken = () => sessionStorage.getItem('sharedToken');

export class BankService {
  static getDashboardInfo() {
    const url = '/bank/api/a/UAC/GetDashboardData';
    return restClient.GET<DashboardInfoResponse>(url, {
      headers: {
        Authorization: `Bearer ${sharedToken()}`
      }
    });
  }
}
