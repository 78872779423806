import { ReactNode } from 'react';

import Icons from 'assets/icons';
import { LinkButton } from 'components';

import {
  ButtonShimmer,
  CardHeaderRow,
  CardPaper,
  CardTitleShimmer,
  ChildrenContainer,
  HeaderTitle,
  StatusChipContainer,
  StepToast
} from './stepCard.styles';

type Props = {
  title: string;
  children?: ReactNode;
  StatusChip?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  step?: string;
  toastMessage?: string;
  explanation?: boolean;
  onInactiveClick?: () => unknown;
  openBankAccountDrawer?: (visible: boolean) => undefined | void;
};

const StepCard = ({
  step,
  title,
  children,
  StatusChip,
  disabled,
  toastMessage,
  loading,
  explanation,
  onInactiveClick,
  openBankAccountDrawer
}: Props) => {
  const displayAdditionalContent = !disabled;

  const hasChildren = Array.isArray(children) ? children.some(Boolean) : Boolean(children);

  const shimmerContainer =
    step === 'requestForFinancing' ? (
      <CardPaper>
        <CardTitleShimmer />
        <ButtonShimmer />
      </CardPaper>
    ) : (
      <CardPaper>
        <CardTitleShimmer />
      </CardPaper>
    );

  return loading ? (
    shimmerContainer
  ) : (
    <CardPaper aria-disabled={disabled} onClick={onInactiveClick}>
      <CardHeaderRow>
        <HeaderTitle>{title}</HeaderTitle>
        {explanation && displayAdditionalContent ? (
          <LinkButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openBankAccountDrawer?.(true);
            }}
          >
            <Icons.ExclamationCircle />
          </LinkButton>
        ) : displayAdditionalContent ? (
          <StatusChipContainer>{StatusChip}</StatusChipContainer>
        ) : null}
      </CardHeaderRow>
      {hasChildren || Boolean(toastMessage) ? (
        <ChildrenContainer data-testid="step-card-children">
          <StepToast message={toastMessage} isVisible={Boolean(toastMessage)} />
          {children}
        </ChildrenContainer>
      ) : null}
    </CardPaper>
  );
};

export default StepCard;
