import styled from 'styled-components';

type ErrorMessageProp = {
  hidden: boolean;
  'data-testid'?: string;
};

export const ErrorMessage = styled.div.attrs<ErrorMessageProp>({
  'data-testid': 'error-message'
})`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.base.red.shades[120]};
  text-align: left;
`;
