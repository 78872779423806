import styled from 'styled-components';

export const FieldsetHeaderTitle = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const FieldsetHeaderValue = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const FieldsetHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0 1rem;

  ${FieldsetHeaderValue} {
    padding-top: 0.25rem;
  }
`;

export const DocumentsUploadHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const DocumentsExplanatoryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};

  li:first-child {
    &::after {
      content: ':';
    }
  }

  li:not(:first-child) {
    &::before {
      content: '•';
      margin-right: 0.25rem;
    }
  }
`;

export const ToastContainer = styled.div`
  margin-bottom: 1.25rem;
`;
