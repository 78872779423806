import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { MaintenanceInfo } from 'defaultConfiguration/getRoutesInMaintenance';
import { MaintenanceModePage, PageNotFound } from 'pages/error';
import { camelCase } from 'utils';

import { ResolvedFeaturesConfig } from '../../../configuration/features';

import { useIsRouteInMaintenanceMode, useMaintenanceMode } from './hooks';

export type PublicRouteProps = {
  outlet: JSX.Element;
  systems?: MaintenanceInfo['system'][];
  title?: string;
  featureName?: keyof ResolvedFeaturesConfig;
};

const availableSubsystems: MaintenanceInfo['system'][] = [
  'goCredits',
  'rbf',
  'factoring',
  'loans',
  'leasing',
  'creditline'
];

type TranslatedTitles = {
  [key: string]: string;
};

type TitleMappings = {
  [pageTitle: string]: keyof TranslatedTitles;
};

const pageTitlesConfig = {
  dashboardOverview: { key: 'main', translationKey: 'main' },
  dashboardApplications: { key: 'myApplications', translationKey: 'myApplications' },
  financeFactoring: { key: 'finance', translationKey: 'factoring' },
  financeLoans: { key: 'loans', translationKey: 'loans' },
  financeCreditline: { key: 'creditLine', translationKey: 'creditline' },
  financeRbf: { key: 'rbf', translationKey: 'rbf' },
  financeLeasing: { key: 'leasing', translationKey: 'leasing' },
  dashboardInsights: { key: 'insights', translationKey: 'insights' },
  newApplication: { key: 'newApplication', translationKey: 'newApplication' },
  factoringStatements: { key: 'statements', translationKey: 'statements' },
  factoringBills: { key: 'bills', translationKey: 'bills' },
  factoringContracts: { key: 'contracts', translationKey: 'contracts' },
  loansContracts: { key: 'contracts', translationKey: 'contracts' },
  creditlineContracts: { key: 'contracts', translationKey: 'contracts' },
  loansBills: { key: 'businessLoanBills', translationKey: 'businessLoanBills' },
  creditlineBills: { key: 'creditlineBills', translationKey: 'creditlineBills' },
  dashboardVerification: { key: 'setUpMyProfile', translationKey: 'setUpMyProfile' }
};

const generateTitleMappings = (
  config: { [key: string]: { key: string; translationKey: string } },
  t: (key: string) => string
) => {
  const translatedTitles: TranslatedTitles = {};
  const titleMappings: { [key: string]: keyof TranslatedTitles } = {};

  Object.entries(config).forEach(([pageTitle, { key, translationKey }]) => {
    translatedTitles[key] = t(translationKey);
    titleMappings[pageTitle] = key;
  });

  return { translatedTitles, titleMappings };
};

// it is important for GA to have unique page titles for each page
// this function will return last two segments of the path
function getKeywordsFromPath(path: string, translatedTitles: TranslatedTitles, titleMappings: TitleMappings) {
  try {
    const segments = path
      .split('/')
      .filter((segment) => segment.trim() !== '')
      .map((segment) => segment.replaceAll('-', ' '));

    const pageTitle = segments.slice(-2).join(' ').toLowerCase();
    const camelCaseTitle = camelCase(pageTitle, ' ');
    const formattedTitle = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);

    return translatedTitles[titleMappings[camelCaseTitle]] || formattedTitle;
  } catch (e) {
    return '';
  }
}

export function PublicRoute({ outlet, systems, title, featureName }: Readonly<PublicRouteProps>) {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useRemoteConfigContext();
  const location = useLocation();

  const { translatedTitles, titleMappings } = generateTitleMappings(pageTitlesConfig, t);

  const dynamicTitle = getKeywordsFromPath(location.pathname, translatedTitles, titleMappings);

  const pageTitle = title ? t(title) : undefined;

  document.title = pageTitle || dynamicTitle ? `${pageTitle || dynamicTitle} | smeGo` : 'smeGo';

  const isFeatureInMaintenance = useMaintenanceMode();

  const subsystems = systems?.filter((system) => availableSubsystems.includes(system));

  const [isAppInMaintenance, , appMaintenanceInfo] = isFeatureInMaintenance(['app', ...(systems || [])]);
  const [isRouteInMaintenance, , routeMaintenanceInfo] = useIsRouteInMaintenanceMode();

  if (featureName && !isFeatureEnabled(featureName)) {
    return <PageNotFound />;
  }

  if (isAppInMaintenance) {
    return <MaintenanceModePage date={appMaintenanceInfo?.date} system={subsystems?.[0]} />;
  }

  if (isRouteInMaintenance) {
    return <MaintenanceModePage date={routeMaintenanceInfo?.date} />;
  }

  return outlet;
}
