import { FC } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Field, Fieldset, Input } from 'components';
import { selectActiveCustomer } from 'features/auth/selectors/';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks/';
import { Farm } from 'services/CapService/types/';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';
import { selectIsNewCompany } from '../../selectors';

type Props = {
  validationHelpers: ValidationHelpers<Farm>;
  getValues: UseFormGetValues<Farm>;
};

export const FarmCodeField: FC<Props> = ({ getValues, validationHelpers }) => {
  const { t } = useTranslation();
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isNewCompany = useAppSelector(selectIsNewCompany);

  const disabled = !!activeCustomer?.customerId && !isNewCompany;

  return (
    <>
      <Fieldset>
        <Field
          Component={Input}
          size="L"
          name="code"
          value={getValues('code')}
          placeholder={t('farmCode')}
          validationHelpers={validationHelpers}
          disabled={disabled}
          required
        />
      </Fieldset>
    </>
  );
};
