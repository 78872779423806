import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { Modal } from 'components';
import { Note } from 'components/notificationModal/notificationModal.styles';

type Props = {
  isOpen?: boolean;
  onVisibilityChange: (isVisible: boolean) => unknown;
};

export const OnboardingSuccessModalForExistingCustomer: FC<Props> = ({ isOpen, onVisibilityChange }) => {
  const { t } = useTranslation();

  const isApplicationsPage = location.pathname.includes('/applications');

  const handleClose = () => {
    onVisibilityChange(false);
  };

  const labelId = isApplicationsPage
    ? 'companyDetailsUpdatedSuccessfully'
    : 'companyProfileUpdatedSuccessfully';
  const mainActionLabel = isApplicationsPage ? t('gotIt') : t('close');

  return (
    <Modal
      variant="informational"
      isOpen={!!isOpen}
      informationalIcon={<Icons.RocketBig />}
      label={t(labelId)}
      onClose={handleClose}
      mainActionLabel={t(mainActionLabel)}
      mainActionhandler={handleClose}
      hideSecondaryAction={true}
    >
      {isApplicationsPage ? null : <Note>{t('informationInReview')}</Note>}
    </Modal>
  );
};
