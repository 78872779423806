import { UPLOAD_STEPS } from 'features/invoices/types';

export const sequence = [
  UPLOAD_STEPS.CONTRACTS,
  UPLOAD_STEPS.INVOICES,
  UPLOAD_STEPS.SUPPORTING_DOCUMENTS,
  UPLOAD_STEPS.VERIFICATION,
  UPLOAD_STEPS.REVIEW
];

export const getInvoiceUploadStepIndex = () => sequence.findIndex((step) => step === UPLOAD_STEPS.INVOICES);
export const getVerificationStepIndex = () =>
  sequence.findIndex((step) => step === UPLOAD_STEPS.VERIFICATION);
