import styled from 'styled-components';

import { SecondaryText } from 'components';

export const LimitDetailBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-of-type {
    padding-top: 0.125rem;
  }

  & > span {
    line-height: 1.25rem;
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const LimitDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${LimitDetailBoxContainer} {
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    border-bottom: 0.0625rem solid ${(props) => props.theme.colors.primary.border.light};

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
`;

export const SecondaryHeader = styled(SecondaryText)`
  display: inline-flex;
  margin-right: 0.625rem;
  font-size: 0.875rem;
`;

export const LabelContainer = styled.span`
  display: flex;
`;
