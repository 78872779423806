export const getLanguageCode = (language: string) => {
  if (language.length <= 2) {
    return language;
  }

  if (language.length > 2 && language.includes('-')) {
    return language.split('-')[0];
  }

  return language;
};
