import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/buttonV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { useAppDispatch, useLoansSelector } from 'hooks/redux/hooks';
import { LoansService } from 'services/LoansService';
import { getLanguageCode } from 'translations/utils';

import {
  selectChosenLoansContractInfo,
  selectIsLoansPaymentsHasNoData,
  selectIsLoansPaymentsListLoading,
  selectLoansUpcomingPaymentsTableItems
} from '../selectors';
import { getPaymentsAsync } from '../slice';

import { UpcomingPaymentsTable } from './upcomingPayments/upcomingPaymentsTable';

export const withNullCheck = (value?: string | number | boolean) => value || '';

type Props = {
  navigateTo: string;
};

export const LoansUpcomingPayments: FC<Props> = ({ navigateTo }) => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const payments = useLoansSelector<typeof selectLoansUpcomingPaymentsTableItems>(
    selectLoansUpcomingPaymentsTableItems
  );
  const isPaymentsLoading = useLoansSelector<typeof selectIsLoansPaymentsListLoading>(
    selectIsLoansPaymentsListLoading
  );
  const selectedContract = useLoansSelector<typeof selectChosenLoansContractInfo>(
    selectChosenLoansContractInfo
  )(contractId || '');
  const noDuePayments = useLoansSelector<typeof selectIsLoansPaymentsHasNoData>(
    selectIsLoansPaymentsHasNoData
  );

  const {
    financierId,
    customerId,
    contractNo,
    contractTerm,
    loansDisbursedAmount,
    financierMigrationDate,
    financierMigrationError,
    validFromDate,
    loanType
  } = selectedContract || {};

  useEffect(() => {
    if (contractId && customerId && financierId) {
      dispatch(
        getPaymentsAsync({
          contractNo: contractId,
          financierId,
          customerId,
          pageNo: '1',
          pageSize: '5'
        })
      );
    }
  }, [contractId, customerId, financierId, dispatch]);

  const handleScheduleDownload = async () => {
    if (
      contractNo &&
      financierId &&
      customerId &&
      contractTerm &&
      validFromDate &&
      (loansDisbursedAmount || loansDisbursedAmount === 0)
    ) {
      return LoansService.downloadSchedule({
        contractNo,
        financierId,
        customerId,
        //pass schema check
        greenReport: (loanType === 'GREEN LOAN') as unknown as string,
        contractTerm: contractTerm.toString(),
        loansDisbursedAmount: loansDisbursedAmount.toString(),
        scheduleReportLanguage: (getLanguageCode(i18n.resolvedLanguage) || 'en').toUpperCase(),
        financierMigrationDate,
        financierMigrationError: `${financierMigrationError}`,
        contractDate: validFromDate
      });
    }
  };

  const handleNavigation = () => {
    navigate(navigateTo);
  };

  return (
    <UpcomingPaymentsTable
      noItems={noDuePayments}
      footerNavigation={
        isFeatureEnabled('bills') ? (
          <Button variant="Filled" color="Blue" onClick={handleNavigation}>
            {t('allBills')}
          </Button>
        ) : undefined
      }
      contractNumber={contractId || ''}
      loading={isPaymentsLoading}
      items={payments}
      scheduleDownload={handleScheduleDownload}
    />
  );
};
