import { FC } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Field, Input, Fieldset } from 'components';
import { selectIsPrefilledFromWeb } from 'features/application/selectors';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { LoansAdditionalInfo } from 'services/CapService/types';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';

type Props = {
  validationHelpers: ValidationHelpers<LoansAdditionalInfo>;
  getValues: UseFormGetValues<LoansAdditionalInfo>;
};

export const TermsFields: FC<Props> = ({ validationHelpers, getValues }) => {
  const disabled = useApplicationSelector<typeof selectIsPrefilledFromWeb>(selectIsPrefilledFromWeb);

  const { t } = useTranslation();

  return (
    <Fieldset>
      <Field
        size="L"
        Component={Input}
        name="term"
        disabled={disabled}
        value={getValues('term')}
        placeholder={t('placeholderTerm')}
        validationHelpers={validationHelpers}
        helpText={t('fromToMonthsWithExample', { from: 1, to: 36, exampleAmount: 20 })}
        type="number"
        required
      />
    </Fieldset>
  );
};
