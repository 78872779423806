import styled from 'styled-components';

import { RouterLink } from '../link';
import { Paper } from '../paper';
import { RegularText } from '../text';

export const ProductCardPaper = styled(Paper)`
  background: linear-gradient(120.53deg, #2b64f5 1.05%, #1e46ac 100.99%);
  min-height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const Circle = styled.span`
  display: inline-flex;
  position: relative;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  background-color: #eaeffe;
  border-radius: 0.625rem;
`;

export const Plus = styled.span`
  height: 0.75rem;
  width: 0.75rem;
  position: absolute;
  top: 0.3125rem;
  left: 0.1875rem;

  &::after {
    content: '';
    position: absolute;
    width: 0.5625rem;
    height: 0.0625rem;
    background-color: ${({ theme }) => theme.colors.primary.linkText};
    display: flex;
    top: 0.25rem;
    right: 0;
    z-index: 9;
  }

  &::before {
    content: '';
    position: absolute;
    height: 0.5625rem;
    width: 0.0625rem;
    background-color: ${({ theme }) => theme.colors.primary.linkText};
    display: flex;
    top: 0;
    right: 0.25rem;
    z-index: 9;
  }
`;

export const ProductCardContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1.5rem;

  ${Circle} {
    margin-right: 0.625rem;
  }

  ${RouterLink} {
    width: 100%;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  ${RegularText} {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    color: #ffffff;
    size: 1rem;
    line-height: 1.5rem;
  }
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FakeButtonStyle = styled.div`
  display: flex;
  background-color: #f9faff;
  border-radius: 3.125rem;
  min-height: 2rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 1rem;
  padding: 0.375rem 1rem 0.375rem 0.5rem;
`;

export const ImageContainer = styled.div`
  height: 6.5rem;
  width: 6.5rem;
  display: flex;
`;
