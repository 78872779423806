import {
  ChangeEvent,
  EventHandler,
  FocusEventHandler,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from 'components/text/formatters';

import {
  CurrencyDisplayInputWrapper,
  CurrencyInputWrapper,
  CurrencyValueInputWrapper
} from './currencyInput.styles';
import Input, { InputProps, sanitizeNumericValue } from './input';

type IBANInputProps = Omit<InputProps, 'value'> & { value: string };

const formattedDecimalLength = (language: string) => {
  switch (language) {
    case 'en':
      return 3;
    case 'lt':
      return 5;
    default:
      return 3;
  }
};

const isNumber = (value: unknown) => !isNaN(Number(value));

const switchVisibleInputs = (inputToShow: HTMLInputElement, inputToHide: HTMLInputElement) => {
  inputToShow.style.visibility = 'visible';
  inputToShow.style.pointerEvents = 'auto';

  inputToHide.style.visibility = 'hidden';
  inputToHide.style.pointerEvents = 'none';
};

export const CurrencyInput = (props: IBANInputProps) => {
  const { i18n } = useTranslation();
  const displayInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const currencyDisplayInputWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const currencyValueInputWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;

  const formatCurrency = formatNumber(i18n.language);

  const [formattedValue, setFormattedValue] = useState(formatCurrency(props.value || 0));
  const [previousCursorPosition, setPreviousCursorPosition] = useState<number | null>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value;
    const { selectionStart } = inputRef.current;

    const sanitizedNumericValue = sanitizeNumericValue(currentValue);

    const newlyFormattedValue = formatCurrency(sanitizedNumericValue || '0');

    if (isNumber(sanitizedNumericValue)) {
      setFormattedValue(newlyFormattedValue);
    }

    props.onChange?.(`${sanitizedNumericValue}`);
    setPreviousCursorPosition(selectionStart);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (inputRef.current) {
      currencyDisplayInputWrapperRef.current.style.visibility = 'visible';
      currencyValueInputWrapperRef.current.style.visibility = 'hidden';
      switchVisibleInputs(displayInputRef.current, inputRef.current);
    }

    props.onBlur?.(e);
  };

  const handleDisplayClick: EventHandler<SyntheticEvent> = (e) => {
    if (document.activeElement === inputRef.current) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (displayInputRef.current) {
      currencyDisplayInputWrapperRef.current.style.visibility = 'hidden';
      currencyValueInputWrapperRef.current.style.visibility = 'visible';
      switchVisibleInputs(inputRef.current, displayInputRef.current);

      const { selectionStart } = displayInputRef.current;
      const leftHandSideDisplayValue = formattedValue
        .substring(0, formattedValue.length - formattedDecimalLength(i18n.language))
        .substring(0, selectionStart || 0);

      const leftHandSideUnformattedValue = leftHandSideDisplayValue.replace(/[^0-9]/g, '');

      const difference = leftHandSideDisplayValue.length - leftHandSideUnformattedValue.length;

      inputRef.current?.focus();
      inputRef.current.selectionStart = (selectionStart || 0) - difference;
      inputRef.current.selectionEnd = (selectionStart || 0) - difference;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (previousCursorPosition || previousCursorPosition === 0) {
        inputRef.current?.setSelectionRange(previousCursorPosition, previousCursorPosition);
      }
    }, 16);
  }, [props.value]);

  useEffect(() => {
    if (formattedValue !== formatCurrency(props.value || 0)) {
      setFormattedValue(isNumber(props.value) ? formatCurrency(props.value || 0) : formatCurrency(0));
    }
  }, [props.value]);

  return (
    <CurrencyInputWrapper>
      <CurrencyDisplayInputWrapper ref={currencyDisplayInputWrapperRef} defaultValueStyle={!props.value}>
        <Input
          testId="currency-input-display"
          withRemoveIcon={false}
          ref={displayInputRef}
          {...props}
          onChange={() => {
            /* noop */
          }}
          value={formattedValue}
          onFocus={handleDisplayClick}
          onClick={handleDisplayClick}
        />
      </CurrencyDisplayInputWrapper>
      <CurrencyValueInputWrapper ref={currencyValueInputWrapperRef}>
        <Input
          onClear={() => {
            setFormattedValue(formatCurrency(0));
            displayInputRef.current?.click();
          }}
          handleActionsOnClick={false}
          animatePlaceholder={false}
          ref={inputRef}
          {...props}
          onBlur={handleBlur}
          handleChangeFormEvent={handleInputChange}
        />
      </CurrencyValueInputWrapper>
    </CurrencyInputWrapper>
  );
};
