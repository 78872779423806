import * as Yup from 'yup';

import { VALIDATION_MESSAGE_ID } from './types';

const maximumTwoDecimals = (number?: number) => {
  if (number) {
    // eslint-disable-next-line regexp/no-unused-capturing-group
    const checkTwoDigitsAfterDecimal = /^\d+(\.\d{1,2})?$/;

    return checkTwoDigitsAfterDecimal.test(number.toString());
  }

  return true;
};

export const percentageRequired = Yup.number()
  .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
  .required(VALIDATION_MESSAGE_ID.REQUIRED)
  .min(0.01, VALIDATION_MESSAGE_ID.MIN_LENGTH)
  .max(100, VALIDATION_MESSAGE_ID.MAX_LENGTH);

export const amountRangeRequired = ({ min, max }: { min: number; max: number }) =>
  amountRangeRequiredMessage({ min, max, message: VALIDATION_MESSAGE_ID.REQUIRED });

export const amountRangeRequiredMessage = ({
  min,
  max,
  message
}: {
  min: number;
  max: number;
  message: string;
}) =>
  Yup.number()
    .typeError(message)
    .min(min, VALIDATION_MESSAGE_ID.MIN_AMOUNT)
    .max(max, VALIDATION_MESSAGE_ID.MAX_AMOUNT)
    .test('maxTwoDecimalsTest', VALIDATION_MESSAGE_ID.MAX_DECIMALS, maximumTwoDecimals)
    .required(message);
