import { useTranslation } from 'react-i18next';

import { IsolatedFlowTopNavigation } from 'components';
import { selectActiveStep, selectProductType, selectStepsSequence } from 'features/application/selectors';
import { STEPS } from 'features/application/types/applicationTypes';
import { selectIsEmbededInMobileWebview } from 'features/auth/selectors';
import { useApplicationSelector, useAuthSelector } from 'hooks/redux/hooks';
import trackers from 'utils/tracking';

import { CapStepper } from '../stepper/capStepper';

const hiddenSteps = [STEPS.SUCCESS_SUBMIT];

type Props = {
  onBackClick?: () => void | Promise<void>;
  showModal?: (visible: boolean) => unknown;
  header?: string;
  onHelpClick?: (header?: string) => unknown;
};

export const TopNavigation = ({ onBackClick, showModal, header, onHelpClick }: Props) => {
  const { t } = useTranslation();

  const productType = useApplicationSelector<typeof selectProductType>(selectProductType);
  const activeStep = useApplicationSelector<typeof selectActiveStep>(selectActiveStep);
  const sequence = useApplicationSelector<typeof selectStepsSequence>(selectStepsSequence);
  const isEmbeded = useAuthSelector<typeof selectIsEmbededInMobileWebview>(selectIsEmbededInMobileWebview);

  const onBackClickHandler = async () => {
    const currentIndex = sequence.findIndex((step) => step === activeStep);

    await onBackClick?.();

    trackers.setFlowStep(sequence[currentIndex - 1], { cap_product_type: productType ?? '' });
  };

  const openModalClickHandler = () => {
    showModal?.(true);
  };

  return (
    <IsolatedFlowTopNavigation
      stepper={<CapStepper hiddenSteps={hiddenSteps} />}
      header={t(header ?? activeStep)}
      onHelpClick={onHelpClick ? () => onHelpClick?.(header) : undefined}
      onBackClick={onBackClickHandler}
      onCloseClick={!isEmbeded ? openModalClickHandler : undefined}
    />
  );
};
