import { FC, MutableRefObject, useRef, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { RegularText, AmountText, PopupIconButtonContainer, Table, DownloadLink, Popup } from 'components';
import { transformDateToLocalizedString } from 'components/datePicker/utils';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { FactoringStatementsService } from 'services';
import { Act, ActType } from 'services/FactoringStatementsService/types';

import { ActsTableContainer, DownloadTypesContainer, FileTypePdf, FileTypeXls } from './actsTable.styles';

type UnselectableActsItem = Act & { toggleSelected?: never };

type Props = {
  items?: UnselectableActsItem[];
  loading?: boolean;
  totalPages?: number;
  onSort?: (sortOder: Sort) => unknown;
  onChangePage?: (pageNumber: number) => unknown;
  persistNoDataContainerHeight?: boolean;
  activePage?: number;
};

type TypesTranslations = {
  [key in ActType]: string;
};

export type Sort = { dataColumnId?: string | number; sortOrder: SortOrder };

export const PAGE_SIZE = 10;

const typesTraslations: TypesTranslations = {
  INTEREST_FEE: 'interest',
  COMMISSION_FEE: 'commissionFee',
  LATE_FEE: 'lateFee',
  REBATE_PAYOUT: 'rebatePayout',
  ADVANCE_PAYOUT: 'advancePayout',
  OVERPAYMENT_PAYOUT: 'overpaymentPayout',
  ACCOUNTS_RECEIVABLE: 'accountsReceivable',
  RECONCILIATION: 'reconciliation'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withNullCheck = (value: any) => value || '-';

export const ActsTable: FC<Props> = ({
  items,
  loading,
  totalPages,
  onSort,
  onChangePage,
  persistNoDataContainerHeight,
  activePage
}) => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<Sort>();

  const sortable = !!onSort;

  const boundaryRef = useRef() as MutableRefObject<HTMLDivElement>;

  const downloadPaymentHandler = (actId?: string) => async () => {
    if (actId) {
      return FactoringStatementsService.getPaymentDocument({ actId });
    }
  };

  const downloadFeeHandler = (actId?: string) => async () => {
    if (actId) {
      return FactoringStatementsService.getFeeDocument({ actId });
    }
  };

  const columns: TableColumn<UnselectableActsItem>[] = [
    {
      selector: (row) => withNullCheck(row.date),
      name: t('date'),
      id: 'date',
      sortable,
      format: (row) => (
        <RegularText>{row.date ? transformDateToLocalizedString(new Date(row.date)) : '-'}</RegularText>
      )
    },
    {
      selector: (row) => row.number,
      name: t('number'),
      sortable,
      id: 'number',
      format: (row) => <RegularText>{row.number}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.actType),
      name: t('type'),
      id: 'type',
      sortable,
      format: (row) => {
        if (row.actType) {
          return <RegularText>{t(typesTraslations[row.actType])}</RegularText>;
        }

        return '-';
      }
    },
    {
      selector: (row) => withNullCheck(row.actualAmount),
      name: t('amount'),
      id: 'amount',
      sortable,
      format: (row) => (
        <RegularText>
          <AmountText>{row.actualAmount}</AmountText>
        </RegularText>
      )
    },
    isFeatureEnabled('factoringStatementsExport')
      ? {
          right: true,
          allowOverflow: true,
          selector: (row) => withNullCheck(row.id),
          name: t(''),
          format: (row) => {
            if (
              row.actType === 'COMMISSION_FEE' ||
              row.actType === 'INTEREST_FEE' ||
              row.actType === 'LATE_FEE'
            ) {
              return (
                <Popup
                  inputContainer
                  id={row.id}
                  button={
                    <PopupIconButtonContainer>
                      <Icons.Download />
                    </PopupIconButtonContainer>
                  }
                  boundaryContainer={boundaryRef.current}
                >
                  <DownloadTypesContainer>
                    <DownloadLink fileRequest={downloadPaymentHandler(row.id)}>
                      <FileTypePdf />
                    </DownloadLink>
                    <DownloadLink fileRequest={downloadFeeHandler(row.id)}>
                      <FileTypeXls />
                    </DownloadLink>
                  </DownloadTypesContainer>
                </Popup>
              );
            }

            return (
              <DownloadLink fileRequest={downloadPaymentHandler(row.id)}>
                <Icons.Download />
              </DownloadLink>
            );
          }
        }
      : {}
  ];

  const handleSort = (column: TableColumn<UnselectableActsItem>, sortDirection: SortOrder) => {
    setSortOrder({ dataColumnId: column.id, sortOrder: sortDirection });
    onSort?.({ dataColumnId: column.id, sortOrder: sortDirection });
  };

  return (
    <ActsTableContainer ref={boundaryRef}>
      <Table<UnselectableActsItem>
        activePage={activePage}
        pagination={!!onChangePage}
        paginationPerPage={PAGE_SIZE}
        noDataMessage={t('noListItems')}
        loading={loading}
        highlightOnHover
        progressPending={loading}
        columns={columns}
        sortedBy={sortOrder}
        data={items || []}
        totalPages={totalPages}
        onSort={handleSort}
        onChangePage={onChangePage}
        persistNoDataContainerHeight={persistNoDataContainerHeight}
      />
    </ActsTableContainer>
  );
};
