/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loader } from 'components';
import { useAppDispatch } from 'hooks/redux/hooks';
import tracking from 'utils/tracking';

import { logoutAsync, refreshMobileSSOTokenAsync } from '../../slice';

export type SSOLoginRequest = {
  customerId: string;
  refreshToken: string;
  email: string;
  userType: 'ghost' | 'regular';
};

export const SSOMobileLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ssoTimeoutRef = useRef(0);
  const ssoMessageTimeoutRef = useRef(0);

  const [searchParams] = useSearchParams({ redirectUrl: '/new-application' });
  const redirectUrl = searchParams.get('redirectUrl') as string;

  const [waitingForMessage, setWaitingForMessage] = useState<boolean>(false);

  const onFailure = () => {
    navigate('/login');
    setWaitingForMessage(false);
    dispatch(logoutAsync());
  };

  const clearTimeouts = () => {
    ssoTimeoutRef.current && clearTimeout(ssoTimeoutRef.current);
    ssoMessageTimeoutRef.current && clearTimeout(ssoMessageTimeoutRef.current);
  };

  useEffect(() => {
    try {
      ssoMessageTimeoutRef.current = setTimeout(() => {
        window.addEventListener('message', async (message) => {
          setWaitingForMessage(true);

          const json = message.data as SSOLoginRequest;

          if (json.refreshToken) {
            clearTimeouts();

            try {
              await dispatch(
                refreshMobileSSOTokenAsync({
                  headers: {
                    'x-customer-id': json.customerId,
                    Authorization: `Bearer ${json.refreshToken}`
                  },
                  request: json
                })
              ).unwrap();
            } catch {
              onFailure();
            } finally {
              setTimeout(() => {
                tracking.setUserChannelIntegrationType(true);
                navigate(redirectUrl);
              }, 100);

              setWaitingForMessage(false);
            }
          }
        });
      }, 0) as unknown as number;

      window.parent.postMessage('tokens_needed', '*');
    } catch {
      onFailure();
      clearTimeouts();
    }

    return clearTimeouts;
  }, []);

  useEffect(() => {
    if (waitingForMessage) {
      ssoTimeoutRef.current = setTimeout(() => {
        onFailure();
      }, 8000) as unknown as number;
    }

    return clearTimeouts;
  }, [waitingForMessage]);

  return waitingForMessage ? <Loader /> : null;
};
