import { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from '../../assets/icons';
import ClappingHands from '../../assets/images/clappingHands.png';
import Nerd from '../../assets/images/nerd.png';
import PalmHand from '../../assets/images/palmHand.png';
import PeekingEye from '../../assets/images/peekingEye.png';

import {
  ActionToastProps,
  IconContainer,
  ImageContainer,
  ToastHeader,
  ToastMessage,
  ToastStyle
} from './actionToast.styles';

export type Variants = 'info' | 'error' | 'warning' | 'success';

type Props = {
  header?: string;
  message?: string;
  isVisible: boolean;
  testId?: string;
  className?: string;
  variant?: Variants | '';
  onClose?: () => unknown;
  item?: string;
  action?: string;
  displayIcon?: boolean;
} & Partial<ActionToastProps>;

const imageByVariant: Record<Variants, ReactNode | null> = {
  info: <img alt="" src={Nerd} />,
  error: <img alt="" src={PeekingEye} />,
  warning: <img alt="" src={PalmHand} />,
  success: <img alt="" src={ClappingHands} />
};

const Toast: FC<Props> = ({
  header,
  message,
  testId,
  variant,
  item,
  action,
  isVisible,
  className,
  displayIcon,
  onClose
}) => {
  const { t } = useTranslation();

  const variantType = variant ?? '';
  const imageToDisplay = displayIcon ? variantType && imageByVariant?.[variantType] : null;

  const [firstLoadIsVisible, setFirstLoadIsVisible] = useState<boolean | null>(isVisible);

  useEffect(() => {
    if (variantType === 'success') {
      const timer = setTimeout(() => {
        onClose?.();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [variantType, isVisible]);

  const renderText = (variantType: Variants) => {
    if (action && item) {
      const loverCaseItem = item.toLowerCase();

      switch (variantType) {
        case 'error':
          return t('actionError', { action, item: loverCaseItem });
        case 'success':
          return t('actionSuccess', { action, item });
      }
    }

    return message;
  };

  const renderHeader = (variantType: Variants) => {
    if (header) {
      return header;
    }

    switch (variantType) {
      case 'error':
        return t('error');
      case 'success':
        return t('success');
    }

    return message;
  };

  const handleOnClose = () => {
    onClose?.();
  };

  useEffect(() => {
    if (firstLoadIsVisible !== null && isVisible !== firstLoadIsVisible) {
      setTimeout(() => {
        setFirstLoadIsVisible(null);
      }, 550);
    }
  }, [isVisible]);

  return variantType ? (
    <ToastStyle
      animate={firstLoadIsVisible !== isVisible || isVisible}
      className={className}
      data-testid={`${variantType}-toast-message${testId || ''}`}
      isVisible={isVisible}
      variant={variantType}
    >
      <ImageContainer>{imageToDisplay}</ImageContainer>
      <ToastMessage>
        <ToastHeader variant={variantType}>{renderHeader(variantType)}</ToastHeader>
        <p>{renderText(variantType)}</p>
      </ToastMessage>
      <IconContainer onClick={handleOnClose}>
        <Icons.Close />
      </IconContainer>
    </ToastStyle>
  ) : null;
};

export default Toast;
