/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppState } from 'features/app/types';
import { ApplicationState } from 'features/application/types/applicationTypes';
import { AuthState } from 'features/auth/types/authTypes';
import { BankState } from 'features/bank/types';
import { ContractsState } from 'features/contracts/types';
import { FactoringStatementsState } from 'features/factoringStatements/types';
import { InvoicesState } from 'features/invoices/types';
import { InvoicesStatusesInitialState } from 'features/invoicesVerification/types';
import { LoansState } from 'features/loans/types';
import { StatementsState } from 'features/statements/types';

import { AppDispatch, RootState } from '../../store';

export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useApplicationSelector = <T extends (...args: any) => any>(
  fn: (state: ApplicationState) => ReturnType<T>
) => useAppSelector((state: RootState) => fn(state.application));

export const useAppInfoSelector = <T extends (...args: any) => any>(fn: (state: AppState) => ReturnType<T>) =>
  useAppSelector((state: RootState) => fn(state.app));

export const useAuthSelector = <T extends (...args: any) => any>(fn: (state: AuthState) => ReturnType<T>) =>
  useAppSelector((state: RootState) => fn(state.auth));

export const useContractSelector = <T extends (...args: any) => any>(
  fn: (state: ContractsState) => ReturnType<T>
) => useAppSelector((state: RootState) => fn(state.contracts));

export const useFactorignStatementsSelector = <T extends (...args: any) => any>(
  fn: (state: FactoringStatementsState) => ReturnType<T>
) => useAppSelector((state: RootState) => fn(state.factoringStatements));

export const useStatementsSelector = <T extends (...args: any) => any>(
  fn: (state: StatementsState) => ReturnType<T>
) => useAppSelector((state: RootState) => fn(state.statements));

export const useInvoicesSelector = <T extends (...args: any) => any>(
  fn: (state: InvoicesState) => ReturnType<T>
) => useAppSelector((state: RootState) => fn(state.invoices));

export const useLoansSelector = <T extends (...args: any) => any>(fn: (state: LoansState) => ReturnType<T>) =>
  useAppSelector((state: RootState) => fn(state.loans));

export const useBankSelector = <T extends (...args: any) => any>(fn: (state: BankState) => ReturnType<T>) =>
  useAppSelector((state: RootState) => fn(state.bank));

export const useInvoicesStatusesSelector = <T extends (...args: any) => any>(
  fn: (state: InvoicesStatusesInitialState) => ReturnType<T>
) => useAppSelector((state: RootState) => fn(state.invoicesVerificationStatuses));
