import styled from 'styled-components';

import { AmountText, Paper, RegularText, SecondaryText } from 'components';
import { StatusType } from 'design/designVariables';

export const ChipText = styled(RegularText)`
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

export const SectionHeaderText = styled(RegularText)`
  display: inline-flex;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-right: 0.625rem;
`;

export const ContractDetailsSectionHeader = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const ContractDetailsSection = styled.div`
  padding: 2.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 1.25rem 1.25rem 0.75rem;
  }
`;

type ContractInformationSectionProps = {
  colorName: keyof StatusType;
};

export const ContractInformationSection = styled(ContractDetailsSection)<ContractInformationSectionProps>`
  background-color: ${({ theme, colorName }) => theme.status[colorName].bg};
  border-top-left-radius: 1.125rem;
  border-top-right-radius: 1.125rem;
`;

export const ContractInformationCard = styled(Paper)`
  padding: 0;
  height: 100%;
`;

export const ContractInformationSet = styled.div`
  display: flex;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${RegularText} {
    font-size: 0.875rem;
  }

  ${SecondaryText} {
    font-size: 0.875rem;
  }

  ${SecondaryText} {
    display: flex;
    margin-right: 0.625rem;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const ContractInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;

  @media ${({ theme }) => theme.media.maxTablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
  }
`;

export const Label = styled.span`
  display: inline-flex;
  margin-bottom: 0.125rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const AssetInformation = styled(RegularText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DetailsAmount = styled(AmountText)`
  font-size: 0.875rem;
`;
