import styled, { css } from 'styled-components';

import { Paper } from '../paper';

type Props = {
  show?: boolean;
  fullWidth?: boolean;
  inputContainer?: boolean;
};

export const PopupArrow = styled.div`
  width: 15px;
  height: 15px;
  visibility: hidden;

  &::before {
    position: absolute;
    width: 15px;
    height: 15px;
    content: '';
    background: #ffffff;
    transition:
      transform 0.2s ease-out 0s,
      visibility 0.2s ease-out 0s;
    visibility: visible;
    transform: translateX(0) rotate(45deg);
    transform-origin: center center;
    left: 0;
  }
`;

export const PopupContainerAnchor = styled.div<Props>`
  display: flex;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  color: ${({ show, theme }) => (show ? theme.colors.primary.linkText : theme.colors.secondary.textColor)};
  pointer-events: all;
  align-items: center;
  cursor: default;
`;

export const PopupIconContainer = styled.div<Props>`
  display: flex;
  position: ${({ inputContainer }) => (inputContainer ? 'relative' : 'absolute')};
  pointer-events: all;
  align-items: center;
  justify-content: center;
  width: ${({ inputContainer, fullWidth }) => (inputContainer || fullWidth ? '100%' : 'auto')};
  min-width: 1.5rem;
  min-height: 1.5rem;
  left: ${({ inputContainer, fullWidth }) => (inputContainer || fullWidth ? 'initial' : '-0.25rem')};
  cursor: default !important;

  span {
    color: ${({ show, theme }) => (show ? theme.colors.primary.linkText : theme.colors.secondary.textColor)};
    pointer-events: none;
  }

  svg {
    pointer-events: none;

    path {
      transition: stroke 0.15s ease-in-out;
      stroke: ${({ theme }) => theme.colors.base.black.shades[50]};
    }
  }

  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.base.black.shades[70]};
      }
    }
  }
`;

export const PopupStyles = styled.div`
  background: #ffffff;
  box-shadow: 0 0.625rem 2.5rem rgba(0, 0, 0, 10%);
  border-radius: 0.625rem;
  color: #707070;
  padding: 1.25rem;
  z-index: ${({ theme }) => theme.layers.dropdown};
  font-size: ${({ theme }) => theme.fontSizes.h5};
`;

export type PopupStylesContainerProps = {
  pause?: number;
  withAnimation?: boolean;
  animationType?: 'fade-in' | 'fade-slide-in' | 'bottom-slide-in';
  closing?: boolean;
  active?: boolean;
  childrenHeight?: number;
};

const createCssAnimation = ({
  withAnimation,
  animationType,
  active,
  closing,
  pause,
  childrenHeight
}: PopupStylesContainerProps) => {
  const resetAnimation = active && !closing;

  if (resetAnimation || !withAnimation) {
    return css`
      animation: none;
    `;
  }

  return css`
    animation: ${animationType ?? 'fade-in'} 0.15s ease-in ${(pause || 300) / 1000}s 1 forwards;
    animation-direction: ${closing ? 'reverse' : 'normal'};

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fade-slide-in {
      0% {
        opacity: 0;
        top: 0.25rem;
      }

      100% {
        opacity: 1;
        top: 0;
      }
    }

    @keyframes bottom-slide-in {
      0% {
        opacity: 0;
        bottom: -${childrenHeight ?? 100}vh;
      }

      100% {
        opacity: 1;
        bottom: 0;
      }
    }
  `;
};

export const PopupStylesContainer = styled.div<PopupStylesContainerProps>`
  z-index: ${({ theme }) => theme.layers.dropdown};
  opacity: ${({ withAnimation, active }) => (withAnimation && !active ? 0 : 1)};
  ${(props) => createCssAnimation(props)};

  &[data-popper-placement^='bottom'] {
    ${PopupArrow} {
      top: 0;

      &::before {
        top: -0.375rem;
        left: 0;
      }
    }
  }

  &[data-popper-placement^='top'] {
    ${PopupArrow} {
      bottom: 0;

      &::before {
        bottom: -0.375rem;
        left: 0;
      }
    }
  }
`;

export const PopupIconButtonContainer = styled.div`
  padding: 0 0.625rem;

  @media ${({ theme }) => theme.media.maxPhone} {
    padding: 0;
  }
`;

export const DotsMenuPopup = styled.div`
  ${PopupIconContainer} {
    cursor: pointer !important;
    color: ${({ theme }) => theme.colors.base.black.shades[90]};

    svg {
      path {
        transition: unset;
        stroke: currentColor;
      }
    }
  }
`;

export const PopupDropdownCard = styled(Paper)`
  padding: 0.5rem 0.25rem;
  min-width: 12.625rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 10%);
  border-radius: 1rem;
`;
