import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFetchFactoringContracts } from './useFetchFactoringContracts';

export const useFetchSelectedContract = () => {
  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const response = useFetchFactoringContracts();

  const { contracts } = response.currentData || {};

  return useMemo(() => {
    return {
      ...response,
      selectedContract: contracts?.find((contract) => contract.id === contractId)
    };
  }, [contractId, contracts, response]);
};
