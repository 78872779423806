import styled from 'styled-components';

import en from 'assets/icons/flags/greatBritain.svg';
import lt from 'assets/icons/flags/lithuania.svg';
import { LinkButton, Modal, Paper } from 'components';
import { BlueCheckmark } from 'components/checkbox/checkbox.style';

type LanguageNameAndFlagProps = {
  language: string;
};

export const LanguageNameAndFlag = styled.label<LanguageNameAndFlagProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  cursor: pointer;

  &::before {
    content: '';
    width: 1.875rem;
    height: 1.875rem;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
    background-image: ${({ language }) => `url("${language === 'en' ? en : lt}")`};
  }
`;

export const LanguageOptionsModal = styled(Modal)`
  ${Paper} {
    max-width: 25.4375rem;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    ${Paper} {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export const LanguageOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${LanguageNameAndFlag} {
    padding: 0.75rem 1rem;
    border-radius: 1rem;
    transition: background-color 0.3s ease-in-out;

    &:has(> ${BlueCheckmark}:checked) {
      background-color: ${({ theme }) => theme.colors.base.black.shades[5]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.base.black.shades[5]};
    }
  }
`;

export const LanguageButton = styled(LinkButton)`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 1.5rem;
`;
