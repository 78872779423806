import styled from 'styled-components';

import { LinkButtonStyle, Typography } from 'components';
import { Button } from 'components/buttonV2';
import { Toast } from 'components/toastV2';

export const StepTitle = styled(Typography.Header).attrs({ type: 'h2' })``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 33.75rem;
  width: 100%;
  height: 100%;
`;

export const ToastMessage = styled(Toast)`
  margin-bottom: ${({ isVisible }) => (isVisible ? '1.25rem' : 0)};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;

  ${LinkButtonStyle}:first-child {
    svg {
      path {
        fill: currentColor;
      }
    }
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    align-items: end;
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`;

export const PaperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const HeaderTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 1.75rem;
`;

export const FormContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
  max-width: 33.75rem;
`;

export const ContainerStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const Column = styled.div`
  grid-column-start: 1;
`;

type ButtonContainerProps = {
  isBorderVisible?: boolean;
};

export const ContinueButton = styled(Button).attrs({
  'data-testid': 'button-next',
  color: 'Blue',
  variant: 'Filled',
  size: 'M',
  type: 'button',
  media: {
    maxPhone: {
      size: 'L'
    }
  }
})`
  width: 100%;
  max-width: 33.75rem;
`;

export const ButtonContainer = styled.div<ButtonContainerProps>`
  position: sticky;
  bottom: 0;
  padding: 1rem 0;
  width: 100%;
  margin-top: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary.background};
  z-index: ${({ theme }) => theme.layers.nav};
  border-top: ${({ isBorderVisible, theme }) =>
    isBorderVisible ? `1px solid ${theme.colors.base.black.shades[10]}` : '1px solid transparent'};
`;
