import styled from 'styled-components';

import { Typography } from 'components';
import { ErrorMessage } from 'components/formError';
import { InputContainer } from 'components/input/input.style';

export const FieldsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

type LinkedInputsBlockProps = {
  invalid?: boolean;
};

export const LinkedInputsBlock = styled.div<LinkedInputsBlockProps>`
  display: flex;
  flex-direction: row;

  ${InputContainer}:first-of-type {
    ${ErrorMessage} {
      width: 200%;
    }
  }

  > *:first-child {
    input {
      border-radius: 0.5rem 0 0 0.5rem;
      border-color: ${({ theme, invalid }) =>
        invalid ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10]};
    }
  }

  > *:last-child {
    input {
      border-radius: 0 0.5rem 0.5rem 0;
      border-left: 0;
      border-color: ${({ theme, invalid }) =>
        invalid ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10]};

      &:focus {
        border-left: 0;
      }
    }
  }

  &:hover {
    > * {
      input {
        border-color: ${({ theme, invalid }) =>
          invalid ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[90]};
      }
    }

    > *:last-child {
      input {
        &:hover {
          border-left: 0;
          border-color: ${({ theme, invalid }) =>
            invalid ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[90]};
        }
      }
    }
  }

  &:focus-within {
    > * {
      input {
        border-color: ${({ theme, invalid }) =>
          invalid ? theme.colors.base.red.shades[120] : theme.colors.base.primary.shades[100]} !important;
      }
    }
  }
`;

export const MonthlyPaymentBanner = styled.div`
  padding: 1rem;
  border-radius: 1.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.primary.shades[100]};
  background-color: ${({ theme }) => theme.colors.base.primary.shades[5]};
`;

export const MonthlyPaymentBannerText = styled(Typography.InputViewLabel)`
  color: ${({ theme }) => theme.colors.base.primary.tints[60]};
`;

export const MonthlyPaymentBannerValueLabel = styled(Typography.Body).attrs({
  type: 'M',
  weight: 'medium'
})`
  color: ${({ theme }) => theme.colors.base.primary.shades[100]};
`;

export const MonthlyPaymentBannerValue = styled(Typography.Header).attrs({
  type: 'h4',
  weight: 'semibold'
})`
  color: ${({ theme }) => theme.colors.base.primary.shades[100]};
`;

export const MonthlyPaymentValueRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  min-height: 2rem;
  align-items: center;
`;
