import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NotFoundImage from 'assets/images/error-404.svg';
import { Copyright, Paper } from 'components';
import { Button } from 'components/buttonV2';
import { TopNavigation } from 'features/nav/components';

import {
  MaintenanceCardContent,
  MaintenanceCardRow,
  MaintenancePageCardColumn,
  MaintenancePageContent,
  MainTitle,
  Subtitle
} from './maintenance.styles';

export const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = () => {
    navigate('/dashboard/overview');
  };

  return (
    <>
      <TopNavigation />
      <MaintenancePageContent>
        <MaintenancePageCardColumn>
          <MaintenanceCardRow>
            <Paper>
              <MaintenanceCardContent>
                <img src={NotFoundImage} />
                <MainTitle> {t('pageNotFound')}</MainTitle>
                <Subtitle> {t('pageNotFoundSubtitle')}</Subtitle>
                <Button variant="Filled" color="Blue" onClick={onClick}>
                  {t('backToHomepage')}
                </Button>
              </MaintenanceCardContent>
            </Paper>
          </MaintenanceCardRow>
        </MaintenancePageCardColumn>
        <Copyright />
      </MaintenancePageContent>
    </>
  );
};
