import { FC, HTMLAttributeAnchorTarget } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Insights from 'assets/images/insights.png';
import { resetApplicationState } from 'features/application/slice';
import { useAppDispatch } from 'hooks/redux/hooks';

import { RouterLink } from '../link';
import { SoonCardLabel } from '../soonCard/soonCard.styles';
import { RegularText } from '../text';

import {
  ProductCardPaper,
  ProductCardContent,
  Plus,
  Circle,
  InfoRow,
  InfoColumn,
  FakeButtonStyle,
  ImageContainer
} from './productCard.styles';

type Props = {
  soon?: boolean;
  label: string;
  link?: {
    to: string | { [key in 'en' | 'lt']: string };
    label: string;
  };
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
};

export const ProductCard: FC<Props> = ({ onClick, label, link, target, soon }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { label: linkLabel } = link || {};

  const linkTo = '/new-application';

  return (
    <ProductCardPaper onClick={onClick}>
      <ProductCardContent>
        {soon ? <SoonCardLabel>{t('soon')}</SoonCardLabel> : null}
        {linkTo ? (
          <RouterLink
            onClick={(e) => {
              e.preventDefault();
              dispatch(resetApplicationState());
              navigate(linkTo);
            }}
            to={linkTo}
            rel={target === '_blank' ? 'noopener noreferrer' : undefined}
            target={target || '_self'}
          >
            <InfoRow>
              <InfoColumn>
                <RegularText>{t(label)}</RegularText>
                <FakeButtonStyle>
                  <Circle>
                    <Plus />
                  </Circle>
                  {t(linkLabel || '')}
                </FakeButtonStyle>
              </InfoColumn>
              <ImageContainer>
                <img src={Insights} />
              </ImageContainer>
            </InfoRow>
          </RouterLink>
        ) : null}
        {onClick ? (
          <InfoRow>
            <InfoColumn>
              <RegularText>{t(label)}</RegularText>
            </InfoColumn>
          </InfoRow>
        ) : null}
      </ProductCardContent>
    </ProductCardPaper>
  );
};
