import styled from 'styled-components';

import { LinkButton } from '../link';
import { LinkButtonStyle } from '../link/link.styles';

export const FormListContainer = styled.section`
  background-color: #f7f7f7;
  padding: 1.25rem;
  border-radius: 0.625rem;
`;

export const ListButtonsContainer = styled.section`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  ${LinkButtonStyle}:first-child {
    margin-right: 0.625rem;
  }

  svg {
    stroke: ${({ theme }) => theme.colors.base.black.tints['40']};
  }
`;

export const ListItem = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 1.25rem;
  border-radius: 10px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const ListTitle = styled.section`
  display: flex;

  h1 {
    margin: 0 0.25rem 0 0;
    color: ${({ theme }) => theme.colors.primary.textColor};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    letter-spacing: 0;
    font-size: 1rem;
  }
`;

export const AddFormListItem = styled.button`
  display: flex;
`;

export const ListItemButton = styled(LinkButton)`
  width: 100%;
  color: unset;

  &:hover {
    color: unset;
  }
`;
