import styled from 'styled-components';

import { Fieldset } from 'components';
import { InputContainer } from 'components/input/input.style';

export const FormStyle = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
`;

export const CompanyInputContainer = styled.div`
  ${InputContainer} {
    margin-bottom: 0.625rem;
  }
`;

export const FarmersDetailsFieldset = styled(Fieldset)`
  margin-bottom: 1.25rem;
`;
