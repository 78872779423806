import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper, Modal } from 'components';
import { FileChip } from 'components/uploadArea/fileChip';
import { ContractsService } from 'services';
import { LoansService } from 'services/LoansService';

import {
  HeaderText,
  ChipsContainer,
  HeaderRow,
  NoDataText,
  ViewMoreButton
} from './documentReviewCard.styles';

type Props = {
  header: string;
  files: { contractId?: string; fileName?: string; id?: string; entryNo?: number; description?: string }[];
  contractDetails?: { financierId: string; customerId: string };
};

export const DocumentReviewCard: FC<Props> = ({ header, files, contractDetails }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFileDownload = (contractId?: string, documentId?: string, entryNo?: number) => async () => {
    if (documentId && contractId) {
      return ContractsService.getContractDocumentById({ contractId, documentId });
    } else if (contractDetails && entryNo) {
      const entryString = entryNo.toString();

      return LoansService.downloadContractDocumentByEntryNo({
        financierId: contractDetails.financierId,
        customerId: contractDetails.customerId,
        entryNo: entryString
      });
    }
  };

  const downloadChips = (inModal: boolean) => (
    <ChipsContainer>
      {files.map((file, index) => {
        if (!inModal && index > 1) {
          return null;
        }

        const name = file.fileName ? file.fileName : file.description || '';

        return (
          <FileChip
            filename={name}
            onPreview={handleFileDownload(file.contractId, file.id, file.entryNo)}
            key={index}
          />
        );
      })}
    </ChipsContainer>
  );

  return (
    <Paper>
      <HeaderRow>
        <HeaderText>{`${header} (${files.length})`}</HeaderText>
      </HeaderRow>
      {downloadChips(false)}
      {!files?.length ? (
        <NoDataText>{t('noListItems')}</NoDataText>
      ) : (
        <ViewMoreButton disabled={!files?.length} onClick={handleShowModal}>
          {t('viewAll')}
        </ViewMoreButton>
      )}
      <Modal isOpen={showModal} onClose={handleCloseModal} label={header}>
        {downloadChips(true)}
      </Modal>
    </Paper>
  );
};
