import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';

import { Container, MainTitle, Subtitle } from './financingUnavailable.styles';

export const FinancingUnavailableCard = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Icons.HeartInArm />
      <MainTitle> {t('financingUnavailable')}</MainTitle>
      <Subtitle> {t('financingUnavailableExplanation')}</Subtitle>
    </Container>
  );
};
