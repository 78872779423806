import { forwardRef, useEffect, useId, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import nut from 'assets/icons/nut.svg';
import { BlueCheckmark } from 'components';
import { resetApplicationState, setIsNewCompany } from 'features/application/slice';
import { selectActiveCompany } from 'features/applications/selectors';
import { selectIsCustomerAdmin } from 'features/auth/selectors';
import { setActiveCompany, setActiveCustomerById, setIsRefreshingToken } from 'features/auth/slice';
import { Company } from 'features/auth/types/authTypes';
import { Logout } from 'features/nav/components/logoutButton';
import { useNavContext } from 'features/nav/context/navContext/';
import { useTriggerExistingCustomerOnboardingStatusFetch } from 'features/onboarding/hooks';
import { useAppDispatch, useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { AppService } from 'services';
import { refreshAuthLogic } from 'services/RestClient';

import { DrawerImplementation } from '../drawersSwitch/drawersSwitch';
import {
  BottomActions,
  SettingsMenuItem,
  SideNavigationMenuItemContent,
  SettingsMenuItemWrapper
} from '../sideNavigation/sideNavigation.styles';

import CompanyNotFound from './companyNotFound';
import {
  AddCompanyOption,
  CloseButton,
  CompanyOption,
  CompanyOptionContent,
  CompanySelect,
  CompanyStatus,
  CompanySwitchCard,
  CompanySwitchTitle,
  CompanyTitle,
  HeroShimmer,
  Shimmer,
  CompanySearchInput
} from './companySwitch.styles';

type Props = {
  companies: Company[];
  onSelect?: (id: string) => unknown;
  loading?: boolean;
  onSearchChange?: (searchString: string) => unknown;
  searchQuery?: string;
  dataTestId?: string;
};

const companyStatusChecker = (companies: Company[]) => (id: string) => {
  const company = companies.find((company) => company.id === id);

  if (!company) {
    return false;
  }

  return (
    company.roles?.length === 0 ||
    (company.roles?.includes('PROSPECT') && !company.roles.includes('VERIFIED'))
  );
};

const CompanySwitch = forwardRef<HTMLDivElement, Props>(
  ({ companies, onSelect, searchQuery, onSearchChange, loading, dataTestId }, ref) => {
    const uniqueId_prefix = useId();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const innerRef = useRef<HTMLDivElement>(null);

    const { toggleRightSideDrawer } = useNavContext<DrawerImplementation<'createCompany'>>();

    const [initialSetup, setInitialSetup] = useState(false);

    const selectedCompanyRef = useRef<HTMLLabelElement>(null);
    const selectScrollAreaRef = useRef<HTMLDivElement>(null);

    const [filteredCompanies, setFilteredCompanies] = useState(companies);

    useTriggerExistingCustomerOnboardingStatusFetch();

    const isAdmin = useAuthSelector<typeof selectIsCustomerAdmin>(selectIsCustomerAdmin);
    const isCompanyUnverified = companyStatusChecker(companies);
    const activeCompany = useAppSelector(selectActiveCompany);

    const handleSearch = (searchString: string) => {
      onSearchChange?.(searchString);
    };

    const addNewCompany = () => {
      if (!isAdmin) {
        dispatch(resetApplicationState());
        dispatch(setIsNewCompany(true));
        navigate('/new-application?newcompany=true');
      } else {
        onSelect?.('');
        innerRef.current?.click();
        toggleRightSideDrawer?.({ drawerId: 'createCompany' });
      }
    };

    const switchToCompany = (id: string) => {
      const selectedCompany = companies.find((company) => company.id === id);

      if (selectedCompany) {
        dispatch(setActiveCompany(selectedCompany));
        onSelect?.(id);
      }
    };

    const switchToCustomer = async (id: string) => {
      const selectedCompany = companies.find((company) => company.id === id);

      await refreshAuthLogic(null, id, true);

      if (selectedCompany) {
        dispatch(setActiveCustomerById(id));
        dispatch(setActiveCompany(selectedCompany));
        onSelect?.(id);
      }
    };

    const handleSelect = async (id: string) => {
      try {
        dispatch(setIsRefreshingToken(true));
        const unverified = isCompanyUnverified(id);

        if (unverified) {
          switchToCompany(id);
        } else {
          await switchToCustomer(id);
        }
      } catch (e) {
        AppService.reportError({
          message: 'Error while switching company',
          eventId: 'company-switch-error'
        });
      } finally {
        dispatch(setIsRefreshingToken(false));
      }
    };

    const onCompanySelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const id = e.currentTarget.value;

        await handleSelect(id);
      }
    };

    useEffect(() => {
      if (searchQuery && searchQuery.length >= 3) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();

        setFilteredCompanies(
          companies.filter(
            (company) =>
              company.name.toLowerCase().includes(lowerCaseSearchQuery) ||
              company.id.toLowerCase().includes(lowerCaseSearchQuery)
          )
        );
      } else {
        setFilteredCompanies(companies);
      }
    }, [searchQuery, companies.length]);

    useEffect(() => {
      if (!initialSetup) {
        if (selectedCompanyRef.current && selectScrollAreaRef.current) {
          selectedCompanyRef.current.scrollIntoView({ behavior: 'instant' as ScrollBehavior });
          setInitialSetup(true);
        }
      }
    }, [selectedCompanyRef.current]);

    useEffect(() => {
      setFilteredCompanies(companies);
    }, [companies.length]);

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
      ref,
      () => {
        return innerRef.current;
      },
      [innerRef.current]
    );

    return (
      <CompanySwitchCard
        ref={innerRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CloseButton onClick={() => onSelect?.('')}></CloseButton>
        {isAdmin ? (
          <CompanySearchInput
            placeholder={t('Search by company name')}
            value={searchQuery ?? ''}
            onChange={handleSearch}
          />
        ) : null}
        <CompanySwitchTitle>{t('myCompanies')}</CompanySwitchTitle>
        <CompanySelect ref={selectScrollAreaRef} data-testid={dataTestId}>
          {filteredCompanies.length ? (
            filteredCompanies.map((company) => {
              const unverified = isCompanyUnverified(company.id);

              return (
                <CompanyOption
                  data-testid={`company-option-${company.id}`}
                  ref={activeCompany?.id === company.id ? selectedCompanyRef : undefined}
                  user={!company.id}
                  key={company.id}
                  htmlFor={company.id}
                >
                  <CompanyOptionContent>
                    <CompanyTitle>{company.name}</CompanyTitle>
                    {unverified ? <CompanyStatus>{t('unverified')}</CompanyStatus> : null}
                  </CompanyOptionContent>
                  <BlueCheckmark
                    onChange={onCompanySelect}
                    name={`${uniqueId_prefix}_company`}
                    id={company.id}
                    value={company.id}
                    checked={activeCompany?.id === company.id}
                  />
                </CompanyOption>
              );
            })
          ) : (
            <CompanyNotFound />
          )}
          {loading ? (
            <CompanyOption hideHero>
              <CompanyOptionContent>
                <HeroShimmer />
                <Shimmer height="1.5rem" width="7.5rem" />
              </CompanyOptionContent>
              <Shimmer height="1.5rem" width="5.25rem" />
            </CompanyOption>
          ) : null}
        </CompanySelect>
        <AddCompanyOption data-testid={`add-new-${dataTestId}`} onClick={addNewCompany}>
          <CompanyTitle>{t('addNewCompany')}</CompanyTitle>
        </AddCompanyOption>
        <BottomActions>
          <SettingsMenuItem to="/settings">
            <SideNavigationMenuItemContent icon={nut}>{t('settings')}</SideNavigationMenuItemContent>
          </SettingsMenuItem>
          <SettingsMenuItemWrapper htmlFor="logout-button" to="/">
            <Logout />
          </SettingsMenuItemWrapper>
        </BottomActions>
      </CompanySwitchCard>
    );
  }
);

export default CompanySwitch;
