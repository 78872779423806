import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CouldNotLoad from 'assets/images/couldNotLoad.svg';
import { SecondaryText } from 'components';
import { Button } from 'components/buttonV2';

import { ErrorContainer, ImageContainer, ErrorStateHeader } from './applicationDetailsDrawer.styles';

type ErrorStateProps = {
  handleRetry: () => unknown;
};

export const ErrorState: FC<ErrorStateProps> = ({ handleRetry }) => {
  const { t } = useTranslation();
  return (
    <ErrorContainer>
      <ImageContainer>
        <img src={CouldNotLoad} alt="" />
      </ImageContainer>
      <ErrorStateHeader>{t('couldNotLoad')}</ErrorStateHeader>
      <SecondaryText>{t('clickRetry')}</SecondaryText>
      <Button color="Blue" variant="Filled" size="M" onClick={handleRetry} type="button">
        {t('retry')}
      </Button>
    </ErrorContainer>
  );
};
