import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISO_CODES } from 'components';
import { formatNumber, formatPercentage } from 'components/text/formatters';
import { COMPANY_REGISTRATION_COUNTRIES_LIST } from 'features/application/forms/company/constants';
import { PURPOSE_LIST } from 'features/application/forms/purpose/constants';
import { useLazyFetchApplicationDetails } from 'features/application/hooks';
import {
  selectApplicationData,
  selectApplicationIsLoading,
  selectPaymentDetails
} from 'features/application/selectors';
import { DrawerInfo } from 'features/applications/components/applicationCard/applicationCard';
import { ApplicationPrimaryAction } from 'features/applications/components/applicationCard/applicationPrimaryAction';
import { useNavContext } from 'features/nav/context/navContext';
import { useApplicationSelector } from 'hooks/redux/hooks';
import { Applicant, Company, FactoringAdditionalInfo, Farm } from 'services/CapService/types';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

import { ApplicationCardDrawerHero } from './applicationCardDrawerHero';
import ApplicationContentDrawerSkeleton from './applicationContentDrawerSkeleton';
import {
  ApplicationContentDrawerContainer,
  ApplicationInformation,
  ButtonContainer,
  DetailsHeader,
  DetailsSection,
  DrawerScrolableContent,
  Line,
  RowInputs,
  StepSectionTitle,
  StepSectionValue
} from './applicationDetailsDrawer.styles';
import { ErrorState } from './drawerErrorState';
import { StepSection } from './stepSection';

const purposeFields = {
  term: 'term',
  type: 'purpose'
};

const leaseFields = {
  amount: 'leaseAmount',
  advancePayment: 'advancePayment',
  propertyPrice: 'propertyPrice',
  term: 'termInMonths',
  residualAmount: 'residualAmount'
};

const farmFields: { [key in keyof Farm]?: string } = {
  firstName: 'name',
  lastName: 'lastName',
  personalCode: 'personalCode',
  code: 'farmCode',
  country: 'country'
};

const companyFields: { [key in keyof Company]?: string } = {
  name: 'placeholderCompanyName',
  code: 'placeholderCompanyCode',
  country: 'placeholderCompanyCountry'
};

const contactFields: { [key in keyof Applicant]?: string } = {
  name: 'name',
  email: 'email',
  phoneNumber: 'phone'
};

const ApplicationContentDrawer = () => {
  const { t, i18n } = useTranslation();

  const [error, setError] = useState(false);

  const { activeDrawer, showRightSideDrawer } = useNavContext<DrawerInfo>();

  const { id: capId, applicationId, viewOnly } = activeDrawer ?? {};

  const application = useApplicationSelector<typeof selectApplicationData>(selectApplicationData);
  const loading = useApplicationSelector<typeof selectApplicationIsLoading>(selectApplicationIsLoading);
  const paymentDetails = useApplicationSelector<typeof selectPaymentDetails>(selectPaymentDetails);

  const [fetchApplicationByIdAsync] = useLazyFetchApplicationDetails();

  const initiateFetchApplication = async () => {
    try {
      const result = await fetchApplicationByIdAsync({
        capId: capId ?? '',
        applicationId: applicationId ?? ''
      }).unwrap();

      setError(false);

      return result;
    } catch {
      setError(true);
    }
  };

  useEffect(() => {
    if (showRightSideDrawer && (capId || applicationId) && !viewOnly) {
      initiateFetchApplication();
    }
  }, [capId, showRightSideDrawer, applicationId]);

  const convertedCompanyValues = {
    ...application.generalInfo?.company,
    country: getCountryDisplayName(
      COMPANY_REGISTRATION_COUNTRIES_LIST.find(
        (item) => item.value === application.generalInfo?.company?.country
      )?.value ?? '',
      i18n.language as Languages
    )
  };

  const convertedFarmValues = {
    ...application.generalInfo?.farm,
    country: getCountryDisplayName(
      COMPANY_REGISTRATION_COUNTRIES_LIST.find(
        (item) => item.value === application.generalInfo?.farm?.country
      )?.value || '',
      i18n.language as Languages
    )
  };

  const contactModifiedData = {
    ...application.generalInfo?.applicant,
    name: `${application.generalInfo?.applicant?.firstName} ${application.generalInfo?.applicant?.lastName}`
  };

  const factoringFields: { [key in keyof FactoringAdditionalInfo]?: string } = {
    partyType: 'factoringWhichInvoices',
    informThirdParties: 'factoringInformBuyers',
    insurancePresent:
      application.factoringAdditionalInfo?.insurancePresent !== null ? 'factoringInsurance' : '',
    agreeToPayInsuranceLimitFee:
      application.factoringAdditionalInfo?.agreeToPayInsuranceLimitFee !== null
        ? 'factoringBuyerInsurance'
        : ''
  };
  const formatCurrency = formatNumber(i18n.language);
  const percentage = formatPercentage(i18n.language);

  const convertedPuposeValues = {
    ...application.loansAdditionalInfo,
    type: t(
      PURPOSE_LIST.find((item) => item.value === application.loansAdditionalInfo?.purpose?.type)?.label || ''
    )
  };

  const leaseValues = {
    amount: formatCurrency(
      Number(paymentDetails?.propertyPrice ?? 0) - Number(paymentDetails?.advancePayment ?? 0)
    ),
    advancePayment: `(${percentage(Number(paymentDetails?.advancePaymentPercentage), 0)}) ${formatCurrency(paymentDetails?.advancePayment)}`,
    propertyPrice: formatCurrency(paymentDetails?.propertyPrice) ?? '-',
    term: paymentDetails?.term ?? '-',
    residualAmount: `(${percentage(Number(paymentDetails?.residualValuePercentage), 0)}) ${formatCurrency(paymentDetails?.residualValue)}`
  };

  const translatedFactoringTypeValues = {
    ...application.factoringAdditionalInfo,
    partyType: t(application.factoringAdditionalInfo?.partyType?.toLowerCase() || '')
  };

  const convertedThirdParties = application.thirdParties?.length
    ? application.thirdParties?.map((party) => ({
        ...party,
        company: {
          ...party.company,
          country: getCountryDisplayName(
            ISO_CODES.find((item) => item.value === party.company?.country)?.value || '',
            i18n.language as Languages
          )
        }
      }))
    : null;

  if (loading) {
    return <ApplicationContentDrawerSkeleton />;
  }

  if (error) {
    return <ErrorState handleRetry={initiateFetchApplication} />;
  }

  return (
    <>
      <ApplicationCardDrawerHero viewOnly={viewOnly} />
      <ApplicationContentDrawerContainer>
        <DrawerScrolableContent>
          {application.loansAdditionalInfo ? (
            <ApplicationInformation>
              <StepSection data={convertedPuposeValues} label={'termsAndPurpose'} fields={purposeFields} />
            </ApplicationInformation>
          ) : null}
          {paymentDetails ? (
            <ApplicationInformation>
              <StepSection data={leaseValues} label={'leaseDetails'} fields={leaseFields} />
            </ApplicationInformation>
          ) : null}
          {application.generalInfo?.farm ? (
            <ApplicationInformation>
              <StepSection data={convertedFarmValues} label={'farm'} fields={farmFields} />
            </ApplicationInformation>
          ) : null}
          {application.generalInfo?.company ? (
            <ApplicationInformation>
              <StepSection data={convertedCompanyValues} label={'company'} fields={companyFields} />
            </ApplicationInformation>
          ) : null}
          {application.generalInfo?.applicant ? (
            <ApplicationInformation>
              <StepSection data={contactModifiedData} label={'contactPerson'} fields={contactFields} />
            </ApplicationInformation>
          ) : null}
          {application.factoringAdditionalInfo ? (
            <ApplicationInformation>
              <StepSection
                data={translatedFactoringTypeValues}
                label={'factoringType'}
                fields={factoringFields}
              />
            </ApplicationInformation>
          ) : null}
          {application.beneficialOwners?.length ? (
            <ApplicationInformation>
              <DetailsSection>
                <DetailsHeader>{t('beneficialOwners')}</DetailsHeader>
                {application.beneficialOwners.map((owner) => {
                  return (
                    <RowInputs key={owner.firstName}>
                      <StepSectionTitle>{`${owner.firstName} ${owner.lastName}`}</StepSectionTitle>
                      <StepSectionValue>{`${t('owns')} ${owner.owningPercentage}%`}</StepSectionValue>
                    </RowInputs>
                  );
                })}
              </DetailsSection>
            </ApplicationInformation>
          ) : null}
          {Boolean(application.thirdParties?.length) && convertedThirdParties ? (
            <ApplicationInformation>
              <DetailsSection>
                <DetailsHeader>{t('thirdParties')}</DetailsHeader>
                {convertedThirdParties?.map((party) => {
                  return (
                    <Fragment key={party.company.code}>
                      <RowInputs>
                        <StepSectionTitle>{t('company')}</StepSectionTitle>
                        <StepSectionValue>{party.company.name || '-'}</StepSectionValue>
                      </RowInputs>
                      <RowInputs>
                        <StepSectionTitle>{t('code')}</StepSectionTitle>
                        <StepSectionValue>{party.company.code || '-'}</StepSectionValue>
                      </RowInputs>
                      <RowInputs>
                        <StepSectionTitle>{t('vatCode')}</StepSectionTitle>
                        <StepSectionValue>{party.company.vatCode || '-'}</StepSectionValue>
                      </RowInputs>
                      <RowInputs>
                        <StepSectionTitle>{t('country')}</StepSectionTitle>
                        <StepSectionValue>{party.company.country || '-'}</StepSectionValue>
                      </RowInputs>
                      <RowInputs>
                        <StepSectionTitle>{t('defferedPayment')}</StepSectionTitle>
                        <StepSectionValue>
                          {party.deferralPeriod ? `${party.deferralPeriod} ${t('days')}` : '-'}
                        </StepSectionValue>
                      </RowInputs>
                      <RowInputs>
                        <StepSectionTitle>{t('amountOfInvoicesPerMonth')}</StepSectionTitle>
                        <StepSectionValue>{formatCurrency(party.limitAmount)}</StepSectionValue>
                      </RowInputs>
                      <Line></Line>
                    </Fragment>
                  );
                })}
              </DetailsSection>
            </ApplicationInformation>
          ) : null}
          {viewOnly ? null : (
            <ButtonContainer>
              <ApplicationPrimaryAction applicationId={applicationId} id={capId} />
            </ButtonContainer>
          )}
        </DrawerScrolableContent>
      </ApplicationContentDrawerContainer>
    </>
  );
};

export default ApplicationContentDrawer;
