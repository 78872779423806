import styled from 'styled-components';

import { RegularText } from 'components';
import { ButtonColumn } from 'components/buttonV2';

export const CounterContainer = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;

  ${RegularText} {
    font-size: ${({ theme }) => theme.fontSizes.h4};
  }
`;

export const Counter = styled.span`
  color: ${({ theme }) => theme.colors.primary.linkText};
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.background};
  align-self: center;
  border-radius: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const ButtonsContainer = styled(ButtonColumn)`
  margin: 0 2.5rem;
  width: initial;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin: 0 0 0.25rem;
  }
`;

export const Header = styled(RegularText)`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    font-size: ${({ theme }) => theme.fontSizes.h2};
    line-height: 1.5rem;
  }
`;
