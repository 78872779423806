import { HTMLAttributes } from 'react';

import { lightTheme } from '../../design/systemDesign';
import { Spinner } from '../loader';

import {
  ButtonLabel,
  Button as StyledButton,
  Props as StyledButtonProps,
  getButtonTextColor
} from './button.styles';

type Attributes = HTMLAttributes<HTMLButtonElement>;

type Props = StyledButtonProps &
  Attributes & {
    disabled?: boolean;
    type?: 'button' | 'submit';
    submitting?: boolean;
    name?: string;
    doNotTruncate?: boolean;
    'data-testid'?: string;
  };

export const Button = ({ disabled, ...rest }: Props) => {
  const spinnerColor = getButtonTextColor('disabled', rest.variant, rest.color, lightTheme);

  return (
    <StyledButton {...rest} aria-disabled={disabled} disabled={disabled}>
      <Spinner display={rest.submitting} color={spinnerColor} />
      <ButtonLabel>{rest.children}</ButtonLabel>
    </StyledButton>
  );
};
