import { createContext, useContext, ReactNode, useState } from 'react';

export const ScrollStateContext = createContext({
  currentScrollPosition: 0,
  saveScrollPosition: (_newScrollPosition: number) => {
    console.warn('ScrollStateContextProvider not initialized', _newScrollPosition);
  }
});

export const useScrollStateContext = () => {
  return useContext(ScrollStateContext);
};

type Props = {
  children: ReactNode;
};

export const ScrollStateContextProvider = ({ children }: Props) => {
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  const saveScrollPosition = (newCurrentScrollPositon: number) =>
    setCurrentScrollPosition(newCurrentScrollPositon);

  return (
    <ScrollStateContext.Provider
      value={{
        currentScrollPosition,
        saveScrollPosition
      }}
    >
      {children}
    </ScrollStateContext.Provider>
  );
};
