import styled from 'styled-components';

import { Fieldset, Overlay, PaperStyle } from 'components';
import { ListContainer } from 'components/formList/formList.styles';

export const ThirdPartiesFieldset = styled(Fieldset)`
  margin-bottom: 1.25rem;
`;

export const ThirdPartiesFormContainer = styled.div`
  ${ListContainer} {
    margin: 0 0 1.25rem;
  }

  ${Overlay} {
    > ${PaperStyle} {
      max-width: 35.75rem;
    }
  }
`;

export const FieldTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.h4};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  margin: 1rem 0 0.5rem;
`;
