import styled from 'styled-components';

export type ToastProps = {
  isVisible: boolean;
  variant: 'info' | 'error' | 'warning' | 'success';
};

export const ToastStyle = styled.div<ToastProps>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  height: auto;
  min-height: 4.5rem;
  background: ${({ variant, theme }) => theme.colors.toast[variant].background};
  border-radius: 0.5rem;
  color: ${({ variant, theme }) => theme.colors.toast[variant].text};
  padding: 1.25rem;
  align-items: center;
  flex: 1 1 100%;
  flex-direction: column;
  position: relative;

  &::before {
    z-index: 9;
    position: absolute;
    content: '';
    width: 0.375rem;
    background-color: ${({ variant, theme }) => theme.colors.toast[variant].text};
    height: 100%;
    left: 0;
    top: 0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &::after {
    z-index: 99;
    position: absolute;
    content: '';
    width: 0.375rem;
    background-color: ${({ variant, theme }) => theme.colors.toast[variant].background};
    left: 0.1875rem;
    top: 0;
    height: 100%;
  }

  svg {
    path {
      stroke: ${({ variant, theme }) => theme.colors.toast[variant].text};
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
  }
`;

export const IconContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
`;

export const ToastContainer = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
`;

export const ToastMessage = styled.div`
  display: flex;
  width: 100%;

  p {
    justify-content: center;
    align-items: center;
  }
`;

export const ToastListContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ToastList = styled.ul`
  margin-top: 0;
  margin-left: 0.625rem;
  margin-bottom: 0;

  li {
    font-size: 0.875rem;
  }
`;
