import {
  SkeletonFile,
  SkeletonFileButton,
  SkeletonFileIcon,
  SkeletonFileName
} from './offerStatusModal.styles';

const OfferDocumentSkeleton = () => {
  return (
    <SkeletonFile>
      <SkeletonFileIcon />
      <SkeletonFileName />
      <SkeletonFileButton />
    </SkeletonFile>
  );
};

export default OfferDocumentSkeleton;
