import styled from 'styled-components';

import { LinkButton, Typography } from 'components';
import { Card } from 'features/application/components/productCard/productCard.styles';

export const SubproductCard = styled(Card)`
  padding-inline: 1.5rem 1rem;
`;

export const SubproductName = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })`
  text-align: left;
  display: inline-flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

export const DrawerLink = styled(LinkButton)`
  display: inline-flex;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.base.primary.shades[20]};
  text-decoration: underline;
  line-height: 1.5rem;
`;
