import styled from 'styled-components';

import { Typography } from 'components';

export const Header = styled(Typography.Header).attrs({ type: 'h1', weight: 'light' })``;

export const Subheader = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5rem;

  @media ${({ theme }) => theme.media.maxPhone} {
    justify-content: space-between;
  }
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
