import { selectActiveCustomer, selectIsRegularUser } from 'features/auth/selectors';
import { useAuthSelector } from 'hooks/redux/hooks';
import { ProductType } from 'services/LoansService/types';

import { useGetTotalsQuery } from '../api';

export const useFetchLoansTotals = (totalsType: ProductType) => {
  const isRegularUser = useAuthSelector<typeof selectIsRegularUser>(selectIsRegularUser);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const { customerCountryId, customerId } = activeCustomer || {};

  const request = {
    customerCode: customerId || '',
    customerType: 'B',
    customerCountryCode: customerCountryId || ''
  };

  const result = useGetTotalsQuery(request, {
    skip: !customerId || !customerCountryId || !isRegularUser,
    selectFromResult: (response) => {
      const productTotals = response.data?.find((totals) => totals.productType === totalsType);

      return {
        ...response,
        productTotals,
        hasNoData: !productTotals
      };
    }
  });

  return result;
};
