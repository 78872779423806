import { useFetchExistingCustomerOnboardingStatus } from 'features/onboarding/hooks';

import { Status } from '../types/applicationsTypes';

const allowedWithoutOnboarding: Status[] = ['NOT_FILLED', 'INITIAL'];

const useFetchExistingCustomerApplicationOnboardingStatus = (status?: Status) => {
  const onboardingStatusCheckResult = useFetchExistingCustomerOnboardingStatus();

  const { onboardingNeeded } = onboardingStatusCheckResult;

  const onboardingForApplicationNeeded =
    onboardingNeeded && status && !allowedWithoutOnboarding.includes(status);

  return { ...onboardingStatusCheckResult, onboardingForApplicationNeeded };
};

export default useFetchExistingCustomerApplicationOnboardingStatus;
