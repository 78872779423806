import styled, { css } from 'styled-components';

import { LinkButton } from 'components/link';

export type ActionToastProps = {
  isVisible?: boolean;
  variant: 'info' | 'error' | 'warning' | 'success';
  animate?: boolean;
};

export const ToastStyle = styled.div<ActionToastProps>`
  display: flex;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  height: auto;
  min-height: 4.5rem;
  background: ${({ variant, theme }) => theme.colors.toast[variant].background};
  color: ${({ theme }) => theme.colors.base.black.faded};
  border-radius: 0.5rem;
  align-items: center;
  flex: 1 1 100%;
  position: fixed;
  top: 2.5rem;
  right: 3rem;
  z-index: ${({ theme }) => theme.layers.sessionTimeoutModal};
  width: 25rem;
  max-width: 25rem;
  ${({ animate }) =>
    animate
      ? css<ActionToastProps>`
          transition: ${(props) =>
            props.isVisible ? 'visibility 0.8 ease-in-out' : 'visibility 0.8 ease-out'};
          animation: ${(props) =>
            props.isVisible
              ? 'open-action-toast 0.8s ease-out forwards'
              : 'close-action-toast 0.5s ease-out forwards'};
        `
      : ''}

  &::before {
    z-index: 9;
    position: absolute;
    content: '';
    width: 0.063rem;
    background-color: ${({ variant, theme }) => theme.colors.toast[variant].border};
    height: 100%;
    right: 3rem;
    top: 0;
    opacity: 0.2;
    margin-left: -50px;
  }

  svg {
    path {
      stroke: ${({ variant, theme }) => theme.colors.toast[variant].border};
      opacity: 0.5;
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
  }

  @keyframes open-action-toast {
    from {
      transform: translateX(100%);
      visibility: hidden;
    }

    to {
      transform: translateX(0);
      visibility: visible;
    }
  }

  @keyframes close-action-toast {
    from {
      transform: translateX(0);
      visibility: visible;
    }

    to {
      transform: translateX(150%);
      visibility: hidden;
    }
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    bottom: 2.5rem;
    right: 1rem;
    left: 1rem;
    top: auto;
    width: auto;
    max-width: 100%;

    @keyframes open-action-toast {
      from {
        transform: translateY(100%);
        visibility: hidden;
      }

      to {
        transform: translateY(0);
        visibility: visible;
      }
    }

    @keyframes close-action-toast {
      from {
        transform: translateY(0);
        visibility: visible;
      }

      to {
        transform: translateY(150%);
        visibility: hidden;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  padding: 0 1rem;
`;

export const ToastContainer = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
`;

export const ToastMessage = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  flex-direction: column;

  p {
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export const IconContainer = styled(LinkButton)`
  display: flex;
  margin: 0 0.8rem;

  svg {
    margin: 0;
  }
`;

export const ToastHeader = styled.p<ActionToastProps>`
  font-size: 0.875rem;
  color: ${({ variant, theme }) => theme.colors.toast[variant].header};
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
