import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;

export const ErrorPageCardColumn = styled(Column)`
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-top: 7rem;

    @media ${({ theme }) => theme.media.maxTablet} {
      margin-top: 6rem;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
