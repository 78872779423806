import { format, isValid, Locale } from 'date-fns';
import { enGB, lt } from 'date-fns/locale';

export const transformDateToLocalizedString = (date?: string | Date | null) => {
  if (!date) return '';

  try {
    let validatedDate;
    const dateReg = /^\d{2}([./\-\s])\d{2}\1\d{4}$/;

    if (typeof date === 'string' && date.match(dateReg)) {
      validatedDate = new Date(
        date
          //eslint-disable-next-line
          .split(/[\.\-\/\\\s]/)
          .reverse()
          .join('-')
      );
    } else if (typeof date === 'string') {
      validatedDate = new Date(date);
    } else {
      validatedDate = date;
    }

    if (validatedDate?.getFullYear() === 1) return '';

    return Boolean(validatedDate) && isValid(validatedDate) ? formatFullDateISO(validatedDate) : '';
  } catch {
    //silently fail
  }
};

export const formatDateToCustomString = (dateFormat: string) => (date: string) => {
  const dateObject = new Date(date);

  if (isValid(dateObject)) {
    return format(dateObject, dateFormat);
  }

  return '';
};

export const formatFullMonthAndYearOnly = (language: string) => (date: string) => {
  const dateObject = new Date(date);

  const formats: Record<string, string> = {
    lt: "yyyy 'm.' LLLL",
    en: 'LLLL, yyyy'
  };

  const locales: Record<string, Locale> = {
    en: enGB,
    lt
  };

  const selectedFormat = formats[language] || formats.en;
  const selectedLocale = locales[language] || locales.en;

  if (isValid(dateObject) && selectedFormat) {
    return format(dateObject, selectedFormat, { locale: selectedLocale });
  }

  return '';
};

export const formatShortMonth = (language: string) => (date: string) => {
  const dateObject = new Date(date);

  const formats: Record<string, string> = {
    lt: 'L',
    en: 'LLL'
  };

  const ltMonths = ['Sau', 'Vas', 'Kov', 'Bal', 'Geg', 'Bir', 'Lie', 'Rgp', 'Rgs', 'Spa', 'Lap', 'Gru'];

  const selectedFormat = formats[language] || formats.en;

  if (isValid(dateObject) && selectedFormat) {
    if (language !== 'lt') {
      return format(dateObject, selectedFormat);
    }

    const monthNumberNotation = format(dateObject, selectedFormat);

    return ltMonths[Number(monthNumberNotation) - 1];
  }

  return '';
};

export const formatFullDateISO = (date: Date) => {
  if (isValid(date)) {
    return format(date, 'yyyy-MM-dd');
  }

  return '';
};

export const formatFullDate = (language: string) => (date: string) => {
  const dateObject = new Date(date);

  const formats: Record<string, string> = {
    lt: "yyyy 'm.' MMMM d 'd.'",
    en: 'LLLL d, yyyy'
  };

  const locales: Record<string, Locale> = {
    en: enGB,
    lt
  };

  const selectedFormat = formats[language] || formats.en;
  const selectedLocale = locales[language] || locales.en;

  if (isValid(dateObject) && selectedFormat) {
    return format(dateObject, selectedFormat, { locale: selectedLocale });
  }

  return '';
};
