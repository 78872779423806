import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputView, transformDateToLocalizedString } from 'components';
import { Contract } from 'services/ContractsService/types';
import { SubmittedInvoice } from 'services/InvoicesService/types';
import { camelCase } from 'utils';

import { Column, DetailsContainer, InvoiceDetailsCard } from './invoiceDetails.styles';

type Props = {
  invoiceDetails: SubmittedInvoice;
  contractDetails: Contract;
};

export const InvoiceDetails: FC<Props> = ({ invoiceDetails, contractDetails }) => {
  const { t } = useTranslation();

  return (
    <InvoiceDetailsCard>
      <DetailsContainer>
        <Column>
          <InputView orientation="vertical" label={t('buyer')}>
            {invoiceDetails.thirdPartyName}
          </InputView>
          <InputView orientation="vertical" label={t('contractType')}>
            {t(camelCase(contractDetails.factoringProduct || '-'))}
          </InputView>
          <InputView orientation="vertical" label={t('issueDate')}>
            {transformDateToLocalizedString(invoiceDetails.invoiceDate)}
          </InputView>
          <InputView orientation="vertical" label={t('submittedforFinancing')}>
            {transformDateToLocalizedString(invoiceDetails.submittedAt)}
          </InputView>
        </Column>
        <Column>
          <InputView orientation="vertical" label={t('buyerCompanyCode')}>
            {invoiceDetails.thirdPartyRegistrationCode}
          </InputView>
          <InputView orientation="vertical" label={t('contractNumber')}>
            {contractDetails.contractNumber}
          </InputView>
          <InputView orientation="vertical" label={t('dueDate')}>
            {transformDateToLocalizedString(invoiceDetails.invoiceDueDate)}
          </InputView>
          <InputView orientation="vertical" label={t('financingApproved')}>
            {transformDateToLocalizedString(invoiceDetails.approvalDate)}
          </InputView>
        </Column>
      </DetailsContainer>
    </InvoiceDetailsCard>
  );
};
