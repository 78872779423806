import ReactModal from 'react-modal';

import { Overlay } from 'components/modal/modal.styles';

import BankCard from '../bankCard/bankCard';

import { ModalContent } from './addBankAccountModal.styles';

type Props = {
  open?: boolean;
  onClose?: () => unknown;
};

const AddBankAccountModal = ({ open, onClose }: Props) => {
  return (
    <ReactModal
      isOpen={Boolean(open)}
      overlayElement={() => (
        <Overlay variant="informational">
          <ModalContent>
            <BankCard step="setUpBankAccount" onClose={onClose} />
          </ModalContent>
        </Overlay>
      )}
    ></ReactModal>
  );
};

export default AddBankAccountModal;
