import styled from 'styled-components';

import { Button } from 'components/buttonV2';

import { ToastContainerLegacy } from '../../../../components';

export const ContainerStyle = styled.div`
  margin-top: 2.5rem;
`;

export const FormButton = styled(Button).attrs({ type: 'button', size: 'L' })`
  width: 100%;
  margin-top: 1.5rem;
`;

export const PersonalDataFormContainer = styled.div`
  height: 100%;

  ${ToastContainerLegacy} {
    margin-bottom: 0.625rem;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
