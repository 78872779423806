import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Legend, Loader, RegularText, ToastLegacy } from 'components';
import { selectCompany, selectCompanyRegistrationCountryCode } from 'features/application/selectors';
import { selectActiveCustomer } from 'features/auth/selectors';
import {
  useAppDispatch,
  useApplicationSelector,
  useAuthSelector,
  useStatementsSelector
} from 'hooks/redux/hooks';
import { BankConsentCountry } from 'services/StatementsService/types';

import { FormContainer } from '../../../application/components/formContainer';
import {
  selectInstitutionById,
  selectInstitutionsIsLoading,
  selectInstitutionsLoadFailed,
  selectInstitutionsWithConsent,
  selectInstitutionsWithoutConsent
} from '../../selectors';
import {
  fetchCollectData,
  fetchInsitutionConsentLink,
  fetchInstitutionsByCountryAsync,
  setConsentFlowIsIniatedFor,
  setConsentForInstitution
} from '../../slice';

import {
  BankAccountButton,
  BankAccountContent,
  BankAccountsFormStyles,
  BankName,
  AccountsSection,
  BankAccountsFormHeadersStyles
} from './bankAccountsForm.styles';

type Props = {
  onNextClick?: () => void;
  displayInsideFormWrapper?: boolean;
  displayInCAPFlow?: boolean;
};

const BANK_ID = 'bankId';
const REF = 'ref';
const ERROR = 'error';
const DETAILS = 'details';
const CAP_ID = 'capId';

const parseSearchParams = (search: string): Record<string, string> => {
  return Object.fromEntries(
    search
      .replace('?', '')
      .split('&')
      .map((entry) => entry.split('='))
  );
};

export const BankAccountsForm: FC<Props> = ({
  onNextClick,
  displayInsideFormWrapper = true,
  displayInCAPFlow = true
}) => {
  const locationRef = useRef('');
  const mutexLockConfirmationRef = useRef(false);
  const mutexLockInstitutionsRef = useRef(false);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    locationRef.current = location.search;
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsMemoized = parseSearchParams(locationRef.current);

  const bankIdQueryParam = searchParamsMemoized[BANK_ID];
  const refQueryParam = searchParamsMemoized[REF];
  const consentError = searchParamsMemoized[ERROR];

  const countryCode = useApplicationSelector<typeof selectCompanyRegistrationCountryCode>(
    selectCompanyRegistrationCountryCode
  );
  const newApplicationCompany = useApplicationSelector<typeof selectCompany>(selectCompany);
  const institutionsWithConsent = useStatementsSelector<typeof selectInstitutionsWithConsent>(
    selectInstitutionsWithConsent
  );
  const isError = useStatementsSelector<typeof selectInstitutionsLoadFailed>(selectInstitutionsLoadFailed);
  const isLoading = useStatementsSelector<typeof selectInstitutionsIsLoading>(selectInstitutionsIsLoading);

  const getInstitutionById = useStatementsSelector<typeof selectInstitutionById>(selectInstitutionById);
  const institutionsWithoutConsent = useStatementsSelector<typeof selectInstitutionsWithoutConsent>(
    selectInstitutionsWithoutConsent
  );
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const selectedInstitution = bankIdQueryParam ? getInstitutionById(bankIdQueryParam) : undefined;

  const countryIdToUse = displayInCAPFlow ? countryCode : activeCustomer?.customerCountryId || countryCode;

  // TODO displaying previously added consent will not work in DEV StrictMode as
  // double useEffect call will remove search query paramswe are getting from nordigen on first umount
  // this however should be temporary problem until we get api to fetch banks user has given consent to
  // in meantime if we need to test it in DEV mode disable react strict mode
  useEffect(() => {
    (async () => {
      if (countryIdToUse) {
        if (!mutexLockInstitutionsRef.current) {
          mutexLockInstitutionsRef.current = true;
          await dispatch(fetchInstitutionsByCountryAsync({ country: countryIdToUse })).unwrap();
          mutexLockInstitutionsRef.current = false;
        }

        if (bankIdQueryParam && refQueryParam) {
          if (!consentError) {
            try {
              let payload = {
                companyCode: activeCustomer?.customerId || '',
                companyName: activeCustomer?.customerName || '',
                country: activeCustomer?.customerCountryId as BankConsentCountry,
                requisitionId: refQueryParam
              };

              if (newApplicationCompany && newApplicationCompany.code && newApplicationCompany.name) {
                payload = {
                  companyCode: newApplicationCompany.code,
                  companyName: newApplicationCompany.name,
                  country: newApplicationCompany.country as BankConsentCountry,
                  requisitionId: refQueryParam
                };
              }

              if (!mutexLockConfirmationRef.current) {
                mutexLockConfirmationRef.current = true;
                await dispatch(fetchCollectData(payload)).unwrap();
                mutexLockConfirmationRef.current = false;
              }

              locationRef.current = '';

              if (selectedInstitution) {
                dispatch(setConsentForInstitution(selectedInstitution));
              }
            } finally {
              locationRef.current = '';

              mutexLockConfirmationRef.current = false;
              mutexLockInstitutionsRef.current = false;

              searchParams.delete(BANK_ID);
              searchParams.delete(REF);
              searchParams.delete(ERROR);
              searchParams.delete(DETAILS);
              searchParams.delete(CAP_ID);

              setSearchParams(searchParams, { replace: true });
            }
          }
        }
      }
    })();
  }, [
    newApplicationCompany,
    activeCustomer,
    selectedInstitution,
    bankIdQueryParam,
    consentError,
    countryIdToUse,
    refQueryParam,
    dispatch
  ]);

  const onClick = (id: string) => () => {
    dispatch(setConsentFlowIsIniatedFor(id));

    dispatch(
      fetchInsitutionConsentLink({
        institutionId: id,
        redirectUrl: window.location.search
          ? `${window.location.href}&${BANK_ID}=${id}`
          : `${window.location.href}?${BANK_ID}=${id}`,
        userLanguage: i18n.language.toUpperCase()
      })
    );
  };

  const content = (
    <>
      {institutionsWithConsent?.length ? (
        <>
          <RegularText>{t('linkedAccounts')}</RegularText>
          <AccountsSection>
            {institutionsWithConsent.map((institution) => (
              <BankAccountContent key={institution.bic}>
                <img src={institution.logo} />
                <BankName>{institution.name}</BankName>
              </BankAccountContent>
            ))}
          </AccountsSection>
        </>
      ) : null}
      {institutionsWithoutConsent?.length ? (
        <>
          <RegularText>
            {t(institutionsWithConsent?.length ? 'chooseAnotherBankAccount' : 'chooseBankAccount')}
          </RegularText>
          <AccountsSection>
            {institutionsWithoutConsent.map((institution) => (
              <BankAccountButton key={institution.bic} onClick={onClick(institution?.id || '')}>
                <BankAccountContent>
                  <img src={institution.logo} />
                  <BankName>{institution.name}</BankName>
                </BankAccountContent>
              </BankAccountButton>
            ))}
          </AccountsSection>
        </>
      ) : null}
    </>
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BankAccountsFormStyles>
      {displayInsideFormWrapper ? (
        <FormContainer onNextClick={onNextClick}>{content}</FormContainer>
      ) : (
        <BankAccountsFormHeadersStyles>
          <RegularText>{t('bankAccounts')}</RegularText>
          <Legend>{t('bankAccountsExplanation')}</Legend>
          {isError && !isLoading ? <ToastLegacy isVisible message={t('generalErrorMessage')} /> : content}
        </BankAccountsFormHeadersStyles>
      )}
    </BankAccountsFormStyles>
  );
};
