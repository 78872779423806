import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Input } from 'components';
import { FieldState } from 'components/formField/field';
import { Drawer } from 'features/app/components/drawer';
import { selectActiveCustomer, selectCustomerInformation } from 'features/auth/selectors';
import { patchCustomerInformation } from 'features/auth/slice';
import { useNavContext } from 'features/nav/context/navContext';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { RequestUpdateCustomer } from 'services/RbacService/typings';
import { CustomBaseQueryError } from 'services/RestClient/typings';

import { usePatchCustomerMutation } from '../../slice';
import { validationSchema } from '../createCompanyDrawer/createCompanyDrawer';

type PayloadBody = RequestUpdateCustomer['content']['application/json'];

type Props = {
  updateProperty: keyof PayloadBody;
};

const EditCompanyInfoDrawer = ({ updateProperty }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const customerInformation = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldState, setFieldState] = useState<FieldState>({} as FieldState);

  const { hideSideDrawer } = useNavContext();

  const fields: PayloadBody = useMemo(
    () => ({
      customerCode: activeCustomer?.customerId || '',
      customerCountry: activeCustomer?.customerCountryId || '',
      customerName: activeCustomer?.customerName || '',
      customerCrmCode: activeCustomer?.customerCrmId || '',
      customerType: 'BUSINESS'
    }),
    [
      activeCustomer?.customerId,
      activeCustomer?.customerCountryId,
      activeCustomer?.customerName,
      activeCustomer?.customerCrmId
    ]
  );

  const [triggerCustomerUpdate] = usePatchCustomerMutation();

  const { validationHelpers, getValues, handleSubmit, setTouchedOnAll, reset } = useFormHandlers(
    fields,
    validationSchema
  );

  const handleClose = () => {
    reset(fields);
    hideSideDrawer?.();
  };

  const handleSave = () => {
    handleSubmit(
      async (payload) => {
        try {
          setIsSubmitting(true);

          if (
            updateProperty === 'customerCrmCode' &&
            payload.customerCrmCode &&
            customerInformation?.customers?.some(
              (customer) =>
                customer.customerCrmId === payload.customerCrmCode &&
                customer.customerId !== activeCustomer?.customerId
            )
          ) {
            const queryError: CustomBaseQueryError = {
              status: 400,
              data: {
                message: 'CUSTOMER_CRM_ID_ALREADY_EXIST'
              }
            };

            throw queryError;
          }

          await triggerCustomerUpdate({
            country: payload.customerCountry,
            companyCode: payload.customerCode,
            content: { 'application/json': payload }
          }).unwrap();

          dispatch(
            patchCustomerInformation({
              customerId: payload?.customerCode,
              customerCountryId: payload.customerCountry,
              customerName: payload.customerName,
              customerCrmId: payload.customerCrmCode,
              referenceCustomerId: activeCustomer?.customerId ?? payload?.customerCode
            })
          );

          handleClose();
        } catch (e) {
          const baseQueryError = e as CustomBaseQueryError<{ id?: string; message?: string }>;

          if (
            baseQueryError?.status === 400 &&
            ['CUSTOMER_ALREADY_EXIST', 'CUSTOMER_CRM_ID_ALREADY_EXIST'].includes(
              baseQueryError.data?.message ?? ''
            )
          ) {
            const resolvedFieldState = {
              isDirty: true,
              isTouched: true,
              message: t('companyAlreadyExists'),
              error: {
                message: t('companyAlreadyExists'),
                type: 'manual'
              },
              invalid: true,
              showValidationMessage: true
            };

            setFieldState(resolvedFieldState);
          }
        } finally {
          setIsSubmitting(false);
        }
      },
      (errors) => {
        setFieldState({
          isDirty: true,
          isTouched: true,
          error: errors[updateProperty],
          message: errors[updateProperty]?.message
        });
        setTouchedOnAll();
      }
    )();
  };

  return (
    <>
      <Drawer
        header={t(`${updateProperty}EditDrawerHeader`)}
        buttonLabel={t('save')}
        onSave={handleSave}
        onClose={handleClose}
        isSubmitting={isSubmitting}
        isValid
        dataTestId={`edit-${updateProperty}`}
      >
        <Field
          testId={`company-edit-${updateProperty}-field`}
          name={updateProperty}
          Component={Input}
          validationHelpers={validationHelpers}
          validationMeta={fieldState}
          placeholder={t(`${updateProperty}EditFieldPlaceholder`)}
          required
          value={getValues(updateProperty)}
        />
      </Drawer>
    </>
  );
};

export default EditCompanyInfoDrawer;
