import { useEffect } from 'react';

const setScrollbarOffsetToVariable = () => {
  const root = document.documentElement;
  root.style.setProperty(
    '--windowScrollbar-offset',
    `${window.innerWidth - window.document.documentElement.clientWidth}px`
  );
};

/**
 * Determines scrollbar offset on page load and sets CSS variable
 *
 * for autohide scrollbar will always be 0
 */
export function useScrollBarOffsetVariable() {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!document.body.classList.contains('drawer-focused')) {
        setScrollbarOffsetToVariable();
      }
    });

    observer.observe(document.documentElement);

    return () => observer.disconnect();
  }, [window.document.documentElement.clientWidth]);
}
