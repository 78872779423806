import styled from 'styled-components';

type ButtonSectionStyleProps = {
  hidde: boolean;
  showBackButton: boolean;
};

export const ButtonSectionStyle = styled.div<ButtonSectionStyleProps>`
  display: ${(props) => (props.hidde ? 'none' : 'flex')};
  margin: 1.5rem 0 0;
  flex-direction: row;
  justify-content: ${({ showBackButton }) => (showBackButton ? 'space-between' : 'flex-end')};

  @media ${({ theme }) => theme.media.maxPhone} {
    flex-direction: row;
    justify-content: space-between;
    gap: 0.625rem;
  }
`;
