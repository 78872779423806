import styled from 'styled-components';

import { Paper, Typography } from 'components';
import { breakpointSizes } from 'design/designVariables';

export const Card = styled(Paper)`
  display: flex;
  width: 100%;
  padding: 2rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  box-shadow: none;

  @media ${() => `(max-width: ${breakpointSizes.maxPhonePx - 200}px)`} {
    padding: 1rem;
    gap: 1.5rem;

    button {
      width: 100%;
    }
  }
`;

export const EmptyCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const EmptyCardContentImage = styled.img`
  width: 10rem;
  height: 10rem;
`;

export const EmptyCardContentText = styled(Typography.Body).attrs({ type: 'M', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.shades[60]};
  text-align: center;
`;
