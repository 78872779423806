import { createPopper, Instance, Placement, State, StrictModifiers, VirtualElement } from '@popperjs/core';
import { OffsetsFunction } from '@popperjs/core/lib/modifiers/offset';
import { MutableRefObject, FC, useEffect, useRef, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';

import {
  PopupStyles,
  PopupArrow,
  PopupStylesContainer,
  PopupIconContainer,
  PopupContainerAnchor,
  PopupStylesContainerProps
} from './popup.styles';

type PopupProps = {
  id?: string | number;
  virtualAnchorElement?: VirtualElement | Element | null;
  button: ReactNode;
  children: ReactNode | ReactNode[];
  className?: string;
  boundaryContainer?: HTMLDivElement;
  showArrow?: boolean;
  showPureChildren?: boolean;
  containerOffset?: OffsetsFunction;
  placement?: Placement;
  onVisibilityChange?: (visible: boolean) => unknown;
  disabled?: boolean;
  keepPopupSameWidth?: boolean;
  showOnHover?: boolean;
  fullWidth?: boolean;
  focusableRefs?: MutableRefObject<HTMLElement | null>[];
  showAfter?: number;
  padding?: number;
  inputContainer?: boolean;
} & PopupStylesContainerProps;

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: { state: State }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: { state: State }) => {
    state.elements.popper.style.width = `${(state.elements.reference as HTMLDivElement).offsetWidth}px`;
  }
};

const Popup: FC<PopupProps> = ({
  button,
  children,
  className,
  showPureChildren,
  onVisibilityChange,
  disabled,
  boundaryContainer,
  keepPopupSameWidth,
  showOnHover,
  fullWidth,
  focusableRefs,
  inputContainer,
  animationType,
  padding,
  virtualAnchorElement,
  showArrow = true,
  withAnimation = true,
  childrenHeight,
  containerOffset = () => {
    return [0, 12];
  },
  placement = 'top',
  showAfter = 100
}) => {
  const triggerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const popupRef = useRef() as MutableRefObject<HTMLDivElement>;
  const arrowRef = useRef() as MutableRefObject<HTMLDivElement>;
  const popperInstance = useRef() as MutableRefObject<Instance>;
  const documentRef = useRef() as MutableRefObject<Document>;

  const [show, setToggle] = useState(false);
  const [showAnimated, setShowAnimated] = useState(show);

  const onClick = () => {
    if (!disabled) {
      const newShow = !showAnimated;

      setToggle(newShow);
      onVisibilityChange?.(newShow);
    }
  };

  useEffect(() => {
    documentRef.current = document;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimated(show);
    }, 200);
  }, [show]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (
        show &&
        triggerRef.current &&
        popupRef.current &&
        !triggerRef.current.contains(e.target as Node) &&
        !popupRef.current.contains(e.target as Node) &&
        !focusableRefs?.some((ref) => ref.current?.contains(e.target as Node))
      ) {
        onVisibilityChange?.(false);
        setToggle(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show, onVisibilityChange, showOnHover, triggerRef]);

  useEffect(() => {
    if (triggerRef.current && popupRef.current && (arrowRef.current || !showArrow || showPureChildren)) {
      let modifiers: StrictModifiers[] = [
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            padding: padding ?? 0
          }
        },

        {
          name: 'offset',
          options: {
            offset: containerOffset
          }
        }
      ];

      if (showArrow && !showPureChildren) {
        modifiers = [
          ...modifiers,
          {
            name: 'arrow',
            options: {
              element: arrowRef.current
            }
          }
        ];
      }

      if (keepPopupSameWidth) {
        modifiers = [...modifiers, sameWidth as StrictModifiers];
      }

      popperInstance.current = createPopper(virtualAnchorElement ?? triggerRef.current, popupRef.current, {
        placement,
        strategy: 'fixed',
        modifiers
      });
    }

    return () => popperInstance.current?.destroy();
  }, [
    show || showAnimated,
    triggerRef,
    virtualAnchorElement,
    popupRef,
    showArrow,
    containerOffset,
    placement,
    keepPopupSameWidth
  ]);

  useEffect(() => {
    if (show || showAnimated) {
      popperInstance.current?.update();
    }
  }, [show || showAnimated]);

  const showWithExitDelay = withAnimation && !showOnHover && showAnimated;

  return (
    <PopupContainerAnchor fullWidth={fullWidth}>
      <PopupIconContainer
        inputContainer={inputContainer}
        show={show}
        fullWidth={fullWidth}
        className={className}
        ref={triggerRef}
        onClick={onClick}
        onMouseOver={() => {
          showOnHover && setToggle(true);
        }}
        onMouseOut={() => {
          showOnHover && setToggle(false);
        }}
      >
        {button}
        {(show || showWithExitDelay) &&
          (ReactDOM.createPortal(
            <PopupStylesContainer
              ref={popupRef}
              pause={showAfter}
              withAnimation={withAnimation}
              animationType={animationType}
              childrenHeight={childrenHeight}
              closing={!show}
              active={showAnimated}
            >
              {showPureChildren ? (
                children
              ) : (
                <PopupStyles>
                  <div>{children}</div>
                  {showArrow ? <PopupArrow data-popper-arrow ref={arrowRef} /> : null}
                </PopupStyles>
              )}
            </PopupStylesContainer>,
            boundaryContainer || documentRef.current.body
          ) as unknown as ReactNode)}
      </PopupIconContainer>
    </PopupContainerAnchor>
  );
};

export default Popup;
