import { queryBuilder } from 'services/utils/queryBuilder';

import { RestClient } from '../RestClient';
import { baseUrlBuilder } from '../utils/baseUrlBuilder';

import {
  InvoicesExportGetRequest,
  InvoicesTotalsGetRequest,
  InvoicesTotalsGetResponse,
  SubmitInvoicesPostRequestPayload,
  SubmitInvoicesPostResponse,
  SubmittedInvoicesByContractsRequest,
  SubmittedInvoicesByContractsResponse,
  SummaryDataGetRequest,
  SummaryDataGetResponse,
  SupportedDocumentsDeleteRequest,
  SupportedDocumentsDeleteResponse,
  SupportedDocumentsDownloadRequest,
  SupportedDocumentsGetRequest,
  SupportedDocumentsGetResponse,
  SupportedDocumentsPutResponse,
  UploadInvoices,
  UploadInvoicesGetRequestPayload,
  UploadPDFInvoicesRequest,
  UploadPDFInvoicesResponse,
  VerifiersGetRequest,
  VerifiersGetResponse
} from './types';

const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('invoices');

export class InvoicesService {
  static get(payload: SubmittedInvoicesByContractsRequest) {
    const url = withBaseUrl(`/invoices/submitted/invoices`);
    return restClient.GET<SubmittedInvoicesByContractsResponse>(queryBuilder(url, payload));
  }

  static submitInvoicesForVerification(payload: SubmitInvoicesPostRequestPayload) {
    const url = withBaseUrl(`/invoices/aggregate/invoices/submit`);
    return restClient.POST<SubmitInvoicesPostRequestPayload, SubmitInvoicesPostResponse>(url, payload);
  }

  /**
   * @deprecated Use rtk query Invoices api with automatic queries invalidation
   * @param payload 
   * @returns 
   */
  static getUploadedInvoices(payload: UploadInvoicesGetRequestPayload) {
    const url = withBaseUrl(`/invoices/aggregate/invoices`);
    return restClient.GET<UploadInvoices>(queryBuilder(url, payload));
  }

  static getSupportedDocuments(payload: SupportedDocumentsGetRequest) {
    const url = withBaseUrl(`/invoices/aggregate/invoices/supporting/documents`);
    return restClient.GET<SupportedDocumentsGetResponse>(queryBuilder(url, payload));
  }

    /**
   * @deprecated verifiers api should be migrated to rtk query slice 
   * @param payload 
   * @returns 
   */
  static getVerifiers(payload: VerifiersGetRequest) {
    const url = withBaseUrl(`/invoices/aggregate/invoices/verifiers`);
    return restClient.GET<VerifiersGetResponse>(queryBuilder(url, payload));
  }

  static uploadSupportDocument(contractId: string, formData: FormData) {
    const url = withBaseUrl(`/invoices/aggregate/invoices/supporting/documents?contractId=${contractId}`);
    return restClient.UPLOAD<SupportedDocumentsPutResponse>(url, formData);
  }

  static removeSupportDocumentById({ fileName, contractId }: SupportedDocumentsDeleteRequest) {
    const url = withBaseUrl(
      `/invoices/aggregate/invoices/supporting/documents/${fileName}?contractId=${contractId}`
    );
    return restClient.DELETE<SupportedDocumentsDeleteResponse>(url);
  }

  static getTotals(payload: InvoicesTotalsGetRequest) {
    const url = withBaseUrl('/invoices/totals');
    return restClient.GET<InvoicesTotalsGetResponse>(queryBuilder(url, payload));
  }

  static downloadExcelTemplate() {
    const url = withBaseUrl('/invoices/template');
    return restClient.DOWNLOAD(url);
  }

  static downloadInvoiceDocument(filename: string, contractId: string) {
    const url = withBaseUrl(`/invoices/aggregate/invoices/documents/${filename}?contractId=${contractId}`);
    return restClient.DOWNLOAD(url);
  }

    /**
   * @deprecated Use rtk query Invoices api with automatic queries invalidation
   * @param payload 
   * @returns 
   */
  static deleteInvoiceDocument(filename: string, contractId: string) {
    const url = withBaseUrl(`/invoices/aggregate/invoices/documents/${filename}?contractId=${contractId}`);
    return restClient.DELETE(url);
  }

  static downloadExportedInvoices(payload: InvoicesExportGetRequest) {
    const url = withBaseUrl('/invoices/submitted/invoices/export');
    return restClient.DOWNLOAD(queryBuilder(url, payload));
  }

  static downloadSupportedDocument({ fileName, contractId }: SupportedDocumentsDownloadRequest) {
    const url = withBaseUrl(`/invoices/aggregate/invoices/supporting/documents/${fileName}?contractId=${contractId}`);
    return restClient.DOWNLOAD(url);
  }

  static downloadExportedInvoicesHead(payload: InvoicesExportGetRequest) {
    const url = withBaseUrl('/invoices/submitted/invoices/export');
    return restClient.HEAD(queryBuilder(url, payload));
  }

  static getSummaryData(payload: SummaryDataGetRequest) {
    const url = withBaseUrl('/invoices/aggregate/invoices/summary');
    return restClient.GET<SummaryDataGetResponse>(queryBuilder(url, payload));
  }

  static uploadPdfInvoices(contractId: UploadPDFInvoicesRequest['contractId'], file: File, readonly?: boolean) {
    const formData = new FormData();
    formData.append('document', file);

    
    const url = withBaseUrl(readonly ? '/invoices/aggregate/invoices/ocr-read' : '/invoices/aggregate/invoices/ocr');
    return restClient.UPLOAD<UploadPDFInvoicesResponse>(`${url}?contractId=${contractId}`, formData);
  }
}
