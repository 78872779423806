import { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Document, pdfjs } from 'react-pdf';

import Download from '../../assets/icons/download.svg';
import { Button } from '../buttonV2';
import { Loader } from '../loader';

import {
  ControlsGroup,
  DocumentWrapper,
  EmptyPage,
  EmptyPageText,
  ImageContainer,
  PreviewUnavailableCard,
  PreviewUnavailableContainer,
  PreviewUnavailableText,
  StyleProps
} from './filePreview.styles';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('/pdf.worker.js', import.meta.url).toString();

export type LoadInfo = {
  hasZoomControlsVisible: boolean;
  hasPageControlsVisible: boolean;
  hasDownloadControlsVisible: boolean;
};

type Props = {
  filename: string;
  fileString: string;
  currentScale: number;
  currentPage: number;
  emptyPage?: ReactNode;
  parentContainer?: HTMLElement;
  previewNotSupported?: boolean;
  hideDownloadControls?: boolean;
  setNumPages: (value: number) => unknown;
  onError?: (value: boolean) => unknown;
  onDownload?: () => unknown;
  onLoad?: (info: LoadInfo) => unknown;
  emptyPageRef?: RefObject<HTMLDivElement>;
} & StyleProps;

const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];

const supportedPreviews = ['pdf', ...imageExtensions];

export const isFilePreviewSupported = (filename: string) => {
  const fileExtension = filename.split('.').pop()?.toLowerCase();

  return supportedPreviews.includes(fileExtension || '');
};

export const isImage = (filename: string) => {
  const fileExtension = filename.split('.').pop()?.toLowerCase();

  return imageExtensions.includes(fileExtension || '');
};

const inlinePaddings = {
  responsive: () => 40
};

export const FilePreview: FC<Props> = ({
  filename,
  fileString,
  currentScale,
  currentPage,
  lightMode,
  onError,
  setNumPages,
  onDownload,
  previewNotSupported,
  hideDownloadControls,
  parentContainer,
  emptyPageRef,
  emptyPage,
  onLoad
}) => {
  const { t } = useTranslation();

  const container = parentContainer || document.body;

  const [containerWidth, setContainerWidth] = useState(container.clientWidth - inlinePaddings.responsive());

  const displayPreview = !previewNotSupported && isFilePreviewSupported(filename);
  const displayEmptyState = !fileString && !previewNotSupported;

  const isImagePreview = isImage(filename);

  const resizeObserverRef = useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;

      if (width < 1024) {
        setContainerWidth(width - inlinePaddings.responsive());

        return;
      }

      setContainerWidth(1024);
    })
  );

  useEffect(() => {
    onLoad?.({
      hasZoomControlsVisible: displayPreview,
      hasPageControlsVisible: displayPreview && !isImagePreview,
      hasDownloadControlsVisible: isFilePreviewSupported(filename)
    });
  }, [displayPreview, isImagePreview, filename]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    resizeObserverRef.current.disconnect();

    resizeObserverRef.current.observe(container);

    return () => {
      resizeObserverRef.current.disconnect();
    };
  }, [container]);

  if (displayEmptyState) {
    return emptyPage ? (
      <>{emptyPage}</>
    ) : (
      <EmptyPage ref={emptyPageRef}>
        <EmptyPageText>{t('uploadFileToSeePreview')}</EmptyPageText>
      </EmptyPage>
    );
  }

  return (
    <DocumentWrapper scale={currentScale} lightMode={lightMode}>
      {displayPreview ? (
        isImagePreview ? (
          <ImageContainer onClick={(e) => e?.stopPropagation?.()}>
            <img draggable={false} src={fileString} alt={filename} />
          </ImageContainer>
        ) : (
          <Document
            data-testid="document"
            onLoadError={() => onError?.(false)}
            file={fileString}
            renderMode="canvas"
            loading={<Loader />}
            onLoadSuccess={async ({ numPages }) => {
              setNumPages(numPages);
            }}
          >
            <Page
              onClick={(e) => e?.stopPropagation?.()}
              width={containerWidth}
              scale={currentScale}
              pageNumber={currentPage}
            />
          </Document>
        )
      ) : (
        <PreviewUnavailableContainer>
          <PreviewUnavailableCard>
            <PreviewUnavailableText>{t('noPreviewAvailable')}</PreviewUnavailableText>
            {hideDownloadControls ? null : (
              <ControlsGroup>
                <Button
                  disabled={!onDownload}
                  icons={{ left: Download }}
                  onClick={onDownload}
                  iconOnly={false}
                >
                  {t('download')}
                </Button>
              </ControlsGroup>
            )}
          </PreviewUnavailableCard>
        </PreviewUnavailableContainer>
      )}
    </DocumentWrapper>
  );
};
