import { selectActiveCustomer, selectIsUserGhost } from 'features/auth/selectors';
import { useAuthSelector } from 'hooks/redux/hooks';

import { useGetFactoringContractsQuery } from '../api';

export const useFetchFactoringContracts = ({ skip }: { skip?: boolean } = {}) => {
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isUserGhost = useAuthSelector<typeof selectIsUserGhost>(selectIsUserGhost);

  const partyId = activeCustomer?.customerCrmId || '';

  return useGetFactoringContractsQuery(
    {
      partyId,
      status: ['ACTIVE', 'EXPIRED', 'MANUALLY_SUSPENDED']
    },
    { skip: skip || !partyId || isUserGhost }
  );
};
