import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import moneyBag from 'assets/icons/moneyBag.svg';
import { Button } from 'components/buttonV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { PERMISSIONS } from 'features/auth/consts/permissions';
import { selectActiveCustomer } from 'features/auth/selectors';
import { useAuthSelector } from 'hooks/redux/hooks';

import { IncreaseFinanceLimitModal } from '../increaseFinancingLimitModal/increaseFinancingLimitModal';

export const IncreaseFinancingLimitButton = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const hasManageContractPermissions = activeCustomer?.roles?.includes(PERMISSIONS.manageContract);

  const handleRequestDisbursementClick = () => {
    setIsOpen(true);
  };

  const { isFeatureEnabled } = useRemoteConfigContext();

  if (!isFeatureEnabled('factoringIncreaseFinancingContractRequest') || !hasManageContractPermissions) {
    return null;
  }

  return (
    <>
      <Button
        icons={{ left: moneyBag }}
        size="S"
        variant="StrokedWhite"
        color="Blue"
        onClick={handleRequestDisbursementClick}
      >
        {t('increaseLimit')}
      </Button>
      <IncreaseFinanceLimitModal isOpen={isOpen} onVisibilityChange={setIsOpen} />
    </>
  );
};
