import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useFormContext } from 'features/application/context/FormContext';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { LoansAdditionalInfo } from 'services/CapService/types';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { FormContainer } from '../../components/formContainer';
import { selectLoansAdditionalInfo } from '../../selectors';
import { setLoansAdditionalInfo, submitApplicationAsync } from '../../slice';
import utils from '../utils/objectHelpers';

import { FormStyle } from './terms.style';
import { TermsFields } from './termsFields';

const initialTerms: LoansAdditionalInfo = {
  term: undefined
};

export const validationSchema = Yup.object({
  term: Yup.number()
    .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
    .required(VALIDATION_MESSAGE_ID.REQUIRED)
    .min(1, VALIDATION_MESSAGE_ID.MIN_LENGTH)
    .max(36, VALIDATION_MESSAGE_ID.MAX_LENGTH)
});

const TermsForm: FC = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const values = useRef({ ...initialTerms });

  const initialValues = utils.copyReadOnlyObjectValues(
    values.current,
    useApplicationSelector(selectLoansAdditionalInfo)
  );

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers(
    initialValues,
    validationSchema,
    'onBlur'
  );

  const { setFormHandlers } = useFormContext<LoansAdditionalInfo>();

  const submitHandler = handleSubmit(
    (data: LoansAdditionalInfo) => {
      dispatch(setLoansAdditionalInfo(data));
      dispatch(submitApplicationAsync());
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(
    () =>
      setFormHandlers({
        submitHandler
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFormHandlers]
  );

  return (
    <FormContainer isValid={validationHelpers.isValid} onNextClick={submitHandler}>
      <FormStyle locale={i18n.language}>
        <TermsFields validationHelpers={validationHelpers} getValues={getValues} />
      </FormStyle>
    </FormContainer>
  );
};

export default TermsForm;
