import styled from 'styled-components';

import { InfoPopup, SecondaryText } from 'components';

export const BuyersTableContainer = styled.div`
  div[data-column-id='insuredLimit'],
  div[data-column-id='dueDays'],
  div[data-column-id='used'],
  div[data-column-id='available'],
  div[data-column-id='interest'],
  div[data-column-id='advance'] {
    &.rdt_TableCol {
      padding: 0 0 0 0.625rem;
    }

    display: flex;
    justify-content: flex-end;
  }

  div[data-column-id='insuredFinancedLimit'] {
    &.rdt_TableCol {
      padding: 0 0 0 0.625rem;
      width: 8rem;
      min-width: 8rem;
    }

    display: flex;
    justify-content: flex-end;
  }
`;

export const HeaderInfoPopup = styled(InfoPopup)`
  display: inline-flex;
  margin-left: 0.625rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  ${SecondaryText} {
    display: inline-flex;
  }
`;
