import { FC } from 'react';
import { PaginationComponentProps } from 'react-data-table-component';

import Icons from '../../assets/icons';

import { PaginationContainer, PageButton } from './table.styles';

const MAX_BUTTONS_TO_DISPLAY = 4;

export const Paginator: FC<PaginationComponentProps> = ({
  rowCount,
  rowsPerPage,
  currentPage,
  onChangePage
}) => {
  const displayNextToCurrent = MAX_BUTTONS_TO_DISPLAY;
  const buttonCount = Math.ceil(rowCount / rowsPerPage);
  const buttons = [...Array.from({ length: buttonCount }).map((_u, i) => i + 1)];

  const nextAvailable = (currentPage + 1) * rowsPerPage <= rowCount;
  const previousAvailable = (currentPage - 1) * rowsPerPage > 0;

  return (
    <PaginationContainer>
      <PageButton disabled={!previousAvailable} onClick={() => onChangePage(currentPage - 1, rowCount)}>
        <Icons.ChevronLeft />
      </PageButton>
      {buttons.map((pageNumber, index) => {
        const minThreshold = currentPage - displayNextToCurrent;
        const maxDisplayThreshold =
          minThreshold <= 0
            ? currentPage + (displayNextToCurrent - (minThreshold || -1))
            : currentPage + displayNextToCurrent + 1;

        if (index > minThreshold && index < maxDisplayThreshold) {
          return (
            <PageButton
              key={pageNumber}
              onClick={() => onChangePage(pageNumber, rowCount)}
              active={pageNumber === currentPage}
            >
              {pageNumber}
            </PageButton>
          );
        }

        return null;
      })}
      <PageButton disabled={!nextAvailable} onClick={() => onChangePage(currentPage + 1, rowCount)}>
        <Icons.ChevronRight />
      </PageButton>
    </PaginationContainer>
  );
};
