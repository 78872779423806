import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  PersistConfig
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import appListenerMiddleware from 'features/app/listeners';
import appSlice from 'features/app/slice';
import capApi from 'features/application/api';
import applicationListenerMiddleware from 'features/application/listeners';
import applicationSlice from 'features/application/slice';
import authSlice, { resetAuthState } from 'features/auth/slice';
import { AuthState } from 'features/auth/types/authTypes';
import bankSlice from 'features/bank/slice';
import factoringContractsApi from 'features/contracts/api';
import contractsSlice from 'features/contracts/slice';
import factoringInvoicesApi from 'features/factoringInvoices/slice';
import factoringStatementsApi from 'features/factoringStatements/api';
import factoringStatementsSlice from 'features/factoringStatements/slice';
import insightsApi from 'features/insights/api';
import invoicesApi from 'features/invoices/api';
import invoicesSlice from 'features/invoices/slice';
import invoicesVerificationApi from 'features/invoicesVerification/api';
import invoicesVerificationSlice from 'features/invoicesVerification/slice';
import loansApi from 'features/loans/api';
import loansSlice from 'features/loans/slice';
import onboardingApi from 'features/onboarding/api';
import statementsSlice, { resetStatementsState } from 'features/statements/slice';
import customerUsersApi from 'features/users/slice';

const persistConfig: PersistConfig<AuthState> = {
  key: 'auth',
  storage: storageSession
};

const persistedAuthSlice = persistReducer(persistConfig, authSlice);

const store = configureStore({
  reducer: {
    app: appSlice,
    application: applicationSlice,
    contracts: contractsSlice,
    factoringStatements: factoringStatementsSlice,
    auth: persistedAuthSlice,
    statements: statementsSlice,
    invoices: invoicesSlice,
    loans: loansSlice,
    bank: bankSlice,
    [factoringInvoicesApi.reducerPath]: factoringInvoicesApi.reducer,
    [invoicesVerificationApi.reducerPath]: invoicesVerificationApi.reducer,
    [invoicesVerificationSlice.name]: invoicesVerificationSlice.reducer,
    [customerUsersApi.reducerPath]: customerUsersApi.reducer,
    [factoringContractsApi.reducerPath]: factoringContractsApi.reducer,
    [loansApi.reducerPath]: loansApi.reducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [insightsApi.reducerPath]: insightsApi.reducer,
    [capApi.reducerPath]: capApi.reducer,
    [factoringStatementsApi.reducerPath]: factoringStatementsApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .prepend(appListenerMiddleware.middleware)
      .prepend(applicationListenerMiddleware.middleware)
      .prepend(factoringInvoicesApi.middleware)
      .prepend(invoicesVerificationApi.middleware)
      .prepend(customerUsersApi.middleware)
      .prepend(factoringContractsApi.middleware)
      .prepend(loansApi.middleware)
      .prepend(invoicesApi.middleware)
      .prepend(insightsApi.middleware)
      .prepend(capApi.middleware)
      .prepend(factoringStatementsApi.middleware)
      .prepend(onboardingApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);

export const resetAuthSlice = () => {
  store.dispatch(resetAuthState());
};

export const resetStatementsSlice = () => {
  store.dispatch(resetStatementsState());
};

export const resetPresistedSlices = async () => {
  await persistor.purge();
  store.dispatch(resetAuthState());
  store.dispatch(resetStatementsState());
  await persistor.flush();
};

export type Store = typeof store;

export default store;
