import { FC, ReactNode, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { AmountText, DownloadLink, RegularText, SecondaryText, Section, StatusChip, Table } from 'components';
import { StatusType } from 'design/designVariables';
import { getBillTypeTranslation } from 'features/loans/consts/billTypeTranslations';
import { BillStatus, LoanInvoiceItem } from 'services/LoansService/types';
import { camelCase } from 'utils';

import { UpcomingPaymentsHeader } from './upcomingPaymentsTable.styles';

type TableRowItem = LoanInvoiceItem & { toggleSelected?: never };

export type StatusToChipColorMap = Record<BillStatus, keyof StatusType>;

const defaultStatusToChipColorMap: StatusToChipColorMap = {
  PAID: 'green',
  UNPAID: 'yellow',
  OVERDUE: 'red',
  PARTIALLY_PAID: 'blue',
  UNKNOWN: 'gray'
};

export type Sort = { dataColumnId?: string | number; sortOrder: SortOrder };

type Props = {
  items: TableRowItem[];
  loading?: boolean;
  noItems: boolean;
  contractNumber?: string;
  onSort?: (sortOder: Sort) => unknown;
  footerNavigation?: ReactNode;
  statusToChipColorMap?: StatusToChipColorMap;
  scheduleDownload?: () => Promise<unknown>;
};

export const withNullCheck = (value?: string | number | boolean) => value || '-';

const UpcomingPaymentsTable: FC<Props> = ({
  items,
  noItems,
  loading,
  contractNumber,
  onSort,
  footerNavigation,
  scheduleDownload,
  statusToChipColorMap = defaultStatusToChipColorMap
}) => {
  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<{ dataColumnId?: string | number; sortOrder: SortOrder }>();

  const sortable = Boolean(onSort);

  const columns: TableColumn<TableRowItem>[] = [
    {
      selector: (row) => withNullCheck(row.billNumber),
      name: t('number'),
      sortable,
      id: 'number',
      sortField: 'number',
      format: (row) => <RegularText>{row.billNumber}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.billType),
      name: t('type'),
      sortable,
      id: 'type',
      sortField: 'type',
      format: (row) => <RegularText>{t(getBillTypeTranslation(row.billType))}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.dueDate),
      name: t('dueDate'),
      sortable,
      id: 'dueDate',
      sortField: 'dueDate',
      format: (row) => <RegularText>{row.dueDate}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.unpaidAmount),
      name: t('unpaid'),
      id: 'unpaid',
      format: (row) => <AmountText>{row.unpaidAmount}</AmountText>
    },
    {
      selector: (row) => withNullCheck(row.paymentStatus),
      name: t('status'),
      sortable,
      id: 'status',
      sortField: 'status',
      format: (row) => {
        if (row.paymentStatus) {
          return (
            <StatusChip color={statusToChipColorMap[row.paymentStatus]}>
              <RegularText>{t(camelCase(row.paymentStatus || '-'))}</RegularText>
            </StatusChip>
          );
        }

        return null;
      }
    }
  ];

  const handleSort = (column: TableColumn<TableRowItem>, sortDirection: SortOrder) => {
    setSortOrder({ dataColumnId: column.id, sortOrder: sortDirection });
    onSort?.({ dataColumnId: column.id, sortOrder: sortDirection });
  };

  return (
    <Section
      footerButton={items && items.length ? footerNavigation : null}
      header={
        <UpcomingPaymentsHeader>
          <div>
            {t('upcomingPayments')} <SecondaryText>({contractNumber})</SecondaryText>
          </div>
          {scheduleDownload && noItems ? (
            <DownloadLink fileRequest={scheduleDownload}>
              <Icons.Download />
              {t('downloadSchedule')}
            </DownloadLink>
          ) : null}
        </UpcomingPaymentsHeader>
      }
    >
      <Table<TableRowItem>
        sortedBy={sortOrder}
        noDataMessage={t(noItems ? 'noDuePayments' : 'noListItems')}
        onSort={handleSort}
        progressPending={loading}
        columns={columns}
        data={items}
      />
    </Section>
  );
};

export { UpcomingPaymentsTable };
