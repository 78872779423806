type ApplicationDetails = unknown;

const postNavigationFromCapEvent = () => {
  window.parent.postMessage('navigation_from_cap_flow_DASHBOARD', '*');
};

const postCreationFromCapEvent = (payload?: ApplicationDetails) => {
  window.parent.postMessage({ message: 'creation_from_cap_flow_DASHBOARD_PAYLOAD', payload }, '*');
};

export default {
  postNavigationFromCapEvent,
  postCreationFromCapEvent
};
