import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Step, Stepper } from 'components';
import { clearToastMessages } from 'features/app/slice';
import FormContext from 'features/application/context/FormContext';
import { selectActiveStep, selectCompletionIndex, selectStepsSequence } from 'features/application/selectors';
import { setActiveStep, setNavigateTo } from 'features/application/slice';
import { STEPS } from 'features/application/types/applicationTypes';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';

const useStepperSteps = (steps: STEPS[], completionIndex: number, previousActiveStep?: STEPS): Step[] => {
  const { t } = useTranslation();

  const checkStepStatus = (step: STEPS, index: number) => {
    const activeIndex = steps.indexOf(step);
    return activeIndex <= index;
  };

  const dispatch = useAppDispatch();
  const { formHandlers } = useContext(FormContext);

  return steps.map((step) => {
    const previousStepIsCompleted = previousActiveStep
      ? steps.findIndex((step) => step === previousActiveStep) <= completionIndex
      : true;

    const onClickHandler = async () => {
      dispatch(clearToastMessages());

      const { submitHandler } = formHandlers || {};

      dispatch(setNavigateTo(step));

      if (previousStepIsCompleted && submitHandler) {
        await submitHandler();
      } else {
        dispatch(setActiveStep(step));
      }
    };

    return {
      label: t(step),
      isActive: previousActiveStep === step,
      isCompleted: checkStepStatus(step, completionIndex),
      onClick: checkStepStatus(step, completionIndex + 1) ? onClickHandler : undefined
    };
  });
};

type Props = {
  hiddenSteps?: STEPS[];
};

export const CapStepper: FC<Props> = ({ hiddenSteps }) => {
  const completionIndex = useApplicationSelector<typeof selectCompletionIndex>(selectCompletionIndex);
  const activeSequence = useApplicationSelector<typeof selectStepsSequence>(selectStepsSequence);
  const activeStep = useApplicationSelector<typeof selectActiveStep>(selectActiveStep);

  const steps = useStepperSteps(
    activeSequence.filter((step) => !hiddenSteps?.includes(step)),
    completionIndex,
    activeStep
  );

  return <Stepper steps={steps} />;
};
