import styled from 'styled-components';

type ImageProps = {
  src: string;
};

type TexProps = {
  primary?: boolean;
};

export const Image = styled.img<ImageProps>`
  width: 100%;
  height: auto;
  max-width: 9.5rem;
  max-height: 7rem;
  object-fit: contain;

  @media ${({ theme }) => theme.media.maxTablet} {
    max-width: 7.6rem;
    max-height: 5.5rem;
  }
`;

export const TextWrapper = styled.div`
  margin: 1rem;
`;

export const Text = styled.p<TexProps>`
  color: ${({ primary, theme }) =>
    primary ? theme.colors.primary.linkText : theme.colors.primary.textColor};
  font-size: 1.5rem;
  margin: 0.5rem;
`;
