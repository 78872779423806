import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CategoricalChartState } from 'recharts/types/chart/types';

import { Section } from 'components';
import { Button } from 'components/buttonV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { selectActiveCustomer } from 'features/auth/selectors';
import { selectContractCreditListHistory } from 'features/contracts/selectors';
import { fetchCreditLimitHistoryAsync } from 'features/contracts/slice';
import { selectActsList, selectIsActsListLoading } from 'features/factoringStatements/selectors';
import { fetchActsAsync } from 'features/factoringStatements/slice';
import {
  useFactorignStatementsSelector,
  useAppDispatch,
  useAuthSelector,
  useContractSelector
} from 'hooks/redux/hooks';
import { Periodicity } from 'services/ContractsService/types';

import { ActsTable } from '../../../factoringInvoices/tables/actsTable/actsTable';
import { FilteredDataItem, FilterPanel } from '../filterPanel/filterPanel';
import { WaveChart } from '../waveChart/waveChart';

const getFirstDayOfMonth = (date: string) => {
  try {
    const dateObj = new Date(date);

    return new Date(dateObj.getFullYear(), dateObj.getMonth(), 2).toISOString().slice(0, 10);
  } catch {
    return date;
  }
};

const getLastDayOfMonth = (date: string) => {
  try {
    const dateObj = new Date(date);

    return new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0).toISOString().slice(0, 10);
  } catch {
    return date;
  }
};

const getDateFromTo = (date: string, periodicity: Periodicity) => {
  switch (periodicity) {
    case 'MONTH':
      return {
        dateFrom: getFirstDayOfMonth(date),
        dateTo: getLastDayOfMonth(date)
      };
    default:
      return {
        dateFrom: date,
        dateTo: date
      };
  }
};

export const LimitUsage = () => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const contractHistory = useContractSelector<typeof selectContractCreditListHistory>(
    selectContractCreditListHistory
  );
  const actsItems = useFactorignStatementsSelector<typeof selectActsList>(selectActsList);
  const actsListLoading =
    useFactorignStatementsSelector<typeof selectIsActsListLoading>(selectIsActsListLoading);

  const partyId = activeCustomer?.customerCrmId || '';

  const sortedActsItems = actsItems
    ? [...actsItems]?.sort((a, b) => {
        if (a.date && b.date) {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        }

        return 0;
      })
    : [];

  const [selectedDate, setSelectedDate] = useState(
    contractHistory?.history?.[contractHistory?.history?.length - 1]?.date || ''
  );
  const [selectedPeriodicity, setSelectedPeriodicity] = useState<Periodicity>('MONTH');
  const [filteredGraphData, setFilteredGraphData] = useState<FilteredDataItem[]>([]);
  const [selectedDataType, setSelectedDataType] = useState<string>('');

  const handleDateSelection = (state: CategoricalChartState) => {
    if (state.activeLabel) {
      setSelectedDate(state.activeLabel);
    }
  };

  useEffect(() => {
    setSelectedDate(contractHistory?.history?.[contractHistory?.history?.length - 1]?.date || '');
  }, [contractHistory.history]);

  useEffect(() => {
    if (contractId && selectedPeriodicity) {
      dispatch(
        fetchCreditLimitHistoryAsync({
          contractId,
          periodicity: selectedPeriodicity
        })
      );
    }
  }, [contractId, selectedPeriodicity, dispatch]);

  useEffect(() => {
    if (isFeatureEnabled('factoringStatements') && selectedDate && contractId && partyId) {
      dispatch(
        fetchActsAsync({
          contractId,
          partyId,
          ...getDateFromTo(selectedDate, selectedPeriodicity),
          page: '1',
          size: '5'
        })
      );
    }
  }, [selectedDate, contractId, partyId, dispatch]);

  const handleNavigation = () => {
    navigate('/finance/factoring/statements');
  };

  return (
    <>
      <Section header={t('limitUsage')}>
        <FilterPanel
          data={contractHistory.history}
          onPeriodicityChange={setSelectedPeriodicity}
          onSelectedPointChange={setFilteredGraphData}
          onDataTypeChange={setSelectedDataType}
        />
        <WaveChart
          dataLabel={selectedDataType}
          onPointClick={handleDateSelection}
          data={filteredGraphData}
          currency={contractHistory.currency ? contractHistory.currency : ''}
          periodicity={contractHistory.periodicity || 'MONTH'}
        />
      </Section>
      {isFeatureEnabled('factoringStatements') ? (
        <Section
          header={t('latestStatements')}
          footerButton={
            actsItems && actsItems.length ? (
              <Button variant="Filled" color="Blue" onClick={handleNavigation}>
                {t('allStatements')}
              </Button>
            ) : null
          }
        >
          <ActsTable items={sortedActsItems} loading={actsListLoading} />
        </Section>
      ) : null}
    </>
  );
};
