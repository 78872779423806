import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Column, Grid, Logo } from 'components';
import { selectIsUserAuthenticated, selectIsUserGhost } from 'features/auth/selectors';
import NavContext from 'features/nav/context/navContext';
import { useAuthSelector } from 'hooks/redux/hooks';

import { LanguagesMenuDropdown } from '../languagesMenu';

import {
  HeaderStyle,
  HeaderWrapper,
  LeftAlignedContainer,
  MenuBurgerContainer,
  NavContent,
  RightAlignedContainer,
  RightSection,
  SpacedBetweenContainer
} from './topNav.styles';

export const TopNavigation: React.FC = () => {
  const isUserAuthenticated = useAuthSelector<typeof selectIsUserAuthenticated>(selectIsUserAuthenticated);
  const isGhostUser = useAuthSelector(selectIsUserGhost);

  const { setShowSideNavigation } = useContext(NavContext);

  const AlignementContainer = isUserAuthenticated ? SpacedBetweenContainer : RightAlignedContainer;

  return (
    <HeaderWrapper>
      <HeaderStyle>
        <Grid>
          <Column span={2} colStart={1}>
            <LeftAlignedContainer>
              <MenuBurgerContainer>
                <Link
                  to={isGhostUser ? '/dashboard/applications' : '/dashboard/overview'}
                  onClick={() => setShowSideNavigation(true)}
                >
                  <Logo />
                </Link>
              </MenuBurgerContainer>
            </LeftAlignedContainer>
          </Column>
          <Column
            span={10}
            spanLg={9}
            spanMd={10}
            spanSm={10}
            colStart={3}
            colStartLg={4}
            colStartMd={3}
            colStartSm={3}
          >
            <NavContent>
              <AlignementContainer>
                <RightSection>
                  <LanguagesMenuDropdown />
                </RightSection>
              </AlignementContainer>
            </NavContent>
          </Column>
        </Grid>
      </HeaderStyle>
    </HeaderWrapper>
  );
};
