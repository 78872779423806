import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  LimitDetail,
  LimitDetailsSection
} from 'features/contracts/components/limitDetailsSection/limitDetailsSection';
import { useFetchSelectedContract } from 'features/contracts/hooks';
import { selectInvoicesTotalsByContract } from 'features/invoices/selectors';
import { fetchInvoicesTotalsAsync } from 'features/invoices/slice';
import { useAppDispatch, useInvoicesSelector } from 'hooks/redux/hooks';

export const FactoringLimitsDetails: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');
  const { selectedContract, refetch } = useFetchSelectedContract();

  useEffect(() => {
    if (contractId) {
      dispatch(fetchInvoicesTotalsAsync({ contractId }));
      refetch();
    }
  }, [contractId, dispatch, refetch]);

  const totals = useInvoicesSelector<typeof selectInvoicesTotalsByContract>(selectInvoicesTotalsByContract);

  if (!selectedContract) return null;

  const { factoringCreditLimit, usedFactoringCreditLineAmount, availableFactoringCreditLineAmount } =
    selectedContract;

  const data: LimitDetail[] = [
    {
      label: 'total',
      info: 'infoTextForContractsTotal',
      value: factoringCreditLimit
    },
    {
      label: 'available',
      info: 'infoTextForContractsAvailable',
      value: availableFactoringCreditLineAmount
    },
    {
      label: 'used',
      info: 'infoTextForContractsUsed',
      value: usedFactoringCreditLineAmount
    },
    {
      label: 'overdue',
      info: 'loansOverdueAmountExplanation',
      value: totals.overdueInvoicesAmount
    }
  ];

  return <LimitDetailsSection data={data} />;
};
