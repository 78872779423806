import { ISO_CODES } from 'components';

type Aliases = (typeof ISO_CODES)[number] extends { readonly aliases: ReadonlyArray<infer U> } ? U : never;

export const checkForValidISOCode = (countryIsoCode: string) => {
  const validISOCode = ISO_CODES.find((isoCode) => {
    const checkForAliases =
      'aliases' in isoCode ? isoCode.aliases?.includes(countryIsoCode as Aliases) : false;

    return isoCode.value === countryIsoCode || checkForAliases;
  });

  if (validISOCode) {
    return countryIsoCode;
  }

  return '';
};
