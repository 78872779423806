import styled from 'styled-components';

import { RegularText } from '../text';

export const SectionContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.gap2x};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 1.5rem;
  }
`;

export const ArticleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.gap};
  font-size: 1.25rem;
  width: 100%;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 0.5rem;
  }

  ${RegularText} {
    line-height: 1.5rem;
  }
`;

export const ContentContaner = styled.div`
  display: 'flex';
  position: relative;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacings.gap};

  & > * {
    margin-right: 0.625rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const SectionLayout = styled.section<{ isHidden?: boolean }>`
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  margin-bottom: ${({ theme }) => theme.spacings.gap};
  font-size: 0.875rem;
`;
