import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Icons from 'assets/icons';
import HuggingFace from 'assets/icons/huggingFace.svg';
import { Link, RegularText, SecondaryText } from 'components';

import { useCheckIfClickedOutside } from '../../../nav/hooks/useCheckIfClickedOutside';

import {
  GreetingContainer,
  HeloPopupInfoContainer,
  HelpPopupButton,
  HelpPopupContainer,
  IconContainer,
  InfoContainer,
  InfoContainerItem
} from './helpPopup.styles';

const blacklistedPathNames = ['/finance/invoices/upload', '/new-application'];

const HelpPopup: FC = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [hide, setIsHidden] = useState(false);

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  useCheckIfClickedOutside(setIsOpen, isOpen, [ref]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const isHidden = blacklistedPathNames.some((item) => location.pathname.includes(item));
    setIsHidden(isHidden);
  }, [location.pathname]);

  if (hide) {
    return null;
  }

  return (
    <HelpPopupContainer ref={ref} onClick={toggleDropdown}>
      <HelpPopupButton active={isOpen} role="button" data-testid={`help-popup`}>
        {isOpen ? <Icons.Close /> : <Icons.Chat />}
      </HelpPopupButton>
      {isOpen && (
        <HeloPopupInfoContainer>
          <GreetingContainer>
            <RegularText>{t('greeting')}</RegularText>
            <SecondaryText>
              {t('needHelp')} <img src={HuggingFace} />
            </SecondaryText>
          </GreetingContainer>
          <InfoContainer>
            <InfoContainerItem>
              <IconContainer>
                <Icons.Mail />
              </IconContainer>
              <Link href="mailto:info@smefinance.lt">info@smefinance.lt</Link>
            </InfoContainerItem>
            <InfoContainerItem>
              <IconContainer>
                <Icons.Phone />
              </IconContainer>
              <RegularText>+370 601 77777</RegularText>
            </InfoContainerItem>
          </InfoContainer>
        </HeloPopupInfoContainer>
      )}
    </HelpPopupContainer>
  );
};

export default HelpPopup;
