import { FC } from 'react';

import { ShimerContainer } from 'components';
import { TotalsCardContainer } from 'components/productTotalsCard';
import { TooltipLine, ProductTotalsCard } from 'components/productTotalsCard/productTotalsCard';

import { useFetchContractsTotals } from '../../hooks';

type Props = {
  loading: boolean;
};

export const FactoringContractsTotalsCard: FC<Props> = ({ loading }) => {
  const {
    data: totals,
    isFetching: totalsLoading,
    isError: isTotalsError,
    hasNoData
  } = useFetchContractsTotals();

  const isLoading = totalsLoading || loading;

  const { creditLimit = 0, overdueAmount = 0, usedAmount = 0, availableAmount = 0 } = totals || {};

  const mappedData = {
    totalValue: creditLimit,
    value: usedAmount,
    dangerValue: overdueAmount,
    navigateTo: '/finance/factoring/overview',
    tooltip: [
      { value: availableAmount, label: 'available' },
      overdueAmount ? { value: overdueAmount, label: 'overdue', type: 'danger' } : null
    ].filter((el) => el) as TooltipLine[]
  };

  const setUsedValueWidth = (used: number) =>
    overdueAmount >= used ? 0 : ((used - overdueAmount) * 100) / creditLimit;

  if ((isTotalsError && !isLoading) || (hasNoData && !isLoading)) {
    return null;
  }

  return (
    <TotalsCardContainer>
      {isLoading ? <ShimerContainer overlay /> : null}
      <ProductTotalsCard
        setUsedLineWidth={setUsedValueWidth}
        {...mappedData}
        productName="factoring"
        valueLineLabel="used"
      />
    </TotalsCardContainer>
  );
};
