import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useLoansSelector } from 'hooks/redux/hooks';

import {
  selectIsLoansPaymentsHasNoData,
  selectIsLoansPaymentsListLoadFailed,
  selectIsLoansPaymentsListLoading,
  selectLoansContractList,
  selectLoansPaymentsList
} from '../selectors';
import { getPaymentsAsync } from '../slice';

export type PaginationArgs = {
  pageNo: number;
  pageSize: number;
};

export const useFetchLoanInvoices = () => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const dispatch = useAppDispatch();

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);

  const { contractNo, financierId, customerId } = selectedContract || {};

  const getInvoicesAsyncMemoized = useCallback(() => {
    if (contractNo && financierId && customerId) {
      dispatch(
        getPaymentsAsync({
          contractNo,
          financierId: financierId,
          customerId
        })
      );
    }
  }, [contractNo, customerId, dispatch, financierId]);

  useEffect(() => {
    getInvoicesAsyncMemoized();
  }, [getInvoicesAsyncMemoized]);

  const invoicesResponse = useLoansSelector<typeof selectLoansPaymentsList>(selectLoansPaymentsList);

  const isLoading = useLoansSelector<typeof selectIsLoansPaymentsListLoading>(
    selectIsLoansPaymentsListLoading
  );
  const isError = useLoansSelector<typeof selectIsLoansPaymentsListLoadFailed>(
    selectIsLoansPaymentsListLoadFailed
  );
  const hasNoData = useLoansSelector<typeof selectIsLoansPaymentsHasNoData>(selectIsLoansPaymentsHasNoData);

  return {
    data: invoicesResponse,
    isLoading,
    isError,
    hasNoData,
    isUnintialized: false, //TODO needs slice refactor to support isUnintialized check
    refetch: getInvoicesAsyncMemoized
  };
};
