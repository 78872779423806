import { queryBuilder } from 'services/utils/queryBuilder';

import { RestClient } from '../RestClient';
import { baseUrlBuilder } from '../utils/baseUrlBuilder';

import {
  LoansOverviewGetRequestPayload,
  LoansOverviewGetReponse,
  LoansPaymentsGetReponse,
  LoansPaymentsGetRequestPayload,
  LoansInvoicesDownloadRequestPayload,
  LoansScheduleDownloadRequestPayload,
  LoansContractDocumentRequestPayload
} from './types';

const restClient = new RestClient();

const withBaseUrl = baseUrlBuilder('loans');

export class LoansService {
  static getOverview(payload: LoansOverviewGetRequestPayload) {
    const url = withBaseUrl('/loans/overview');
    return restClient.GET<LoansOverviewGetReponse>(queryBuilder(url, payload));
  }

  static getPayment(payload: LoansPaymentsGetRequestPayload) {
    const url = withBaseUrl('/loans/invoices');
    return restClient.GET<LoansPaymentsGetReponse>(queryBuilder(url, payload));
  }

  static downloadInvoice(payload: LoansInvoicesDownloadRequestPayload) {
    const url = withBaseUrl('/reports/invoice');
    return restClient.DOWNLOAD(queryBuilder(url, payload), { responseType: 'blob' });
  }

  static downloadSchedule(payload: LoansScheduleDownloadRequestPayload) {
    const url = withBaseUrl('/reports/schedule');
    return restClient.DOWNLOAD(queryBuilder(url, payload), { responseType: 'blob' });
  }

  static downloadContractDocumentByEntryNo(payload: LoansContractDocumentRequestPayload) {
    const url = withBaseUrl('/loans/document');
    return restClient.DOWNLOAD(queryBuilder(url, payload), { responseType: 'blob' });
  }
}
