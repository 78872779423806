import styled from 'styled-components';

import { SecondaryText } from 'components';
import { MobileList } from 'components/table/table.styles';

export const InvoicesSummaryTableContainer = styled.div`
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  gap: 0.5rem;

  ${MobileList} {
    max-height: 33.8125rem;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    flex-direction: column;
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:last-of-type {
    margin-right: 0;
  }

  ${SecondaryText} {
    padding-bottom: 0.4rem;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const AmountWithInfoPopup = styled.div`
  display: flex;

  div {
    padding: 0;

    svg {
      margin-left: 0.375rem;
    }
  }
`;

export const InfoTextContainer = styled.div`
  width: 18.75rem;
  display: flex;
  flex-direction: column;

  > :first-child {
    margin-bottom: 0.8125rem;
  }
`;

export const InfoTextLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 0.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-top: 0.5rem;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    margin-top: 0;
  }
`;
