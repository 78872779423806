import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { Toast } from 'components';
import { getProductsAvailabilityByCountry } from 'defaultConfiguration/getProductsAvailabilityByCountry';
import { ProductsInfo } from 'defaultConfiguration/productsAvailabilityDefaultConfig';
import { useFormContext } from 'features/application/context/FormContext';
import { DrawerImplementation } from 'features/application/drawers';
import {
  selectCompanyCountry,
  selectIsCompanyStepsEnabled,
  selectIsFarm,
  selectProductType
} from 'features/application/selectors';
import {
  setApplicationProduct,
  setApplicationType,
  setCompletionIndex,
  setStepsSequence
} from 'features/application/slice';
import { FINANCIAL_PRODUCT, STEPS } from 'features/application/types/applicationTypes';
import { useNavContext } from 'features/nav/context/navContext';
import { useAppDispatch, useAppSelector, useApplicationSelector } from 'hooks/redux/hooks';
import { Type } from 'services/CapService/types';
import { getCountryDisplayName } from 'translations/countries';
import { addCaseToCountryName } from 'translations/countries/getCountryDisplayName';
import { getLanguageCode } from 'translations/utils';

import FormContainer from '../../components/formContainer/formContainer';
import { ProductCard, type CardProps } from '../../components/productCard';
import { CountryOfCompanyRegistration } from '../company/constants';
import { getStepsByProductType } from '../sequences';

import { Column, DrawerLink } from './selectSubproduct.styles';

export const cards: CardProps[] = [
  {
    id: FINANCIAL_PRODUCT.FACTORING,
    type: 'FACTORING',
    label: 'factoring',
    iconToDisplay: <Icons.InvoiceFinance />
  },
  {
    id: FINANCIAL_PRODUCT.BUSINESS_LOANS,
    type: 'LOAN',
    label: 'loan',
    iconToDisplay: <Icons.BusinessLoan />
  },
  {
    id: FINANCIAL_PRODUCT.BUSINESS_LOANS,
    type: 'CREDIT_LINE',
    label: 'creditline',
    iconToDisplay: <Icons.FlexiFinance />
  },
  {
    id: FINANCIAL_PRODUCT.LEASING,
    type: 'LEASING',
    label: 'leasing',
    iconToDisplay: <Icons.Car />
  },
  {
    id: FINANCIAL_PRODUCT.RBF,
    type: 'RBF',
    label: 'rbf',
    iconToDisplay: <Icons.RBF />
  }
];

const filterProductsByCountry = (productsAvailability: ProductsInfo, country: string) => {
  const products = productsAvailability[country as CountryOfCompanyRegistration];

  return cards.filter((card) => products.includes(card.type));
};

type Props = {
  onNextClick: (sequence?: STEPS[]) => void;
};

const SelectProduct = ({ onNextClick }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { setFormHandlers } = useFormContext();

  const activeProductType = useApplicationSelector<typeof selectProductType>(selectProductType);
  const companyCountry = useApplicationSelector<typeof selectCompanyCountry>(selectCompanyCountry);
  const isCompanyStepsEnabled = useAppSelector(selectIsCompanyStepsEnabled);
  const isFarmer = useAppSelector(selectIsFarm);

  const { toggleRightSideDrawer } =
    useNavContext<DrawerImplementation<'companyRegistrationSelectorWithDispatch'>>();

  const displayName = getCountryDisplayName(companyCountry ?? '', (i18n.language as 'lt' | 'en') ?? 'en');

  const countryName =
    getLanguageCode(i18n.language) === 'lt' ? addCaseToCountryName(displayName) : displayName;

  const handleProductSelect = (selectedProduct: FINANCIAL_PRODUCT, type: Type) => {
    const selectedSequence = getStepsByProductType(type, !isCompanyStepsEnabled, isFarmer);

    if (activeProductType !== type) {
      window.gtag?.('event', 'page_view', {
        cap_product_type: type
      });

      const currentIndex = selectedSequence.findIndex((step) => step === STEPS.PRODUCT_TYPE);
      dispatch(setCompletionIndex(currentIndex));
    }

    dispatch(setApplicationProduct(selectedProduct));
    dispatch(setApplicationType(type));

    dispatch(setStepsSequence(selectedSequence));
    onNextClick(selectedSequence);
  };

  useEffect(() => {
    setFormHandlers({
      submitHandler: undefined
    });
  }, []);

  const onCountrySelectorToggle = () => {
    toggleRightSideDrawer?.({ drawerId: 'companyRegistrationSelectorWithDispatch' });
  };

  const productsAvailability = getProductsAvailabilityByCountry();

  const availableProducts = companyCountry
    ? filterProductsByCountry(productsAvailability, companyCountry)
    : [];

  return (
    <FormContainer hiddenNavigation>
      <Column>
        {availableProducts.map((card) => (
          <ProductCard
            key={card.type}
            type={card.type}
            id={card.id}
            onCardClick={handleProductSelect}
            iconToDisplay={card.iconToDisplay}
            label={t(card.label)}
          />
        ))}
        <Toast
          variant="info"
          messageWithIcon
          isVisible={!!isCompanyStepsEnabled}
          message={
            <Trans
              i18nKey={'theseFinancingOptionsAreAvailableIn'}
              values={{ country: countryName }}
              components={{
                button: <DrawerLink onClick={onCountrySelectorToggle} />,
                span: <span />
              }}
            />
          }
        />
      </Column>
    </FormContainer>
  );
};

export default SelectProduct;
