import styled, { css } from 'styled-components';

import { ScreenSize } from 'components';

export const SublinksContainerWrapper = styled.div`
  height: 3.875rem;
  width: calc(100% - 0.25rem);
`;

export const SublinksContainerOffset = styled.div`
  padding: 1.625rem 1.5rem 1rem 3rem;
  height: 3.875rem;
  margin: 0 -1.5rem;
  background-color: ${({ theme }) => theme.colors.primary.background};
`;

type Props = {
  width: number;
  position: number;
};

const underline = css<Props>`
  position: absolute;
  bottom: 0;
  display: inline-flex;
  height: 0.125rem;
  width: ${({ width }) => `${width}px`};
  content: '';
  background: ${({ theme }) => theme.colors.primary.linkText};
  left: 0;
  transform: translateX(${({ position }) => `${position}px`});
  transition: all 250ms ease-in-out;
`;

export const SublinksContainer = styled.nav<Props>`
  & > ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.base.black.shades[5]};

    & > li {
      margin-right: 2rem;
      white-space: nowrap;
    }

    &::after {
      ${underline}
    }
  }
`;

export const StickyScreenSize = styled(ScreenSize)`
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: ${({ theme }) => theme.colors.primary.background};
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
`;
