import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, retry } from '@reduxjs/toolkit/dist/query/react';

import { selectAddsParams } from 'features/app/selectors';
import {
  ExtendedPaths as Cap2Paths,
  CompaniesSearchResponse,
  CreateCustomerApplicationV2Request,
  CreateCustomerApplicationV2Response,
  CustomerDecisionSubmitRequest,
  CustomerDecisionSubmitResponse,
  PipedrivePostRequest,
  PipedrivePostResponse,
  SubmittedApplication,
  UpdateCustomerApplicationV2Request,
  UpdateCustomerApplicationV2Response
} from 'services/Cap2Service/types';
import { paths } from 'services/CapService/schema';
import {
  AcceptOfferRequest,
  AcceptOfferResponse,
  GetApplicationRequest,
  GetApplicationResponse,
  GetApplicationsResponse,
  GetOfferFileNamesRequest,
  GetOfferFileNamesResponse,
  GetRequiredInfoRequest,
  GetRequiredInfoResponse,
  RejectOfferRequest,
  RejectOfferResponse,
  SubmitApplicationRequest,
  submitRequiredDocumentInfoRequest,
  submitRequiredDocumentInfoResponse,
  uploadRequiredDocumentInfoRequest,
  uploadRequiredDocumentInfoResponse
} from 'services/CapService/types';
import { axiosBaseQuery } from 'services/RestClient';
import { CustomBaseQueryError } from 'services/RestClient/typings';
import { baseUrlBuilder, typedBaseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';
import { RootState } from 'store';

const withCap2BaseUrl = baseUrlBuilder('cap2');
const withTypedCap2BaseUrl = typedBaseUrlBuilder<Cap2Paths>('cap2');
const withCapBaseUrl = typedBaseUrlBuilder<paths>('cap');

const axiosBaseQueryWithRetry = retry(axiosBaseQuery, {
  maxRetries: 3,
  backoff: (attempt) => {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000 * attempt);
    });
  }
});

type ReturnValue<TResponse> = QueryReturnValue<TResponse, CustomBaseQueryError, Record<string, string>>;

const capApi = createApi({
  reducerPath: 'capApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['application', 'applications', 'requestedDocumentsList'],
  endpoints: (build) => ({
    fetchApplication: build.query<GetApplicationResponse, GetApplicationRequest>({
      queryFn(arg, api, extraOptions) {
        const get = async <TResponse>(url: string) =>
          axiosBaseQueryWithRetry({ url, method: 'GET' }, api, extraOptions) as ReturnValue<TResponse>;

        const capCacheUrl = withTypedCap2BaseUrl('/v1/cap2/fetch/{capId}', { capId: arg.capId }, arg ?? {});

        const capGoCreditsUrl = withTypedCap2BaseUrl('/v1/cap2/submitted/{applicationId}', {
          applicationId: arg.applicationId
        });

        return get<GetApplicationResponse>(arg.applicationId ? capGoCreditsUrl : capCacheUrl);
      },
      providesTags: ['application']
    }),
    fetchApplications: build.query<
      GetApplicationsResponse,
      { fullList?: boolean; filterStatus?: string; filterPoducts?: string } | void
    >({
      //@ts-expect-error - TS doesn't like the fact that we're using a custom queryFn
      queryFn: async (_arg, api, extraOptions) => {
        const get = async <TResponse>(url: string) =>
          axiosBaseQueryWithRetry({ url, method: 'GET' }, api, extraOptions) as ReturnValue<TResponse>;

        const result = await get<GetApplicationsResponse>(
          withTypedCap2BaseUrl(
            '/v2/cap2/all',
            {},
            {
              fullList: true,
              filterStatus: ['REJECTED', 'INACTIVE', 'DELETED', 'REFUSED_BY_CLIENT']
            }
          )
        );

        return {
          ...result,
          data: result.data?.map((application) => {
            const resolvedStatus =
              application.status === 'INITIAL' ? 'ASSESSMENT_ONGOING' : application.status;

            return {
              ...application,
              status: resolvedStatus
            };
          })
        };
      },
      providesTags: ['applications']
    }),
    submitPipedriveApplication: build.mutation<PipedrivePostResponse, PipedrivePostRequest>({
      queryFn: (payload, api, _extraOptions, baseQuery) => {
        try {
          const rootState = api.getState() as RootState;

          const addsParams = selectAddsParams(rootState.app);

          const post = (url: string) =>
            baseQuery({ url, method: 'POST', data: payload }) as ReturnValue<PipedrivePostResponse>;

          const url = withTypedCap2BaseUrl(
            '/v1/cap2/rbf/submit',
            {},
            {
              telem: payload.telem,
              partner: payload.partner,
              partnerType: payload.partnerType?.toUpperCase?.(),
              dealId: payload.dealId,
              ...addsParams
            }
          );

          return post(url);
        } catch (error) {
          return { error: error as CustomBaseQueryError };
        }
      },
      invalidatesTags: ['applications']
    }),
    createApplication: build.mutation<
      CreateCustomerApplicationV2Response,
      CreateCustomerApplicationV2Request
    >({
      queryFn: async (payload, api, _extraOptions, baseQuery) => {
        try {
          const rootState = api.getState() as RootState;

          const addsParams = selectAddsParams(rootState.app);

          const post = (url: string) =>
            baseQuery({
              url,
              method: 'POST',
              data: payload.content['application/json']
            }) as ReturnValue<CreateCustomerApplicationV2Response>;

          const url = withTypedCap2BaseUrl(
            '/v1/cap2/create',
            {},
            {
              telem: payload.telem,
              partner: payload.partner,
              partnerType: payload.partnerType?.toUpperCase?.(),
              dealId: payload.dealId,
              ...addsParams
            }
          );

          const result = post(url);

          return result;
        } catch (error) {
          return { error: error as CustomBaseQueryError };
        }
      },
      invalidatesTags: ['applications']
    }),
    updateApplication: build.mutation<
      UpdateCustomerApplicationV2Response,
      UpdateCustomerApplicationV2Request
    >({
      query: (payload) => {
        const dealId = payload?.dealId ?? null;
        const url = withTypedCap2BaseUrl('/v1/cap2/update/{capId}', payload, { dealId });

        return {
          url,
          method: 'PUT',
          data: payload.content['application/json']
        };
      },
      invalidatesTags: ['applications']
    }),
    submitApplication: build.mutation<unknown, SubmitApplicationRequest>({
      query: (payload) => {
        return {
          url: withTypedCap2BaseUrl('/v1/cap2/submit/{capId}', payload, payload),
          method: 'POST'
        };
      },
      invalidatesTags: ['applications']
    }),
    updateApplicationOfferStatus: build.mutation<
      CustomerDecisionSubmitResponse,
      CustomerDecisionSubmitRequest
    >({
      query: (payload) => {
        return {
          url: withCap2BaseUrl(`/cap2/offers`),
          method: 'POST',
          data: payload
        };
      },
      /**
       * Make optimistic update on application offer accept if call fails revert optimistic update
       * @param arg
       * @param api
       */
      async onQueryStarted(arg, api) {
        const optimisticUpdate = api.dispatch(
          capApi.util.updateQueryData('fetchApplications', { fullList: true }, (draft) => {
            const updatedDraft = draft.map((application) => {
              if (application.applicationId === arg.applicationId) {
                return {
                  ...application,
                  status: (arg.accepted
                    ? 'OFFER_ACCEPTED'
                    : 'OFFER_REJECTED') as GetApplicationsResponse[0]['status']
                };
              }

              return application;
            });

            return updatedDraft;
          })
        );

        try {
          await api.queryFulfilled;
        } catch {
          optimisticUpdate.undo();
        }
      }
    }),
    fetchOfferFilenames: build.query<GetOfferFileNamesResponse, Partial<GetOfferFileNamesRequest>>({
      //@ts-expect-error - TS doesn't like the fact that we're using a custom queryFn
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        try {
          const get = <T>(url: string) => baseQuery({ url, method: 'GET', data: payload }) as ReturnValue<T>;

          const url = withTypedCap2BaseUrl('/v1/cap2/submitted/{applicationId}', {
            applicationId: payload.applicationId
          });

          const result = await get<SubmittedApplication>(url);

          if (result?.data) {
            const offer = result.data.offers?.[0];

            if (offer?.id) {
              const offerDocumentsUrl = withCapBaseUrl(
                '/v2/offers/{offerId}/documents',
                { offerId: offer?.id },
                {
                  applicationId: payload.applicationId
                }
              );

              const filenamesResult = await get<GetOfferFileNamesResponse>(offerDocumentsUrl);

              return { data: { filenames: filenamesResult.data, offerId: offer?.id } };
            }

            return { data: { filenames: [], offerId: null } };
          }
        } catch (error) {
          return { error: error as CustomBaseQueryError };
        }
      }
    }),
    acceptApplicationOffer: build.mutation<AcceptOfferResponse, AcceptOfferRequest>({
      query: (payload) => {
        return {
          url: withCapBaseUrl('/v1/offers/{offerId}/accept', payload, {
            comment: payload.comment
          }),
          method: 'POST'
        };
      },
      /**
       * Make optimistic update on application offer accept if call fails revert optimistic update
       * @param arg
       * @param api
       */
      async onQueryStarted(arg, api) {
        const optimisticUpdate = api.dispatch(
          capApi.util.updateQueryData('fetchApplications', { fullList: true }, (draft) => {
            const updatedDraft = draft.map((application) => {
              if (application.applicationId === arg.applicationId) {
                return {
                  ...application,
                  status: 'OFFER_ACCEPTED' as GetApplicationsResponse[0]['status']
                };
              }

              return application;
            });

            return updatedDraft;
          })
        );

        try {
          await api.queryFulfilled;
        } catch {
          optimisticUpdate.undo();
        }
      }
    }),
    rejectApplicationOffer: build.mutation<RejectOfferResponse, RejectOfferRequest>({
      query: (payload) => {
        return {
          url: withCapBaseUrl('/v1/offers/{offerId}/reject', payload, {
            comment: payload.comment
          }),
          method: 'POST'
        };
      },
      /**
       * Make optimistic update on application offer reject if call fails revert optimistic update
       * @param arg
       * @param api
       */
      async onQueryStarted(arg, api) {
        const optimisticUpdate = api.dispatch(
          capApi.util.updateQueryData('fetchApplications', { fullList: true }, (draft) => {
            const updatedDraft = draft.map((application) => {
              if (application.applicationId === arg.applicationId) {
                return {
                  ...application,
                  status: 'OFFER_REJECTED' as GetApplicationsResponse[0]['status']
                };
              }

              return application;
            });

            return updatedDraft;
          })
        );

        try {
          await api.queryFulfilled;
        } catch {
          optimisticUpdate.undo();
        }
      }
    }),
    getRequiredInfo: build.query<GetRequiredInfoResponse, GetRequiredInfoRequest>({
      query: (payload) => {
        return {
          url: queryBuilder(withCap2BaseUrl(`/applications/${payload.applicationId}/required-info`), payload),
          method: 'GET'
        };
      },
      providesTags: ['requestedDocumentsList']
    }),
    uploadRequiredInfo: build.mutation<uploadRequiredDocumentInfoResponse, uploadRequiredDocumentInfoRequest>(
      {
        query: (payload) => {
          return {
            url: withCap2BaseUrl(
              `/applications/${payload.applicationId}/required-info/${payload.infoId}?applicationId=${payload.applicationId}`
            ),
            method: 'POST',
            data: payload.formData
          };
        }
      }
    ),
    submitRequiredDocumentInfo: build.mutation<
      submitRequiredDocumentInfoResponse,
      submitRequiredDocumentInfoRequest
    >({
      query: (payload) => {
        return {
          url: withCap2BaseUrl(
            `/applications/${payload.applicationId}/required-info/${payload.infoId}/submit?applicationId=${payload.applicationId}`
          ),
          method: 'POST'
        };
      },
      /**
       * Make optimistic update on application additional docs submit if call fails revert optimistic update
       * @param arg
       * @param api
       */
      async onQueryStarted(arg, api) {
        const optimisticUpdate = api.dispatch(
          capApi.util.updateQueryData('fetchApplications', { fullList: true }, (draft) => {
            const updatedDraft = draft.map((application) => {
              if (application.applicationId === arg.applicationId) {
                return {
                  ...application,
                  status: 'ASSESSMENT_ONGOING' as GetApplicationsResponse[0]['status']
                };
              }

              return application;
            });

            return updatedDraft;
          })
        );

        try {
          await api.queryFulfilled;
        } catch {
          optimisticUpdate.undo();
        }
      }
    }),
    deleteApplication: build.mutation<unknown, string>({
      query: (payload) => {
        return {
          url: withTypedCap2BaseUrl('/v1/cap2/delete/{capId}', { capId: payload }),
          method: 'DELETE'
        };
      },
      /**
       * Make optimistic update on application delete if call fails revert optimistic update
       * @param arg
       * @param api
       */
      async onQueryStarted(arg, api) {
        const optimisticUpdate = api.dispatch(
          capApi.util.updateQueryData('fetchApplications', { fullList: true }, (draft) => {
            return draft.filter((application) => application.capId !== arg);
          })
        );

        try {
          await api.queryFulfilled;
        } catch {
          optimisticUpdate.undo();
        }
      }
    }),
    searchCompaniesByName: build.query<CompaniesSearchResponse, string>({
      query: (payload) => {
        return {
          url: withTypedCap2BaseUrl('/v1/company-details/{companyName}', { companyName: payload }),
          method: 'GET'
        };
      }
    })
  })
});

export const {
  submitPipedriveApplication,
  createApplication,
  updateApplication,
  submitApplication,
  deleteApplication,
  fetchApplication
} = capApi.endpoints;

export const {
  useAcceptApplicationOfferMutation,
  useRejectApplicationOfferMutation,
  useSubmitRequiredDocumentInfoMutation,
  useUploadRequiredInfoMutation,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
  useSubmitApplicationMutation,
  useDeleteApplicationMutation,
  useFetchApplicationsQuery,
  useLazyFetchApplicationsQuery,
  useLazyGetRequiredInfoQuery,
  useFetchOfferFilenamesQuery,
  useUpdateApplicationOfferStatusMutation,
  useLazyFetchApplicationQuery,
  useGetRequiredInfoQuery,
  useLazySearchCompaniesByNameQuery,
  useFetchApplicationQuery
} = capApi;

export const { resetApiState: resetCapApiState } = capApi.util;

export default capApi;
