import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import clock from 'assets/images/clock.png';
import { Modal, Typography } from 'components';

import { Container, Message, Image } from './limitedInfoModal.styles';

type Props = {
  isOpen: boolean;
  onContinue: () => void;
  onCancel: () => void;
};

export const LimitedInfoModal: FC<Props> = ({ isOpen, onContinue, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      mainActionhandler={onContinue}
      mainActionLabel={t('fillLeasingApplication')}
      secondaryActionhandler={onCancel}
      secondaryActionLabel="fillInLater"
      variant="informational"
      isOpen={isOpen}
    >
      <Container>
        <Image src={clock} alt={t('leasingCapSuccessFlowHeader')} />
        <Typography.Header type="h3" weight="medium">
          {t('leasingCapSuccessFlowHeader')}
        </Typography.Header>
        <Message>
          <Trans components={{ b: <b /> }} i18nKey="leasingCapSuccessFlowMessage" />
        </Message>
      </Container>
    </Modal>
  );
};
