import {
  MutableRefObject,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { breakpointSizes } from 'design/designVariables';
import { useWindowDimensions } from 'hooks/window';

import close from '../../assets/icons/close24px.svg';
import { Button, ButtonRow } from '../buttonV2';
import { LinkButton } from '../link';
import { Paper } from '../paper';

import {
  ContentWrapper,
  InformationIconContainer,
  InformationImageContainer,
  ModalFooter,
  ModalHeader,
  ModalLabel,
  Overlay
} from './modal.styles';

type Props = {
  variant?: 'functional' | 'informational';
  informationalIcon?: ReactNode;
  informationalImage?: ReactNode;
  onClose?: () => void;
  overlayClickhandler?: () => void;
  children: ReactNode;
  isOpen: boolean;
  label?: string | ReactNode;
  testId?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  maxWidth?: string;
  widthFitContent?: boolean;
  closeButton?: boolean;
  desktopFooterActionsDirection?: 'row' | 'column';
  padding?: string;
  sessionTimeoutModal?: boolean;
  termsModal?: boolean;
  stickyNavSections?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  toggleHash?: string;
  className?: string;
  footerSecondaryAction?: boolean;
  secondaryActionLabel?: string;
  secondaryActionTestId?: string;
  hideSecondaryAction?: boolean;
  mainActionLabel?: string;
  mainActionDisabled?: boolean;
  secondaryActionDisabled?: boolean;
  secondaryActionSubmitting?: boolean;
  mainActionTestId?: string;
  mainActionSubmitting?: boolean;
  mainActionhandler?: () => unknown;
  secondaryActionhandler?: () => unknown;
  contentWrapperBackground?: string;
  reverse?: boolean;
  mainButtonColor?: 'Blue' | 'DarkBlue' | 'Black' | 'Red' | 'Green' | 'Yellow';
};

export type ModalRef = {
  handleClose: () => void;
} & HTMLDivElement;

export const Modal = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      label,
      onClose,
      fullHeight,
      fullWidth,
      testId,
      maxWidth,
      widthFitContent,
      padding,
      sessionTimeoutModal,
      termsModal,
      isOpen,
      className,
      header,
      footer,
      contentWrapperBackground,
      mainActionLabel,
      secondaryActionLabel,
      mainActionhandler,
      secondaryActionhandler,
      hideSecondaryAction,
      overlayClickhandler,
      mainActionDisabled,
      secondaryActionTestId,
      mainActionTestId,
      informationalIcon,
      informationalImage,
      secondaryActionDisabled,
      secondaryActionSubmitting,
      mainButtonColor,
      mainActionSubmitting,
      variant = 'functional',
      footerSecondaryAction = true,
      closeButton = true,
      stickyNavSections = true,
      reverse = false,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const overlayRef = useRef() as MutableRefObject<HTMLDivElement>;
    const contentWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    const topMarkerRef = useRef() as MutableRefObject<HTMLDivElement>;
    const bottomMarkerRef = useRef() as MutableRefObject<HTMLDivElement>;

    const isInMobileView = useWindowDimensions().width < breakpointSizes.maxPhonePx;

    const topMarkerIntersectionObserverRef = useRef(
      new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (overlayRef.current) {
            if (!entry.isIntersecting) {
              overlayRef.current.classList.add('top-shadow');
            } else {
              overlayRef.current.classList.remove('top-shadow');
            }
          }
        });
      })
    );

    const bottomMarkerIntersectionObserverRef = useRef(
      new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (overlayRef.current) {
            if (!entry.isIntersecting) {
              overlayRef.current.classList.add('bottom-shadow');
            } else {
              overlayRef.current.classList.remove('bottom-shadow');
            }
          }
        });
      })
    );

    const showCloseButton = closeButton && onClose;
    const showModalHeader = label || showCloseButton;

    useImperativeHandle(ref, () => overlayRef.current);

    const [modalIsOpen, setModalIsOpen] = useState(isOpen);

    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      }

      return () => {
        document.body.style.overflow = '';
      };
    }, [isOpen]);

    useEffect(() => {
      if (topMarkerRef.current && isOpen) {
        topMarkerIntersectionObserverRef.current.observe(topMarkerRef.current);
      }

      if (topMarkerRef.current && !isOpen) {
        topMarkerIntersectionObserverRef.current.unobserve(topMarkerRef.current);
      }

      return () => {
        if (topMarkerRef.current) {
          topMarkerIntersectionObserverRef.current.unobserve(contentWrapperRef.current);
        }
      };
    }, [topMarkerRef.current, isOpen]);

    useEffect(() => {
      if (bottomMarkerRef.current) {
        bottomMarkerIntersectionObserverRef.current.observe(bottomMarkerRef.current);
      }

      if (bottomMarkerRef.current && !isOpen) {
        bottomMarkerIntersectionObserverRef.current.unobserve(bottomMarkerRef.current);
      }

      return () => {
        if (bottomMarkerRef.current && isOpen) {
          bottomMarkerIntersectionObserverRef.current.unobserve(contentWrapperRef.current);
        }
      };
    }, [bottomMarkerRef.current, isOpen]);

    useEffect(() => {
      if (isOpen !== modalIsOpen) {
        setModalIsOpen(isOpen);
      }
    }, [isOpen]);

    const onOverlayClick = async (e: SyntheticEvent<HTMLDivElement>) => {
      if (e.target === overlayRef.current) {
        if (closeButton || overlayClickhandler) {
          (overlayClickhandler ?? onClose)?.();
        }
      }
    };

    const handleSecondaryActionClick = async () => {
      (secondaryActionhandler ?? onClose)?.();
    };

    const handleCloseClick = async (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      await onClose?.();
    };

    const functionalButtonSize = isInMobileView ? 'L' : 'M';

    const CustomOvelay = () => (
      <Overlay
        onClick={(e) => {
          e.stopPropagation();
        }}
        stickyNavSections={stickyNavSections}
        onMouseDown={onOverlayClick}
        widthFitContent={widthFitContent}
        fullHeight={fullHeight}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        padding={padding}
        ref={overlayRef}
        sessionTimeoutModal={sessionTimeoutModal}
        termsModal={termsModal}
        className={className}
        variant={variant}
      >
        <Paper data-testid={testId ? `modal-${testId}` : 'modal'}>
          {showModalHeader && (
            <ModalHeader>
              {variant === 'functional' ? <ModalLabel>{label}</ModalLabel> : <div></div>}
              {showCloseButton && (
                <LinkButton
                  color="Grey"
                  iconOnly
                  icons={{ left: close }}
                  data-testid={testId ? `modal-${testId}-close-button` : 'modal-close-button'}
                  onClick={handleCloseClick}
                ></LinkButton>
              )}
            </ModalHeader>
          )}

          {header || null}
          <ContentWrapper
            verticallyCentered={variant === 'informational'}
            ref={contentWrapperRef}
            style={{ backgroundColor: contentWrapperBackground }}
          >
            {informationalIcon ? (
              <InformationIconContainer>{informationalIcon}</InformationIconContainer>
            ) : null}
            {informationalImage ? (
              <InformationImageContainer offsetContent={Boolean(showCloseButton)}>
                {informationalImage}
              </InformationImageContainer>
            ) : null}
            <div ref={topMarkerRef} id="top-marker"></div>
            {variant === 'informational' ? <ModalLabel>{label}</ModalLabel> : null}
            {children}
            <div ref={bottomMarkerRef} id="bottom-marker"></div>
          </ContentWrapper>
          <ModalFooter>
            {footer || null}
            <ButtonRow desktopDirection={variant === 'functional' ? 'row' : 'column'} reverse={reverse}>
              {(onClose || footerSecondaryAction) && !hideSecondaryAction ? (
                <Button
                  size={variant === 'functional' ? functionalButtonSize : 'L'}
                  variant="StrokedWhite"
                  color="Black"
                  submitting={secondaryActionSubmitting}
                  disabled={secondaryActionDisabled}
                  data-testid={
                    secondaryActionTestId ??
                    (testId ? `modal-${testId}-secondary-button` : 'modal-secondary-button')
                  }
                  onClick={secondaryActionhandler ? handleSecondaryActionClick : handleCloseClick}
                >
                  {t(secondaryActionLabel ?? 'close')}
                </Button>
              ) : null}
              {mainActionhandler ? (
                <Button
                  submitting={mainActionSubmitting}
                  disabled={mainActionDisabled}
                  size={variant === 'functional' ? functionalButtonSize : 'L'}
                  data-testid={
                    mainActionTestId || (testId ? `modal-${testId}-main-button` : 'modal-main-button')
                  }
                  onClick={mainActionhandler}
                  color={mainButtonColor ?? 'Blue'}
                  variant="Filled"
                >
                  {mainActionLabel}
                </Button>
              ) : null}
            </ButtonRow>
          </ModalFooter>
        </Paper>
      </Overlay>
    );

    return (
      <ReactModal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayElement={CustomOvelay}
        {...props}
      ></ReactModal>
    );
  }
);
