import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import arrowLeft from '../../assets/icons/arrowLeft.svg';
import { LinkButton } from '../link';
import { CheckmarkCircle, Line } from '../loader';
import { SecondaryText } from '../text';

import {
  AuthenticationDialogContainer,
  VerificationCodeStyle,
  LinkBackStyles,
  Verifcode,
  VerifcodeText,
  VerificationForm,
  HeaderLabelsContainer,
  Header,
  InfoMessage,
  TimerText,
  SuccessDialogContainer,
  ChildrenContainer,
  DigitFrame,
  DigitsLoader
} from './authenticationDialog.styles';

type InitResponse = { sessionId?: string; verificationCode?: string };
type CompleteResponse = { state?: string };

type PollingConfig = {
  init: () => Promise<InitResponse>;
  complete: (initResponse: InitResponse) => Promise<CompleteResponse>;
};

type Props = {
  testId: string;
  isWaitingForConfirmation: boolean;
  children: ReactNode;
  backLinkLabel: string;
  infoMessage: string;
  timerLabel?: string;
  headerLabel?: string;
  subheaderLabel?: string;
  onBackHandle: () => unknown;
  onFailHandle: () => unknown;
  onSuccessHandle: (completeResponse: unknown) => unknown;
  pollingConfig: PollingConfig;
  hideBackLink?: boolean;
  challengeCompleted?: boolean;
};

// allow for calls to be repeated for max one minute before reseting back to initial state
const BREAKER_COUNT = 50;

const TIMEOUT = 100; // 100 seconds

const sleep = (timeMS = 2000) => new Promise((resolve) => setTimeout(resolve, timeMS));

export const AuthenticationDialog: FC<Props> = ({
  children,
  testId,
  isWaitingForConfirmation,
  backLinkLabel,
  infoMessage,
  headerLabel,
  subheaderLabel,
  onBackHandle,
  onFailHandle,
  onSuccessHandle,
  pollingConfig,
  timerLabel,
  hideBackLink,
  challengeCompleted
}) => {
  const pollingRef = useRef<number | null>(null);
  const breakerRef = useRef<number>(0);
  const mutexLockRef = useRef<boolean>(false);
  const timerRef = useRef<number>(0);

  const [currentTime, setCurrentTime] = useState<number>(TIMEOUT);

  const [verificationCode, setVerificationCode] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccessCheckmark, setShowSuccessCheckmark] = useState(false);
  const [confirmationInProgress, setConfirmationInProgress] = useState(isWaitingForConfirmation);

  const { init, complete } = pollingConfig || {};

  const handleClearingPollingInterval = () => {
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    if (isWaitingForConfirmation) {
      if (init && complete) {
        (async () => {
          try {
            setShowSpinner(true);
            const initResponse = await init();

            if (initResponse) {
              if (initResponse.sessionId) {
                setVerificationCode(initResponse.verificationCode || '');
                timerRef.current = setInterval(() => {
                  setCurrentTime((currentTime) => currentTime - 1);
                }, 1000) as unknown as number;
              }
            }

            if (!initResponse || !initResponse?.sessionId) {
              throw new Error('missing initial information');
            }

            setShowSpinner(false);

            pollingRef.current = setInterval(async () => {
              try {
                // only call next iteration if mutex lock is unlocked
                if (!mutexLockRef.current) {
                  mutexLockRef.current = true;
                  const completeResponse = await complete(initResponse);

                  if (completeResponse.state === 'waiting') {
                    await sleep();
                  }

                  mutexLockRef.current = false;

                  //clear polling interval after success
                  if (completeResponse.state === 'ok') {
                    onSuccessHandle(completeResponse);
                    handleClearingPollingInterval();
                  }

                  //clear polling interval after max exceeded calls or status is not one of whitelisted ones and reset back to initial state
                  if (
                    breakerRef.current >= BREAKER_COUNT ||
                    currentTime <= 0 ||
                    (completeResponse.state !== 'waiting' && completeResponse.state !== 'ok')
                  ) {
                    handleBackClick();
                    onFailHandle();
                  }
                }

                //iterate breaker outside of lock as it counts ellapsed time instead of call counts
                breakerRef.current = +1;
              } catch {
                handleBackClick();
                onFailHandle();
              }
            }, 500) as unknown as number;
          } catch (err) {
            handleBackClick();
            onFailHandle();
          }
        })();
      }
    } else {
      breakerRef.current = 0;
    }

    return handleClearingPollingInterval;
  }, [isWaitingForConfirmation]);

  useEffect(() => {
    if (challengeCompleted) {
      setShowSuccessCheckmark(true);
    }
  }, [challengeCompleted]);

  useEffect(() => {
    if (confirmationInProgress !== isWaitingForConfirmation) {
      setConfirmationInProgress(isWaitingForConfirmation);
    }
  }, [isWaitingForConfirmation]);

  useEffect(() => {
    if (!isWaitingForConfirmation && confirmationInProgress) {
      handleClearingPollingInterval();
      setVerificationCode('');
      setShowSpinner(false);
      setShowSuccessCheckmark(false);
      setConfirmationInProgress(false);
      setCurrentTime(TIMEOUT);
    }
  }, [isWaitingForConfirmation]);

  const handleBackClick = () => {
    onBackHandle?.();
    handleClearingPollingInterval();
    setShowSpinner(false);
  };

  return (
    <AuthenticationDialogContainer>
      <ChildrenContainer display={!isWaitingForConfirmation && !showSuccessCheckmark}>
        {children}
      </ChildrenContainer>
      {isWaitingForConfirmation || showSuccessCheckmark ? (
        <VerificationForm>
          <LinkBackStyles>
            {hideBackLink ? null : (
              <LinkButton
                iconOnly
                color="Black"
                icons={{ left: arrowLeft }}
                data-testid="authentication-dialog-back-link"
                onClick={handleBackClick}
              >
                {backLinkLabel}
              </LinkButton>
            )}
            <HeaderLabelsContainer>
              {headerLabel && <Header>{headerLabel}</Header>}
              {subheaderLabel ? <SecondaryText>{subheaderLabel}</SecondaryText> : null}
            </HeaderLabelsContainer>
          </LinkBackStyles>
          {showSuccessCheckmark ? (
            <SuccessDialogContainer>
              <CheckmarkCircle />
            </SuccessDialogContainer>
          ) : (
            <VerificationCodeStyle>
              <Verifcode>
                {verificationCode ? (
                  <VerifcodeText data-testid={`${testId}-verification-code`}>
                    {verificationCode}
                  </VerifcodeText>
                ) : (
                  <DigitsLoader>
                    <DigitFrame />
                    <DigitFrame />
                    <DigitFrame />
                    <DigitFrame />
                  </DigitsLoader>
                )}
                {showSpinner ? null : (
                  <TimerText>
                    {`${timerLabel} `}
                    <b>{`${currentTime}s`}</b>
                  </TimerText>
                )}
              </Verifcode>
              <Line />
            </VerificationCodeStyle>
          )}
          <InfoMessage>{infoMessage}</InfoMessage>
        </VerificationForm>
      ) : null}
    </AuthenticationDialogContainer>
  );
};
