import styled from 'styled-components';

import { LinkButtonStyle } from 'components';
import { OuterTableContainer } from 'components/table/table.styles';

export const Row = styled.div`
  display: flex;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 0.625rem;

  @media ${({ theme }) => theme.media.maxPhone} {
    margin-top: 0.375rem;
    margin-left: 0;
  }
`;

export const CrudActions = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Action = styled.div`
  width: 2rem;
  display: flex;
  justify-content: center;

  ${LinkButtonStyle} {
    color: ${({ theme }) => theme.colors.secondary.textColor};

    .pencil {
      path {
        stroke: ${({ theme }) => theme.colors.base.black.shades[50]};
      }
    }

    .bin {
      path {
        fill: ${({ theme }) => theme.colors.base.black.shades[50]};
      }
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  ${OuterTableContainer} {
    margin-top: 1.5rem;
  }

  div[data-column-id='amount'] {
    display: flex;
    justify-content: flex-end;
  }

  div[data-column-id='transferClaim'] {
    display: flex;
    justify-content: flex-end;
  }

  div[role='columnheader'] {
    div[data-column-id='transferClaim'] {
      text-overflow: ellipsis;
      white-space: normal;
      overflow: hidden;
      display: inline;
    }

    div[data-column-id='amount'] {
      text-overflow: ellipsis;
      white-space: normal;
      overflow: hidden;
      display: inline;
    }
  }

  div[data-column-id='actions'] {
    width: 4rem;
  }

  .upload-invoices-table-cell_actions_type {
    text-align: end;
  }
`;

export const DeleteButtonContainer = styled.div`
  display: flex;

  ${LinkButtonStyle} {
    svg {
      path {
        fill: currentColor;
      }
    }
  }
`;

export const ResponsiveTableContainer = styled.div`
  width: 100%;

  .table-cell_amount-type {
    text-align: end;
  }

  .table-cell_upload-type {
    text-align: center;
  }

  .table-cell_status-type {
    text-align: end;
  }

  .table-cell_buyer-type {
    white-space: pre-wrap;
  }

  .table-cell_error,
  .mobile-list-row.table-cell_error {
    color: ${({ theme }) => theme.colors.primary.errorText};

    span {
      color: ${({ theme }) => theme.colors.primary.errorText};
    }
  }
`;

export const UploadTypeBlock = styled.div`
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3125rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.black.shades[50]};
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  font-weight: 500;
  letter-spacing: 0.015rem;
`;
