import * as Yup from 'yup';

import { ISO_CODES } from 'components';

import { VALIDATION_MESSAGE_ID } from './types';

export const countryRequiredMessage = (message: string) =>
  Yup.string().oneOf(
    ISO_CODES.map((country) => country.value),
    message
  );

export const countryRequired = countryRequiredMessage(VALIDATION_MESSAGE_ID.REQUIRED);
