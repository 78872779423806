import { Dispatch, FC, SetStateAction, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../modal';
import { Note } from '../notificationModal/notificationModal.styles';

type Props = {
  onConfirmation?: (...args: unknown[]) => unknown;
  onVisibilityChange?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
  message?: string;
  iconNode?: ReactNode;
  children?: ReactNode;
  label?: string;
  icon?: ReactNode;
  mainActionLabel?: string;
  secondaryActionLabel?: string;
  mainButtonColor?: 'Blue' | 'DarkBlue' | 'Black' | 'Red' | 'Green' | 'Yellow';
};

export const DeleteConfirmationModal: FC<Props> = ({
  isOpen,
  message,
  onConfirmation,
  onVisibilityChange,
  iconNode,
  children,
  label,
  icon,
  mainActionLabel,
  secondaryActionLabel,
  mainButtonColor
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onVisibilityChange?.(false);
  };

  const handleConfirmation = () => {
    onConfirmation?.();
    handleClose();
  };

  return (
    <Modal
      informationalIcon={icon ? icon : ''}
      variant="informational"
      testId="delete-confirmation"
      label={t(label ?? 'deleteConfirmation')}
      isOpen={!!isOpen}
      onClose={handleClose}
      mainActionLabel={t(mainActionLabel ?? 'yes')}
      secondaryActionLabel={t(secondaryActionLabel ?? 'no')}
      mainActionhandler={handleConfirmation}
      mainActionTestId="modal-delete-confirmation-yes-button"
      secondaryActionTestId="modal-delete-confirmation-no-button"
      mainButtonColor={mainButtonColor}
    >
      {iconNode ? iconNode : null}
      {message ? <Note>{message}</Note> : null}
      {children}
    </Modal>
  );
};
