import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ISO_CODES } from 'components';
import { Drawer } from 'features/app/components/drawer';
import { ThirdPartiesFields } from 'features/application/forms/thirdParties/thirdPartiesFields';
import { PARTY_TYPE_VALUE } from 'features/application/forms/types/formTypes';
import { selectFactoringType, selectThirdParties } from 'features/application/selectors';
import { setThirdPartiesData } from 'features/application/slice';
import { useNavContext } from 'features/nav/context/navContext';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { ThirdParty } from 'services/CapService/types';
import { companyNameRequired, companyCodeRequired, amountRangeRequired } from 'validators';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';

export type ThirdPartiesDrawer = {
  mode: 'add' | 'edit';
  keyInEdit?: number;
};

const AmountRanges = {
  min: 0,
  max: 9999999
};

export const validationSchema = Yup.object({
  company: Yup.object({
    name: companyNameRequired,
    code: companyCodeRequired,
    vatCode: Yup.string().optional().max(16, VALIDATION_MESSAGE_ID.MAX_LENGTH),
    country: Yup.string().oneOf(
      ISO_CODES.map((country) => country.value),
      VALIDATION_MESSAGE_ID.REQUIRED
    )
  }),
  deferralPeriod: Yup.number()
    .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
    .required(VALIDATION_MESSAGE_ID.REQUIRED),
  limitAmount: amountRangeRequired(AmountRanges)
});

const initialValues: ThirdParty = {
  company: { code: '', name: '', vatCode: '', country: '' },
  deferralPeriod: NaN,
  limitAmount: NaN
};

const ThirdPartyDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { activeDrawer, hideSideDrawer, setActiveDrawer } = useNavContext<ThirdPartiesDrawer>();

  const thirdParties = useApplicationSelector<typeof selectThirdParties>(selectThirdParties);
  const factoringType = useApplicationSelector<typeof selectFactoringType>(selectFactoringType);

  const [activeForm, setActiveForm] = useState<ThirdParty>(initialValues);
  const [formStatus, setFormStatus] = useState<'edit' | 'add'>('add');
  const [formKeyInEdit, setFormKeyInEdit] = useState(-1);

  const { validationHelpers, getValues, handleSubmit, setTouchedOnAll, reset, trigger } =
    useFormHandlers<ThirdParty>(activeForm, validationSchema, 'onBlur');

  useEffect(() => {
    (async () => {
      if (activeDrawer) {
        const activeDrawerKey = activeDrawer?.keyInEdit ?? -1;

        if (activeDrawer.mode === 'edit' && activeDrawerKey > -1) {
          const party = (thirdParties ?? [])[activeDrawerKey];
          setActiveForm(party);
          setFormKeyInEdit(activeDrawerKey);
        } else {
          setActiveForm(initialValues);
          setFormKeyInEdit(-1);
        }

        setFormStatus(activeDrawer.mode);
      }
    })();
  }, [activeDrawer?.keyInEdit, activeDrawer?.mode, thirdParties]);

  useEffect(() => {
    setTimeout(() => {
      trigger();
    }, 100);
  }, [activeForm]);

  const saveParties = (parties: ThirdParty[]) => {
    if (parties.length) {
      dispatch(setThirdPartiesData(parties));
    }
  };

  const addSubmitHandler = async () => {
    await handleSubmit(
      (data: ThirdParty) => {
        saveParties([...(thirdParties ?? []), data]);
        hideSideDrawer?.();
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const editSubmitHandler = async (key: number) => {
    await handleSubmit(
      (data: ThirdParty) => {
        saveParties((thirdParties ?? []).map((party, index) => (index === key ? data : party)));
        hideSideDrawer?.();
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const onPartyUpdate = async () => {
    if (formStatus === 'add') {
      await addSubmitHandler();
    } else {
      await editSubmitHandler(formKeyInEdit);
    }
  };

  const onPartyDelete = () => {
    const owners = (thirdParties ?? []).filter((_, index) => index !== formKeyInEdit);

    dispatch(setThirdPartiesData(owners));
    hideSideDrawer?.();
  };

  const onFormReset = () => {
    setActiveForm(initialValues);
    setFormStatus('add');
    setFormKeyInEdit(-1);
    reset(initialValues);
    setActiveDrawer?.({});
  };

  const addTextId = factoringType?.partyType === PARTY_TYPE_VALUE.BUYERS ? 'addBuyer' : 'addSuplier';

  const allowDelete = (thirdParties?.length ?? 0) > 1;

  return (
    <Drawer
      isValid={validationHelpers.isValid}
      header={t(addTextId)}
      disabledDelete={!allowDelete}
      onDelete={formStatus === 'edit' ? onPartyDelete : undefined}
      onSave={onPartyUpdate}
      onClose={onFormReset}
      buttonLabel={t('save')}
    >
      <ThirdPartiesFields validationHelpers={validationHelpers} getValues={getValues} />
    </Drawer>
  );
};

export default ThirdPartyDrawer;
