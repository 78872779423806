import styled from 'styled-components';

import { SecondaryText } from 'components';

type Props = {
  locale: string;
};

export const TimeRangeExplanationText = styled(SecondaryText)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.375rem;
`;

export const FormStyle = styled.form<Props>`
  position: relative;
`;
