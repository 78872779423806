import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { getExistingCustomerOnboardingConfig } from 'defaultConfiguration/getExistingCustomerOnboardingConfig';
import { getOnboardingSSO } from 'defaultConfiguration/getOnboardingSSO';
import { selectActiveCompany, selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { selectActiveCustomersPermissions } from 'features/auth/selectors';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';

const useIsExistingCustomerOnboardingEnabled = () => {
  const onboardingRemoteConfig = getExistingCustomerOnboardingConfig();

  const activeCompany = useAppSelector(selectActiveCompany);
  const isCompanyVerified = useAppSelector(selectIsActiveCompanyVerified);

  const countryIsAllowed = onboardingRemoteConfig?.availableCountries?.includes(
    activeCompany?.country?.toUpperCase() ?? ''
  );

  const userRoles = useAuthSelector<typeof selectActiveCustomersPermissions>(
    selectActiveCustomersPermissions
  );

  const customerFitsOnboardingUpdateConditions =
    activeCompany &&
    isCompanyVerified &&
    userRoles?.includes('MANAGE_USERS') &&
    userRoles?.includes('MANAGE_CONTRACT');

  const onboardingSSOCountriesList = getOnboardingSSO();

  const { isFeatureEnabled } = useRemoteConfigContext();

  return (
    isFeatureEnabled('existingCustomerOnboarding') &&
    countryIsAllowed &&
    customerFitsOnboardingUpdateConditions &&
    onboardingSSOCountriesList.includes(activeCompany?.country?.toUpperCase() ?? '')
  );
};

export default useIsExistingCustomerOnboardingEnabled;
