import { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { NotificationModal } from 'components';
import { useLazyFetchApplicationDetails } from 'features/application/hooks';
import { selectContactPerson } from 'features/application/selectors';
import { setApplication } from 'features/application/slice';
import { selectActiveCompany, selectVerificationApplication } from 'features/applications/selectors';
import { logoutAsync } from 'features/auth/slice';
import { ModalsRegistry, useModalContext } from 'features/nav/context/modalContext';
import { useAppDispatch, useAppSelector, useApplicationSelector } from 'hooks/redux/hooks';

type Variant = 'success' | 'error';

const informationalIcons: Record<Variant, React.ReactNode> = {
  success: <Icons.Heart />,
  error: <Icons.UserBlock />
};

const explanations = (
  t: TFunction,
  name?: string
): Record<Variant, { explanation: string; header: string }> => ({
  success: {
    explanation: t('loginWithVerifiedMethodExplanation'),
    header: name ? t('successVerificationPersonalizedGreeting', { name }) : t('successVerificationGreeting')
  },
  error: {
    explanation: t('identityVerificationFailedExplanation'),
    header: t('identityVerificationFailed')
  }
});

const createHandlers = (
  modals: ModalsRegistry,
  onClose: () => unknown,
  t: TFunction
): Record<
  Variant,
  {
    label: string;
    handler: () => unknown;
  }
> => ({
  success: {
    label: t('continueToLogin'),
    handler: onClose
  },
  error: {
    label: t('tryAgain'),
    handler: () => {
      modals?.get('ondato-redirect')?.();
      onClose();
    }
  }
});

const OndatoStatusModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const contactPerson = useApplicationSelector<typeof selectContactPerson>(selectContactPerson) ?? {};
  const application = useAppSelector(selectVerificationApplication);
  const activeCompany = useAppSelector(selectActiveCompany);

  const { modals, registerModalHash } = useModalContext<Variant>();
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState<Variant>('success');

  const { firstName } = contactPerson || {};

  const onClose = () => {
    setOpen(false);
  };

  const explanation = explanations(t, firstName)[variant];
  const mainAction = createHandlers(modals, onClose, t)[variant];
  const icon = informationalIcons[variant];
  const resolvedId = activeCompany?.applicationId ?? application?.applicationId ?? '';

  const [fetchApplicationByIdAsync] = useLazyFetchApplicationDetails();

  const initiateFetchApplication = async () => {
    try {
      const result = await fetchApplicationByIdAsync({
        capId: application.capId ?? '',
        applicationId: resolvedId ?? ''
      }).unwrap();

      dispatch(setApplication(result));
    } catch {
      // fail silently
    }
  };

  const fetchDataAndRegisterModal = async () => {
    try {
      if (application.capId || resolvedId) {
        await initiateFetchApplication();
      }
    } catch {
      // fail silently
    }

    const modal = registerModalHash('ondato-status', (variant) => {
      dispatch(
        logoutAsync({
          identityStepUp: true
        })
      );
      setVariant(variant ?? 'success');
      setOpen(true);
    });

    return () => {
      modal.unregister();
    };
  };

  useEffect(() => {
    fetchDataAndRegisterModal();
  }, []);

  return (
    <NotificationModal
      type={variant}
      isOpen={Boolean(open)}
      informationalIcon={icon}
      label={explanation.header}
      handleClose={onClose}
      explanationText={explanation.explanation}
      mainAction={mainAction}
    />
  );
};

export default OndatoStatusModal;
