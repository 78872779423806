import { selectActiveCustomer, selectIsUserGhost } from 'features/auth/selectors';
import { useAuthSelector } from 'hooks/redux/hooks';

import { useGetFactoringContractTotalsQuery } from '../api';

import { useFetchFactoringContracts } from './useFetchFactoringContracts';

export const useFetchContractsTotals = () => {
  const isUserGhost = useAuthSelector<typeof selectIsUserGhost>(selectIsUserGhost);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const partyId = activeCustomer?.customerCrmId || '';

  const {
    data: contractsData,
    isFetching: contractsLoading,
    isError: isContractsError
  } = useFetchFactoringContracts({ skip: isUserGhost || !partyId });

  const contracts = contractsData?.contracts;
  const hasNoData = !contracts?.length;

  const { isError, isFetching, isLoading, isSuccess, isUninitialized, data, currentData, refetch } =
    useGetFactoringContractTotalsQuery(
      { partyId },
      { skip: isUserGhost || contractsLoading || isContractsError || hasNoData }
    );

  return {
    hasNoData,
    isError: isError || isContractsError,
    isFetching: isFetching || contractsLoading,
    isLoading,
    isSuccess,
    isUninitialized,
    data,
    currentData,
    refetch
  };
};
