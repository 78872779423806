import { getValue } from 'firebase/remote-config';

import { RemoteConfigSingleton } from 'services';

type TypesOfPartnership = Record<string, 'partner' | 'affiliate'>;

export const getTypesOfPartnership = (id?: string) => {
  const isInTestEnv = process.env.NODE_ENV === 'test';

  if (isInTestEnv || !id) {
    /**
     * for running tests in node enviroments resolve default configuration
     */
    return '';
  }

  const remoteConfigInstance = RemoteConfigSingleton.getInstance();

  if (remoteConfigInstance.remoteConfig) {
    const typesOfPartnershipJSONString = getValue(
      remoteConfigInstance.remoteConfig,
      'typesOfPartnership'
    ).asString();

    const typesOfPartnership = (JSON.parse(typesOfPartnershipJSONString) || []) as TypesOfPartnership;

    return typesOfPartnership[id];
  }

  return '';
};
