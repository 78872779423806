import { useRef, useEffect } from 'react';

const removeCookiebotButton = () => {
  const cookiePrivacyTriggerButton = document.getElementById('CookiebotWidget');

  if (cookiePrivacyTriggerButton) {
    cookiePrivacyTriggerButton.remove();
  }
};

export const useCustomPrivacyTrigger = () => {
  const mutationObserverRef = useRef<MutationObserver | null>(new MutationObserver(removeCookiebotButton));

  useEffect(() => {
    if (mutationObserverRef.current) {
      // Remove cookiebot button if it is already in the DOM before attaching the observer
      removeCookiebotButton();

      mutationObserverRef.current.observe(document.body, {
        childList: true,
        subtree: true
      });
    }
    return () => {
      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }
    };
  }, []);
};
