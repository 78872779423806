import styled from 'styled-components';

import { Typography } from 'components/typography';
import { InputText } from 'components/typography/typography.styles';

export const CopyrightTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;
  width: 100%;
  margin-top: auto;

  @media ${({ theme }) => theme.media.maxTablet} {
    flex-direction: column;
    padding: 0.25rem 1rem 1rem;
  }
`;

export const CopyrightLinkText = styled(Typography.Button).attrs({ type: 'S', weight: 'normal' })`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.tints[60]};
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  text-align: center;

  svg {
    width: 1rem;
    height: 1rem;

    g > * {
      stroke: ${({ theme }) => theme.colors.base.black.tints[60]};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.base.black.tints[90]};

    svg {
      g > * {
        stroke: ${({ theme }) => theme.colors.base.black.tints[90]};
      }
    }
  }
`;

export const CopyrightText = styled(Typography.Body).attrs({ type: 'S', weight: 'normal' })`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.base.black.tints[40]};
`;

export const CopyrightLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${InputText} {
    font-size: 0.75rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.base.black.tints[60]};

    ${InputText} {
      font-size: 0.75rem;
    }
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 0.5rem;
  }
`;

export const Elipsis = styled.span`
  display: inline-flex;
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.base.black.tints[60]};
`;
