import { checkForValidISOCode } from './checkForValidISOCode';

export const getCountryDisplayName = (
  countryIsoCode: string,
  selectedLanguage: 'lt' | 'en',
  disableCheck?: boolean
) => {
  try {
    const validISOCode = disableCheck || checkForValidISOCode(countryIsoCode);

    if (validISOCode) {
      const regionNames = new Intl.DisplayNames([selectedLanguage], { type: 'region' });

      return regionNames.of(countryIsoCode);
    }

    return countryIsoCode;
  } catch {
    //fail silently
  }
};

const casesMapping = {
  ai: 'uose',
  a: 'oje'
};

export const addCaseToCountryName = (countryName?: string) => {
  if (!countryName) {
    return '';
  }

  const lastLetter = countryName.slice(-1);
  const lastTwoLetters = countryName.slice(-2);

  if (lastLetter === 'a') {
    return countryName.slice(0, -1) + casesMapping.a;
  }

  if (lastTwoLetters === 'ai') {
    return countryName.slice(0, -2) + casesMapping.ai;
  }

  return countryName;
};
