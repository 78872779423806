import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import close from 'assets/icons/close24px.svg';
import { IbanInput } from 'components';
import { getSmeBankPromo } from 'defaultConfiguration/getSmeBankPromo';
import { selectVerificationApplication } from 'features/applications/selectors';
import { useExternalSessionStorage } from 'features/onboarding/hooks';
import { useAppSelector } from 'hooks/redux/hooks';
import { getLanguageCode } from 'translations/utils';
import tracking from 'utils/tracking';

import AlternativeBankModal from '../alternativeBankModal/alternativeBankModal';

import {
  BankAccountExplanationText,
  BankAlternativeButton,
  BankCardCloseButton,
  BankCardContent,
  BankCardPrimaryButton,
  BankCardText,
  BankLogo,
  BankPaperCard,
  GradientBankLogo
} from './bankCard.styles';

type Props = {
  iban?: string;
  alternativeBankAccountEnabled?: boolean;
  step?: 'setUpBankAccount';
  secondaryAction?: boolean;
  shouldOpenBankAccount?: boolean;
  onClose?: () => unknown;
};

const BankCard = ({
  iban,
  alternativeBankAccountEnabled,
  step = 'setUpBankAccount',
  secondaryAction,
  shouldOpenBankAccount,
  onClose
}: Props) => {
  const { t, i18n } = useTranslation();

  const application = useAppSelector(selectVerificationApplication);

  const [showAlternativeBankModal, setShowAlternativeBankModal] = useState(false);

  const smeBankPromoList = getSmeBankPromo();
  const enableSmeBankPromo = smeBankPromoList.includes(
    application.company?.country ?? application.farm?.country ?? ''
  );

  const [, setBankStarted] = useExternalSessionStorage(step);

  const handleBankAccountSetup = () => {
    if (enableSmeBankPromo) {
      setBankStarted();

      const queryLanguage = `?step=Intro&language=${getLanguageCode(i18n.language)}`;

      tracking.setSubmitEvent('single_flow_sme_bank_account_setup_started');

      window.open(VARIABLES.onboardingBankDomainUrl + queryLanguage, '_blank');

      onClose?.();
    } else {
      setShowAlternativeBankModal(true);
    }
  };

  const renderButton = () => {
    if (secondaryAction) {
      return (
        <BankCardPrimaryButton variant="StrokedWhite" onClick={handleBankAccountSetup}>
          {`${t('continueTo')} `}
          <GradientBankLogo />
          <Icons.Enter />
        </BankCardPrimaryButton>
      );
    }

    return (
      <BankCardPrimaryButton gradient onClick={handleBankAccountSetup}>
        {`${t('continueTo')} `}
        <BankLogo />
        <Icons.Enter />
      </BankCardPrimaryButton>
    );
  };

  return (
    <>
      <AlternativeBankModal
        open={showAlternativeBankModal}
        onVisibilityChange={setShowAlternativeBankModal}
      />
      <BankPaperCard>
        {onClose ? <BankCardCloseButton icons={{ left: close }} onClick={onClose} /> : null}
        {enableSmeBankPromo ? (
          <BankCardContent>
            {shouldOpenBankAccount ? (
              <BankCardText>{t('directToSmeBank')}</BankCardText>
            ) : (
              <>
                <BankCardText>{t('setUpBankAccountHeader')}</BankCardText>
                <BankCardText>
                  <Trans
                    i18nKey={
                      alternativeBankAccountEnabled && !iban
                        ? 'setUpBankAccountExplanationWithAlternative'
                        : 'setUpBankAccountExplanation'
                    }
                    components={{
                      press: <BankAlternativeButton onClick={() => setShowAlternativeBankModal(true)} />
                    }}
                  />
                </BankCardText>
              </>
            )}
          </BankCardContent>
        ) : null}
        {iban && !enableSmeBankPromo ? (
          <>
            <BankAccountExplanationText>{t('bankAccountExplanationText')}</BankAccountExplanationText>
            <IbanInput
              absoluteMessage
              onChange={() => {}}
              placeholder={t('iban')}
              name="iban"
              value={iban}
              disabled
              required
            />
          </>
        ) : enableSmeBankPromo ? (
          renderButton()
        ) : (
          <BankCardPrimaryButton variant="Filled" onClick={handleBankAccountSetup}>
            {t('add')}
          </BankCardPrimaryButton>
        )}
      </BankPaperCard>
    </>
  );
};

export default BankCard;
