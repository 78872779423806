import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Section = styled(Column)`
  gap: 0.125rem;
`;

export const Sections = styled(Column)`
  gap: 1.5rem;
  height: 100%;
`;

export const SideNavigationContainer = styled(Column)`
  padding: 1.5rem;
  gap: 1rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: 0;
  }
`;

type SideNavigationMenuItemContentProps = {
  icon?: string;
};

export const SideNavigationMenuItemContent = styled.span<SideNavigationMenuItemContentProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  &::before {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    mask-image: ${({ icon }) => `url("${icon}")`};
    background-color: ${({ theme }) => theme.colors.base.black.shades[70]};
    transition: background-color 0.15s ease-out;
    display: flex;
    align-self: baseline;
  }
`;

export const SideNavigationMenuItem = styled(NavLink)`
  border-radius: 0.75rem;
  padding: 0.5rem;
  min-height: 2.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.base.black.shades[70]};
  font-size: 0.8125rem;
  line-height: 1.375rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: 0.0081rem;
  transition:
    background-color 0.15s ease-out,
    color 0.15s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.base.black.shades[8]};
    color: ${({ theme }) => theme.colors.base.black.shades[90]};

    ${SideNavigationMenuItemContent}::before {
      background-color: ${({ theme }) => theme.colors.base.black.shades[90]};
    }
  }

  &.active {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    background-color: ${({ theme }) => theme.colors.base.black.shades[8]};
    color: ${({ theme }) => theme.colors.base.black.shades[90]};

    ${SideNavigationMenuItemContent}::before {
      background-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
    }
  }
`;

export const SettingsMenuItem = styled(SideNavigationMenuItem)`
  margin-top: auto;
`;

export const SettingsMenuItemWrapper = styled(SideNavigationMenuItem).attrs({ as: 'label' })<{
  htmlFor: string;
}>`
  cursor: pointer;
  margin-top: auto;
`;

export const SectionTitle = styled.span`
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: 0.0375rem;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
  text-transform: uppercase;
`;

export const BottomActions = styled(Column)`
  gap: 0.125rem;
  margin-top: auto;

  @media ${({ theme }) => theme.media.maxTablet} {
    display: none;
  }
`;
