import { FC, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import { setLoginWarning } from 'features/app/slice';
import { selectIsUserAuthenticated, selectIsUserPartiallyAuthenticated } from 'features/auth/selectors';
import { logoutAsync } from 'features/auth/slice';
import { useAuthSelector, useAppDispatch } from 'hooks/redux/hooks';
import { refreshAuthLogic } from 'services/RestClient';

import { SessionTimeoutModal } from './sessionTimeouModal';

const TIMEOUT_IN_MINS = VARIABLES.logoutTimeoutMins as number;
const SESSION_TOKEN_TTL_MINS = 5;

const MINUTE_IN_MILISECONDS = 1000 * 60;

export const SessionTimeout: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const refreshInterval = useRef(0);

  const isUserAuthenticated = useAuthSelector<typeof selectIsUserAuthenticated>(selectIsUserAuthenticated);
  const isUserPartiallyAuthenticated = useAuthSelector<typeof selectIsUserPartiallyAuthenticated>(
    selectIsUserPartiallyAuthenticated
  );

  const isAuthenticated = isUserAuthenticated || isUserPartiallyAuthenticated;

  //display prompt
  const timeout = MINUTE_IN_MILISECONDS * TIMEOUT_IN_MINS;

  //prompt active before on idle is called
  const promptTimeout = MINUTE_IN_MILISECONDS;

  //refresh token every n minutes as long as user is active (onAction is called with refreshTokenTimeout interval)
  const refreshTokenTimeout = MINUTE_IN_MILISECONDS * SESSION_TOKEN_TTL_MINS;
  const [isOpen, setIsOpen] = useState(false);

  // called after "timeout" time has passed as long as user is NOT active
  const onPrompt = () => {
    setIsOpen(true);
  };

  //called after "timeout" + "promptTimeout" time has passed and user has not given any input
  const onIdle = () => {
    setIsOpen(false);
    logout();
  };

  //called in interval with time "refreshTokenTimeout" between calls as long as user is active
  const onAction = () => {
    if (!isPrompted()) refreshAuthLogic(null);
  };

  const { start, isPrompted } = useIdleTimer({
    onPrompt,
    onIdle,
    promptTimeout,
    timeout,
    stopOnIdle: true,
    startManually: true
  });

  const handleStillHere = () => {
    setIsOpen(false);
    start();
  };

  const logout = () => {
    setIsOpen(false);
    navigate('/login');
    dispatch(logoutAsync());
    dispatch(setLoginWarning({ header: 'sessionTimeoutMessage', message: '' }));
  };

  useEffect(() => {
    start();

    if (!refreshInterval.current) {
      refreshInterval.current = setInterval(() => {
        onAction();
      }, refreshTokenTimeout) as unknown as number;
    }

    return () => {
      refreshInterval.current && clearInterval(refreshInterval.current);
      refreshInterval.current = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, refreshTokenTimeout, start]);

  return (
    <SessionTimeoutModal timeout={promptTimeout} logout={logout} extend={handleStillHere} isOpen={isOpen} />
  );
};
