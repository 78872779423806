import styled from 'styled-components';

import plus from '../../assets/icons/plus.svg';
import { Typography } from '../typography';

export const AddButtonLabel = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })`
  color: ${({ theme }) => theme.colors.base.primary.shades['100']};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.1s ease-out;
`;

export const AddButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.colors.base.primary.shades['10']};
  border-radius: 50%;
  padding: 0.5rem;
  position: relative;
  transition: all 0.1s ease-out;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    transition: all 0.1s ease-out;
    top: 0.5rem;
    left: 0.5rem;
    background-color: ${({ theme }) => theme.colors.base.primary.shades['100']};
    mask-image: ${() => `url("${plus}")`};
  }
`;

export const AddButtonCard = styled.button`
  display: flex;
  border-radius: 1.5rem;
  padding: 1rem 0.875rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  width: 100%;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-out;

  &:hover {
    ${AddButtonIcon} {
      background-color: ${({ theme }) => theme.colors.base.primary.tints['20']};

      &::before {
        background-color: ${({ theme }) => theme.colors.base.primary.shades['20']};
      }
    }

    ${AddButtonLabel} {
      color: ${({ theme }) => theme.colors.base.primary.shades['20']};
    }
  }
`;
