import styled from 'styled-components';

import { BlueCheckmark } from '../checkbox';

export const SearchListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: hidden;
`;

export const CountryLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.75rem;

  ${BlueCheckmark} {
    visibility: hidden;
  }

  &:has(${BlueCheckmark}:checked) {
    ${BlueCheckmark} {
      visibility: visible;
    }

    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.base.black.tints[5]};
  }
`;

export type CountryFlagProps = {
  size?: 'S' | 'M' | 'L';
};

export const CountryFlag = styled.span<CountryFlagProps>`
  font-size: ${({ size }) => (size === 'S' ? '1.25rem' : '1.6663rem')};
  border-radius: 100%;
`;

export const CountryFlagContainer = styled.div<CountryFlagProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: ${({ size }) => (size === 'S' ? '1.25rem' : '2rem')};
  height: 2rem;
`;

export const CountryDisplayName = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: inline-flex;
`;

export const ScrollableList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  overscroll-behavior: contain;
`;
