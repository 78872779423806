import { TFunction } from 'i18next';
import { FieldError } from 'react-hook-form';

import { PaymentDetails } from 'services/CapService/types';
import { DefaultConfig } from 'services/RemoteConfigService/RemoteConfigService';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

type Limit = {
  min: (term?: string | number) => number | undefined;
  max: (term?: string | number) => number | undefined;
  default: number;
};

type LimitFields = keyof Omit<
  PaymentDetails,
  | 'leasingFormLink'
  | 'uniqueId'
  | 'monthlyPayment'
  | 'advancePayment'
  | 'residualValue'
  | 'monthlyAdministrationFee'
>;

export type FormLimits = Record<LimitFields, Limit>;

type LeasingDefaultConfigValues = DefaultConfig['leasingCalculatorInfo'];

export const getFormLimits = (defaultConfigValues: LeasingDefaultConfigValues): FormLimits => {
  const { propertyPrice, term, advancePaymentPercentage } = defaultConfigValues.genericFields;

  return {
    propertyPrice: {
      min: () => propertyPrice.min,
      max: () => propertyPrice.max,
      default: propertyPrice.default
    },
    term: {
      min: () => term.min,
      max: () => term.max,
      default: term.default
    },
    advancePaymentPercentage: {
      min: () => advancePaymentPercentage.min,
      max: () => advancePaymentPercentage.max,
      default: advancePaymentPercentage.default
    },
    residualValuePercentage: {
      min: () => 0,
      max: (term) => {
        const termNumber = Number(term);

        if (!termNumber) {
          return 60;
        }

        const rangeKeys = Object.keys(defaultConfigValues.residualValueCeiling).map(Number);

        const maxPercentage = rangeKeys.find((key) => {
          const range = defaultConfigValues.residualValueCeiling[key];

          if (termNumber && termNumber >= range.min && (!range.max || termNumber <= range.max)) {
            return Number(range.max ?? 0);
          }
        });

        return maxPercentage ?? 0;
      },
      default: 60
    }
  };
};

type PartialFieldMeta = {
  isTouched: boolean;
  error?: FieldError;
  message?: string;
  isDirty: boolean;
  showValidationMessage: boolean;
};

type FormFields = keyof PaymentDetails;

export type ValidatorReturnType = Partial<Record<FormFields, PartialFieldMeta>>;

const isMore = (value: string | number, limit: number | undefined) => {
  return !isNaN(Number(limit)) && Number(value) > Number(limit);
};

const isLess = (value: string | number, limit: number | undefined) => {
  return !isNaN(Number(limit)) && Number(value) < Number(limit);
};

type ValidatePropertyMessages = 'minAmountExceeded' | 'maxAmountExceeded' | 'valueRequired' | '';

function validateProperty(
  value: string | number,
  formLimits: FormLimits,
  fieldName: LimitFields,
  term?: string | number
): ValidatePropertyMessages {
  const minVal = formLimits[fieldName].min(term);
  const maxVal = formLimits[fieldName].max(term);

  if (isLess(value, minVal)) {
    return 'minAmountExceeded';
  }

  if (isMore(value, maxVal)) {
    return 'maxAmountExceeded';
  }

  if (value !== 0 && !value) {
    return 'valueRequired';
  }

  return '';
}

function validateTerm(values: PaymentDetails, formLimits: FormLimits) {
  const messages: Record<ValidatePropertyMessages, string | undefined> = {
    minAmountExceeded: VALIDATION_MESSAGE_ID.TERM_TO_SHORT,
    maxAmountExceeded: VALIDATION_MESSAGE_ID.TERM_TO_LONG,
    valueRequired: VALIDATION_MESSAGE_ID.REQUIRED,
    '': undefined
  };

  return messages[validateProperty(values.term, formLimits, 'term')];
}

const messages: Record<ValidatePropertyMessages, string | undefined> = {
  minAmountExceeded: VALIDATION_MESSAGE_ID.MIN_AMOUNT,
  maxAmountExceeded: VALIDATION_MESSAGE_ID.MAX_AMOUNT,
  valueRequired: VALIDATION_MESSAGE_ID.REQUIRED,
  '': undefined
};

function validatePropertyPrice(values: PaymentDetails, formLimits: FormLimits) {
  return messages[validateProperty(values.propertyPrice, formLimits, 'propertyPrice')];
}

function validateAdvance(values: PaymentDetails, formLimits: FormLimits) {
  return messages[validateProperty(values.advancePaymentPercentage, formLimits, 'advancePaymentPercentage')];
}

function validateResidual(values: PaymentDetails, formLimits: FormLimits) {
  return messages[
    validateProperty(values.residualValuePercentage, formLimits, 'residualValuePercentage', values.term)
  ];
}

const errors: Partial<Record<FormFields, string>> = {};

export const validator = (t: TFunction, defaultLeasingCalculatorInfo: LeasingDefaultConfigValues) => {
  const formLimits = getFormLimits(defaultLeasingCalculatorInfo);

  const mapToFieldErrors = (errors: Partial<Record<FormFields, string>>): ValidatorReturnType =>
    Object.entries(errors).reduce((acc, [key, value]) => {
      if (value) {
        acc[key as FormFields] = {
          isTouched: true,
          isDirty: true,
          showValidationMessage: true,
          error: { type: 'value', message: value },
          message: t(value)
        };
      }

      return acc;
    }, {} as ValidatorReturnType);

  return (values: PaymentDetails, fieldName?: FormFields): ValidatorReturnType => {
    if (fieldName) {
      switch (fieldName) {
        case 'propertyPrice':
          errors.propertyPrice = validatePropertyPrice(values, formLimits);
          break;
        case 'term':
          errors.term = validateTerm(values, formLimits);
          break;
        case 'advancePaymentPercentage':
          errors.advancePaymentPercentage = validateAdvance(values, formLimits);
          break;
        case 'residualValuePercentage':
          errors.residualValuePercentage = validateResidual(values, formLimits);
          break;
        default:
          break;
      }
    } else {
      errors.propertyPrice = validatePropertyPrice(values, formLimits);
      errors.term = validateTerm(values, formLimits);
      errors.advancePaymentPercentage = validateAdvance(values, formLimits);
      errors.residualValuePercentage = validateResidual(values, formLimits);
    }

    return mapToFieldErrors(errors);
  };
};
