import { RemoteConfig } from 'firebase/remote-config';
import { useCallback } from 'react';

import { checkIsFeatureEnabled } from 'defaultConfiguration/isFeatureEnabled';

export const useIsFeatureEnabled = (remoteConfig: RemoteConfig | null) => {
  const isInTestEnv = process.env.NODE_ENV === 'test';

  const isFeatureEnabled = useCallback(checkIsFeatureEnabled(remoteConfig), [remoteConfig]);

  if (isInTestEnv) {
    /**
     * for running tests in node enviroments resolve default configuration
     */
    return (keyName: string) => !!keyName;
  }

  return isFeatureEnabled;
};
