import styled from 'styled-components';

import { HeaderRow } from 'components/page/page.styles';

export const HeaderWrapper = styled(HeaderRow)`
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 0.5rem;
  }
`;

export const Header = styled.header`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  span {
    color: ${({ theme }) => theme.colors.base.primary.shades['100']};
  }
`;

export const HeaderShimmer = styled.div`
  width: 100%;
  border-radius: 6.25rem;
  background-color: ${(props) => props.theme.colors.formElement.background};
  height: 2.25rem;
  animation: fade-out 1s infinite;
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 0.5rem;
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
`;
