import styled, { css } from 'styled-components';

import magnifyingGlass from '../../assets/icons/magnificationGlass.svg';
import { AppTheme } from '../../design/styled-components';
import { ErrorMessage } from '../formError/errors.styles';
import { HelpText } from '../text/text.styles';

type Props = {
  dense?: boolean;
  invalidStatus?: boolean;
  hasActions?: boolean;
  animatePlaceholder?: boolean;
  inputSize?: 'S' | 'M' | 'L';
};

type LabelProps = {
  dense?: boolean;
  required?: boolean;
  hasValue?: boolean;
  textArea?: boolean;
  animatePlaceholder?: boolean;
} & Pick<Props, 'inputSize'>;

type ContainerProps = {
  hasPlaceholder?: boolean;
};

const getInputHeight = ({ inputSize }: Props) => {
  switch (inputSize) {
    case 'S':
      return '2rem';
    case 'L':
      return '3.5rem';
    case 'M':
    default:
      return '3rem';
  }
};

const getInputFontSize = ({ inputSize, hasValue }: LabelProps) => {
  if (hasValue) {
    return '0.75rem';
  }

  switch (inputSize) {
    case 'S':
      return '0.875rem';
    case 'L':
    case 'M':
    default:
      return '1rem';
  }
};

const getInputPaddingRight = ({ hasActions, inputSize }: Props) => {
  if (inputSize === 'L') {
    return hasActions ? '3.125rem' : '0.875rem';
  }

  return hasActions ? '2.875rem' : '1rem';
};

const inputPaddingCss = css<Props>`
  padding: 1.5rem 2.875rem 0.5rem 1rem;
  padding-right: ${getInputPaddingRight};
`;

export const PlaceholderLabel = styled.label<LabelProps>`
  color: ${({ theme }) => theme.colors.primary.inputPlaceholderColor};
  font-size: ${getInputFontSize};
  position: absolute;
  left: 1rem;
  top: ${({ textArea }) => (textArea ? '0.5rem' : 0)};
  display: inline-flex;
  flex-direction: column;
  height: ${({ textArea }) => (textArea ? 'auto' : '100%')};
  justify-content: center;
  pointer-events: none;
`;

export const AditionalPlaceholderLabel = styled(PlaceholderLabel)`
  padding: 1.5rem 2.875rem 0.5rem 0;
`;

export const TextAreaPlaceholderLabel = styled(PlaceholderLabel)`
  width: calc(100% - 2rem);
  white-space: nowrap;
  overflow: hidden;
`;

export const ElipsisTruncatedText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const placeholderFocusCss = css<Props & ContainerProps>`
  box-shadow: none;
  ${inputPaddingCss}
  & + ${PlaceholderLabel} {
    color: ${({ invalidStatus, theme }) =>
      invalidStatus ? theme.colors.primary.inputPlaceholderColor : theme.colors.base.primary.shades[100]};
    transform-origin: 0% 50%;
    transform: translateY(-0.75rem) scale(0.75);
    ${({ animatePlaceholder }) =>
      animatePlaceholder
        ? css`
            transition: transform 0.15s ease-in-out;
          `
        : ''};
  }
`;

const placeholderNotShownCss = css<Props>`
  ${inputPaddingCss}
  & + ${PlaceholderLabel} {
    transform-origin: 0% 50%;
    transform: translateY(-0.75rem) scale(0.75);
  }
`;

export const getBorderRestColor = ({ invalidStatus, theme }: Props & AppTheme) =>
  invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10];

export const getBorderHoverColor = ({ invalidStatus, theme }: Props & AppTheme) =>
  invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[90];

export const getBorderFocusColor = ({ invalidStatus, theme }: Props & AppTheme) =>
  invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.primary.shades[100];

export const inputCss = css<Props>`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.base.white.functional};
  ${inputPaddingCss}
  border: 0.0625rem ${getBorderRestColor} solid;
  border-radius: 0.625rem;
  line-height: 1.5rem;
  font-size: ${({ theme }) => theme.fontSizes.h4};
  transition: border 0.15s ease-in-out;
  &::placeholder {
    color: transparent;
  }
  &:not(:disabled) {
    &:hover {
      border: 0.0625rem solid ${getBorderHoverColor};
    }
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
  &:not(:disabled) {
    &:focus-visible {
      outline: none;
      border: 0.0625rem solid ${getBorderFocusColor};

      &:hover {
        border: 0.0625rem solid ${getBorderFocusColor};

        & + ${PlaceholderLabel} {
          color: ${({ invalidStatus, theme }) =>
            invalidStatus
              ? theme.colors.primary.inputPlaceholderColor
              : theme.colors.base.primary.shades[100]};
        }
      }
    }
  }
  &:not(:disabled) {
    &:active {
      border: 0.0625rem solid ${getBorderFocusColor};
    }
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.base.black.tints[5]};
    border: 0.0625rem solid ${({ theme }) => theme.colors.base.black.tints[10]};
    color: ${({ theme }) => theme.colors.base.black.tints[40]};

    & + ${PlaceholderLabel} {
      color: ${({ theme }) => theme.colors.base.black.tints[30]};
    }
  }
`;

export const TextAreaStyle = styled.textarea<Props>`
  min-height: 4.5rem;
  height: 100%;
  resize: none;
  box-sizing: border-box;
  width: calc(100% - 0.125rem);
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  border-top: 1.25rem solid transparent;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  outline: 1px solid ${getBorderRestColor};
  outline-offset: -1px;
  padding: 0;
  border-radius: 0.625rem;
  transform: translateY(0);

  &::placeholder {
    color: transparent;
  }

  &:hover {
    outline: 1px solid ${getBorderHoverColor};
  }

  &:focus {
    outline: 1px solid ${getBorderFocusColor};
  }

  &:active {
    outline: 1px solid ${getBorderFocusColor};
  }

  &:not(:disabled) {
    &:focus {
      & + ${TextAreaPlaceholderLabel} {
        color: ${({ invalidStatus, theme }) =>
          invalidStatus ? theme.colors.primary.inputPlaceholderColor : theme.colors.base.primary.shades[100]};
        transform-origin: 0% 50%;
        transform: translateY(-0.25rem);
        font-size: 12px;
        transition:
          font-size 0.15s ease-out,
          transform 0.15s ease-out;
      }
    }
  }

  &:not(:placeholder-shown) {
    & + ${TextAreaPlaceholderLabel} {
      font-size: 1rem;
      transform: translateY(-0.25rem);
      font-size: 12px;
      transform-origin: 0% 50%;
    }
  }
`;

type InputActionsProps = {
  offsetMiddleAction?: boolean;
  offsetHelpPopup?: boolean;
};

const getHelpPopupOffset = ({ offsetHelpPopup }: InputActionsProps) =>
  offsetHelpPopup ? '2.625rem' : '0.625rem';

const getMiddelActionOffset = ({ offsetMiddleAction, offsetHelpPopup }: InputActionsProps) => {
  if (offsetMiddleAction) {
    return offsetHelpPopup ? '4.875rem' : getHelpPopupOffset({ offsetHelpPopup: true });
  }

  return getHelpPopupOffset({ offsetHelpPopup });
};

export const InputActions = styled.div<InputActionsProps>`
  position: absolute;
  right: ${getMiddelActionOffset};
  top: 0;
  visibility: hidden;
  opacity: 0;
  z-index: ${({ theme }) => theme.layers.helpPopup};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 1.75rem;
  transition: opacity 0.15s ease-in-out;
`;

export const Action = styled.div`
  z-index: ${({ theme }) => theme.layers.dropdown};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};

  svg {
    path {
      fill: ${({ theme }) => theme.colors.base.black.shades[50]};
      transition: fill 0.15s ease-in-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.base.black.shades[70]};
      }
    }
  }
`;

export const AdditionalActions = styled.div<InputActionsProps>`
  position: absolute;
  right: ${getHelpPopupOffset};
  top: 0;
  z-index: ${({ theme }) => theme.layers.dropdown};
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 1.5rem;

  ${Action} {
    display: flex;
  }
`;

export const InputStyle = styled.input<Props>`
  height: ${getInputHeight};
  ${inputCss}

  &.clear-action {
    box-shadow: none;
    ${inputPaddingCss}
    & + ${PlaceholderLabel} {
      color: ${({ invalidStatus, theme }) =>
        invalidStatus ? theme.colors.primary.inputPlaceholderColor : theme.colors.base.primary.shades[100]};
      transform-origin: 0% 50%;
      transform: translateY(-0.75rem) scale(0.75);
    }
  }

  &:not(:placeholder-shown) {
    ${placeholderNotShownCss}
  }

  &:not(.clear-action) {
    &:not(:disabled) {
      &:focus {
        ${placeholderFocusCss}
      }
    }
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export const InputLabel = styled.span`
  padding: 0 0.625rem;
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const InputContainer = styled.div<ContainerProps>`
  position: relative;
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px white inset !important;
  }
  ${ErrorMessage} {
    padding-top: 0.25rem;
  }
  ${InputStyle} {
    ${({ hasPlaceholder }) =>
      !hasPlaceholder &&
      css`
        padding: 1rem !important;
      `}
  }
`;

const getSuffixTopPositionBySize = ({ inputSize }: Props) => (inputSize === 'L' ? '1.125rem' : '1rem');

export const SuffixLabel = styled.span<Props>`
  color: ${({ theme }) => theme.colors.secondary.textColor};
  position: absolute;
  right: 1.25rem;
  top: ${getSuffixTopPositionBySize};
  z-index: 999;
`;

export type InputMessageProps = {
  absoluteMessage?: boolean;
};

export const InputMessagesContainer = styled.div<InputMessageProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 1rem;

  ${ErrorMessage} {
    display: inline-flex;
    position: ${({ absoluteMessage }) => (absoluteMessage ? 'absolute' : 'relative')};
  }

  ${HelpText} {
    display: inline-flex;
    padding-top: 0.25rem;
  }

  ${HelpText} {
    & + ${ErrorMessage} {
      padding-top: 0.25rem;
    }
  }
`;

type WrapperProps = {
  showRemoveButton?: boolean;
};

export const InputWrapper = styled.label<WrapperProps>`
  display: flex;
  position: relative;

  ${InputStyle} {
    & + ${PlaceholderLabel} {
    }
  }

  ${InputStyle}:not(:disabled):focus {
    & + ${PlaceholderLabel} + ${InputActions} {
      ${({ showRemoveButton }) =>
        showRemoveButton
          ? css`
              visibility: visible;
              opacity: 1;
            `
          : ''}
    }
  }
`;

export const InfoPopupWrapper = styled.div`
  position: absolute;
  right: 0.625rem;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 1.5rem;
  align-items: center;
  justify-content: center;
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${ErrorMessage} {
    padding-top: 0;
    padding-left: 1rem;
  }
`;

export const SearchInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;

  ${PlaceholderLabel} {
    left: 2.5rem;
    transition: left 0.15s ease-out;

    ::after {
      content: '';
      position: absolute;
      left: -1.75rem;
      width: 1.5rem;
      height: 1.5rem;
      mask-image: ${() => `url("${magnifyingGlass}")`};
      background-color: ${({ theme }) => theme.colors.base.black.tints[70]};
    }
  }

  &:focus-within {
    ${PlaceholderLabel} {
      ::after {
        background-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
      }
    }
  }
`;

export const AsyncSearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  gap: 0.25rem;

  ${InputContainer} {
    margin-bottom: 0 !important;
  }
`;

export const DropdownAnchor = styled.div``;

export const HiddenArea = styled.div`
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  visibility: hidden;
  position: absolute;
  padding: 1.25rem 2.875rem 0.5rem 1rem;
`;
