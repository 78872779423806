import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppLocation } from 'hooks/router/useAppLocation';

/**
 * Contains keys of fields with potential sensitive data (GDPR)
 * These fields will be always removed from query string to prevent storing them in analytics
 */
const blacklistedKeys = [
  'requiredAmount',
  'registrationCode',
  'companyName',
  'activeProduct',
  'country',
  'productSelect',
  'subProductSelect',
  'name',
  'surname',
  'email',
  'phone',
  'privacyAgreement',
  'dealId',
  'term',
  'purpose',
  'other',
  'companyId',
  'personId'
];

export type QueryPayload = Partial<{
  requiredAmount: string;
  registrationCode: string;
  companyName: string;
  activeProduct: string;
  country: string;
  productSelect: string;
  subProductSelect: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  privacyAgreement: string;
  dealId: string;
  term: string;
  purpose: string;
  other: string;
  partner: string;
  partnerType: string;
  telem: string;
  product: string;
  subproduct: string;
  newcompany: string;
  [key: string]: unknown;
}>;

export const useQueryFields = (): [QueryPayload, string | undefined] => {
  const location = useAppLocation();

  const { state } = location || {};
  const { originalLocation } = state || {};
  const { search } = originalLocation || {};

  const [searchParams, setSearchParams] = useSearchParams(search);

  const memoizedPayload = useMemo(() => Object.fromEntries(searchParams.entries()), [search]) as QueryPayload;

  useEffect(() => {
    //deletes blacklisted keys from query string
    blacklistedKeys.forEach((key) => {
      searchParams.delete(key);
    });

    //updates query string leaving only whitelisted keys
    setSearchParams(searchParams);
  }, [searchParams]);

  return [memoizedPayload, search];
};
