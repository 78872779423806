export const toggleAsideMenu = (isOpen: boolean) => {
  const asideMenu = document.querySelector('aside#overlay-aside-menu');

  if (asideMenu?.classList.contains('open') !== isOpen) {
    if (asideMenu) {
      const hasOpened = asideMenu.classList.toggle('open');

      if (!hasOpened) {
        asideMenu.classList.toggle('close');
      }

      setTimeout(() => {
        window.requestAnimationFrame(() => {
          asideMenu.classList.remove('close');
        });
      }, 200);
    }
  }
};

export const getOpenDrawersList = () => {
  const openDrawers = document.querySelectorAll('aside.open');

  return Array.from(openDrawers);
};

export const getHighestActiveDrawerIndex = () => {
  const openDrawers = getOpenDrawersList();

  return openDrawers.reduce((acc, drawer) => {
    const index = parseInt(drawer.getAttribute('data-active-index') ?? '0', 10);

    return index > acc ? index : acc;
  }, 0);
};
