import { FC, useEffect, useId, useState } from 'react';

import ChevronDown from '../../assets/icons/chevronDown.svg';
import ChevronUp from '../../assets/icons/chevronUp.svg';
import MagnifyingGlass from '../../assets/icons/magnifyingGlassWithPlus.svg';
import Minus from '../../assets/icons/minus.svg';
import Plus from '../../assets/icons/plus.svg';

import {
  DocumentControls,
  ControlsGroup,
  ControlValue,
  ControlButton,
  ControlInput,
  StyleProps
} from './filePreview.styles';

type Props = {
  numPages: number;
  currentPage: number;
  currentZoom: number;
  onPageChange: (page: number) => unknown;
  onZoom: (scale: number) => unknown;
  hasZoomControlsVisible?: boolean;
  hasPageControlsVisible?: boolean;
} & StyleProps;

export const PreviewControls: FC<Props> = ({
  numPages,
  currentPage,
  currentZoom,
  onPageChange,
  onZoom,
  lightMode,
  hasZoomControlsVisible = true,
  hasPageControlsVisible = true
}) => {
  const uniqueId = useId();

  const [selectedPage, setSelectedPage] = useState(currentPage);

  const increaseZoom = () => onZoom(currentZoom < 2 ? (currentZoom * 100 + 5) / 100 : 2);
  const decreaseZoom = () => onZoom(currentZoom > 0.5 ? (currentZoom * 100 - 5) / 100 : 0.5);
  const resetZoom = () => onZoom(1);

  const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);

    if (value > numPages) {
      setSelectedPage(currentPage);
      return;
    }

    if (value < 0 || value === 0) {
      setSelectedPage(currentPage);
      return;
    }

    setSelectedPage(value);
  };

  useEffect(() => {
    if (selectedPage > 0 && selectedPage <= numPages) {
      onPageChange(selectedPage);
    }
  }, [selectedPage]);

  useEffect(() => {
    setSelectedPage(currentPage);
  }, [currentPage]);

  const handleBlur = () => {
    if (!selectedPage || selectedPage < 1 || selectedPage > numPages) {
      setSelectedPage(currentPage);
    }
  };

  if (hasPageControlsVisible || hasZoomControlsVisible) {
    return (
      <DocumentControls onClick={(e) => e.stopPropagation()} lightMode={lightMode}>
        {hasPageControlsVisible ? (
          <ControlsGroup id={`page-controls-${uniqueId}`}>
            <ControlButton
              disabled={numPages === 1 || currentPage === 1}
              icon={ChevronUp}
              onClick={() => onPageChange(currentPage - 1 || 1)}
            />
            <ControlValue>
              <ControlInput value={selectedPage} onBlur={handleBlur} onChange={handlePageChange} />
              {` / ${numPages}`}
            </ControlValue>
            <ControlButton
              disabled={numPages === 1 || currentPage === numPages}
              icon={ChevronDown}
              onClick={() => onPageChange(currentPage + 1 < numPages ? currentPage + 1 : numPages)}
            />
          </ControlsGroup>
        ) : null}
        {hasZoomControlsVisible ? (
          <ControlsGroup id={`zoom-controls-${uniqueId}`}>
            <ControlButton disabled={currentZoom === 0.5} icon={Minus} onClick={decreaseZoom} />
            <ControlButton icon={MagnifyingGlass} onClick={resetZoom} />
            <ControlButton disabled={currentZoom === 2} icon={Plus} onClick={increaseZoom} />
          </ControlsGroup>
        ) : null}
      </DocumentControls>
    );
  }

  return null;
};
