import { CountryOfCompanyRegistration } from 'features/application/forms/company/constants';
import { Type } from 'services/CapService/types';

export type ProductsInfo = Record<CountryOfCompanyRegistration, Type[]>;

export const defaultConfig: ProductsInfo = {
  LT: ['FACTORING', 'LOAN', 'CREDIT_LINE', 'LEASING', 'RBF'],
  LV: ['FACTORING', 'LOAN', 'CREDIT_LINE', 'RBF'],
  EE: ['FACTORING', 'LOAN', 'CREDIT_LINE', 'LEASING', 'RBF'],
  FI: ['LOAN', 'CREDIT_LINE'],
  NL: ['LOAN', 'CREDIT_LINE']
};
