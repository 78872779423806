import styled from 'styled-components';

export const MobileDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 1rem 1.5rem;
`;

type MobileDrawerProps = {
  direction: 'left' | 'right';
};

export const MobileDrawer = styled.aside<MobileDrawerProps>`
  width: 100dvw;
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  position: fixed;
  height: calc(100dvh - var(--spa-globals-top-banner-height));
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};
  overscroll-behavior: contain;
  left: ${({ direction }) => (direction === 'left' ? '-101vw' : '101vw')};
  z-index: ${({ theme }) => theme.layers.overlay};

  > ${MobileDrawerContent} {
    display: none;
  }

  &.open {
    width: 100%;
    left: 0;
    animation: ${({ direction }) => (direction === 'left' ? 'slideinfromright' : 'slideinfromleft')} 150ms
      ease-out forwards;

    > ${MobileDrawerContent} {
      display: flex;
    }
  }

  @keyframes slideinfromleft {
    from {
      left: 101vw;
    }

    to {
      left: 0;
    }
  }

  @keyframes slideinfromright {
    from {
      left: -101vw;
    }

    to {
      left: 0;
    }
  }
`;
