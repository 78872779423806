import styled from 'styled-components';

import { Typography } from 'components';

export const GrayOverlay = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary.background};
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.layers.overlay};
  flex-direction: column;
  flex-grow: 1;
`;

export const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3.75rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3.75rem;
`;

export const LanguagesDropdownListContainer = styled.div`
  position: absolute;
  width: calc(100% + 2rem);
  top: calc(100% + 1.5rem);
  left: -1rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  padding: 0.5rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.primary.border.light};
`;

export const SectionHeader = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export const SectionCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 1.5rem;
  justify-content: space-between;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2.5rem;

  &:empty {
    display: none;
  }
`;
