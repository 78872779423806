import { MouseEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import close from 'assets/icons/close.svg';
import { LinkButton } from 'components';
import Typography from 'components/typography/typography';

import {
  BannerBody,
  BannerContainer,
  BannerHeader,
  CloseButtonPlaceHolder,
  SmeGoLogo,
  ScanQRCodeMessage,
  SmeGoButton,
  BannerOverlay
} from './smeGoAppBanner.styles';
import smeGoQRCode from './smeGoQRCode.png';

type Props = {
  className?: string;
  isOpen?: boolean;
  onVisibilityChange?: (visible: boolean) => unknown;
};

const BannerCard = ({ onVisibilityChange }: Props) => {
  const { t } = useTranslation();

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.open('https://smego.page.link/VctC', '_blank');
    onVisibilityChange?.(false);
  };

  return (
    <BannerContainer>
      <BannerHeader>
        <CloseButtonPlaceHolder>
          <LinkButton
            iconOnly
            color="Black"
            icons={{ left: close }}
            onClick={() => onVisibilityChange?.(false)}
          />
        </CloseButtonPlaceHolder>
        <Typography.Header type="h3" weight="medium">
          {t('yourFinancingRequestRecieved')}
        </Typography.Header>
      </BannerHeader>
      <BannerBody>
        <img src={smeGoQRCode} alt="smeGO app" />
        <Typography.Header type="h3" weight="medium">
          {t('seamlesslyTrackApplicationProgress')}
        </Typography.Header>
        <ScanQRCodeMessage>{t('scanQRCodeSmeGo')}</ScanQRCodeMessage>
        <SmeGoButton onClick={onButtonClick} color="Black" size="L">
          {t('getTheApp')}
        </SmeGoButton>
        <SmeGoLogo />
      </BannerBody>
    </BannerContainer>
  );
};

export const SmeGoAppBanner = ({ isOpen, onVisibilityChange, className }: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const onOverlayClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === overlayRef.current) {
      onVisibilityChange?.(false);
    }
  };

  return (
    <ReactModal
      isOpen={Boolean(isOpen)}
      overlayElement={() => (
        <BannerOverlay
          ref={overlayRef}
          onMouseDown={onOverlayClick}
          className={className}
          variant="functional"
        >
          <BannerCard onVisibilityChange={onVisibilityChange} />
        </BannerOverlay>
      )}
    />
  );
};
