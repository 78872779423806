import { ReactNode, forwardRef, useId } from 'react';
import { createPortal } from 'react-dom';

import { ScreenSize } from 'components/layout';

import { TopBar } from '../topBar';

import { MobileDrawer, MobileDrawerContent } from './mobileView.styles';

type Props = {
  children: ReactNode;
  open?: boolean;
  onClose: () => unknown;
  direction?: 'left' | 'right';
  dataTestId?: string;
};

export const MobileView = forwardRef<HTMLDivElement, Props>(
  ({ children, open, onClose, direction = 'left', dataTestId }, ref) => {
    const className = open ? 'open' : 'close';

    const handleSelfClose = () => {
      setTimeout(() => {
        onClose();
      }, 150);
    };

    const key = useId();

    return createPortal(
      <ScreenSize data-testid={dataTestId} mobile tablet ref={ref}>
        <MobileDrawer className={className} direction={direction}>
          <TopBar onToggleAcionClick={onClose} variant="inner" alignment={direction} />
          <MobileDrawerContent onClick={handleSelfClose}>{children}</MobileDrawerContent>
        </MobileDrawer>
      </ScreenSize>,
      document.body,
      `mobile-view-${key}`
    ) as unknown as ReactNode;
  }
);
