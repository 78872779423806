import { FC, ReactElement } from 'react';

import { LinkButtonStyle } from '../link/link.styles';

import { StepStyles, StepperContainerStyles } from './stepper.styles';

type StepProps = {
  isActive?: boolean;
  isCompleted?: boolean;
};

const Step: React.FC<StepProps> = ({ isCompleted, ...rest }) => {
  return <StepStyles data-testid="stepper-label" isCompleted={isCompleted} {...rest}></StepStyles>;
};

type StepperLinkContainerProps = {
  onClick?: (args: unknown) => unknown;
  children: ReactElement;
};

const StepperLinkContainer: FC<StepperLinkContainerProps> = ({ children, onClick }) => {
  if (onClick) {
    return (
      <LinkButtonStyle data-testid="stepper-link" onClick={onClick}>
        {children}
      </LinkButtonStyle>
    );
  }

  return children;
};

export type Step = {
  label: string;
  isActive?: boolean;
  isCompleted?: boolean;
  onClick?: (args: unknown) => unknown;
};

type StepperProps = {
  steps: Step[];
};

export const Stepper: FC<StepperProps> = ({ steps }) => {
  return (
    <StepperContainerStyles data-testid="stepper-container">
      {steps?.map((step: Step) => {
        if (step.label) {
          return (
            <StepperLinkContainer key={step.label} onClick={step.onClick}>
              <Step isCompleted={step.isCompleted} isActive={step.isActive}></Step>
            </StepperLinkContainer>
          );
        }

        return null;
      })}
    </StepperContainerStyles>
  );
};
