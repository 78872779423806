import { CountryOfCompanyRegistration } from 'features/application/forms/company/constants';
import { Status } from 'features/applications/types/applicationsTypes';

export type OnboardingEnabledConfig = {
  includedCountries?: CountryOfCompanyRegistration[];
  ondatoAvailableCAPStatus: Status;
  ondatoAvailableCAPStatusForOtherCountries: Status;
  /**
   * M - application created manually via goCredits;
   * D - application created by customer via smeGo flow;
   */
  enabledForChannels?: ('M' | 'D')[];
};

export const onboardingParallelFlowDefaultConfig: OnboardingEnabledConfig = {
  includedCountries: ['LT', 'LV', 'EE', 'FI', 'NL'],
  ondatoAvailableCAPStatus: 'SUBMITTED_FOR_VOTING',
  ondatoAvailableCAPStatusForOtherCountries: 'OFFER_ACCEPTED',
  enabledForChannels: ['M', 'D']
};
