import styled from 'styled-components';

export const LimitChart = styled.div`
  margin-top: 2.25rem;
`;

export const LimitChartContainer = styled.div`
  margin: 0.625rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 1 100%;
`;

export const DonutChartContainer = styled.div`
  height: 15.625rem;
  width: 15.625rem;
  margin: 0 1.875rem;
  display: flex;
`;

export const TotalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
`;
