import styled from 'styled-components';

import { LinkButtonStyle, Typography } from 'components';
import { Toast } from 'components/toastV2';

type LoginContainerStyleProps = {
  dense?: boolean;
};

export const LoginContainerStyle = styled.div<LoginContainerStyleProps>`
  display: flex;
  flex-direction: column;
  max-width: ${({ dense }) => (dense ? '19rem' : '25.4375rem')};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const LoginLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100% - 10.25rem);
  width: 100%;
`;

export const ExplanationText = styled(Typography.Body).attrs({ type: 'M', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.shades[70]};
  margin-bottom: 2rem;
`;

export const LoginHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const LoginHeaderLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Bolded = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Header = styled(Typography.Header).attrs({ type: 'h2', weight: 'medium' })`
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  display: inline-flex;
  gap: 1.5rem;

  &:has(+ ${ExplanationText}) {
    margin-bottom: 0.5rem;
  }

  ${LinkButtonStyle} {
    margin-left: -3rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    flex-direction: column;
    justify-content: flex-start;

    ${LinkButtonStyle} {
      margin-left: 0;
    }
  }
`;

export const HeaderContent = styled.span`
  display: inline-flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const WarningToast = styled(Toast)`
  margin-bottom: ${({ isVisible }) => (isVisible ? '1rem' : 0)};
  transition: margin-bottom 0.14s ease-out;

  p {
    display: inline;
  }

  div:has(> p) {
    padding-bottom: 0;
  }
`;
