import { FC, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import { Table } from 'components';
import { FactoringStatementsService } from 'services';

import { BillsFilterParams } from '../../components';

import { BillsDetailsTableContainer } from './billsDetailsTable.style';
import { createMobileListRows, createTableColumns, TableRowItem } from './createTableColumns';

export const PAGE_SIZE = 10;

export type Sort = { dataColumnId?: string | number; sortOrder: SortOrder };

type Props = {
  items: TableRowItem[];
  filterParams: BillsFilterParams;
  activePage?: number;
  loading?: boolean;
  totalPages?: number;
  onSort?: (sortOder: Sort) => unknown;
  onChangePage?: (pageNumber: number) => unknown;
};

export const BillsDetailsTable: FC<Props> = ({
  items,
  loading,
  totalPages,
  activePage,
  onSort,
  onChangePage
}) => {
  const { t } = useTranslation();

  const [sortOrder, setSortOrder] = useState<{ dataColumnId?: string | number; sortOrder: SortOrder }>();

  const handleSort = (column: TableColumn<TableRowItem>, sortDirection: SortOrder) => {
    setSortOrder({ dataColumnId: column.id, sortOrder: sortDirection });
    onSort?.({ dataColumnId: column.id, sortOrder: sortDirection });
  };

  const handlePageChange = (pageNumber: number) => {
    onChangePage?.(pageNumber);
  };

  const downloadHandler = (actId?: string) => async () => {
    if (actId) {
      return FactoringStatementsService.getPaymentDocument({ actId });
    }
  };

  const columns = createTableColumns(t, downloadHandler);
  const mobileRows = createMobileListRows(t, downloadHandler);

  return (
    <BillsDetailsTableContainer>
      <Table<TableRowItem>
        persistNoDataContainerHeight
        pagination
        paginationPerPage={PAGE_SIZE}
        highlightOnHover
        sortedBy={sortOrder}
        activePage={activePage}
        noDataMessage={t('noListItems')}
        onSort={handleSort}
        progressPending={loading}
        columns={columns}
        mobileColumns={mobileRows}
        data={items}
        totalPages={totalPages}
        onChangePage={handlePageChange}
      />
    </BillsDetailsTableContainer>
  );
};
