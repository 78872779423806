import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, HeaderContainer, HeaderRow } from 'components/page/page.styles';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { selectActiveCustomer, selectIsUserGhost } from 'features/auth/selectors';
import { fetchDashboardInformationAsync } from 'features/bank/slice';
import { useFetchContractsTotals } from 'features/contracts/hooks';
import { selectHasProducts } from 'features/contracts/selectors';
import { useFetchLoansTotals } from 'features/loans/hooks';
import { OnboardingSuccessModalForExistingCustomer } from 'features/onboarding/components/onboardingSuccessModalForExistingCustomer/onboardingSuccessModalForExistingCustomer';
import { selectIsCompanyOnboardingNeeded } from 'features/onboarding/selectors';
import getTypedSearchParams from 'features/onboarding/utils/getTypedSearchParams';
import { useAppDispatch, useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import tracking from 'utils/tracking';

import { getFinanceCards } from './components/cards';
import { CardsSection } from './components/cardsSection';
import { CardContentSection } from './components/overview.styles';

export const Overview = () => {
  const { isFeatureEnabled } = useRemoteConfigContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const typedSearchParams = getTypedSearchParams();

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isGhostUser = useAuthSelector<typeof selectIsUserGhost>(selectIsUserGhost);
  const isOnboardingNeeded = useAppSelector(selectIsCompanyOnboardingNeeded);
  const hasLoansContracts = useAppSelector(selectHasProducts);

  const displayMarketingCard = !isOnboardingNeeded && !hasLoansContracts;

  const { onboardingSuccess } = typedSearchParams;

  useEffect(() => {
    if (typeof onboardingSuccess !== 'undefined') {
      tracking.setSubmitEvent('existing_customer_onboarding_flow_submitted');
      setIsSuccessModalVisible(true);
    }
  }, [onboardingSuccess]);

  useEffect(() => {
    if (isFeatureEnabled('bankInfo')) {
      dispatch(fetchDashboardInformationAsync());
    }
  }, [dispatch, activeCustomer?.customerId]);

  const { isFetching } = useFetchLoansTotals('CREDIT_LINE');
  const { isFetching: factoringTotalsIsFetching } = useFetchContractsTotals();

  const cardsLoading = isFetching || factoringTotalsIsFetching;

  const overviewHeaderLabel = t(isGhostUser ? 'welcomeToDashboardGhost' : 'welcomeToDashboardRegular', {
    companyName: activeCustomer?.customerName ?? ''
  });

  return (
    <>
      <OnboardingSuccessModalForExistingCustomer
        isOpen={isSuccessModalVisible}
        onVisibilityChange={() => setIsSuccessModalVisible(false)}
      />
      <HeaderRow style={{ paddingBottom: '1.5rem' }} centerVeritcally>
        <HeaderContainer>
          <Header>{overviewHeaderLabel}</Header>
        </HeaderContainer>
      </HeaderRow>
      <CardContentSection>
        <CardsSection cards={getFinanceCards(cardsLoading, displayMarketingCard)} />
      </CardContentSection>
    </>
  );
};
