import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyInputProps, TypographySizeProps } from '../typography';

import { formatNumber, formatPercentage } from './formatters';
import {
  InputViewContainer,
  InputViewContainerProps,
  InputViewLabel,
  InputViewLabelMobile,
  InputViewText,
  InputViewTextMobile,
  Percentage
} from './text.styles';

export type Currency = 'EUR' | 'USD';

type CurrencySymbols = Record<Currency, string>;

export const currencySymbols: CurrencySymbols = {
  EUR: String.fromCodePoint(8364),
  USD: String.fromCodePoint(36)
} as const;

type CurrencyAmountProps = {
  children?: string | number | null;
  currency?: Currency;
  className?: string;
};

export const AmountText: FC<CurrencyAmountProps> = ({ children, className, currency = 'EUR' }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  if (children === null || children === '' || typeof children === 'undefined' || isNaN(Number(children)))
    return <span>-</span>;

  // To behave inside jest tests environment cimode language is switched to default lt
  const resolvedLanguage = language === 'cimode' ? 'lt' : language;

  const formatter = formatNumber(resolvedLanguage, { style: 'currency', currency });

  return <span className={className}>{formatter(Number(children))}</span>;
};

export const PercentageText: FC<CurrencyAmountProps> = ({ children, className }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  if (children === null || children === '' || typeof children === 'undefined' || isNaN(Number(children)))
    return <span>-</span>;

  // To behave inside jest tests environment cimode language is switched to default lt
  const resolvedLanguage = language === 'cimode' ? 'lt' : language;

  const formatter = formatPercentage(resolvedLanguage);

  return <Percentage className={className}>{formatter(Number(children))}</Percentage>;
};

type InputViewProps = {
  label?: string;
  children?: ReactNode;
  className?: string;
  textWeight?: TypographyInputProps['weight'];
  sizeMobile?: TypographySizeProps['size'];
  dataTestId?: string;
} & InputViewContainerProps &
  TypographySizeProps;

export const InputView: FC<InputViewProps> = ({
  label,
  children,
  className,
  size,
  sizeMobile,
  orientation,
  orientationMobile,
  textWeight,
  dataTestId
}) => {
  const resolvedMobileSize = sizeMobile || size || 'S';
  const resolvedMobileOrientation = orientationMobile || orientation || 'horizontal';

  return (
    <InputViewContainer
      data-testid={dataTestId}
      orientation={orientation}
      orientationMobile={resolvedMobileOrientation}
      className={className}
    >
      <InputViewLabel data-testid={`input-view-label-${dataTestId}`} type={size}>
        {label}
      </InputViewLabel>
      <InputViewText data-testid={`input-view-value-${dataTestId}`} weight={textWeight} type={size}>
        {children}
      </InputViewText>
      <InputViewLabelMobile data-testid={`input-view-label-mobile-${dataTestId}`} type={resolvedMobileSize}>
        {label}
      </InputViewLabelMobile>
      <InputViewTextMobile
        data-testid={`input-view-value-mobile-${dataTestId}`}
        weight={textWeight}
        type={resolvedMobileSize}
      >
        {children}
      </InputViewTextMobile>
    </InputViewContainer>
  );
};
