import { isBefore, isSameDay, isAfter } from 'date-fns';
import { FC, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Icons from 'assets/icons';
import {
  AmountText,
  DefaultItem,
  DownloadLink,
  LinkButton,
  Paper,
  RegularText,
  Section,
  Table,
  transformDateToLocalizedString
} from 'components';
import {
  DataTypeSelector,
  DataTypeOption
} from 'features/factoring/components/filterPanel/filterPanel.styles';
import { useFetchSchedulesForSelectedContract } from 'features/loans/hooks';
import { selectLoansContractList } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';
import { ScheduleData } from 'services/LoansService/types';

import {
  HeaderText,
  LeftAllignedContent,
  MissingScheduleDataToast,
  SchedulesTableContainer,
  SchedulesTableHeader
} from './schedulesTable.styles';

type DataSource = 'future' | 'historic';
type TableRowItem = ScheduleData & DefaultItem;

export const withNullCheck = (value?: string | number) => (value || value === 0 ? value : '-');

const getSelectedData = (dataSource: DataSource) => (items: ScheduleData[]) => {
  if (dataSource === 'historic') {
    return items.filter(
      (item) =>
        item?.date && isBefore(new Date(item.date), new Date()) && !isSameDay(new Date(item.date), new Date())
    );
  }

  return items.filter(
    (item) =>
      (item?.date && !isBefore(new Date(item.date), new Date())) ||
      (item?.date && isSameDay(new Date(item.date), new Date()))
  );
};

type Props = {
  scheduleDownload?: () => Promise<unknown>;
};

export const SchedulesTable: FC<Props> = ({ scheduleDownload }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);
  const isGreenLoan = selectedContract?.loanType === 'GREEN LOAN';

  const [selectedDataSource, setSelectedDataSource] = useState<DataSource>('future');

  const { data, isFetching, financierMigrationDate, financierMigrationError } =
    useFetchSchedulesForSelectedContract();
  const items = data?.loanSchedulesData || [];

  const columns: TableColumn<TableRowItem>[] = [
    {
      selector: (row) => withNullCheck(row.date),
      name: t('paymentDate'),
      id: 'paymentDate',
      format: (row) => (
        <RegularText data-testid="schedule-payment-dates">
          {transformDateToLocalizedString(row.date || '-')}
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.outstandingAmount),
      name: t('unpaid'),
      id: 'unpaid',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.outstandingAmount)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.interestAmount),
      omit: isGreenLoan,
      name: t('interest'),
      id: 'interest',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.interestAmount)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.principalAmount),
      name: t('principalPart'),
      id: 'principalPart',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.principalAmount)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.vatAmount),
      omit: isGreenLoan,
      name: t('vatAmount'),
      id: 'vatAmount',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.vatAmount)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.totalAmount),
      omit: isGreenLoan,
      name: t('totalAmount'),
      id: 'totalAmount',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.totalAmount)}</AmountText>
        </RegularText>
      )
    }
  ];

  const shownItems = getSelectedData(selectedDataSource)(items);
  const isAfterToday =
    selectedContract?.firstInstallmentDate &&
    isAfter(new Date(selectedContract?.firstInstallmentDate), new Date());
  const instalmentDate = selectedContract?.firstInstallmentDate?.split('-').join('.');

  if (selectedContract?.loanType === 'CREDLINE' && !selectedContract?.scheduleApplies) return null;

  return (
    <Paper>
      <Section
        header={
          <SchedulesTableHeader>
            <LeftAllignedContent>
              <HeaderText>{t('schedule')}</HeaderText>
              <DataTypeSelector>
                <DataTypeOption
                  data-testid={`filter-panel-data-type-toggle${
                    selectedDataSource === 'historic' ? '-active' : ''
                  }`}
                  active={selectedDataSource === 'historic'}
                >
                  <LinkButton
                    data-testid="filter-panel-data-type-toggle-historic"
                    onClick={() => setSelectedDataSource('historic')}
                  >
                    {t('historicGraph')}
                  </LinkButton>
                </DataTypeOption>
                <DataTypeOption
                  data-testid={`filter-panel-data-type-toggle${
                    selectedDataSource === 'future' ? '' : '-active'
                  }`}
                  active={selectedDataSource === 'future'}
                >
                  <LinkButton
                    data-testid="filter-panel-data-type-toggle-future"
                    onClick={() => setSelectedDataSource('future')}
                  >
                    {t('futureGraph')}
                  </LinkButton>
                </DataTypeOption>
              </DataTypeSelector>
            </LeftAllignedContent>
            {scheduleDownload ? (
              <DownloadLink fileRequest={scheduleDownload}>
                <Icons.Download />
                {t('downloadSchedule')}
              </DownloadLink>
            ) : null}
          </SchedulesTableHeader>
        }
      >
        <MissingScheduleDataToast
          testId="-preliminary-payments-info"
          variant="info"
          isVisible={!!isAfterToday && selectedContract?.loanType === 'CREDLINE'}
          message={t('shedulePreliminaryPaymentsInfo', { instalmentDate })}
        />
        <MissingScheduleDataToast
          testId="-missing-schedule-data"
          variant="info"
          isVisible={
            !!financierMigrationError &&
            selectedDataSource === 'historic' &&
            selectedContract?.loanType !== 'CREDLINE'
          }
          message={t('schedulesMissingDataInfo', { financierMigrationDate })}
        />
        <SchedulesTableContainer>
          <Table<TableRowItem>
            highlightOnHover
            noDataMessage={t('noListItems')}
            pagination
            paginationPerPage={10}
            progressPending={isFetching}
            columns={columns}
            data={shownItems}
          />
        </SchedulesTableContainer>
      </Section>
    </Paper>
  );
};
