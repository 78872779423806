import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, OtpCodeDialog } from 'components';
import { selectUserData } from 'features/auth/selectors';
import { verifySMSOTPAsync, initiateVerifySMSOTPAsync } from 'features/auth/slice';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';

type Props = {
  isOpen: boolean;
  onSuccess?: () => unknown;
  onError?: () => unknown;
  onClose?: () => unknown;
  phoneNumber: string;
};

export const PhoneVerificationModal: FC<Props> = ({ onSuccess, onError, onClose, isOpen, phoneNumber }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [sessionId, setSessionId] = useState('');

  const { country } = useAuthSelector<typeof selectUserData>(selectUserData);

  const onSubmit = useCallback(
    (code: string) => {
      return dispatch(verifySMSOTPAsync({ password: code, sessionId: sessionId })).unwrap();
    },
    [sessionId]
  );

  const handleSuccess = useCallback(() => {
    onSuccess?.();
  }, [onSuccess]);

  const handleClose = () => {
    onClose?.();
  };

  const handleVerificationInit = async () => {
    try {
      if (phoneNumber && country) {
        const response = await dispatch(
          initiateVerifySMSOTPAsync({ phoneNumber: phoneNumber.replace('+', '').trim(), country })
        ).unwrap();

        if (response?.sessionId) {
          setSessionId(response?.sessionId);
        }
      }
    } catch {
      onError?.();
      handleClose?.();
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleVerificationInit();
    }
  }, [isOpen]);

  return (
    <Modal widthFitContent label={t('phoneNumberVerification')} isOpen={isOpen} onClose={handleClose}>
      <OtpCodeDialog
        wrongOtpWarningLabel={t('wrongOTP')}
        wrongOtpWarningExceededRetriesLabel={t('wrongOTPNoRetriesLeft')}
        resendOtpLinkLabel={t('resendOTPviaSMS')}
        resendOneTimePasswordLable={t('resendOTPviaSMS')}
        onSubmit={onSubmit}
        onRefresh={handleVerificationInit}
        onSuccess={handleSuccess}
      />
    </Modal>
  );
};
