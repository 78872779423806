import {
  ChangeEvent,
  FocusEventHandler,
  FormEvent,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { InfoPopup } from 'components/infoPopup';
import { HelpText } from 'components/text/text.styles';

import Icons from '../../assets/icons';
import { ErrorMessage } from '../formError/errors.styles';
import { FieldState } from '../formField/field';

import {
  Action,
  AdditionalActions,
  AditionalPlaceholderLabel,
  InfoPopupWrapper,
  InputActions,
  InputContainer,
  InputMessagesContainer,
  InputStyle,
  InputWrapper,
  PlaceholderLabel,
  SuffixLabel
} from './input.style';

type Props = {
  value: string | number;
  placeholder?: string;
  testId?: string;
  disabled?: boolean;
  type?: string;
  pattern?: string;
  onChange?: (value: string) => unknown;
  handleChangeFormEvent?: (e: ChangeEvent<HTMLInputElement>) => unknown;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClear?: () => unknown;
  fieldMeta?: FieldState;
  className?: string;
  required?: boolean;
  maxDecimals?: number;
  readOnly?: boolean;
  name?: string;
  suffix?: string;
  formatPlaceholder?: string;
  /**
   * @deprecated use size instead
   */
  dense?: boolean;
  size?: 'S' | 'M' | 'L';
  helpText?: string;
  infoText?: string;
  animatePlaceholder?: boolean;
  withRemoveIcon?: boolean;
  handleActionsOnClick?: boolean;
  absoluteMessage?: boolean;
  additionalAction?: {
    icon: keyof typeof Icons;
    onClick: () => unknown;
  };
};

type NativeAttributes = Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>;

export const sanitizeNumericValue = (value: string | number) => {
  if (typeof value === 'string') {
    const cleanedValue = value.replace(/[^-0-9.]/g, '');

    if (cleanedValue === '-') {
      return '-';
    }

    return isNaN(Number(cleanedValue)) ? '' : cleanedValue;
  }

  return value || '';
};

const getClearedValue = (value: string | number) => {
  if (typeof value === 'number') {
    return isNaN(value) ? '' : value;
  }

  return value || '';
};

export type InputProps = Props & Partial<NativeAttributes>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      id,
      placeholder,
      disabled,
      onChange,
      onBlur,
      type,
      pattern,
      fieldMeta,
      className,
      required,
      readOnly,
      name,
      maxDecimals = 2,
      suffix,
      dense,
      helpText,
      infoText,
      absoluteMessage,
      handleChangeFormEvent,
      onClear,
      additionalAction,
      handleActionsOnClick = false,
      animatePlaceholder = true,
      withRemoveIcon = true,
      testId,
      size,
      formatPlaceholder,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const { t } = useTranslation();
    const { isTouched, error, showValidationMessage, message } = fieldMeta || {};

    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value || '');

    const inputId = useId();
    const uniqueId = `${id ?? placeholder?.replaceAll(' ', '_')}_${inputId}`;

    /**
     * handleChangeFormEvent should be used only when dealing with a custom input components for eg. IBAN, currency input etc...
     */
    const handleChange =
      handleChangeFormEvent ||
      ((event: FormEvent<HTMLInputElement>) => {
        let currentValue = event.currentTarget.value;

        if (type === 'number') {
          const sanitizedValue = sanitizeNumericValue(currentValue);

          if (sanitizedValue !== currentValue) {
            const stringSanitizedValue = (sanitizedValue || '').toString();

            event.currentTarget.value = stringSanitizedValue;
            setInputValue(sanitizedValue);
            onChange?.(stringSanitizedValue);

            return;
          }

          const [integer, remainder] = currentValue.toString().split('.');

          const decimals = remainder?.split('') || 0;

          if (!maxDecimals) {
            currentValue = integer;
          } else {
            currentValue =
              decimals && decimals.length >= maxDecimals
                ? `${integer}.${decimals.filter((_, index) => index < maxDecimals).join('')}`
                : currentValue;
          }

          setInputValue(currentValue);
          onChange?.(currentValue);
        } else {
          setInputValue(currentValue);
          onChange?.(currentValue);
        }
      });

    useEffect(() => {
      setInputValue(value || '');
    }, [value]);

    const handleFocus = () => setIsFocused(true);

    const onBlurHandler: FocusEventHandler<HTMLInputElement> = (e) => {
      onBlur?.(e);
      setIsFocused(false);
    };

    const inputType = type === 'number' || !type ? 'text' : type;
    const hasValidationMessages = error?.message && isTouched;

    const clearedValue = getClearedValue(inputValue);

    const showRemoveButton =
      withRemoveIcon && !disabled && !readOnly && clearedValue && `${clearedValue}`.length > 0;

    const handleClear = () => {
      if (inputRef.current) {
        inputRef.current.classList.add('clear-action');
        inputRef.current.value = '';
        inputRef.current.focus();

        setInputValue('');
        onChange?.('');

        inputRef.current.value = '';
        inputRef.current.focus();

        setTimeout(() => {
          onClear?.();
        }, 0);

        setTimeout(() => {
          inputRef.current.classList.remove('clear-action');
        }, 300);
      }
    };

    useImperativeHandle(ref, () => inputRef.current);

    const AdditionalIcon = additionalAction ? Icons[additionalAction?.icon] : null;

    const resolvedSize = dense ? 'S' : size ?? 'M';

    return (
      <InputContainer
        data-testid={`input-container-${testId ?? placeholder}`}
        className={className}
        hasPlaceholder={Boolean(placeholder)}
      >
        <InputWrapper showRemoveButton={Boolean(showRemoveButton)} htmlFor={uniqueId}>
          <InputStyle
            animatePlaceholder={animatePlaceholder}
            hasActions={Boolean(infoText)}
            ref={inputRef}
            inputSize={resolvedSize}
            onBlur={onBlurHandler}
            onFocus={handleFocus}
            invalidStatus={Boolean(hasValidationMessages)}
            value={clearedValue}
            onChange={handleChange}
            disabled={disabled}
            type={inputType}
            pattern={pattern}
            data-testid={testId ?? placeholder}
            placeholder={placeholder}
            id={uniqueId}
            readOnly={readOnly}
            name={name}
            {...rest}
          />
          {placeholder && (
            <PlaceholderLabel
              inputSize={resolvedSize}
              htmlFor={uniqueId}
              hasValue={false}
              required={required}
            >
              {required ? placeholder : t('placeholderOptional', { placeholder })}
            </PlaceholderLabel>
          )}
          {isFocused && !clearedValue && formatPlaceholder && (
            <AditionalPlaceholderLabel
              inputSize={resolvedSize}
              htmlFor={uniqueId}
              hasValue={false}
              required={required}
            >
              {formatPlaceholder}
            </AditionalPlaceholderLabel>
          )}
          <InputActions
            offsetMiddleAction={Boolean(additionalAction)}
            offsetHelpPopup={Boolean(infoText)}
            onMouseDownCapture={handleActionsOnClick ? undefined : handleClear}
            onClick={handleActionsOnClick ? handleClear : undefined}
          >
            <Action>
              <Icons.CloseMedium />
            </Action>
          </InputActions>
          {additionalAction ? (
            <AdditionalActions
              offsetHelpPopup={Boolean(infoText)}
              onMouseDownCapture={handleActionsOnClick ? undefined : additionalAction.onClick}
              onClick={handleActionsOnClick ? additionalAction.onClick : undefined}
            >
              <Action>{AdditionalIcon ? <AdditionalIcon /> : null}</Action>
            </AdditionalActions>
          ) : null}
          {infoText ? (
            <InfoPopupWrapper>
              <InfoPopup text={infoText}></InfoPopup>
            </InfoPopupWrapper>
          ) : null}
          {suffix && <SuffixLabel inputSize={resolvedSize}>{suffix}</SuffixLabel>}
        </InputWrapper>
        <InputMessagesContainer absoluteMessage={absoluteMessage}>
          {helpText ? <HelpText>{helpText}</HelpText> : null}
          {hasValidationMessages && showValidationMessage ? (
            <ErrorMessage hidden={!hasValidationMessages || !showValidationMessage}>{message}</ErrorMessage>
          ) : null}
        </InputMessagesContainer>
      </InputContainer>
    );
  }
);

export default Input;
