import {
  getCountryCallingCode,
  isSupportedCountry,
  type CountryCode as PhoneCountryCode
} from 'libphonenumber-js';

import { getCountryDisplayName } from '../../translations/countries';

export const ISO_CODES = [
  { value: 'AF' },
  { value: 'SH' },
  { value: 'AL' },
  { value: 'AX' },
  { value: 'DZ' },
  { value: 'AS' },
  { value: 'AD' },
  { value: 'AO' },
  { value: 'AI' },
  { value: 'AG' },
  { value: 'AR' },
  { value: 'AM' },
  { value: 'AW' },
  { value: 'AU' },
  { value: 'AT' },
  { value: 'AZ' },
  { value: 'BS' },
  { value: 'BH' },
  { value: 'BD' },
  { value: 'BB' },
  { value: 'BY' },
  { value: 'BE' },
  { value: 'BZ' },
  { value: 'BJ' },
  { value: 'BM' },
  { value: 'BT' },
  { value: 'BO' },
  { value: 'BQ' },
  { value: 'BA' },
  { value: 'BW' },
  { value: 'BV' },
  { value: 'BR' },
  { value: 'IO' },
  { value: 'BN' },
  { value: 'BG' },
  { value: 'BF' },
  { value: 'BI' },
  { value: 'CV' },
  { value: 'KH' },
  { value: 'CM' },
  { value: 'CA' },
  { value: 'KY' },
  { value: 'CF' },
  { value: 'TD' },
  { value: 'CL' },
  { value: 'CN' },
  { value: 'CX' },
  { value: 'CC' },
  { value: 'CO' },
  { value: 'KM' },
  { value: 'CD' },
  { value: 'CG' },
  { value: 'CK' },
  { value: 'CR' },
  { value: 'HR' },
  { value: 'CU' },
  { value: 'CW' },
  { value: 'CY' },
  { value: 'CZ' },
  { value: 'CI' },
  { value: 'DK' },
  { value: 'DJ' },
  { value: 'DM' },
  { value: 'DO' },
  { value: 'EC' },
  { value: 'EG' },
  { value: 'SV' },
  { value: 'GQ' },
  { value: 'ER' },
  { value: 'EE' },
  { value: 'SZ' },
  { value: 'ET' },
  { value: 'FK' },
  { value: 'FO' },
  { value: 'FJ' },
  { value: 'FI' },
  { value: 'FR' },
  { value: 'GF' },
  { value: 'PF' },
  { value: 'TF' },
  { value: 'GA' },
  { value: 'GM' },
  { value: 'GE' },
  { value: 'DE' },
  { value: 'GH' },
  { value: 'GI' },
  { value: 'GR' },
  { value: 'GL' },
  { value: 'GD' },
  { value: 'GP' },
  { value: 'GU' },
  { value: 'GT' },
  { value: 'GG' },
  { value: 'GN' },
  { value: 'GW' },
  { value: 'GY' },
  { value: 'HT' },
  { value: 'HM' },
  { value: 'VA' },
  { value: 'HN' },
  { value: 'HK' },
  { value: 'HU' },
  { value: 'IS' },
  { value: 'IN' },
  { value: 'ID' },
  { value: 'IR' },
  { value: 'IQ' },
  { value: 'IE' },
  { value: 'IM' },
  { value: 'IL' },
  { value: 'IT' },
  { value: 'JM' },
  { value: 'JP' },
  { value: 'JE' },
  { value: 'JO' },
  { value: 'KZ' },
  { value: 'KE' },
  { value: 'KI' },
  { value: 'KP' },
  { value: 'KR' },
  { value: 'KW' },
  { value: 'KG' },
  { value: 'LA' },
  { value: 'LV' },
  { value: 'LB' },
  { value: 'LS' },
  { value: 'LR' },
  { value: 'LY' },
  { value: 'LI' },
  { value: 'LT' },
  { value: 'LU' },
  { value: 'MO' },
  { value: 'MG' },
  { value: 'MW' },
  { value: 'MY' },
  { value: 'MV' },
  { value: 'ML' },
  { value: 'MT' },
  { value: 'MH' },
  { value: 'MQ' },
  { value: 'MR' },
  { value: 'MU' },
  { value: 'YT' },
  { value: 'MX' },
  { value: 'FM' },
  { value: 'MD' },
  { value: 'MC' },
  { value: 'MN' },
  { value: 'ME' },
  { value: 'MS' },
  { value: 'MA' },
  { value: 'MZ' },
  { value: 'MM' },
  { value: 'NA' },
  { value: 'NR' },
  { value: 'NP' },
  { value: 'NL' },
  { value: 'NC' },
  { value: 'NZ' },
  { value: 'NI' },
  { value: 'NE' },
  { value: 'NG' },
  { value: 'NU' },
  { value: 'NF' },
  { value: 'MP' },
  { value: 'NO' },
  { value: 'OM' },
  { value: 'PK' },
  { value: 'PW' },
  { value: 'PS' },
  { value: 'PA' },
  { value: 'PG' },
  { value: 'PY' },
  { value: 'PE' },
  { value: 'PH' },
  { value: 'PN' },
  { value: 'PL' },
  { value: 'PT' },
  { value: 'PR' },
  { value: 'QA' },
  { value: 'MK' },
  { value: 'RO' },
  { value: 'RU' },
  { value: 'RW' },
  { value: 'RE' },
  { value: 'BL' },
  { value: 'KN' },
  { value: 'LC' },
  { value: 'MF' },
  { value: 'PM' },
  { value: 'VC' },
  { value: 'WS' },
  { value: 'SM' },
  { value: 'ST' },
  { value: 'SA' },
  { value: 'SN' },
  { value: 'RS' },
  { value: 'SC' },
  { value: 'SL' },
  { value: 'SG' },
  { value: 'SX' },
  { value: 'SK' },
  { value: 'SI' },
  { value: 'SB' },
  { value: 'SO' },
  { value: 'ZA' },
  { value: 'GS' },
  { value: 'SS' },
  { value: 'ES' },
  { value: 'LK' },
  { value: 'SD' },
  { value: 'SR' },
  { value: 'SJ' },
  { value: 'SE' },
  { value: 'CH' },
  { value: 'SY' },
  { value: 'TW' },
  { value: 'TJ' },
  { value: 'TZ' },
  { value: 'TH' },
  { value: 'TL' },
  { value: 'TG' },
  { value: 'TK' },
  { value: 'TO' },
  { value: 'TT' },
  { value: 'TN' },
  { value: 'TR' },
  { value: 'TM' },
  { value: 'TC' },
  { value: 'TV' },
  { value: 'UG' },
  { value: 'UA' },
  { value: 'AE' },
  { value: 'GB', aliases: ['UK'] },
  { value: 'UM' },
  { value: 'US' },
  { value: 'UY' },
  { value: 'UZ' },
  { value: 'VU' },
  { value: 'VE' },
  { value: 'VN' },
  { value: 'VG' },
  { value: 'VI' },
  { value: 'WF' },
  { value: 'EH' },
  { value: 'YE' },
  { value: 'ZM' },
  { value: 'ZW' },
  { value: 'XK' }
] as const;

export type CountryCodes = (typeof ISO_CODES)[number]['value'];

type Country = {
  value: CountryCodes;
  label: string;
  flagCode: string;
};

export const getCountriesList = (
  language: 'en' | 'lt',
  areaCodeEnabled?: boolean,
  prioritisedCountries: CountryCodes[] = []
) => {
  const countriesList = ISO_CODES.map((isoCode) => {
    const translationCode = 'aliases' in isoCode ? isoCode.aliases?.[0] ?? isoCode.value : isoCode.value;

    const isSupported = !areaCodeEnabled || isSupportedCountry(isoCode.value);

    if (!isSupported) {
      return null;
    }

    const label = getCountryDisplayName(translationCode, language) ?? '';

    const fullLabel = areaCodeEnabled
      ? `${label} (+${getCountryCallingCode(isoCode.value as PhoneCountryCode)})`
      : label;

    return {
      value: isoCode.value,
      label: fullLabel,
      flagCode: isoCode.value.toLowerCase()
    };
  }).filter(Boolean) as Country[];

  const countriesMap = new Map(countriesList.map((country) => [country.value, country]));

  const prioritisedList = prioritisedCountries
    .map((countryCode) => countriesMap.get(countryCode))
    .filter(Boolean) as Country[];
  const remainingList = countriesList.filter((country) => !prioritisedCountries.includes(country.value));

  const sortedRemainingList = remainingList.sort(
    (a: (typeof countriesList)[number], b: (typeof countriesList)[number]) => a.label.localeCompare(b.label)
  );

  return [...prioritisedList, ...sortedRemainingList];
};

export const getFlagCodeByValue = (value: string) => {
  return value.toLowerCase();
};

export const getAreaCodes = () => {
  return ISO_CODES.filter((isoCode) => isSupportedCountry(isoCode.value)).map((isoCode) => ({
    [isoCode.value.toLocaleLowerCase()]: [`${getCountryCallingCode(isoCode.value as PhoneCountryCode)}`]
  }));
};
