import { CountryFlag, CountryFlagContainer, CountryFlagProps } from './countriesList.styles';

type Props = {
  flagCode: string;
} & CountryFlagProps;

const CountryFlagIcon = ({ flagCode, size }: Props) => {
  return (
    <CountryFlagContainer size={size}>
      <CountryFlag size={size} className={`fi fi-${flagCode.toLowerCase()} fis`}></CountryFlag>
    </CountryFlagContainer>
  );
};

export default CountryFlagIcon;
