import { useSearchParams } from 'react-router-dom';

import { useContractSelector } from 'hooks/redux/hooks';

import { useGetContractDocumentsListQuery } from '../api';
import { selectContractDetails, selectIsDetailsLoading } from '../selectors';

export const useFetchContractsDocumentsList = () => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || '';

  const contractDetails = useContractSelector<typeof selectContractDetails>(selectContractDetails);
  const isFetching = useContractSelector<typeof selectIsDetailsLoading>(selectIsDetailsLoading);

  const contractDetailsUpdated = contractDetails.id === contractId;

  const response = useGetContractDocumentsListQuery(
    { contractId, contractVersion: `${contractDetails.contractVersion || ''}` },
    { skip: !contractId || !contractDetailsUpdated }
  );

  return { ...response, isFetching: response.isFetching || isFetching };
};
