import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { VerificationInvoice } from 'services/InvoiceVerificationService/types';

const statusesAdapter = createEntityAdapter<VerificationInvoice & Required<Pick<VerificationInvoice, 'id'>>>({
  selectId: (invoice) => invoice.id
});

export const initialState = statusesAdapter.getInitialState();

const invoicesVerificationSlice = createSlice({
  name: 'invoicesVerificationStatuses',
  initialState: initialState,
  reducers: {
    addStatus: statusesAdapter.addOne,
    updateStatus: statusesAdapter.updateOne,
    removeStatus: statusesAdapter.removeOne,
    resetStatuses: statusesAdapter.removeAll,
    resetInvoicesVerificationState: () => initialState
  }
});

export const { addStatus, updateStatus, removeStatus, resetStatuses, resetInvoicesVerificationState } =
  invoicesVerificationSlice.actions;

export const { selectAll: selectAllStatuses, selectById: selectStatusById } = statusesAdapter.getSelectors();

export default invoicesVerificationSlice;
