import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FactoringStatementsService } from 'services';
import { FactoringStatementsGetRequest } from 'services/FactoringStatementsService/types';

import { FactoringStatementsState } from './types';

export const initialState: FactoringStatementsState = {
  isLoading: false,
  acts: {}
};

const factoringStatementsSlice = createSlice({
  name: 'factoringStatements',
  initialState,
  reducers: {
    resetFactoringStatementsState: () => initialState
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchActsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchActsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.acts = action.payload;
      })
      .addCase(fetchActsAsync.rejected, (state) => {
        state.isLoading = false;
        state.acts = {};
      });
  }
});

export const fetchActsAsync = createAsyncThunk(
  'factoringStatements/getActs',
  async (payload: FactoringStatementsGetRequest) => {
    return await FactoringStatementsService.getActs(payload);
  }
);

export const { resetFactoringStatementsState } = factoringStatementsSlice.actions;

export default factoringStatementsSlice.reducer;
