import styled from 'styled-components';

import { PaperStyle, Typography } from 'components';

import { Column } from './error.styles';

export const MainTitle = styled(Typography.Header)`
  text-align: center;
  margin-block: 1.5rem 1rem;
`;

MainTitle.defaultProps = {
  type: 'h2',
  weight: 'medium'
};

export const Subtitle = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
  text-align: center;
  white-space: pre-wrap;
`;

Subtitle.defaultProps = {
  type: 'L',
  weight: 'normal'
};

export const MaintenanceCardRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  justify-content: center;
`;

export const MaintenancePageContent = styled(Column)`
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${PaperStyle} {
    max-width: 32.5rem;
    width: 100%;
    padding: 4.375rem 2rem;
    margin-inline: 1rem;

    @media ${({ theme }) => theme.media.maxTablet} {
      padding: 2.5rem 1rem;
    }
  }
`;

export const MaintenancePageCardColumn = styled(Column)`
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-top: 3.5rem;

    @media ${({ theme }) => theme.media.maxTablet} {
      margin-top: 2.5rem;
    }
  }
`;

export const MaintenanceCardContent = styled(Column)`
  justify-content: center;
  align-items: center;

  > img {
    width: 11rem;
  }

  > span {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
