import { FC } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import {
  AmountText,
  DefaultItem,
  Paper,
  PercentageText,
  RegularText,
  SecondaryText,
  Section,
  Table,
  ToastLegacy
} from 'components';
import { ThirdParty } from 'services/ContractsService/types';

import { BuyersTableContainer, Header, HeaderInfoPopup } from './buyers.styles';

type Item = ThirdParty & DefaultItem;

type Props = {
  loading?: boolean;
  error?: boolean;
  items: Item[];
};

export const withNullCheck = (value?: string | number | null) => (value || value === 0 ? value : '-');

export const FactoringBuyers: FC<Props> = ({ loading, error, items }) => {
  const { t } = useTranslation();

  const filteredItems = items?.filter(
    (thirdParty) =>
      thirdParty.factoringCreditLimit ||
      thirdParty.securedFactoringCreditLimit ||
      thirdParty.insuredCreditLimit
  );

  const columns: TableColumn<Item>[] = [
    {
      selector: (row) => withNullCheck(row.party?.name),
      name: t('company'),
      format: (row) => <RegularText>{withNullCheck(row.party?.name)}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.insuredCreditLimit),
      name: (
        <Header>
          <SecondaryText>{t('insuredLimit')}</SecondaryText>
          <HeaderInfoPopup>{t('insuredLimitExplanation')}</HeaderInfoPopup>
        </Header>
      ),
      width: '7.5rem',
      id: 'insuredLimit',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.insuredCreditLimit)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.insuredFactoringCreditLimit),
      name: (
        <Header>
          <SecondaryText>{t('insuredFinancedLimit')}</SecondaryText>
          <HeaderInfoPopup>{t('insuredFinancedLimitExplanation')}</HeaderInfoPopup>{' '}
        </Header>
      ),
      width: '7.5rem',
      id: 'insuredFinancedLimit',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.insuredFactoringCreditLimit)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.usedFactoringCreditLimit),
      name: t('used'),
      id: 'used',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.usedFactoringCreditLimit)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.availableFactoringCreditLimit),
      name: t('available'),
      id: 'available',
      format: (row) => (
        <RegularText>
          <AmountText>{withNullCheck(row.availableFactoringCreditLimit)}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.maxInvoicePaymentTerm),
      id: 'dueDays',
      name: t('dueDays'),
      format: (row) => <RegularText>{withNullCheck(row.maxInvoicePaymentTerm)}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.interestRate),
      name: t('interest'),
      id: 'interest',
      format: (row) => <PercentageText>{withNullCheck(row.interestRate)}</PercentageText>
    },
    {
      selector: (row) => withNullCheck(row.advanceRate),
      name: t('advance'),
      id: 'advance',
      format: (row) => <PercentageText>{withNullCheck(row.advanceRate)}</PercentageText>
    }
  ];

  return (
    <Paper>
      <Section header={`${t('buyers')} ${error ? '' : `(${filteredItems?.length || 0})`}`}>
        {error ? (
          <ToastLegacy isVisible message={t('generalErrorMessage')} />
        ) : (
          <BuyersTableContainer>
            <Table
              pagination
              paginationPerPage={10}
              noDataMessage={t('noListItems')}
              progressPending={loading}
              columns={columns}
              data={filteredItems}
              highlightOnHover
            />
          </BuyersTableContainer>
        )}
      </Section>
    </Paper>
  );
};
