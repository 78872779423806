import styled from 'styled-components';

import { Typography } from 'components';

export const DescriptionText = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const FieldsetHeaderTitle = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const FieldsetHeaderValue = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const FieldsetHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 1rem;

  ${FieldsetHeaderValue} {
    padding-top: 0.25rem;
  }
`;

export const ToastContainer = styled.div`
  margin-bottom: 1.25rem;
`;

export const PurposeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1.5rem 0 1rem;
`;

export const PurposeHeader = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })``;

export const AttachmentsExplanationText = styled(Typography.Body).attrs({ type: 'M', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.tints[50]};
  margin-bottom: 0.5rem;
`;
