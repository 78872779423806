import styled from 'styled-components';

import { RegularText } from 'components';

export const HeaderText = styled(RegularText)`
  line-height: 1.5rem;
  font-size: 1rem;

  &:last-of-type {
    margin-top: 1.5rem;
  }
`;

export const AgreementFieldContainer = styled.div`
  margin: 1.5rem 0;
`;

export const AgreementText = styled(RegularText)`
  font-size: 0.875rem;
`;
