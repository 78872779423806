import styled, { css } from 'styled-components';

import { Typography } from 'components/typography';

import { ButtonRow } from '../buttonV2';
import { PaperStyle } from '../paper';

type Props = {
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxWidth?: string;
  padding?: string;
  sessionTimeoutModal?: boolean;
  termsModal?: boolean;
  widthFitContent?: boolean;
  stickyNavSections?: boolean;
  offsetHeader?: boolean;
  variant: 'functional' | 'informational';
};

type ContentWrapperProps = {
  verticallyCentered?: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ verticallyCentered }) => (verticallyCentered ? 'center' : 'baseline')};
`;

export const ModalLabel = styled(Typography.Header).attrs({ type: 'h3', weight: 'medium' })`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  text-align: center;
  white-space: break-spaces;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4.25rem;
  padding: 1.5rem 2rem 1rem;

  @media ${({ theme }) => theme.media.maxPhone} {
    padding: 1rem;
  }

  svg {
    margin-right: 0;

    path {
      fill: ${({ theme }) => theme.colors.base.black.functional};
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Overlay = styled.div<Props>`
  z-index: ${({ theme, sessionTimeoutModal, termsModal }) =>
    sessionTimeoutModal
      ? theme.layers.sessionTimeoutModal
      : termsModal
        ? theme.layers.termsModal
        : theme.layers.overlay};
  display: flex;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 60%);
  justify-content: center;
  align-items: center;
  padding: 5.25rem 0;

  @media ${({ theme }) => theme.media.maxDesktop} {
    padding: 1.25rem 0;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    background-color: ${({ theme }) => theme.colors.base.white.functional};
    padding: 0;
  }

  ${PaperStyle} {
    border-radius: 1.5rem;
    box-shadow: none;
    width: ${({ widthFitContent, fullWidth }) =>
      fullWidth ? 'calc(100% - 5.25rem)' : widthFitContent ? 'auto' : '100%'};
    max-height: 100%;
    max-width: ${({ maxWidth, widthFitContent, variant }) =>
      variant === 'informational' ? '25rem' : widthFitContent ? 'auto' : maxWidth ? maxWidth : '35.75rem'};
    height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
    padding: ${({ variant }) => (variant === 'functional' ? 0 : '2.5rem 0 1rem')};
    overflow-y: ${({ stickyNavSections }) => (stickyNavSections ? 'hidden' : 'auto')};
    ${({ widthFitContent }) =>
      widthFitContent
        ? css`
            display: flex;
            flex: 0 1 auto;
          `
        : ''};
    margin-left: 1.25rem;
    margin-right: 1.25rem;

    @media ${({ theme }) => theme.media.maxPhone} {
      height: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding: ${({ variant }) => (variant === 'functional' ? 0 : '2.5rem 0 0')};
      border-radius: 0;
    }
  }

  &.top-shadow {
    ${ModalHeader} {
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 5%);
      z-index: 999;
    }
  }

  &.bottom-shadow {
    ${ModalFooter} {
      box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 5%);
      z-index: 999;
    }
  }

  ${ContentWrapper} {
    overflow-y: ${({ stickyNavSections, variant }) =>
      variant === 'informational' ? 'visible' : stickyNavSections ? 'auto' : 'initial'};
    padding-inline: 2rem 1.5625rem;
    margin-right: 0.4375rem;
    align-items: ${({ variant }) => (variant === 'informational' ? 'center' : 'initial')};

    @media ${({ theme }) => theme.media.maxPhone} {
      padding-inline: ${({ variant }) => (variant === 'informational' ? '2rem' : '1rem')};
      margin-right: 0;
      margin-block: auto;
    }

    ${ModalLabel} {
      margin-bottom: 1rem;
    }
  }

  ${ModalHeader} {
    margin-top: ${({ variant }) => (variant === 'functional' ? 0 : '-2.5rem;')};
    margin-inline: 0;

    @media ${({ theme }) => theme.media.maxPhone} {
      padding: ${({ variant }) => (variant === 'functional' ? '1rem' : '1.5rem 1.5rem 1rem')};
    }
  }

  ${ButtonRow} {
    margin-top: 0;

    @media ${({ theme }) => theme.media.maxPhone} {
      flex-direction: column-reverse;
    }
  }

  ${ModalFooter} {
    padding: 1rem 2rem 1.5rem;

    @media ${({ theme }) => theme.media.maxPhone} {
      padding: ${({ variant }) => (variant === 'informational' ? '1.5rem 2rem 1.5rem' : '1rem 1rem 1.5rem')};
    }
  }
`;

type InformationImageContainerProps = {
  offsetContent?: boolean;
};

export const InformationImageContainer = styled.div<InformationImageContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ offsetContent }) => (offsetContent ? '-1.5rem' : 0)};
  margin-bottom: 1.5rem;
`;

export const InformationIconContainer = styled(InformationImageContainer)`
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalSubheader = styled(Typography.Body).attrs({ type: 'M', weight: 'normal' })`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;
