import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 0.5rem;
  }

  *:first-child {
    margin-right: 1rem;
  }
`;
