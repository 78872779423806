import { TableColumn } from 'react-data-table-component';
import { TFunction } from 'react-i18next';

import Icons from 'assets/icons';
import {
  SecondaryText,
  RegularText,
  AmountText,
  StatusChip,
  transformDateToLocalizedString,
  Popup
} from 'components';
import { StatusType } from 'design/designVariables';
import { UpcommingPaymentItem } from 'features/invoices/types';
import { InvoiceStatusType, SubmittedInvoice } from 'services/InvoicesService/types';
import { camelCase } from 'utils';

import {
  HeaderCell,
  CellCollumn,
  AmountCellColumn,
  IconContainerProps,
  IconContainer,
  MobileVerificationBadgeContainer
} from './invoiceDetailsTable.styles';

export type TableRowItem = UpcommingPaymentItem & SubmittedInvoice & { toggleSelected?: never };

type StatusToChipColorMap = {
  [key in InvoiceStatusType]: keyof StatusType;
};

const statusToChipColorMap: StatusToChipColorMap = {
  FINANCED: 'yellow',
  PARTIALLY_PAID: 'green',
  OVERDUE: 'red',
  SUBMITTED: 'lightBlue',
  PAID: 'green',
  REJECTED: 'yellow',
  CLOSED: 'white',
  TRANSFERRED: 'gray'
};

type VerificationStatusToIconsMap = {
  [key in NonNullable<SubmittedInvoice['buyerVerificationStatus']>]: JSX.Element | null;
};

type VerificationStatusToExplanationsMap = {
  [key in NonNullable<SubmittedInvoice['buyerVerificationStatus']>]: string;
};

type VerificationStatusToColorsMap = {
  [key in NonNullable<SubmittedInvoice['buyerVerificationStatus']>]: IconContainerProps['variant'];
};

const verificationStatusIcons: VerificationStatusToIconsMap = {
  REJECTED: <Icons.QuestionaireRejected />,
  WAITING: <Icons.QuestionaireWaiting />,
  ACCEPTED: <Icons.QuestionaireCompleted />,
  NOT_APPLICABLE: null
};

const verificationStatusExplanations: VerificationStatusToExplanationsMap = {
  REJECTED: 'invoiceRejectedByBuyer',
  WAITING: 'waitingForBuyerVerification',
  ACCEPTED: 'invoiceVerifiedByBuyer',
  NOT_APPLICABLE: ''
};

const verificationStatusColors: VerificationStatusToColorsMap = {
  REJECTED: 'negative',
  WAITING: 'neutral',
  ACCEPTED: 'positive',
  NOT_APPLICABLE: 'neutral'
};

export const withNullCheck = (value?: string | number | boolean) => value || '';

export const createTableColumns = (
  t: TFunction<'translation', undefined>,
  customerName: string
): TableColumn<TableRowItem>[] => [
  {
    selector: (row) => withNullCheck(row.invoiceNumber),
    name: (
      <HeaderCell>
        {t('number')}/<br></br>
        {t('buyer')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'invoiceNumber',
    sortField: 'invoiceNumber',
    format: (row) => {
      if (row.type === 'CLIENT_DEBT' || row.type === 'OVERPAYMENT_TO_CLIENT') {
        return (
          <CellCollumn>
            <SecondaryText data-testid={`invoices-details-item-number-cell-${row.invoiceNumber}`}>
              {row.invoiceNumber}
            </SecondaryText>
            <RegularText>{customerName}</RegularText>
          </CellCollumn>
        );
      }

      return (
        <CellCollumn>
          <SecondaryText data-testid={`invoices-details-item-number-cell-${row.invoiceNumber}`}>
            {row.invoiceNumber}
          </SecondaryText>
          <RegularText>{row.thirdPartyName}</RegularText>
        </CellCollumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceDate),
    name: (
      <HeaderCell>
        {t('placeholderUploadIssueDate')}/<br></br>
        {t('dueDate')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'invoiceDueDate',
    sortField: 'invoiceDueDate',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return (
        <AmountCellColumn>
          <SecondaryText>{row.invoiceDate}</SecondaryText>
          <RegularText>{row.dueDate}</RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceAmount?.amount),
    name: (
      <HeaderCell>
        {t('placeholderUploadAmount')}/<br></br>
        {t('financed')}
      </HeaderCell>
    ),
    sortable: true,
    id: 'invoiceAmount',
    sortField: 'invoiceAmount',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      const { invoiceAmount, advanceAmountFunded } = row;

      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText currency={invoiceAmount?.currency || undefined}>
              {row.invoiceAmount?.amount}
            </AmountText>
          </RegularText>
          <RegularText>
            <AmountText currency={advanceAmountFunded?.currency || undefined}>
              {advanceAmountFunded?.amount}
            </AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.unpaid),
    name: t('reminder'),
    sortable: true,
    id: 'invoiceAmountUnfunded',
    sortField: 'invoiceAmountUnfunded',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText currency={row.invoiceAmountUnfunded?.currency || undefined}>
              {row.invoiceAmountUnfunded?.amount}
            </AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.unpaid),
    name: t('unpaid'),
    sortable: true,
    id: 'invoiceAmountAssignedOutstanding',
    sortField: 'invoiceAmountAssignedOutstanding',
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_amount_type']
      }
    ],
    format: (row) => {
      return (
        <AmountCellColumn>
          <RegularText>
            <AmountText currency={row.invoiceAmountAssignedOutstanding?.currency || undefined}>
              {row.invoiceAmountAssignedOutstanding?.amount}
            </AmountText>
          </RegularText>
        </AmountCellColumn>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceStatus),
    name: t('status'),
    sortable: true,
    id: 'invoiceStatus',
    sortField: 'invoiceStatus',
    format: (row) => {
      if (row.invoiceStatus) {
        return (
          <CellCollumn>
            <StatusChip color={statusToChipColorMap[row.invoiceStatus as InvoiceStatusType]}>
              <RegularText>{t(camelCase(row.invoiceStatus || ''))}</RegularText>
            </StatusChip>
          </CellCollumn>
        );
      }

      return null;
    }
  },
  {
    selector: (row) => withNullCheck(row.buyerVerificationStatus),
    id: 'verificationStatus',
    width: '46px',
    format: (row) => {
      const { buyerVerificationStatus } = row || {};

      const displayStatusBadge = buyerVerificationStatus && buyerVerificationStatus !== 'NOT_APPLICABLE';

      if (displayStatusBadge) {
        return (
          <CellCollumn>
            <Popup
              showOnHover
              button={
                <IconContainer variant={verificationStatusColors[buyerVerificationStatus]}>
                  {verificationStatusIcons[buyerVerificationStatus]}
                </IconContainer>
              }
            >
              <SecondaryText>{t(verificationStatusExplanations[buyerVerificationStatus])}</SecondaryText>
            </Popup>
          </CellCollumn>
        );
      }

      return null;
    }
  }
];

export const createMobileListRows = (t: TFunction<'translation', undefined>): TableColumn<TableRowItem>[] => [
  {
    selector: (row) => withNullCheck(row.invoiceNumber),
    name: t('number'),
    id: 'invoiceNumber',
    format: (row) => {
      return <RegularText>{row.invoiceNumber}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceNumber),
    name: t('buyer'),
    id: 'invoiceNumber',
    format: (row) => {
      return <RegularText>{row.thirdPartyName}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceDate),
    name: t('placeholderUploadIssueDate'),
    id: 'invoiceDates',
    format: (row) => {
      return <RegularText>{row.invoiceDate}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceDate),
    name: t('dueDate'),
    id: 'invoiceDates',
    format: (row) => {
      return <RegularText>{row.dueDate}</RegularText>;
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceAmount?.amount),
    name: t('placeholderUploadAmount'),
    id: 'amountAndFinanced',
    format: (row) => {
      const { invoiceAmount } = row;

      return (
        <RegularText>
          <AmountText currency={invoiceAmount?.currency || undefined}>{row.invoiceAmount?.amount}</AmountText>
        </RegularText>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.invoiceAmount?.amount),
    name: t('financed'),
    id: 'amountAndFinanced',
    format: (row) => {
      const { advanceAmountFunded } = row;

      return (
        <RegularText>
          <AmountText currency={advanceAmountFunded?.currency || undefined}>
            {advanceAmountFunded?.amount}
          </AmountText>
        </RegularText>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.unpaid),
    name: t('reminder'),
    id: 'unfunded',
    format: (row) => {
      return (
        <RegularText>
          <AmountText currency={row.invoiceAmountUnfunded?.currency || undefined}>
            {row.invoiceAmountUnfunded?.amount}
          </AmountText>
        </RegularText>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.unpaid),
    name: t('unpaid'),
    id: 'unpaid',
    format: (row) => {
      return (
        <RegularText>
          <AmountText currency={row.invoiceAmountAssignedOutstanding?.currency || undefined}>
            {row.invoiceAmountAssignedOutstanding?.amount}
          </AmountText>
        </RegularText>
      );
    }
  },
  {
    selector: (row) => withNullCheck(row.submittedAt),
    name: t('uploadedDate'),
    id: 'unpaid',
    format: (row) => {
      return row.submittedAt ? (
        <RegularText>{transformDateToLocalizedString(new Date(row.submittedAt))}</RegularText>
      ) : null;
    }
  },
  {
    selector: (row) => withNullCheck(row.status),
    name: t('status'),
    id: 'status',
    format: (row) => {
      if (row.invoiceStatus) {
        const { buyerVerificationStatus } = row || {};

        const displayStatusBadge = buyerVerificationStatus && buyerVerificationStatus !== 'NOT_APPLICABLE';

        return (
          <>
            <StatusChip color={statusToChipColorMap[row.status as InvoiceStatusType]}>
              <RegularText>{t(camelCase(row.invoiceStatus || ''))}</RegularText>
            </StatusChip>
            {displayStatusBadge ? (
              <MobileVerificationBadgeContainer>
                <Popup
                  showOnHover
                  button={
                    <IconContainer variant={verificationStatusColors[buyerVerificationStatus]}>
                      {verificationStatusIcons[buyerVerificationStatus]}
                    </IconContainer>
                  }
                >
                  <SecondaryText>{t(verificationStatusExplanations[buyerVerificationStatus])}</SecondaryText>
                </Popup>
              </MobileVerificationBadgeContainer>
            ) : null}
          </>
        );
      }

      return null;
    }
  }
];
