import styled from 'styled-components';

import { MobileList } from 'components/table/table.styles';

export const ContractsTableContainer = styled.div`
  div[data-column-id='1'] {
    min-width: 2.125rem;
    max-width: 2.125rem;
  }

  .rdt_TableBody {
    height: 8.4375rem;
    overflow-y: auto;
  }

  ${MobileList} {
    max-height: 33.8125rem;
    overflow-y: scroll;
  }
`;
