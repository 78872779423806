import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { BankService } from 'services';

import { BankState } from './types';

const initialState: BankState = {
  dashboard: { Accounts: [] },
  isLoading: false
};

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    resetBankState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardInformationAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDashboardInformationAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashboard = action.payload;
      })
      .addCase(fetchDashboardInformationAsync.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const fetchDashboardInformationAsync = createAsyncThunk('bank/getDashboardInfo', async () => {
  return await BankService.getDashboardInfo();
});

export const { resetBankState } = bankSlice.actions;

export default bankSlice.reducer;
