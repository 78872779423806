import styled from 'styled-components';

import { ScreenSize } from 'components/layout';
import { SortIndicator, SortIndicatorContainer } from 'components/table/table.styles';

import { Paper } from '../paper';

export const MobileList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const ListItemCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.base.black.tints[10]};
`;

export const ListItemCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 0.5rem;
`;

export const ListItemCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

export const ListItemCard = styled(Paper)`
  padding: 1rem;
  gap: 0.5rem;
`;

type SortableHeaderCellContentsProps = {
  asc?: boolean;
};

export const SortableHeaderCellContents = styled.div<SortableHeaderCellContentsProps>`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  &:hover {
    color: ${({ onClick, theme }) =>
      onClick ? theme.colors.base.black.shades[70] : theme.colors.base.black.shades[50]};
  }

  ${SortIndicatorContainer} {
    ${SortIndicator}:first-child {
      span {
        background: ${({ theme, asc }) => theme.colors.base.black.shades[asc ? 90 : 50]};
      }
    }

    ${SortIndicator}:last-child {
      span {
        background: ${({ theme, asc }) => theme.colors.base.black.shades[asc ? 50 : 90]};
      }
    }
  }
`;

export const ScrollableScreenSize = styled(ScreenSize)`
  overflow: auto;
`;

type StyledTableProps = {
  withoutBorder?: boolean;
};

export const StyledTable = styled.table<StyledTableProps>`
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      th {
        text-align: left;
        padding: 1rem 1rem 1rem 0;
        color: ${({ theme }) => theme.colors.base.black.shades[50]};
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0.0075rem;
        word-wrap: break-word;
      }

      td.select-input {
        width: 3rem;
      }

      th:first-child {
        padding-left: 0.5rem;
      }

      th:last-child {
        padding-right: 0.5rem;
      }

      border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.primary.border.light};
    }
  }

  tbody {
    tr {
      td {
        padding: 0.75rem 1rem 0.75rem 0;
        text-align: left;
        padding-right: 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.375rem;
        word-wrap: break-word;
        white-space: nowrap;
      }

      td.select-input-row {
        width: 3rem;
      }

      td:first-child {
        padding-left: 0.5rem;
      }

      td:last-child {
        padding-right: 0.5rem;
      }

      border-bottom: ${({ withoutBorder, theme }) =>
        withoutBorder ? '' : `${theme.colors.primary.border.light} 0.0625rem solid`};
    }

    tr:last-child {
      border-bottom: none;
    }
  }
`;
