import styled from 'styled-components';

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 1.5rem;
  width: 100%;
  flex: 1;
  max-width: 33.75rem;
`;

export const SectionHeader = styled.div`
  padding-bottom: 0.5rem;
`;

export const PromotionSection = styled.div`
  padding: 1.5rem 0;
`;

export const ProcessSection = styled.div`
  background-color: ${({ theme }) => theme.colors.base.primary.shades['5']};
  border-radius: 1.5rem;
  padding: 1rem;
  white-space: break-spaces;
  margin-bottom: 1rem;
`;
