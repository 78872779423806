import styled from 'styled-components';

import { ShimmerOnWhite } from 'components';

import { CompanyProfileCard } from './updateCompanyProfileCard.styles';

export const ShimmerIcon = styled(ShimmerOnWhite)`
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  border-radius: 0.375rem;
`;

export const ShimmerText = styled(ShimmerOnWhite)`
  width: 100%;
  max-width: 19.5rem;
  height: 1.125rem;
  border-radius: 6.25rem;
`;

export const ShimmerButton = styled(ShimmerOnWhite)`
  width: 100%;
  height: 2rem;
  border-radius: 0.875rem;
`;

export const ShimmerHeader = styled(ShimmerOnWhite)`
  max-width: 10.5rem;
  width: 100%;
  height: 1.25rem;
  border-radius: 6.25rem;
  margin-block: 0.125rem 1.125rem;

  @media ${({ theme }) => theme.media.maxPhone} {
    margin-block: 1rem;
  }
`;

export const SkeletonCardStyle = styled(CompanyProfileCard)`
  background-color: ${({ theme }) => theme.colors.base.white.functional};
`;
