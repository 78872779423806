import { FC, useState, useEffect } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RegularText, StatusChip, Table } from 'components';
import { StatusType } from 'design/designVariables';
import { selectActiveCustomer } from 'features/auth/selectors';
import {
  selectIsInvoicesLoading,
  selectIsInvoicesHasNoItems,
  selectUpcomintPaymentsTableItems
} from 'features/invoices/selectors';
import { fetchInvoicesAsync } from 'features/invoices/slice';
import { UpcommingPaymentItem } from 'features/invoices/types';
import { useAppDispatch, useAuthSelector, useInvoicesSelector } from 'hooks/redux/hooks';
import { InvoiceStatusType, InvoiceType, SubmittedInvoice } from 'services/InvoicesService/types';

type TableRowItem = UpcommingPaymentItem & SubmittedInvoice & { toggleSelected?: never };

type StatusToChipColorMap = {
  [key in InvoiceStatusType]?: keyof StatusType;
};

type StatusTranslationMap = {
  [key in InvoiceStatusType]?: string;
};

type TypeTranslationMap = {
  [key in InvoiceType]?: string;
};

const statusToChipColorMap: StatusToChipColorMap = {
  FINANCED: 'yellow',
  PARTIALLY_PAID: 'green',
  OVERDUE: 'red'
};

const statusesTranslations: StatusTranslationMap = {
  FINANCED: 'unpaid',
  PARTIALLY_PAID: 'partiallyPaid',
  OVERDUE: 'overdue'
};

const typesTranslations: TypeTranslationMap = {
  STANDARD_INVOICE: 'buyerInvoice',
  CREDIT_MEMO: 'buyerInvoice',
  OVERPAYMENT_TO_CLIENT: 'overpayment',
  CLIENT_DEBT: 'clientDebt'
};

export type Sort = { dataColumnId?: string | number; sortOrder: SortOrder };

export const withNullCheck = (value?: string | number | boolean) => value || '';

type Props = {
  minBlockSize?: number;
};

const UpcomingPaymentsTable: FC<Props> = ({ minBlockSize }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const loading = useInvoicesSelector<typeof selectIsInvoicesLoading>(selectIsInvoicesLoading);
  const noItems = useInvoicesSelector<typeof selectIsInvoicesHasNoItems>(selectIsInvoicesHasNoItems);
  const items = useInvoicesSelector<typeof selectUpcomintPaymentsTableItems>(
    selectUpcomintPaymentsTableItems
  ).slice(0, 5);

  const [sortOrder, setSortOrder] = useState<string>();

  const columns: TableColumn<TableRowItem>[] = [
    {
      selector: (row) => withNullCheck(row.thirdPartyName),
      name: t('company'),
      id: 'name',
      sortField: 'thirdPartyName',
      format: (row) => {
        if (row.type === 'CLIENT_DEBT' || row.type === 'OVERPAYMENT_TO_CLIENT') {
          return <RegularText>{activeCustomer?.customerName}</RegularText>;
        }

        return <RegularText>{row.thirdPartyName}</RegularText>;
      }
    },
    {
      selector: (row) => withNullCheck(row.number),
      name: t('number'),
      id: 'number',
      sortField: 'invoiceNumber',
      format: (row) => <RegularText>{row.number}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.type),
      name: t('type'),
      id: 'type',
      sortField: 'invoiceType',
      format: (row) => <RegularText>{t(typesTranslations[row.type as InvoiceType] || '')}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.dueDate),
      name: t('dueDate'),
      id: 'dueDate',
      sortField: 'invoiceDueDate',
      format: (row) => <RegularText>{row.dueDate}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.status),
      name: t('status'),
      id: 'status',
      sortField: 'invoiceStatus',
      format: (row) => {
        if (row.status) {
          return (
            <StatusChip color={statusToChipColorMap[row.status as InvoiceStatusType]}>
              <RegularText>{t(statusesTranslations[row.status as InvoiceStatusType] || '')}</RegularText>
            </StatusChip>
          );
        }

        return null;
      }
    }
  ];

  const handleSort = (column: TableColumn<TableRowItem>, sortDirection: SortOrder) => {
    const { sortField } = column || {};

    setSortOrder(sortField ? `${sortField},${(sortDirection || 'asc').toUpperCase()}` : '');
  };

  useEffect(() => {
    if (contractId) {
      dispatch(
        fetchInvoicesAsync({
          contractId,
          invoiceStatus: ['FINANCED', 'PARTIALLY_PAID', 'OVERDUE'],
          page: '1',
          size: '5',
          sort: sortOrder
        })
      );
    }
  }, [sortOrder, contractId, dispatch]);

  return (
    <Table<TableRowItem>
      minNoDataContainerHeight={minBlockSize}
      noDataMessage={t(noItems ? 'noDuePayments' : 'noListItems')}
      onSort={handleSort}
      progressPending={loading}
      columns={columns}
      data={items}
      paginationPerPage={5}
    />
  );
};

export { UpcomingPaymentsTable };
