import styled from 'styled-components';

import { LinkButtonStyle } from 'components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    background-color: ${({ theme }) => theme.colors.base.white.functional};
    border-radius: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 0 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.black.shades[10]};
  width: 100%;

  &:last-of-type {
    padding: 0;
    border: none;
  }

  ${LinkButtonStyle} {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    width: 100%;
  }

  a {
    width: 100%;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    text-decoration: none;
    color: inherit;
  }
`;
