import styled from 'styled-components';

import { ShimerContainer, Typography } from 'components';

export const InputShimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })`
  height: 3rem;
  width: 100%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RoleOptionShimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })`
  height: 4.125rem;
  width: 100%;
`;

export const RolesTitleShimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })`
  margin-top: 0.25rem;
  height: 1.5rem;
  width: 3.75rem;
`;

export const DeleteButtonShimmer = styled(ShimerContainer).attrs({ color: '#F4F4F5', invert: true })`
  height: 1.5rem;
  width: 8rem;
`;

export const ExplanationAboutInvitationShimmer = styled(ShimerContainer).attrs({
  color: '#F4F4F5',
  invert: true
})`
  height: 1.375rem;
  width: 80%;
  margin-bottom: 1.5rem;
`;

export const ExplanationAboutInvitation = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
  display: inline-flex;
  margin-bottom: 0.5rem;
`;
