import styled from 'styled-components';

import { RegularText } from 'components';

export const Header = styled.div`
  margin-bottom: 2rem;

  & ${RegularText} {
    font-size: ${({ theme }) => theme.fontSizes.h3};
    line-height: 1.75rem;
  }
`;

type ImageProps = {
  src: string;
};

export const Image = styled.img<ImageProps>`
  width: 100%;
  height: auto;
  max-height: 9.125rem;
  object-fit: contain;
  margin-bottom: 1.5rem;
`;

export const Title = styled(RegularText)`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  line-height: 1.75rem;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-bottom: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BulletPointWrapper = styled.div`
  display: flex;
  margin: 0.75rem 0;
`;

export const BulletPointsWrapper = styled.div`
  padding: 0.5rem 0;

  ${RegularText} {
    padding-left: 0.6875rem;
    font-size: ${({ theme }) => theme.fontSizes.h5};
  }

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.primary.linkText};
    }
  }
`;
