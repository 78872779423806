import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import {
  Modal,
  Legend,
  UploadTitle,
  DocumentUpload,
  DownloadLink,
  InformationBox,
  InformationBoxTitle,
  ToastContainerLegacy,
  Toast,
  Loader
} from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import {
  useGetRequiredInfoQuery,
  useSubmitRequiredDocumentInfoMutation,
  useUploadRequiredInfoMutation
} from 'features/application/api';
import { selectGroupedRequiredInfo } from 'features/applications/selectors';
import { useModalContext } from 'features/nav/context/modalContext';
import { ApplicationService } from 'services';
import { DocumentTypes } from 'services/CapService/types';

import { RequiredDocumentUploadContainer } from './provideInformationModal.styles';

export type FileUpload = {
  file: File;
  documentType: DocumentTypes;
  applicationId?: string;
};

export type ToggleParams = { applicationId: string };

type DocumentTypeTranslations = Record<DocumentTypes, string>;

const documentTypeTranslations: DocumentTypeTranslations = {
  QUARTER_FINANCIAL_REPORT: 'quarterFinancialReport',
  ANNUAL_FINANCIAL_REPORT: 'annualFinancialReport',
  DETAILED_FINANCIAL_REPORT: 'detailedFinancialReport',
  FINANCIAL_FORECAST: 'financialForecast',
  BANK_ACCOUNT_STATEMENT: 'bankAccountStatement',
  BUSINESS_PLAN: 'businessPlan',
  INVEGA_SVV_DECLARATION: 'invegaSVVDeclaration',
  INVEGA_COMPANY_DECLARATION: 'invegaCompanyDeclaration',
  INVEGA_ATTACHMENT_4: 'invegaAttachment',
  REAL_PROPERTY_REGISTER: 'realPropertyRegister',
  PROPERTY_APPRAISAL: 'propertyAppraisal',
  COMMERCIAL_PROPOSAL: 'commercialProposal',
  REGISTRATION_CERTIFICATE: 'registrationCertificate',
  REGITRA_CERTIFICATE: 'regitraCertificate',
  PURCHASE_AND_SALE_CONTRACT: 'purchaseAndSaleContract',
  POWER_OF_ATTORNEY: 'powerOfAttorney',
  COPY_OF_CEO_ID: 'copyOfCEOId',
  ARTICLE_OF_ASSOCIATION: 'articleOfAssociation',
  CORPORATE_GROUP_STRUCTURE: 'corporateGroupStructure',
  CONSENT_OF_PERSONAL_DATA: 'consentOfPersonalData',
  LOAN_AGREEMENT: 'loanAgreement',
  LAND_USE_PLAN: 'landUsePlan',
  PLEDGED_PROPERTY_INSURANCE_CERTIFICATE: 'pledgedPropertyInsuranceCertificate',
  STATEMENT_FROM_BANK: 'statementFromBank',
  CONSTRUCTION_CONTRACT: 'constructionContract',
  POSTNUPTIAL_AGREEMENT: 'postnuptialAgreement',
  PRENUPTIAL_AGREEMENT: 'prenuptialAgreement',
  ORGANISATIONAL_CHART: 'organisationalChart',
  OTHER_DOCUMENT: 'otherDocument'
};

const downloadTemplateAvailableFor = {
  INVEGA_SVV_DECLARATION: true,
  INVEGA_COMPANY_DECLARATION: true,
  INVEGA_ATTACHMENT_4: true,
  CONSENT_OF_PERSONAL_DATA: true,
  DETAILED_FINANCIAL_REPORT: true
};

export const ProvideInformationModal = () => {
  const { registerModalHash } = useModalContext<ToggleParams>();
  const { t } = useTranslation();

  const [applicationId, setActionApplicationId] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [submitedWithoutParties, setSubmitedWithoutParties] = useState<boolean>(false);

  const { isFeatureEnabled } = useRemoteConfigContext();

  const [submitRequiredDocumentInfo] = useSubmitRequiredDocumentInfoMutation();
  const [uploadRequiredInfo] = useUploadRequiredInfoMutation();

  const { currentData: requiredInfoResponse, isFetching } = useGetRequiredInfoQuery(
    { applicationId },
    { skip: !applicationId }
  );

  const requiredInformation = selectGroupedRequiredInfo(requiredInfoResponse);

  const handleClose = () => {
    setFiles([]);
    setSubmitedWithoutParties(false);
    setError(false);
    setLoading(false);
    setIsOpen(false);
  };

  const handleConfirmation = async () => {
    if (!loading) {
      try {
        setError(false);
        setLoading(true);

        const missingRequiredInfoGroups = requiredInformation?.filter((info) => {
          return !files.some((file) => file.documentType === info.documentType);
        });

        if (missingRequiredInfoGroups.length > 0) {
          setSubmitedWithoutParties(true);

          return;
        }

        const requestData = {
          applicationId,
          infoId: requiredInfoResponse?.infoId || ''
        };

        await Promise.all(
          files.map((fileData) => {
            const formData = new FormData();

            formData.append('document', fileData.file);
            formData.append('documentType', fileData.documentType);
            formData.append('applicationId', applicationId || '');

            return uploadRequiredInfo({
              ...requestData,
              formData
            }).unwrap();
          })
        );

        await submitRequiredDocumentInfo({ ...requestData, applicationId: applicationId || '' }).unwrap();

        handleClose();
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpload = (documentType: DocumentTypes) => (files: File[]) => {
    files.forEach((file) => {
      setFiles((uploadedFiles) => [...uploadedFiles, { file, documentType }]);
    });

    return setSubmitedWithoutParties(false);
  };

  const handleRemoval = (documentType: DocumentTypes) => () => {
    setFiles(files.filter((upload) => upload.documentType !== documentType));
  };

  const downloadTemplate = (documentType: string) => async () => {
    if (!isDownloading) {
      try {
        setIsDownloading(true);

        return await ApplicationService.getRequiredDocumentTemplate(documentType);
      } finally {
        setIsDownloading(false);
      }
    }
  };

  useEffect(() => {
    const registry = registerModalHash('provide-information', ({ applicationId } = { applicationId: '' }) => {
      setActionApplicationId(applicationId || '');
      setIsOpen(true);
    });

    return () => {
      registry.unregister();
    };
  }, [registerModalHash]);

  return (
    <Modal
      testId="provide-information"
      label={t('provideInformation')}
      isOpen={Boolean(isOpen)}
      onClose={handleClose}
      secondaryActionLabel={t('cancel')}
      mainActionLabel={t('submitInformation')}
      mainActionhandler={handleConfirmation}
      mainActionTestId="modal-provide-information-submit-button"
    >
      {loading || isFetching ? <Loader /> : null}
      <Legend>{t('provideInformationExplanation')}</Legend>
      {submitedWithoutParties ? (
        <ToastContainerLegacy>
          <Toast isVisible header={t('requiredAllDocuments')} />
        </ToastContainerLegacy>
      ) : null}
      {error ? (
        <ToastContainerLegacy>
          <Toast isVisible message={t('generalErrorMessage')} />
        </ToastContainerLegacy>
      ) : null}
      {requiredInformation?.map((document) => (
        <InformationBox key={document.documentType}>
          <InformationBoxTitle>
            {t(documentTypeTranslations[document.documentType as DocumentTypes])}
          </InformationBoxTitle>
          {downloadTemplateAvailableFor[document.documentType as keyof typeof downloadTemplateAvailableFor] &&
          isFeatureEnabled('capRequiredInfoTemplatesDownload') ? (
            <DownloadLink
              testId={`download-template-${document.documentType}`}
              fileRequest={downloadTemplate(document.documentType)}
              fileName={documentTypeTranslations[document.documentType as DocumentTypes]}
            >
              <Icons.Download fill="red" />
              {t('downloadTemplate')}
            </DownloadLink>
          ) : null}
          {document.description.map((descriptionTitle: string) => {
            return (
              <RequiredDocumentUploadContainer key={document.documentType}>
                <DocumentUpload
                  onRemove={handleRemoval(document.documentType)}
                  buttonText={t('upload')}
                  maxUploadSizeWarning={t('maxFileSizeWarning', { sizeInMb: 15 })}
                  onClick={handleUpload(document.documentType)}
                  multipleFiles
                >
                  {descriptionTitle ? <UploadTitle>{descriptionTitle}</UploadTitle> : null}
                </DocumentUpload>
              </RequiredDocumentUploadContainer>
            );
          })}
        </InformationBox>
      ))}
    </Modal>
  );
};
