import styled from 'styled-components';

import { Typography } from 'components';

export const IntroCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  padding: 0.875rem 1rem;
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
`;

export const IntroCardHeader = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })``;
export const IntroCardSubheader = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.tints['70']};
`;

export const IntroCardRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
`;

export const IntroCardRowColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IntroView = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 33.75rem;
  width: 100%;
  height: 100%;

  button {
    width: 100%;
    margin-top: auto;
  }
`;
