import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import FormContext from 'features/application/context/FormContext';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { LoansAdditionalInfo } from 'services/CapService/types';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { FormContainer } from '../../components/formContainer';
import { selectLoansAdditionalInfo } from '../../selectors';
import { setLoansAdditionalInfo, submitApplicationAsync } from '../../slice';
import { FormStyle } from '../terms/terms.style';
import utils from '../utils/objectHelpers';

import { PURPOSE, PURPOSE_LIST } from './constants';
import { PurposeFields } from './purposeFields';

const initialTerms: LoansAdditionalInfo = {
  purpose: { type: undefined, description: '' }
};

const validateIfPurposeIsOther = Yup.string().when('type', (type: PURPOSE) => {
  if (type === PURPOSE.OTHER) {
    return Yup.string()
      .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
      .required(VALIDATION_MESSAGE_ID.REQUIRED)
      .max(100, VALIDATION_MESSAGE_ID.MAX_LENGTH);
  }
  return Yup.string().optional();
});

export const validationSchema = Yup.object({
  purpose: Yup.object({
    type: Yup.string()
      .oneOf(PURPOSE_LIST.map((purpose) => purpose.value))
      .required(VALIDATION_MESSAGE_ID.REQUIRED),
    description: validateIfPurposeIsOther
  })
});

const PurposeForm = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const values = useRef({ ...initialTerms });

  const initialValues = utils.copyReadOnlyObjectValues(
    values.current,
    useApplicationSelector(selectLoansAdditionalInfo)
  );

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit, trigger } = useFormHandlers(
    initialValues,
    validationSchema,
    'onBlur'
  );

  const { setFormHandlers } = useContext(FormContext);

  const submitHandler = handleSubmit(
    (data: LoansAdditionalInfo) => {
      dispatch(setLoansAdditionalInfo(data));
      dispatch(submitApplicationAsync());
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(
    () =>
      setFormHandlers({
        submitHandler
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFormHandlers]
  );

  const isPurposeEntered =
    getValues('purpose.type') === PURPOSE.OTHER
      ? getValues('purpose.description') !== ''
      : !!getValues('purpose.type');

  return (
    <FormContainer isValid={isPurposeEntered} onNextClick={submitHandler}>
      <FormStyle locale={i18n.language}>
        <PurposeFields trigger={trigger} validationHelpers={validationHelpers} getValues={getValues} />
      </FormStyle>
    </FormContainer>
  );
};

export default PurposeForm;
