import { AxiosResponse } from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountText, Column, DownloadLink, InputView, SecondaryText } from 'components';
import { InvoicePayoutCollection } from 'services/FactoringStatementsService/types';
import { camelCase } from 'utils';

import {
  InvoiceDetailsCard,
  HeaderContainer,
  Title,
  TransactionDetails,
  TotalsSection
} from './invoiceDetails.styles';

type Props = {
  payouts: InvoicePayoutCollection;
  onClick?: (actId: string) => Promise<AxiosResponse<any, any>> | undefined;
};

export const InvoiceDetailsPayouts: FC<Props> = ({ payouts, onClick }) => {
  const { t } = useTranslation();

  const onClickHandler = (actId: string) => async () => {
    return onClick?.(actId);
  };

  return (
    <InvoiceDetailsCard>
      <HeaderContainer>
        <Title>{t('payouts')}</Title>
      </HeaderContainer>
      {payouts.invoicePayouts?.length ? (
        <>
          <Column>
            {payouts.invoicePayouts &&
              payouts.invoicePayouts.map((payout) => {
                return (
                  <TransactionDetails>
                    <InputView orientation="vertical" label={t(camelCase(payout.payoutType || ''))}>
                      {payout.payoutPaymentDate}
                      <DownloadLink fileRequest={onClickHandler(payout.payoutActId || '')}>
                        {t('view')}
                      </DownloadLink>
                    </InputView>
                    <AmountText className="payout-amount" currency={payout.invoicePaymentAmount?.currency}>
                      {payout.invoicePaymentAmount?.amount}
                    </AmountText>
                  </TransactionDetails>
                );
              })}
          </Column>
          <TotalsSection>
            <InputView orientation="horizontal" label={t('total')}>
              <AmountText className="payouts-amount" currency={payouts.totalInvoicePaymentAmount?.currency}>
                {payouts.totalInvoicePaymentAmount?.amount}
              </AmountText>
            </InputView>
          </TotalsSection>
        </>
      ) : (
        <SecondaryText>{t('emptyInvoiceStatementsState', { item: t('payouts') })}</SecondaryText>
      )}
    </InvoiceDetailsCard>
  );
};
