import {
  DragEventHandler,
  MouseEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { Toast } from '../toastV2';

import { Area, InfoConfig } from './area';
import { FileChip } from './fileChip';
import { FileNamesContainer, HiddenInput, ToastContainer, UploadAreaWrapper } from './uploadArea.styles';

type FileStatus = {
  filename: string;
  submiting: boolean;
};

type Props = {
  fileSizeInMB?: number;
  allowedExtensions?: string;
  maxFilesCount?: number;
  validationErrors?: ValidationError[];
  onDelete?: (filename: string, validationErrors: ValidationError[]) => unknown;
  onUpload?: (files: File[], validationErrors: ValidationError[]) => Promise<void | ValidationError[]> | void;
  onPreview?: (filename: string) => Promise<unknown>;
  hideDefaultArea?: boolean;
  filenames?: string[];
  info?: InfoConfig;
  fileSubmitInfo?: FileStatus[];
};

export type ValidationError = {
  name: string;
  message: string;
};

function filterOutDuplicateObjects(errors: ValidationError[]) {
  const uniqueErrors: ValidationError[] = [];
  const uniqueKeys = new Set();

  errors.forEach((error) => {
    const { name, message } = error;
    const key = `${name}|${message}`;

    if (!uniqueKeys.has(key)) {
      uniqueErrors.push(error);
      uniqueKeys.add(key);
    }
  });

  return uniqueErrors;
}

const fileFormatIsAllowed = (filename: string, allowedExtensions?: string) => {
  if (!allowedExtensions) {
    return true;
  }

  const allowedExtensionsArray = allowedExtensions.split(',');
  const fileExtension = `.${filename.split('.').pop()}`;

  if (!fileExtension) {
    return false;
  }

  return allowedExtensionsArray.includes(fileExtension);
};

const fileSizeInBytes = (fileSizeInMB: number) => fileSizeInMB * 1024 * 1024;
const truncateFilename = (filename: string) =>
  filename.length > 24
    ? `${filename.substring(0, 13)}...${filename.substring(filename.length - 8)}`
    : filename;

export type UploadAreaElement = HTMLInputElement & {
  isValid: () => boolean;
  getFiles: () => File[];
  getFileStatuses: () => ValidationError[];
  handlers?: {
    onClick?: MouseEventHandler<HTMLDivElement>;
    onDragOver?: DragEventHandler<HTMLDivElement>;
    onDragLeave?: DragEventHandler<HTMLDivElement>;
    onDrop?: DragEventHandler<HTMLDivElement>;
  };
};

const preventDefaultDrag = (e: DragEvent) => {
  e.preventDefault();

  window.document.body.classList.add('drag-in-progress');

  if (e.dataTransfer) {
    e.dataTransfer.dropEffect = 'none';
  }
};

const preventDefaultDrop = (e: DragEvent) => {
  e.preventDefault();

  window.document.body.classList.remove('drag-in-progress');
};

export const UploadArea = forwardRef<UploadAreaElement, Props>(
  (
    {
      filenames,
      onPreview,
      onUpload,
      onDelete,
      validationErrors,
      fileSizeInMB = 15,
      maxFilesCount = 5,
      allowedExtensions = '.docx,.xlsx,.csv,.jpg,.tiff,.git,.png,.pdf,.pptx,.adoc,.edoc,.doc,.xls,.ppt,.jpeg,.asice,.bdoc,.cdoc.',
      fileSubmitInfo,
      hideDefaultArea,
      info
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [localFiles, setLocalFiles] = useState<File[]>([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const [localValidationErrors, setLocalValidationErrors] = useState<ValidationError[]>(
      validationErrors || []
    );
    const [localFilenames, setFilenames] = useState<string[]>(filenames || []);
    const [localFileStatus, setLocalFileStatus] = useState<FileStatus[]>(fileSubmitInfo || []);

    const handleDelete = async (filename: string) => {
      try {
        if (inputRef.current) {
          const { files } = inputRef.current;

          if (files) {
            const filteredValidationErrors = localValidationErrors.filter((error) => error.name !== filename);

            await onDelete?.(filename, filteredValidationErrors);

            const filesarray = Array.from(files);

            const filteredFiles = filesarray.filter((file) => file.name !== filename);
            const filteredLocalFilenames = localFilenames.filter((name) => name !== filename);

            const newFilesDataSet = new DataTransfer();

            filteredFiles.forEach((file) => newFilesDataSet.items.add(file));
            inputRef.current.files = newFilesDataSet.files;

            setLocalValidationErrors(filteredValidationErrors);
            setFilenames(filteredLocalFilenames);
            setLocalFiles(filteredFiles);
          }
        }
      } catch {
        // fail silently
      }
    };

    const handleUpload = async (files: FileList) => {
      const newFilesDataSet = new DataTransfer();
      const filesarray = Array.from(files);
      const errors: ValidationError[] = [];

      const appendedFiles = filesarray
        .filter((file) => !localFiles.find((localFile) => localFile.name === file.name))
        .slice(0, maxFilesCount);

      try {
        setLocalFileStatus(
          filesarray
            .filter((file) => !localFiles.find((localFile) => localFile.name === file.name))
            .map((file) => ({ filename: file.name, submiting: true }))
        );

        const maxFilesSizeArray = [...localFiles, ...appendedFiles];

        maxFilesSizeArray.forEach((file) => newFilesDataSet.items.add(file));

        maxFilesSizeArray.map((file) => {
          if (!fileFormatIsAllowed(file.name?.toLowerCase(), allowedExtensions)) {
            errors.push({
              name: file.name,
              message: t('fileFormatIsIncorrect', {
                filename: file.name,
                formats: allowedExtensions?.replaceAll(',', ', ').replaceAll('.', '')
              })
            });
          }

          if (file.size > fileSizeInBytes(fileSizeInMB)) {
            errors.push({
              name: file.name,
              message: t('fileIsToLarge', { filename: file.name })
            });
          }
        });

        if (inputRef.current) {
          setLocalFiles(maxFilesSizeArray);
          inputRef.current.files = newFilesDataSet.files;
          setFilenames(
            Array.from(
              new Set([
                ...localFilenames,
                ...Array.from(inputRef.current?.files || []).map((file) => file.name)
              ])
            )
          );

          const validationErrors = await onUpload?.(maxFilesSizeArray, errors);

          if (validationErrors) {
            errors.push(...validationErrors);
          }

          setLocalValidationErrors([...errors]);
        }
      } catch {
        //fail silently
      } finally {
        setLocalFileStatus(filesarray.map((file) => ({ filename: file.name, submiting: false })));
      }
    };

    const appendFiles = (files: FileList) => {
      const newFilesDataSet = new DataTransfer();
      const newfilesarray = Array.from(files);
      const previousfilesarray = Array.from(localFiles || []);

      const filenamesArray = [...previousfilesarray, ...newfilesarray].map((file) => file.name);

      const uniqueFilenames = Array.from(new Set([...filenamesArray]));

      uniqueFilenames.forEach((filename) => {
        const file = newfilesarray.find((file) => file.name === filename);

        if (file) {
          newFilesDataSet.items.add(file);
        } else {
          const previousFile = previousfilesarray.find((file) => file.name === filename);

          if (previousFile) {
            newFilesDataSet.items.add(previousFile);
          }
        }
      });

      return newFilesDataSet;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleUpload(appendFiles(e.target.files || new DataTransfer().files).files);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      handleUpload(appendFiles(e.dataTransfer.files).files);
      e.currentTarget.classList.remove('dragover');
      window.document.body.classList.remove('drag-in-progress');

      if (e.currentTarget) {
        (e.currentTarget as HTMLDivElement).style.cursor = 'default';
      }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'copy';
      }

      if (e.currentTarget) {
        (e.currentTarget as HTMLDivElement).style.cursor = 'pointer';
        e.currentTarget.classList.add('dragover');
      }

      window.document.body.classList.remove('drag-in-progress');
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      e.currentTarget.classList.remove('dragover');

      window.document.body.classList.add('drag-in-progress');

      if (e.currentTarget) {
        e.dataTransfer.dropEffect = 'none';
      }
    };

    const handleClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    const handlers = {
      onClick: handleClick,
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
      onDrop: handleDrop
    };

    useImperativeHandle(ref, () => ({
      ...(inputRef.current as HTMLInputElement),
      getFiles: () => localFiles,
      getFileStatuses: () => localValidationErrors,
      isValid: () => !localValidationErrors.length,
      handlers
    }));

    useEffect(() => {
      if (filenames) {
        setFilenames(Array.from(new Set([...localFilenames, ...filenames])));
      }
    }, [filenames]);

    useEffect(() => {
      if (validationErrors) {
        setLocalValidationErrors(validationErrors);
      }
    }, [validationErrors]);

    useEffect(() => {
      if (fileSubmitInfo) {
        setLocalFileStatus(fileSubmitInfo);
      }
    }, [fileSubmitInfo]);

    useEffect(() => {
      window.addEventListener('dragover', preventDefaultDrag);
      window.addEventListener('drop', preventDefaultDrop);

      return () => {
        window.removeEventListener('dragover', preventDefaultDrag);
        window.removeEventListener('drop', preventDefaultDrop);
        window.document.body.classList.remove('no-drop');
      };
    }, []);

    const hasValidationErrors = Boolean(localValidationErrors?.length);

    return (
      <UploadAreaWrapper>
        <Area
          id="file-upload-drag-area"
          dataTestId="file-upload-drag-area"
          hideArea={hideDefaultArea}
          handlers={handlers}
          allowedExtensions={allowedExtensions}
          maxFilesCount={maxFilesCount}
          hiddenInput={
            <HiddenInput
              onChange={handleInputChange}
              ref={inputRef}
              type={'file'}
              multiple
              accept={allowedExtensions}
            />
          }
          info={info}
          fileSizeInMB={fileSizeInMB}
        />
        {hasValidationErrors ? (
          <ToastContainer>
            <Toast
              isVisible
              variant="error"
              header={t('errorsWhileUploadingFiles')}
              list={filterOutDuplicateObjects(localValidationErrors).map((error) => ({
                message: error.message,
                id: `${error.name}-${error.message}`
              }))}
            />
          </ToastContainer>
        ) : null}
        {localFilenames && (
          <FileNamesContainer>
            {localFilenames.map((filename, index) => {
              const error = Boolean(localValidationErrors?.find((status) => status?.name === filename));

              return (
                <FileChip
                  loading={localFileStatus?.find((file) => file.filename === filename)?.submiting}
                  key={index}
                  filename={filename}
                  error={error}
                  onPreview={onPreview}
                  handleDelete={handleDelete}
                  formatFilename={truncateFilename}
                  file={localFiles.find((file) => file.name === filename)}
                />
              );
            })}
          </FileNamesContainer>
        )}
      </UploadAreaWrapper>
    );
  }
);
