import { useLocation } from 'react-router-dom';

import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { getRoutesInMaintenance, MaintenanceInfo } from 'defaultConfiguration/getRoutesInMaintenance';

export const useIsRouteInMaintenanceMode = (): [boolean, MaintenanceInfo[], MaintenanceInfo | undefined] => {
  const location = useLocation();
  const { remoteConfig } = useRemoteConfigContext();

  const routesInMaintenance = getRoutesInMaintenance(remoteConfig);

  const route = routesInMaintenance.find((route) => {
    return route.url === location.pathname;
  });

  return [!!route, routesInMaintenance, route];
};
