import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const LoginMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
