export const ObjectHelpers = <T extends Record<string, unknown>>(toMap: T) => {
  return {
    map: (predicate: (currentValue: T[keyof T]) => unknown): T => {
      const entries = Object.entries(toMap);

      const mappedEntries = entries.map(([key]) => [key, predicate(toMap[key as keyof typeof toMap])]);

      return Object.fromEntries(mappedEntries);
    }
  };
};

export const copyReadOnlyObjectValues = <T extends Record<string, unknown>>(
  target: Partial<T>,
  source: Partial<T>
): Partial<T> => {
  return Object.assign(target, source || {});
};

export default { copyReadOnlyObjectValues };
