export const queryBuilder = <T extends object>(url: string, payload: T) => {
  let searchParams: string[] = [];

  Object.keys(payload).forEach((key) => {
    if (payload[key as keyof T]) {
      if (Array.isArray(payload[key as keyof T])) {
        searchParams = [
          ...searchParams,
          ...(payload[key as keyof T] as unknown as Array<string>).map(
            (value) => `${key}=${encodeURIComponent(value)}`
          )
        ];
      } else {
        searchParams = [
          ...searchParams,
          `${key}=${encodeURIComponent(payload[key as keyof T] as unknown as string)}`
        ];
      }
    }
  });

  const queryString = searchParams.join('&').trim();

  return queryString ? `${url}?${queryString}` : url;
};
