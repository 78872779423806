import { useState } from 'react';

import { usePatchUserMutation } from 'features/users/slice';

const useUpdatePhoneNumber = (
  userId?: string | number,
  onSuccess?: () => unknown,
  onError?: () => unknown
) => {
  const [updateUser] = usePatchUserMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updatePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber && userId && !isSubmitting) {
      setIsSubmitting(true);

      try {
        await updateUser({
          mobilePhoneNumber: phoneNumber
        }).unwrap();

        onSuccess?.();
      } catch (error) {
        alert(error);
        onError?.();
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return { isLoading: isSubmitting, updatePhoneNumber };
};

export default useUpdatePhoneNumber;
