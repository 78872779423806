import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import arrowLeft from 'assets/icons/arrowLeft.svg';
import { LinkButton } from 'components';

import {
  LoginContainerStyle,
  LoginHeaderContainer,
  LoginHeaderLabelsContainer,
  Header,
  ExplanationText,
  HeaderContent
} from './loginContainer.styles';

export type LoginContainerProps = {
  children: ReactNode;
  onBack?: () => void;
  headerLabel?: string;
  subheaderLabel?: string | ReactNode;
  headerPictogram?: string;
  dense?: boolean;
};

const LoginContainer: FC<LoginContainerProps> = ({
  children,
  headerLabel,
  headerPictogram,
  subheaderLabel,
  dense,
  onBack
}) => {
  const { t } = useTranslation();

  return (
    <LoginContainerStyle dense={dense}>
      <LoginHeaderContainer>
        <LoginHeaderLabelsContainer>
          {headerLabel ? (
            <Header>
              {onBack ? (
                <LinkButton color="Black" iconOnly icons={{ left: arrowLeft }} onClick={onBack} />
              ) : null}
              <HeaderContent>
                {t(headerLabel)} {headerPictogram ? <img src={headerPictogram} alt={headerLabel} /> : null}
              </HeaderContent>
            </Header>
          ) : null}
          {subheaderLabel ? (
            <ExplanationText>
              {typeof subheaderLabel === 'string' ? t(subheaderLabel || '') : subheaderLabel}
            </ExplanationText>
          ) : null}
        </LoginHeaderLabelsContainer>
      </LoginHeaderContainer>
      {children}
    </LoginContainerStyle>
  );
};

export default LoginContainer;
