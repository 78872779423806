import { SortOrder } from 'react-data-table-component';
import styled from 'styled-components';

import { Typography } from 'components/typography';

import { StyledCheckbox } from '../checkbox/checkbox.style';
import { InputLabel } from '../input/input.style';
import { LinkButton } from '../link';
import { ShimerContainer } from '../loader';
import { RegularText, SecondaryText } from '../text';

export const SortIndicator = styled.span`
  width: 0.5rem;
  height: 0.3125rem;
  display: inline-block;
  position: relative;

  span {
    top: 0;
    position: absolute;
    width: 0.0625rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.colors.secondary.textColor};
    display: inline-block;

    &:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }

    &:last-of-type {
      right: 0.3125rem;
      transform: rotate(-45deg);
    }
  }
`;

export const CellCollumn = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: none;

  &:first-child {
    white-space: normal;
  }
`;

export const SortIndicatorContainer = styled.div`
  position: relative;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;

  ${SortIndicator} {
    &:last-of-type {
      transform: rotate(180deg) translateX(0.3125rem);
    }
  }
`;

export const CellWithCheckbox = styled.div`
  display: flex;
  position: relative;

  ${StyledCheckbox} {
    margin-right: 0.625rem;

    span {
      color: ${({ theme }) => theme.colors.secondary.textColor};
    }
  }

  ${SecondaryText} {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const Header = styled(RegularText)`
  font-size: 1rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1.25rem;

  ${Header} {
    line-height: 1.25rem;
  }

  ${CellWithCheckbox} {
    padding-top: 0.625rem;
  }
`;

type TableContainerProps = {
  sortedBy?: { dataColumnId?: string | number; sortOrder: SortOrder };
  clickable?: boolean;
};

export const TableContainer = styled.section<TableContainerProps>`
  position: relative;
  width: 100%;

  div[data-sort-id='${({ sortedBy }) => sortedBy?.dataColumnId}'] {
    ${SortIndicator} {
      ${({ sortedBy }) =>
        sortedBy?.sortOrder === 'asc'
          ? `&:last-of-type {
        display: none;
      }`
          : `&:first-of-type {
        display: none;
      }`}
    }
  }

  .rdt_TableHeader {
    padding: 0;
    height: 1.625rem;
    min-height: 1.625rem;
    font-size: 1.25rem;
    margin-bottom: 2.25rem;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  .rdt_Table {
    .rdt_TableRow,
    .rdt_TableHeadRow {
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.border.light};
      align-items: center;
      min-height: 2.75rem;
    }

    .rdt_ExpanderRow {
      display: flex;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.border.light};
      align-items: center;
      min-height: 2.75rem;
    }

    .rdt_TableRow {
      min-height: 2.75rem;
      padding: 0;
    }

    .rdt_TableHeadRow {
      border-top: 1px solid ${({ theme }) => theme.colors.primary.border.light};

      .rdt_TableCol_Sortable,
      .rdt_TableCell {
        color: ${({ theme }) => theme.colors.secondary.textColor};
      }
    }

    .rdt_TableCol_Sortable,
    .rdt_TableCell {
      user-drag: none;
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      min-height: 2.75rem;
      padding: 0.625rem 0 0.625rem 0.625rem;
      cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};

      & > div {
        user-drag: none;
        pointer-events: ${({ clickable }) => (clickable ? 'none' : 'all')};
        white-space: normal;

        ${CellCollumn} {
          white-space: normal;
        }
      }
    }

    .table-cell_asset {
      & > div {
        width: 100%;

        ${CellCollumn} {
          white-space: nowrap;
          width: 100%;

          ${RegularText} {
            display: block;
            max-width: 98%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .table-row_selected {
      background-color: ${({ theme }) => theme.colors.base.white.functional};
    }
  }

  .rdt_TableCol {
    padding-left: 0;
  }

  .table-cell_amount_type {
    display: flex;
    justify-content: flex-end;
  }

  .table-cell_full_width {
    display: flex;
    width: 100%;

    & > div {
      display: flex;
      width: 100%;
    }
  }

  .rdt_TableHeadRow {
    ${StyledCheckbox} {
      margin-left: 0.625rem;
      margin-top: 0.1875rem;
    }
  }

  .table-input-cell > div {
    width: 100%;
  }

  .table-invalid-row {
    background-color: ${({ theme }) => theme.colors.toast.error.background};
  }
`;

export const SecondaryTextWithAction = styled.div`
  display: flex;
`;

export const MobileNoDataExistsMessage = styled(SecondaryText)`
  display: flex;
`;

export const MobileList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MobileNoDataExistsMessage} {
    justify-content: center;
  }

  ${CellWithCheckbox} {
    display: flex;
    justify-content: space-between;
  }

  ${HeaderContainer} {
    flex-direction: column;
    padding-bottom: 1rem;
  }
`;

type MobileListItemProps = {
  collapsible?: boolean;
};

export const MobileListItem = styled.div<MobileListItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: ${({ theme, collapsible }) =>
    collapsible ? 'none' : `0.0625rem solid ${theme.colors.primary.border.light}`};
  background-color: ${({ theme, collapsible }) => (collapsible ? theme.colors.formElement.background : '')};
  padding: ${({ collapsible }) => (collapsible ? '1rem' : '1rem 0 0')};
  border-radius: ${({ collapsible }) => (collapsible ? '1.125rem' : 0)};
  margin-bottom: ${({ collapsible }) => (collapsible ? '1rem' : '1rem')};

  &:first-of-type {
    border-top: none;
    padding: ${({ collapsible }) => (collapsible ? '1rem' : 0)};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const MobileListItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.75rem;
  line-height: 1.25rem;
  flex-wrap: wrap;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const OuterTableContainer = styled.div`
  ${InputLabel} {
    padding: 0;
    font-size: 0.75rem;
    padding-left: 0.625rem;
    display: inline-flex;
    align-items: center;
  }
`;

type Props = {
  active?: boolean;
};

export const PageButton = styled(LinkButton)<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 0.0625rem solid
    ${({ theme, active }) => (active ? theme.colors.primary.linkText : theme.colors.primary.border.light)};
  border-radius: 0.5rem;
  margin-right: 0.3125rem;
  color: ${({ theme, active }) => (active ? '#ffffff' : theme.colors.secondary.textColor)};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.linkText : theme.colors.base.white.functional};

  svg {
    margin-right: 0;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-top: 1.25rem;

  ${PageButton} {
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const AmountCellColumn = styled(CellCollumn)`
  align-items: flex-end;
`;

export const HeaderCell = styled.div`
  display: flex;
  padding: 0.625rem 0;
`;

type SkeletonRowContainerProps = {
  rowHeight?: number;
};

export const SkeletonRowContainer = styled.div<SkeletonRowContainerProps>`
  display: flex;
  height: ${({ rowHeight }) => `${rowHeight}px` || 'auto'};
  justify-content: center;
  align-items: center;
`;

export const SkeletonRow = styled(ShimerContainer)<SkeletonRowContainerProps>`
  height: ${({ rowHeight }) => (rowHeight ? `${rowHeight}px` : 'auto')};
  min-width: 100%;
  border-radius: 0.5rem;
`;

export const PaginationRow = styled(SkeletonRow)`
  min-height: 3.25rem;
  margin-bottom: 0.0625rem;
  min-width: 100%;
  border-radius: 0.1875rem;
`;

type TableStateContainerProps = {
  visible?: boolean;
  minNoDataContainerHeight?: number;
  headerHeight?: number;
};

export const TableSkeletonContainer = styled.div<TableStateContainerProps>`
  display: flex;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  flex-direction: column;
  width: 100%;
  position: absolute;
  background: #ffffff;
  z-index: 9;
  overflow: hidden;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: opacity 0.1s;
`;

export const NoDataContainer = styled.div<TableStateContainerProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  border-bottom: 0.0625rem solid ${({ theme }) => theme.colors.primary.border.light};
  width: 100%;
  background: #ffffff;
  z-index: 3;
  overflow: hidden;
  min-height: 2.8125rem;
  min-block-size: ${({ minNoDataContainerHeight, headerHeight }) =>
    minNoDataContainerHeight && headerHeight ? `${minNoDataContainerHeight - headerHeight}px` : '2.8125rem'};
`;

type MobileListCollapsibleItemProps = {
  isOpen: boolean;
};

export const MobileListItemHeaderWrapper = styled.div<MobileListCollapsibleItemProps>`
  display: flex;
  justify-content: space-between;

  svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const MobileListItemHeader = styled(Typography.Header).attrs({
  type: 'h3',
  weight: 'medium'
})`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.shades[90]};
  text-align: center;
`;

export const MobileListCollapsibleItem = styled.div<MobileListCollapsibleItemProps>`
  height: ${({ isOpen }) => (isOpen ? '8rem' : 0)};
  overflow: hidden;
  transition: height ease 0.9s;
  margin-top: ${({ isOpen }) => (isOpen ? '0.5rem' : 0)};
  border-top: ${({ theme, isOpen }) => (isOpen ? `1px solid ${theme.colors.primary.border.light}` : '')};
`;
