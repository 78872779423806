import { RestClient } from '../RestClient';

import { CrashReportRequest, ErrorReportRequest } from './types';


const restClient = new RestClient();

export class AppService {
  static reportError(payload: ErrorReportRequest) {
    const url = '/api/reports/error';
    return restClient.POST<unknown, unknown>(
      url,
      {},
      { headers: { 'x-crash-report': JSON.stringify(payload) } }
    );
  }

  static reportCrash(payload: CrashReportRequest) {
    const url = '/api/reports/error';
    return restClient.POST<unknown, unknown>(
      url,
      {},
      { headers: { 'x-crash-report': JSON.stringify(payload) } }
    );
  }

  static reportConfigurationFailure(payload: Error) {
    const url = '/api/reports/configuration/failure';
    return restClient.POST<unknown, unknown>(
      url,
      {},
      { headers: { 'x-crash-report': JSON.stringify(payload) } }
    );
  }
}
