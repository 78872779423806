import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountText, InputView } from 'components';
import { SubmittedInvoice } from 'services/InvoicesService/types';

import { Column, InvoiceTotalsCard } from './invoiceDetails.styles';

type Props = {
  invoiceDetails: SubmittedInvoice;
};

export const InvoiceDetailsTotals: FC<Props> = ({ invoiceDetails }) => {
  const { t } = useTranslation();

  return (
    <InvoiceTotalsCard>
      <Column>
        <InputView orientation="vertical" label={t('advance')}>
          <AmountText currency={invoiceDetails.advanceAmountTotal?.currency}>
            {invoiceDetails.advanceAmountTotal?.amount}
          </AmountText>
        </InputView>
        <InputView orientation="vertical" label={t('reminder')}>
          <AmountText currency={invoiceDetails.reserveAmountTotal?.currency}>
            {invoiceDetails.reserveAmountTotal?.amount}
          </AmountText>
        </InputView>
        <InputView orientation="vertical" label={t('placeholderUploadTransferClaim')}>
          <AmountText currency={invoiceDetails.invoiceAmountAssigned?.currency}>
            {invoiceDetails.invoiceAmountAssigned?.amount}
          </AmountText>
        </InputView>
        <InputView orientation="vertical" label={t('totalInvoiceAmount')}>
          <AmountText currency={invoiceDetails.invoiceAmount?.currency}>
            {invoiceDetails.invoiceAmount?.amount}
          </AmountText>
        </InputView>
      </Column>
    </InvoiceTotalsCard>
  );
};
