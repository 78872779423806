import styled from 'styled-components';

import shield from 'assets/icons/shield.svg';
import { Typography } from 'components';
import { Button } from 'components/buttonV2';

export const CompanyProfileCard = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  background-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
  border-radius: 1rem;
`;

export const CardContentContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media ${({ theme }) => theme.media.maxPhone} {
    flex-direction: column;
  }
`;

export const UpdateProfileButton = styled(Button).attrs({
  variant: 'StrokedWhite',
  size: 'S',
  media: { maxPhone: { size: 'L' } }
})`
  width: 100%;
`;

export const ShieldIcon = styled.div`
  background-image: ${() => `url("${shield}")`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const CardContentHeader = styled(Typography.Header).attrs({ type: 'h5', weight: 'medium' })`
  color: ${({ theme }) => theme.colors.base.white.functional};
`;

export const CardContentBody = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.white.functional};
`;
