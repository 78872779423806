import { ReactNode } from 'react';

import { ProductCard } from 'components/productCard';
import { TotalsCardContainer } from 'components/productTotalsCard';
import { isFeatureEnabled } from 'defaultConfiguration/isFeatureEnabled';
import { FactoringContractsTotalsCard } from 'features/contracts/components';
import {
  CreditLineContractsTotalsCard,
  LeasingContractsTotalsCard,
  LoansContractsTotalsCard,
  RbfContractsTotalsCard
} from 'features/loans/components';

export type Placement = 'Hero' | 'SubHero' | 'Card';

export type Card = {
  placement: Placement;
  children: ReactNode;
  display?: boolean;
};

export const getFinanceCards = (loading: boolean, showEmptyProductsBanner?: boolean): Card[] => [
  {
    placement: 'Card',
    children: <FactoringContractsTotalsCard loading={loading} />,
    display: isFeatureEnabled('factoring')
  },
  {
    placement: 'Card',
    children: <LoansContractsTotalsCard loading={loading} />,
    display: isFeatureEnabled('loans')
  },
  {
    placement: 'Card',
    children: <CreditLineContractsTotalsCard loading={loading} hideOnLoading />,
    display: isFeatureEnabled('creditLine')
  },
  {
    placement: 'Card',
    children: <LeasingContractsTotalsCard loading={loading} hideOnLoading />,
    display: isFeatureEnabled('leasing')
  },
  {
    placement: 'Card',
    children: <RbfContractsTotalsCard loading={loading} hideOnLoading />,
    display: isFeatureEnabled('rbf')
  },
  {
    placement: 'Card',
    children: (
      <TotalsCardContainer>
        <ProductCard
          label={'getProductsExplanation'}
          link={{
            to: '/new-application',
            label: 'getProducts'
          }}
        />
      </TotalsCardContainer>
    ),
    display: showEmptyProductsBanner && !loading
  }
];
