import { Locale, setDefaultOptions } from 'date-fns';
import { enGB, lt } from 'date-fns/locale';
import { FC, ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Flags from 'assets/icons/flags';
import { LinkButton, ScreenSize } from 'components';
import { MobileView } from 'components/pageV2';
import { useCheckIfClickedOutside } from 'features/nav/hooks';
import { getLanguageDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';

import {
  DropdownContainer,
  DropdownHeader,
  LanguageIconCircle,
  LanguageDropdownListContainer,
  LanguagesListItem,
  LanguagesDropdownList,
  SelectedLanguageIconCircle,
  LanguageName
} from '../dropdown/dropdown.styles';

import { saveLanguagePreferenceWithConsentCheck } from './utils';

const availableLanguages: Languages[] = ['lt', 'en'];

type CountryFlags = Record<Languages, ReactNode>;

const dateFnsLocales: Record<Languages, Locale> = {
  lt: lt,
  en: enGB
};

export const countryFlags: CountryFlags = {
  lt: <Flags.Lithuania />,
  en: <Flags.GreatBritain />
};

type Props = {
  onLanguageChange?: (lang: string) => unknown;
};

export const LanguagesMenuDropdown: FC<Props> = ({ onLanguageChange }) => {
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const selectedLanguage = i18n.resolvedLanguage;

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const refMobile = useRef() as React.MutableRefObject<HTMLDivElement>;

  useCheckIfClickedOutside(setIsOpen, isOpen, [ref, refMobile]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const cookiebotScript = document.getElementById('Cookiebot');

    if (cookiebotScript) {
      if (cookiebotScript.getAttribute('data-culture') !== i18n.language) {
        cookiebotScript.setAttribute('data-culture', i18n.language);

        if (window.Cookiebot.bannerIsShown) {
          //force translations renew for banner
          window.Cookiebot.renew();
        }
      }
    }
  }, [i18n.language]);

  const changeLanguage = (lang: string) => (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDefaultOptions({ locale: dateFnsLocales[lang as Languages] });
    i18n.changeLanguage(lang);
    setIsOpen(false);
    document.documentElement.lang = lang;

    saveLanguagePreferenceWithConsentCheck(lang);

    onLanguageChange?.(lang);
  };

  const availableLanguageOptions = availableLanguages.map((language) => {
    return (
      <LanguagesListItem active={selectedLanguage === language} key={language}>
        <LinkButton data-testid={`language-option-${language}`} onClick={changeLanguage(language)}>
          <LanguageIconCircle>{countryFlags[language]}</LanguageIconCircle>
          <LanguageName>{getLanguageDisplayName(language.toUpperCase(), language)}</LanguageName>
        </LinkButton>
      </LanguagesListItem>
    );
  });

  return (
    <>
      <DropdownContainer ref={ref} onClick={toggleDropdown}>
        <DropdownHeader role="button" data-testid={`dropdown-languages`}>
          <SelectedLanguageIconCircle active={isOpen}>
            {countryFlags[selectedLanguage as Languages]}
          </SelectedLanguageIconCircle>
        </DropdownHeader>
        {isOpen && (
          <LanguageDropdownListContainer>
            <ScreenSize display="flex" desktop desktopXL>
              <LanguagesDropdownList>{availableLanguageOptions}</LanguagesDropdownList>
            </ScreenSize>
          </LanguageDropdownListContainer>
        )}
      </DropdownContainer>
      <MobileView ref={refMobile} direction="right" open={isOpen} onClose={toggleDropdown}>
        <LanguagesDropdownList>{availableLanguageOptions}</LanguagesDropdownList>
      </MobileView>
    </>
  );
};
