import {
  createTheme,
  useTheme as muiTheme,
  ThemeProvider as MuiThemeProvider,
  THEME_ID
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps
} from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { breakpointSizes } from 'design/designVariables';
import { ResolvedTheme } from 'design/styled-components';

import Icons from '../../assets/icons';
import { FieldState } from '../formField/field';

import {
  DatePickerContainer,
  SpaCalendarHeader,
  SpaPaper,
  SpaPickersDay,
  SpaPickersModalDialogRoot,
  SpaTextField
} from './datePicker.styles';
import DatePickerLayout from './datePickerLayout';

type LocalizedInnerValidationMessages = Record<NonNullable<DateValidationError>, string>;

type DatePickerProps = {
  value?: Date | string | null;
  fieldMeta?: FieldState;
  onChange?: (value: Date | null) => unknown;
  className?: string;
  required?: boolean;
  placeholder?: string;
  name?: string;
  helpText?: string;
  disabled?: boolean;
  localizedInnerValidationMessages?: LocalizedInnerValidationMessages;
} & Omit<MuiDatePickerProps<Date>, 'value'>;

const transformToDate = (date?: string | Date | null): Date | null =>
  typeof date === 'string' && date ? new Date(date) : null;

const dayAbbreviations: Record<string, string> = {
  Mon: 'Mo',
  Tue: 'Tu',
  Wed: 'We',
  Thu: 'Th',
  Fri: 'Fr',
  Sat: 'Sa',
  Sun: 'Su',
  pr: 'Pr',
  an: 'An',
  tr: 'Tr',
  kt: 'Kt',
  pn: 'Pn',
  št: 'Š',
  sk: 'S'
};

export const DatePicker = ({
  value,
  fieldMeta,
  className,
  required,
  placeholder,
  name,
  onChange,
  localizedInnerValidationMessages,
  ...rest
}: DatePickerProps) => {
  const { t } = useTranslation();
  const theme = muiTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(breakpointSizes.maxTabletPx));

  const domValidContainerId = `date-picker-container-${useId().split(':').join('')}`;

  const pickerIsOpenStateRef = useRef(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isTextFieldDirty, setIsTextFieldDirty] = useState(false);
  const [innerValidationError, setInnerValidationError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(transformToDate(value));
  const [currentMonth, setCurrentMonth] = useState<Date | null>(new Date());

  const onChangeHandler = (date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    if (context.validationError && localizedInnerValidationMessages) {
      setInnerValidationError(localizedInnerValidationMessages[context.validationError] ?? null);
    }

    if (date) {
      const dateMonth = date.getMonth();
      const dateYear = date.getFullYear();
      const currentMonthValue = currentMonth?.getMonth() ?? 0;
      const currentYearValue = currentMonth?.getFullYear() ?? 0;

      if (dateMonth === currentMonthValue && dateYear === currentYearValue) {
        setIsOpen(false);
      } else {
        setCurrentMonth(new Date(date.getFullYear(), date.getMonth(), 1));
      }

      setSelectedDate(date);
      onChange?.(date);
    }
  };

  const currentAppTheme = useTheme() as ResolvedTheme;

  const extendedTheme = {
    [THEME_ID]: createTheme({
      spaTheme: currentAppTheme,
      typography: {
        fontFamily:
          "'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;"
      },
      zIndex: {
        modal: currentAppTheme.layers.modal
      }
    })
  };

  const handleMouseDownCapturePrevent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const invalidStatus = fieldMeta?.error && fieldMeta?.isTouched;

  const showError = (Boolean(innerValidationError) || invalidStatus) && isTextFieldDirty;

  const paperSlotProps = {
    onMouseDownCapture: handleMouseDownCapturePrevent
  };

  const slotProps: MuiDatePickerProps<Date, false>['slotProps'] = {
    desktopPaper: { ...paperSlotProps, ...rest.slotProps?.desktopPaper },
    mobilePaper: { ...paperSlotProps, ...rest.slotProps?.desktopPaper },
    inputAdornment: {
      position: 'end',
      ...rest.slotProps?.inputAdornment
    },
    textField: {
      error: showError,
      label: required ? placeholder : t('placeholderOptional', { placeholder }),

      helperText: showError && innerValidationError,
      name,
      className,
      ...rest.slotProps?.textField,
      onBlur: (e) => {
        setIsTextFieldDirty(true);

        //@ts-expect-error - dom events is not included in MuiDatePickerProps
        rest.slotProps?.textField?.onBlur?.(e);
      }
    },
    openPickerButton: {
      disableRipple: true,
      disableFocusRipple: true,
      disableTouchRipple: true,
      ...rest.slotProps?.openPickerButton
    },
    calendarHeader: {
      slotProps: {
        switchViewIcon: Icons.ChevronLeft
      },
      ...rest.slotProps?.calendarHeader
    },
    actionBar: {
      actions: [],
      ...rest.slotProps?.actionBar
    },
    popper: {
      // component: DatePickerPopper,
      popperOptions: {
        placement: 'bottom-end'
      }
    }
  };

  useEffect(() => {
    setSelectedDate(value ? transformToDate(value) : null);
  }, [value, setSelectedDate]);

  useEffect(() => {
    setInnerValidationError(fieldMeta?.message || null);
  }, [invalidStatus, innerValidationError, fieldMeta?.message]);

  useEffect(() => {
    setIsTextFieldDirty(fieldMeta?.isTouched || false);
  }, [fieldMeta?.isTouched]);

  //efect which attaches a handler to close mui date picker if click was registered outside of the picker
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const datepickerContainer = document.getElementById(domValidContainerId);
      const popperRoot = document.querySelector('.MuiPickersPopper-root');

      if (popperRoot && datepickerContainer) {
        const composedPath = event.composedPath();

        // use ref instead of state for check because value of state prop doesn't update inside listener
        if (
          pickerIsOpenStateRef.current &&
          !composedPath.includes(popperRoot) &&
          !composedPath.includes(datepickerContainer)
        ) {
          setIsOpen(false);
          pickerIsOpenStateRef.current = false;
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getTwoLetterDay = (date: Date) => {
    const dayOfWeek = format(date, 'eee');

    return dayAbbreviations[dayOfWeek] || dayOfWeek;
  };

  return (
    <MuiThemeProvider theme={extendedTheme}>
      <DatePickerContainer
        data-testid={`data-picker-container-${name}`}
        id={domValidContainerId}
        invalidStatus={invalidStatus}
        disabled={rest.disabled}
      >
        <MuiDatePicker
          open={isOpen}
          value={selectedDate}
          closeOnSelect={isMobile}
          defaultValue={selectedDate}
          fixedWeekNumber={6}
          onChange={onChangeHandler}
          yearsPerRow={3}
          views={['year', 'month', 'day']}
          showDaysOutsideCurrentMonth
          format="yyyy-MM-dd"
          dayOfWeekFormatter={(weekday) => getTwoLetterDay(weekday)}
          onViewChange={(view) => {
            if (view === 'month') {
              setCurrentMonth(new Date());
            }
          }}
          {...rest}
          onError={(error, date) => {
            if (error && localizedInnerValidationMessages) {
              setInnerValidationError(localizedInnerValidationMessages[error] ?? null);
            }

            rest.onError?.(error, date);
          }}
          onOpen={() => {
            setIsOpen(true);
            pickerIsOpenStateRef.current = true;
            rest.onOpen?.();
          }}
          onClose={() => {
            setIsOpen(false);
            pickerIsOpenStateRef.current = false;
            rest.onClose?.();
          }}
          slots={{
            openPickerIcon: Icons.Calendar,
            textField: SpaTextField,
            calendarHeader: SpaCalendarHeader,
            layout: DatePickerLayout,
            desktopPaper: SpaPaper,
            mobilePaper: SpaPaper,
            day: SpaPickersDay,
            leftArrowIcon: Icons.ChevronLeft,
            rightArrowIcon: Icons.ChevronRight,
            switchViewIcon: Icons.ChevronDown,
            dialog: SpaPickersModalDialogRoot,
            ...rest.slots
          }}
          slotProps={slotProps}
        />
      </DatePickerContainer>
    </MuiThemeProvider>
  );
};
