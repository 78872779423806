import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountText, InfoPopup, Paper } from 'components';

import {
  LabelContainer,
  LimitDetailBoxContainer,
  LimitDetailsContainer,
  SecondaryHeader
} from './limitDetailsSection.styles';

type LimitDetailProps = {
  amount?: number;
  label: string;
  infoText?: string;
};

const LimitDetailBox: FC<LimitDetailProps> = ({ amount, label, infoText }) => {
  const { t } = useTranslation();
  return (
    <LimitDetailBoxContainer>
      <LabelContainer>
        <SecondaryHeader>{t(label)}</SecondaryHeader>
        {infoText ? <InfoPopup text={t(infoText)} /> : null}
      </LabelContainer>
      <AmountText>{amount}</AmountText>
    </LimitDetailBoxContainer>
  );
};

export type LimitDetail = {
  label: string;
  info: string;
  value?: number;
};

type Props = {
  data: LimitDetail[];
};

export const LimitDetailsSection: FC<Props> = ({ data }) => {
  return (
    <Paper>
      <LimitDetailsContainer>
        {data.map((item, index) => (
          <LimitDetailBox key={`${index}`} amount={item.value} label={item.label} infoText={item.info} />
        ))}
      </LimitDetailsContainer>
    </Paper>
  );
};
