import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Drawer } from 'features/app/components/drawer';
import { BeneficialOwnerFields } from 'features/application/forms/beneficialOwner/beneficialOwnerFields';
import { selectBeneficialOwners } from 'features/application/selectors';
import { setBeneficialOwnerData } from 'features/application/slice';
import { useNavContext } from 'features/nav/context/navContext';
import { useAppDispatch, useApplicationSelector } from 'hooks/redux/hooks';
import { BeneficialOwner } from 'services/CapService/types';
import { percentageRequired, personNameRequired, personalCodeRequired } from 'validators';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';

type InitialValues = BeneficialOwner & { previousOwners?: BeneficialOwner[] };

export type BeneficialOwnerDrawer = {
  mode: 'add' | 'edit';
  keyInEdit?: number;
};

export const validationSchema = Yup.object({
  firstName: personNameRequired,
  lastName: personNameRequired,
  personalCode: personalCodeRequired,
  owningPercentage: percentageRequired.test(
    'exceededPercentageMax',
    'companySharesTotalExceeded',
    function (owningPercentage?: number) {
      const { previousOwners }: InitialValues = this.parent;

      const percentage = !owningPercentage || isNaN(owningPercentage) ? 0 : owningPercentage;

      const totalPercentage =
        previousOwners?.reduce(
          (percentage, owner) => (percentage += owner.owningPercentage ?? 0),
          percentage
        ) ?? 0;

      const isValid = totalPercentage <= 100;

      return isValid;
    }
  )
});

const initialValues: InitialValues = {
  firstName: '',
  lastName: '',
  personalCode: '',
  owningPercentage: NaN,
  previousOwners: []
};

const BeneficialOwnersDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { activeDrawer, hideSideDrawer, setActiveDrawer } = useNavContext<BeneficialOwnerDrawer>();

  const beneficialOwners = useApplicationSelector<typeof selectBeneficialOwners>(selectBeneficialOwners);

  const [activeForm, setActiveForm] = useState<InitialValues>(initialValues);
  const [formStatus, setFormStatus] = useState<'edit' | 'add'>('add');
  const [formKeyInEdit, setFormKeyInEdit] = useState(-1);

  const formValues = useMemo(() => {
    return {
      ...activeForm,
      personalCode: Array(11).fill(0).join(''),
      previousOwners:
        formStatus === 'edit'
          ? beneficialOwners?.filter((_, index) => index !== formKeyInEdit) ?? []
          : beneficialOwners
    };
  }, [formKeyInEdit, beneficialOwners, formStatus, activeForm]);

  const { validationHelpers, getValues, handleSubmit, setTouchedOnAll, reset, trigger } =
    useFormHandlers<BeneficialOwner>(formValues, validationSchema, 'onBlur');

  useEffect(() => {
    if (activeDrawer) {
      const activeDrawerKey = activeDrawer?.keyInEdit ?? -1;

      if (activeDrawer.mode === 'edit' && activeDrawerKey > -1) {
        const owner = (beneficialOwners ?? [])[activeDrawerKey];
        setActiveForm(owner);
        setFormKeyInEdit(activeDrawerKey);
      } else {
        setActiveForm(initialValues);
        setFormKeyInEdit(-1);
      }

      setFormStatus(activeDrawer.mode);
    }
  }, [activeDrawer?.keyInEdit, activeDrawer?.mode, beneficialOwners]);

  useEffect(() => {
    setTimeout(() => {
      trigger();
    }, 100);
  }, [formValues]);

  const saveOwners = (owners: BeneficialOwner[]) => {
    if (owners.length) {
      dispatch(setBeneficialOwnerData(owners));
    }
  };

  const addSubmitHandler = async () => {
    await handleSubmit(
      (data: BeneficialOwner) => {
        saveOwners([...(beneficialOwners ?? []), data]);
        hideSideDrawer?.();
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const editSubmitHandler = async (key: number) => {
    await handleSubmit(
      (data: BeneficialOwner) => {
        saveOwners((beneficialOwners ?? []).map((owner, index) => (index === key ? data : owner)));
        hideSideDrawer?.();
      },
      () => {
        setTouchedOnAll();
      }
    )();
  };

  const onFormReset = () => {
    setActiveForm(initialValues);
    setFormStatus('add');
    setFormKeyInEdit(-1);
    reset(initialValues);
    setActiveDrawer?.({});
  };

  const onOwnerUpdate = async () => {
    if (formStatus === 'add') {
      await addSubmitHandler();
    } else {
      await editSubmitHandler(formKeyInEdit);
    }
  };

  const onOwnerDelete = () => {
    const owners = (beneficialOwners ?? []).filter((_, index) => index !== formKeyInEdit);

    dispatch(setBeneficialOwnerData(owners));
    hideSideDrawer?.();
  };

  const allowDelete = (beneficialOwners?.length ?? 0) > 1;

  return (
    <Drawer
      isValid={validationHelpers.isValid}
      header={t('beneficialOwner')}
      onDelete={formStatus === 'edit' ? onOwnerDelete : undefined}
      onSave={onOwnerUpdate}
      onClose={onFormReset}
      disabledDelete={!allowDelete}
      buttonLabel={t('save')}
    >
      <BeneficialOwnerFields validationHelpers={validationHelpers} getValues={getValues} />
    </Drawer>
  );
};

export default BeneficialOwnersDrawer;
