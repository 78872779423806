import { isAfter, isBefore, isSameDay } from 'date-fns';

import { LoanInvoiceItem } from 'services/LoansService/types';

import { BillsFilterParams, FilterKeys } from './loansBillsFilterFields';

const isSameOrAfter = (dateB: string, dateA?: string) =>
  (dateA && isAfter(new Date(dateA), new Date(dateB))) ||
  (dateA && isSameDay(new Date(dateA), new Date(dateB)));

const isSameOrBefore = (dateB: string, dateA?: string) =>
  (dateA && isBefore(new Date(dateA), new Date(dateB))) ||
  (dateA && isSameDay(new Date(dateA), new Date(dateB)));

export const filterPaymentsList = (invoices?: LoanInvoiceItem[], filterParams?: BillsFilterParams) => {
  if (!invoices) {
    return [];
  }

  if (!filterParams) {
    return invoices || [];
  }

  const filterKeys = Object.keys(filterParams) as FilterKeys[];

  let filteredPayments = [...invoices];

  filterKeys.forEach((key) => {
    switch (key) {
      case 'billNumber':
        if (filterParams[key]) {
          filteredPayments = filteredPayments.filter((payment) =>
            payment.billNumber?.toLowerCase()?.includes(filterParams[key].toLowerCase())
          );
        }
        break;
      case 'billStatus':
        if (filterParams[key] && filterParams[key] !== 'ALL') {
          filteredPayments = filteredPayments.filter(
            (payment) => payment.paymentStatus === filterParams[key]
          );
        }
        break;
      case 'billType':
        if (filterParams[key] && filterParams[key] !== 'ALL') {
          filteredPayments = filteredPayments.filter((payment) => payment.billType === filterParams[key]);
        }
        break;
      case 'dueDateFrom':
        if (filterParams[key]) {
          filteredPayments = filteredPayments.filter((payment) =>
            isSameOrAfter(filterParams[key], payment.dueDate)
          );
        }
        break;
      case 'dueDateTo':
        if (filterParams[key]) {
          filteredPayments = filteredPayments.filter((payment) =>
            isSameOrBefore(filterParams[key], payment.dueDate)
          );
        }
        break;
      case 'issueDateFrom':
        if (filterParams[key]) {
          filteredPayments = filteredPayments.filter((payment) =>
            isSameOrAfter(filterParams[key], payment.issueDate)
          );
        }
        break;
      case 'issueDateTo':
        if (filterParams[key]) {
          filteredPayments = filteredPayments.filter((payment) =>
            isSameOrBefore(filterParams[key], payment.issueDate)
          );
        }
        break;
      default:
        break;
    }
  });

  return filteredPayments;
};
