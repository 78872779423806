import { Column, Grid } from 'components';
import { selectCustomerInformation, selectUserData } from 'features/auth/selectors';
import { EditableField } from 'features/users/components';
import { useGetUserByEmailQuery } from 'features/users/slice';
import { useAuthSelector } from 'hooks/redux/hooks';

import { ContentContainer } from './myInformation.styles';

export const MyInformation = () => {
  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const { email } = useAuthSelector(selectUserData);
  const { data } = useGetUserByEmailQuery({ email });

  const { userFirstName, userLastName } = customerInfo || {};

  return (
    <ContentContainer>
      <Grid>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField readonly label="name" value={userFirstName} />
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField readonly label="lastName" value={userLastName} />
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField readonly label="email" value={email} />
        </Column>
        <Column span={12} colStart={1} spanMd={12} colStartMd={1}>
          <EditableField label="phone" value={data?.mobilePhoneNumber} editDrawerId="editPhone" />
        </Column>
      </Grid>
    </ContentContainer>
  );
};
