import { useTranslation } from 'react-i18next';

import stopwatch from 'assets/icons/stopwatch.svg';
import { NotificationModal } from 'components';
import { NotificationImage } from 'components/notificationModal/notificationModal.styles';

type Props = {
  open?: boolean;
  onClose: () => unknown;
  takeAnotherLook: () => unknown;
  skipThisOpportunity: () => unknown;
};

const OfferStatusPushModal = ({ open, onClose, takeAnotherLook, skipThisOpportunity }: Props) => {
  const { t } = useTranslation();

  return (
    <NotificationModal
      type={'success'}
      isOpen={!!open}
      informationalImage={<NotificationImage src={stopwatch} alt="" />}
      label={t('doYouReallyWantToRejectOffer')}
      handleClose={onClose}
      explanationText={t('doYouReallyWantToRejectOfferExplanation')}
      mainAction={{
        label: t('takeAnotherLook'),
        handler: takeAnotherLook
      }}
      secondaryAction={{
        label: t('skipThisOpportunity'),
        handler: skipThisOpportunity
      }}
    />
  );
};

export default OfferStatusPushModal;
