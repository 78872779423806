import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import plus from 'assets/icons/plus.svg';
import { LinkButton, ListItem, Toast } from 'components';
import { ActionToast } from 'components/actionToast';
import { DrawerImplementation } from 'features/app/components/drawersSwitch/drawersSwitch';
import { selectUserCrudError, selectUserCrudSuccess } from 'features/app/selectors';
import { clearUserCRUDMessage } from 'features/app/slice';
import { selectActiveCompany } from 'features/applications/selectors/';
import { PERMISSIONS } from 'features/auth/consts/permissions';
import { selectActiveCustomer, selectCanMangeUsers, selectUserData } from 'features/auth/selectors';
import { useNavContext } from 'features/nav/context/navContext';
import { userRole, UserRole } from 'features/users/components';
import { useGetUsersQuery } from 'features/users/slice';
import { useAppDispatch, useAppInfoSelector, useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { User } from 'services/RbacService/typings';
import { refreshAuthLogic } from 'services/RestClient';

import Skeleton from './skeleton';
import { AddUserButton, Clause, List } from './users.style';

type UserInfo = User & { role?: UserRole; toggleSelected?: never };

export const Users = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { toggleRightSideDrawer, activeDrawer, hideSideDrawer } =
    useNavContext<DrawerImplementation<'editUser'>>();

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const canManageUsers = useAuthSelector<typeof selectCanMangeUsers>(selectCanMangeUsers);
  const activeCompany = useAppSelector(selectActiveCompany);
  const successMessage = useAppInfoSelector<typeof selectUserCrudSuccess>(selectUserCrudSuccess);
  const errorMessage = useAppInfoSelector<typeof selectUserCrudError>(selectUserCrudError);
  const [isToastVisible, setToastVisible] = useState(false);

  const isAdmin = activeCustomer?.roles?.includes(PERMISSIONS.manageUsers);

  const { email } = useAuthSelector<typeof selectUserData>(selectUserData);

  const {
    data: usersResponse,
    refetch,
    isFetching
  } = useGetUsersQuery({ id: activeCompany.id }, { skip: !activeCompany.id });

  const { users, customer } = usersResponse || {};
  const isCurrentUser = useMemo(
    () => activeCustomer?.customerId === customer?.customerCode,
    [activeCustomer?.customerId, customer?.customerCode]
  );

  const initialUsers = isCurrentUser ? users : [];

  const sortedUsers = initialUsers
    ? [...initialUsers]?.sort((user) => {
        if (user.email === email) {
          return -1;
        }

        return 0;
      })
    : initialUsers;

  const userEmails = (users?.map((user) => user.email).filter((el) => el) as string[]) || [];

  useEffect(() => {
    if (successMessage) {
      refetch();
    }
  }, [successMessage, refetch]);

  const checkIfCurrentUser = async () => {
    await refreshAuthLogic(null, activeCustomer?.customerId);
    const { data } = await refetch();

    if (activeCustomer?.customerId === data?.customer?.customerCode) {
      setToastVisible(false);

      return true;
    }

    setToastVisible(true);

    return false;
  };

  const onAddUser = async () => {
    const openSideBar = await checkIfCurrentUser();

    if (openSideBar) {
      toggleRightSideDrawer?.({
        drawerId: 'editUser',
        userEmails
      });
    }
  };

  const onUseEdit = (user?: UserInfo) => {
    if (canManageUsers) {
      if (activeDrawer?.drawerId === 'editUser' && activeDrawer.email === user?.email) {
        hideSideDrawer?.();
      } else {
        toggleRightSideDrawer?.({
          drawerId: 'editUser',
          email: user?.email,
          userEmails
        });
      }
    }
  };

  return (
    <>
      <ActionToast
        item={t('user')}
        action={successMessage?.action}
        isVisible={Boolean(successMessage)}
        variant={'success'}
        onClose={() => dispatch(clearUserCRUDMessage())}
      />
      <ActionToast
        item={t('user')}
        action={errorMessage?.action}
        isVisible={Boolean(errorMessage)}
        variant={'error'}
        onClose={() => dispatch(clearUserCRUDMessage())}
      />
      <List data-testid="users-list">
        {isFetching ? (
          <Skeleton />
        ) : (
          sortedUsers?.map((user) => (
            <LinkButton
              data-testid={`users-list-item-${user.email}`}
              color="Black"
              key={user.email}
              onClick={(e) => {
                if (email !== user.email) {
                  e.preventDefault();
                  e.stopPropagation();
                  onUseEdit(user);
                }
              }}
            >
              <ListItem
                content={`${user.firstName?.charAt(0) ?? ''}${user.lastName?.charAt(0) ?? ''}`}
                title={
                  <span>
                    {`${user.firstName} ${user.lastName}`}
                    {user.email === email ? <Clause>{` (${t('you')})`}</Clause> : ''}
                  </span>
                }
                subtitle={t(userRole(user.roles || []))}
              >
                <Icons.ChevronRight />
              </ListItem>
            </LinkButton>
          ))
        )}
      </List>
      {isAdmin ? (
        <>
          <Toast variant="error" isVisible={isToastVisible} header={t('currentUserAndCompanyNotMatch')} />
          <AddUserButton
            disabled={isFetching}
            data-testid="add-user-button"
            icons={{ left: plus }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAddUser();
            }}
          >
            {t('addUser')}
          </AddUserButton>
        </>
      ) : null}
    </>
  );
};
