import styled from 'styled-components';

import { LinkButton, Paper, Typography } from 'components';

export const EditButtonStyle = styled(LinkButton)``;

export const HeaderActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CardHeader = styled(Typography.Header).attrs({ type: 'h5', weight: 'semibold' })`
  font-size: 1.125rem;
  line-height: 1.625rem;
`;

export const MobileCardHeader = styled(CardHeader)<InvoicePaperCardProps>`
  display: none;
  gap: 0.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    display: ${({ cardlessOnMobile }) => (cardlessOnMobile ? 'flex' : 'none')};
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    display: flex;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type InvoicePaperCardProps = {
  cardlessOnMobile?: boolean;
};

export const InvoicePaperCard = styled(Paper)<InvoicePaperCardProps>`
  padding: 1.5rem 2rem;
  flex: initial;
  width: 100%;

  @media ${({ theme }) => theme.media.maxTablet} {
    background-color: ${({ theme, cardlessOnMobile }) =>
      cardlessOnMobile ? 'transparent' : theme.colors.base.white.functional};
    padding: ${({ cardlessOnMobile }) => (cardlessOnMobile ? 0 : '1.5rem 2rem')};

    ${HeaderContainer} {
      display: ${({ cardlessOnMobile }) => (cardlessOnMobile ? 'none' : 'flex')};
    }
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    padding: ${({ cardlessOnMobile }) => (cardlessOnMobile ? 0 : '1rem')};

    ${CardHeader} {
      display: none;
    }

    ${EditButtonStyle} {
      display: none;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;
