import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ContractsService } from 'services';
import { ContractDetailsRequest, ContractLimitHistoryRequest } from 'services/ContractsService/types';

import { ContractsState } from './types';

export const initialState: ContractsState = {
  detailsIsLoading: false,
  detailsLoadFailed: false,
  historyIsLoading: false,
  creditLimitHistory: {},
  contractDetails: {}
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    resetContractsState: () => initialState
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchContractByIdAsync.pending, (state) => {
        state.detailsIsLoading = true;
        state.detailsLoadFailed = false;
      })
      .addCase(fetchContractByIdAsync.fulfilled, (state, action) => {
        state.detailsIsLoading = false;
        state.contractDetails = action.payload;
      })
      .addCase(fetchContractByIdAsync.rejected, (state) => {
        state.contractDetails = {};
        state.detailsIsLoading = false;
        state.detailsLoadFailed = true;
      });

    builder
      .addCase(fetchCreditLimitHistoryAsync.pending, (state) => {
        state.historyIsLoading = true;
      })
      .addCase(fetchCreditLimitHistoryAsync.fulfilled, (state, action) => {
        state.historyIsLoading = false;
        state.creditLimitHistory = action.payload;
      })
      .addCase(fetchCreditLimitHistoryAsync.rejected, (state) => {
        state.historyIsLoading = false;
      });
  }
});

export const fetchContractByIdAsync = createAsyncThunk(
  'contracts/getContractById',
  async (payload: ContractDetailsRequest) => {
    return await ContractsService.getContractById(payload);
  }
);

export const fetchCreditLimitHistoryAsync = createAsyncThunk(
  'contracts/getCreditLimitHistory',
  async (payload: ContractLimitHistoryRequest) => {
    return await ContractsService.getCreditLimitHistory(payload);
  }
);

export const { resetContractsState } = contractsSlice.actions;

export default contractsSlice.reducer;
