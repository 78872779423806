import styled from 'styled-components';

import { MobileListItemRow } from 'components/table/table.styles';

export const BillsDetailsTableContainer = styled.div`
  div[data-column-id='invoiceDate'],
  div[data-column-id='invoiceAmount'],
  div[data-column-id='amountUnpaid'] {
    display: flex;
    justify-content: flex-end;
  }

  .exclamationCircle {
    path {
      fill: none;
    }
  }
  ${MobileListItemRow} {
    margin-bottom: 0;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.secondary.textColor};
    }
  }
`;
