const envPrefix = process.env.REACT_APP_STAGE !== 'prod' ? 'stage_' : '';

const submitIds = {
  applicationProgress: 'application_progress',
  applicationSuccess: 'application_success',
  offerRejected: 'single_flow_offer_rejected',
  offerViewed: 'single_flow_offer_viewed',
  offerAccepted: 'single_flow_offer_accepted',
  ondataFlowStarted: 'single_flow_ondata_flow_started',
  ondatoFlowFinished: 'single_flow_ondato_flow_finished',
  existingCustomerFlowStarted: 'existing_customer_ondata_flow_started',
  existingCustomerFlowFinished: 'existing_customer_ondato_flow_finished',
  onboardingFlowStarted: 'single_flow_onboarding_flow_started',
  onboardingFlowSubmitted: 'single_flow_onboarding_flow_submitted',
  existingCustomerOnboardingFlowStarted: 'existing_customer_onboarding_flow_started',
  existingCustomerOnboardingFlowSubmitted: 'existing_customer_onboarding_flow_submitted',
  smeBankAccountSetupStarted: 'single_flow_sme_bank_account_setup_started',
  alternativeBankAccountAdded: 'single_flow_alternative_bank_account_added',
  ocrAutofillRejected: 'ocr_pdf_suggestion_autofill_cancel',
  ocrPdfSuggestionAutofill: 'ocr_pdf_suggestion_autofill',
  companySuggestionSelected: 'application_company_suggestion_selected',
  companySuggestionShown: 'application_company_suggestion_shown',
  userSettingsEdit: 'user_settings_edit',
  userSettingsCreated: 'user_settings_created'
} as const;

const modalIds = {
  invoiceUploadManual: 'invoice_upload_manual',
  invoiceUploadExcel: 'invoice_upload_excel',
  invoiceUploadOcr: 'invoice_upload_ocr'
} as const;

const checkForInConsoleDebugMode = () => {
  if (location.search.includes('hjDryMode=1')) {
    // eslint-disable-next-line no-console
    window.hj = (...args) => console.log('hotjar event:', ...args);
  }
};

const setFlowStep = (step: string, extraArgs?: Record<string, string>) => {
  checkForInConsoleDebugMode();

  if (step) {
    window.hj?.('event', `${envPrefix}step_view_${step}`);
    window.gtag?.('event', 'page_view', {
      flow_step_id: step,
      ...(extraArgs || {})
    });
  }
};

const setUserType = (isGhost: boolean) => {
  window.gtag?.('set', 'user_properties', { user_type: isGhost ? 'prospect' : 'regular' });
};

const setUserChannelIntegrationType = (isMobileWebView: boolean) => {
  window.gtag?.('set', 'user_properties', { user_channel_integration: isMobileWebView ? 'mobile' : 'web' });
};

const setIsAdmin = () => {
  window.gtag?.('set', 'user_properties', { is_admin: true });
};

type ModalIds = (typeof modalIds)[keyof typeof modalIds];

const setOpenModalView = (title: ModalIds) => {
  if (title) {
    checkForInConsoleDebugMode();

    window.hj?.('event', `${envPrefix}modal_opened_${title}`);
    window.gtag?.('event', 'page_view', {
      modal_title: title
    });
  }
};

type SubmitIds = (typeof submitIds)[keyof typeof submitIds];

const setSubmitEvent = (id: SubmitIds, extraArgs?: Record<string, unknown>) => {
  if (id) {
    checkForInConsoleDebugMode();

    window.hj?.('event', `${envPrefix}submit_init_${id}`);
    window.gtag?.('event', 'click', {
      user_submit: id,
      ...(extraArgs ?? {})
    });
  }
};

const setSingleFlowIdAndStatus = (flowId: string, singleFlowStep: string) => {
  window.gtag?.('set', 'user_properties', { single_flow_step: singleFlowStep });
  window.hj?.('event', `${envPrefix}single_flow_id_${flowId}`);
};

const updateMarketingDataLayer = (data: Record<string, string | Record<string, string>>) => {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(data);
};

type ApplicationProgress = {
  locality: string;
  application_step: string;
  product: string;
  subproduct: string;
};

export type ApplicationSubmit = Omit<ApplicationProgress, 'application_step'> & {
  enhanced_conversions: {
    country: string;
    // region: string; Need to verify if we can send this (might be PII)
    // city: string; Need to verify if we can send this (might be PII)
    // last_name: string; Need to verify if we can send this (might be PII)
    // first_name: string; Need to verify if we can send this (might be PII)
    email: string;
    phone_number: string;
    // address: string; Need to verify if we can send this (might be PII)
  };
};

const updateApplicationProgress = (data: ApplicationProgress) => {
  updateMarketingDataLayer({ ...data, event: 'application_progress', business: 'smego' });
};

const updateApplicationSubmitted = (data: ApplicationSubmit, extraArgs?: Record<string, unknown>) => {
  setSubmitEvent('application_success', extraArgs);
  updateMarketingDataLayer({ ...data, event: 'application_success', business: 'smego' });
};

type QueryParams = 'fbclid' | 'gclid';
type CookieParams = '_fbc' | '_fbp' | '_ga';
export type AddsParams = Record<QueryParams | CookieParams, string | null>;

const stripGAClidVersion = (gaClientId: string | null) => {
  if (gaClientId === null) return null;

  return gaClientId.replace('GA1.1.', '');
};

const createFBCookie = (fbclid: string | null) => {
  if (fbclid === null) return;

  const ttl90Days = 60 * 60 * 24 * 90;
  const unixTimeInMs = Date.now();

  document.cookie = `_fbc=fb.1.${unixTimeInMs}.${fbclid}; max-age=${ttl90Days}; path=/; SameSite=None; Secure`;
};

const readCookieValue = (cookieName: CookieParams) => {
  return (
    document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${cookieName}=`))
      ?.split('=')[1] ?? null
  );
};

const readAddsParams = (): AddsParams => {
  const urlParams = new URLSearchParams(window.location.search);

  const gclid = urlParams.get('gclid');
  const fbclid = urlParams.get('fbclid');

  const _fbc = readCookieValue('_fbc');
  const gaClientId = stripGAClidVersion(readCookieValue('_ga'));

  if (!_fbc && fbclid) {
    createFBCookie(fbclid);
  }

  if (_fbc) {
    const cookieValue = _fbc.split('.')[3];

    if (cookieValue !== fbclid) {
      createFBCookie(fbclid);
    }
  }

  const _fbp = readCookieValue('_fbp');

  return {
    gclid,
    _ga: gaClientId,
    fbclid,
    _fbp,
    _fbc: _fbc ?? fbclid
  };
};

export default {
  setIsAdmin,
  readAddsParams,
  setFlowStep,
  setUserType,
  setUserChannelIntegrationType,
  setOpenModalView,
  setSubmitEvent,
  updateApplicationProgress,
  updateApplicationSubmitted,
  setSingleFlowIdAndStatus
};
