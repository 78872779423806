import { FirebaseApp, initializeApp } from 'firebase/app';
import { getRemoteConfig, RemoteConfig, fetchConfig, activate, getValue } from 'firebase/remote-config';

import { existingCustomerOnboardingConfig } from 'defaultConfiguration/existingCustomerOnboardingConfig';
import { defaultLeasingCalculatorInfo } from 'defaultConfiguration/leasingDefaultConfig';
import { onboardingParallelFlowDefaultConfig } from 'defaultConfiguration/onboardingParallelFlowDefaultConfig';
import { defaultValue as onboardingSSODefaultConfig } from 'defaultConfiguration/onboardingSSODefaultConfig';
import { defaultConfig as productsAvailabilityDefaultConfig } from 'defaultConfiguration/productsAvailabilityDefaultConfig';
import { defaultValue as smeBankPromoDefaultConfig } from 'defaultConfiguration/smeBankPromoDefaultConfig';
import { AppService } from 'services/AppService';

const defaultConfig = {
  features: FEATURES,
  available_products: productsAvailabilityDefaultConfig,
  smeBankPromo: smeBankPromoDefaultConfig,
  onboardingSSO: onboardingSSODefaultConfig,
  leasingCalculatorInfo: defaultLeasingCalculatorInfo,
  onboardingParallelFlowConfig: onboardingParallelFlowDefaultConfig,
  existingCustomerOnboardingConfig,
  pages_in_maintenance: []
};

const stringifyObjectValues = (obj: Record<string, unknown>) => {
  const newObj = {} as Record<string, string>;

  Object.keys(obj).forEach((key) => {
    newObj[key] = JSON.stringify(obj[key]);
  });

  return newObj;
}


export type DefaultConfig = typeof defaultConfig;

type AvailableConfigurations = keyof DefaultConfig;

export class RemoteConfigSingleton {
  private static instance: RemoteConfigSingleton;
  private static _remoteConfig: RemoteConfig | null;
  private static _app: FirebaseApp | null;
  private static _lastRemoteRefresh: number;
  private static _failedConnectionAttempts = 0;

  private constructor() {
    const firebaseConfig = {
      apiKey: VARIABLES.firebaseApiKey as string,
      projectId: VARIABLES.firebaseProjectId as string,
      appId: VARIABLES.firebaseAppId as string
    };

    try {
      RemoteConfigSingleton._app = initializeApp(firebaseConfig);

      RemoteConfigSingleton._remoteConfig = getRemoteConfig(RemoteConfigSingleton._app);

      RemoteConfigSingleton._remoteConfig.settings = {
        minimumFetchIntervalMillis: 720000 * 2, // 24 minutes, increased to remove fetchlimit errors
        fetchTimeoutMillis: 8000 // 8 seconds
      };

      RemoteConfigSingleton._remoteConfig.defaultConfig = stringifyObjectValues(defaultConfig);

      // initialize analytics
    } catch {
      RemoteConfigSingleton._remoteConfig = null;
    }
  }

  public static getInstance(): RemoteConfigSingleton {
    if (!RemoteConfigSingleton.instance) {
      RemoteConfigSingleton.instance = new RemoteConfigSingleton();
    }

    return RemoteConfigSingleton.instance;
  }

  public get remoteConfig(): RemoteConfig | null {
    return RemoteConfigSingleton._remoteConfig;
  }

  public get lastRemoteRefresh(): number {
    return RemoteConfigSingleton._lastRemoteRefresh;
  }

  public getValueOf<TKey extends AvailableConfigurations>(key: TKey) {
    try {
      if (RemoteConfigSingleton._remoteConfig) {
        const value = getValue(RemoteConfigSingleton._remoteConfig, key);

        return { ...value, asJson: () => JSON.parse(value.asString()) as  DefaultConfig[TKey]};
      }

      return { asJson: () => defaultConfig[key] };
    } catch {
      return { asJson: () => defaultConfig[key] };
    }
  }

  public async activateLatestConfig(cb?: () => void) {
    try {
      if (RemoteConfigSingleton._failedConnectionAttempts < 6) {
        if (RemoteConfigSingleton._remoteConfig) {
          await fetchConfig(RemoteConfigSingleton._remoteConfig);
          const activatedConfigs = await activate(RemoteConfigSingleton._remoteConfig);

          if (activatedConfigs) {
            RemoteConfigSingleton._lastRemoteRefresh = Date.now();
          }

          cb?.();
        }
      }
    } catch (e) {
      RemoteConfigSingleton._failedConnectionAttempts += 1;

      try {
        await AppService.reportConfigurationFailure(e as Error);
      } catch {
        console.error('error fetching remote config');
      }
    }
  }
}
