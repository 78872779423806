/**
 * Apply the Promise.withResolvers polyfill if nativly supported method does not exist
 * This polyfill adds a static method to the Promise class that returns a promise and its resolve and reject functions
 *
 * Required by react-pdf lib latest version (file preview component)
 *
 * TODO: refactor this to apply via Vite bundle configuration
 */
export default function applyWithResolversPolyfill() {
  //@ts-expect-error - Promise.withResolvers is not a standard method
  if (typeof Promise.withResolvers !== 'function') {
    //@ts-expect-error - Promise.withResolvers is not a standard method
    Promise.withResolvers = function () {
      let resolve;
      let reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });

      return { promise, resolve, reject };
    };
  }
}
