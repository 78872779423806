import { ListenerEffect, AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { AddsParams } from 'utils/tracking';

export type Toast = {
  status: 'error' | 'info' | 'warning' | 'success';
  header?: string;
  message: string;
  action?: string;
  id?: number | string;
  displayIcon?: boolean;
};

export type AppState = {
  toasts: Toast[];
  adds: Partial<AddsParams>;
};

export type Effect = ListenerEffect<AnyAction, unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>;

export type LoginWarnings =
  | 'errorOccuredMessage'
  | 'basicSmartIdMessage'
  | 'basicSmartIdMessageHeader'
  | 'sessionTimeoutMessage'
  | '';

export type UserCrudType = 'create' | 'update' | 'delete';

export const GLOBAL_MESSAGE_ID = {
  LOGIN_WARNING: 'loginWarning',
  GENERAL_MESSAGE: 'generalMessage',
  USER_CRUD_SUCCESS: 'userCrudSuccess',
  USER_CRUD_ERROR: 'userCrudError'
} as const;
