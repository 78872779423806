import { FC } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

export type PieCartData = { name: string; value?: number; color: string };

type Props = {
  data: PieCartData[];
};

export const DonutChart: FC<Props> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={75}
          outerRadius={125}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry) => (
            <Cell key={`cell-${entry.name}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
