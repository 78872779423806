import {
  Body,
  Card,
  Header,
  HeaderActions,
  HeaderIconAndText,
  HeaderLeftColumn,
  HeaderTextContentSkeleton,
  Shimmer
} from './applicationCard.styles';

const ApplicationCardSkeleton = () => {
  return (
    <Card>
      <Header>
        <HeaderLeftColumn>
          <HeaderIconAndText>
            <Shimmer width="3rem" height="3rem" />
            <HeaderTextContentSkeleton>
              <Shimmer height="1.25rem" width="7.5rem" />
              <Shimmer height="1.25rem" width="12.5rem" />
            </HeaderTextContentSkeleton>
          </HeaderIconAndText>
          <Shimmer height="1.5rem" width="6.25rem" />
        </HeaderLeftColumn>
        <HeaderActions></HeaderActions>
      </Header>
      <Body>
        <Shimmer height="3.75rem" />
      </Body>
    </Card>
  );
};

export default ApplicationCardSkeleton;
