import styled from 'styled-components';

import { LinkButtonStyle, RegularText, SecondaryText, Link } from 'components';

export const HelpPopupContainer = styled.div`
  position: fixed;
  bottom: 7rem;
  margin-left: var(--scrollbar-offset);
  left: calc(100% - 7rem);
  z-index: ${({ theme }) => theme.layers.helpPopup};

  @media ${({ theme }) => theme.media.maxTablet} {
    left: calc(100% - 5.5rem);
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    left: calc(100% - 5rem);
    bottom: 2.5rem;
  }
`;

type HelpPopupButtonProps = {
  active: boolean;
};

export const HelpPopupButton = styled.div<HelpPopupButtonProps>`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: ${({ active, theme }) =>
    active ? `${theme.colors.primary.textColor}` : `${theme.colors.primary.buttonBackground}`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary.navBackground};
    }
  }
`;

export const HeloPopupInfoContainer = styled.div`
  position: absolute;
  width: 25.438rem;
  height: 11.125rem;
  right: 3%;
  bottom: 3.5rem;
  background-color: ${({ theme }) => theme.colors.primary.navBackground};
  box-shadow: 0 0.25rem 0.5rem ${({ theme }) => theme.colors.shadow};
  border-radius: 0.625rem;
  z-index: ${({ theme }) => theme.layers.modal};

  @media ${({ theme }) => theme.media.maxPhone} {
    width: 18.75rem;
    height: 11.125rem;
  }
`;

export const GreetingContainer = styled.div`
  background: linear-gradient(120.53deg, #2b64f5 1.05%, #1e46ac 100.99%);
  border-radius: 0.625rem 0.625rem 0 0;
  padding: 0.75rem 1.25rem;

  ${RegularText} {
    font-size: ${({ theme }) => theme.fontSizes.h2};
    color: ${({ theme }) => theme.colors.primary.buttonText};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    display: block;
    line-height: 1.5rem;
  }

  ${SecondaryText} {
    font-size: ${({ theme }) => theme.fontSizes.h4};
    color: ${({ theme }) => theme.colors.primary.buttonText};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    line-height: 1.5rem;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5625rem;
  border-radius: 0 0 0.625rem 0.625rem;
`;

export const IconContainer = styled.span`
  width: 1.5rem;
  height: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const InfoContainerItem = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;

  ${Link} {
    color: ${({ theme }) => theme.colors.primary.linkText};
    margin-left: 0.5rem;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  ${RegularText} {
    margin-left: 0.5rem;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

export const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3.75rem;
  align-items: center;
  border-bottom: 1px ${({ theme }) => theme.colors.primary.border.light} solid;

  ${LinkButtonStyle} {
    padding: 1.25rem;
  }
`;
