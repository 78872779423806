import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UpsImage from 'assets/images/oopsUnderConstruction.svg';
import { Paper } from 'components';
import { MaintenanceInfo } from 'defaultConfiguration/getRoutesInMaintenance';

import { useMaintenanceDate } from './hooks';
import { MaintenanceCardContent, MaintenanceCardRow, MainTitle, Subtitle } from './maintenance.styles';

type Props = {
  date?: string;
  system?: MaintenanceInfo['system'];
};

const headerTranslations: Record<NonNullable<MaintenanceInfo['system']>, string> = {
  app: 'maintenanceBreak',
  goCredits: 'maintenanceBreakGoCredits',
  goFinance: 'maintenanceBreakFactoring',
  navision: 'maintenanceBreak',
  rbf: 'maintenanceBreakRBF',
  factoring: 'maintenanceBreakFactoring',
  loans: 'maintenanceBreakLoans',
  leasing: 'maintenanceBreak',
  creditline: 'maintenanceBreakFlexiFinance'
};

export const MaintenanceCard: FC<Props> = ({ date, system }) => {
  const { t } = useTranslation();

  const formatedDate = useMaintenanceDate(date);

  const header = system ? headerTranslations[system] : 'maintenanceBreak';

  return (
    <MaintenanceCardRow>
      <Paper>
        <MaintenanceCardContent>
          <img src={UpsImage} />
          <MainTitle> {t(header)}</MainTitle>
          <Subtitle>
            {date
              ? t('maintenanceBreakMessageTimed', { date: formatedDate })
              : t('maintenanceBreakMessageAproximate')}
          </Subtitle>
        </MaintenanceCardContent>
      </Paper>
    </MaintenanceCardRow>
  );
};
