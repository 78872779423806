import styled from 'styled-components';

import { Fieldset, RadioButtonGroup, RegularText } from 'components';

export const HeaderText = styled(RegularText)`
  line-height: 1.5rem;
  font-size: 1rem;
`;

export const DeferredPaymentHeaderText = styled(HeaderText)`
  display: inline-flex;
  margin-bottom: 1rem;
`;

export const InsuranceLimitForm = styled.form`
  ${Fieldset} {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${RadioButtonGroup} {
    margin-top: 1rem;
  }
`;

export const AgreementFieldContainer = styled.div`
  margin: 1.5rem 0;
`;

export const AgreementText = styled(RegularText)`
  font-size: 0.875rem;
`;
