import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
  InvoicesVerificationGetRequest,
  InvoicesVerificationGetResponse,
  InvoicesVerificationPostRequest,
  InvoicesVerificationPostResponse
} from 'services/InvoiceVerificationService/types';
import { axiosBaseQuery } from 'services/RestClient';
import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';

const withBaseUrl = baseUrlBuilder('invoice-verification');

const invoicesVerificationApi = createApi({
  reducerPath: 'invoicesVerificationApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['statuses', 'skip_invalidation'],
  endpoints: (build) => ({
    getVerificationStatuses: build.query<InvoicesVerificationGetResponse, InvoicesVerificationGetRequest>({
      query: (payload) => ({ url: queryBuilder(withBaseUrl('/invoices/verification'), payload) }),
      transformResponse: (response: InvoicesVerificationGetResponse) => {
        return response.filter((invoices) => invoices.verificationStatus === 'WAITING');
      },
      providesTags: ['statuses']
    }),
    updateVerificationStatuses: build.mutation<
      InvoicesVerificationPostResponse,
      InvoicesVerificationPostRequest
    >({
      query: (payload) => ({
        url: withBaseUrl('/invoices/verification'),
        method: 'POST',
        data: payload
      }),
      invalidatesTags: (_, error) => [!error ? 'statuses' : 'skip_invalidation']
    })
  })
});

export const { useGetVerificationStatusesQuery, useUpdateVerificationStatusesMutation } =
  invoicesVerificationApi;

export const { resetApiState: resetInvoicesVerficationApiState } = invoicesVerificationApi.util;

export default invoicesVerificationApi;
