import createHash from 'create-hash';
import { v4 } from 'uuid';

function getUniqueId() {
  return v4();
}

export const createHashId = () => {
  if (process.env.E2E_TEST === 'true') {
    return 'uniqueId_in_test';
  }

  const hash = createHash('sha256');

  hash.update(getUniqueId());

  return hash.digest('hex');
};

/**
 * Inits unique session id in global space if user accepted with analytics can be used to pass unique id to analytics
 * for user flows and events
 *
 * @returns {Object} - Object with getUniqueId method
 */
export const initUniqueSessionId = () => {
  const uniqueIdRef = createHashId();

  const config = {
    timestamp: Date.now(),
    ttl: 1800000, // 30 minutes
    uniqueId: uniqueIdRef
  };

  return {
    getUniqueId: () => {
      if (Date.now() - config.timestamp > config.ttl) {
        config.timestamp = Date.now();
        config.uniqueId = createHashId();
      }

      return config.uniqueId;
    }
  };
};
