import { FC, useEffect, useState } from 'react';
import { FieldError, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ValidationHelpers } from 'hooks/forms/useFormHandlers';

export type FieldState = {
  isDirty: boolean;
  isTouched: boolean;
  error?: FieldError;
  message?: string;
  invalid?: boolean;
  showValidationMessage?: boolean;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  validationMeta?: FieldState;
  validateOnInitialSet?: boolean;
};

export type FieldOnChangeOverrideHandler<TFieldValues extends FieldValues, TValue = never> = (
  name: Path<TFieldValues>,
  value: TValue,
  onChange: (name: Path<TFieldValues>) => (value: unknown) => unknown,
  setStateValue: (value: unknown) => unknown
) => unknown;

type UnknownObject = Record<string, unknown>;

type StaticProps<TFieldValues extends FieldValues, TValue = never> = {
  name: Path<TFieldValues>;
  showValidationMessage?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: FC<any>;
  placeholder?: string;
  disable?: boolean;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  focused?: boolean;
  validateOnInitialSet?: boolean;
  validationMeta?: Partial<FieldState>;
  onBlur?: (...args: unknown[]) => unknown;
  onChangeOverride?: FieldOnChangeOverrideHandler<TFieldValues, TValue>;
  value?: unknown;
} & UnknownObject;

type Props<T extends FieldValues> =
  | {
      disabled?: never;
      validationHelpers: ValidationHelpers<T>;
      onBlur?: () => unknown;
    }
  | { disabled: boolean; validationHelpers?: ValidationHelpers<T> };

export function Field<TFieldValues extends FieldValues>({
  Component,
  validationHelpers,
  name,
  showValidationMessage = true,
  value,
  disable,
  validateOnChange,
  validateOnBlur,
  onBlur,
  validationMeta,
  validateOnInitialSet,
  focused,
  placeholder,
  onChangeOverride = (name, value, onChange, setStateValue) => {
    onChange(name)(value);
    setStateValue(value);
  },
  ...rest
}: StaticProps<TFieldValues> & Props<TFieldValues>) {
  const { t } = useTranslation();

  const [initialValueUpdated, setInitialValueUpdated] = useState(false);

  const [stateValue, setStateValue] = useState<unknown>(value);

  const { setValue, getFieldState, setTouched, setValueWithoutValidation, watch, validateMode } =
    validationHelpers || {
      setValue: () => undefined,
      setValueWithoutValidation: () => undefined,
      getFieldState: () => ({}) as FieldState,
      setTouched: () => undefined
    };

  //triggers state updates on field value change
  watch?.();

  const fieldMeta = (validationMeta ?? getFieldState(name as Path<TFieldValues>)) as FieldState;
  const { error }: FieldState = fieldMeta || {};

  const onChange = (name: Path<TFieldValues>) => (value: unknown) => {
    if (validateOnChange) {
      setValue(name, value as TFieldValues[keyof TFieldValues]);
    } else {
      setValueWithoutValidation(name, value as TFieldValues[keyof TFieldValues]);
    }
  };

  const onChangeHandler = (value: never) => {
    onChangeOverride(name, value, onChange, setStateValue);
  };

  const onBlurHandler = (...args: any[]) => {
    if (typeof onBlur === 'function') {
      onBlur?.(...args);
    }

    if (validateOnBlur || validateMode === 'onBlur') setTouched(name);
  };

  useEffect(() => {
    if (!initialValueUpdated) {
      if (validateOnInitialSet) {
        setValue(name, value as TFieldValues[keyof TFieldValues]);
      }

      setInitialValueUpdated(true);
    } else {
      value !== '' && setValueWithoutValidation(name, value as TFieldValues[keyof TFieldValues]);
    }

    setStateValue(value);
  }, [value, initialValueUpdated]);

  const message = t(error?.message ?? '');

  useEffect(() => {
    if (focused) {
      const input = document.querySelector(`input[name="${name}"]`) as HTMLInputElement;

      input?.focus?.();
    }
  }, []);

  return (
    <Component
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      value={stateValue}
      checked={isNaN(Number(stateValue)) || !stateValue ? undefined : stateValue}
      fieldMeta={{ ...fieldMeta, message, showValidationMessage }}
      disable={disable}
      name={name}
      placeholder={placeholder}
      {...rest}
    />
  );
}
