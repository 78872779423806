import { SwipeHandlersOptions, useSwipeHandlers } from './hooks';
import { Pill } from './layout.styles';

type Props = {
  swipeAreaRef?: React.RefObject<HTMLDivElement>;
  swipeOptions?: SwipeHandlersOptions;
};

const DragablePill = ({ swipeAreaRef, swipeOptions }: Props) => {
  useSwipeHandlers(swipeAreaRef?.current ?? null, swipeOptions);

  return <Pill id="draggable-pill" data-dragable-element="true" />;
};

export default DragablePill;
