import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { Modal } from 'components';
import { Note } from 'components/notificationModal/notificationModal.styles';
import { useModalContext } from 'features/nav/context/modalContext';

import { ToggleParams } from '../offerStatusModal/offerStatusModal';

type Props = {
  onVisibilityChange?: Dispatch<SetStateAction<boolean>>;
  isOpen?: boolean;
} & ToggleParams;

export const OfferSubmittedModal: FC<Props> = ({ isOpen, onVisibilityChange, ...rest }) => {
  const { t } = useTranslation();

  const { modals } = useModalContext<ToggleParams>();

  const handleClose = () => {
    onVisibilityChange?.(false);
  };

  const handleAction = () => {
    modals?.get?.('offer-status')?.(rest);
    handleClose();
  };

  return (
    <Modal
      variant="informational"
      informationalIcon={<Icons.Star />}
      testId="offer-submitted"
      isOpen={!!isOpen}
      onClose={handleClose}
      mainActionhandler={handleAction}
      mainActionLabel={t('takeALook')}
      label={t('yourOfferIsSubmitted')}
      hideSecondaryAction={true}
    >
      <Note>{t('yourOfferIsSubmittedExplanation')}</Note>
    </Modal>
  );
};
