import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Field, Fieldset, IbanInput, Input, Modal } from 'components';
import { getSmeBankPromo } from 'defaultConfiguration/getSmeBankPromo';
import { useErrorActionToast, useSuccessActionToast } from 'features/app/hooks';
import { selectActiveCompany, selectVerificationApplication } from 'features/applications/selectors';
import { useFetchBankAccountInfoQuery, useSubmitBankAccountInfoMutation } from 'features/onboarding/api';
import { useGetOnboardingEnabledConfig } from 'features/onboarding/hooks';
import { ondatoNotReadyCAPStatuses } from 'features/onboarding/hooks/useCurrentFlowStep';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAppSelector } from 'hooks/redux/hooks';
import { getCountryDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';
import tracking from 'utils/tracking';
import { validIBANrequired } from 'validators';

import BankCard from '../bankCard/bankCard';

import { Contents, Legend } from './alternativeBankModal.styles';

type AlternativeBankValues = {
  iban: string;
};

type Props = {
  open: boolean;
  onVisibilityChange: (isVisible: boolean) => unknown;
};

const initialValues: AlternativeBankValues = {
  iban: ''
};

export const validationSchema = Yup.object({
  iban: validIBANrequired
});

const noop = () => {};

const AlternativeBankModal = ({ onVisibilityChange, open }: Props) => {
  const { t, i18n } = useTranslation();

  const activeCompany = useAppSelector(selectActiveCompany);
  const application = useAppSelector(selectVerificationApplication);

  const { ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const smeBankPromoList = getSmeBankPromo();

  const enableSmeBankPromo = smeBankPromoList.includes(
    application.company?.country ?? application.farm?.country ?? ''
  );

  const [showErrorToast, { clearToast }] = useErrorActionToast();
  const [showSuccessToast] = useSuccessActionToast({
    header: 'bankAccountAddedExclamation',
    displayIcon: false
  });

  const resolvedNotReadyForOndato = ondatoNotReadyCAPStatuses.filter(
    (status) => status !== ondatoAvailableCAPStatus
  );

  const notReadyForOndato =
    !application.applicationId || resolvedNotReadyForOndato.includes(application?.status ?? 'INITIAL');

  const { currentData: bankAccountInfo } = useFetchBankAccountInfoQuery(
    {
      applicationId: application.applicationId ?? ''
    },
    { skip: notReadyForOndato || !open }
  );

  const [submitBankAccountInfo, { isLoading: isSubmitting }] = useSubmitBankAccountInfoMutation();

  const { validationHelpers, handleSubmit, setTouchedOnAll, setValueWithoutValidation, getValues } =
    useFormHandlers<AlternativeBankValues>(initialValues, validationSchema);

  const onClose = () => {
    setValueWithoutValidation('iban', '');
    onVisibilityChange(false);
  };

  const onSubmit = handleSubmit(
    async (data) => {
      try {
        clearToast();
        if (application.applicationId) {
          await submitBankAccountInfo({ ...data, applicationId: application.applicationId ?? '' }).unwrap();

          showSuccessToast();
        } else {
          throw new Error('Application ID is missing');
        }

        tracking.setSubmitEvent('single_flow_alternative_bank_account_added');

        onClose();
      } catch {
        showErrorToast();
      }
    },
    () => setTouchedOnAll()
  );

  const countryDisplayName =
    getCountryDisplayName(activeCompany.country ?? '', i18n.language as Languages) ?? '';

  useEffect(() => {
    if (bankAccountInfo?.iban) {
      setValueWithoutValidation('iban', bankAccountInfo?.iban);
    }
  }, [bankAccountInfo?.iban]);

  return (
    <Modal
      isOpen={open}
      label={t(enableSmeBankPromo ? 'addAlternativeBank' : 'addBankAccount')}
      variant="functional"
      mainActionLabel={t('add')}
      mainActionhandler={onSubmit}
      mainActionDisabled={isSubmitting}
      mainActionSubmitting={isSubmitting}
      onClose={onClose}
      secondaryActionLabel="cancel"
    >
      <Contents>
        {enableSmeBankPromo ? <BankCard /> : null}
        <Fieldset>
          {enableSmeBankPromo ? <Legend>{t('alternativeBank')}</Legend> : null}
          <Input
            placeholder={t('placeholderCompanyName')}
            type="text"
            value={activeCompany.name}
            disabled
            onChange={noop}
            required
          />
          <Input
            required
            placeholder={t('country')}
            type="text"
            value={countryDisplayName}
            disabled
            onChange={noop}
          />
          <Field
            disabled={!!bankAccountInfo?.iban}
            placeholder={t('iban')}
            name="iban"
            Component={IbanInput}
            value={getValues('iban')}
            validationHelpers={validationHelpers}
            required
          />
        </Fieldset>
      </Contents>
    </Modal>
  );
};

export default AlternativeBankModal;
