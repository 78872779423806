import styled from 'styled-components';

export const PurposeFieldset = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const ChipOptions = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const FadeInContainer = styled.div`
  display: flex;
  width: 100%;
  animation: dissolve 150ms ease-out forwards;

  @keyframes dissolve {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
