import { FC, useState, useEffect, FormEvent, FocusEventHandler, HTMLAttributes, useId, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { HelpText } from 'components/text/text.styles';

import { ErrorMessage } from '../formError/errors.styles';
import { FieldState } from '../formField/field';

import {
  InputContainer,
  SuffixLabel,
  TextAreaStyle,
  CounterWrapper,
  HiddenArea,
  TextAreaPlaceholderLabel,
  ElipsisTruncatedText
} from './input.style';

type Props = {
  value: string | number;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: string) => unknown;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  fieldMeta?: FieldState;
  className?: string;
  required?: boolean;
  maxDecimals?: number;
  readOnly?: boolean;
  name?: string;
  suffix?: string;
  dense?: boolean;
  helpText?: string;
  maxLength?: number;
  counter?: boolean;
  rows?: number;
  maxHeight?: number;
  autoHeight?: boolean;
};

type NativeAttributes = Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'>;

const TextArea: FC<Props & Partial<NativeAttributes>> = ({
  value,
  placeholder,
  disabled,
  onChange,
  onBlur,
  fieldMeta,
  className,
  required,
  readOnly,
  name,
  suffix,
  dense,
  autoHeight,
  maxHeight = 76,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const hiddenAreaRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState(value || '');

  const inputId = useId();
  const uniqueId = `${placeholder}_${inputId}`;

  const handleChange = (event: FormEvent<HTMLTextAreaElement>) => {
    const currentValue = event.currentTarget.value;

    setInputValue(currentValue);
    onChange(currentValue);
  };

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    if (autoHeight) {
      if (hiddenAreaRef.current) {
        hiddenAreaRef.current.textContent = `|${inputValue}`;
      }

      if (textAreaRef.current && hiddenAreaRef.current) {
        if (maxHeight && hiddenAreaRef.current.clientHeight > maxHeight) {
          textAreaRef.current.style.height = `${maxHeight}px`;
          return;
        }

        textAreaRef.current.style.height =
          hiddenAreaRef.current.clientHeight < 76
            ? `${76}px`
            : `${hiddenAreaRef.current.clientHeight + 28}px`;
      }
    }
  }, [inputValue, autoHeight, maxHeight]);

  const onBlurHandler: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    onBlur?.(e);
  };

  const hasValidationMessages = error?.message && isTouched;

  const clearedValue =
    typeof inputValue === 'number' ? (isNaN(inputValue) ? '' : inputValue) : inputValue || '';

  return (
    <InputContainer
      data-testid={`input-container-${placeholder}`}
      className={className}
      hasPlaceholder={!!placeholder}
    >
      <HiddenArea ref={hiddenAreaRef}></HiddenArea>
      <TextAreaStyle
        ref={textAreaRef}
        dense={dense}
        onBlur={onBlurHandler}
        invalidStatus={!!hasValidationMessages}
        value={clearedValue}
        onChange={handleChange}
        disabled={disabled}
        data-testid={placeholder}
        id={uniqueId}
        readOnly={readOnly}
        name={name}
        placeholder={placeholder}
        {...rest}
      />
      {placeholder && (
        <TextAreaPlaceholderLabel
          textArea
          animatePlaceholder
          dense={dense}
          htmlFor={uniqueId}
          hasValue={inputValue !== ''}
          required={required}
        >
          <ElipsisTruncatedText>{placeholder}</ElipsisTruncatedText>
        </TextAreaPlaceholderLabel>
      )}
      {suffix && <SuffixLabel>{suffix}</SuffixLabel>}
      {!required && !suffix && <SuffixLabel>{t('optional')}</SuffixLabel>}
      <CounterWrapper>
        <span>
          <ErrorMessage hidden={!hasValidationMessages || !showValidationMessage}>{message}</ErrorMessage>
        </span>
        {rest.counter ? <HelpText>{`${String(clearedValue).length || 0}/${rest.maxLength}`}</HelpText> : null}
      </CounterWrapper>
    </InputContainer>
  );
};

export default TextArea;
