import { FC } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

import { AmountText, RegularText, SecondaryText, StatusChip } from 'components';
import { CellCollumn } from 'components/table/table.styles';
import { StatusType } from 'design/designVariables';
import { LoanOverviewItem } from 'services/LoansService/types';

import { ContractItem } from '../types';

import { Contracts, withNullCheck } from './contracts';

type Item = ContractItem & LoanOverviewItem;

type ContractStatus = 'UNKNOWN_STATUS' | 'ACTIVE_NOT_DISBURSED' | 'ACTIVE' | 'SHORT_FALL' | 'LOST_RECOVERY';

type Props = {
  items: Item[];
  firstItemSelectedByDefault?: boolean;
  onRowClick?: (contractId: string) => unknown;
  loading?: boolean;
};

type StatusTranslationsMap = {
  [key in ContractStatus]: string;
};

type StatusColorsMap = {
  [key in ContractStatus]: keyof StatusType;
};

const statusTranslationsMap: StatusTranslationsMap = {
  UNKNOWN_STATUS: '',
  ACTIVE_NOT_DISBURSED: 'activeNotDisbursed',
  ACTIVE: 'active',
  SHORT_FALL: 'shortfall',
  LOST_RECOVERY: 'shortfall'
};

const statusColorMap: StatusColorsMap = {
  UNKNOWN_STATUS: 'gray',
  ACTIVE_NOT_DISBURSED: 'yellow',
  ACTIVE: 'green',
  SHORT_FALL: 'red',
  LOST_RECOVERY: 'red'
};

export const LoansContracts: FC<Props> = ({ items, firstItemSelectedByDefault, onRowClick, loading }) => {
  const { t } = useTranslation();

  const showAssetColumn = location.pathname.includes('leasing');

  const additionalColumns: TableColumn<Item>[] = [
    {
      selector: (row) => withNullCheck(row.loanAmount),
      name: t('amount'),
      format: (row) => (
        <RegularText>
          <AmountText>{row.loanAmount}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.contractStatus),
      name: t('status'),
      format: (row) =>
        row.contractStatus && row.contractStatus !== 'UNKNOWN_STATUS' ? (
          <StatusChip color={statusColorMap[row.contractStatus as ContractStatus]}>
            <RegularText>{t(statusTranslationsMap[row.contractStatus as ContractStatus])}</RegularText>
          </StatusChip>
        ) : (
          '-'
        )
    }
  ];

  const loansAssetColumn: TableColumn<Item> = {
    selector: (row) => withNullCheck(row.assetDescription),
    name: t('asset'),
    conditionalCellStyles: [
      {
        when: () => true,
        classNames: ['table-cell_asset']
      }
    ],
    format: (row) => {
      if (!row.assetDescription) {
        return null;
      }
      return (
        <CellCollumn>
          <RegularText>{row.assetDescription}</RegularText>
          <SecondaryText>{row.assetVin}</SecondaryText>
        </CellCollumn>
      );
    }
  };

  const fullAdditionalColumns = [showAssetColumn ? loansAssetColumn : null, ...additionalColumns].filter(
    (el) => el
  ) as TableColumn<Item>[];

  return (
    <Contracts
      items={items}
      firstItemSelectedByDefault={firstItemSelectedByDefault}
      onRowClick={onRowClick}
      additionalColumns={fullAdditionalColumns}
      loading={loading}
    />
  );
};
