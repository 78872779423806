import { useSearchParams } from 'react-router-dom';

import {
  LimitDetail,
  LimitDetailsSection
} from 'features/contracts/components/limitDetailsSection/limitDetailsSection';
import { selectChosenLoansContractInfo } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';

export const CreditLineLimitsDetails = () => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contract = useLoansSelector<typeof selectChosenLoansContractInfo>(selectChosenLoansContractInfo)(
    contractId || ''
  );

  if (!contract) return null;

  const { loanAmount, usedAmount, availableAmount, overdueAmount } = contract;

  const data: LimitDetail[] = [
    {
      label: 'total',
      info: 'loansTotalAmountExplanation',
      value: loanAmount
    },
    {
      label: 'used',
      info: 'loansUsedAmountExplanation',
      value: usedAmount
    },
    {
      label: 'available',
      info: 'loansAvailableAmountExplanation',
      value: availableAmount
    },
    {
      label: 'overdue',
      info: 'loansOverdueAmountExplanation',
      value: overdueAmount
    }
  ];

  return <LimitDetailsSection data={data} />;
};
