import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { RegistrationCountryModal } from 'features/application/components/registrationCountryModal';
import { useFormContext } from 'features/application/context/FormContext';
import { STEPS } from 'features/application/types/applicationTypes';
import { selectIsActiveCompanyVerified } from 'features/applications/selectors';
import { selectActiveCustomer, selectIsUserGhost } from 'features/auth/selectors';
import { useAppDispatch, useAppSelector, useApplicationSelector, useAuthSelector } from 'hooks/redux/hooks';
import { Company } from 'services/CapService/types';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { FormContainer } from '../../components/formContainer';
import { selectCompanyCountry, selectIsNewCompany } from '../../selectors';
import { increaseCompletionIndex, setActiveStep, setCompanyData, submitApplicationAsync } from '../../slice';

import { FormStyle } from './company.style';
import { CompanyCountryFields } from './companyCountryFields';
import { COMPANY_REGISTRATION_COUNTRIES_LIST, CountryOfCompanyRegistration } from './constants';

export const validationSchema = Yup.object({
  country: Yup.string().oneOf(
    COMPANY_REGISTRATION_COUNTRIES_LIST.map((purpose) => purpose.value),
    VALIDATION_MESSAGE_ID.REQUIRED
  )
});

const initialCompany: Company = {
  country: 'LT'
};

const getMarketCode = () => {
  const market = new URLSearchParams(window.location.search).get('market');

  if (market) {
    return market.toUpperCase() as CountryOfCompanyRegistration;
  }

  const dataAttributeName = 'data-country-suggestion';

  if (window.document.body.hasAttribute(dataAttributeName)) {
    const market = window.document.body.getAttribute(dataAttributeName);

    window.document.body.removeAttribute(dataAttributeName);

    return market?.toUpperCase() as CountryOfCompanyRegistration;
  }

  return null;
};

const useCountrySuggestion = (): CountryOfCompanyRegistration | null => {
  const market = getMarketCode();

  const isValid = COMPANY_REGISTRATION_COUNTRIES_LIST.some((country) => country.value === market);

  return isValid ? (market as CountryOfCompanyRegistration) : null;
};

const CompanyCountryForm: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isGhostUser = useAuthSelector(selectIsUserGhost);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);

  const suggestionCountry = useCountrySuggestion();

  const dispatch = useAppDispatch();
  const [isCountrySuggestionsModalOpen, setIsCountrySuggestionsModalOpen] = useState(!!suggestionCountry);

  const currentCompanyCountry = useApplicationSelector(selectCompanyCountry);

  const { customerCountryId } = activeCustomer || {};

  const isNewCompany = useAppSelector(selectIsNewCompany);

  const isActiveCompanyVerified = useAppSelector(selectIsActiveCompanyVerified);

  const anyCompanyIsAllowed = isNewCompany || isGhostUser || !isActiveCompanyVerified;

  const formData = useMemo(
    () => ({ country: currentCompanyCountry ?? customerCountryId ?? initialCompany.country }),
    [customerCountryId, anyCompanyIsAllowed]
  );

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers<Company>(
    formData,
    validationSchema,
    'onBlur'
  );

  const { setFormHandlers } = useFormContext<Company>();

  const submitHandler = handleSubmit(
    (data: Company) => {
      dispatch(setCompanyData(data));
      dispatch(submitApplicationAsync());
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(
    () =>
      setFormHandlers({
        submitHandler,
        validationHelpers,
        getValues
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFormHandlers]
  );

  const onCountrySuggestionAccept = () => {
    if (suggestionCountry) {
      dispatch(setCompanyData({ country: suggestionCountry }));
      validationHelpers.setValue('country', suggestionCountry);
      dispatch(setActiveStep(STEPS.COMPANY));
      dispatch(increaseCompletionIndex());
      searchParams.delete('market');
      setSearchParams(searchParams, { replace: true });
    }
  };

  return (
    <>
      <RegistrationCountryModal
        onVisibilityChange={setIsCountrySuggestionsModalOpen}
        isOpen={isCountrySuggestionsModalOpen}
        country={suggestionCountry ?? 'LT'}
        onAccept={onCountrySuggestionAccept}
      />
      <FormContainer isValid={validationHelpers.isValid} onNextClick={submitHandler}>
        <FormStyle>
          <CompanyCountryFields
            validationHelpers={validationHelpers}
            getValues={getValues}
            isGhostUser={anyCompanyIsAllowed}
          />
        </FormStyle>
      </FormContainer>
    </>
  );
};

export default CompanyCountryForm;
