import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Content, ContentBody, ContentHeader, InformationalDrawerContent } from './infromationDrawer.styles';

export type Props = {
  header?: string;
  body?: string;
  icon?: ReactNode;
};

const InformationDrawerContent = ({ header, body, icon }: Props) => {
  const { t } = useTranslation();

  return (
    <InformationalDrawerContent>
      <Content>
        {icon}
        {header ? <ContentHeader>{t(header)}</ContentHeader> : null}
        {body ? <ContentBody type="L">{t(body)}</ContentBody> : null}
      </Content>
    </InformationalDrawerContent>
  );
};

export default InformationDrawerContent;
