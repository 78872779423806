import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  RegularText,
  StatusChip,
  AmountText,
  transformDateToLocalizedString,
  PercentageText
} from 'components';
import { StatusType } from 'design/designVariables';
import { useGetContractReferenceRateQuery } from 'features/contracts/api';
import { IncreaseFinancingLimitButton, ProlongContractButton } from 'features/contracts/components';
import {
  ChipText,
  ContractInformation,
  ContractInformationSet,
  ContractInformationCard,
  ContractDetailsSection,
  ContractInformationSection,
  ContractDetailsSectionHeader,
  SectionHeaderText,
  Label
} from 'features/loans/components/loansContractDetails/loansContractDetails.styles';
import { ContractDetailsResponse } from 'services/ContractsService/types';
import { camelCase } from 'utils';

import { Actions } from './factoringContractDetails.styles';

type StatusToColorMap = Record<Required<ContractDetailsResponse>['status'], keyof StatusType>;

type Props = {
  contractDetails: ContractDetailsResponse;
};

export const statusToColorMap: StatusToColorMap = {
  PREPARED: 'gray',
  READY_FOR_SIGNING: 'gray',
  ACTIVE: 'green',
  MANUALLY_SUSPENDED: 'red',
  EXPIRED: 'yellow'
};

export const withNullCheck = (value?: number | string) => (value || value === 0 ? value : '-');

export const FactoringContractDetails: FC<Props> = ({ contractDetails }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || '';

  const { data: referenceRate } = useGetContractReferenceRateQuery(
    {
      contractId: contractId,
      contractVersion: String(contractDetails.contractVersion)
    },
    {
      skip:
        !contractDetails.contractVersion ||
        !contractId ||
        contractId !== contractDetails.id ||
        !contractDetails?.interestFeeConditions?.referenceRateMaturity
    }
  );

  return (
    <ContractInformationCard data-testid="contract-details">
      <ContractInformationSection
        data-testid="contract-details-information-section"
        colorName={contractDetails?.status ? statusToColorMap[contractDetails?.status] : 'white'}
      >
        <ContractDetailsSectionHeader>
          <SectionHeaderText>{t('contractInfo')}</SectionHeaderText>
          <StatusChip color="white">
            {<ChipText>{t(camelCase(withNullCheck(contractDetails?.status) as string))}</ChipText>}
          </StatusChip>
        </ContractDetailsSectionHeader>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('factoringType')}</Label>
            <RegularText>
              {t(camelCase(withNullCheck(contractDetails?.factoringProduct) as string))}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('financier')}</Label>
            <RegularText>{withNullCheck(contractDetails?.factor?.name)}</RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('factoringLimit')}</Label>
            <RegularText>
              <AmountText>{withNullCheck(contractDetails?.factoringCreditLimit)}</AmountText>
            </RegularText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('validFrom')}</Label>
            <RegularText>
              {withNullCheck(transformDateToLocalizedString(contractDetails?.contractTerm?.startDate))}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('validTo')}</Label>
            <RegularText>
              {withNullCheck(transformDateToLocalizedString(contractDetails?.contractTerm?.endDate))}
            </RegularText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <Actions>
            <ProlongContractButton />
            <IncreaseFinancingLimitButton />
          </Actions>
        </ContractInformationSet>
      </ContractInformationSection>
      <ContractDetailsSection>
        <ContractDetailsSectionHeader>
          <SectionHeaderText>{t('contractDetails')}</SectionHeaderText>
        </ContractDetailsSectionHeader>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('interestPeriod')}</Label>
            <RegularText>
              {t(withNullCheck(camelCase(contractDetails?.interestFeeConditions?.period || '')) as string)}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('interest')}</Label>
            <PercentageText>{withNullCheck(contractDetails?.interestFeeConditions?.rate)}</PercentageText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('commissionFee')}</Label>
            <PercentageText>
              {withNullCheck(contractDetails?.commissionFeeConditions?.commissionFeeRate)}
            </PercentageText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('interestType')}</Label>
            <RegularText>
              {t(withNullCheck(camelCase(contractDetails?.interestFeeConditions?.type || '')) as string)}
            </RegularText>
          </ContractInformation>
          {contractDetails?.interestFeeConditions?.type === 'FLOATING' && (
            <>
              <ContractInformation>
                <Label>{t('euriborPeriod')}</Label>
                <RegularText>
                  {t(
                    withNullCheck(
                      camelCase(contractDetails?.interestFeeConditions?.referenceRateMaturity || '')
                    ) as string
                  )}
                </RegularText>
              </ContractInformation>
              <ContractInformation>
                <Label>{t('variableInterest')}</Label>
                <PercentageText>{withNullCheck(referenceRate?.referenceRate)}</PercentageText>
              </ContractInformation>
            </>
          )}
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('deferralPeriod')}</Label>
            <RegularText>
              {`${withNullCheck(contractDetails?.thirdPartyConstraints?.maxInvoicePaymentTerm)} ${t('days')}`}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('advance')}</Label>
            <PercentageText>
              {withNullCheck(contractDetails?.thirdPartyConstraints?.maxAdvanceRate)}
            </PercentageText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('lateFee')}</Label>
            <PercentageText>{withNullCheck(contractDetails?.lateFeeConditions?.lateFeeRate)}</PercentageText>
          </ContractInformation>
        </ContractInformationSet>
        <ContractInformationSet>
          <ContractInformation>
            <Label>{t('financiersInsurance')}</Label>
            <RegularText>
              {contractDetails?.recourseConditions?.insolvencyRiskInsuredByFactor
                ? t('applicable')
                : t('notApplicable')}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('clientsInsurance')}</Label>
            <RegularText>
              {contractDetails?.recourseConditions?.insolvencyRiskInsuredByClient
                ? t('applicable')
                : t('notApplicable')}
            </RegularText>
          </ContractInformation>
          <ContractInformation>
            <Label>{t('clientsResponsibility')}</Label>
            <PercentageText>
              {withNullCheck(contractDetails?.recourseConditions?.clientLiabilityRate)}
            </PercentageText>
          </ContractInformation>
        </ContractInformationSet>
      </ContractDetailsSection>
    </ContractInformationCard>
  );
};
