import React, { createRef, RefObject, useState, FC, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { FileChip } from 'components/uploadArea/fileChip';
import { UploadStatus } from 'features/applications/types/applicationsTypes';

import Icons from '../../assets/icons';
import { ScreenSize } from '../layout';
import { ErrorText, RegularText } from '../text';

import {
  ChildrenContainer,
  FileNamesContainer,
  HiddenFileInput,
  UploadButtonStyle,
  UploadStyle,
  ValidationMessageContainer,
  FileUploadButtonMobileContainer,
  LabelContainer,
  UploadStatusChip
} from './upload.style';

export type Status = { error: string; filename: string };

interface FileUpload {
  buttonText: string;
  children?: ReactNode;
  onClick: (files: File[]) => void;
  onRemove?: (filenames: string[], removedFilename?: string) => void;
  allowedExtensions?: string;
  maxUploadSizeWarning?: string;
  maxFilesCountWarning?: string;
  maxSizeInMB?: number;
  className?: string;
  statusChip?: boolean;
  multipleFiles?: boolean;
  maxFilesCount?: number;
  fileStatus?: Status[];
  onValidation?: (isValid: boolean, errors?: string[]) => unknown;
  formatFileName?: (filename: string) => string;
  showValidationMessages?: boolean;
  filesNames?: string[];
}

type UploadStatusTranslations = {
  [key in UploadStatus]: string;
};

type UploadStatusColors = {
  [key in UploadStatus]: 'green' | 'red';
};

const uploadStatusTranslations: UploadStatusTranslations = {
  PROVIDED: 'provided',
  REQUIRED: 'required'
};

const uploadStatusColor: UploadStatusColors = {
  PROVIDED: 'green',
  REQUIRED: 'red'
};

const Upload: FC<FileUpload> = ({
  onClick,
  buttonText,
  children,
  allowedExtensions,
  maxUploadSizeWarning,
  maxSizeInMB,
  maxFilesCount,
  maxFilesCountWarning,
  className,
  statusChip,
  multipleFiles,
  fileStatus,
  onRemove,
  onValidation,
  formatFileName = (value) => value,
  showValidationMessages = true,
  filesNames
}) => {
  const { t } = useTranslation();
  const fileInputRef: RefObject<HTMLInputElement> = createRef();
  const handleInput = () => fileInputRef.current?.click();
  const [fileNames, setFileNames] = useState<string[]>(filesNames ? filesNames : []);
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [fileSizeStatus, setfileSizeStatus] = useState<Status[]>([]);
  const [sizeWarningTriggered, setSizeWarningTriggered] = useState<boolean>(false);
  const [maxFilesCountWarningTriggered, setMaxFilesCountWarningTriggered] = useState<boolean>(false);

  const resetValidations = () => {
    setMaxFilesCountWarningTriggered(false);
    setSizeWarningTriggered(false);
    setfileSizeStatus([]);
  };

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    resetValidations();

    const files = (e.target as HTMLInputElement).files || [];
    const filesArray = Array.from(files);

    const filteredLocalFiles = localFiles.filter(
      (localFile) => !filesArray.find((uploadedFile) => uploadedFile.name === localFile.name)
    );

    if (filteredLocalFiles.length !== localFiles.length) {
      setLocalFiles(filteredLocalFiles);
    }

    const fileCollection = multipleFiles ? [...filteredLocalFiles, ...filesArray] : filesArray;

    if (maxFilesCount) {
      if (fileCollection.length > maxFilesCount) {
        setMaxFilesCountWarningTriggered(true);

        return;
      }
    }

    let filesExceedMaxSize = false;

    filesArray?.forEach((file: File) => {
      if (file.size > (maxSizeInMB || 1)) {
        filesExceedMaxSize = true;

        if (multipleFiles) {
          setfileSizeStatus([...fileSizeStatus, { filename: file.name, error: maxUploadSizeWarning || '' }]);
        } else {
          setSizeWarningTriggered(true);
        }
      }
    });

    if (filesExceedMaxSize && !multipleFiles) {
      return;
    }

    const fileNames = fileCollection.map((file) => file.name);

    setFileNames(fileNames);
    setLocalFiles(fileCollection);
    onClick(fileCollection);
  };

  useEffect(() => {
    if (fileInputRef.current) {
      const newFilesDataSet = new DataTransfer();

      localFiles.forEach((file) => newFilesDataSet.items.add(file));
      fileInputRef.current.files = newFilesDataSet.files;
    }
  }, [fileInputRef, localFiles]);

  const onDeleteClick = (filename: string) => {
    if (fileInputRef.current) {
      if (!multipleFiles) {
        fileInputRef.current.files = null;
        setFileNames([]);
        onRemove?.([], filename);
        setfileSizeStatus([]);
      } else {
        const filteredFiles = localFiles.filter((localFile) => localFile.name !== filename);
        const filteredFileNames = filteredFiles.map((file) => file.name);

        setfileSizeStatus(
          fileSizeStatus.filter((status) => filteredFiles.find((file) => file.name === status.filename))
        );
        setLocalFiles(filteredFiles);
        setFileNames(filteredFileNames);

        onRemove?.(filteredFileNames, filename);
      }
    }
  };

  const filesUploaded = fileNames.length > 0;
  const uploadStatus = filesUploaded ? 'PROVIDED' : 'REQUIRED';

  const FileUploadButton = () => (
    <UploadButtonStyle onClick={handleInput}>
      <Icons.UploadOutline />
      {buttonText}
    </UploadButtonStyle>
  );

  const invalidFiles = Array.from(
    new Set([
      ...(fileStatus?.map((status) => status?.filename) || []),
      ...(fileSizeStatus?.map((status) => status?.filename) || [])
    ])
  );

  const extraValidationMessages = invalidFiles
    .map(
      (filename) =>
        fileSizeStatus?.find((status) => status.filename === filename) ||
        fileStatus?.find((status) => status.filename === filename)
    )
    .map((status) => `${status?.filename}: ${status?.error}`);

  useEffect(() => {
    const isValid = !maxFilesCountWarningTriggered && !extraValidationMessages?.length;
    const validationMessages = isValid
      ? undefined
      : maxFilesCountWarningTriggered
        ? [maxFilesCountWarning || '', ...extraValidationMessages]
        : extraValidationMessages;

    onValidation?.(isValid, validationMessages);
  }, [extraValidationMessages, maxFilesCountWarning, maxFilesCountWarningTriggered, onValidation]);

  return (
    <UploadStyle className={className}>
      <>
        <HiddenFileInput
          ref={fileInputRef}
          onChange={handleFileChange}
          type="file"
          accept={allowedExtensions}
          multiple={multipleFiles}
        />
        <ChildrenContainer>
          <LabelContainer>
            {children}
            {statusChip && (
              <UploadStatusChip color={uploadStatusColor[uploadStatus]}>
                <RegularText>{t(uploadStatusTranslations[uploadStatus])}</RegularText>
              </UploadStatusChip>
            )}
          </LabelContainer>
          {(!filesUploaded || multipleFiles) && (
            <ScreenSize desktopXL desktop tablet>
              <FileUploadButton />
            </ScreenSize>
          )}
        </ChildrenContainer>

        {filesUploaded && (
          <FileNamesContainer>
            {fileNames.map((filename, index) => {
              const error = Boolean(fileStatus?.find((status) => status?.filename === filename));

              return (
                <FileChip
                  key={index}
                  error={error}
                  formatFilename={formatFileName}
                  file={localFiles.find((file) => file.name === filename)}
                  filename={filename}
                  handleDelete={onDeleteClick}
                />
              );
            })}
          </FileNamesContainer>
        )}
        {showValidationMessages && sizeWarningTriggered && (
          <ValidationMessageContainer>
            <ErrorText>{maxUploadSizeWarning}</ErrorText>
          </ValidationMessageContainer>
        )}
        {showValidationMessages && maxFilesCountWarningTriggered && (
          <ValidationMessageContainer>
            <ErrorText>{maxFilesCountWarning}</ErrorText>
          </ValidationMessageContainer>
        )}
        {showValidationMessages && extraValidationMessages && extraValidationMessages.length
          ? extraValidationMessages.map((message) => (
              <ValidationMessageContainer key={message}>
                <ErrorText>{message}</ErrorText>
              </ValidationMessageContainer>
            ))
          : null}
        {!filesUploaded && (
          <ScreenSize display="flex" fullWidth mobile>
            <FileUploadButtonMobileContainer>
              <FileUploadButton />
            </FileUploadButtonMobileContainer>
          </ScreenSize>
        )}
      </>
    </UploadStyle>
  );
};

Upload.defaultProps = {
  allowedExtensions: '.docx,.xlsx,.csv,.jpg,.tiff,.git,.png,.pdf,.pptx,.adoc',
  maxSizeInMB: 15 * 1024 * 1024 // 15 MB
};

export default Upload;
