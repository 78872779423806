import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import celebration from 'assets/icons/celebrationLarge.svg';
import documentError from 'assets/icons/documentErrorLarge.svg';
import { NotificationModal } from 'components';
import { NotificationImage } from 'components/notificationModal/notificationModal.styles';
import { resetApplicationState } from 'features/application/slice';
import { useModalContext } from 'features/nav/context/modalContext';
import { useOndatoRedirectHandler } from 'features/onboarding/hooks';
import { useAppDispatch } from 'hooks/redux/hooks';

type Props = {
  open?: boolean;
  variant: 'success' | 'error' | 'successNextSteps';
  applicationId: string;
  onClose: () => unknown;
};

const informationalIcons: Record<Props['variant'], React.ReactNode> = {
  successNextSteps: <NotificationImage src={celebration} alt="" />,
  success: <NotificationImage src={celebration} alt="" />,
  error: <NotificationImage src={documentError} alt="" />
};

const explanations: Record<Props['variant'], { explanation: string; header: string }> = {
  successNextSteps: {
    explanation: 'youAcceptedOfferExplanationNextSteps',
    header: 'youAcceptedOffer'
  },
  success: {
    explanation: 'youAcceptedOfferExplanation',
    header: 'youAcceptedOffer'
  },
  error: {
    explanation: 'youRejectedOfferExplanation',
    header: 'youRejectedOffer'
  }
};

const OfferStatusConfirmationModal = ({ open, variant, onClose, applicationId }: Props) => {
  const { t } = useTranslation();
  const { modals } = useModalContext();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSuccess = (success: boolean) => {
    if (success) {
      modals?.get('ondato-intro')?.();
      onClose();
    }
  };

  const [onVerifyIdentityClick, isSubmitting] = useOndatoRedirectHandler({
    ondatoInfo: { applicationId, status: undefined },
    onOndatoInitSuccess: handleSuccess
  });

  const applyAgain = () => {
    dispatch(resetApplicationState());
    navigate('/new-application');
    onClose();
  };

  const explanation = explanations[variant];
  const icon = informationalIcons[variant];

  let mainActionLabel = '';

  switch (variant) {
    case 'success':
      mainActionLabel = t('verifyYourIdentity');
      break;
    case 'successNextSteps':
      mainActionLabel = t('viewNextSteps');
      break;
    case 'error':
      mainActionLabel = t('applyAgain');
      break;
  }

  let mainActionHandler = () => {
    onClose();
  };

  switch (variant) {
    case 'success':
      mainActionHandler = onVerifyIdentityClick;
      break;
    case 'error':
      mainActionHandler = applyAgain;
      break;
  }

  return (
    <NotificationModal
      type={variant === 'error' ? 'error' : 'success'}
      isOpen={Boolean(open)}
      informationalImage={icon}
      label={explanation.header}
      handleClose={onClose}
      explanationText={explanation.explanation}
      mainAction={{
        label: mainActionLabel,
        handler: mainActionHandler,
        isSubmitting
      }}
    />
  );
};

export default OfferStatusConfirmationModal;
