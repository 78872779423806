import styled from 'styled-components';

type Props = {
  defaultValueStyle?: boolean;
};

export const CurrencyDisplayInputWrapper = styled.div<Props>`
  position: absolute;
  width: 100%;
  z-index: 999;

  input {
    color: ${({ theme, defaultValueStyle }) =>
      defaultValueStyle ? theme.colors.base.black.shades[50] : 'inherit'};
  }
`;

export const CurrencyInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1.25rem;
`;

export const CurrencyValueInputWrapper = styled.div``;
