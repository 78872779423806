import { FC, Fragment } from 'react';

import { Column, Grid, CardsReelContainer } from 'components';
import { UpdateCompanyProfileCard } from 'features/onboarding/components';

import { Card } from './cards';

type Props = {
  cards: Card[];
};

export const CardsSection: FC<Props> = ({ cards }) => {
  const secondaryCards = cards.filter((card) => card.placement === 'Card' && card.display);

  return (
    <Grid>
      <Column span={12} colStart={1} spanLg={12} colStartLg={1}>
        <CardsReelContainer>
          <UpdateCompanyProfileCard />
          {secondaryCards.map((card, index) => (
            <Fragment key={index}>{card.children}</Fragment>
          ))}
        </CardsReelContainer>
      </Column>
    </Grid>
  );
};
