import { ReactNode } from 'react';

import Icons from '../../assets/icons';

import Popup from './popup';
import { DotsMenuPopup, PopupDropdownCard } from './popup.styles';

type Props = {
  children: ReactNode;
};

export const DotsMenu = ({ children }: Props) => {
  return (
    <DotsMenuPopup>
      <Popup
        placement="bottom-start"
        showPureChildren
        showOnHover={false}
        showArrow={false}
        inputContainer
        showAfter={0}
        withAnimation={false}
        button={<Icons.MenuDots />}
      >
        <PopupDropdownCard>{children}</PopupDropdownCard>
      </Popup>
    </DotsMenuPopup>
  );
};
