import { FC } from 'react';

import { Section } from 'components';
import { LoansLimitChartSection } from 'features/loans/components';
import { LoansUpcomingPayments } from 'features/loans/tables';

export const LoansOverview: FC = () => {
  return (
    <>
      <Section>
        <LoansLimitChartSection />
      </Section>
      <LoansUpcomingPayments navigateTo="/finance/loans/bills" />
    </>
  );
};
