import { FC } from 'react';

import { Section } from 'components';
import { RbfLimitChartSection } from 'features/loans/components';
import { CreditLineUpcomingPayments } from 'features/loans/tables';

export const RbfOverview: FC = () => {
  return (
    <>
      <Section>
        <RbfLimitChartSection />
      </Section>
      <CreditLineUpcomingPayments navigateTo="/finance/rbf/bills" />
    </>
  );
};
