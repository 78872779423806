import { ReactNode } from 'react';

import burgerMenu from '../../assets/icons/burgerMenu.svg';
import close from '../../assets/icons/close24px.svg';
import smeGo from '../../assets/logo/smeGoBorderless.svg';
import { ScreenSize } from '../layout/layout.styles';
import { LinkButton } from '../link/linkButton';

import {
  TopBarContainer,
  LogoButton,
  TopBarCloseButton,
  TopBarContainerProps,
  Header
} from './TobBar.styles';

type Props = {
  onToggleAcionClick?: () => unknown;
  isAuthenticated?: boolean;
  companySwitch?: ReactNode;
  title?: string;
} & TopBarContainerProps;

const Logo = () => (
  <LogoButton to="/dashboard/overview">
    <img src={smeGo} alt="smeGo" />
  </LogoButton>
);

export const TopBar = ({
  onToggleAcionClick,
  variant,
  isAuthenticated,
  companySwitch,
  title,
  alignment = 'left'
}: Props) => {
  const toggleAction = () => {
    onToggleAcionClick?.();
  };

  if (variant === 'inner') {
    return (
      <>
        <TopBarContainer variant={variant}>
          {alignment === 'right' ? <div /> : null}
          {title ? <Header>{title}</Header> : null}
          {onToggleAcionClick ? (
            <TopBarCloseButton
              data-testid="topbar-close-button"
              iconOnly
              icons={{ left: close }}
              color="Black"
              onClick={toggleAction}
            />
          ) : null}
          {alignment === 'left' ? <div /> : null}
        </TopBarContainer>
        {title ? <Header>{title}</Header> : null}
      </>
    );
  }

  return (
    <TopBarContainer>
      {isAuthenticated ? (
        <ScreenSize display="flex" mobile tablet>
          <LinkButton iconOnly icons={{ left: burgerMenu }} color="Grey" onClick={toggleAction} />
        </ScreenSize>
      ) : (
        <div />
      )}
      <Logo />
      {isAuthenticated ? companySwitch : <div />}
    </TopBarContainer>
  );
};
