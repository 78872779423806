import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { getRoutesInMaintenance, MaintenanceInfo } from 'defaultConfiguration/getRoutesInMaintenance';

export type MaintenanceModeCheckFunc = ReturnType<typeof useMaintenanceMode>;

export const useMaintenanceMode = () => {
  const { remoteConfig } = useRemoteConfigContext();

  const routesInMaintenance = getRoutesInMaintenance(remoteConfig);

  return (
    systems: MaintenanceInfo['system'][]
  ): [boolean, MaintenanceInfo[], MaintenanceInfo | undefined] => {
    const route = routesInMaintenance.find((route) => {
      return systems.includes(route.system);
    });

    return [!!route, routesInMaintenance, route];
  };
};
