import styled from 'styled-components';

import typography from 'components/typography/typography';

export type StyleProps = {
  lightMode: 'light' | 'dark';
};

export const ControlValue = styled.div`
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 400;
  justify-content: center;
  min-width: 2.125rem;
  align-items: center;
`;

export const ControlButton = styled.button.attrs({ type: 'button' })<ControlButtonProps>`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 100%;

  @media ${({ theme }) => theme.media.maxTablet} {
    height: 2.75rem;
    width: 2.75rem;
  }

  &::after {
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: ${({ icon }) => (icon ? `url("${icon}")` : 'none')};
    content: ${({ icon }) => (icon ? '""' : 'none')};
    background-color: ${({ theme }) => theme.colors.base.white.functional};
    height: 1.5rem;
    width: 1.5rem;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 7%);
  }

  &:active {
    background-color: rgba(255, 255, 255, 12%);
  }

  &:disabled {
    cursor: not-allowed;

    &::after {
      background-color: rgba(255, 255, 255, 50%);
    }
  }
`;

export const PreviewControls = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: ${({ lightMode }) => (lightMode ? '2.5rem' : '3.5rem')};
  background-color: ${({ lightMode, theme }) =>
    lightMode === 'light' ? theme.colors.base.white.functional : 'rgba(0, 0, 0, 30%)'};
  box-shadow: ${({ lightMode }) =>
    lightMode === 'light' ? '0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.25)' : 'none'};
  border-radius: ${({ lightMode }) => (lightMode === 'light' ? '0.5rem' : 0)};
  width: 100%;
  padding: ${({ lightMode }) => (lightMode ? '0 1.25rem' : '0.375rem 1.25rem')};

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: ${({ lightMode }) => (lightMode ? '0 1.25rem' : '0.625rem 1rem')};
  }

  &.preview--header {
    ${ControlButton} {
      &::after {
        background-color: ${({ theme, lightMode }) =>
          lightMode === 'light' ? theme.colors.base.black.tints[50] : theme.colors.base.white.functional};
      }
    }
  }
`;

export const ControlsGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${ControlButton}:first-child {
    margin-inline-end: 0.625rem;
  }

  ${ControlButton}:last-child {
    margin-inline-start: 0.625rem;
  }
`;

export const ControlInput = styled.input.attrs({ type: 'number' })`
  background: none;
  border: none;
  margin-inline: 0.125rem 0.375rem;
  justify-content: center;
  width: 2rem;
  height: 1.875rem;
  color: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 0.5rem;
  padding: 0.625rem 0.375rem;
  text-align: end;

  &:hover {
    background-color: rgba(255, 255, 255, 5%);
  }

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.base.primary.tints[80]};
    outline: none;
  }
`;

export const DocumentControls = styled(PreviewControls)`
  min-height: 2.5rem;
  max-height: 2.5rem;
  position: absolute;
  bottom: 1.5rem;
  width: auto;
  background-color: ${({ theme }) => theme.colors.base.black.tints[80]};
  z-index: ${({ theme }) => theme.layers.modal};
  border-radius: 5rem;
  padding: 0.25rem;

  ${ControlValue} {
    color: ${({ theme }) => theme.colors.base.white.functional};
  }

  ${ControlsGroup} {
    &:not(:last-child)::after {
      content: '';
      display: block;
      width: 1px;
      height: 2.5rem;
      background-color: ${({ theme, lightMode }) =>
        lightMode === 'light' ? theme.colors.base.white.functional : theme.colors.base.black.tints[50]};
      margin-inline: 1.25rem;
    }
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    min-height: 3.25rem;
    max-height: 3.25rem;

    ${ControlsGroup} {
      &:not(:last-child)::after {
        height: 3.25rem;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: visible;
`;

type ControlButtonProps = {
  icon?: string;
};

type DocumentWrapperProps = {
  scale?: number;
};

export const DocumentWrapper = styled.div<DocumentWrapperProps & StyleProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  flex-grow: 1;
  overflow: auto;
  width: 100%;

  img {
    max-width: calc(100% - 17.5rem * 2);
    display: block;
    max-height: 100%;
    transition: transform 0.3s;
    transform-origin: top left;
    position: absolute;
    top: 1rem;
    transform: scale(${({ scale }) => scale || 1});

    @media ${({ theme }) => theme.media.maxTablet} {
      max-width: calc(100% - 1.25rem * 2);
    }
  }
`;

export const FileTypeImage = styled.img`
  margin-inline: 0.75rem;
`;

type Props = {
  fullScreen?: boolean;
  fixed?: boolean;
};

export const Filename = styled(typography.Header).attrs({ type: 'h5', weight: 'medium' })``;

export const PreviewOverlay = styled.div<Props & StyleProps>`
  display: flex;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'sticky')};
  top: 0;
  left: 0;
  width: 100%;
  padding: ${({ lightMode }) => (lightMode === 'light' ? '0.0625rem' : 0)};
  border-radius: ${({ lightMode }) => (lightMode === 'light' ? '0.5rem' : 0)};
  height: ${({ fullScreen }) => (fullScreen ? '100vh' : '100%')};
  background-color: ${({ lightMode, theme }) =>
    lightMode === 'light' ? theme.colors.base.black.tints[3] : 'rgba(0, 0, 0, 70%)'};
  flex-direction: column;
  align-items: center;
  z-index: ${({ theme }) => theme.layers.modal};
  justify-content: space-between;

  ${Filename} {
    color: ${({ theme, lightMode }) =>
      lightMode === 'light' ? theme.colors.base.black.tints[90] : theme.colors.base.white.functional};
  }
`;

export const PreviewContainer = styled.div<Props>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.layers.modal};
`;

export const PreviewUnavailableText = styled(typography.Header).attrs({ type: 'h3', weight: 'medium' })`
  color: ${({ theme }) => theme.colors.base.white.functional};
`;

export const PreviewUnavailableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const PreviewUnavailableCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 32.5rem;
  width: 100%;
  padding: 2.75rem;
  gap: 1.5rem;
  background-color: rgba(0, 0, 0, 30%);
  border-radius: 1.5rem;
  text-align: center;
  margin-bottom: 4.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-bottom: 5rem;
  }
`;

export const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.25rem 1.25rem 0 #0000001a;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  margin: 1.5rem 2.75rem;
  padding: 1.5rem;
  height: 100%;
  width: calc(100% - 2.75rem * 2);
`;

export const EmptyPageText = styled.span`
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
`;
