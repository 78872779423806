import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Checkbox,
  Dropdown,
  ErrorMessage,
  Field,
  Fieldset,
  Input,
  Legend,
  LinkButton,
  Loader,
  Modal,
  PhoneField,
  RadioButton,
  RadioButtonGroup,
  TermsModal,
  ToastLegacy
} from 'components';
import { PAYMENT_DAYS_LIST } from 'features/application/forms/thirdParties/constants';
import { selectActiveCustomer, selectCustomerInformation, selectUserData } from 'features/auth/selectors';
import { useRequestInsuranceReportMutation } from 'features/insights/api';
import { useGetRelationsQuery } from 'features/users/slice';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { useAuthSelector } from 'hooks/redux/hooks';
import {
  amountRangeRequiredMessage,
  companyCodeRequiredMessage,
  emailRequired,
  personNameRequired,
  phoneNumberRequired
} from 'validators';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { CountryOfRegistrationField } from '../countryOfRegistrationField/countryOfRegistrationField';

import {
  AgreementFieldContainer,
  AgreementText,
  DeferredPaymentHeaderText,
  HeaderText,
  InsuranceLimitForm
} from './insuranceLimitModal.styles';

const fields = {
  dataHandling: false,
  reportNeededFor: 'Own',
  paymentTerm: '',
  insuranceLimit: '',
  companyName: '',
  companyCode: '',
  vatCode: '',
  country: 'LT',
  userName: '',
  userSurname: '',
  phoneNumber: '',
  email: ''
};

type Fields = typeof fields;

type InsuranceLimitValidationSchema = Yup.ObjectSchema<Record<keyof Fields, Yup.AnySchema>>;

const supportedCountries = ['LT', 'LV', 'EE', 'NL', 'FI'];

export const validationSchema: InsuranceLimitValidationSchema = Yup.object().shape(
  {
    dataHandling: Yup.boolean().isTrue('dataHandlingMandatory'),
    reportNeededFor: Yup.string().oneOf(['Own', 'Buyer'], VALIDATION_MESSAGE_ID.REQUIRED),
    paymentTerm: Yup.number()
      .typeError(VALIDATION_MESSAGE_ID.REQUIRED)
      .required(VALIDATION_MESSAGE_ID.REQUIRED),
    insuranceLimit: amountRangeRequiredMessage({
      min: 10000,
      max: 5000000,
      message: 'insuranceLimitRequired'
    }),
    companyName: personNameRequired,
    companyCode: Yup.string().when('vatCode', {
      is: '',
      then: companyCodeRequiredMessage('oneOfFieldsIsRequired'),
      otherwise: Yup.string().optional()
    }),
    vatCode: Yup.string().when('companyCode', {
      is: '',
      then: companyCodeRequiredMessage('oneOfFieldsIsRequired'),
      otherwise: Yup.string().optional()
    }),
    country: Yup.string().oneOf(supportedCountries, VALIDATION_MESSAGE_ID.REQUIRED),
    userName: personNameRequired,
    userSurname: personNameRequired,
    phoneNumber: phoneNumberRequired,
    email: emailRequired
  },
  [['companyCode', 'vatCode']]
);

type Props = {
  isOpen: boolean;
  onVisibilityChange: (visible: boolean) => unknown;
  showSuccessPage: (visible: boolean) => unknown;
};

export const InsuranceLimitModal: FC<Props> = ({ isOpen, onVisibilityChange, showSuccessPage }) => {
  const { t } = useTranslation();

  const [triggerInsuranceReportRequest] = useRequestInsuranceReportMutation();

  const [dataHandlingModalOpen, setIsDataHandlingModalOpen] = useState(false);
  const [emailSendError, setEmailSendError] = useState(false);
  const [emailIsSending, setEmailIsSending] = useState(false);
  const [selectedReportFor, setSelectedReportFor] = useState(fields.reportNeededFor);

  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const customerInformation = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const userData = useAuthSelector<typeof selectUserData>(selectUserData);

  const { customerId, customerName, customerCountryId } = activeCustomer || {};
  const { userFirstName, userLastName } = customerInformation || {};
  const { email } = userData || {};

  const { data } = useGetRelationsQuery({ email: email || '' }, { skip: !email });
  const { userData: relationUserData } = data?.[0] || {};
  const { mobilePhoneNumber } = relationUserData || {};

  const formValues = useMemo(
    () => ({
      ...fields,
      userName: userFirstName || '',
      userSurname: userLastName || '',
      companyCode: customerId || '',
      companyName: customerName || '',
      country: customerCountryId || fields.country,
      phoneNumber: mobilePhoneNumber || '',
      email: email || ''
    }),
    [
      userFirstName,
      userLastName,
      customerId,
      customerName,
      customerCountryId,
      email,
      mobilePhoneNumber,
      isOpen
    ]
  );

  const {
    validationHelpers,
    setValueWithoutValidation,
    getValues,
    reset,
    handleSubmit,
    setTouchedOnAll,
    getFieldState
  } = useFormHandlers(formValues, validationSchema);

  const handleClose = () => {
    reset();
    onVisibilityChange(false);
    setEmailSendError(false);
    setEmailIsSending(false);
    setSelectedReportFor(fields.reportNeededFor);
  };

  const handleSend = handleSubmit(
    async (data) => {
      try {
        if (!emailIsSending) {
          setEmailIsSending(true);
          setEmailSendError(false);
          await triggerInsuranceReportRequest({ ...data, paymentTerm: Number(data.paymentTerm) }).unwrap();
        }
      } catch {
        setEmailSendError(true);
        setEmailIsSending(false);
      } finally {
        handleClose();
        showSuccessPage(true);
      }
    },
    () => {
      setTouchedOnAll();
    }
  );

  const handleReadMoreClick = () => {
    setIsDataHandlingModalOpen(true);
  };

  const onRadioButtonChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setValueWithoutValidation('reportNeededFor', e.currentTarget.value);
    setSelectedReportFor(e.currentTarget.value);
  };

  const reportForfieldState = getFieldState('reportNeededFor');
  const reportForInvalid = !!(reportForfieldState.error?.message && reportForfieldState.isTouched);

  return (
    <>
      <TermsModal
        isOpen={dataHandlingModalOpen}
        termsTextId="dataProcessingConsent"
        onVisibilityChange={setIsDataHandlingModalOpen}
      />
      <Modal
        label={t('preliminaryInsuranceLimit')}
        isOpen={isOpen}
        onClose={handleClose}
        mainActionLabel={t('orderService')}
        mainActionhandler={handleSend}
      >
        {emailIsSending ? <Loader /> : null}
        <ToastLegacy isVisible={emailSendError} message={t('generalErrorMessage')} />
        <InsuranceLimitForm>
          <HeaderText>{t('insuranceLimitNeededFor')}</HeaderText>
          <Fieldset>
            <RadioButtonGroup variant="row" invalid={reportForInvalid}>
              <RadioButton
                value="Own"
                label={t('ownsCompany')}
                onChange={onRadioButtonChange}
                checked={selectedReportFor === 'Own'}
              />
              <RadioButton
                value="Buyer"
                label={t('buyersCompany')}
                onChange={onRadioButtonChange}
                checked={selectedReportFor === 'Buyer'}
              />
            </RadioButtonGroup>
            <ErrorMessage hidden={!reportForInvalid}>
              {t(reportForfieldState.error?.message || '')}
            </ErrorMessage>
          </Fieldset>
          <HeaderText>{t('company')}</HeaderText>
          <Fieldset>
            <Legend>{t('provideCompanyInformation')}</Legend>
            <Field
              name="companyName"
              Component={Input}
              validationHelpers={validationHelpers}
              value={getValues('companyName')}
              placeholder={t('placeholderCompanyName')}
              required
            />
            <Field
              name="companyCode"
              type="number"
              maxDecimals={0}
              Component={Input}
              validationHelpers={validationHelpers}
              value={getValues('companyCode')}
              placeholder={t('placeholderCompanyCode')}
              required
            />
            <Field
              name="vatCode"
              Component={Input}
              validationHelpers={validationHelpers}
              value={getValues('vatCode')}
              placeholder={t('vatCode')}
              required
            />
            <CountryOfRegistrationField
              name="country"
              getValues={getValues}
              validationHelpers={validationHelpers}
              supportedCountries={supportedCountries}
            />
          </Fieldset>
          <HeaderText>{t('contactPerson')}</HeaderText>
          <Fieldset>
            <Legend>{t('provideInformationOfContactPerson')}</Legend>
            <Field
              name="userName"
              Component={Input}
              validationHelpers={validationHelpers}
              value={getValues('userName')}
              placeholder={t('name')}
              required
            />
            <Field
              name="userSurname"
              Component={Input}
              validationHelpers={validationHelpers}
              value={getValues('userSurname')}
              placeholder={t('lastName')}
              required
            />
            <Field
              name="phoneNumber"
              Component={PhoneField}
              validationHelpers={validationHelpers}
              value={getValues('phoneNumber')}
              placeholder={t('phone')}
              required
            />
            <Field
              name="email"
              Component={Input}
              validationHelpers={validationHelpers}
              value={getValues('email')}
              placeholder={t('email')}
              required
            />
          </Fieldset>
          <HeaderText>{t('preliminaryInsuranceLimitDetails')}</HeaderText>
          <Fieldset>
            <Legend>{t('providePreliminaryInvoiceFinancingInformation')}</Legend>
            <Field
              name="insuranceLimit"
              type="number"
              Component={Input}
              validationHelpers={validationHelpers}
              value={getValues('insuranceLimit')}
              placeholder={t('requiredInsuranceLimit')}
              required
            />
          </Fieldset>
          <DeferredPaymentHeaderText>{t('deferredPaymentsUpTo')}</DeferredPaymentHeaderText>
          <Fieldset>
            <Field
              name="paymentTerm"
              Component={Dropdown}
              options={PAYMENT_DAYS_LIST}
              validationHelpers={validationHelpers}
              value={getValues('paymentTerm')}
              placeholder={t('placeholderDeferralPeriod')}
              required
            />
          </Fieldset>
          <AgreementFieldContainer>
            <Field
              Component={Checkbox}
              name="dataHandling"
              value={getValues('dataHandling')}
              validationHelpers={validationHelpers}
              label={
                <AgreementText>
                  {t('agreementPartnershipTextForContact')}
                  <LinkButton onClick={handleReadMoreClick}>{t('readMore')}</LinkButton>
                </AgreementText>
              }
            />
          </AgreementFieldContainer>
        </InsuranceLimitForm>
      </Modal>
    </>
  );
};
