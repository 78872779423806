import { BillTypes } from 'services/LoansService/types';

type MapBillTypeToTranslation = Record<string, string>;

export const mapBillTypeToTranslation: MapBillTypeToTranslation = {
  'Loan Loan Admin Fee': 'loanAdministrationFee',
  'Loan Overdue Interest': 'overdueInterest',
  'Loan Establishment Fee': 'contract',
  'Loan Interest': 'interest',
  'Loan Principal': 'principal',
  Downpaymet: 'downpayment',
  'Contract fee': 'contract',
  Installment: 'installment',
  'Installment Principal': 'principal',
  'Installment Interest': 'interest',
  'Penalty Interest': 'penaltyInterest',
  'Full Asset Value': 'assetValue',
  'Service Upfront': 'contract'
};

export const getBillTypeTranslation = (type?: string) => {
  if (!type) {
    return 'other';
  }

  const translation = mapBillTypeToTranslation[type as BillTypes];

  if (translation) {
    return translation;
  }

  return 'other';
};
