import { useEffect } from 'react';

const useCloseMutationObserver = (target: HTMLElement | null, onClose: () => unknown) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.target === target && mutation.attributeName === 'class') {
        const classList = target.classList;
        if (classList.contains('close')) {
          onClose();
        }
      }
    });
  });

  const initializer = () => {
    if (target) {
      observer.observe(target, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  };

  useEffect(initializer, [target]);
};

export default useCloseMutationObserver;
