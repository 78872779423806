import styled from 'styled-components';

import { Typography } from 'components';

export const InformationalDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1.5rem;
  align-items: center;
  flex: 1;
  height: 100%;

  button {
    width: 100%;
    margin-top: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;
  height: 100%;
  margin-top: 7.5rem;
  padding-bottom: 1.5rem;

  svg:hover {
    #Path_3,
    #Path_4,
    #Path_5 {
      animation: wiggle 3s ease-in-out infinite;
    }
  }

  @keyframes wiggle {
    100% {
      transform: translateX(0) translateY(-20px);
      opacity: 0;
    }

    75% {
      transform: translateX(-2px);
      opacity: 0.9;
    }

    25% {
      transform: translateX(2px);
      opacity: 0.2;
    }

    0% {
      transform: translateX(0) translateY(30px);
      opacity: 0;
    }
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    margin-top: 0;
  }
`;

export const ContentHeader = styled(Typography.Header).attrs({ type: 'h3', weight: 'medium' })`
  text-align: center;
`;

export const ContentBody = styled(Typography.Body).attrs({ type: 'L' })`
  text-align: center;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;
