import {
  ApplicationInformation,
  DrawerTopContent,
  MainInfoSection,
  MainInfoSectionRow,
  SquareShimmer,
  HeaderIconAndText,
  Shimmer,
  ActionsContainer,
  ApplicationContentDrawerContainer,
  DrawerScrolableContent,
  TitleAndActionsInnerRow
} from './applicationDetailsDrawer.styles';

const ApplicationContentDrawerSkeleton = () => {
  return (
    <ApplicationContentDrawerContainer>
      <DrawerTopContent>
        <TitleAndActionsInnerRow>
          <HeaderIconAndText>
            <SquareShimmer width="2rem" height="2rem" />
            <Shimmer height="1.25rem" width="7.5rem" />
          </HeaderIconAndText>
          <ActionsContainer>
            <Shimmer height="1.5rem" width="1.5rem" />
            <Shimmer height="1.5rem" width="1.5rem" />
          </ActionsContainer>
        </TitleAndActionsInnerRow>
        <MainInfoSection>
          <MainInfoSectionRow>
            <Shimmer height="1.5rem" width="12.5rem" />
          </MainInfoSectionRow>
          <MainInfoSectionRow>
            <Shimmer height="1.5rem" width="12.5rem" />
          </MainInfoSectionRow>
          <MainInfoSectionRow>
            <Shimmer height="1.5rem" width="17.5rem" />
          </MainInfoSectionRow>
        </MainInfoSection>
      </DrawerTopContent>
      <DrawerScrolableContent>
        <ApplicationInformation>
          <Shimmer height="7.14rem" width="100%" />
        </ApplicationInformation>
        <ApplicationInformation>
          <Shimmer height="7.14rem" width="100%" />
        </ApplicationInformation>
        <ApplicationInformation>
          <Shimmer height="7.14rem" width="100%" />
        </ApplicationInformation>
        <ApplicationInformation>
          <Shimmer height="9rem" width="100%" />
        </ApplicationInformation>
      </DrawerScrolableContent>
    </ApplicationContentDrawerContainer>
  );
};

export default ApplicationContentDrawerSkeleton;
