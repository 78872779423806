import { getValue, RemoteConfig } from 'firebase/remote-config';

export type CoreSystem = 'goCredits' | 'goFinance' | 'navision';
export type CoreSystemProduct = 'rbf' | 'factoring' | 'loans' | 'leasing' | 'creditline';

export type MaintenanceInfo = {
  url?: string;
  system?: 'app' | CoreSystem | CoreSystemProduct;
  date?: string;
};

export const getRoutesInMaintenance = (remoteConfig: RemoteConfig | null) => {
  const isInTestEnv = process.env.NODE_ENV === 'test';

  if (isInTestEnv) {
    /**
     * for running tests in node enviroments resolve default configuration
     */
    return [];
  }

  try {
    if (!remoteConfig) {
      return [];
    }

    const routesArrrayString = getValue(remoteConfig, 'pages_in_maintenance').asString();

    return (JSON.parse(routesArrrayString) || []) as MaintenanceInfo[];
  } catch {
    return [] as MaintenanceInfo[];
  }
};
