import styled from 'styled-components';

import { SecondaryText } from '../text';

export const ErrorMessage = styled(SecondaryText)`
  font-size: 0.75rem;
`;

type ContainerProps = {
  iconOnly?: boolean;
};

export const ErrorPopupContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${({ iconOnly }) => (iconOnly ? 'center' : 'flex-end')};

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const IconButtonContainer = styled.div<ContainerProps>`
  padding: 0 0.225rem;
  color: ${({ theme }) => theme.colors.primary.errorText};
  display: inline-flex;
  margin-left: ${({ iconOnly }) => (iconOnly ? 0 : '0.25rem')};

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.primary.errorText};
    }
  }

  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.base.red.shades[120]};
      }
    }
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    padding: 0;
  }
`;
