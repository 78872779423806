import styled, { css } from 'styled-components';

import checkmark from '../../assets/icons/blueCheckmark.svg';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

type StyledCheckboxProps = {
  checked: boolean;
  invalidStatus?: boolean;
  disabled?: boolean;
};

type CheckmarkProps = {
  isInvalid?: boolean;
  checked?: boolean;
  circle?: boolean;
  size?: 'M' | 'L';
};

const getCheckboxSize = ({ size }: Partial<CheckmarkProps>) => (size === 'L' ? '1.5rem' : '1.1875rem');
const getIconSize = ({ size }: Partial<CheckmarkProps>) => (size === 'L' ? '0.75rem' : '0.625rem');

export const Checkmark = styled.span<CheckmarkProps>`
  position: relative;
  left: 0;
  height: ${getCheckboxSize};
  min-width: ${getCheckboxSize};
  transition: all 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ circle }) => (circle ? '50%' : '0.375rem')};
  background-color: ${({ theme }) => theme.colors.secondary.buttonBackground};

  & svg {
    width: ${getIconSize};
    height: ${getIconSize};
  }
`;

const containerCheckbox = css<CheckmarkProps>`
  position: relative;
  cursor: pointer;
  min-height: ${getCheckboxSize};
  min-width: ${getCheckboxSize};
  user-select: none;
`;

const uncheckedValid = css`
  border: 0.0625rem solid ${({ theme }) => theme.colors.primary.border.middle};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.border.dark};
  }
`;

const checkedValid = css`
  border: 0.0625rem solid ${({ theme }) => theme.colors.primary.buttonBackground};
  background-color: ${({ theme }) => theme.colors.primary.buttonBackground};

  &:hover {
    border: 0.0625rem solid ${({ theme }) => theme.colors.primary.hoverButtonBackground};
    background-color: ${({ theme }) => theme.colors.primary.hoverButtonBackground};
  }
`;

const uncheckedInvalid = css`
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.red.shades[100]};

  &:hover {
    border-color: ${({ theme }) => theme.colors.base.red.shades[120]};
  }
`;

const checkedInvalid = css`
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.red.shades[100]};
  background-color: ${({ theme }) => theme.colors.base.red.shades[100]};

  &:hover {
    border: 0.0625rem solid ${({ theme }) => theme.colors.base.red.shades[120]};
    background-color: ${({ theme }) => theme.colors.base.red.shades[120]};
  }
`;

const disabledStatus = css`
  cursor: not-allowed;
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.black.shades[20]};
  background-color: ${({ theme }) => theme.colors.primary.background};
`;

const disabledChecked = css`
  border: 0.0625rem solid ${({ theme }) => theme.colors.base.primary.shades[50]};
  background-color: ${({ theme }) => theme.colors.base.primary.shades[50]};
`;

export const StyledCheckbox = styled.label<StyledCheckboxProps>`
  display: flex;
  gap: 0.875rem;
  padding: 0.25rem;
  align-items: center;
  ${containerCheckbox};

  ${Checkmark} {
    ${(props) => !props.checked && !props.disabled && uncheckedValid};
    ${(props) => props.checked && !props.disabled && checkedValid};
    ${(props) => props.invalidStatus && !props.checked && !props.disabled && uncheckedInvalid};
    ${(props) => props.invalidStatus && props.checked && !props.disabled && checkedInvalid};
    ${(props) => props.disabled && disabledStatus};
    ${(props) => props.checked && props.disabled && disabledChecked};
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    align-items: normal;
  }
`;

export const BlueCheckmark = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  background-image: ${() => `url("${checkmark}")`};
  background-size: contain;
  background-position: center;
  visibility: hidden;

  &:checked {
    visibility: visible;
  }
`;

export const LabelContainer = styled.div`
  display: inline-flex;
`;
