import { DragEventHandler, MouseEventHandler, ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from '../../assets/icons';

import { AreaContainer, IconContainer, InnerHeader, HelpText } from './uploadArea.styles';

type AreaConfig = {
  fileSizeInMB?: number;
  allowedExtensions?: string;
  maxFilesCount?: number;
};

export type InfoConfig = {
  header?: string;
  helpText?: (string | ((config: AreaConfig) => string))[];
};

type Props = {
  id?: string;
  info?: InfoConfig;
  hiddenInput?: ReactNode;
  fileSizeInMB?: number;
  maxFilesCount?: number;
  allowedExtensions?: string;
  hideArea?: boolean;
  dataTestId?: string;
  handlers?: {
    onClick?: MouseEventHandler<HTMLDivElement>;
    onDragOver?: DragEventHandler<HTMLDivElement>;
    onDragLeave?: DragEventHandler<HTMLDivElement>;
    onDrop?: DragEventHandler<HTMLDivElement>;
  };
};

export const Area = forwardRef<HTMLDivElement, Props>(
  (
    { id, info, hiddenInput, hideArea, fileSizeInMB, allowedExtensions, maxFilesCount, handlers, dataTestId },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <AreaContainer
        id={id}
        data-testid={dataTestId}
        ref={ref}
        visible={!hideArea}
        className="drag-area"
        onDragEnter={handlers?.onDragOver}
        {...handlers}
      >
        {hiddenInput}
        <IconContainer>
          <Icons.UploadOutline />
        </IconContainer>
        {info ? (
          <>
            {info.header ? (
              <InnerHeader>{info.header}</InnerHeader>
            ) : (
              <InnerHeader>{t('uploadDocuments')}</InnerHeader>
            )}
            {info.helpText?.map((text, index) => (
              <HelpText key={index}>
                {typeof text === 'string' ? text : text({ fileSizeInMB, allowedExtensions, maxFilesCount })}
              </HelpText>
            ))}
          </>
        ) : (
          <>
            <InnerHeader>{t('uploadDocuments')}</InnerHeader>
            <HelpText>
              {t('acceptedFormatsUnset', {
                formats: allowedExtensions?.split('.').join(' ')
              })}
            </HelpText>
            <HelpText>{t('maximumFileSize', { mb: fileSizeInMB })}</HelpText>
          </>
        )}
      </AreaContainer>
    );
  }
);
