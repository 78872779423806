import { FC, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Table } from 'components';
import { selectLoansContractList } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';
import { LoansService } from 'services/LoansService';
import { LoanInvoiceItem } from 'services/LoansService/types';

import { createMobileListRows, createTableColumns, TableRowItem } from './createTableColumns';
import { BillsTableContainer } from './loansBills.style';

const PAGE_SIZE = 10;

export type Sort = { dataColumnId?: string | number; sortOrder: SortOrder };

type Props = {
  items: LoanInvoiceItem[];
  activePage?: number;
  loading?: boolean;
  onChangePage?: (pageNumber: number) => unknown;
};

export const LoansBillsTable: FC<Props> = ({ items, loading, activePage, onChangePage }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId');

  const contracts = useLoansSelector<typeof selectLoansContractList>(selectLoansContractList);
  const selectedContract = contracts?.find((contract) => contract.contractNo === contractId);
  const { financierId } = selectedContract || {};

  const [sortOrder, setSortOrder] = useState<{ dataColumnId?: string | number; sortOrder: SortOrder }>();

  const handleInvoiceDownload = (invoiceNumber?: string) => async () => {
    if (invoiceNumber && financierId) {
      return await LoansService.downloadInvoice({ financierId, invoiceNumber });
    }
  };

  const handleSort = (column: TableColumn<TableRowItem>, sortDirection: SortOrder) => {
    setSortOrder({ dataColumnId: column.id, sortOrder: sortDirection });
  };

  const handlePageChange = (pageNumber: number) => {
    onChangePage?.(pageNumber);
  };

  const columns = createTableColumns(t, handleInvoiceDownload);
  const mobileRows = createMobileListRows(t, handleInvoiceDownload);

  return (
    <BillsTableContainer>
      <Table<TableRowItem>
        pagination
        paginationPerPage={PAGE_SIZE}
        highlightOnHover
        sortedBy={sortOrder}
        activePage={activePage}
        noDataMessage={t('noListItems')}
        onSort={handleSort}
        progressPending={loading}
        columns={columns}
        mobileColumns={mobileRows}
        data={items}
        onChangePage={handlePageChange}
      />
    </BillsTableContainer>
  );
};
