import styled from 'styled-components';

import { LinkButton, RegularText, Typography } from 'components';

export const HeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const HeaderText = styled(RegularText)`
  display: inline-flex;
  font-size: 1.25rem;
  line-height: 2rem;
  margin-right: 0.625rem;
`;

export const ViewMoreButton = styled(LinkButton)`
  margin-top: 1.5rem;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const NoDataText = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;
