import { ReactNode } from 'react';

import Icons from '../../assets/icons';

import {
  ToastList,
  ToastListContainer,
  ToastMessage,
  ToastStyle,
  ToastProps,
  IconContainer
} from './toast.styles';

type Props = {
  message: ReactNode;
  isVisible: boolean;
  list?: string[];
  testId?: string;
  className?: string;
} & Partial<ToastProps>;

const Toast = ({ testId, variant, message, isVisible, list, className }: Props) => {
  const variantType = variant ?? 'error';

  return (
    <ToastStyle
      className={className}
      data-testid={`${variantType}-toast-message${testId ?? ''}`}
      isVisible={isVisible}
      variant={variantType}
    >
      <ToastMessage>
        <IconContainer>
          <Icons.ExclamationCircle />
        </IconContainer>
        <p>{message}</p>
      </ToastMessage>
      {list?.length ? (
        <ToastListContainer>
          <ToastList>
            {list.map((listMessage, index) => (
              <li key={index}>{listMessage}</li>
            ))}
          </ToastList>
        </ToastListContainer>
      ) : null}
    </ToastStyle>
  );
};

export default Toast;
