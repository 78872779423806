import { SubmittedInvoice } from 'services/InvoicesService/types';

export type DefaultValue = { amount: number; currency?: string };

export type FactoringSummary = {
  totalAmount: DefaultValue;
  totalFinanced: DefaultValue;
  reminder: DefaultValue;
  totalUnpaid: DefaultValue;
};

export const calculateSummary = (invoices: SubmittedInvoice[]): FactoringSummary => {
  const defaultValue: DefaultValue = { amount: 0, currency: 'EUR' };

  const summary: FactoringSummary = {
    totalAmount: { ...defaultValue },
    totalFinanced: { ...defaultValue },
    reminder: { ...defaultValue },
    totalUnpaid: { ...defaultValue }
  };

  if (invoices?.length === 0) return summary;

  invoices.forEach((invoice: SubmittedInvoice) => {
    const { invoiceAmount, advanceAmountFunded, invoiceAmountUnfunded, invoiceAmountAssignedOutstanding } =
      invoice || {};

    if (!summary.totalAmount.currency && invoiceAmount?.currency) {
      summary.totalAmount.currency = invoiceAmount.currency;
    }

    if (!summary.totalFinanced.currency && advanceAmountFunded?.currency) {
      summary.totalFinanced.currency = advanceAmountFunded.currency;
    }

    if (!summary.reminder.currency && invoiceAmountUnfunded?.currency) {
      summary.reminder.currency = invoiceAmountUnfunded.currency;
    }

    if (!summary.totalUnpaid.currency && invoiceAmountAssignedOutstanding?.currency) {
      summary.totalUnpaid.currency = invoiceAmountAssignedOutstanding.currency;
    }

    summary.totalAmount.amount += invoiceAmount?.amount || 0;
    summary.totalFinanced.amount += advanceAmountFunded?.amount || 0;
    summary.reminder.amount += invoiceAmountUnfunded?.amount || 0;
    summary.totalUnpaid.amount += invoiceAmountAssignedOutstanding?.amount || 0;
  });

  return { ...summary };
};
