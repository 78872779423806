import styled from 'styled-components';

export const InformationBox = styled.section`
  background: ${({ theme }) => theme.colors.primary.background};
  position: relative;
  padding: 1.25rem;
  margin-bottom: 0.625rem;
  border-radius: 0.8rem;

  &:last-of-type {
    margin-bottom: 1.25rem;
  }
`;

export const InformationBoxTitle = styled.h4`
  margin: 0 0 0.75rem;
  color: ${({ theme }) => theme.colors.primary.textColor};
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;
