import factoring from 'assets/icons/factoring_ic.svg';
import flexiFinance from 'assets/icons/flexiFinance.svg';
import insights from 'assets/icons/insights.svg';
import leasing from 'assets/icons/leasing_ic.svg';
import loans from 'assets/icons/loans_ic.svg';
import main from 'assets/icons/main.svg';
import myApplications from 'assets/icons/myApplications.svg';
import rbf from 'assets/icons/revenueBasedFinancing.svg';
import rocket from 'assets/icons/rocket.svg';
import { SupportedCountries } from 'features/auth/types/authTypes';

import { ResolvedFeaturesConfig } from '../../../../../configuration/features';

type UserScope = 'regular' | 'ghost' | 'partial';
type CompanyRole = 'PROSPECT' | 'VERIFIED';

export type LinksSection = {
  segment: string;
  icon?: string;
  disabled?: boolean;
  title?: string;
  links?: LinksSection[];
  featureName?: keyof ResolvedFeaturesConfig;
  scope?: UserScope[];
  role?: CompanyRole[];
  country?: SupportedCountries[];
};

const links: LinksSection[] = [
  {
    segment: 'dashboard',
    scope: ['regular', 'ghost'],
    links: [
      {
        title: 'main',
        icon: main,
        segment: 'overview',
        role: ['VERIFIED']
      },
      {
        title: 'myApplications',
        icon: myApplications,
        segment: 'applications',
        role: ['VERIFIED']
      },
      {
        title: 'setUpMyProfile',
        icon: rocket,
        segment: 'verification',
        featureName: 'verificationFlow',
        role: ['PROSPECT']
      }
    ]
  },
  {
    segment: 'finance',
    title: 'myProducts',
    scope: ['regular'],
    role: ['VERIFIED'],
    links: [
      {
        segment: 'factoring',
        icon: factoring,
        featureName: 'factoring',
        links: [
          {
            title: 'overview',
            segment: ''
          },
          {
            segment: 'invoices'
          },
          {
            segment: 'statements',
            featureName: 'factoringStatements'
          },

          {
            segment: 'bills',
            featureName: 'factoringBills'
          },
          {
            segment: 'contracts',
            featureName: 'contracts'
          }
        ]
      },
      {
        segment: 'loans',
        icon: loans,
        featureName: 'loans',
        links: [
          {
            title: 'overview',
            segment: ''
          },
          {
            segment: 'bills',
            featureName: 'bills'
          },
          {
            segment: 'contracts',
            featureName: 'contracts'
          }
        ]
      },
      {
        segment: 'creditline',
        icon: flexiFinance,
        featureName: 'creditLine',
        links: [
          {
            title: 'overview',
            segment: ''
          },
          {
            segment: 'bills',
            featureName: 'bills'
          },
          {
            segment: 'contracts',
            featureName: 'contracts'
          }
        ]
      },
      {
        segment: 'rbf',
        icon: rbf,
        featureName: 'rbf',
        links: [
          {
            title: 'overview',
            segment: ''
          },
          {
            segment: 'bills',
            featureName: 'bills'
          },
          {
            segment: 'contracts',
            featureName: 'contracts'
          }
        ]
      },
      {
        segment: 'leasing',
        icon: leasing,
        featureName: 'leasing',
        country: ['LT'],
        links: [
          {
            title: 'overview',
            segment: ''
          },
          {
            segment: 'bills',
            featureName: 'bills'
          },
          {
            segment: 'contracts',
            featureName: 'contracts'
          }
        ]
      }
    ]
  },
  {
    segment: 'dashboard',
    scope: ['regular'],
    role: ['VERIFIED'],
    links: [
      {
        icon: insights,
        segment: 'insights',
        featureName: 'insights'
      }
    ]
  }
];

export const getSublinksByProduct = (product: string): LinksSection | undefined => {
  const productLinks = links.find((link) => link.segment === 'finance')?.links;

  return productLinks?.find((link) => link.segment === product);
};

export default links;
