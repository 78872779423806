import { FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker, Dropdown, Input } from 'components';
import { FilterPanelRow, InputContainer } from 'components/filterPanel/filterPanel.styles';
import { OptionsDrawer } from 'features/app/components/drawer';
import { selectContractDetails } from 'features/contracts/selectors';
import { useContractSelector } from 'hooks/redux/hooks';
import { SubmittedInvoicesByContractsRequest } from 'services/InvoicesService/types';
import { camelCase } from 'utils';

type InputOnChangeHandler = ((value: string) => string | void) & FormEventHandler<HTMLInputElement>;

export type FilterKeys = keyof Omit<
  SubmittedInvoicesByContractsRequest,
  'contractId' | 'invoiceNumber' | 'page' | 'size' | 'sort'
>;

export type FilterParams = {
  [key in FilterKeys]: string;
};

type FilterFieldsProps = {
  onChange: (name: keyof FilterParams, shouldDebounce?: boolean) => (value: string | Date | null) => unknown;
  filterParams: FilterParams;
};

export const defaultParams: FilterParams = {
  thirdPartyId: '',
  invoiceNumberFragment: '',
  invoiceDateFrom: '',
  invoiceDateTo: '',
  invoiceDueDateFrom: '',
  invoiceDueDateTo: '',
  invoiceStatus: ''
};

const invoicesStatuses = [
  'SUBMITTED',
  'FINANCED',
  'OVERDUE',
  'PARTIALLY_PAID',
  'PAID',
  'REJECTED',
  'CLOSED',
  'TRANSFERRED'
];

export const FilterFields: FC<FilterFieldsProps> = ({ onChange, filterParams }) => {
  const { t } = useTranslation();

  const contractDetails = useContractSelector<typeof selectContractDetails>(selectContractDetails);

  const [thirdPArtiesVisible, setThirdPartiesVisible] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);

  const { thirdParties } = contractDetails || {};

  const thirdPartiesOptions = [
    ...(thirdParties?.map((thirdParty) => ({
      value: thirdParty.party?.id || '',
      label: thirdParty.party?.name || ''
    })) || [])
  ];

  const statusesOptions = [
    ...invoicesStatuses.map((status) => ({ value: status, label: t(camelCase(status)) }))
  ];

  const onBillTypeChange = (value: string) => {
    onChange('thirdPartyId')(value);

    setTimeout(() => setThirdPartiesVisible(false), 150);
  };

  const onBillStatusChange = (value: string) => {
    onChange('invoiceStatus')(value);

    setTimeout(() => setStatusVisible(false), 150);
  };

  return (
    <>
      <OptionsDrawer
        isOpen={thirdPArtiesVisible}
        onClose={() => setThirdPartiesVisible(false)}
        header={t('buyer')}
        onChange={onBillTypeChange}
        selectedValue={filterParams.thirdPartyId}
        options={thirdPartiesOptions}
      />
      <OptionsDrawer
        isOpen={statusVisible}
        onClose={() => setStatusVisible(false)}
        header={t('status')}
        onChange={onBillStatusChange}
        selectedValue={filterParams.invoiceStatus}
        options={statusesOptions}
      />
      <FilterPanelRow>
        <InputContainer>
          <Input
            required
            placeholder={t('invoiceNumber')}
            onChange={onChange('invoiceNumberFragment', true) as unknown as InputOnChangeHandler}
            value={filterParams.invoiceNumberFragment}
          />
        </InputContainer>
        <InputContainer>
          <Dropdown
            required
            separateFromMenu
            onClick={() => setThirdPartiesVisible(true)}
            placeholder={t('buyer')}
            onChange={onChange('thirdPartyId')}
            value={filterParams.thirdPartyId}
            options={thirdPartiesOptions}
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('issueDateFrom')}
            onChange={onChange('invoiceDateFrom')}
            value={filterParams.invoiceDateFrom}
            required
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('issueDateTo')}
            onChange={onChange('invoiceDateTo')}
            value={filterParams.invoiceDateTo}
            required
          />
        </InputContainer>
      </FilterPanelRow>
      <FilterPanelRow>
        <InputContainer>
          <DatePicker
            placeholder={t('dueDateFrom')}
            onChange={onChange('invoiceDueDateFrom')}
            value={filterParams.invoiceDueDateFrom}
            required
          />
        </InputContainer>
        <InputContainer>
          <DatePicker
            placeholder={t('dueDateTo')}
            onChange={onChange('invoiceDueDateTo')}
            value={filterParams.invoiceDueDateTo}
            required
          />
        </InputContainer>
        <InputContainer>
          <Dropdown
            placeholder={t('status')}
            separateFromMenu
            onClick={() => setStatusVisible(true)}
            onChange={onChange('invoiceStatus')}
            value={filterParams.invoiceStatus}
            options={statusesOptions}
            required
          />
        </InputContainer>
      </FilterPanelRow>
    </>
  );
};
