const useCheckForHigherActiveDrawer = (drawerActiveIndex: number) => {
  return () => {
    let hasHigherActiveDrawer = false;

    const asideMenus = document.querySelectorAll('aside');

    asideMenus.forEach((menu) => {
      if (
        Number(menu.getAttribute('data-active-index')) > drawerActiveIndex &&
        menu.classList.contains('open') === true
      ) {
        hasHigherActiveDrawer = true;
      }
    });

    return hasHigherActiveDrawer;
  };
};

export default useCheckForHigherActiveDrawer;
