import { AppState, GLOBAL_MESSAGE_ID } from './types';

export const selectToastMessages = (state: AppState) => state.toasts;
export const selectToastMessage = (state: AppState) => (id: string) => {
  const toasts = selectToastMessages(state);

  return toasts.find((toast) => toast.id === id);
};
export const selectLoginWarning = (state: AppState) => {
  const toasts = selectToastMessages(state);

  return toasts.find((toast) => toast.id === GLOBAL_MESSAGE_ID.LOGIN_WARNING);
};

export const selectGeneralActionToast = (state: AppState) => {
  const toasts = selectToastMessages(state);

  return toasts.find((toast) => toast.id === GLOBAL_MESSAGE_ID.GENERAL_MESSAGE);
};

export const selectUserCrudSuccess = (state: AppState) => {
  const toasts = selectToastMessages(state);

  return toasts.find((toast) => toast.id === GLOBAL_MESSAGE_ID.USER_CRUD_SUCCESS);
};

export const selectUserCrudError = (state: AppState) => {
  const toasts = selectToastMessages(state);

  return toasts.find((toast) => toast.id === GLOBAL_MESSAGE_ID.USER_CRUD_ERROR);
};

export const selectAddsParams = (state: AppState) => state.adds;
