import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppInfoSelector } from 'hooks/redux/hooks';

import { selectGeneralActionToast } from '../selectors';
import { clearGeneralActionToast, setGeneralActionToast } from '../slice';
import { Toast } from '../types';

type ReturnType = [
  initiator: (header?: string, message?: string) => unknown,
  props: { toast?: Toast; clearToast: () => unknown; isVisible: boolean }
];

export const useGeneralActionToast = (
  init: Toast = { status: 'info', message: '', header: '' }
): ReturnType => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const generalToast = useAppInfoSelector<typeof selectGeneralActionToast>(selectGeneralActionToast);

  const clearGeneralToastState = () => {
    dispatch(clearGeneralActionToast());
  };

  useEffect(() => {
    return clearGeneralToastState;
  }, [dispatch]);

  return [
    (header?: string, message?: string) =>
      dispatch(
        setGeneralActionToast({
          ...init,
          header: t(header ?? init.header ?? ''),
          message: t(message ?? init.message)
        })
      ),
    {
      toast: generalToast,
      clearToast: clearGeneralToastState,
      isVisible: !!generalToast
    }
  ];
};

export const useErrorActionToast = (): ReturnType =>
  useGeneralActionToast({ status: 'error', message: 'generalErrorMessage', header: 'generalErrorHeader' });

export const useSuccessActionToast = (init: Partial<Toast> = {}): ReturnType =>
  useGeneralActionToast({
    status: 'success',
    message: init.message ?? '',
    header: init.header
  });
