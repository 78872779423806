import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { FormContainer } from 'features/application/components/formContainer';
import { useFormContext } from 'features/application/context/FormContext';
import { getStepsByProductType } from 'features/application/forms/sequences.ts';
import {
  selectIsCompanyStepsEnabled,
  selectIsFarm,
  selectIsNewCompany,
  selectProductType
} from 'features/application/selectors';
import { overWriteClientCountryCode, setIsFarm, setStepsSequence } from 'features/application/slice';
import { selectActiveCustomer } from 'features/auth/selectors/';
import { useAppDispatch, useApplicationSelector, useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { VALIDATION_MESSAGE_ID } from 'validators/types';

import { useFormHandlers } from '../../../../hooks/forms/useFormHandlers';
import { STEPS } from '../../types/applicationTypes';
import utils from '../utils/objectHelpers';

import { CompanyOfFarmField } from './companyOrFarmField';

export type IsFarm = {
  isFarm?: boolean;
};

const initialData: IsFarm = {
  isFarm: undefined
};

export const validationSchema = Yup.object({
  isFarm: Yup.boolean().required(VALIDATION_MESSAGE_ID.REQUIRED)
});

type Props = {
  onNextClick: (sequence?: STEPS[]) => void;
};

const CompanyOrFarmForm: React.FC<Props> = ({ onNextClick }) => {
  const isFarm = useAppSelector(selectIsFarm);
  const isCompanyStepsEnabled = useAppSelector(selectIsCompanyStepsEnabled);
  const productType = useApplicationSelector<typeof selectProductType>(selectProductType);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isNewCompany = useAppSelector(selectIsNewCompany);

  const isCustomerFarmer = activeCustomer?.customerType === 'FARMER';

  const { getValues, validationHelpers, setTouchedOnAll, handleSubmit, clearErrors } =
    useFormHandlers<IsFarm>(
      utils.copyReadOnlyObjectValues(
        initialData,
        isFarm !== undefined ? { isFarm: (isCustomerFarmer && !isNewCompany) || isFarm } : {}
      ),
      validationSchema,
      'onBlur'
    );

  const dispatch = useAppDispatch();

  const { setFormHandlers } = useFormContext();

  const submitHandler = handleSubmit(
    (data) => {
      const selectedSequence = getStepsByProductType(productType, !isCompanyStepsEnabled, data.isFarm);

      dispatch(setIsFarm(data.isFarm));
      dispatch(setStepsSequence(selectedSequence));
      dispatch(overWriteClientCountryCode(data.isFarm === true));
      onNextClick(selectedSequence);
    },
    () => {
      setTouchedOnAll();
    }
  );

  useEffect(
    () =>
      setFormHandlers({
        submitHandler
      }),
    [setFormHandlers]
  );

  const handleNextClick = async () => {
    await submitHandler();
  };

  return (
    <FormContainer isValid={validationHelpers.isValid} onNextClick={handleNextClick}>
      <CompanyOfFarmField
        validationHelpers={validationHelpers}
        getValues={getValues}
        clearErrors={clearErrors}
      />
    </FormContainer>
  );
};

export default CompanyOrFarmForm;
