import { LoginTypes, SupportedCountries } from '../types/authTypes';

type LoginPerCountry = {
  value: SupportedCountries;
  loginMethods?: LoginTypes[];
};

export const availableLogonMethodsByCountry: LoginPerCountry[] = [
  { value: 'LT', loginMethods: ['smartId', 'mobilId'] },
  { value: 'LV', loginMethods: ['smartId', 'eparakts'] },
  { value: 'EE', loginMethods: ['smartId', 'mobilId'] },
  { value: 'FI', loginMethods: ['FTN'] },
  { value: 'NL', loginMethods: ['IDIN'] }
];
