import styled from 'styled-components';

import chevronDown from '../../assets/icons/chevronDown.svg';
import magnificationGlass from '../../assets/icons/magnificationGlass.svg';
import flags from '../../assets/images/flags.png';
import { ErrorMessage } from '../formError/errors.styles';
import { PlaceholderLabel } from '../input/input.style';
import { LinkButton } from '../link';

type Props = {
  invalidStatus?: boolean;
  inputSize?: 'M' | 'L';
};

const getInputHeight = ({ inputSize }: Props) => (inputSize === 'L' ? '3.5rem' : '3rem');

export const PhoneFieldContainer = styled.section<Props>`
  position: relative;

  input {
    margin-left: 4.875rem;
    width: 100%;
    height: ${getInputHeight};
    z-index: 5;
    position: relative;
    background: white;
    padding: 1.75rem 1rem 0.5rem 0.375rem;
    border: 0.0625rem
      ${({ invalidStatus, theme }) =>
        invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10]}
      solid;
    border-radius: 0.5rem;
    line-height: 1.25rem;
    font-size: ${({ theme }) => theme.fontSizes.h4};

    &:focus {
      border-bottom: 0.0625rem black solid;
      box-shadow: none;
      padding: 1rem;
    }

    &:focus-visible {
      border-color: black;
    }

    &:disabled {
      background-color: #f7f7f7;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.primary.inputPlaceholderColor};
      opacity: 1;
    }

    &:focus-within {
      outline: none;
      border: 0.0625rem
        ${({ invalidStatus, theme }) =>
          invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10]}
        solid;
    }

    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .areaCodeSelect--open + input {
    border-bottom: none;
    border-bottom-right-radius: 0;
  }

  .PhoneInputCountry {
    padding: 0 1rem;
    height: ${getInputHeight};
    border: 0.0625rem
      ${({ invalidStatus, theme }) =>
        invalidStatus ? theme.colors.base.red.shades[120] : theme.colors.base.black.tints[10]}
      solid;
    border-right: 0;
    margin-right: 0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    select {
      height: ${getInputHeight};
    }

    .PhoneInputCountryIcon {
      overflow: hidden;
      width: 1.5rem;
      height: 1.5rem;
      border: 0;
      box-shadow: none;
      position: relative;
      max-width: 2rem;
      border-radius: 50%;
    }

    .PhoneInputCountryIconImg {
      display: block;
      height: auto;
      width: 150%;
      margin: 0 0 0 -20%;
    }

    .PhoneInputCountrySelectArrow {
      margin-left: 1rem;
      margin-bottom: 0.2rem;
      border-bottom-width: 0.15rem;
      border-right-width: 0.15rem;
      width: 0.4rem;
      height: 0.4rem;
      border-color: #7f7f7f;
    }
  }

  ${ErrorMessage} {
    padding-top: 0.25rem;
  }
`;

export const PhoneFieldLabel = styled(PlaceholderLabel)`
  left: 5.2rem;
  font-size: 0.75rem;
  top: -0.75rem;
`;

type StyledPhoneInputContainerProps = {
  showLabel: boolean;
  hasError: boolean;
  disabled?: boolean;
  width?: number;
} & Props;

const getPlaceHolderOffsetBySize = ({ inputSize }: Props) => (inputSize === 'L' ? '1rem' : '0.7813rem');

export const StyledPhoneInputContainer = styled.div<StyledPhoneInputContainerProps>`
  width: 100%;
  position: relative;

  .phoneInputContainer {
    font-family: inherit;
    font-size: 0.875rem;
    line-height: 1.5rem;

    .special-label {
      display: ${({ showLabel }) => (showLabel ? 'inline-block' : 'none')};
      font-size: 0.875rem;
      line-height: 1.5rem;
      left: 7.5rem;
      top: ${getPlaceHolderOffsetBySize};
      padding: 0;
      background-color: rgba(255, 255, 255, 0%);
      color: ${({ theme }) => theme.colors.primary.inputPlaceholderColor};
      pointer-events: none;
    }
  }

  .phoneInput {
    width: calc(100% - 3.75rem);
    height: ${getInputHeight};
    background-color: #ffffff;
    border: 0.0625rem solid
      ${(props) =>
        props.hasError ? props.theme.colors.base.red.shades[120] : props.theme.colors.base.black.tints[10]};
    border-radius: 0 0.5rem 0.5rem 0;
    padding: 0.125rem 0.1875rem 0.125rem 1rem;
    margin-left: 3.75rem;

    &:hover {
      border: 0.0625rem solid ${(props) => props.theme.colors.base.black.tints[90]};
    }

    :focus {
      outline: none;
      border: 0.0625rem solid
        ${(props) =>
          props.hasError
            ? props.theme.colors.base.red.shades[120]
            : props.theme.colors.base.primary.shades[100]};
    }

    :disabled {
      cursor: not-allowed;
      background-color: ${(props) => (props.disabled ? '#f7f7f7' : '#fff')};
    }

    &.open {
      z-index: 0;
    }
  }

  .phoneInput:hover + .phoneInputButton {
    border: 0.0625rem solid ${(props) => props.theme.colors.base.black.tints[90]};
    border-right: none;
  }

  .phoneInput:has(+ .phoneInputButton:hover) {
    border: 0.0625rem solid ${(props) => props.theme.colors.base.black.tints[90]};
  }

  .phoneInput:focus:has(+ .phoneInputButton:hover) {
    border: 0.0625rem solid
      ${(props) =>
        props.hasError
          ? props.theme.colors.base.red.shades[120]
          : props.theme.colors.base.primary.shades[100]};
  }

  .phoneInput:focus + .phoneInputButton {
    border: 0.0625rem solid
      ${(props) =>
        props.hasError
          ? props.theme.colors.base.red.shades[120]
          : props.theme.colors.base.primary.shades[100]};
    border-right: none;
  }

  .phoneInputButton {
    &.hidden {
      display: none;
    }

    border-radius: 0.5rem 0 0 0.5rem;
    border: 0.0625rem solid
      ${(props) =>
        props.hasError ? props.theme.colors.base.red.shades[120] : props.theme.colors.base.black.tints[10]};
    border-right: none;
    width: 3.75rem;
    display: flex;
    justify-content: center;

    &.open {
      border-radius: 0.5rem 0 0 0.5rem;
      border: 0.0625rem solid
        ${(props) =>
          props.hasError ? props.theme.colors.base.red.shades[120] : props.theme.colors.base.black.tints[10]};
      border-right: none;
    }

    &:hover {
      border: 0.0625rem solid ${(props) => props.theme.colors.base.black.tints[90]};
      border-right: none;
    }

    .selected-flag {
      width: 100%;
      padding: 0;
      border-radius: 0.5rem 0 0 0.5rem;
      display: flex;
      justify-content: center;

      &.open {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      .flag {
        width: 0.9375rem;
        height: 0.9375rem;
        border-radius: 62.4375rem;
        margin: 0;
        margin-right: 0.9375rem;
        background-image: ${() => `url("${flags}")`};
        top: 60%;
        transform: translateY(-60%);
        scale: 140%;
      }

      .arrow {
        border: none;
        background-image: ${() => `url("${chevronDown}")`};
        width: 1.5rem;
        height: 1.5rem;
        left: 0.75rem;
        scale: 60%;
        top: -0.125rem;
      }

      .arrow.up {
        background-image: ${() => `url("${chevronDown}")`};
        transform: rotate(180deg);
      }
    }
  }

  .react-tel-input {
    .special-label {
      font-size: 1rem;
    }

    .form-control {
      font-size: 1rem;
    }
  }

  .phoneInputDropdown {
    ${({ width }) => (width ? ` width: ${width}px` : '')};
    padding: 0 0.25rem 0.25rem;
    box-shadow: 0 0.25rem 0.5rem ${({ theme }) => theme.colors.shadow};
    margin: 0;
    left: -0.0625rem;
    top: 3.25rem;
    border-radius: 0.625rem;
    max-height: 17.375rem;

    > li.search {
      padding: 0.25rem 0;

      .search-box {
        font-size: 1rem;
        border: 0.0625rem solid ${(props) => props.theme.colors.base.black.tints[10]};

        &:hover {
          border: 0.0625rem solid ${(props) => props.theme.colors.base.black.tints[90]};
        }
      }

      .search-emoji {
        top: 1rem;
      }
    }

    > li.no-entries-message {
      font-size: 1rem;
      text-align: center;
      color: rgb(153, 153, 153);
    }

    > li.country {
      padding-inline: 0.875rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      min-height: 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0.5rem;
      margin-bottom: 0.25rem;
      padding-left: 3rem;

      &:hover {
        background-color: ${({ theme }) => theme.colors.base.black.shades[3]};
      }

      &.highlight {
        background-color: ${({ theme }) => theme.colors.base.primary.shades[5]};

        &:hover {
          background-color: ${({ theme }) => theme.colors.base.primary.shades[10]};
        }
      }

      .flag {
        background-image: ${() => `url("${flags}")`};
        border-radius: 62.4375rem;
        width: 0.9375rem;
        height: 0.9375rem;
        scale: 140%;
        top: 50%;
        transform: translateY(-40%);
        left: 1.125rem;
      }
    }
  }

  .phoneInputSearch {
    background-color: #ffffff;

    &.search {
      padding: 1rem 0;
    }

    .search-box {
      height: 3rem;
      border-radius: 0.5rem;
      border: 0.0625rem solid ${({ theme }) => theme.colors.primary.border.dark};
      font-family: inherit;
      font-size: 0.875rem;
      line-height: 1.5rem;
      width: 100%;
      margin: 0;
      padding: 0.7813rem 2.5313rem;

      :focus {
        outline: none;
        border: 0.0625rem solid ${({ theme }) => theme.colors.primary.linkText};
      }
    }

    .search-emoji {
      background-image: ${() => `url("${magnificationGlass}")`};
      font-size: 0;
      padding: 0;
      position: absolute;
      top: 1.75rem;
      left: 0.625rem;
      z-index: 10;
      width: 1.5rem;
      height: 1.5rem;
    }

    input[type='search' i]::-webkit-search-cancel-button {
      appearance: none;
    }
  }

  .flag-dropdown {
    background-color: #ffffff;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .selected-flag .flag {
    background-size: auto;
  }

  .react-tel-input .selected-flag .arrow {
    position: inherit;
  }
`;

export const AreaCodeSelectOverride = styled(LinkButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: none;
  border-radius: 0.625rem 0 0 0.625rem;
`;
