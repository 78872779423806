import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useContractSelector } from 'hooks/redux/hooks';

import { selectContractDetails, selectIsDetailsLoadFailed, selectIsDetailsLoading } from '../selectors';
import { fetchContractByIdAsync } from '../slice';

export const useFetchContractDetails = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || '';

  const fetchContractDetails = useCallback(() => {
    if (contractId) {
      dispatch(fetchContractByIdAsync({ contractId }));
    }
  }, [contractId, dispatch]);

  useEffect(() => {
    fetchContractDetails();
  }, [fetchContractDetails]);

  const contractDetails = useContractSelector<typeof selectContractDetails>(selectContractDetails);
  const isLoading = useContractSelector<typeof selectIsDetailsLoading>(selectIsDetailsLoading);
  const isError = useContractSelector<typeof selectIsDetailsLoadFailed>(selectIsDetailsLoadFailed);

  return { data: contractDetails, isLoading, isError, isUninitialized: false, refetch: fetchContractDetails };
};
