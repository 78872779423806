import { useTranslation } from 'react-i18next';

import { selectActiveCompany } from 'features/applications/selectors';
import { useFetchExistingCustomerOnboardingStatus } from 'features/onboarding/hooks';
import { useAppSelector } from 'hooks/redux/hooks';

import { useFetchExistingCustomerOndatoLinkMutation } from '../../api';

import SkeletonCard from './skeletonCard';
import {
  CardContent,
  CardContentBody,
  CardContentContainer,
  CardContentHeader,
  CompanyProfileCard,
  ShieldIcon,
  UpdateProfileButton
} from './updateCompanyProfileCard.styles';

const UpdateCompanyProfileCard = () => {
  const { t } = useTranslation();

  const selectedCompany = useAppSelector(selectActiveCompany);

  const { isFetching, isFeatureEnabled, onboardingNeeded, onboardingInitiationHandler } =
    useFetchExistingCustomerOnboardingStatus();

  const [, { isLoading }] = useFetchExistingCustomerOndatoLinkMutation({ fixedCacheKey: selectedCompany.id });

  if (isFetching) {
    return <SkeletonCard />;
  }

  if (!isFeatureEnabled || !onboardingNeeded) {
    return null;
  }

  return (
    <CompanyProfileCard>
      <CardContentContainer>
        <ShieldIcon />
        <CardContent>
          <CardContentHeader>{t('updateCompanyProfileData')}</CardContentHeader>
          <CardContentBody>{t('updateCompanyProfileDataExplanation')}</CardContentBody>
        </CardContent>
      </CardContentContainer>
      <UpdateProfileButton submitting={isLoading} disabled={isLoading} onClick={onboardingInitiationHandler}>
        {t('updateProfile')}
      </UpdateProfileButton>
    </CompanyProfileCard>
  );
};

export default UpdateCompanyProfileCard;
