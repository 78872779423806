import styled, { css } from 'styled-components';

import { LinkButton } from '../link';

export type ToastProps = {
  isVisible: boolean;
  variant: 'info' | 'error' | 'warning' | 'success';
  animate: boolean;
};

export const ToastStyle = styled.div<ToastProps>`
  display: flex;
  flex-direction: column;
  flex: initial;
  max-height: ${(props) => (props.isVisible ? '45rem' : '0')};
  min-height: ${(props) => (props.isVisible ? '3.5rem' : '0')};
  background: ${({ variant, theme }) => theme.colors.toast[variant].background};
  border-radius: 0.5rem;
  color: ${({ variant, theme }) => theme.colors.toast[variant].header};
  border: 1px solid ${({ variant, theme }) => theme.colors.toast[variant].outline};
  align-items: center;
  overflow: hidden;
  width: 100%;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  ${({ animate }) =>
    animate
      ? css<ToastProps>`
          animation: ${(props) =>
            props.isVisible ? 'open-toast 0.8s ease-out forwards' : 'close-toast 0.5s ease-out forwards'};
        `
      : ''}

  @keyframes open-toast {
    from {
      max-height: 0;
    }

    to {
      max-height: 20rem;
    }
  }

  @keyframes close-toast {
    from {
      max-height: 20rem;
    }

    to {
      max-height: 0;
    }
  }
`;

type IconContainerProps = {
  colorByVariant: boolean;
  variant: ToastProps['variant'];
};

export const IconContainer = styled.div<IconContainerProps>`
  width: 1.5rem;
  height: 1.5rem;
  ${({ colorByVariant, variant, theme }) =>
    colorByVariant
      ? css`
          svg {
            * {
              stroke: ${theme.colors.toast[variant || 'error'].fill};
            }
          }
        `
      : ''}
`;

export const Header = styled.h3<Partial<ToastProps>>`
  color: ${({ variant, theme }) => theme.colors.toast[variant || 'error'].header};
  font-size: ${({ theme }) => theme.fontSizes.h5};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: 1.5rem;
  margin: 0;
`;

export const ToastContainer = styled.div`
  display: flex;
  padding: 1rem;
  width: 100%;

  @media ${({ theme }) => theme.media.maxTablet} {
    justify-content: space-between;
    padding: 0.75rem;
  }
`;

export type ToastMessageProps = {
  showMore: boolean;
  truncateText: boolean;
  showHeader: boolean;
};

export const ToastMessage = styled.div<ToastMessageProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: ${({ showHeader }) => (showHeader ? '1rem' : 0)};
  padding-inline: ${({ showHeader }) => (showHeader ? '1.25rem 2.75rem' : 0)};
  gap: 1rem;

  p {
    justify-content: flex-start;
    line-height: 1.5rem;
    display: ${({ showMore }) => (showMore ? 'block' : 'inline-flex')};
    overflow: hidden;
    margin: 0;
    white-space: break-spaces;
    font-size: 0.875rem;
    ${({ truncateText }) => {
      if (truncateText) {
        return css`
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        `;
      }

      return '';
    }}
  }

  ul {
    margin: 0;
    padding: 0;
    margin-left: 1.25rem;

    li.removed {
      animation: removed 0.5s ease-out forwards;
    }

    li.added {
      animation: added 0.5s ease-out forwards;
    }
  }

  @keyframes removed {
    0% {
      opacity: 1;
      max-height: 100%;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 0;
      max-height: 0;
    }
  }

  @keyframes added {
    0% {
      opacity: 0;
      max-height: 0;
    }

    20% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      max-height: 100%;
    }
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    padding: ${({ showHeader }) => (showHeader ? '0 0.75rem 0.75rem' : '0 0.75rem 0')};
    margin: 0;
  }
`;

export const ToggleButton = styled(LinkButton)<Partial<ToastProps>>`
  color: ${({ variant, theme }) => theme.colors.toast[variant || 'error'].fill};
`;

export const CloseContainer = styled(LinkButton)<Partial<ToastProps>>`
  align-items: start;

  svg {
    margin: 0;
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill: ${({ variant, theme }) => theme.colors.toast[variant || 'error'].fill};
      stroke: none;
    }
  }
`;

export const ToastHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const ToastLeftContent = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    > ${ToastMessage} {
      padding-inline: 0;
    }
  }
`;
