import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { selectActiveCompany } from 'features/applications/selectors';
import { useAppDispatch, useAppSelector } from 'hooks/redux/hooks';
import { AppService } from 'services';
import { OndatoInitiateResponse, OndatoStatusResponse } from 'services/OnboardingService/types';
import { getLanguageCode } from 'translations/utils';
import tracking from 'utils/tracking';

import {
  updateQueryData,
  useFetchExistingCustomerOndatoLinkMutation,
  useFetchOndatoLinkMutation
} from '../api';

type Params = {
  ondatoInfo: OndatoStatusResponse;
  onOndatoInitSuccess?: (success: boolean, ondatoUrl?: string) => void | Promise<void>;
};

const useOndatoRedirectHandler = ({
  ondatoInfo,
  onOndatoInitSuccess
}: Params): [handler: () => unknown, isSubmiting: boolean] => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const resolvedApplicationId = ondatoInfo?.applicationId ?? '';

  const selectedCompany = useAppSelector(selectActiveCompany);

  const [initOndatoLink, { isLoading }] = useFetchOndatoLinkMutation();
  const [initExistingCustomerOndatoLink, { isLoading: existingCustomerOndatoLinkIsLoading }] =
    useFetchExistingCustomerOndatoLinkMutation({ fixedCacheKey: selectedCompany?.id });

  const memoizedHandler = useCallback(async () => {
    try {
      let result: OndatoInitiateResponse | null = null;

      const commonArgs = {
        debug: process.env.NODE_ENV === 'development',
        onlyMobile: false,
        language: getLanguageCode(i18n.language)
      };

      if (resolvedApplicationId) {
        result = await initOndatoLink({
          applicationId: resolvedApplicationId,
          content: {
            'application/json': {
              ...commonArgs,
              applicationId: resolvedApplicationId
            }
          }
        }).unwrap();
      } else {
        result = await initExistingCustomerOndatoLink({
          cacheKey: selectedCompany?.id,
          ...commonArgs
        }).unwrap();

        if (result?.ondatoUrl) {
          dispatch(
            updateQueryData(
              'fetchExistingCustomerOnboardingStatus',
              { cacheKey: selectedCompany?.id },
              (draft) => {
                draft.ondatoUrl = result?.ondatoUrl;
              }
            )
          );

          tracking.setSubmitEvent('existing_customer_ondata_flow_started');
          await onOndatoInitSuccess?.(true, result?.ondatoUrl);

          return;
        }

        throw new Error('Failed to init ondato link for existing customer');
      }

      if (result) {
        if (!result.ondatoUrl) {
          throw new Error('Failed to init ondato link');
        }

        if (resolvedApplicationId) {
          dispatch(
            updateQueryData(
              'checkForApplicationStatus',
              { applicationId: resolvedApplicationId },
              (draft) => {
                draft.ondatoUrl = result?.ondatoUrl;
                draft.ondatoId = result?.ondatoId;
              }
            )
          );

          tracking.setSubmitEvent('single_flow_ondato_flow_finished');
          await onOndatoInitSuccess?.(true);
        } else {
          tracking.setSubmitEvent('existing_customer_ondato_flow_finished');
          await onOndatoInitSuccess?.(true);
        }
      } else {
        throw new Error('Failed to init ondato link');
      }
    } catch (e) {
      await onOndatoInitSuccess?.(false);
      AppService.reportError({
        message: `Failed to init ${resolvedApplicationId ? 'single flow' : 'existing customer'} ondato link`,
        eventId: ondatoInfo.ondatoId
      });
    }
  }, [resolvedApplicationId, i18n.language, dispatch, initOndatoLink, onOndatoInitSuccess]);

  return [memoizedHandler, isLoading || existingCustomerOndatoLinkIsLoading];
};

export default useOndatoRedirectHandler;
