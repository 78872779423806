import { Props as ContainerProps, StatusChipContainer, StatusChipText } from './statusChip.styles';

type Props = {
  children: React.ReactNode;
  className?: string;
} & ContainerProps;

export const StatusChip = ({ color, children, className }: Props) => {
  return (
    <StatusChipContainer className={className} color={color}>
      <StatusChipText color={color}>{children}</StatusChipText>
    </StatusChipContainer>
  );
};
