import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Drawer } from 'features/app/components/drawer';
import { useFormContext } from 'features/application/context/FormContext';
import { ContactFields } from 'features/application/forms/contact/contactFields';
import { ApplicationFields } from 'features/application/forms/contact/contactForm';
import { selectIsUserGhost } from 'features/auth/selectors';
import { useAuthSelector } from 'hooks/redux/hooks';

type Props = {
  setPhoneNumber?: (value: string) => void;
};

const ContactsDrawer: FC<Props> = ({ setPhoneNumber }) => {
  const { t } = useTranslation();

  const isGhostUser = useAuthSelector(selectIsUserGhost);

  const { formHandlers } = useFormContext<ApplicationFields>();

  const { getValues, validationHelpers, submitHandler } = formHandlers ?? {};

  const onContactsUpdate = async () => {
    setPhoneNumber?.(getValues?.('phoneNumber') ?? '');
    await submitHandler?.();
  };

  const onFormReset = () => {
    setPhoneNumber?.(getValues?.('phoneNumber') ?? '');
    validationHelpers?.clearErrors?.();
  };

  return (
    <Drawer
      isValid={validationHelpers?.isValid}
      header={t('contactPerson')}
      onSave={onContactsUpdate}
      onClose={onFormReset}
      buttonLabel={t('save')}
    >
      {getValues && validationHelpers ? (
        <ContactFields
          isGhostUser={isGhostUser}
          getValues={getValues}
          validationHelpers={validationHelpers}
        />
      ) : null}
    </Drawer>
  );
};

export default ContactsDrawer;
