import { Trans, useTranslation } from 'react-i18next';

import { Modal } from 'components';
import { CountryOfCompanyRegistration } from 'features/application/forms/company/constants';
import { getCountryDisplayName } from 'translations/countries';
import { addCaseToCountryName } from 'translations/countries/getCountryDisplayName';

import { Flag, ModalHeader } from './registrationCountryModal.styles';

type Props = {
  country: CountryOfCompanyRegistration;
  isOpen?: boolean;
  onVisibilityChange?: (isOpen: boolean) => void;
  onAccept?: () => void;
};

const RegistrationCountryModal = ({ country, isOpen, onVisibilityChange, onAccept }: Props) => {
  const { t, i18n } = useTranslation();

  const displayName = getCountryDisplayName(country, (i18n.language as 'lt' | 'en') ?? 'en');

  const countryName = i18n.language === 'en' ? displayName : addCaseToCountryName(displayName);

  const handleClose = () => {
    onVisibilityChange?.(false);
  };

  const handleAccept = () => {
    onAccept?.();
    onVisibilityChange?.(false);
  };

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={handleClose}
      variant="informational"
      informationalImage={<Flag className={`fi fi-${country.toLowerCase()} fis`} />}
      mainActionLabel={t('isYourCompanyRegisteredInAnswer', { country: countryName })}
      mainActionhandler={handleAccept}
      secondaryActionLabel={t('isYourCompanyRegisteredInNegativeAnswer')}
      secondaryActionhandler={handleClose}
    >
      <ModalHeader>
        <Trans
          i18nKey="isYourCompanyRegisteredIn"
          components={{ b: <b></b> }}
          values={{ country: countryName }}
        />
      </ModalHeader>
    </Modal>
  );
};

export default RegistrationCountryModal;
