import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InlineLink } from '../link';
import { Modal } from '../modal';

type Props = {
  isOpen: boolean;
  onVisibilityChange: (visible: boolean) => unknown;
  termsTextId: string;
};

const privacyPolicyLink = {
  lt: 'https://smego.com/lt/privacy-policy/',
  en: 'https://smego.com/privacy-policy/'
};

export const TermsModal: FC<Props> = ({ isOpen, onVisibilityChange, termsTextId }) => {
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    onVisibilityChange(false);
  };

  const linkTo = privacyPolicyLink[i18n.language as keyof typeof privacyPolicyLink] || privacyPolicyLink.en;

  return (
    <Modal termsModal label={t('termsAndConditions')} isOpen={isOpen} onClose={handleClose}>
      <p>
        <Trans
          i18nKey={termsTextId}
          components={{
            mail: <InlineLink href="mailTo:info@smefinance.lt" />,
            smeLink: <InlineLink href={linkTo} target="_blank" rel="noopener noreferrer" />
          }}
        />
      </p>
    </Modal>
  );
};
