import styled from 'styled-components';

import { LinkButtonStyle, ToggleContainerStyle } from 'components';

export const MonthSelectorArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.0625rem solid #cccccc;
  border-radius: 0.5rem;
  margin-right: 0.625rem;

  svg {
    margin-right: 0;
  }
`;

export const MonthSelector = styled.div`
  margin-bottom: 0.625rem;
`;

type Props = {
  active?: boolean;
};

export const DataTypeOption = styled.span<Props>`
  ${LinkButtonStyle} {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary.linkText : theme.colors.secondary.textColor};
  }
`;

export const DataTypeSelector = styled.div`
  display: flex;
  align-items: center;

  ${LinkButtonStyle} {
    &:first-of-type {
      margin-right: 0.625rem;
    }
  }
`;

export const LeftSideSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${ToggleContainerStyle} {
    margin-right: 1.25rem;
    margin-bottom: 0.625rem;
  }
`;

export const FilterPanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
`;
