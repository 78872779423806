import styled, { css } from 'styled-components';

import { DatePickerContainer } from '../datePicker/datePicker.styles';
import { AsyncSearchInputContainer, InputContainer } from '../input/input.style';

type Props = {
  $applyLastInput?: boolean;
};

export const Fieldset = styled.fieldset<Props>`
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${InputContainer} {
    margin-bottom: 1rem;
  }

  ${DatePickerContainer} {
    margin-bottom: 1rem;
  }

  ${AsyncSearchInputContainer} {
    margin-bottom: 1rem;
  }

  ${InputContainer}:last-of-type {
    ${({ $applyLastInput }) =>
      !$applyLastInput &&
      css`
        margin-bottom: 0;
      `}
  }

  ${DatePickerContainer}:last-of-type {
    ${({ $applyLastInput }) =>
      !$applyLastInput &&
      css`
        margin-bottom: 0;
      `}
  }
`;

export const Legend = styled.legend`
  padding: 0 0 1rem;
  color: ${({ theme }) => theme.colors.secondary.textColor};
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;
