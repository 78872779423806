import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { OtpCodeDialog } from 'components';
import { ActionToast } from 'components/actionToast';
import { selectUserData } from 'features/auth/selectors';
import { SupportedCountries } from 'features/auth/types/authTypes';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { ProtectedRouteState } from 'hooks/router/useAppLocation';

import { initiateOTPAsync, loginOTPAsync } from '../../slice';

type Props = {
  fullyQualifiedLogin: boolean;
  redirectUri?: string;
  state?: ProtectedRouteState;
  otpHeaderLabel?: string;
  leftAlign?: boolean;
};

export const OtpCode: FC<Props> = ({
  fullyQualifiedLogin,
  redirectUri,
  state,
  otpHeaderLabel,
  leftAlign
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const languageCode = (i18n.language || 'en').toUpperCase();

  const userData = useAuthSelector(selectUserData);

  const [otpRefreshed, setOTPRefreshed] = useState(false);

  const onSuccess = () => {
    navigate(redirectUri || '/new-application', { state });
  };

  const resendOTP = async () => {
    await dispatch(initiateOTPAsync({ ...userData, country: languageCode as SupportedCountries })).unwrap();
    setOTPRefreshed(true);
  };

  const onSubmit = async (code: string) => {
    return dispatch(loginOTPAsync({ password: code, fullyQualifiedLogin })).unwrap();
  };

  return (
    <>
      <ActionToast
        isVisible={otpRefreshed}
        onClose={() => setOTPRefreshed(false)}
        message={t('pleaseCheckYourInboxTo')}
        variant="success"
        header={t('passwordWasSent')}
      />
      <OtpCodeDialog
        borderless
        leftAlign={leftAlign}
        wrongOtpWarningLabel={t('wrongOTP')}
        wrongOtpWarningExceededRetriesLabel={t('wrongOTPNoRetriesLeft')}
        otpHeaderLabel={otpHeaderLabel ? t(otpHeaderLabel) : otpHeaderLabel}
        userData={userData}
        resendOtpLinkLabel={t('timeRemaining')}
        resendOneTimePasswordLable={t('resendOneTimePassword')}
        onSubmit={onSubmit}
        onRefresh={resendOTP}
        onSuccess={onSuccess}
      />
    </>
  );
};
