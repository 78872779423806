import { ReactNode, forwardRef, useEffect, useId, useImperativeHandle, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useOnOpenChangeMutationObserver } from './hooks';
import { Aside, AsideContent, AsideContentProps } from './layout.styles';

const animationDuration = 150;

type Props = {
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => unknown;
  activeIndex?: number;
  /**
   * @deprecated use position instead
   */
  absolute?: boolean;
  position?: 'absolute' | 'fixed' | 'sticky';
  disableBodyScroll?: boolean;
  swipeable?: boolean;
  portal?: boolean;
} & AsideContentProps;

export const toggleAsideMenuByActiveDataIndex = (activeIndex: number, isOpen?: boolean) => {
  const asideMenus = document.querySelectorAll('aside');

  asideMenus.forEach((menu) => {
    if (
      menu.getAttribute('data-active-index') === activeIndex.toString() &&
      menu.classList.contains('open') !== isOpen
    ) {
      toggleAsideMenu(menu, isOpen);
    }
  });
};

const toggleAsideMenuById = (uniqueId: string, isOpen?: boolean) => {
  const asideMenu = document.querySelector(`aside#${uniqueId}`);

  toggleAsideMenu(asideMenu, isOpen);
};

const toggleAsideMenu = (asideMenu: Element | null, isOpen?: boolean) => {
  const asideMenus = document.querySelectorAll('aside');

  if (asideMenu?.classList.contains('open') !== Boolean(isOpen)) {
    if (asideMenu) {
      asideMenus.forEach((menu) => {
        menu.classList.add('drawer-transition');
        menu.style.removeProperty('z-index');
      });

      const hasOpened = asideMenu.classList.toggle('open');

      if (!hasOpened) {
        const isSwipeConfirm = asideMenu.getAttribute('data-swipe-comfirm') === 'true';

        if (!isSwipeConfirm) {
          asideMenu.classList.toggle('close');
        }
      }

      setTimeout(() => {
        window.requestAnimationFrame(() => {
          asideMenu.classList.remove('close');

          asideMenus.forEach((menu) => {
            menu.classList.remove('drawer-transition');
          });
        });
      }, animationDuration + 50);
    }
  }
};

const AsideDrawer = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      isOpen,
      onClose,
      hideScroll,
      position,
      disableBodyScroll,
      portal = true,
      swipeable = true,
      absolute = true,
      activeIndex = 0
    },
    ref
  ) => {
    const uniqueId = useId();
    const validDOMId = `overlay-aside-menu${uniqueId.replaceAll(':', '')}`;

    const drawerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      toggleAsideMenuById(validDOMId, isOpen);

      if (!isOpen) {
        onClose?.();
      }

      if (disableBodyScroll) {
        document.body.classList.toggle('drawer-focused', Boolean(isOpen));
      }

      return () => {
        if (disableBodyScroll) {
          document.body.classList.remove('drawer-focused');
        }
      };
    }, [isOpen]);

    const isRoot = activeIndex === 0;

    const resolvedPosition = position ?? (absolute ? 'absolute' : 'sticky');

    const onOpenUpdateZIndexesOfOpenDrawers = (activeIndex: number, isOpen?: boolean) => {
      const asideMenus = document.querySelectorAll('aside');

      asideMenus.forEach((menu) => {
        if (menu.classList.contains('open')) {
          const siblingActiveIndex = Number(menu.getAttribute('data-active-index'));

          if (siblingActiveIndex < activeIndex) {
            if (isOpen && !menu.style.zIndex) {
              const currentZIndex = getComputedStyle(menu).zIndex;

              menu.style.zIndex = (Number(currentZIndex) - activeIndex * 100).toString();
            }
          }
        }
      });
    };

    useOnOpenChangeMutationObserver(drawerRef.current, onOpenUpdateZIndexesOfOpenDrawers);
    useOnOpenChangeMutationObserver(drawerRef.current, (_activeIndex, isOpen) => {
      if (!isOpen) {
        onClose?.();
      }
    });

    useImperativeHandle(ref, () => drawerRef.current as HTMLDivElement, [drawerRef]);

    const drawer = (
      <Aside
        ref={drawerRef}
        data-testid={`aside-drawer-${activeIndex}`}
        data-active-index={activeIndex}
        data-swipable-area={swipeable}
        opacity={isRoot ? 0 : 1}
        animationDurationInMs={animationDuration}
        position={resolvedPosition}
        id={validDOMId}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <AsideContent hideScroll={hideScroll}>{children}</AsideContent>
      </Aside>
    );

    if (isRoot || !portal) {
      return drawer;
    }

    return createPortal(
      drawer,
      document.getElementById('smesp-root') ?? document.body,
      validDOMId
    ) as unknown as ReactNode;
  }
);

export default AsideDrawer;
