import { FC, ReactNode } from 'react';

import Icons from '../../assets/icons';
import { Popup } from '../popup';

import { IconButtonContainer, InfoText } from './infoPopup.styles';

type InfoPopupProps = {
  text?: string;
  className?: string;
  children?: ReactNode;
  testId?: string;
  showAfter?: number;
};

export const InfoPopup: FC<InfoPopupProps> = ({ text, children, className, testId, showAfter }) => {
  return (
    <Popup
      className={className}
      placement="top"
      showAfter={showAfter}
      showOnHover
      button={
        <IconButtonContainer data-testid={`info-popup-button${testId ? `-${testId}` : ''}`}>
          <Icons.ExclamationCircle />
        </IconButtonContainer>
      }
    >
      {text ? <InfoText data-testid={`info-popup-text${testId ? `-${testId}` : ''}`}>{text}</InfoText> : null}
      {children}
    </Popup>
  );
};
