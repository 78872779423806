import styled from 'styled-components';

import smeGoLogo from 'assets/logo/SMEGo.svg';
import { Typography } from 'components';
import { Button } from 'components/buttonV2';
import { Overlay } from 'components/modal/modal.styles';

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  max-height: 29.5rem;
  max-width: 25rem;
  position: relative;
  text-align: center;

  @media ${({ theme }) => theme.media.maxPhone} {
    max-width: 20rem;
  }
`;

export const BannerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 100%;
  padding: 2.5rem 3rem 1.5rem;
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  position: relative;
`;

export const BannerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  padding: 1.5rem 2rem 2rem;
  width: 100%;
  gap: 1.5rem;
  color: #ffffff;

  img {
    padding: 0.4375rem;
    border-radius: 0.875rem;
    background-color: #ffffff;
    width: 7.125rem;
    height: 7.125rem;
  }

  @media ${({ theme }) => theme.media.maxPhone} {
    img {
      display: none;
    }
  }
`;

export const SmeGoLogo = styled.div`
  background-color: #ffffff;
  mask-position: center;
  mask-size: cover;
  mask-image: ${() => `url("${smeGoLogo}")`};
  width: 82px;
  height: 16px;
`;

export const CloseButtonPlaceHolder = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const ScanQRCodeMessage = styled(Typography.Body).attrs({ type: 'M' })`
  @media ${({ theme }) => theme.media.maxPhone} {
    display: none;
  }
`;

export const SmeGoButton = styled(Button)`
  display: none;

  @media ${({ theme }) => theme.media.maxPhone} {
    display: block;
    width: 100%;
  }
`;

export const BannerOverlay = styled(Overlay)`
  @media ${({ theme }) => theme.media.maxPhone} {
    background-color: rgba(0, 0, 0, 70%);
  }
`;
