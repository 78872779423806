import i18next, { ResourceLanguage } from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import legalTextEn from './legalTexts/en.json';
import legalTextLt from './legalTexts/lt.json';
import en from './locales/en.json';
import lt from './locales/lt.json';
import termsAndConditionsEN from './termsAndConditions/en.html?raw';
import termsAndConditionsLT from './termsAndConditions/lt.html?raw';

export type Languages = 'en' | 'lt';

export const USER_LANGUAGE_CHOICE = 'USER_LANGUAGE_CHOICE';

type Resources = {
  [key in Languages]: { translation: ResourceLanguage };
};

const resources: Resources = {
  lt: { translation: { ...lt, ...legalTextLt, termsAndConditionsContent: termsAndConditionsLT } },
  en: { translation: { ...en, ...legalTextEn, termsAndConditionsContent: termsAndConditionsEN } }
};

const isTestEnv = process.env.NODE_ENV === 'test';

const i18nInstance = i18next.use(detector).use(initReactI18next);

i18nInstance.init({
  lng: isTestEnv ? 'cimode' : undefined,
  fallbackLng: isTestEnv ? 'cimode' : 'lt',
  supportedLngs: ['en', 'lt'],
  load: 'languageOnly',
  keySeparator: false,
  interpolation: { escapeValue: false },
  detection: {
    lookupQuerystring: 'lan',
    lookupLocalStorage: USER_LANGUAGE_CHOICE,
    order: ['querystring', 'localStorage', 'navigator'],
    caches: []
  },
  resources
});

i18next.services?.formatter?.add('lowercase', (value) => {
  return value.toLowerCase();
});
