import { useTranslation } from 'react-i18next';

import UpsImage from 'assets/images/ups-illustration.svg';
import { Copyright, Paper } from 'components';
import { Button } from 'components/buttonV2';

import { Footer, ErrorPageCardColumn } from './error.styles';
import {
  MaintenancePageContent,
  MaintenanceCardContent,
  MaintenanceCardRow,
  MainTitle,
  Subtitle
} from './maintenance.styles';

export const GeneralErrorPage = () => {
  const { t } = useTranslation();

  const onClick = () => {
    window.location.href = '/dashboard/overview';
  };

  return (
    <MaintenancePageContent>
      <ErrorPageCardColumn>
        <div>
          <MaintenanceCardRow>
            <Paper>
              <MaintenanceCardContent>
                <img src={UpsImage} />
                <MainTitle> {t('somethingWentWrong')}</MainTitle>
                <Subtitle> {t('tryAgainLater')}</Subtitle>
                <Button variant="Filled" color="Blue" onClick={onClick}>
                  {t('backToHomepage')}
                </Button>
              </MaintenanceCardContent>
            </Paper>
          </MaintenanceCardRow>
        </div>
      </ErrorPageCardColumn>
      <Footer>
        <Copyright />
      </Footer>
    </MaintenancePageContent>
  );
};
