import styled from 'styled-components';

import { RegularText, SecondaryText } from 'components';

export const AditionalInfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${SecondaryText} {
    font-size: 0.875rem;
  }
`;

export const AditionalInfoHeader = styled(AditionalInfoRow)`
  margin-bottom: 0.25rem;

  ${RegularText} {
    font-size: 0.875rem;
  }
`;

export const AdditionalFeesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.25rem;
`;

export const DisbursementSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.125rem;
`;
