import { useEffect, useState, ReactNode, MutableRefObject, useRef } from 'react';
import DataTable, { TableProps, ConditionalStyles, SortOrder, TableColumn } from 'react-data-table-component';

import { colors } from 'design/designVariables';

import { ScreenSize } from '../layout';
import { SecondaryText } from '../text';

import { MobileTableList } from './mobileList';
import { Paginator } from './pagination';
import { SelectableRowCheckbox } from './selectableRowCheckbox';
import {
  TableContainer,
  SortIndicator,
  SortIndicatorContainer,
  OuterTableContainer,
  HeaderContainer,
  Header,
  TableSkeletonContainer,
  NoDataContainer
} from './table.styles';
import { TableSkeleton, TableSkeletonRef } from './tableSkeleton';
import { DefaultItem } from './types';

export const SortArrows = () => (
  <SortIndicatorContainer>
    <SortIndicator>
      <span />
      <span />
    </SortIndicator>
    <SortIndicator>
      <span />
      <span />
    </SortIndicator>
  </SortIndicatorContainer>
);

type Props<T> = {
  totalPages?: number;
  noDataMessage?: string;
  loading?: boolean;
  sortedBy?: { dataColumnId?: string | number; sortOrder: SortOrder };
  headerLabel?: string;
  headerActions?: ReactNode | ReactNode[];
  headerSelectAllExplanationLabel?: string;
  selectAllCheckboxLabel?: string;
  mobileColumns?: TableColumn<T>[];
  activePage?: number;
  persistNoDataContainerHeight?: boolean;
  minNoDataContainerHeight?: number;
  collapsible?: boolean;
};

const hoverBackground = '#fff';

const customStyles = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: hoverBackground,
      outline: `1px solid ${colors.primary.border.light}`
    }
  }
};

const conditionalRowStyles: ConditionalStyles<DefaultItem>[] = [
  {
    when: (row: DefaultItem) => Boolean(row.toggleSelected),
    classNames: ['table-row_selected']
  }
];

function Table<T extends DefaultItem>({
  noDataMessage,
  data,
  columns,
  loading,
  sortedBy,
  onRowClicked,
  headerLabel,
  headerActions,
  totalPages,
  activePage,
  headerSelectAllExplanationLabel,
  selectAllCheckboxLabel,
  mobileColumns,
  persistNoDataContainerHeight,
  minNoDataContainerHeight,
  collapsible = false,
  ...rest
}: TableProps<T> & Props<T>) {
  const tableContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const loaderContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const noDataContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const tableSkeletonRef = useRef() as MutableRefObject<TableSkeletonRef>;
  const tableSkeletonTimerRef = useRef(0) as MutableRefObject<number>;

  const [headerHeight, setHeaderHeight] = useState(0);
  const [displaySkeletonLoader, setDisplaySkeletonLoader] = useState(false);

  const TableContainerResizeObserver = useRef(
    new ResizeObserver((entries) => {
      if (tableContainerRef.current) {
        for (const entry of entries) {
          if (loaderContainerRef.current && !loading) {
            const headerCollection = entry.target.getElementsByClassName('rdt_TableHead');
            const tableCollection = entry.target.getElementsByClassName('rdt_Table');
            const tableRowsCollection = entry.target.getElementsByClassName('rdt_TableRow');

            const header = headerCollection.item(0) as HTMLDivElement;
            const table = tableCollection.item(0) as HTMLDivElement;
            const tableRow = tableRowsCollection.item(0) as HTMLDivElement;

            if (table && header) {
              loaderContainerRef.current.style.height = `${table.clientHeight - header.clientHeight}px`;
              loaderContainerRef.current.style.top = `${header.clientHeight}px`;
              setHeaderHeight(header.clientHeight);
            }

            if (tableRow && tableSkeletonRef.current) {
              tableSkeletonRef.current.rowHeight(tableRow.clientHeight);
            }

            if (noDataContainerRef.current && persistNoDataContainerHeight) {
              const newHeight = table.clientHeight - header.clientHeight;

              if (newHeight) {
                noDataContainerRef.current.style.height = `${table.clientHeight - header.clientHeight}px`;
              }
            }
          }
        }
      }
    })
  );

  const [visibleItemsInPage, setVisibleItemsInPage] = useState(data);
  const [currentPage, setCurrentPage] = useState(activePage || 1);

  const isLoading = Boolean(loading || rest.progressPending);

  const pagination =
    Boolean(totalPages) ||
    Boolean(rest.pagination && rest.paginationPerPage && data?.length > rest.paginationPerPage);

  const paginationServer = Boolean(pagination && totalPages);

  const totalRowsCount = rest.paginationPerPage ? (totalPages || 0) * rest.paginationPerPage : 0;

  const onChangePage = (page: number) => {
    const paginationPerPage = rest.paginationPerPage || 1;

    const visibleItems = data.filter((_, index) => {
      return index >= (page - 1) * paginationPerPage && index < paginationPerPage * page;
    });

    if (visibleItems && visibleItems.length) {
      setVisibleItemsInPage(visibleItems);
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    if (TableContainerResizeObserver.current && tableContainerRef.current) {
      TableContainerResizeObserver.current.observe(tableContainerRef.current);
    }

    return () => {
      if (
        TableContainerResizeObserver.current &&
        TableContainerResizeObserver.current.unobserve &&
        tableContainerRef.current
      ) {
        TableContainerResizeObserver.current.unobserve(tableContainerRef.current);
      }
    };
  }, [TableContainerResizeObserver.current, tableContainerRef.current]);

  useEffect(() => {
    if (rest.pagination && !paginationServer) {
      onChangePage(1);
    } else {
      setVisibleItemsInPage(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.paginationPerPage, rest.pagination, data]);

  useEffect(() => {
    if (currentPage !== activePage && activePage) {
      setCurrentPage(activePage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    if (isLoading) {
      tableSkeletonTimerRef.current = setTimeout(
        () => setDisplaySkeletonLoader(true),
        300
      ) as unknown as number;
    } else {
      if (tableSkeletonTimerRef.current) {
        clearTimeout(tableSkeletonTimerRef.current);
      }

      setDisplaySkeletonLoader(false);
    }
  }, [isLoading]);

  return (
    <OuterTableContainer>
      <ScreenSize data-testid="table-desktop-view" tablet desktop desktopXL>
        {headerLabel ? (
          <HeaderContainer>
            <Header>{headerLabel}</Header>
            {headerActions}
          </HeaderContainer>
        ) : null}
        <TableContainer
          ref={tableContainerRef}
          sortedBy={sortedBy}
          clickable={Boolean(onRowClicked) || Boolean(rest.expandOnRowClicked)}
        >
          <DataTable
            persistTableHead
            onRowClicked={onRowClicked}
            paginationDefaultPage={currentPage}
            paginationResetDefaultPage
            noDataComponent={null}
            sortIcon={<SortArrows />}
            responsive={true}
            data={data}
            columns={columns}
            conditionalRowStyles={conditionalRowStyles}
            dense
            customStyles={customStyles}
            paginationComponent={Paginator}
            //NOTE there is a bug in ts definitions for table library, selectableRowsComponent requires incorrect type
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            selectableRowsComponent={SelectableRowCheckbox}
            {...rest}
            pagination={pagination}
            paginationServer={paginationServer}
            paginationTotalRows={totalRowsCount}
            onChangePage={rest.onChangePage || onChangePage}
            progressPending={false}
          />
          <NoDataContainer
            data-testid="table-no-data-container"
            minNoDataContainerHeight={minNoDataContainerHeight}
            visible={!data?.length && !isLoading}
            ref={noDataContainerRef}
            headerHeight={headerHeight}
          >
            <SecondaryText>{noDataMessage}</SecondaryText>
          </NoDataContainer>
          <TableSkeletonContainer visible={displaySkeletonLoader} ref={loaderContainerRef}>
            <TableSkeleton
              visible={displaySkeletonLoader}
              rowCount={data?.length || rest.paginationPerPage}
              ref={tableSkeletonRef}
            />
          </TableSkeletonContainer>
        </TableContainer>
      </ScreenSize>
      <ScreenSize data-testid="table-mobile-view" mobile display="flex" fullWidth>
        <MobileTableList<T>
          collapsible={collapsible}
          data={data}
          headerLabel={headerLabel || ''}
          selectAllCheckboxLabel={selectAllCheckboxLabel || ''}
          headerSelectAllExplanationLabel={headerSelectAllExplanationLabel || ''}
          headerActions={headerActions}
          visibleItemsInPage={visibleItemsInPage}
          onRowClicked={onRowClicked}
          columns={columns}
          mobileColumns={mobileColumns}
          pagination={pagination}
          totalRowsCount={totalRowsCount}
          loading={isLoading}
          noDataMessage={noDataMessage}
          currentPage={currentPage}
          onChangePageHandler={onChangePage}
          setCurrentPage={setCurrentPage}
          {...rest}
        />
      </ScreenSize>
    </OuterTableContainer>
  );
}

export default Table;
