import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Tooltip, ResponsiveContainer, XAxis, YAxis, TooltipProps } from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';

import { AmountText, Currency, RegularText } from 'components';
import {
  formatDateToCustomString,
  formatFullDate,
  formatFullMonthAndYearOnly,
  formatShortMonth
} from 'components/datePicker/utils';
import { formatNumber } from 'components/text/formatters';
import { lightTheme } from 'design/systemDesign';
import { Periodicity } from 'services/ContractsService/types';

import { ChartContainer, CustomTooltipContainer } from './waveChart.styles';

type Item = {
  amount?: number;
  date?: string;
};

type Props = {
  data?: Item[];
  dataLabel: string;
  currency: Currency | string;
  periodicity: Periodicity;
  onPointClick: (state: CategoricalChartState) => unknown;
};

const getYTicks = (data?: Item[]) => {
  if (data) {
    const maxAmount = Math.max(...data.map((item) => item.amount || 0));

    return [0, Math.floor(maxAmount / 2), maxAmount];
  }

  return [0, 0];
};

const CustomTooltip: FC<
  TooltipProps<keyof Item, keyof Item> & {
    periodicity: Periodicity;
    dataLabel: string;
  }
> = ({ payload, periodicity, dataLabel }) => {
  const { t, i18n } = useTranslation();

  const tooltipFormats = {
    TOOLTIP_MONTH: formatFullMonthAndYearOnly(i18n.resolvedLanguage),
    TOOLTIP: formatFullDate(i18n.resolvedLanguage)
  };

  if (payload) {
    const legend = payload[0];

    return (
      <CustomTooltipContainer>
        <RegularText>
          {periodicity === 'MONTH'
            ? tooltipFormats.TOOLTIP_MONTH(legend?.payload.date)
            : tooltipFormats.TOOLTIP(legend?.payload.date)}
        </RegularText>
        <span>
          <RegularText>{`${t(dataLabel)}: `}</RegularText>
          <AmountText>{legend?.payload.amount}</AmountText>
        </span>
      </CustomTooltipContainer>
    );
  }

  return null;
};

export const WaveChart: FC<Props> = ({ data, dataLabel, periodicity, onPointClick }) => {
  const { i18n } = useTranslation();
  const { background } = lightTheme.colors.primary.chart;
  const { textColor } = lightTheme.colors.secondary;
  const white = '#FFFFFF';

  const cursorStyle = { stroke: white, strokeWidth: 1 };
  const axisStyle = { fill: textColor, fontFamily: 'Inter' };

  const dotHandler = (event: { cx: number; cy: number }) => {
    const { cx, cy } = event;

    return <circle opacity={1} cx={cx} cy={cy} r={4} strokeWidth={1} stroke={background} fill={white} />;
  };

  const formatCurrency = formatNumber(i18n.resolvedLanguage);

  const formatYaxisTicks =
    periodicity === 'MONTH' ? formatShortMonth(i18n.resolvedLanguage) : formatDateToCustomString('d');

  return (
    <ChartContainer>
      <ResponsiveContainer width="99%" height="100%" minWidth={240} minHeight={50}>
        <AreaChart
          data={data}
          margin={{
            top: 20,
            left: 0,
            right: 0
          }}
          onClick={onPointClick}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={background} stopOpacity={0.4} />
              <stop offset="95%" stopColor={background} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            mirror
            dataKey="date"
            stroke="0"
            tickFormatter={formatYaxisTicks}
            style={axisStyle}
            interval="preserveStartEnd"
          />
          <YAxis
            tick={{ dx: -8 }}
            ticks={getYTicks(data)}
            mirror
            stroke="0"
            tickFormatter={(value) => formatCurrency(value, 0)}
            style={axisStyle}
            domain={['dataMin', (dataMax: number) => Math.floor(dataMax)]}
            interval="preserveStartEnd"
            width={200}
            padding={{
              bottom: 40
            }}
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="amount"
            fill="url(#colorUv)"
            stroke="0"
            activeDot={dotHandler}
            animationDuration={300}
          />
          <Tooltip
            cursor={cursorStyle}
            content={<CustomTooltip periodicity={periodicity} dataLabel={dataLabel} />}
          />
        </AreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};
