import styled, { css } from 'styled-components';

import { Typography } from 'components/typography';

export const RegularText = styled.span`
  color: ${({ theme }) => theme.colors.primary.textColor};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 1.25rem;
`;

export const SecondaryText = styled(RegularText)`
  color: ${({ theme }) => theme.colors.secondary.textColor};
`;

type SecondaryValueTextProps = {
  active: boolean;
};
export const SecondaryValueText = styled.div<SecondaryValueTextProps>`
  display: flex;
  ${({ active, theme }) => (active ? `color: ${theme.colors.secondary.buttonText}` : '')};

  & span {
    ${({ active, theme }) => (active ? `color: ${theme.colors.secondary.buttonText}` : '')};
  }
`;

export const ErrorText = styled(RegularText)`
  color: ${({ theme }) => theme.colors.status.error};
`;

export const HelpText = styled.span`
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 1%;
`;

export const Percentage = styled.span`
  font-size: 0.875rem;
  line-height: 1.375rem;

  * {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export type InputViewContainerProps = {
  orientation?: 'horizontal' | 'vertical';
  orientationMobile?: 'horizontal' | 'vertical';
};

export const InputViewLabel = styled(Typography.InputViewLabel)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const InputViewLabelMobile = styled(InputViewLabel)``;

export const InputViewText = styled(Typography.Input)`
  display: inline-flex;
`;

export const InputViewTextMobile = styled(InputViewText)``;

const returnAlignment = (orientation: InputViewContainerProps['orientation']) => {
  return css`
    flex-direction: ${orientation === 'horizontal' ? 'row' : 'column'};
    justify-content: ${orientation === 'horizontal' ? 'space-between' : 'center'};
    align-items: ${orientation === 'horizontal' ? 'center' : 'flex-start'};
  `;
};

export const InputViewContainer = styled.div<InputViewContainerProps>`
  display: flex;
  width: 100%;
  ${({ orientation }) => returnAlignment(orientation)};

  ${InputViewLabel} {
    margin-bottom: ${({ orientation }) => (orientation === 'horizontal' ? 0 : '0.125rem')};
  }

  ${InputViewLabelMobile} {
    display: none;
  }

  ${InputViewTextMobile} {
    display: none;
  }

  @media ${({ theme }) => theme.media.maxTablet} {
    ${({ orientationMobile }) => returnAlignment(orientationMobile)};

    ${InputViewLabel} {
      margin-bottom: ${({ orientationMobile }) => (orientationMobile === 'horizontal' ? 0 : '0.125rem')};
    }

    ${InputViewLabel} {
      display: none;
    }

    ${InputViewText} {
      display: none;
    }

    ${InputViewLabelMobile} {
      display: inline-flex;
    }

    ${InputViewTextMobile} {
      display: inline-flex;
    }
  }
`;
