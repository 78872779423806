import { useEffect, useState } from 'react';

import { Status } from 'features/applications/types/applicationsTypes';
import { useCheckForApplicationStatusQuery } from 'features/onboarding/api';
import { ApplicationItem } from 'services/CapService/types';
import { OndatoStatusResponse } from 'services/OnboardingService/types';

import {
  UseGetCurrentStepStateReturn,
  default as useGetCurrentCAPStepState
} from './useGetCurrentCAPStepState';
import useGetOnboardingEnabledConfig from './useGetOnboardingEnabledConfig';

type UseCurrentFlowStepReturn = {
  ondatoInfo: OndatoStatusResponse;
  flowStatus: ReturnType<typeof useGetCurrentCAPStepState>;
};

const ondatoReadyCapStatuses: Status[] = [
  'OFFER_ACCEPTED',
  'KYC_WAITING',
  'KYC_NOT_REQUIRED',
  'KYC_COMPLETED',
  'CONTRACT_PREPARED',
  'CONTRACT_PREPARATION_IN_PROGRESS',
  'CONTRACT_PREPARATION_FAILED',
  'CONTRACT_SIGNED'
];

const ondatoCompletedStatuses: OndatoStatusResponse['status'][] = [
  'ONDATO_FINISHED',
  'SME_CUSTOMER_ONBOARDING_NOT_FOUND',
  'SME_CUSTOMER_ONBOARDING_WAITING',
  'SME_CUSTOMER_ONBOARDING_REJECTED',
  'SME_CUSTOMER_ONBOARDING_IN_PROGRESS',
  'SME_CUSTOMER_ONBOARDING_COMPLETED'
];

const ondatoNotReadyLocalCAPStatuses: Status[] = ['NA', 'INITIAL'];

const ondatoNotReadyGoCreditsCAPStatuses: Status[] = [
  'ASSESSMENT_ONGOING',
  'SUBMITTED_FOR_VOTING',
  'OFFER_SENT',
  'ADDITIONAL_INFORMATION_NEEDED',
  'ADDITIONAL_INFO_SUBMITTED',
  'OFFER_REJECTED',
  'KYC_REJECTED',
  'KYC_NOT_REQUIRED'
];

export const ondatoNotReadyCAPStatuses: (Status | OndatoStatusResponse['status'])[] = [
  ...ondatoNotReadyLocalCAPStatuses,
  ...ondatoNotReadyGoCreditsCAPStatuses,
  ...ondatoReadyCapStatuses
];

export const isReadyForOndato = (
  status: Status | OndatoStatusResponse['status'],
  ondatoAvailableFrom: Status
) => {
  const currentStatusIndex = ondatoNotReadyCAPStatuses.indexOf(status ?? 'INITIAL');
  const ondatoAvailableFromIndex = ondatoNotReadyCAPStatuses.indexOf(ondatoAvailableFrom);

  return currentStatusIndex >= ondatoAvailableFromIndex;
};

const useCheckForOndatoReady = (currentStepConfig: UseGetCurrentStepStateReturn) => {
  const { ondatoAvailableCAPStatus, isParallelFlowEnabled: parallelFlowEnabled } =
    useGetOnboardingEnabledConfig();

  const isOndatoReady = isReadyForOndato(
    currentStepConfig[1]?.acceptOffer ?? 'NOT_FILLED',
    ondatoAvailableCAPStatus
  );

  const [currentStep, currentStepStatus] = currentStepConfig ?? [null, null];

  if (parallelFlowEnabled) {
    return currentStepStatus.acceptOffer && isOndatoReady;
  }

  return (
    currentStep === 'verifyYourIdentity' &&
    currentStepStatus.acceptOffer &&
    ondatoReadyCapStatuses.includes((currentStepStatus?.acceptOffer ?? 'NOT_FILLED') as Status)
  );
};

const useCurrentFlowStep = ({ application }: { application: ApplicationItem }): UseCurrentFlowStepReturn => {
  const resolvedApplicationId = application?.applicationId ?? '';

  const applicationStatus = (application?.status ?? 'NOT_FILLED') as Status;

  const [currentStep, currentStepStatus] = useGetCurrentCAPStepState({
    status: applicationStatus
  });

  const [flowStatus, setFlowStatus] = useState([
    currentStep,
    currentStepStatus
  ] as UseCurrentFlowStepReturn['flowStatus']);

  const readyForOndato = useCheckForOndatoReady([currentStep, currentStepStatus]);

  const uninitializedOndatoInfo: OndatoStatusResponse = {
    ondatoId: '',
    status: undefined
  };

  const { currentData: applicationInfo = uninitializedOndatoInfo } = useCheckForApplicationStatusQuery(
    { applicationId: resolvedApplicationId },
    {
      skip: !readyForOndato || !resolvedApplicationId
    }
  );

  const resolvedApplicationInfo =
    applicationInfo.applicationId === resolvedApplicationId ? applicationInfo : uninitializedOndatoInfo;

  useEffect(() => {
    if (resolvedApplicationInfo?.status) {
      setFlowStatus(([, previousStatus]) => {
        const ondatoFinished = ondatoCompletedStatuses.includes(resolvedApplicationInfo?.status);

        const newCurrentStep = ondatoFinished ? 'verifyYourBusiness' : currentStep;

        const newFlowStatus: UseCurrentFlowStepReturn['flowStatus'] = [
          newCurrentStep,
          {
            ...previousStatus,
            verifyYourIdentity: ondatoFinished ? 'ONDATO_FINISHED' : resolvedApplicationInfo?.status,
            verifyYourBusiness:
              resolvedApplicationInfo?.status === 'ONDATO_FINISHED'
                ? 'SME_CUSTOMER_ONBOARDING_NOT_FOUND'
                : resolvedApplicationInfo?.status
          }
        ];

        return newFlowStatus;
      });
    } else {
      setFlowStatus([currentStep, currentStepStatus]);
    }
  }, [resolvedApplicationId, applicationInfo?.applicationId, resolvedApplicationInfo?.status, currentStep]);

  return { ondatoInfo: applicationInfo, flowStatus };
};

export default useCurrentFlowStep;
