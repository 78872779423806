import { useTranslation } from 'react-i18next';

import Icons from 'assets/icons';
import { AsideMenuTopBar } from 'components';
import { Button } from 'components/buttonV2';
import { useNavContext } from 'features/nav/context/navContext';

import {
  Content,
  ContentBody,
  ContentHeader,
  InformationalDrawerContent
} from './infromationReviewDrawer.styles';

const InformationReviewDrawer = () => {
  const { t } = useTranslation();

  const { hideSideDrawer } = useNavContext();

  const handleClose = () => {
    hideSideDrawer?.();
  };

  return (
    <>
      <AsideMenuTopBar handleClose={handleClose} />
      <InformationalDrawerContent>
        <Content>
          <Icons.TeaCupLarge />
          <ContentHeader>{t('relaxWeAreReviewingYourApplication')}</ContentHeader>
          <ContentBody type="L">{t('theseStepsWillBeAvailableAfterReview')}</ContentBody>
        </Content>
        <Button media={{ maxPhone: { size: 'L' } }} onClick={handleClose}>
          {t('gotIt')}
        </Button>
      </InformationalDrawerContent>
    </>
  );
};

export default InformationReviewDrawer;
