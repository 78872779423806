import styled from 'styled-components';

import { colors } from 'design/designVariables';

import { ErrorMessage } from '../formError';

const radioCheckedColor = colors.base.primary.shades[100];
const radioUncheckedColor = colors.base.black.tints[30];
const radioDisableColor = '#f7f7f7';
const radioSize = '1.125rem';
const radioRippleSize = '8px';

type FakeRadioButtonProps = {
  checked?: boolean;
  disabled?: boolean;
};

type RadioButtonGroupProps = {
  variant: 'row' | 'column';
  invalid?: boolean;
};

export const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

export const FakeRadioButton = styled.span<FakeRadioButtonProps>`
  box-sizing: border-box;
  width: ${radioSize};
  height: ${radioSize};
  ${({ checked }) =>
    checked ? `border: 1px solid ${radioCheckedColor}` : `border: 1px solid ${radioUncheckedColor}`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ disabled }) => (disabled ? `border-color: ${radioDisableColor}` : '')};

  &::after {
    width: ${radioRippleSize};
    height: ${radioRippleSize};
    content: '';
    border-radius: 50%;
    ${({ checked }) => (checked ? `background-color: ${radioCheckedColor}` : '')};
    ${({ checked, disabled }) => (checked && disabled ? `background-color: ${radioDisableColor}` : '')};
    ${({ checked }) => (checked ? `transform: scale(1)` : 'transform: scale(0)')};
    transition: all 0.3s ease;
    transition-property: transform, border-color;
  }
`;

export const RadioButtonContainer = styled.label<{ invalid?: boolean; checked?: boolean }>`
  margin: 0;
  display: flex;
  align-items: center;

  ${FakeRadioButton} {
    border: ${({ invalid, checked, theme }) =>
      invalid
        ? `2px solid ${theme.colors.base.red.shades[100]}`
        : checked
          ? `2px solid ${radioCheckedColor}`
          : `2px solid ${radioUncheckedColor}`};
  }
`;

export const RadioButtonGroup = styled.div<RadioButtonGroupProps>`
  display: flex;
  flex-direction: ${({ variant }) => variant};

  ${RadioButtonContainer} {
    padding: ${({ variant }) => (variant === 'row' ? '0 1rem 0 0' : 0)};
  }

  ${FakeRadioButton} {
    border: ${({ invalid, theme }) =>
      invalid ? `1px solid ${theme.colors.status.error}` : `1px solid ${radioCheckedColor}`};
  }

  & + ${ErrorMessage} {
    padding-top: 0.5rem;
  }
`;
