import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icons from '../../assets/icons';
import { LinkButton } from '../link';

import { CopyrightLinkText, CopyrightLinksContainer, Elipsis } from './copyright.styles';

type Props = {
  className?: string;
};

export const CopyrightLinks: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  const showCookieSettings = () => {
    window.Cookiebot.renew();
  };

  return (
    <CopyrightLinksContainer className={className}>
      <a target="blank" href={'https://smego.com/privacy-policy/'} rel="noopener">
        <CopyrightLinkText>
          {t('privacyPolicy')}
          <Icons.NewTab />
        </CopyrightLinkText>
      </a>
      <Elipsis />
      <LinkButton onClick={showCookieSettings}>
        <CopyrightLinkText>{t('cookieSettings')}</CopyrightLinkText>
      </LinkButton>
    </CopyrightLinksContainer>
  );
};
