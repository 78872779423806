import styled from 'styled-components';

import { maxWidthStyle } from 'components/page/page.styles';

export const RightAlignedContainer = styled.div`
  height: 4.5rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const LeftAlignedContainer = styled(RightAlignedContainer)`
  justify-content: flex-start;
`;

export const SpacedBetweenContainer = styled(RightAlignedContainer)`
  justify-content: end;

  @media ${({ theme }) => theme.media.maxTablet} {
    justify-content: flex-end;
  }
`;

export const NavContent = styled.div`
  font-size: 1rem;
  display: flex;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.layers.overlay};
  display: flex;
  height: 4.5rem;
  padding: 0 3rem;
  margin: 0 auto;
  ${maxWidthStyle}

  @media ${({ theme }) => theme.media.maxDesktop} {
    padding: 0 1rem;
  }
`;

export const HeaderStyle = styled.header`
  height: 4.5rem;
  margin-inline: -1.5rem;
  padding-inline: 1.5rem;
  max-width: ${({ theme }) => theme.pageContentLayouts.maxWidth};
  min-width: ${({ theme }) => theme.pageContentLayouts.minWidth};
  width: calc(100% + 3rem);
  background-color: ${({ theme }) => theme.colors.primary.background};

  @media ${({ theme }) => theme.media.maxDesktop} {
    margin-inline: -1rem;
    padding-inline: 1rem;
    width: calc(100% + 2rem);
  }
`;

export const UserCompanySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.5rem;
  max-width: 7.9375rem;
`;

export const FullUserName = styled.div`
  color: ${({ theme }) => theme.colors.secondary.textColor};
  size: 0.75rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
`;

export const CompanyName = styled.div`
  color: ${({ theme }) => theme.colors.primary.textColor};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  line-height: 1.25rem;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
`;

export const LanguageSelectorContainer = styled.div`
  padding-inline: 0.625rem;
`;

export const MenuBurgerContainer = styled.div`
  display: flex;
  align-items: center;
`;
