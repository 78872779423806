import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Loader } from 'components';
import { selectRedirectSessionId } from 'features/auth/selectors';
import { checkStatusEIDeasyAsync, completeEIDeasyAsync } from 'features/auth/slice';
import { useAppDispatch, useAuthSelector } from 'hooks/redux/hooks';
import { RequestCompleteEIDeasy, RequestPollEIDeasy } from 'services/LoginService/typings';

export const RedirectLogin = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams({ code: '', state: '' });

  const sessionId = useAuthSelector<typeof selectRedirectSessionId>(selectRedirectSessionId);

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const completeLogin = async (onComplete: () => unknown) => {
    const data: RequestCompleteEIDeasy = {
      code: code || '',
      state: state || ''
    };

    await dispatch(completeEIDeasyAsync(data)).unwrap();
    onComplete();
  };

  const checkForStatus = async () => {
    const data: RequestPollEIDeasy = {
      sessionId: sessionId || ''
    };

    try {
      await dispatch(checkStatusEIDeasyAsync(data)).unwrap();

      navigate('/dashboard/overview', { replace: true });
    } catch {
      navigate('/login', { replace: true });
    }
  };

  useEffect(() => {
    if (code && state) {
      completeLogin(checkForStatus);
    } else {
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <>
      <Loader />
    </>
  );
};
