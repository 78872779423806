import styled from 'styled-components';

import { Typography } from 'components';

export const LobbbyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
  max-width: 31rem;
  width: 100%;
  margin-top: min(18.75rem, calc(50vh - 8.25rem));
`;

export const LobbyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const LobbyHeaderText = styled(Typography.Header).attrs({ type: 'h4', weight: 'medium' })`
  text-align: center;
`;

export const LobbyText = styled(Typography.Body).attrs({ type: 'S' })`
  color: ${({ theme }) => theme.colors.base.black.tints[50]};
  text-align: center;
`;
