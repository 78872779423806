import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Layout } from 'components/pageV2';
import { DrawersSwitch, SideNavigation } from 'features/app/components';
import { OfferStatusModal, ProvideInformationModal } from 'features/applications/components';
import OpenBankExplanationDrawer from 'features/applications/components/openBankExplanationDrawer/openBankExplanationDrawer';
import { useNavContext } from 'features/nav/context/navContext';
import { PageNotFound } from 'pages/error';
import VerificationWrapper from 'pages/verification/verificationWrapper';

import { ApplicationsOverview } from './applications';
import { Insights } from './insights';
import { Overview } from './overview';

const renderPageContents = (subCategory: string) => {
  switch (subCategory) {
    case 'overview':
      return <Overview />;
    case 'applications':
      return <ApplicationsOverview />;
    case 'insights':
      return <Insights />;
    case 'verification':
      return <VerificationWrapper />;
    default:
      return null;
  }
};

export const MyDashboard: FC = () => {
  const { subCategory = 'overview' } = useParams();

  const [isOpenBankAccountExplanationDrawerOpen, setIsOpenBankAccountExplanationDrawerOpen] = useState<{
    inner: boolean;
    isOpen: boolean;
  }>({
    inner: false,
    isOpen: false
  });

  const { showRightSideDrawer, hideSideDrawer } = useNavContext();

  const closeRolesDrawer = () => setIsOpenBankAccountExplanationDrawerOpen({ inner: false, isOpen: false });

  const PageContent = renderPageContents(subCategory);

  if (!PageContent) {
    return <PageNotFound />;
  }

  return (
    <Layout
      onDrawerStateChange={(visible) => {
        if (!visible) {
          closeRolesDrawer();
          hideSideDrawer?.();
        }
      }}
      sideNavContent={<SideNavigation />}
      asideMenuContent={<DrawersSwitch toggleBankAccountDrawer={setIsOpenBankAccountExplanationDrawerOpen} />}
      rightSideDrawerOpen={showRightSideDrawer}
    >
      <ProvideInformationModal />
      <OpenBankExplanationDrawer
        open={isOpenBankAccountExplanationDrawerOpen.isOpen}
        onClose={closeRolesDrawer}
        inner={isOpenBankAccountExplanationDrawerOpen.inner}
      />
      <OfferStatusModal
        openBankAccountDrawer={() =>
          setIsOpenBankAccountExplanationDrawerOpen({ inner: false, isOpen: true })
        }
      />
      {PageContent}
    </Layout>
  );
};

export default MyDashboard;
