import styled from 'styled-components';

import { Typography } from 'components/typography';

import { LinkButtonStyle } from '../link/link.styles';
import { RegularText, SecondaryText } from '../text';

type ChildrenContainerProps = {
  display: boolean;
};

export const ChildrenContainer = styled.div<ChildrenContainerProps>`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const AuthenticationDialogContainer = styled.div`
  max-width: 28.25rem;
  width: 100%;
`;

export const SuccessDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 7.375rem;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  border-radius: 0.25rem;
  padding-top: 1.6875rem;
`;

export const Verifcode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const VerifcodeText = styled(RegularText)`
  font-size: 3rem;
  line-height: 3.625rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.linkText};
  position: relative;
  letter-spacing: 0.3rem;
`;

export const TimerText = styled(Typography.Body).attrs({ type: 'S', weight: 'normal' })`
  font-size: 0.75rem;
  line-height: 1.25rem;

  b {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

export const VerificationForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const VerificationCodeStyle = styled.div`
  box-sizing: border-box;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  padding: 1rem;
  text-align: center;
  position: relative;
  border-top-left-radius: 0.25rem;
  min-height: 7.375rem;
  border-top-right-radius: 0.25rem;
`;

export const LinkBackStyles = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${SecondaryText} {
    margin-bottom: 0.9375rem;
  }

  ${LinkButtonStyle} {
    font-size: ${({ theme }) => theme.fontSizes.h5};
    color: ${({ theme }) => theme.colors.base.black.faded};
    margin-bottom: 1.75rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      path {
        fill: currentColor;
      }
    }
  }
`;

export const HeaderLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${SecondaryText} {
    margin-bottom: 0.9375rem;
    color: ${({ theme }) => theme.colors.base.black.faded};
    font-size: ${({ theme }) => theme.fontSizes.h5};
  }
`;

export const Header = styled.h2`
  width: 100%;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.h2};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.primary.headerColor};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoMessage = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.h6};
  margin-top: 0.75rem;
  color: ${({ theme }) => theme.colors.primary.infoTextColor};
`;

export const DigitsLoader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.625rem;
  min-height: 3.625rem;
`;

export const DigitFrame = styled.div`
  width: 1.5938rem;
  height: 2.2813rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.base.black.shades[5]};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.base.black.shades[5]} 0%,
    #f6f7f8 20%,
    #f6f7f8 80%,
    ${({ theme }) => theme.colors.base.black.shades[5]} 100%
  );
  background-repeat: no-repeat;
  background-size: 2rem 3rem;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholdershimmer;
  animation-timing-function: linear;

  @keyframes placeholdershimmer {
    0% {
      background-position: -2rem 0;
    }

    100% {
      background-position: 2rem 0;
    }
  }
`;
