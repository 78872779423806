const capitalize = (languageName: string) => languageName.charAt(0).toUpperCase() + languageName.slice(1);

export const getLanguageDisplayName = (
  countryIsoCode: string,
  selectedLanguage: 'lt' | 'en',
  alwaysCapitalize = true
) => {
  try {
    const languageNames = new Intl.DisplayNames([selectedLanguage], { type: 'language' });

    const languageName = languageNames.of(countryIsoCode);

    if (!languageName) {
      return countryIsoCode;
    }

    return alwaysCapitalize ? capitalize(languageName) : languageName;
  } catch {
    //fail silently
  }
};
