import { Column, Grid } from 'components';
import { CreditLineContractDetails, CreditLineLimitsDetails } from 'features/loans/components';
import { DocumentsReview } from 'features/loans/components/documentsReview/documentsReview';
import { SchedulesTable } from 'features/loans/tables';

import { Row, FlexColumn } from '../factoring/contracts.styles';

export const CreditLineContracts = () => {
  return (
    <Grid>
      <Column rowStart={1} spanRow={3} colStart={1} span={12}>
        <Row>
          <FlexColumn>
            <CreditLineContractDetails />
          </FlexColumn>
          <FlexColumn>
            <CreditLineLimitsDetails />
            <DocumentsReview />
          </FlexColumn>
        </Row>
      </Column>
      <Column colStart={1} span={12} colStartMd={1} spanMd={12} rowStartMd={4} spanRowMd={4}>
        <SchedulesTable />
      </Column>
    </Grid>
  );
};
