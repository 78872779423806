import { FC, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RegularText, RadioButton, AmountText, StatusChip, Table } from 'components';
import { StatusType } from 'design/designVariables';
import { ContractInfo } from 'services/ContractsService/types';
import { camelCase } from 'utils';

import { ContractsTableContainer } from './contracts.styles';

type Props = {
  items?: ContractInfo[];
  loading?: boolean;
  firstItemSelectedByDefault?: boolean;
  onRowClick?: (contractId: string) => unknown;
};

type StatusToChipColorMap = Record<Required<ContractInfo>['status'], keyof StatusType>;

const statusToChipColorMap: StatusToChipColorMap = {
  PREPARED: 'gray',
  READY_FOR_SIGNING: 'gray',
  ACTIVE: 'green',
  MANUALLY_SUSPENDED: 'red',
  EXPIRED: 'yellow'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withNullCheck = (value: any) => value || '-';

const convertStatusToNumber = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return 0;
    case 'MANUALLY_SUSPENDED':
      return 10;
    case 'EXPIRED':
      return 20;
    default:
      return 30;
  }
};

export const FactoringContracts: FC<Props> = ({
  items,
  loading,
  onRowClick,
  firstItemSelectedByDefault = true
}) => {
  const [searchParams, setSearchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const [selectedContractId, setSelectedContractId] = useState(contractId);

  const { t } = useTranslation();

  const mappedItems = items?.map((item) => ({
    ...item,
    toggleSelected: Boolean(selectedContractId && item.id === selectedContractId)
  }));

  const sortedItems = mappedItems?.sort((itemA, itemB) => {
    const { status: statusA } = itemA;
    const { status: statusB } = itemB;

    return convertStatusToNumber(statusA || '') - convertStatusToNumber(statusB || '');
  });

  const hasContracts = sortedItems && sortedItems.length;

  useEffect(() => {
    if (hasContracts) {
      if (selectedContractId && !contractId) {
        searchParams.set('contractId', selectedContractId);
        setSearchParams(searchParams, { replace: true });
      } else if (firstItemSelectedByDefault) {
        if (!selectedContractId && !contractId) {
          const firstContract = sortedItems[0];
          const firstContractsId = firstContract?.id;

          if (firstContractsId) {
            searchParams.set('contractId', firstContractsId);
            setSearchParams(searchParams, { replace: true });
            setSelectedContractId(firstContractsId);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContractId, contractId, hasContracts, searchParams, setSearchParams]);

  const columnsWithoutStatus: TableColumn<ContractInfo>[] = [
    {
      cell: (row) => (
        <RadioButton
          name="contracts"
          value={row.id}
          data-testid={`contracts-table-checkbox-${row.id}`}
          checked={row.id === contractId}
          defaultValue={''}
          onChange={() => handleRowClicked(row)}
        />
      )
    },
    {
      selector: (row) => withNullCheck(row.factoringProduct),
      name: t('type'),
      format: (row) => <RegularText>{t(camelCase(row.factoringProduct || ''))}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.contractNumber),
      name: t('number'),
      format: (row) => <RegularText>{row.contractNumber}</RegularText>
    },
    {
      selector: (row) => row.factorName,
      name: t('financier'),
      format: (row) => <RegularText>{row.factorName}</RegularText>
    },
    {
      selector: (row) => withNullCheck(row.factoringCreditLimit),
      name: t('total'),
      format: (row) => (
        <RegularText>
          <AmountText>{row.factoringCreditLimit}</AmountText>
        </RegularText>
      )
    },
    {
      selector: (row) => withNullCheck(row.availableFactoringCreditLineAmount),
      name: t('available'),
      format: (row) => (
        <RegularText>
          <AmountText>{row.availableFactoringCreditLineAmount}</AmountText>
        </RegularText>
      )
    }
  ];

  const columnsWithStatus: TableColumn<ContractInfo>[] = [
    ...columnsWithoutStatus,
    {
      selector: (row) => withNullCheck(row.status),
      name: t('status'),
      id: 'status',
      format: (row) => {
        if (row.status) {
          return (
            <StatusChip color={statusToChipColorMap[row.status]}>
              <RegularText>{t(camelCase(row.status))}</RegularText>
            </StatusChip>
          );
        }

        return '-';
      }
    }
  ];

  const handleRowClicked = (row: ContractInfo) => {
    setSelectedContractId(row.id || '');
    onRowClick?.(row.id || '');
    setSearchParams({ contractId: row.id || '' }, { replace: true });
  };

  return (
    <ContractsTableContainer data-testid="bills-contracts-table">
      <Table
        noDataMessage={t('noListItems')}
        progressPending={loading}
        columns={columnsWithStatus}
        data={sortedItems || []}
        highlightOnHover
        onRowClicked={handleRowClicked}
      />
    </ContractsTableContainer>
  );
};
