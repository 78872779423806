import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPanel, Paper, Section, SectionLayout } from 'components';
import {
  BillsFilterParams,
  loansBillsFilterDefaultParams,
  LoansBillsFilterFields
} from 'features/loans/components';
import { useBillsFilteredData } from 'features/loans/components/loansBillsFilterFields';
import { useFetchLoanInvoices } from 'features/loans/hooks';
import { LoansBillsTable } from 'features/loans/tables';
import { sortedItemsByDate } from 'utils';

export const RbfBills = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useFetchLoanInvoices();

  const [selectedPage, setSelectedPage] = useState(1);

  const [filteredItems, appliedFilterParams, setAppliedFilterParams] = useBillsFilteredData(
    data.loanInvoicesData
  );

  const sortedActsItems = sortedItemsByDate(filteredItems, 'issueDate');

  const onChangePage = (pageNumber: number) => {
    setSelectedPage(pageNumber);
  };

  const onFilter = useCallback(
    (params: BillsFilterParams) => {
      setAppliedFilterParams(params);
    },
    [data]
  );

  return (
    <SectionLayout>
      <Paper>
        <Section header={t('bills')}>
          <FilterPanel
            FilterFields={LoansBillsFilterFields}
            defaultParams={loansBillsFilterDefaultParams}
            appliedParams={appliedFilterParams}
            onFilterParamsChange={onFilter}
          />
        </Section>
        <Section>
          <LoansBillsTable
            activePage={selectedPage}
            loading={isLoading}
            items={sortedActsItems}
            onChangePage={onChangePage}
          />
        </Section>
      </Paper>
    </SectionLayout>
  );
};
