import { CreateApplicationResponse } from 'services/CapService/types';

export enum FINANCIAL_PRODUCT {
  FACTORING = 'FACTORING_PRODUCT',
  BUSINESS_LOANS = 'BUSINESS_LOANS_PRODUCT',
  LEASING = 'LEASING_PRODUCT',
  RBF = 'RBF_PRODUCT'
}

export enum STEPS {
  PRODUCT_TYPE = 'productType',
  SUBPRODUCT_TYPE = 'subproductType',
  AMOUNT = 'amount',
  REGISTRATION_COUNTRY = 'registrationCountry',
  COMPANY_OR_FARM = 'companyOrFarm',
  FARM_CODE = 'farmCode',
  FARMERS_DETAILS = 'farmersDetails',
  COMPANY = 'company',
  CONTACT_PERSON = 'contactPerson',
  FACTORING_TYPE = 'factoringType',
  LOAN = 'loan',
  BENEFICIAL_OWNERS = 'beneficialOwners',
  THIRD_PARTIES = 'thirdParties',
  TERMS = 'terms',
  PURPOSE = 'purpose',
  BANK_ACCOUNTS = 'bankAccounts',
  REVIEW = 'review',
  PRICE_AND_PAYMENT_DETAILS = 'priceAndPaymentDetails',
  SUCCESS_SUBMIT = 'successSubmit'
}

export interface ApplicationState {
  navigateTo?: STEPS;
  isLoading: boolean;
  loadFailed: boolean;
  activeStep: STEPS;
  completionIndex: number;
  application: CreateApplicationResponse;
  sequence: STEPS[];
  newCompany?: boolean;
  isFarm?: boolean;
}
