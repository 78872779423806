import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { Field, Input } from 'components';
import { SupportedCountries } from 'features/auth/types/authTypes';
import { useFormHandlers } from 'hooks/forms/useFormHandlers';
import { emailRequired } from 'validators';

import { FormSubheading, FormHeading, Form, FormButton } from './emailForm.styles';

export type LoginInfo = {
  email: string;
  country: SupportedCountries;
};

type Props = {
  formHeading?: string;
  formSubheading?: string;
  inputPlaceholder: string;
  buttonLabel: string;
  onSubmit: (loginInfo: LoginInfo) => void;
  beforeSubmit?: () => void;
  center?: boolean;
  userEmail?: string;
};

const initialValues: LoginInfo = {
  email: '',
  country: 'LT'
};

const validationSchema = Yup.object({
  email: emailRequired
});

const EmailForm: FC<Props> = ({
  formHeading,
  formSubheading,
  inputPlaceholder,
  buttonLabel,
  center,
  userEmail,

  beforeSubmit,
  onSubmit
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formValues = useMemo(
    () => ({
      ...initialValues,
      email: userEmail || ''
    }),
    [userEmail]
  );

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFormHandlers<LoginInfo>(
    formValues,
    validationSchema
  );

  const onHandleSubmit = async () => {
    try {
      if (!isSubmitting) {
        setIsSubmitting(true);

        beforeSubmit?.();
        await handleSubmit(
          (data: LoginInfo) => onSubmit(data),
          () => setTouchedOnAll()
        )();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onHandleSubmit();
      }}
    >
      {formHeading && <FormHeading center={center}>{formHeading}</FormHeading>}
      {formSubheading && <FormSubheading>{formSubheading}</FormSubheading>}

      <Field
        focused
        Component={Input}
        name="email"
        type="email"
        value={getValues('email')}
        placeholder={inputPlaceholder}
        validationHelpers={validationHelpers}
        required
      />
      <FormButton data-testid="button-login" size="L" onClick={onHandleSubmit} type="button">
        {buttonLabel}
      </FormButton>
    </Form>
  );
};

export default EmailForm;
