import { ReactNode } from 'react';

import { B, H5, H2, H1, H3, Chips, H4, InputText, InputLabel, H6 } from './typography.styles';

type HeaderProps = {
  children: ReactNode;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  weight?: 'bold' | 'semibold' | 'medium' | 'normal' | 'light';
  className?: string;
};

export const Header = ({ type, children, weight, className, ...rest }: HeaderProps) => {
  switch (type) {
    case 'h1':
      return (
        <H1 {...rest} weight={weight} className={className}>
          {children}
        </H1>
      );
    case 'h2': {
      return (
        <H2 {...rest} weight={weight} className={className}>
          {children}
        </H2>
      );
    }
    case 'h3':
      return (
        <H3 {...rest} weight={weight} className={className}>
          {children}
        </H3>
      );
    case 'h4':
      return (
        <H4 {...rest} weight={weight} className={className}>
          {children}
        </H4>
      );
    case 'h6':
      return (
        <H6 {...rest} weight={weight} className={className}>
          {children}
        </H6>
      );
    case 'h5': //h5 at the moment is default
    default:
      return (
        <H5 {...rest} weight={weight} className={className}>
          {children}
        </H5>
      );
  }
};

type BodyProps = {
  children: ReactNode;
  type?: 'S' | 'M' | 'L';
  weight?: 'bold' | 'semibold' | 'medium' | 'normal' | 'light';
  className?: string;
};

export const Body = ({ children, weight, type, className, ...rest }: BodyProps) => {
  return (
    <B {...rest} className={className} size={type} weight={weight}>
      {children}
    </B>
  );
};

type ChipProps = {
  children: ReactNode;
  type?: 'S' | 'M' | 'L';
  weight?: 'bold' | 'semibold' | 'medium' | 'normal' | 'light';
  className?: string;
};

export const Chip = ({ children, weight, type, className }: ChipProps) => {
  return (
    <Chips className={className} size={type} weight={weight}>
      {children}
    </Chips>
  );
};

export type InputProps = {
  children: ReactNode;
  type?: 'S' | 'M' | 'L';
  weight?: 'bold' | 'semibold' | 'medium' | 'normal' | 'light';
  className?: string;
};

const Input = ({ children, weight, type, className, ...rest }: ChipProps) => {
  return (
    <InputText className={className} size={type} weight={weight} {...rest}>
      {children}
    </InputText>
  );
};

const InputViewLabel = ({ children, weight, type, className, ...rest }: ChipProps) => {
  return (
    <InputLabel className={className} size={type} weight={weight} {...rest}>
      {children}
    </InputLabel>
  );
};

const Button = ({ children, weight, type, className, ...rest }: ChipProps) => {
  return (
    <InputText className={className} size={type} weight={weight} {...rest}>
      {children}
    </InputText>
  );
};

export default { Header, Body, Chip, Input, InputViewLabel, Button };
