import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttonV2';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import {
  useApplicationCardStatusHandlers,
  useFetchExistingCustomerApplicationOnboardingStatus
} from 'features/applications/hooks';
import { selectActiveCompany, selectApplicationsList } from 'features/applications/selectors';
import { ButtonActions, Status } from 'features/applications/types/applicationsTypes';
import { useFetchExistingCustomerOndatoLinkMutation } from 'features/onboarding/api';
import { useAppSelector } from 'hooks/redux/hooks';

const statusesButtons: Record<Status, ButtonActions | ''> = {
  NOT_FILLED: 'requestFinancing',
  INITIAL: 'continueFilling',
  ADDITIONAL_INFORMATION_NEEDED: 'uploadDocuments',
  SUBMITTED_FOR_VOTING: '',
  ASSESSMENT_ONGOING: '',
  OFFER_SENT: 'viewOffer',
  OFFER_ACCEPTED: '',
  OFFER_REJECTED: '',
  KYC_WAITING: 'fillQuestionnaire',
  KYC_COMPLETED: '',
  KYC_REJECTED: '',
  KYC_NOT_REQUIRED: '',
  REJECTED: '',
  NA: '',
  INACTIVE: '',
  DELETED: '',
  REFUSED_BY_CLIENT: '',
  CONTRACT_PREPARED: '',
  VOTING_COMPLETED: '',
  OFFER_GENERATED: '',
  WAITING_FOR_INFO: 'uploadDocuments',
  ADDITIONAL_INFO_SUBMITTED: '',
  CONTRACT_PREPARATION_IN_PROGRESS: '',
  CONTRACT_SIGNED: '',
  CONTRACT_PREPARATION_FAILED: ''
};

export const ApplicationPrimaryAction = ({ applicationId, id }: { applicationId?: string; id?: string }) => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const applications = useAppSelector(selectApplicationsList);
  const selectedCompany = useAppSelector(selectActiveCompany);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const application = applications?.find(
    (application) =>
      (id && application.capId === id) || (applicationId && application.applicationId === applicationId)
  );

  const { status } = application ?? ({ status: 'NOT_FILLED' } as { status: Status });

  const { onboardingForApplicationNeeded, onboardingInitiationHandler } =
    useFetchExistingCustomerApplicationOnboardingStatus(status);

  const [, { isLoading }] = useFetchExistingCustomerOndatoLinkMutation({ fixedCacheKey: selectedCompany.id });

  const buttonHandlers = useApplicationCardStatusHandlers();

  const { t } = useTranslation();

  const action =
    isFeatureEnabled('verificationFlow') && status === 'KYC_WAITING'
      ? undefined
      : statusesButtons[status ?? 'INITIAL'];

  const buttonLabel = onboardingForApplicationNeeded ? t('updateProfile') : t(action ?? '');

  const handleButtonClick =
    (action: ButtonActions | '') => async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onboardingForApplicationNeeded) {
        setIsSubmitting(true);

        try {
          await onboardingInitiationHandler();
        } finally {
          setIsSubmitting(false);
        }

        return;
      }

      if (!application?.status) {
        buttonHandlers.continueFilling(application?.capId);
      }

      if (buttonHandlers && action) {
        buttonHandlers[action](applicationId);
      }
    };

  return (
    <>
      {action || onboardingForApplicationNeeded ? (
        <Button
          color="Blue"
          variant="Filled"
          size="M"
          submitting={isSubmitting}
          disabled={onboardingForApplicationNeeded && isLoading}
          onClick={handleButtonClick(action ?? '')}
          type="button"
        >
          {buttonLabel}
        </Button>
      ) : null}
    </>
  );
};
