import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LinkButton } from 'components/link';
import { debounce } from 'utils';

import close from '../../assets/icons/close.svg';
import { Button } from '../buttonV2';
import { transformDateToLocalizedString } from '../datePicker/utils';
import { AsideDrawer, AsideMenuTopBar } from '../pageV2';
import { useCheckForHigherActiveDrawer, useSwipeTillConfirmAttributeMutationObserver } from '../pageV2/hooks';
import { DrawerTopbarTitle, IsolatedBottomNav, Overlay } from '../pageV2/layout.styles';

import { ActionsPanelRow, FilterButton, FilterContent, FilterPanelContainer } from './filterPanel.styles';

type UnknownObject = Record<string, unknown>;

type FilterFieldsProps<TFilterParams extends UnknownObject> = {
  onChange: (name: keyof TFilterParams, shouldDebounce?: boolean) => (value: string | Date | null) => unknown;
  filterParams: TFilterParams;
};

type Props<TFilterParams extends UnknownObject> = {
  onFilterParamsChange: (params: TFilterParams) => unknown;
  defaultParams: TFilterParams;
  FilterFields: FC<FilterFieldsProps<TFilterParams>>;
  appliedParams?: TFilterParams;
  [key: string]: unknown;
};

const getConvertedValue = (value: string | Date | null) => (value === 'ALL' ? '' : value);

const checkIfRefIsInPath = (e: Event, ref: MutableRefObject<HTMLDivElement>) => {
  return ref.current && ref.current.contains(e.target as Node);
};

export const useCheckIfClickedOutside = (
  callback: (isOpen: boolean) => unknown,
  open: boolean,
  refs: MutableRefObject<HTMLDivElement>[],
  checkForExtraConditions?: () => boolean
) => {
  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      const extraConditionsPass = checkForExtraConditions ? checkForExtraConditions() : true;

      if (open && extraConditionsPass) {
        const isInPath = refs.some((ref) => checkIfRefIsInPath(e, ref));

        if (!isInPath) {
          callback(false);
        }
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => document.removeEventListener('mousedown', checkIfClickedOutside);
  }, [open]);
};

export const FilterPanel = <TFilterParams extends UnknownObject>({
  FilterFields,
  onFilterParamsChange,
  defaultParams,
  appliedParams,
  ...rest
}: Props<TFilterParams>) => {
  const [filterDrawerIsOpen, setFilterDrawerIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(appliedParams || defaultParams);
  const [appliedFilterParams, setAppliedFilterParams] = useState(appliedParams || defaultParams);

  const { t } = useTranslation();

  const onClear = () => {
    setFilterParams(defaultParams);
    setAppliedFilterParams(defaultParams);
  };

  const onClose = () => {
    setFilterDrawerIsOpen(false);
    setFilterParams(appliedFilterParams);
  };

  const drawerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;
  const overlayRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

  const checkForHigherActiveDrawer = useCheckForHigherActiveDrawer(1);

  useCheckIfClickedOutside(onClose, filterDrawerIsOpen, [drawerRef], () => !checkForHigherActiveDrawer());

  const onChange =
    (applyOnChange: boolean) =>
    (name: keyof TFilterParams, shouldDebounce = false) =>
    (value: string | Date | null) => {
      let convertedValue = value;

      if (!value) {
        convertedValue = '';
      }

      if (value instanceof Date) {
        convertedValue = transformDateToLocalizedString(value) || '';
      }

      debounce(
        () => {
          if (applyOnChange) {
            setFilterParams({ ...filterParams, [name]: getConvertedValue(convertedValue) });
            setAppliedFilterParams({ ...appliedFilterParams, [name]: getConvertedValue(convertedValue) });
          } else {
            setFilterParams({ ...filterParams, [name]: getConvertedValue(convertedValue) });
          }
        },
        shouldDebounce ? 300 : 0
      )();
    };

  const onApply = () => {
    setAppliedFilterParams(filterParams);
    setFilterDrawerIsOpen(false);
  };

  const modalFilterParamsChange = onChange(false);

  useEffect(() => {
    onFilterParamsChange(appliedFilterParams);
  }, [appliedFilterParams, onFilterParamsChange]);

  useEffect(() => {
    setAppliedFilterParams(appliedParams || defaultParams);
    setFilterParams(appliedParams || defaultParams);
  }, [appliedParams, defaultParams, setFilterParams]);

  const filterParamsCount = Object.keys(appliedFilterParams)
    .map((key) => Boolean(appliedFilterParams[key as keyof typeof appliedFilterParams]))
    .filter((el) => el).length;

  const onSwipe = (tillConfirm: number) => {
    if (overlayRef.current) {
      if (tillConfirm) {
        overlayRef.current.style.backgroundColor = `rgba(0, 0, 0, ${Math.min(1 - tillConfirm, 0.5)})`;
      } else {
        overlayRef.current.removeAttribute('style');
      }
    }
  };

  useSwipeTillConfirmAttributeMutationObserver(drawerRef.current, onSwipe);

  return (
    <>
      <Overlay ref={overlayRef} position="absolute" />
      <AsideDrawer
        disableBodyScroll
        ref={drawerRef}
        isOpen={filterDrawerIsOpen}
        activeIndex={0}
        position="fixed"
        portal={false}
      >
        <AsideMenuTopBar
          inner={false}
          handleClose={onClose}
          topbarContent={<DrawerTopbarTitle>{t('filter')}</DrawerTopbarTitle>}
        />
        <FilterPanelContainer data-testid="filter-fields">
          <FilterFields onChange={modalFilterParamsChange} filterParams={filterParams} {...rest} />
        </FilterPanelContainer>
        <IsolatedBottomNav data-testid="filter-actions">
          <Button
            data-testid="filter-actions-filter"
            disabled={defaultParams === filterParams}
            onClick={onApply}
          >
            {t('filterVerb')}
          </Button>
          <Button
            data-testid="filter-actions-clear"
            disabled={defaultParams === filterParams}
            onClick={onClear}
            variant="Stroked"
            color="Black"
          >
            {t('clearFilter')}
          </Button>
        </IsolatedBottomNav>
      </AsideDrawer>
      <ActionsPanelRow data-testid="filter-panel-desktop">
        <FilterButton
          data-testid="filter-button"
          onClick={() => setFilterDrawerIsOpen((visible) => !visible)}
        >
          <FilterContent>
            {t('filter')}
            {filterParamsCount ? ` | ` : ''}
            <b>{filterParamsCount ? `${filterParamsCount}` : ''}</b>
            {filterParamsCount ? (
              <LinkButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  onClear();
                }}
                color="Grey"
                iconOnly
                icons={{ right: close }}
              />
            ) : null}
          </FilterContent>
        </FilterButton>
      </ActionsPanelRow>
    </>
  );
};
