import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import AsideDrawer from './asideDrawer';
import DragablePill from './dragablePill';
import { SwipeHandlersOptions, useSwipeTillConfirmAttributeMutationObserver } from './hooks';
import { BottomDrawerScreen } from './layout.styles';

type Props = {
  children: ReactNode;
  open?: boolean;
  onVisibilityChange?: (visible: boolean) => void;
};

const BottomDrawer = ({ children, open, onVisibilityChange }: Props) => {
  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const [delayedClosed, setDelayedClose] = useState(!open);

  useEffect(() => {
    if (open) {
      setDelayedClose(false);
    } else {
      window.requestAnimationFrame(() => setTimeout(() => setDelayedClose(true), 200));
    }

    document.body.classList.toggle('drawer-focused', Boolean(open));

    return () => {
      document.body.classList.remove('drawer-focused');
    };
  }, [open]);

  const handleVisibilityChange = () => {
    onVisibilityChange?.(false);
    setDelayedClose(true);
  };

  const onSwipe = (tillConfirm: number) => {
    if (overlayRef.current) {
      if (tillConfirm) {
        overlayRef.current.style.backgroundColor = `rgba(0, 0, 0, ${Math.min(1 - tillConfirm, 0.5)})`;
      } else {
        overlayRef.current.removeAttribute('style');
      }
    }
  };

  useSwipeTillConfirmAttributeMutationObserver(childrenContainerRef.current, onSwipe);

  const swipeOptions = useMemo((): SwipeHandlersOptions => {
    return {
      thresholdForConfirm: (childrenContainerRef.current?.clientHeight ?? 0) * 0.25,
      onSwipeConfirm: handleVisibilityChange
    };
  }, [childrenContainerRef.current?.clientHeight]);

  return (
    <BottomDrawerScreen ref={overlayRef} hidden={delayedClosed} display="flex" mobile tablet fullWidth>
      <AsideDrawer isOpen={open} data-active-index={0} data-swipable-area ref={childrenContainerRef}>
        <DragablePill swipeAreaRef={childrenContainerRef} swipeOptions={swipeOptions} />
        {children}
      </AsideDrawer>
    </BottomDrawerScreen>
  );
};

export default BottomDrawer;
