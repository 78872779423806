import { StatementsState } from './types';

export const selectInstitutionsIsLoading = (state: StatementsState) => state.isLoading;
export const selectInstitutionsLoadFailed = (state: StatementsState) => state.isError;

export const selectInstitutionsWithoutConsent = (state: StatementsState) => {
  const withConsent = selectInstitutionsWithConsent(state) || [];

  return state.institutions
    ? state.institutions.filter(
        (institution) =>
          !withConsent.find((institutionWithConsent) => institutionWithConsent.id === institution.id)
      )
    : state.institutions;
};
export const selectInstitutionsWithConsent = (state: StatementsState) => {
  return state.institutionsWithConsent;
};
export const selectConsentInitiatedFor = (state: StatementsState) => state.consentInitiatedFor;
export const selectRequisitionId = (state: StatementsState) => state.reguisitionId;
export const selectCapIdConsentInitiatedFor = (state: StatementsState) => state.capId;
export const selectPsd2FlowLink = (state: StatementsState) => state.psd2FlowLink;
export const selectInstitutionById = (state: StatementsState) => (referenceId: string) =>
  selectInstitutionsWithoutConsent(state)?.find((institution) => institution.id === referenceId);
