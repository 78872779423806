import styled from 'styled-components';

import { Typography } from 'components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const Message = styled(Typography.Body).attrs({ type: 'M' })`
  color: ${({ theme }) => theme.colors.base.black.shades[70]};
  text-align: center;
`;

export const Image = styled.img`
  height: 11rem;
  width: 11rem;
  margin-bottom: 0.5rem;
`;
