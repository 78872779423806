const keys = ['identitySuccess', 'onboardingSuccess'] as const;

type Keys = (typeof keys)[number];

type VerificationSearchParams = Record<Keys, boolean>;

const getTypedSearchParams = () => {
  const searchParams = new URLSearchParams(location.search);

  return Object.fromEntries(
    keys.map((key) => {
      if (!searchParams.has(key)) {
        return [key, undefined];
      }

      return [key, searchParams.get(key) === 'true'];
    })
  ) as VerificationSearchParams;
};

export default getTypedSearchParams;
