import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { HeaderContainer } from 'components/page/page.styles';
import { Toast } from 'components/toastV2';
import { useFetchApplicationsQuery } from 'features/application/api';
import { ApplicationReviewModal } from 'features/applications/components/applicationReviewModal/applicationReviewModal';
import { selectVerificationApplication } from 'features/applications/selectors';
import { Status } from 'features/applications/types/applicationsTypes';
import { selectCustomerInformation } from 'features/auth/selectors';
import {
  useCheckForApplicationStatusQuery,
  useInitOnboardingApplicationMutation
} from 'features/onboarding/api';
import {
  AddBankAccountModal,
  FinancingUnavailableCard,
  OnboardingSuccessModal,
  OndatoIntro,
  OndatoRedirect,
  ReauthenticationSuccessModal,
  Stepper
} from 'features/onboarding/components';
import {
  useCurrentFlowStep,
  useGetOnboardingEnabledConfig,
  useStepNotifications
} from 'features/onboarding/hooks';
import { isReadyForOndato } from 'features/onboarding/hooks/useCurrentFlowStep';
import { useOnboardingWarmup } from 'features/onboarding/hooks/useOnboardingRedirectHandler';
import getTypedSearchParams from 'features/onboarding/utils/getTypedSearchParams';
import { useAppSelector, useAuthSelector } from 'hooks/redux/hooks';
import { usePollingOptions } from 'hooks/rtkQuery';
import { OndatoStatusResponse } from 'services/OnboardingService/types';
import tracking from 'utils/tracking';

import { Header, HeaderShimmer, HeaderWrapper } from './verification.styles';

export const Verification = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get('success');

  const typedSearchParams = getTypedSearchParams();

  const { identitySuccess } = typedSearchParams;

  const customerInfo = useAuthSelector<typeof selectCustomerInformation>(selectCustomerInformation);
  const { userAgreementAccepted } = customerInfo ?? { userAgreementAccepted: false };

  const { isFetching: applicationsIsLoading, isError } = useFetchApplicationsQuery({
    fullList: true
  });

  const [reauthenticationSuccessModalIsShown, setReauthenticationSuccessModalIsShown] = useState(false);
  const [onboardingSuccessModalIsShown, setOnboardingSuccessModalIsShown] = useState(false);
  const [addBankAccountModalIsShown, setAddBankAccountModalIsShown] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const { ondatoAvailableCAPStatus } = useGetOnboardingEnabledConfig();

  const application = useAppSelector(selectVerificationApplication);

  const applicationStatus = application?.status as Status;
  const resolvedId = application?.applicationId ?? '';

  const { flowStatus } = useCurrentFlowStep({
    application: { ...application, applicationId: resolvedId }
  });

  const [currentStep, currentStepStatus] = flowStatus ?? [null, null];

  const notReadyForOndato = !resolvedId || !isReadyForOndato(applicationStatus, ondatoAvailableCAPStatus);

  const interval = usePollingOptions(Boolean(identitySuccess));

  const { data: ondatoInfo = {} as OndatoStatusResponse, isLoading: ondatoStatusIsLoading } =
    useCheckForApplicationStatusQuery(
      { applicationId: resolvedId },
      {
        skip: notReadyForOndato,
        pollingInterval: interval
      }
    );

  const loading = applicationsIsLoading || ondatoStatusIsLoading || (application && !flowStatus);

  const [createOnboardingQuestionaire] = useInitOnboardingApplicationMutation({
    fixedCacheKey: 'initOnboardingApplication'
  });

  const onboardingWarmupTrigger = useOnboardingWarmup(
    createOnboardingQuestionaire,
    ondatoInfo,
    Boolean(userAgreementAccepted)
  );

  useEffect(() => {
    onboardingWarmupTrigger();
  }, [ondatoInfo.status, userAgreementAccepted]);

  useEffect(() => {
    if (success) {
      searchParams.delete('success');

      setIsSuccessModalVisible(true);
      setSearchParams(searchParams, { replace: true });
    }
  }, [success]);

  const stepperHeaderLabel = () => {
    if (
      currentStepStatus.verifyYourIdentity === 'ONDATO_FINISHED' ||
      currentStepStatus.singContractAndReceiveFinancing !== undefined
    ) {
      const icon = currentStepStatus.singContractAndReceiveFinancing !== undefined ? '🚀' : '💼';

      return (
        <Header>
          {t('verificationPersonalizedHeaderGreeting', { name: customerInfo?.userFirstName })} {icon}
        </Header>
      );
    }

    return (
      <Header>
        {t('verificationHeaderGreeting')} <span>smeGo</span> 👋
      </Header>
    );
  };

  useStepNotifications(setReauthenticationSuccessModalIsShown, setOnboardingSuccessModalIsShown, ondatoInfo);

  useEffect(() => {
    if (!loading && !isError) {
      if (resolvedId) {
        tracking.setSingleFlowIdAndStatus(resolvedId.replaceAll('-', ''), currentStep);
      }
    }
  }, [loading]);

  if (!loading && isError) {
    return (
      <Toast isVisible variant="error" header={t('generalErrorHeader')} message={t('generalErrorMessage')} />
    );
  }

  const kycRejected =
    currentStepStatus[currentStep] === 'SME_CUSTOMER_ONBOARDING_REJECTED' ||
    applicationStatus === 'KYC_REJECTED';

  return kycRejected ? (
    <FinancingUnavailableCard />
  ) : (
    <>
      <OndatoIntro />
      <OndatoRedirect redirectTo={ondatoInfo?.ondatoUrl ?? ''} />
      <ApplicationReviewModal
        isOpen={isSuccessModalVisible}
        onVisibilityChange={() => setIsSuccessModalVisible(false)}
      />
      <AddBankAccountModal
        open={addBankAccountModalIsShown}
        onClose={() => setAddBankAccountModalIsShown(false)}
      />
      <OnboardingSuccessModal
        open={onboardingSuccessModalIsShown}
        onVisibilityChange={(visible) => {
          setOnboardingSuccessModalIsShown(visible);
          setAddBankAccountModalIsShown(!visible);
        }}
      />
      <ReauthenticationSuccessModal
        open={reauthenticationSuccessModalIsShown}
        onVisibilityChange={setReauthenticationSuccessModalIsShown}
        ondatoInfo={ondatoInfo}
        isFarm={!!application.farm}
      />
      {loading ? (
        <HeaderShimmer />
      ) : (
        <HeaderWrapper>
          <HeaderContainer>{stepperHeaderLabel()}</HeaderContainer>
        </HeaderWrapper>
      )}
      <Stepper
        currentStep={currentStep}
        currentStepStatus={currentStepStatus}
        ondatoInfo={ondatoInfo}
        applicationsLoading={loading}
        application={application}
      />
    </>
  );
};
