import { FC, ReactNode, useEffect, useState } from 'react';

import { FilePreview, LoadInfo } from './filePreview';
import { PreviewContainer, PreviewOverlay, StyleProps } from './filePreview.styles';
import { PreviewControls } from './previewControls';
import { PreviewHeader } from './previewHeader';

type Props = {
  file: Blob | null;
  filename: string;
  awaitParentContainer?: boolean;
  parentContainer?: HTMLElement;
  onError?: (visible: boolean) => unknown;
  onClose?: () => unknown;
  fullScreen?: boolean;
  extraActions?: ReactNode;
  emptyPage?: ReactNode;
  emptyPageRef?: React.RefObject<HTMLDivElement>;
  fixed?: boolean;
} & Partial<StyleProps>;

export const FileViewer: FC<Props> = ({
  file,
  filename,
  parentContainer,
  onError,
  onClose,
  extraActions,
  emptyPage,
  emptyPageRef,
  fixed = true,
  lightMode = 'dark',
  fullScreen = true
}) => {
  const [fileString, setFileString] = useState('');
  const [numPages, setNumPages] = useState<number>(0);
  const [currentScale, setCurrentScale] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [previewNotSupported, setPreviewNotSupported] = useState(false);
  const [controlsInfo, setControlsInfo] = useState<LoadInfo>({
    hasZoomControlsVisible: true,
    hasPageControlsVisible: true,
    hasDownloadControlsVisible: true
  });

  const handleFileDownload = async () => {
    try {
      if (file) {
        const url = window.URL.createObjectURL(file as Blob);

        const link = document.createElement('a');

        link.href = url;
        link.download = filename;
        link.click();
      }
    } catch {
      console.error('Error while reading file');
    }
  };

  const onLoad = (info: LoadInfo) => {
    setControlsInfo(info);
  };

  const handlePreviewClose = () => {
    setNumPages(0);
    setCurrentScale(1);
    setCurrentPage(1);
    setPreviewNotSupported(false);
    setControlsInfo({
      hasZoomControlsVisible: true,
      hasPageControlsVisible: true,
      hasDownloadControlsVisible: true
    });
    onClose?.();
  };

  useEffect(() => {
    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        setFileString(fileReader.result as string);
      };

      fileReader.readAsDataURL(file);
    } else {
      setFileString('');
    }
  }, [file]);

  return (
    <PreviewContainer fixed={fixed} data-testid="file-preview-container">
      <PreviewOverlay
        fixed={fixed}
        onClick={onClose ? handlePreviewClose : undefined}
        fullScreen={fullScreen}
        lightMode={lightMode}
      >
        <PreviewHeader
          extraActions={extraActions}
          lightMode={lightMode}
          filename={filename}
          onDownload={handleFileDownload}
          onClose={onClose ? handlePreviewClose : undefined}
        />
        <FilePreview
          emptyPage={emptyPage}
          emptyPageRef={emptyPageRef}
          lightMode={lightMode}
          onLoad={onLoad}
          filename={filename}
          fileString={fileString || ''}
          currentScale={currentScale}
          currentPage={currentPage}
          onError={onError}
          setNumPages={setNumPages}
          onDownload={handleFileDownload}
          previewNotSupported={previewNotSupported}
          parentContainer={parentContainer}
        />
        <PreviewControls
          lightMode={lightMode}
          numPages={numPages}
          onPageChange={setCurrentPage}
          currentPage={currentPage}
          onZoom={setCurrentScale}
          currentZoom={currentScale}
          {...controlsInfo}
        />
      </PreviewOverlay>
    </PreviewContainer>
  );
};
