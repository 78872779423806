import React, { ForwardedRef, forwardRef } from 'react';

import { Spinner } from 'components/loader';

import { LinkButtonStyle, LinkButtonStyleProps } from './link.styles';

export type Props = {
  loading?: boolean;
  className?: string;
  ref?: ForwardedRef<HTMLButtonElement>;
} & React.ComponentPropsWithoutRef<'button'> &
  LinkButtonStyleProps;

export const LinkButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, onClick, loading, type, ...rest }, ref) => {
    return (
      <LinkButtonStyle
        ref={ref}
        type={type ?? 'button'}
        onClick={onClick}
        {...rest}
        loading={loading}
        aria-disabled={loading || rest.disabled}
        disabled={loading || rest.disabled}
      >
        <Spinner display={loading} />
        {children}
      </LinkButtonStyle>
    );
  }
);
