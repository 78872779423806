import { ReactNode, forwardRef, useEffect, useId } from 'react';
import { createPortal } from 'react-dom';

import { TopBar } from '../topBar';

import { OverlayViewContainer, OverlayViewContent } from './layout.styles';

type Props = {
  children: ReactNode;
  open?: boolean;
  onClose?: () => unknown;
  direction?: 'left' | 'right';
  title?: string;
  lowerZIndex?: boolean;
};

export const OverlayView = forwardRef<HTMLDivElement, Props>(
  ({ children, open, onClose, title, direction = 'left', lowerZIndex = false }, ref) => {
    const isOpen = open ? 'open' : 'close';
    const zIndex = lowerZIndex && 'lower-z-index';

    const className = `${isOpen} ${zIndex}`;

    const key = useId();

    useEffect(() => {
      document.body.classList.toggle('drawer-focused', Boolean(open));

      return () => {
        document.body.classList.remove('drawer-focused');
      };
    }, [open]);

    return createPortal(
      <OverlayViewContainer data-testid="overlay-view" hidden={!open} ref={ref} className={className}>
        <TopBar title={title} onToggleAcionClick={onClose} variant="inner" alignment={direction} />
        <OverlayViewContent>{children}</OverlayViewContent>
      </OverlayViewContainer>,
      document.body,
      `overlay-view-${key}`
    ) as unknown as ReactNode;
  }
);
