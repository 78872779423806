import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DetailsHeader,
  DetailsSection,
  RowInputs,
  StepSectionTitle,
  StepSectionValue
} from './applicationDetailsDrawer.styles';

const isBoolean = (value: unknown) => typeof value === 'boolean';

type StepSectionProps = {
  label?: string;
  data?: object;
  fields?: object;
};

export const StepSection: FC<StepSectionProps> = ({ data, fields, label }) => {
  const { t } = useTranslation();

  if (!data || !fields) {
    return null;
  }

  const fieldKeys = Object.keys(fields);

  return (
    <DetailsSection>
      {label && <DetailsHeader>{t(label)}</DetailsHeader>}
      {fieldKeys.map((key: string) => {
        const value = data[key as keyof typeof data];
        const isValueDefined = typeof value === 'undefined';
        const isValueBoolean = isBoolean(value);

        if (isValueDefined) {
          return '-';
        }

        return (
          <RowInputs key={key}>
            <StepSectionTitle>{t(fields[key as keyof typeof data])}</StepSectionTitle>
            {isValueBoolean ? (
              <StepSectionValue>{t(value ? 'yes' : 'no')}</StepSectionValue>
            ) : (
              <StepSectionValue>{value}</StepSectionValue>
            )}
          </RowInputs>
        );
      })}
    </DetailsSection>
  );
};
