import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
  ContractsRequest,
  ContractsResponse,
  ContractsTotalsRequest,
  ContractsTotalsResponse,
  ContractReferenceRateResponse,
  ContractReferenceRateRequest,
  ContractDocumentsListRequest,
  ContractDocumentsListResponse,
  ContractsProlongRequest,
  ContractsProlongResponse,
  ContractsIncreaseLimitResponse,
  ContractsIncreaseLimitRequest
} from 'services/ContractsService/types';
import { axiosBaseQuery } from 'services/RestClient/axiosBaseQuery';
import { baseUrlBuilder } from 'services/utils/baseUrlBuilder';
import { queryBuilder } from 'services/utils/queryBuilder';

const withBaseUrl = baseUrlBuilder('contracts');

const factoringContractsApi = createApi({
  reducerPath: 'factoringContractsApi',
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    getFactoringContracts: build.query<ContractsResponse, ContractsRequest>({
      query: (payload) => ({ url: queryBuilder(withBaseUrl('/contracts'), payload) })
    }),
    getFactoringContractTotals: build.query<ContractsTotalsResponse, ContractsTotalsRequest>({
      query: (payload) => ({ url: queryBuilder(withBaseUrl('/contracts/totals'), payload) }),
      keepUnusedDataFor: 0
    }),
    getContractReferenceRate: build.query<ContractReferenceRateResponse, ContractReferenceRateRequest>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl(`/contracts/${payload.contractId}/contract-reference-rate`), payload)
      }),
      keepUnusedDataFor: 0
    }),
    getContractDocumentsList: build.query<ContractDocumentsListResponse, ContractDocumentsListRequest>({
      query: (payload) => ({
        url: queryBuilder(withBaseUrl(`/contracts/${payload.contractId}/documents`), {
          contractVersion: payload.contractVersion,
          documentStatus: ['SIGNED', 'MANUALLY_SIGNED']
        })
      }),
      keepUnusedDataFor: 0
    }),
    requestIncreaseLimit: build.mutation<ContractsIncreaseLimitResponse, ContractsIncreaseLimitRequest>({
      query: (payload) => ({
        url: withBaseUrl('/contracts/increase-limits/'),
        method: 'POST',
        data: payload
      })
    }),
    requestContractProlong: build.mutation<ContractsProlongResponse, ContractsProlongRequest>({
      query: (payload) => ({
        url: withBaseUrl('/contracts/prolong/'),
        method: 'POST',
        data: payload
      })
    })
  }),
  keepUnusedDataFor: 15
});

export const {
  useGetFactoringContractsQuery,
  useGetFactoringContractTotalsQuery,
  useGetContractReferenceRateQuery,
  useGetContractDocumentsListQuery,
  useRequestContractProlongMutation,
  useRequestIncreaseLimitMutation
} = factoringContractsApi;
export const { resetApiState: resetContractsApiState } = factoringContractsApi.util;

export default factoringContractsApi;
