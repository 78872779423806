import styled, { DefaultTheme } from 'styled-components';

import { Typography } from '../typography';

type Props = {
  type: 'error' | 'warning' | 'success';
};

export const IconStyle = styled.div<Props>`
  svg {
    path {
      stroke: ${({ theme, type }) =>
        type === 'error' ? theme.colors.primary.errorText : theme.colors.status.note};
    }
  }
`;

export const Note = styled(Typography.Body).attrs({ type: 'L', weight: 'normal' })`
  text-align: center;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
`;

export const NoteHeader = styled(Typography.Header).attrs({ type: 'h3', weight: 'medium' })`
  display: inline-flex;
  text-align: center;
  margin-bottom: 1rem;

  @media ${({ theme }) => theme.media.maxTablet} {
    line-height: 1.75rem;
  }
`;

type IconProps = {
  variant: keyof DefaultTheme['colors']['notificationIcon'];
  icon: string;
};

export const NotificationImage = styled.img`
  width: 6.25rem;
  height: 6.25rem;
  object-fit: cover;
`;

export const NotificationIcon = styled.div<IconProps>`
  width: 4.5rem;
  height: 4.5rem;
  background-color: ${({ theme, variant }) => theme.colors.notificationIcon[variant].background};
  border-radius: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;

  &::before {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    mask-image: ${({ icon }) => `url("${icon}")`};
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: cover;
    background-color: ${({ theme, variant }) => theme.colors.notificationIcon[variant].icon};
  }
`;
