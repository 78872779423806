import { useModalContext } from 'features/nav/context/modalContext';
import { useAppSelector } from 'hooks/redux/hooks';
import { ExistingCustomerOnboardingStatusResponse } from 'services/OnboardingService/types';

import { selectCompanyQuestionaireStatus, selectExistingCustomerStatus } from '../selectors';

import useOnboardingRedirectHandler from './useOnboardingRedirectHandler';
import useOndatoRedirectHandler from './useOndatoRedirectHandler';

type UseHandlerReturnType = () => unknown;

const useExistingCustomerOnboardingInitiationHandler = ({
  onboardingStatus,
  ondatoStatus
}: Partial<ExistingCustomerOnboardingStatusResponse> = {}): UseHandlerReturnType => {
  const location = window.location;

  const { modals } = useModalContext();

  const questionaireStatus = useAppSelector(selectExistingCustomerStatus);
  const companyOnboardingStatus = useAppSelector(selectCompanyQuestionaireStatus);

  const ondatoInfo = {
    status: questionaireStatus,
    ondatoId: companyOnboardingStatus?.ondatoId
  };

  const onboardingRedirectHandler = useOnboardingRedirectHandler({
    ondatoInfo,
    existingCustomerOnboarding: true,
    redirectBackTo: location.pathname
  });

  const [ondatoRedirectHandler] = useOndatoRedirectHandler({
    ondatoInfo,
    onOndatoInitSuccess: async (success?: boolean, ondatoUrl?: string) => {
      if (success) {
        if (ondatoUrl) {
          modals?.get('existing-customer-ondato-intro')?.();
        }
      }
    }
  });

  return async () => {
    if (onboardingStatus !== 'COMPLETED') {
      if (
        (ondatoStatus !== 'FINISHED' && ondatoStatus !== 'IN_PROGRESS') ||
        onboardingStatus === 'REJECTED'
      ) {
        await ondatoRedirectHandler();
      } else {
        onboardingRedirectHandler();
      }
    }
  };
};

export default useExistingCustomerOnboardingInitiationHandler;
