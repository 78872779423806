import { Grid, Column } from 'components';
import { useFetchContractDetails } from 'features/contracts/hooks';
import { FactoringBuyers } from 'features/contracts/tables';
import { FactoringContractDetails } from 'features/factoring/components/factoringContractDetails/factoringContractDetails';
import { FactoringDocumentsReview } from 'features/factoring/components/factoringDocumentsReview/factoringDocumentsReview';
import { FactoringLimitsDetails } from 'features/factoring/components/factoringLimitsDetails/factoringLimitsDetails';

import { Row, FlexColumn } from './contracts.styles';

export const FactoringContracts = () => {
  const { data, isLoading, isError } = useFetchContractDetails();
  const { thirdParties } = data || {};

  return (
    <Grid>
      <Column rowStart={1} spanRow={3} colStart={1} span={12}>
        <Row>
          <FlexColumn>{data && <FactoringContractDetails contractDetails={data} />}</FlexColumn>
          <FlexColumn>
            {data && <FactoringLimitsDetails />}
            <FactoringDocumentsReview />
          </FlexColumn>
        </Row>
      </Column>
      <Column colStart={1} span={12} colStartMd={1} spanMd={12} rowStartMd={4} spanRowMd={4}>
        <FactoringBuyers items={thirdParties || []} loading={isLoading} error={isError} />
      </Column>
    </Grid>
  );
};
