import styled from 'styled-components';

import { Legend, LinkButtonStyle } from 'components';

import { RegularText, SecondaryText } from '../../../../components/text';

export const ProductAndAmountContainer = styled.div`
  & > ${Legend} {
    margin-top: 1rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${LinkButtonStyle} {
    align-items: flex-end;

    svg {
      margin-right: 0;
    }
  }
`;

export const RowInputs = styled(Row)`
  flex-wrap: wrap;

  span {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0.3125rem;
  }

  ${SecondaryText} {
    margin-right: 1.875rem;
  }
`;

type ButtonContainerProps = {
  isBorderVisible?: boolean;
};

export const ButtonSection = styled.div<ButtonContainerProps>`
  position: sticky;
  bottom: 0;
  padding: 1rem 0;
  width: 100%;
  margin-top: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 33.75rem;
  background-color: ${({ theme }) => theme.colors.primary.background};
  z-index: ${({ theme }) => theme.layers.nav};
  border-top: ${({ isBorderVisible, theme }) =>
    isBorderVisible ? `1px solid ${theme.colors.base.black.shades[10]}` : '1px solid transparent'};

  button {
    width: 100%;
    max-width: 35.75rem;
  }
`;

export const InformationContainer = styled.div`
  ${Legend} {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.primary.textColor};
    padding: 0.9375rem 0 0.3125rem;
  }

  > ${Legend}:first-of-type {
    padding: 0 0 0.3125rem;
  }
`;

export const ConsentContainer = styled.div`
  margin: 1rem 0 0.5rem;
  font-size: 0.875rem;
`;

export const Subsection = styled.div`
  background-color: #f7f7f7;
  padding: 1.25rem;
  margin-bottom: 0.625rem;

  ${RowInputs}:last-of-type {
    span {
      margin-bottom: 0;
    }
  }
`;

export const SubsectionsList = styled.div`
  margin-top: 0.625rem;

  ${Subsection}:last-of-type {
    margin-bottom: 0;
  }
`;

type Props = {
  positive?: boolean;
};

export const IconContainer = styled.div<Props>`
  display: flex;
  margin-bottom: 0.9375rem;
  width: 100%;
  align-items: center;

  svg {
    path {
      fill: ${({ positive }) => (positive ? '' : '#EF2615')};
      stroke: ${({ positive }) => (positive ? '#a4e694' : '')};
    }
  }

  ${RegularText} {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }
`;

export const FactoringTypeSection = styled.div`
  ${RowInputs}:last-of-type {
    ${IconContainer} {
      margin-bottom: 0;
    }
  }
`;

export const ViewModeHeader = styled.div`
  margin-bottom: 0.9rem;
`;

export const Header = styled.h2`
  width: 100%;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.h2};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0.875rem;
  color: ${({ theme }) => theme.colors.primary.headerColor};
`;

export const ReviewFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const ViewModeConsentContainer = styled.div`
  margin: 1.25rem 0;
  font-size: 0.875rem;

  ${RegularText} {
    color: #7f7f7f;
  }
`;
