import { FC, ReactNode } from 'react';

import { FINANCIAL_PRODUCT } from 'features/application/types/applicationTypes';
import { Type } from 'services/CapService/types';

import { Card, InfoRow, IconPlaceholder, InfoColumn, InfoCardProductLabel } from './productCard.styles';

export type CardProps = {
  id: FINANCIAL_PRODUCT;
  type: Type;
  label: string;
  iconToDisplay: ReactNode;
  onCardClick?: (id: FINANCIAL_PRODUCT, type: Type) => void;
};

const ProductCard: FC<CardProps> = ({ id, type, label, iconToDisplay, onCardClick }) => {
  const dataTestId = 'product-card';

  return (
    <Card data-testid={`${dataTestId}-${label}`} onClick={() => onCardClick && onCardClick(id, type)}>
      <InfoRow>
        <IconPlaceholder>{iconToDisplay}</IconPlaceholder>
        <InfoColumn>
          <InfoCardProductLabel data-testid={`${dataTestId}-label`}>{label}</InfoCardProductLabel>
        </InfoColumn>
      </InfoRow>
    </Card>
  );
};

export default ProductCard;
