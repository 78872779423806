import { useTranslation } from 'react-i18next';

import binXL from 'assets/icons/binExtraLarge.svg';
import { DeleteConfirmationModal } from 'components';
import { selectActiveCompany } from 'features/applications/selectors';
import { useAppSelector } from 'hooks/redux/hooks';

type Props = {
  isOpen: boolean;
  onClose: () => unknown;
  onDelete: () => unknown;
  userName?: string;
  userRole?: string;
};

const UserDeleteConfirmationModal = ({ isOpen, onClose, onDelete, userRole, userName }: Props) => {
  const { t } = useTranslation();

  const activeCompany = useAppSelector(selectActiveCompany);

  return (
    <DeleteConfirmationModal
      icon={<img src={binXL} />}
      label={t('deleteThisUserName', { name: userName })}
      message={t('deleteThisUserDescription', {
        userRole: userRole,
        companyName: activeCompany.name
      })}
      isOpen={isOpen}
      onVisibilityChange={onClose}
      mainButtonColor="Red"
      onConfirmation={onDelete}
    ></DeleteConfirmationModal>
  );
};

export default UserDeleteConfirmationModal;
