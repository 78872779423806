import { ChangeEvent, FC } from 'react';
import { UseFormClearErrors, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ChipOption } from 'components';
import { selectActiveCustomer } from 'features/auth/selectors/';
import { useApplicationSelector, useAppSelector, useAuthSelector } from 'hooks/redux/hooks/';

import { ValidationHelpers } from '../../../../hooks/forms/useFormHandlers';
import { selectCapId, selectCompanyCountry, selectIsNewCompany } from '../../selectors';

import { BuyersFormFieldsContainer, BuyersFormListItem } from './companyOrFarmField.styles';
import { IsFarm } from './companyOrFarmForm';

type Props = {
  getValues: UseFormGetValues<IsFarm>;
  validationHelpers: ValidationHelpers<IsFarm>;
  clearErrors: UseFormClearErrors<IsFarm>;
};

export const CompanyOfFarmField: FC<Props> = ({ getValues, validationHelpers }) => {
  const { t } = useTranslation();
  const { setValue } = validationHelpers || {};
  const capId = useAppSelector(({ application }) => selectCapId(application));
  const isApplicationCreated = Boolean(capId); // Do not let edit if application is already created
  const selectedCountry = useApplicationSelector<typeof selectCompanyCountry>(selectCompanyCountry);
  const activeCustomer = useAuthSelector<typeof selectActiveCustomer>(selectActiveCustomer);
  const isNewCompany = useAppSelector(selectIsNewCompany);

  const hasType = !!activeCustomer?.customerType;
  const disabled = isApplicationCreated || (hasType && !isNewCompany);

  const setIsFarm = (value: boolean) => setValue('isFarm', value);

  const onIsFarmChangeValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const isFarm = target.value === 'true';

    setIsFarm(isFarm);
  };

  const isFarm = getValues('isFarm');

  return (
    <BuyersFormFieldsContainer>
      <BuyersFormListItem>
        <ChipOption
          checked={isFarm === false}
          value="false"
          name="isFarm"
          onChange={onIsFarmChangeValue}
          disabled={disabled}
        >
          {t('forCompany')}
        </ChipOption>
        {selectedCountry === 'LT' && (
          <ChipOption
            checked={isFarm === true}
            value="true"
            name="isFarm"
            onChange={onIsFarmChangeValue}
            disabled={disabled}
          >
            {t('forFarm')}
          </ChipOption>
        )}
      </BuyersFormListItem>
    </BuyersFormFieldsContainer>
  );
};
