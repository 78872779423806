import { useSyncExternalStore } from 'react';

const useExternalSessionStorage = (key: string) => {
  const STORAGE_KEY = key;
  const STORAGE_VALUE = 'started';

  function setBankStepStarted() {
    if (window.Cookiebot?.consent?.preferences) {
      window.localStorage.setItem(STORAGE_KEY, STORAGE_VALUE);

      window.dispatchEvent(new StorageEvent('storage', { key: STORAGE_KEY, newValue: STORAGE_VALUE }));
    }
  }

  const externalStore = {
    getSnapshot: () => localStorage.getItem(STORAGE_KEY),
    subscribe: (listener: () => void) => {
      window.addEventListener('storage', listener);

      return () => void window.removeEventListener('storage', listener);
    }
  };

  const state = useSyncExternalStore(externalStore.subscribe, externalStore.getSnapshot);

  return [state ?? '', setBankStepStarted] as const;
};

export default useExternalSessionStorage;
