import { Locale, setDefaultOptions } from 'date-fns';
import { enGB, lt } from 'date-fns/locale';
import { useState, MouseEvent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import chevronRight from 'assets/icons/chevronRight.svg';
import { BlueCheckmark } from 'components/checkbox/checkbox.style';
import { saveLanguagePreferenceWithConsentCheck } from 'features/nav/components/languagesMenu';
import { getLanguageDisplayName } from 'translations/countries';
import { Languages } from 'translations/translations';
import { getLanguageCode } from 'translations/utils';

import {
  LanguageButton,
  LanguageNameAndFlag,
  LanguageOptions,
  LanguageOptionsModal
} from './languageSelector.styles';

const locales: Record<Languages, Locale> = {
  lt,
  en: enGB
};

type OptionProps = {
  language: Languages;
  selectedLanguage?: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
};

const LanguageRadioButton = ({ language, selectedLanguage, onClick }: OptionProps) => {
  return (
    <BlueCheckmark
      name="language"
      id={language}
      value={language}
      checked={selectedLanguage === language}
      onClick={onClick}
    />
  );
};

const LanguageOption = ({ language, selectedLanguage, onClick }: OptionProps) => {
  return (
    <LanguageNameAndFlag data-testid={`language-option-${language}`} language={language} htmlFor={language}>
      {getLanguageDisplayName(language.toUpperCase(), language)}
      {onClick ? (
        <LanguageRadioButton language={language} selectedLanguage={selectedLanguage} onClick={onClick} />
      ) : undefined}
    </LanguageNameAndFlag>
  );
};

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const getLanguage = () => getLanguageCode(i18n.resolvedLanguage).toLowerCase() as Languages;

  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguage());

  const onOptionSelect = (e: MouseEvent<HTMLInputElement>) => {
    const language = e.currentTarget.value;

    setSelectedLanguage(language as Languages);
  };

  const onClose = () => {
    setSelectedLanguage(getLanguage());
    setOpen(false);
  };

  const onSave = () => {
    setDefaultOptions({ locale: locales[selectedLanguage as Languages] });
    i18n.changeLanguage(selectedLanguage);
    document.documentElement.lang = selectedLanguage;

    saveLanguagePreferenceWithConsentCheck(selectedLanguage);

    onClose();
  };

  const onLanguageButtonClick = () => {
    setOpen(true);
  };

  return (
    <>
      <LanguageOptionsModal
        testId="language-options"
        label={t('language')}
        isOpen={open}
        onClose={onClose}
        secondaryActionLabel={t('cancel')}
        mainActionLabel={t('save')}
        mainActionhandler={onSave}
      >
        <LanguageOptions>
          <LanguageOption language="en" onClick={onOptionSelect} selectedLanguage={selectedLanguage} />
          <LanguageOption language="lt" onClick={onOptionSelect} selectedLanguage={selectedLanguage} />
        </LanguageOptions>
      </LanguageOptionsModal>
      <LanguageButton color="Grey" onClick={onLanguageButtonClick} icons={{ right: chevronRight }}>
        <LanguageOption language={getLanguage()} />
      </LanguageButton>
    </>
  );
};

export default LanguageSelector;
