import styled from 'styled-components';

import { LinkButton } from 'components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 1.75rem;
  height: 1.75em;
`;

type Props = {
  absolute?: boolean;
};

export const LogoutButton = styled(LinkButton)<Props>`
  display: flex;
  position: ${({ absolute }) => (absolute ? 'absolute' : '')};
  bottom: 0;

  span {
    color: ${({ theme }) => theme.colors.base.black.shades[70]};
    font-size: 0.8125rem;
    line-height: 1.375rem;
  }

  svg {
    margin-right: 0.625rem;
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill: ${({ theme }) => theme.colors.base.black.shades[70]};
    }
  }
`;
