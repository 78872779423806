import { Column, Grid } from 'components';
import { LeasingContractDetails } from 'features/loans/components';
import { DocumentsReview } from 'features/loans/components/documentsReview/documentsReview';
import { LeasingLimitsDetails } from 'features/loans/components/loansLimitsDetails/leasingLimitsDetails';

import { Row, FlexColumn } from '../factoring/contracts.styles';

export const LeasingContracts = () => {
  return (
    <Grid>
      <Column rowStart={1} spanRow={3} colStart={1} span={12}>
        <Row>
          <FlexColumn>
            <LeasingContractDetails />
          </FlexColumn>
          <FlexColumn>
            <LeasingLimitsDetails />
            <DocumentsReview />
          </FlexColumn>
        </Row>
      </Column>
    </Grid>
  );
};
