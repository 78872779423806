import { createSelector } from '@reduxjs/toolkit';

import { LoanOverviewItem, ProductType } from 'services/LoansService/types';
import { RootState } from 'store';

import { selectActiveCustomer } from '../auth/selectors';

import loansApi from './api';
import { LoansState, UpcommingPaymentItem, ContractItem } from './types';

const createLoansTotalsSelector = createSelector(
  ({ customerId, customerCountryId }: { customerId: string; customerCountryId: string }) => ({
    customerId,
    customerCountryId
  }),
  (activeCustomer) => {
    const { customerCountryId, customerId } = activeCustomer || {};

    return loansApi.endpoints.getTotals.select({
      customerCode: customerId,
      customerType: 'B',
      customerCountryCode: customerCountryId
    });
  }
);

export const selectHasLoansProducts = createSelector(
  (state: RootState) => ({
    state,
    customerId: selectActiveCustomer(state.auth)?.customerId ?? '',
    customerCountryId: selectActiveCustomer(state.auth)?.customerCountryId ?? ''
  }),
  ({ state, customerId, customerCountryId }) => {
    const totals = createLoansTotalsSelector({ customerId, customerCountryId })(state);

    const isSuccessfullyLoaded = !totals.isUninitialized && !totals.isLoading && totals.isSuccess;

    return isSuccessfullyLoaded && totals.data?.length > 0;
  }
);

export const selectLoansContractList = (state: LoansState) => state.overview.contracts;
export const selectLoansProductType = (state: LoansState) => state.overview.type;
export const selectIsLoansContractListLoading = (state: LoansState) => state.isLoading;
export const selectIsLoansContractListLoadFailed = (state: LoansState) => state.isLoadingFailed;

const selectHasNoProductTypeContracts = (state: LoansState) => (productType: ProductType) =>
  !selectIsLoansContractListLoadFailed(state) &&
  !selectIsLoansContractListLoading(state) &&
  !selectLoansContractList(state)?.length &&
  selectLoansProductType(state) === productType;

export const selectHasNoLoansContracts = (state: LoansState) =>
  selectHasNoProductTypeContracts(state)('LOANS');

export const selectHasNoCreditLineContracts = (state: LoansState) =>
  selectHasNoProductTypeContracts(state)('CREDIT_LINE');

export const selectHasNoLeasingContracts = (state: LoansState) =>
  selectHasNoProductTypeContracts(state)('LEASING');

export const selectHasNoRbfContracts = (state: LoansState) => selectHasNoProductTypeContracts(state)('RBF');

export const selectLoansPaymentsList = (state: LoansState) => state.payments;
export const selectFilteredLoansPaymentsList = (state: LoansState) =>
  selectLoansPaymentsList(state)?.loanInvoicesData?.filter((payment) => payment.paymentStatus !== 'PAID');
export const selectIsLoansPaymentsListLoading = (state: LoansState) => state.isPaymentsLoading;
export const selectIsLoansPaymentsListLoadFailed = (state: LoansState) => state.isPaymentsLoadFailed;
export const selectIsLoansPaymentsHasNoData = (state: LoansState) =>
  !selectIsLoansPaymentsListLoadFailed(state) &&
  !selectIsLoansPaymentsListLoading(state) &&
  !selectFilteredLoansPaymentsList(state)?.length;

export const selectChosenLoansContractInfo = (state: LoansState) => (id: string) =>
  state.overview?.contracts?.find((contract) => contract.contractNo === id);

export const selectLoansContractsTableItems = createSelector(
  selectLoansContractList,
  selectLoansProductType,
  (contracts, productType): (ContractItem & LoanOverviewItem)[] => {
    return (
      contracts?.map((contract) => {
        const { financierShortName, contractNo } = contract || {};

        return {
          ...contract,
          id: contractNo,
          type: productType,
          number: contractNo,
          financier: financierShortName
        };
      }) || []
    );
  }
);

export const selectLoansUpcomingPaymentsTableItems = createSelector(
  selectFilteredLoansPaymentsList,
  (payments): UpcommingPaymentItem[] => {
    return (
      payments?.map((payment) => {
        const { billType, billNumber, dueDate, paymentStatus, unpaidAmount } = payment || {};

        return {
          ...payment,
          type: billType,
          number: billNumber,
          dueDate,
          status: paymentStatus,
          unpaid: unpaidAmount
        };
      }) || []
    );
  }
);
