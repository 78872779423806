import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { AmountText, RegularText, SecondaryText } from 'components';
import { useRemoteConfigContext } from 'defaultConfiguration/context';
import { InfoBoxes, LimitChartSection } from 'features/contracts/components';
import { selectChosenLoansContractInfo } from 'features/loans/selectors';
import { useLoansSelector } from 'hooks/redux/hooks';

import {
  AdditionalFeesSection,
  AditionalInfoHeader,
  AditionalInfoRow
} from './loansLimitChartSection.styles';

export const LeasingLimitChartSection: FC = () => {
  const { isFeatureEnabled } = useRemoteConfigContext();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams({ contractId: '' });
  const contractId = searchParams.get('contractId');

  const contract = useLoansSelector<typeof selectChosenLoansContractInfo>(selectChosenLoansContractInfo)(
    contractId || ''
  );

  if (!contract) return null;

  const {
    contract: contractFeesOverdue,
    leasingFinancedAmount,
    leasingOverdueAmount,
    assetPriceIncVat,
    overdueAmount,
    repaidAmount,
    repaymentLeftAmount,
    interestAmount,
    defaultInterest
  } = contract;

  const availableAdditionalInfo = assetPriceIncVat ? (
    <AditionalInfoRow>
      <SecondaryText>{t('assetPrice')}</SecondaryText>
      <SecondaryText>
        <AmountText>{assetPriceIncVat}</AmountText>
      </SecondaryText>
    </AditionalInfoRow>
  ) : null;

  const showAdditionalFees = leasingOverdueAmount || interestAmount || defaultInterest || contractFeesOverdue;

  const overdueAdditionalInfo = showAdditionalFees ? (
    <AdditionalFeesSection data-testid="additional-fees-section">
      <AditionalInfoHeader>
        <RegularText>{t('additionalFees')}</RegularText>
      </AditionalInfoHeader>
      {leasingOverdueAmount ? (
        <AditionalInfoRow>
          <SecondaryText>{t('overdue')}</SecondaryText>
          <SecondaryText>
            <AmountText>{leasingOverdueAmount}</AmountText>
          </SecondaryText>
        </AditionalInfoRow>
      ) : null}
      {interestAmount ? (
        <AditionalInfoRow>
          <SecondaryText>{t('interest')}</SecondaryText>
          <SecondaryText>
            <AmountText>{interestAmount}</AmountText>
          </SecondaryText>
        </AditionalInfoRow>
      ) : null}
      {defaultInterest ? (
        <AditionalInfoRow>
          <SecondaryText>{t('overdueInterest')}</SecondaryText>
          <SecondaryText>
            <AmountText>{defaultInterest}</AmountText>
          </SecondaryText>
        </AditionalInfoRow>
      ) : null}
      {contractFeesOverdue ? (
        <AditionalInfoRow>
          <SecondaryText>{t('contract')}</SecondaryText>
          <SecondaryText>
            <AmountText>{contractFeesOverdue}</AmountText>
          </SecondaryText>
        </AditionalInfoRow>
      ) : null}
    </AdditionalFeesSection>
  ) : null;

  const infoBoxes: InfoBoxes = {
    total: {
      label: t('financedAmount'),
      value: leasingFinancedAmount,
      infoPopupText: t('loansFinancedAmountExplanation'),
      children: availableAdditionalInfo
    },
    used: {
      label: t('repaidAmount'),
      value: repaidAmount,
      infoPopupText: t('loansRepaidAmountExplanation')
    },
    available: {
      label: t('repaymentLeft'),
      value: repaymentLeftAmount,
      infoPopupText: t('loansRepaymentLeftAmountExplanation')
    },
    overdue: {
      value: overdueAmount,
      infoPopupText: t('loansOverdueAmountExplanation'),
      link: isFeatureEnabled('bills')
        ? { url: `/finance/leasing/bills?contractId=${contractId}&billStatus=OVERDUE`, label: t('view') }
        : undefined,
      children: overdueAdditionalInfo
    }
  };

  return <LimitChartSection infoBoxes={infoBoxes} />;
};
